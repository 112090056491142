import { ApiListResult, Models } from "@alcome-rep/alcome-types";
import { IFileDocument, IInventory, InventoryStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { Inventory } from "@alcome-rep/alcome-types/dist/models";
import { defaultPagination } from "../../common/app-constants";
import ApiService from "../../common/services/apiService";

const INVENTORY_ADMIN_PATH_API = 'admin/inventory';

class InventoryAdminApi extends ApiService<IInventory> {
  constructor() {
    super(INVENTORY_ADMIN_PATH_API)
  }

  /**
   * @description Find all inventories
   * @param param0 
   * @returns inventory list
   */
  findInventory(query = {}, page = defaultPagination.page, pageSize = defaultPagination.pageSize): Promise<ApiListResult<IInventory>> {
    return this.find({ query, page, pageSize })
  }

  /**
   * @description get Inventory by structureId
   * @param structureId 
   * @returns Inventory
   */
  getInventoryByStructureId = (structureId: string): Promise<Inventory> => {
    return this.get('byStructure/' + structureId)
      .then(r => new Inventory(r))
  }

  /**
   * @description Generate Inventory file
   * @param inventoryId 
   * @returns Inventory updated
   */
  generateInventoryFile(inventoryId: string): Promise<Models.Inventory> {
    return this.custom<IInventory>('post', 'generate/:inventoryId', { inventoryId })
      .then(r => new Models.Inventory(r))
  }

  /**
   * @description delete inventory file
   * @param inventoryId 
   * @returns deleted updated inventory
   */
  deleteInventoryFile(inventoryId: string): Promise<IInventory> {
    return this.deleteFileDoc('inventoryFileId', inventoryId)
  }

  /**
   * @description download inventory file
   * @param inventoryId 
   * @returns inventory
   */
  downloadInventoryFile(inventoryId: string): Promise<void> {
    return this.downloadFileDoc('inventoryFileId', inventoryId, 'annexes_alcome.pdf')
  }

  /**
   * @description get inventory file
   * @param inventoryId 
   * @returns inventory
   */
  getInventoryFile(inventoryId: string): Promise<void> {
    return this.getFileDoc('inventoryFileId', inventoryId)
  }

  /**
   * @description Update inventory file
   * @param inventoryId 
   * @param data 
   * @returns Updated inventory
   */
  updateInventoryFile(inventoryId: string, data: Partial<IFileDocument>) {
    return this.updateFileDoc('inventoryFileId', data, inventoryId)
  }

  /**
  * @description get inventory by Id
  * @param inventoryId 
  * @returns return inventory infos
  */
  getInventoryById(inventoryId: string): Promise<IInventory> {
    return this.get(inventoryId)
  }

  /**
   * @description Validate inventory
   * @param inventoryId 
   * @param data 
   * @returns inventory updated
   */
  validateStatus(inventoryId: string): Promise<Models.Inventory> {
    return this.custom<IInventory>('put', 'validateStatus/:inventoryId', { inventoryId })
      .then(r => new Models.Inventory(r))
  }

  /**
   * @description Refuse inventory
   * @param inventoryId 
   * @param data 
   * @returns inventory updated
   */
  refuseStatus(inventoryId: string, data: { comment: string | null }): Promise<Models.Inventory> {
    return this.custom<IInventory>('put', 'refuseStatus/:inventoryId', { inventoryId }, data)
      .then(r => new Models.Inventory(r))
  }

  /**
   * @description change inventory status
   * @param inventoryId 
   * @param changeStatus 
   * @returns inventory updated
   */
  changeStatus(inventoryId: string, changeStatus: { status: InventoryStatusEnum, dontNotif: boolean }): Promise<IInventory> {
    return this.custom('put', 'changeStatus', inventoryId, changeStatus)
  }

}

const inventoryAdminApi = new InventoryAdminApi();
export default inventoryAdminApi;
import { BaseCommune } from "@alcome-rep/alcome-types/dist/models";
import { Tag } from "antd";
import { useTranslation } from "react-i18next";

type PropsType = {
  structure: BaseCommune,
  mode?: "text" | "tag"
}

const StructureType = ({ structure, mode = "text" }: PropsType) => {
  const { t } = useTranslation();
  if (mode === 'text')
    return <span>{t('TYPES.STRUCTURE.StructureTypesEnum.' + structure.structureType)}</span>
  else if (mode === 'tag')
    return <Tag className="space-x-2" color={t(`UI.TAG.StructureTypesEnum.${structure.structureType}`)}>
      {t(`TYPES.STRUCTURE.StructureTypesEnum.${structure.structureType}`)}
    </Tag>

  return <span> – </span>;
}

export default StructureType;
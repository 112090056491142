import { ISupportOrders, StructureQuotasType, SupportOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { User } from "@alcome-rep/alcome-types/dist/models";
import { Loader } from "@googlemaps/js-api-loader";
import { Button, Form, InputNumber } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { selectUser } from "../../../../Auth/state/auth.slice";
import { selectCurrentAdminStructure } from "../../../../Structure/state/structure-admin.slice";
import { selectCurrentStructure } from "../../../../Structure/state/structure-portal.slice";
import LoadingScreen from "../../../../common/components/LoadingScreen";
import Section from "../../../../common/components/Panels/Section";
import Info from "../../../../common/components/WarningUser/Info";
import { useAppSelector } from "../../../../common/hooks";
import useFormRules from "../../../../common/hooks/useFormRules";
import supportOrderApi from "../../../services/supportOrder.api";
import SupportOrderHotspotSelection from "../SupportOrderHotSpotSelection/SupportOrderHotspotSelection";

const SupportOrderEdit = () => {
  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLEMAP_KEY || '',
    libraries: ["drawing", "places"]
  });
  loader.libraries.forEach(l => loader.importLibrary(l))
  // loader.load()

  const user = useAppSelector(selectUser);
  const structure = useAppSelector(User.IsUserAdmin(user) ? selectCurrentAdminStructure : selectCurrentStructure)
  const [quotas, setQuotas] = useState<StructureQuotasType>();

  useEffect(() => {
    if (!structure)
      return
    supportOrderApi.getQuotas(structure.id!)
      .then(r => setQuotas(r))
  }, [structure])

  const [form] = useForm();
  const { required } = useFormRules()

  const { supportOrderId }: any = useParams();

  const [supportOrder, setSupportOrder] = useState<ISupportOrders | undefined>();

  useEffect(() => {
    if (!supportOrderId)
      setSupportOrder({} as ISupportOrders)
    else
      supportOrderApi.getSupportOrder(supportOrderId)
        .then(res => {
          setSupportOrder(res)
        })
  }, [supportOrderId])


  const submit = async (data: any) => {
    if (supportOrderId) {
      const supportUpdated = await (await supportOrderApi.updateSupportOrder(supportOrderId, data))
      setSupportOrder(supportUpdated)
    }
  }

  if (!supportOrder)
    return <LoadingScreen />

  return (

    <div>
      {supportOrder.status === SupportOrderStatusEnum.support_order_completed && (
        <Info body="Votre demande est en cours de validation par Alcome" />
      )}

      {/* {supportOrder.status === SupportOrderStatusEnum.support_order_refused && (
        <>
          <SupportCounter />
          <div className="my-4 bg-alc-yellow p-4 rounded-md">
            <p>Votre demande de soutien a été refusée pour le motif indiqué ci-dessous. Veuillez passer une nouvelle commande.<br /></p>
            <p className="italic mb-0 font-semibold">{supportOrder.supportOrderComment}</p>
          </div>
        </>
      )} */}

      <Form
        form={form}
        initialValues={supportOrder}
        onFinish={submit}
      >

        <Section title="Dispositifs de collecte - Cendrier de rue">
          <SupportOrderHotspotSelection supportOrder={supportOrder} collectPointsAllowed={quotas?.available.ashtrays} />
        </Section>

        <Section title="Nombre de dispositifs mégot associés aux corbeilles">
          <Form.Item name="extinguisherCount" rules={[
            required,
            {
              message: 'Quota autorisé: ' + quotas?.available.extinguishers,
              validator: (_, value) => {
                if (value <= quotas?.available.extinguishers!) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('Some message here');
                }
              }
            }
          ]}>
            <InputNumber readOnly={supportOrder.status >= SupportOrderStatusEnum.support_order_completed} min={0} ></InputNumber>
          </Form.Item>
        </Section>
        {supportOrder.status < SupportOrderStatusEnum.support_order_completed && (
          <Button className="btn mt-8 rounded-lg" htmlType="submit">Envoyer la demande de soutien</Button>
        )}
      </Form>

    </div >

  )
}

export default SupportOrderEdit
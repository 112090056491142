import { Outlet } from "react-router-dom";
import AppNavBar from "../AppLayout/AppNavBar";

const WelcomeLayout = () => {
  // const isLoggedIn = useAppSelector(selectIsLoggedIn);
  // if (isLoggedIn) {
  //   return <>
  //     <Navigate to={ROUTES.DASHBOARD}></Navigate>
  //   </>
  // }

  return <>
    <div className="grid grid-main-frame h-screen" >
      < div className="" >
        <AppNavBar></AppNavBar>
      </div>
      < div className="bg-gray-100 " >
        <div className="page-grid">
          <Outlet></Outlet>
        </div>
      </div>
    </div >

  </>
}

export default WelcomeLayout;
import { ActionStatusEnum, ActionTypeEnum, IAction, IContact, IProspect } from "@alcome-rep/alcome-types/dist/interfaces/crm";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import confirm from "antd/es/modal/confirm";
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../common/hooks";
import useFormRules from "../../../common/hooks/useFormRules";
import { closeDrawerAction } from "../../../common/state/modal.slice";
import actionAdminApi from "../../services/action-admin.api";
import contactAdminApi from "../../services/contact-admin.api";
import prospectAdminApi from "../../services/prospect-admin.api";

type PropTypes = {
  action?: IAction,
  prospectIdToEdit?: string,
  formCb?: (data?: FormType | null) => void
}

type FormType = Partial<IAction>

const ActionInfosForm = ({ action, formCb, prospectIdToEdit }: PropTypes) => {
  const [actionType, setActionType] = useState<ActionTypeEnum>()

  const [prospects, setProspects] = useState<IProspect[]>()
  const [contacts, setContacts] = useState<IContact[]>()

  const { t } = useTranslation()
  const [form] = useForm()
  const prospectId = useWatch('prospectId', form)
  // const actionStatus = useWatch('status', form)
  const { required } = useFormRules()
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (action)
      actionAdminApi.getAction(action.id as string)
        .then(res => {
          // setData(res)
          form.setFieldsValue({
            ...res,
            actionDate: moment(new Date(res.actionDate)),
            prospect: (res.prospect as any).id,
            contact: (res.contacts as any[]).map(c => c.id),
          })
        })
    else if (prospectId) {
      form.setFieldsValue({ prospect: prospectId })
    }

  }, [dispatch, action, prospectId, form]);

  useEffect(() => {
    if (prospectIdToEdit || (action && action.prospect && (action.prospect as any).id)) {
      contactAdminApi.findProspectContacts(prospectIdToEdit as string)
        .then(res => {
          setContacts(res.rows)
          return res
        })
    }
  }, [action, prospectIdToEdit])

  const deleteAction = () => {
    confirm({
      title: 'Voulez-vous supprimer l\'action ?',
      icon: <QuestionCircleOutlined />,
      cancelText: 'Annuler',
      closable: true,
      okCancel: true,
      okText: 'Oui',
      okButtonProps: { className: "btn" },
      async onOk() {
        if (action) {
          await actionAdminApi.deleteAction(action.id as string);
          formCb && formCb(null);
        }
      },
      onCancel() {
      },
    });
  }

  const update = async (data: any) => {
    let updatedAction;
    if (action) {
      updatedAction = await actionAdminApi.update(action.id!, { ...data, prospectId})
    } else {
      if(data.prospectId){
        updatedAction = await actionAdminApi.create({ ...data, prospectId: data.prospectId });
        }else{
        updatedAction = await actionAdminApi.create({ ...data, prospectId: prospectIdToEdit });
      }
    }
    formCb && formCb(updatedAction)
    dispatch(closeDrawerAction())
  }

  const updateActionType = (e: ActionTypeEnum) => {
    setActionType(e);
    if (e === ActionTypeEnum.email)
      form.setFieldValue('status', ActionStatusEnum.terminated)
    else
      form.setFieldValue('status', undefined)
  }

  const getProspects = async (query: any) => {
    const queryObj = {
      name: `/${query}/`
    }
    const res = await prospectAdminApi.findAll({ query: queryObj, page: 1, limit: 5 })
    setProspects(res.rows)
  }

  return (
    <>
      <div className=''>
        <Form
          name="basic"
          layout='vertical'
          onFinish={update}
          form={form}
          initialValues={action ?? {}}
        >
          {prospectIdToEdit === undefined && !action?.id && (
            <Form.Item
              label="Fiche de prospection"
              name={'prospectId'}
            >
              <Select
                showSearch
                placeholder="Chercher un prospect"
                optionFilterProp="children"
                onSearch={e => getProspects(e)}
              // onBlur={_ => findContacts()}
              >
                {prospects && prospects.map(v => {
                  return <Select.Option key={v.id} value={v.id}>{v.name}</Select.Option>
                }
                )}
              </Select>
            </Form.Item>
          )}
          <div className='grid grid-cols-2 gap-x-4 gap-y-0'>

            <Form.Item
              label="Type d'action"
              name='type'
              rules={[{ required: true, message: 'Merci de sélectionner un type de d\'action' }]}
            >
              <Select
                onChange={e => updateActionType(e)}>
                {Object.keys(ActionTypeEnum)
                  .filter(v => typeof v === "string")
                  .map(v => (
                    <Select.Option key={v} value={v}>{t(`TYPES.CRM.ACTIONS.ActionTypeEnum.${v}`)}</Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Statut de l'action"
              name='status'
              rules={[{ required: true, message: 'Merci de sélectionner un statut pour l\'action' }]}
            >
              <Select>
                {Object.keys(ActionStatusEnum)
                  .filter(v => typeof v === "string")
                  .filter(v => {
                    if (actionType !== ActionTypeEnum.phone_call && v === ActionStatusEnum.unsuccessfull)
                      return false
                    else if (actionType === ActionTypeEnum.email && v !== ActionStatusEnum.terminated)
                      return false
                    return true
                  })
                  .map(v => (
                    <Select.Option key={v} value={v}>{t(`TYPES.CRM.ACTIONS.ActionStatusEnum.${v}`)}</Select.Option>
                  ))}
              </Select>
            </Form.Item>

          </div>

          <Form.Item
            label="Date de l'action"
            name={'actionDate'}
            rules={[required]}
            getValueProps={(e: string) => ({
              value: e ? dayjs(e) : "",
            })}
          >
            <DatePicker className='w-full ' format={'DD/MM/YYYY'} />
          </Form.Item>

          <Form.Item
            label="Commentaire"
            name={'comment'}
          >
            <TextArea rows={4} placeholder="Commentaire"></TextArea>
          </Form.Item>

          {contacts && (
            <Form.Item
              label="Contact de la structure"
              name={'contactsId'}
            >
              <Select
                // showSearch
                placeholder="Liste des contact"
                // optionFilterProp="children"
                mode="multiple"
                // onSearch={findContacts}
                options={contacts.map(v => ({ label: `${v.firstName} ${v.lastName}`, value: v.id }))}
                allowClear
              >

              </Select>
            </Form.Item>
          )}

          <Form.Item
            hidden
            label="prospect"
            name={'prospectId'}
          >
            <Input></Input>
          </Form.Item>
          {action?.id && (
            <div className='flex'>
              <Form.Item>
                <Button htmlType="submit" type="primary">{t('COMMON.save')}</Button>
              </Form.Item>
              <Button type='link' danger htmlType="button" onClick={deleteAction}>Supprimer</Button>
            </div>
          )}
          {(prospectId || prospectIdToEdit) && !action?.id && (
            <Form.Item>
              <Button htmlType="submit" type="primary" >Créer l'action</Button>
            </Form.Item>
          )}

        </Form>
      </div>
    </>
  );
}

export default ActionInfosForm
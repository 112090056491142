import { SignatoryTypesEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import { LeftOutlined, SyncOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Modal, Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import React, { Dispatch, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../../../../common/components/LoadingScreen';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { getTrackProgressAreas } from '../../../../common/services/apiService';
import toast from '../../../../common/services/toast';
import { useTrackProgress } from '../../../../common/services/trackProgress';
import { getMyStructureAction, selectCurrentStructure } from '../../../../Structure/state/structure-portal.slice';
import contractPortalApi from '../../../services/contract-portal.api';
import { selectCurrentContract, setCurrentContractAction } from '../../../state/contract-portal.slice';
import PortalDelegationFileUpload from '../FileUploads/PortalDelegationUpload';
import PortalExecutiveDeliberationFileUpload from '../FileUploads/PortalExecutiveDeliberationUpload';
import GenerateContractButton from './GenerateContractButton';

const CommuneConflictRecap: React.FC = () => {

  const { t } = useTranslation();

  const navigate = useNavigate()
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const contract = useAppSelector(selectCurrentContract)
  const structure = useAppSelector(selectCurrentStructure)

  const dispatch: Dispatch<any> = useAppDispatch()
  const isLoading = useTrackProgress(getTrackProgressAreas("").fileUpload('contractFileId'));

  const openModal = () => {
    setModalVisible(true)
  }

  // const [canUploadContract, setCanUploadContract] = useState<boolean>()

  const canUploadContract = contract
    && contract.executiveDeliberationFileId
    && contract.contractFileGeneratedId
    && (contract.signatory.type === SignatoryTypesEnum.president || contract.delegationFileId)

  const uploadContract = async (file: RcFile) => {
    const isPDF = file.type === 'application/pdf';
    if (!isPDF) {
      toast.error(`${file.name} n'est pas un fichier pdf`);
    } else {
      const updatedContract = await contractPortalApi.uploadContractFile(file)
      dispatch(setCurrentContractAction(updatedContract))
      dispatch(getMyStructureAction())
    }
    return false;
  }

  if (!contract || !structure)
    return <LoadingScreen />


  return (
    <>
      <h2 className='mb-4'>{t('UI.REGISTER_CONTRACT.CONFLICT_RECAP.title')}</h2>
      <div>
        <p>
          {t('UI.REGISTER_CONTRACT.CONFLICT_RECAP.fields.documents.label')}
        </p>
      </div>

      <div className='grid grid-cols-2 gap-x-4'>
        {contract && contract.signatory.type === SignatoryTypesEnum.delegate && (
          <PortalDelegationFileUpload isInForm={true} />
        )}
        <PortalExecutiveDeliberationFileUpload isInForm={true} />
      </div>
      {contract.executiveDeliberationFileId && <GenerateContractButton />}

      {/* {structure?.structureType === StructureTypesEnum.member_structure && (
                <GenerateButtonField
                    form={form}
                    field={{
                        name: "contractFileGenerated",
                        label: "generate.conflict.recap.buttons.generate",
                        type: FieldTypeEnum.generateButton,
                        options: { className: 'bg-gray-100 text-black' },
                        validators: ['required'],
                    }} />
            )} */}
      <div>
        <hr />
        <div className='flex justify-between items-center'>
          <Button onClick={_ => navigate(-1)} type="primary" className={`btn btn-link`} ><LeftOutlined />{t('COMMON.back')}</Button>
          <Button disabled={!canUploadContract} htmlType="button" onClick={_ => openModal()} className="bg-alc-blue text-white text-center">Téléverser le contrat signé </Button>
        </div>
      </div>

      <Modal
        title={null}
        closable={true}
        open={modalVisible}
        onOk={() => { }}
        onCancel={_ => setModalVisible(false)}
        footer={null}
      >
        <div>
          <h4 className='underline'>Envoyer votre contrat signé et paraphé :</h4>
          <div className='px-24 py-12'>
            {/* <ContractUploadModal dispatch={dispatch} isLoading={isLoading} handleClose={() => setModalVisible(false)} /> */}
            <Upload
              beforeUpload={uploadContract}
              accept="application/pdf"
              showUploadList={false}>
              <Button disabled={isLoading} className='h-16 bg-gray-50'>
                <div className='flex space-x-2 justify-center items-center'>
                  <div className=''>
                    {isLoading && <SyncOutlined spin />}
                    {!isLoading && <UploadOutlined className='text-2xl text-alc-blue'></UploadOutlined>}
                  </div>
                  <div className='flex flex-col space-y-0 '>
                    <span className='text-alc-gray font-semibold text-lg'>{t("COMMON.FILEUPLOAD.dragndrop")}</span>
                    <span className='text-alc-gray'>{t("COMMON.FILEUPLOAD.dragndrop2")}</span>
                  </div>
                </div>
              </Button>
            </Upload>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CommuneConflictRecap;
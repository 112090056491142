import { ApiListResult, Models } from "@alcome-rep/alcome-types";
import { Prospect } from "@alcome-rep/alcome-types/dist/models";
import { AxiosResponse } from "axios";
import ApiService, { ApiSortType } from "../../common/services/apiService";
import { buildFindQuery, buildSortQuery } from "../../common/tools/global-tools";

const PROSPECT_ADMIN_PATH_API = 'admin/prospect';

class ProspectAdminApi extends ApiService<Prospect> {
  constructor() {
    super(PROSPECT_ADMIN_PATH_API)
  }

  /**
   * @description find all prospects / find all prospects with queryParams
   * @param query
   * @returns prospect list
   */
  findAll({ query, page, limit, sort }: { query: any, page: number, limit: number, sort?: ApiSortType }) {
    const myQuery = { ...query }

    let queryString = buildFindQuery(myQuery);
    if (sort) {
      queryString += buildSortQuery(sort)
    }
    return this.custom<ApiListResult<Models.Prospect>>('get', `?${queryString}&page=${page}&limit=${limit}`)
      .then(r => ({ ...r, rows: r.rows.map(row => new Models.Prospect(row)) }))
  }

  /**
   * @description idk
   * @returns prospects
   */
  getExportProspects(): Promise<AxiosResponse<any, any>> {
    return this.custom('get', 'exportProspects')
  }

  /**
   * @description get a prospect
   * @param prospectId 
   * @returns ProspectDoc
   */
  getProspect = (prospectId: string) => {
    return this.get(prospectId)
      .then(r => new Models.Prospect(r))
  }

  /**
   * @description update a prospect
   * @param prospectId 
   * @param data 
   * @returns Updated prospect
   */
  updateProspect = (prospectId: string, data: any) => {
    return this.update(prospectId, data)
      .then(r => new Models.Prospect(r))
  }

  /**
   * @description Delete a prospect
   * @param prospectId 
   * @returns Deleted prospect
   */
  deleteProspect(prospectId: string) {
    return this.delete(prospectId)
  }

  getAshtrayQuantity(population: number) {
    return this.custom<any>('get', `ashtrayQuantity?population=${population}`)
  }

  calculateStatement(siren: string | string[]) {
    if (Array.isArray(siren)) {
      const query = siren.reduce((acc, s) => acc += `siren[]=${s}&`, '?')
      return this.custom<any>('get', `calculateStatement${query}`)
    } else
      return this.custom<any>('get', `calculateStatement?siren=${siren}`)
  }


}

const prospectAdminApi = new ProspectAdminApi();
export default prospectAdminApi;
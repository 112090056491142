import { AcquisitionItemOrderStatusEnum, AcquisitionOrderItemType, AcquisitionOrderStatusEnum, DispositifCategoryEnum, DispositifTypeEnum, IHotspot, isAshtrayType } from "@alcome-rep/alcome-types/dist/interfaces";
import { IProduct } from "@alcome-rep/alcome-types/dist/interfaces/product";
import { User } from "@alcome-rep/alcome-types/dist/models";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Loader } from "@googlemaps/js-api-loader";
import { Alert, Button, Form, InputNumber, Modal, Select, Table, TableColumnType } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectUser } from "../../../../Auth/state/auth.slice";
import MapComponent from "../../../../Map/components/Gmap/mapComponent";
import mapApi from "../../../../Map/services/map.api";
import ProductSelector from "../../../../Product/components/common/ProductSelector";
import { selectCurrentStructure } from "../../../../Structure/state/structure-portal.slice";
import Section from "../../../../common/components/Panels/Section";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks";
import msg from "../../../../common/services/toast";
import { randomStringId } from "../../../../common/tools/global-tools";
import useQuotas from "../../../hooks/useQuota.hook";
import { selectCurrentAdminAcquisitionOrder, setCurrentAdminAcquisitionOrderAction } from "../../../state/acquisitionOrder-admin.slice";
import { selectCurrentPortalAcquisitionOrder, setCurrentPortalAcquisitionOrderAction } from "../../../state/acquisitionOrder-portal.slice";

const AcquisitionOrderAshtrays = () => {
  const [mapLoaded, setMapLoaded] = useState<boolean>(false)
  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLEMAP_KEY || '',
      libraries: ["drawing", "places"]
    });
    Promise.all(loader.libraries.map(l => loader.importLibrary(l))).then(_ => setMapLoaded(true))
    // loader.load()
  }, [])

  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const acquisitionOrder = useAppSelector(User.IsUserAdmin(user) ? selectCurrentAdminAcquisitionOrder : selectCurrentPortalAcquisitionOrder);
  const structure = useAppSelector(selectCurrentStructure);

  const quotas = useQuotas(acquisitionOrder?.structureId || structure?.id);
  const [isOverQuotas, setIsOverQuotas] = useState<boolean>(false);


  const [items, setItems] = useState<AcquisitionOrderItemType[]>([]);
  const [hotspots, setHotspots] = useState<Array<IHotspot>>([]);
  const [hotspotsCps, setHotspotsCps] = useState<{ [K: string]: number }>({});

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const canEdit = User.IsUserPortal(user) && !acquisitionOrder?.id

  const { t } = useTranslation();

  /* ===== USE EFFECTS ===== */

  // settings items 
  useEffect(() => {
    if (acquisitionOrder) {
      const items = (acquisitionOrder.items ?? []).filter(item => item.category === DispositifCategoryEnum.ashtray)
      setItems(items)
    }
  },
    // eslint-disable-next-line
    [])

  // refresh hotspots list
  useEffect(() => {
    if (!mapLoaded)
      return;
    const newhotspots = items.reduce((list, item) => {
      if (item.hotspot && !list.find(hs => item.hotspotId === hs.id))
        list.push(item.hotspot)
      return list;
    }, [] as IHotspot[])
    setHotspots(newhotspots)
  }, [items, setHotspots, mapLoaded])

  // refresh acquisition order state
  useEffect(() => {
    if (items && (acquisitionOrder && !acquisitionOrder.id)) {
      const newItems = [
        ...items,
        ...(acquisitionOrder?.items || []).filter(item => item.category === DispositifCategoryEnum.extinguisher)
      ];
      dispatch(User.IsUserAdmin(user)
        ? setCurrentAdminAcquisitionOrderAction({ ...acquisitionOrder, items: newItems, status: AcquisitionOrderStatusEnum.acquisition_order_pending })
        : setCurrentPortalAcquisitionOrderAction({ ...acquisitionOrder, items: newItems, status: AcquisitionOrderStatusEnum.acquisition_order_pending })
      )
    }
  },// eslint-disable-next-line  
    [items, dispatch])


  // refresh quotas
  useEffect(() => {
    if (!quotas)
      return
    setIsOverQuotas(items.reduce((total, item) => total = total + (item.hotspot ? item.quantity : 0), 0) > quotas?.available.ashtrays)
  }, [items, quotas])

  const addItem = async (hotspot: IHotspot, isSupport = false) => {
    const hasSupportLine = items.find(item => item.isSupport === true && item.hotspot?.id === hotspot.id) !== undefined
    if (isSupport && hasSupportLine)
      return;

    const newItems = [...items.map(i => ({ ...i }))];
    let position = newItems.findLastIndex((item: any) => item.hotspotId === hotspot.id) + 1;
    // if (!hasSupportLine || isSupport)
    //   position++;
    newItems.splice(position, 0, {
      id: randomStringId().toString(),
      category: DispositifCategoryEnum.ashtray,
      isSupport,
      quantity: 1,
      // type: DispositifTypeEnum.cendrier_mural,
      status: AcquisitionItemOrderStatusEnum.pending,
      hotspotId: hotspot.id,
      hotspot: hotspot,
    } as AcquisitionOrderItemType)

    setItems(newItems)

    if (!hotspotsCps[hotspot.id!]) {
      const newhotspotsCps = { ...hotspotsCps }
      const r = await mapApi.getCollectPoints(structure?.id!, hotspot.id!)
      newhotspotsCps[hotspot.id!] = r.count
      setHotspotsCps(newhotspotsCps)
    }
  }



  const removeItem = (item: AcquisitionOrderItemType) => {
    const newItems = [...items];
    newItems.splice(items.indexOf(item), 1)
    setItems(newItems)
  }

  const columns: TableColumnType<AcquisitionOrderItemType>[] = [

    {
      title: 'Type',
      dataIndex: ['type'],
      render: (v: DispositifTypeEnum, item: AcquisitionOrderItemType) => <Select
        disabled={!canEdit}
        value={v}
        options={Object.values(DispositifTypeEnum)
          .filter(isAshtrayType)
          .map(k => ({ value: k, label: t('TYPES.INVENTORY.DispositifTypeEnum.' + k) }))
        }
        onChange={v => {
          const index = items.findIndex(i => i === item);
          const newItems = [...items.map(i => ({ ...i }))];
          newItems[index].type = v
          setItems(newItems)
        }}
        className="w-64"
      />
    },
    {
      title: 'Quantité',
      dataIndex: 'quantity',
      align: "left",
      render: (_value: number, item: AcquisitionOrderItemType) => {
        return <Form.Item className="m-0" label="Quantité">
          <InputNumber
            value={item.quantity}
            min={1}
            status={isOverQuotas ? "error" : undefined}
            readOnly={!canEdit}
            onChange={e => {
              const index = items.findIndex(i => i === item);
              const newItems = [...items.map(i => ({ ...i }))];
              newItems[index].quantity = e ?? 1
              setItems(newItems)
            }}
          />
        </Form.Item>
      }
    },
    {
      title: 'Financement',
      dataIndex: 'isSupport',
      align: "left",
      render: (isSupport: boolean, item: AcquisitionOrderItemType) => {
        return <Select
          disabled={!canEdit}
          className="w-44"
          options={[{ label: "Soutien financier", value: true }, { label: "Acquisition", value: false }]}
          onChange={v => {
            const newItems = [...items.map(item => ({ ...item }))]
            const newItem = newItems[items.indexOf(item)]
            newItem.isSupport = v
            setItems(newItems)
          }}
          value={isSupport}
        />
      }
    },
    {
      title: 'Produit',
      dataIndex: ['product'],
      render: (product: IProduct, item: AcquisitionOrderItemType) => {
        if (item.isSupport) {
          return null
        } else {
          return canEdit ? <ProductSelector
            product={product}
            type={item.type}
            category={DispositifCategoryEnum.ashtray}
            productSelectedCb={product => {
              const newItems = [...items.map(i => ({ ...i }))];
              const newItem: AcquisitionOrderItemType | undefined = newItems.find(i => i.id === item?.id);
              if (!newItem)
                return;
              const doublon = newItems.find(i => i.isSupport === false && i.category === DispositifCategoryEnum.ashtray && i.product && i.hotspotId === newItem.hotspotId && i.id !== newItem.id && i.product.id === product.id);
              if (doublon) {
                doublon.quantity += newItem.quantity
                newItems.splice(newItems.indexOf(newItem), 1)
                msg.info("Ce produit est déjà présent pour ce hotspot. Les lignes ont été réunies.")
              } else {
                newItem.product = product
                newItem.price = product.price
                newItem.type = product.type
                // newItem.category = product.category
              }
              setItems(newItems)
            }}
          />
            : <span>{product.name}</span>
        }
      }
    },
    {
      title: '', key: 'delete', render: (item: AcquisitionOrderItemType) => canEdit && <Button danger type="link" onClick={_ => removeItem(item)} icon={<DeleteOutlined />} />
    },
  ];

  // const getHotspotInfos = async (hotspot: IHotspot) => {
  //   const cps = await mapApi.getCollectPoints(structure?.id!, hotspot.id)
  //   return cps;
  // }

  return <>
    {canEdit && (
      <Section>
        Cliquez sur le lien <Button type="link" className="px-0" icon={<PlusOutlined />} onClick={_ => setIsModalOpen(true)}>Sélectionner un hotspot</Button>. Une fenêtre vous permettra de sélection un puis plusieurs hotspots. Pour sélectionner les hotspots cliquez simplement dessus. Une fois votre sélection réalisée cliquez sur le bouton "Valider ma sélection" et renseignez le nombre de cendriers de rue souhaité pour chaque hotspot.
      </Section>
    )}
    <Modal
      className="h-dvh !w-10/12 !lg:w-9/12"
      title="Sélectionnez les hotspots"
      open={isModalOpen}
      onCancel={_ => setIsModalOpen(false)}
      cancelText={'Annuler'}
      okButtonProps={{ style: { display: 'none' } }}
      destroyOnClose={true}
    >
      <p>Cliquez sur les hotspots pour lesquels vous souhaitez demander un soutien pour l'installation d'un dispositif de rue. Une fois les hotspots sélectionnés cliquez sur "Valider ma sélection".</p>
      <div className="container">
        {isModalOpen && <MapComponent
          structure={structure!}
          editMode="selectUniqueHotspot"
          onSelectionChanged={selection => {
            setTimeout(() => {
              setIsModalOpen(false)
              if (selection.length) {
                addItem(selection[0] as IHotspot);
                msg.info('Hotspot ajouté à la liste')
              }
            }, 600);
          }}
          initialSelection={hotspots}
        />}
      </div>
    </Modal>

    {canEdit && isOverQuotas && <Alert message="Quota de cendriers atteint." className="my-2" banner type="error" />}

    {(!hotspots || !hotspots.length) && <Alert className="mb-4" message="Aucun hotspot sélectionné" />}

    {hotspots && hotspots.map(hotspot => <Section
      key={hotspot.id}
      title={`Hotspot ${hotspot.name} – ${hotspot.surface} m2 ${hotspotsCps[hotspot.id!] ? `(${hotspotsCps[hotspot.id!]} dispositif(s)), 1 pour ${(hotspot.surface / hotspotsCps[hotspot.id!]).toFixed()} m2` : ''} `}
      mode="transparent"
    >
      <Table
        className="hotspots-table"
        columns={columns}
        rowKey={'id'}
        size="small"
        dataSource={items.filter(item => item.hotspotId === hotspot.id)}
        pagination={false}
        footer={() =>
          <div className="flex justify-end">
            {canEdit && <Button icon={<PlusOutlined />} type="link" onClick={_ => addItem(hotspot)}>Ajouter un dispositif</Button>}
          </div>
        }
      />
    </Section>
    )}

    <div className="flex justify-between">
      <div>{canEdit && <Button type="primary" icon={<PlusOutlined />} onClick={_ => setIsModalOpen(true)}>Sélectionner un hotspot</Button>}</div>
    </div>



  </>
}

export default AcquisitionOrderAshtrays
import { useEffect, useState } from "react"
import { Link, Outlet, useLocation } from "react-router-dom"
import InventoryStatus from "../../Inventory/components/admin/InventoryData/InventoryStatus"
import { selectCurrentInventory } from "../../Inventory/state/inventory-portal.slice"
import { selectCurrentStructure } from "../../Structure/state/structure-portal.slice"
import LoadingScreen from "../../common/components/LoadingScreen"
import Figure from "../../common/components/Panels/Figure"
import NavTabs from "../../common/components/Panels/NavTabs"
import PageCanvas from "../../common/components/Panels/PageCanvas"
import { useAppSelector } from "../../common/hooks"
import { ROUTES } from "../../common/router"
import { getRoute } from "../../common/tools/router-tools"
import ContractStatus from "../components/admin/ContractData/ContractStatus"
import { selectCurrentContract } from "../state/contract-portal.slice"
import { selectUser } from "../../Auth/state/auth.slice"
import { User } from "@alcome-rep/alcome-types/dist/models"
import { selectCurrentAdminContract } from "../state/contract-admin.slice"
import { selectCurrentAdminStructure } from "../../Structure/state/structure-admin.slice"
import { selectCurrentAdminInventory } from "../../Inventory/state/inventory-admin.slice"
import { Models } from "@alcome-rep/alcome-types"
import { ContractStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces"
import Section from "../../common/components/Panels/Section"

const ContractLayout = () => {

  const user = useAppSelector(selectUser);
  const contract = useAppSelector(User.IsUserAdmin(user) ? selectCurrentAdminContract : selectCurrentContract)
  const structure = useAppSelector(User.IsUserAdmin(user) ? selectCurrentAdminStructure : selectCurrentStructure)
  const inventory = useAppSelector(User.IsUserAdmin(user) ? selectCurrentAdminInventory : selectCurrentInventory)

  const adminDefaultBreadCrumbs = [
    ROUTES.ADMIN_USER_ROUTES.home,
    ROUTES.ADMIN_STRUCTURE_ROUTES.structureDetails,
  ]
  const portalDefaultBreadCrumbs = [
    ROUTES.PORTAL_CONTRACT_ROUTES.dashboard,
    ROUTES.PORTAL_CONTRACT_ROUTES.contractDetails,
  ]
  const defaultBreadCrumbs = User.IsUserAdmin(user) ? adminDefaultBreadCrumbs : portalDefaultBreadCrumbs

  const [breadcrumbs, setBreadcrumbs] = useState<{ path: string; title: string; }[]>(defaultBreadCrumbs)

  const { pathname } = useLocation();

  const tabsRoutes = Models.User.IsUserAdmin(user) ?
    [
      { ...ROUTES.ADMIN_CONTRACT_ROUTES.contractDetails, params: { structureId: structure?.id } },
      { ...ROUTES.ADMIN_CONTRACT_ROUTES.inventoryDetails, params: { structureId: structure?.id } },
      { ...ROUTES.ADMIN_CONTRACT_ROUTES.amendmentsDetails, params: { structureId: structure?.id } },
    ]
    : [
      { ...ROUTES.PORTAL_CONTRACT_ROUTES.contractDetails, disabled: contract?.status! < ContractStatusEnum.validated },
      { ...ROUTES.PORTAL_CONTRACT_ROUTES.inventoryDetails, disabled: contract?.status! < ContractStatusEnum.validated },
      { ...ROUTES.PORTAL_CONTRACT_ROUTES.amendmentsDetails, disabled: contract?.status! < ContractStatusEnum.validated }
    ]

  useEffect(() => {

    switch (pathname) {
      case getRoute(ROUTES.PORTAL_CONTRACT_ROUTES.contractDetails):
        setBreadcrumbs([
          ROUTES.PORTAL_CONTRACT_ROUTES.dashboard,
          ROUTES.PORTAL_CONTRACT_ROUTES.contractDetails,
        ])
        break;
      case getRoute(ROUTES.PORTAL_CONTRACT_ROUTES.inventoryDetails):
        setBreadcrumbs([
          ROUTES.PORTAL_CONTRACT_ROUTES.dashboard,
          ROUTES.PORTAL_CONTRACT_ROUTES.contractDetails,
          ROUTES.PORTAL_CONTRACT_ROUTES.inventoryDetails,
        ])
        break;
      case getRoute(ROUTES.PORTAL_CONTRACT_ROUTES.amendmentsDetails):
        setBreadcrumbs([
          ROUTES.PORTAL_CONTRACT_ROUTES.dashboard,
          ROUTES.PORTAL_CONTRACT_ROUTES.contractDetails,
          ROUTES.PORTAL_CONTRACT_ROUTES.amendmentsDetails,
        ])
        break;

      case getRoute(ROUTES.ADMIN_CONTRACT_ROUTES.contractDetails):
        setBreadcrumbs([
          ROUTES.ADMIN_USER_ROUTES.home,
          ROUTES.ADMIN_CONTRACT_ROUTES.contractDetails,
        ])
        break;
      case getRoute(ROUTES.ADMIN_CONTRACT_ROUTES.inventoryDetails):
        setBreadcrumbs([
          ROUTES.PORTAL_CONTRACT_ROUTES.dashboard,
          ROUTES.PORTAL_CONTRACT_ROUTES.contractDetails,
          ROUTES.PORTAL_CONTRACT_ROUTES.inventoryDetails,
        ])
        break;
      case getRoute(ROUTES.ADMIN_CONTRACT_ROUTES.amendmentsDetails):
        setBreadcrumbs([
          ROUTES.ADMIN_USER_ROUTES.home,
          ROUTES.ADMIN_CONTRACT_ROUTES.contractDetails,
          ROUTES.ADMIN_CONTRACT_ROUTES.amendmentsDetails,
        ])
        break;
      default:
        break;
    }
  }, [setBreadcrumbs, pathname])

  if (!structure || !contract || !inventory) {
    return <LoadingScreen />
  }

  return <>
    <PageCanvas
      params={{ structureId: structure.id }}
      breadCrumbs={breadcrumbs}
      title={<div>
        <div>Détails du contrat</div>
        <div className='text-sm'><Link to={User.IsUserAdmin(user) ?
          getRoute(ROUTES.ADMIN_STRUCTURE_ROUTES.structureDetails, { structureId: contract.structureId })
          : getRoute(ROUTES.PORTAL_CONTRACT_ROUTES.dashboard)
        }
        >{structure.name}</Link></div>
      </div>}
    >
      <div className="grid grid-cols-1 gap-6">
        <div className="grid grid-cols-4 gap-4">
          <Figure title="Statut du contrat" value={<ContractStatus contract={contract} />} />
          <Figure title="Statut des annexes" value={<InventoryStatus inventory={inventory} />} />
        </div>

        <Section>
          <NavTabs tabRoutes={tabsRoutes} />
        </Section>

        <Outlet />
      </div>
    </PageCanvas>
  </>
}

export default ContractLayout
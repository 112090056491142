import { IProduct } from "@alcome-rep/alcome-types/dist/interfaces/product";
import ApiService, { ApiSortType } from "../../common/services/apiService";
import apiClient from "../../common/services/apiClient";

const PRODUCT_PATH_API = 'product';

class ProductApi extends ApiService<IProduct> {
    constructor() {
        super(PRODUCT_PATH_API)
    }

    /**
     * @description find all products
     * @param query
     * @returns products list
     */
    findAllProducts(query: any, page: number, limit: number, sort?: ApiSortType) {
        return this.find({ query, page, pageSize: limit, sort })
    }

    /**
     * @description get a product
     * @param productId 
     * @returns Product
     */
    getProduct(productId: string) {
        return this.get(productId)
    }

    /**
     * @description get a product image
     * @param productId 
     * @param path 
     * @returns Product image buffer
     */
    async getProductImage(productId: string, path: string): Promise<Blob> {
        return apiClient({
            url: `${PRODUCT_PATH_API}/${productId}/image/?path=${path}`,
            method: 'get',
            responseType: 'blob'
        }).then(r => r.data)
    }

}

const productApi = new ProductApi();
export default productApi;
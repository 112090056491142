// import { all, fork } from 'redux-saga/effects';

import { all, fork } from 'redux-saga/effects';
import { acquisitionAdminSaga } from '../../AcquisitionOrder/state/acquisitionOrder-admin.ducks';
import { acquisitionSaga } from '../../AcquisitionOrder/state/acquisitionOrder-portal.ducks';
import { annualreviewAdminSaga } from '../../AnnualReview/state/annual-review-admin.ducks';
import { annualreviewPortalSaga } from '../../AnnualReview/state/annual-review-portal.ducks';
import { authSaga } from "../../Auth/state/auth.ducks";
import { contractAdminSaga } from '../../Contract/state/contract-admin.ducks';
import { contractPortalSaga } from '../../Contract/state/contract-portal.ducks';
import { prospectAdminSaga } from '../../Crm/state/prospect-admin.ducks';
import { InventoryAdminSaga } from '../../Inventory/state/inventory-admin.ducks';
import { InventoryPortalSaga } from '../../Inventory/state/inventory-portal.ducks';
import { structureAdminSaga } from '../../Structure/state/structure-admin.ducks';
import { structurePortalSaga } from '../../Structure/state/structure-portal.ducks';

export default function* rootSaga() {
  yield all([fork(authSaga)]);

  yield all([fork(structurePortalSaga)]);
  yield all([fork(structureAdminSaga)]);

  yield all([fork(contractPortalSaga)]);
  yield all([fork(contractAdminSaga)]);

  yield all([fork(InventoryPortalSaga)]);
  yield all([fork(InventoryAdminSaga)]);

  // yield all([fork(annualreviewSaga)]);
  yield all([fork(annualreviewAdminSaga)]);
  yield all([fork(annualreviewPortalSaga)]);

  yield all([fork(acquisitionSaga)]);
  yield all([fork(acquisitionAdminSaga)]);

  yield all([fork(prospectAdminSaga)]);
}

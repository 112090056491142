import { Models, hasFeatureAccess } from "@alcome-rep/alcome-types";
import { IStructure } from "@alcome-rep/alcome-types/dist/interfaces";
import { IUser, UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { EditOutlined, MoreOutlined, UserOutlined } from "@ant-design/icons";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { Button, Dropdown, MenuProps, message } from "antd";
import { ColumnsType } from "antd/es/table";
import confirm from 'antd/lib/modal/confirm';
import { TFunction } from "i18next";
import { NavigateFunction } from "react-router-dom";
import authApi from "../../../../Auth/services/auth.api";
import { resetLoginAsAction, setLoginAsAction } from "../../../../Auth/state/auth.slice";
import FeatureAvailable from "../../../../common/components/FeaturesAvailable/FeatureAvailable";
import { ROUTES } from "../../../../common/router";
import { getHomeRoute } from "../../../../common/tools/router-tools";
import userAdminApi from "../../../services/user-admin.api";
import msg from "../../../../common/services/toast";


const loginAs = (dispatch: Dispatch<AnyAction>, userId: string, userType: UserTypeEnum, userFullName?: string) => {
  confirm({
    title: 'Connexion',
    icon: <UserOutlined />,
    content: `Voulez-vous vous connecter en tant que ${userFullName} ?`,
    cancelText: 'Annuler',
    closable: true,
    okCancel: true,
    okText: 'Oui',
    okButtonProps: { className: "btn" },
    async onOk() {
      if (userType !== UserTypeEnum.admin)
        dispatch(resetLoginAsAction({ userType: userType }))
      const auth = await authApi.loginAs(userId)
      dispatch(setLoginAsAction(auth))
      if (userType !== UserTypeEnum.admin)
        window.open(getHomeRoute(auth.user.userType), "_blank", "noreferrer");
      else
        msg.success("Vous êtes connecté en tant que utilisateur Admin: " + auth.user.email)
    },
    onCancel() {
    },
  });

}

const invalidateToken = (userId: string, userFullName?: string) => {
  confirm({
    title: 'Jeton de connexion',
    icon: <UserOutlined />,
    content: `Êtes-vous sûr de vouloir invalider le jeton de ${userFullName} ?`,
    cancelText: 'Annuler',
    closable: true,
    okCancel: true,
    okText: 'Oui',
    okButtonProps: { className: "btn" },
    onOk() {
      userAdminApi.invalidateToken(userId).then(_ => message.success("Jeton invalidé"))
    },
    onCancel() {
    },
  });

}

export const userListColumns = (t: TFunction, navigate: NavigateFunction, dispatch: Dispatch<AnyAction>, user: IUser, structure: IStructure, userType: UserTypeEnum) => {

  const cols: ColumnsType<IUser> = [
    {
      width: '80px',
      fixed: 'left',
      align: 'center',
      render: (value: any) => {
        const canLoginAs = hasFeatureAccess('admin.structure.loginAs', { userRole: user?.role, structureType: structure?.structureType })
        const canInvalidateToken = hasFeatureAccess('admin.structure.invalidateToken', { userRole: user?.role, structureType: structure?.structureType })

        const items: MenuProps['items'] = []
        if (canLoginAs)
          items.push({
            key: 'loginAs',
            label: <FeatureAvailable userType={UserTypeEnum.admin} featureName='admin.structure.loginAs'>
              <Button
                type="link"
                onClick={_ => {
                  const userFullName = `${value.firstName} ${value.lastName}`
                  return loginAs(dispatch, value.id, value.userType, userFullName)
                }}
                icon={<UserOutlined />}
              >
                Se connecter en tant que...
              </Button>
            </FeatureAvailable>
          })
        if (canInvalidateToken) {
          items.push(
            {
              key: 'invalidateToken',
              label: <FeatureAvailable userType={UserTypeEnum.admin} featureName='admin.structure.invalidateToken'>
                <Button
                  type="link"
                  onClick={_ => {
                    const userFullName = `${value.firstName} ${value.lastName}`
                    return invalidateToken(value._id, userFullName)
                  }}
                  icon={<UserOutlined />}
                >
                  Invalider le jeton de connexion
                </Button>
              </FeatureAvailable>
            }
          )
        }

        return (
          <div className='flex space-x-4 items-center'>

            <Dropdown menu={{ items }}>
              <Button className="btn-link text-alc-blue font-semibold text-lg px-0" onClick={e => e.stopPropagation()}>
                <MoreOutlined />
              </Button>
            </Dropdown>

            <Button className="btn-link text-alc-blue  px-0" onClick={(e) => {
              navigate(`/${ROUTES.ADMIN_USER_ROUTES.userDetails.path.replace(/:userId/, value.id)}`);
            }}>
              <EditOutlined className='font-semibold text-lg' />
            </Button>
          </div>
        )
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      fixed: 'left',
      key: 'email',
      align: 'center',
    },
    {
      title: 'Nom',
      dataIndex: 'lastName',
      key: 'lastName',
      align: 'center',
      className: "font-semibold"
    },
    {
      title: 'Prénom',
      dataIndex: 'firstName',
      key: 'firstName',
      align: 'center',
    },
    {
      title: 'Téléphone',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
    },
    {
      title: 'Fonction',
      dataIndex: 'position',
      key: 'position',
      align: 'center',
    },
    {
      title: 'Structure',
      hidden: userType !== UserTypeEnum.portal,
      dataIndex: ['structure', 'name'],
      key: 'structureName',
      align: 'center',

    },
    {
      title: 'Nom de l\'entreprise',
      hidden: userType !== UserTypeEnum.supplier,
      dataIndex: ['supplierCompany', 'name'],
      key: 'supplierCompany',
      align: 'center',

    },
    {
      title: 'Rôle',
      key: 'role',
      align: 'center' as const,
      filterMultiple: false,
      render: (value: any) => {
        return <div className=''>{t(`TYPES.ROLES.${userType.toUpperCase()}.${Models.User.GetUserRoleKey(value)}`)}</div>
      }
    },

  ]

  const indexes = [5, 6];
  const supplierCols = cols.filter((_, index) => !indexes.includes(index));

  return userType === UserTypeEnum.supplier ? supplierCols : cols;
};
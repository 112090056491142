import { ICrmFileDocument } from '@alcome-rep/alcome-types/dist/interfaces/crm';
import { DeleteOutlined, EyeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Table, TablePaginationConfig, Tag } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { FilterValue } from 'antd/lib/table/interface';
import { RcFile } from 'antd/lib/upload';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import AdminFileField from '../../../common/components/admin/AdminFileField/AdminFileField';
import Section from '../../../common/components/Panels/Section';
import { useAppSelector } from '../../../common/hooks';
import crmFileDocAdminApi from '../../../FileDocument/services/crmFileDocument-admin.api';
import { selectCurrentAdminProspect } from '../../state/prospect-admin.slice';
import crmFileDocApi from '../../../FileDocument/services/crmFileDocument.api';

const ProspectFiles = ({ actionId }: { actionId?: string }) => {

  const DEFAULT_PAGE_SIZE = 10;
  const [data, setData] = useState<ICrmFileDocument[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setloading] = useState<boolean>(false);

  const [visible, setVisible] = useState(false);
  const iFrame = useRef(null);

  const { t } = useTranslation()
  const prospect = useAppSelector(selectCurrentAdminProspect);
  if (!prospect)
    throw new Error()

  let [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);

  const { current = 1, pageSize = DEFAULT_PAGE_SIZE, ...query } = params;

  const memoQuery = useMemo(() => {
    return JSON.stringify(query)
  }, [query])

  const getData = async (
    pagination: TablePaginationConfig = { pageSize: Number(pageSize), current: Number(current) },
    filters: Record<string, FilterValue | null>,
  ) => {
    const q = Object.keys(filters).reduce((acc, k) => {
      if (filters[k] !== null)
        acc[k] = filters[k];
      return acc;
    }, {} as any)

    setSearchParams({ current: (pagination.current || 1).toString(), pageSize: (pagination.pageSize || 50).toString(), ...q })
  }

  const getAllFiles = useMemo(() => () => {

    const actionQuery = actionId ? { ...JSON.parse(memoQuery), action: actionId } : { ...JSON.parse(memoQuery) }

    if (prospect) {
      setloading(true)
      crmFileDocApi.findCrmDocs(
        {
          page: Number(current),
          pageSize: Number(pageSize),
          query: { actionQuery, prospectId: prospect.id }
        }
      ).then(res => {
        setData(res.rows)
        setCount(res.count)
        setloading(false)
      })
    }
  }, [current, pageSize, memoQuery, prospect, actionId])

  useEffect(() => {
    getAllFiles()
  }, [setData, setCount, setloading, getAllFiles]);

  const downloadDoc = (file: any) => async () => {
    setVisible(true)
    const res = await crmFileDocAdminApi.downloadCrmDoc(file.id)
    const blobContent = new Blob([res], { type: "application/pdf" });
    if (iFrame.current !== null) {
      (iFrame.current as HTMLIFrameElement).src = URL.createObjectURL(blobContent);
    }
    return
  }

  const uploadFile = (propertyName: 'prospectFile') => async (file: RcFile) => {
    // const res = await crmFileDocAdminApi.uploadCrmDoc({ entityId: prospect.id!, propertyName, file, actionId })
    const res = await crmFileDocApi.uploadCrmDoc({ entityId: prospect.id!, propertyName, file, actionId })
    getAllFiles()
    return res;
  }

  const deleteFile = (value: any) => {
    if (prospect) {
      confirm({
        title: `Voulez-vous supprimer le fichier ${value.timestamp}${value.extension} ?`,
        icon: <QuestionCircleOutlined />,
        cancelText: 'Annuler',
        closable: true,
        okCancel: true,
        okText: 'Oui',
        okButtonProps: { className: "btn" },
        onOk() {
          crmFileDocAdminApi.deleteCrmDoc(value.id)
            .then(res => {
              getAllFiles()
            })
        },
        onCancel() {
        },
      });
    }
  }

  const columns = [
    {
      title: "Détails ",
      dataIndex: "action",
      key: "action",
      render: (value: any) => {
        if (!value) {
          return <span> <Tag color='blue'>Fichier rattaché à aucune action</Tag>  </span>
        } else {
          let date = format(new Date(value.actionDate as Date), "d MMMM yyyy HH:mm", { locale: fr });
          return <span> <Tag color='green'>{t(`TYPES.CRM.ACTIONS.ActionTypeEnum.${value.type}`)} {date}</Tag></span>
        }
      }
    },
    {
      title: "Type de fichier",
      dataIndex: "propertyName",
      key: "propertyName",
      render: (value: any) => {
        if (value) {
          return <span>{t(`TYPES.FILEDOCUMENT.${value}`)}</span>
        } else {
          return <span>-</span>
        }
      }
    },
    {
      title: "Nom du fichier",
      render: (value: any) => {
        if (value) {
          return (<>
            <Button className='btn-link text-alc-blue' onClick={downloadDoc(value)} icon={<EyeOutlined />}>{value.timestamp}.pdf</Button>
          </>)
        } else {
          return <span>-</span>
        }
      }
    },
    {
      title: "Actions",
      render: (value: any) => {
        if (value) {
          return (<>
            <Button className='btn-link text-alc-danger font-semibold text-lg' onClick={_ => deleteFile(value)}>
              <DeleteOutlined />
            </Button>
          </>)
        } else {
          return <span>-</span>
        }
      }
    }
  ]
  return (
    <Section
      title="Liste des fichiers"
      actions={<AdminFileField
        propertyName='Fichier'
        upload={uploadFile('prospectFile')}
      ></AdminFileField>}
    >
      <Modal
        title="Fichier"
        styles={{ body: { height: 800 } }}
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={1000}
        footer={null}
      >
        <iframe title="prospect" ref={iFrame} className="w-full h-full" ></iframe>
      </Modal>

      <div>
        <Table<ICrmFileDocument>
          style={{ cursor: 'pointer' }}
          columns={columns}
          dataSource={data}
          loading={loading}
          onChange={getData}
          rowKey="id"
          size='small'
          pagination={{
            pageSize: 10,
            total: count,
            hideOnSinglePage: true
          }}
        />
      </div>
    </Section>
  );
};

export default ProspectFiles;
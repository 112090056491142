import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../../common/hooks";
import { ROUTES } from "../../common/router";
import { getRoute } from "../../common/tools/router-tools";
import { selectCurrentContract } from "../state/contract-portal.slice";

const ContractFileGuard = ({ children }: any) => {
  const contract = useAppSelector(selectCurrentContract);

  if (contract && !contract.contractFileId)
    return <Navigate to={getRoute(ROUTES.PORTAL_REGISTER_CONTRACT_ROUTES.start)}></Navigate>

  if (children)
    return <>{children}</>

  return <Outlet />
}

export default ContractFileGuard;
import { AnnualReviewStatusEnum, IAnnualReview } from '@alcome-rep/alcome-types/dist/interfaces'
import { CloseOutlined } from '@ant-design/icons'
import { Button, DatePicker, Form } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import TextArea from 'antd/lib/input/TextArea'
import dayjs from 'dayjs'
import DateField from '../../../../common/components/Fields/DateField'
import Section from '../../../../common/components/Panels/Section'
import useFormRules from '../../../../common/hooks/useFormRules'
import { Models } from '@alcome-rep/alcome-types'
import { useAppSelector } from '../../../../common/hooks'
import { selectUser } from '../../../../Auth/state/auth.slice'
import { User } from '@alcome-rep/alcome-types/dist/models'
import { useState } from 'react'
import AddButton from '../../../../common/components/Buttons/addButton'

type PrecautionTableComponentType = {
  annualReview: Models.AnnualReview
}

const PrecautionTableComponent = ({ annualReview }: PrecautionTableComponentType) => {
  const user = useAppSelector(selectUser);
  const admin = User.IsUserAdmin(user)
  const canEdit = !admin && annualReview && annualReview.status <= AnnualReviewStatusEnum.refused ? true : false

  const { required } = useFormRules()

  const [collapsed, setCollapsed] = useState<boolean>(true);

  const form = useFormInstance<IAnnualReview>()
  const precautionaryMeasure = useWatch('precautionaryMeasure', form) || (annualReview.precautionaryMeasure ?? [])

  const ListItemHasError = (fieldName: string) => {
    return form.getFieldsError()
      .filter(f => f.errors.length)
      .map(f => f.name.join('.'))
      .find(f => f.includes(fieldName))
      !== undefined
  }

  return (
    <Form.List name={'precautionaryMeasure'}>
      {(fields, { add, remove }, { errors }) => (
        <div className="grid grid-cols-1 gap-4">
          {fields.map(({ key, name }) => (
            <Section
              key={key}
              mode="collapsible"
              collapsed={collapsed}
              titleClass={ListItemHasError('precautionaryMeasure.' + name) ? '!text-alc-danger' : ''}
              title={<span><DateField value={(precautionaryMeasure[name].measureDate)} /></span>}
              actions={canEdit && <Button
                type='text'
                size='small'
                onClick={_ => remove(name)}
                icon={<CloseOutlined className='text-alc-danger' readOnly={!canEdit}></CloseOutlined>}>
              </Button>}
            >
              <Form.Item label="Date de la mesure" name={[name, 'measureDate']} rules={[required]}>
                <DatePicker className='w-full' readOnly={!canEdit} format={'DD/MM/YYYY'} />
              </Form.Item>

              <Form.Item label="Description" name={[name, 'description']} rules={[required]} className="mb-0">
                <TextArea readOnly={!canEdit} rows={3} />
              </Form.Item>
            </Section>
          ))}

          {canEdit && <AddButton onClick={() => { add({ measureDate: dayjs() }); setCollapsed(false) }}>Ajouter une mesure préventive</AddButton>}
        </div>
      )}
    </Form.List>
  )
}

export default PrecautionTableComponent
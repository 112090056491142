
/* ======== CONSTANTES ======== */

import { Models } from "@alcome-rep/alcome-types";
import { IContract } from "@alcome-rep/alcome-types/dist/interfaces";

/* ======== TYPES ======== */
export type ContractServiceState = {
  currentContract: Models.Contract | null,
};

export const CONTRACT_PORTAL_SERVICE = "contractPortalService";
// eslint-disable-next-line
export type CONTRACT_PORTAL_SERVICE = typeof CONTRACT_PORTAL_SERVICE; // Typescript line

export const CONTRACT_ADMIN_SERVICE = "contractAdminService";
// eslint-disable-next-line
export type CONTRACT_ADMIN_SERVICE = typeof CONTRACT_ADMIN_SERVICE; // Typescript line


/* ======== PAYLOADS ======== */

export type GetContractPayload = {
  id: string
}


export type AdminUpdateContractPayload = {
  data: Partial<IContract>,
  id: string,
}

import { DispositifTypeEnum, IStructure, MapStats, PlaceTypeEnum, SoilingEnum } from "@alcome-rep/alcome-types/dist/interfaces"
import { DownOutlined, UpOutlined } from "@ant-design/icons"
import { Button, List } from "antd"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { typedKeys } from "../../../common/tools/global-tools"
import mapApi from "../../services/map.api"
import Section from "../../../common/components/Panels/Section"
import LoadingScreen from "../../../common/components/LoadingScreen"

type PropsType = {
  structure: IStructure
  mapStats?: MapStats
}

const MapStatsComponent = ({ structure, mapStats }: PropsType) => {

  const [viewDetails, setViewDetails] = useState<boolean>(false)
  const [componentMapStats, setComponentMapStats] = useState<MapStats | undefined>(mapStats);

  const { t } = useTranslation()

  useEffect(() => {
    if (structure.id && !componentMapStats) {
      mapApi.getStats(structure.id).then(r => setComponentMapStats(r))
    }else{
      return;
    }
  }, [structure, componentMapStats])

  if(!componentMapStats) return <LoadingScreen />;

  return (

    <Section title="Résumé données cartographiques" mode="transparent" className="my-4">
      <div className="grid grid-cols-1">

        <p className="mb-0">Nombre de points de collecte : <span className="font-semibold">{componentMapStats?.total.collectPoints.total ?? 0}</span></p>
        <p className="mb-4">
          Nombre de hotspots : <span className="font-semibold"> {componentMapStats?.total.hotspots.total.count ?? 0}
          </span> pour une surface total de <span className="font-semibold">{componentMapStats?.total.hotspots.total.surface ?? 0} m²</span>
        </p>

        <Button icon={viewDetails === false ? <DownOutlined /> : <UpOutlined />}
          className="text-black font-semibold" htmlType="button" onClick={() => setViewDetails(!viewDetails)}>
          {viewDetails === false ? 'Voir plus de données' : 'Masquer'}
        </Button>

        {viewDetails === true && (
          <div className="grid grid-cols-2 gap-6 mt-3">
            <List
              header={<div className="font-semibold">Type de lieux des points de collecte</div>}
              dataSource={typedKeys<PlaceTypeEnum>(PlaceTypeEnum)}
              size="small"
              renderItem={placeType => (
                <List.Item>
                  {t('TYPES.INVENTORY.PlaceTypeEnum.' + placeType)}: <span className="font-semibold">{componentMapStats?.[placeType]?.collectPoints.total ?? 0}</span>
                </List.Item>
              )}
            />

            <List
              header={<div className="font-semibold">Dispositif de collecte</div>}
              dataSource={typedKeys<DispositifTypeEnum>(DispositifTypeEnum)}
              size="small"
              renderItem={dispositif => (
                <List.Item>
                    {t('TYPES.INVENTORY.DispositifTypeEnum.' + dispositif)}: <span className="font-semibold">{componentMapStats?.total.collectPoints[dispositif] ?? 0}</span>
                </List.Item>
              )}
            />

            <List
              header={<div className="font-semibold">Type de lieux des hotspots</div>}
              dataSource={typedKeys<PlaceTypeEnum>(PlaceTypeEnum)}
              size="small"
              renderItem={placeType => (
                <List.Item>
                  {t('TYPES.INVENTORY.PlaceTypeEnum.' + placeType)}: <span className="font-semibold">{componentMapStats?.[placeType]?.hotspots.total.count ?? 0}</span>
                </List.Item>
              )}
            />

            <List
              header={<div className="font-semibold">Propreté des hotspots</div>}
              dataSource={typedKeys<SoilingEnum>(SoilingEnum)}
              size="small"
              renderItem={soiling => (
                <List.Item>
                  {t('TYPES.INVENTORY.SoilingEnum.' + soiling)}: <span className="font-semibold">{componentMapStats?.total.hotspots[soiling]?.count ?? 0}</span>
                </List.Item>
              )}
            />
          </div>
        )}
      </div>
    </Section>
  );
}

export default MapStatsComponent;
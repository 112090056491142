import { IUserAdmin } from "@alcome-rep/alcome-types/dist/interfaces/user"

const RegionalDelegateSection = ({ regionalDelegate, department, isPortal }: { regionalDelegate: IUserAdmin, department: string, isPortal?: boolean }) => {

  return (
    <div>
      {isPortal ? (
        <h6>Votre délégué régional</h6>
      ) :
        <h6>Délégué régional</h6>
      }
      <div className=''>
        {regionalDelegate.firstName} {regionalDelegate.lastName} est en charge du département du {department}
      </div>
      <div>
        <span className='font-semibold'>Email :</span> <a className="text-alc-blue" href={`mailto:${regionalDelegate.email}`}>{regionalDelegate.email}</a>
      </div>
      {(regionalDelegate as any).phone && (
        <div>
          <span className='font-semibold'>Téléphone :</span> <a className="text-alc-blue" href={`tel:${(regionalDelegate as any).phone}`}>{(regionalDelegate as any).phone}</a>
        </div>
      )}
    </div>
  )
}

export default RegionalDelegateSection
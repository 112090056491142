import { ContactStatusEnum, ContactTypeEnum, IContact } from '@alcome-rep/alcome-types/dist/interfaces/crm';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Radio } from 'antd';
import { useForm } from 'antd/es/form/Form';
import confirm from 'antd/lib/modal/confirm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as reactPhone from 'react-phone-number-input/input';
import useFormRules from '../../../common/hooks/useFormRules';
import contactAdminApi from '../../services/contact-admin.api';
import { closeDrawerAction } from '../../../common/state/modal.slice';
import { useAppDispatch } from '../../../common/hooks';

type PropsType = {
  contact?: IContact,
  formCb?: (data?: FormType | null) => void,
  prospectId:string
}

type FormType = Partial<IContact>

const ContactInfosForm = ({ formCb, contact, prospectId }: PropsType) => {

  const [value, setValue] = useState()
  const dispatch = useAppDispatch()

  const { t } = useTranslation()
  const [form] = useForm()
  const { required } = useFormRules()


  const deleteContact = async () => {
    confirm({
      title: 'Voulez-vous supprimer le contact ?',
      icon: <QuestionCircleOutlined />,
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okText: 'Oui',
      okButtonProps: { className: "btn" },
      async onOk() {
        if (contact) {
          await contactAdminApi.deleteContact(contact.id!)
          formCb && formCb(null)
        }
      },
      onCancel() {
      },
    });
  }

  const update = async (contactData: any) => {
    if (contact) {
      const updatedContact = await contactAdminApi.updateContact(contact.id!, {...contactData, prospectId})
      formCb && formCb(updatedContact)
    }
    else {
      const newContact = await contactAdminApi.create({...contactData, prospectId})
      formCb && formCb(newContact)
    }
    dispatch(closeDrawerAction())
  }

  return (
    <>
      <div>
        <Form
          name="basic"
          layout='vertical'
          onFinish={update}
          form={form}
          initialValues={contact ?? {}}
        >
          <Form.Item
            label="Type de contact"
            name='type'
            rules={[{ required: true, message: 'Merci de sélectionner un type de contact' }]}
          >
            <Radio.Group>
              {Object.keys(ContactTypeEnum)
                .filter(v => !isNaN(+v))
                .map(v => (
                  <Radio key={v} value={+v}>{t(`TYPES.CRM.CONTACTS.ContactTypeEnum.${ContactTypeEnum[v as any]}`)}</Radio>
                ))}
            </Radio.Group>
          </Form.Item>
          <div className='grid grid-cols-2 gap-x-4 gap-y-0'>
            <Form.Item
              label="Prénom"
              name={'firstName'}
              rules={[]}
            >
              <Input placeholder="Prénom"></Input>
            </Form.Item>
            <Form.Item
              label="Nom"
              name={'lastName'}
              rules={[required]}
            >
              <Input placeholder="Nom"></Input>
            </Form.Item>
          </div>
          <div className='grid grid-cols-3 gap-x-4 gap-y-0'>
            <Form.Item
              label="Email"
              name={'email'}
              rules={[]}
            >
              <Input placeholder="Email"></Input>
            </Form.Item>
            <Form.Item
              label="Téléphone fixe"
              name={'phone'}
              rules={[]}
            >
              <reactPhone.default
                className="ant-input"
                country="FR"
                placeholder="Téléphone"
                value={value}
                onChange={(e: any) => setValue(e)} />
            </Form.Item>
            <Form.Item
              label="Téléphone mobile"
              name={'mobile'}
              rules={[]}
            >
              <reactPhone.default
                className="ant-input"
                country="FR"
                placeholder="Téléphone"
                value={value}
                onChange={(e: any) => setValue(e)} />
            </Form.Item>
          </div>
          <Form.Item
            label="Poste"
            name={'fonction'}
            rules={[]}
          >
            <Input placeholder="Poste"></Input>
          </Form.Item>
          <Form.Item
            label="Sur une échelle de 1 à 5 renseigner le niveau de décision du contact"
            name={'decisionMakingPower'}
          >

            <Radio.Group buttonStyle='solid' defaultValue={3}>
              {[1, 2, 3, 4, 5].map(v => (
                <Radio.Button key={v} value={v}>{v}</Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Statut du contact"
            name={'status'}
            rules={[]}
          >
            <Radio.Group buttonStyle='solid'>
              {Object.keys(ContactStatusEnum)
                .filter(v => !isNaN(+v))
                .map(v => (
                  <Radio.Button key={v} value={+v}>{t(`TYPES.CRM.CONTACTS.ContactStatusEnum.${ContactStatusEnum[v as any]}`)}</Radio.Button>
                ))}
            </Radio.Group>
          </Form.Item>
          {contact && (
            <div className='flex'>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Enregister
                </Button>
              </Form.Item>
              {contact && contact.type !== ContactTypeEnum.mainContact && (
                <Button onClick={_ => deleteContact()} className='btn-link text-alc-danger'>
                  Supprimer
                </Button>)}
            </div>
          )}
          {!contact && (
            <Form.Item>
              <Button htmlType="submit" type="primary">Créer le contact</Button>
            </Form.Item>
          )}
        </Form>
      </div>
    </>
  );
};

export default ContactInfosForm;
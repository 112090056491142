import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { selectAdminLoggedIn, selectUserPortalLoggedIn, selectUserSupplierLoggedIn } from "../../Auth/state/auth.slice"
import { useAppSelector } from "../hooks"
import { ROUTES } from "../router"
import { getRoute } from "../tools/router-tools"

const RedirectGuard = () => {

    const isAdmin = useAppSelector(selectAdminLoggedIn)
    const isPortal = useAppSelector(selectUserPortalLoggedIn)
    const isSupplier = useAppSelector(selectUserSupplierLoggedIn)

    const navigate = useNavigate();

    useEffect(() => {
        if (isAdmin) {
            return navigate(getRoute(ROUTES.ADMIN_USER_ROUTES.home))
        } else if (isPortal) {
            return navigate(getRoute(ROUTES.PORTAL_USER_ROUTES.home))
        } else if (isSupplier) {
            return navigate(getRoute(ROUTES.SUPPLIER_USER_ROUTES.home))
        } else {
            return navigate(ROUTES.LOGIN)
        }
    }, [isAdmin, isPortal, isSupplier, navigate])
    return <Outlet />
}

export default RedirectGuard

import { useTranslation } from "react-i18next";
import AuthTabs from "../AuthTabs";
import RegisterForm from "./RegisterForm";

const RegisterPage = () => {
  const { t } = useTranslation();

  const paras = t("UI.AUTH.REGISTER_PAGE.p1", { returnObjects: true });

  return <>
    <AuthTabs ></AuthTabs>
    <h1>{t('UI.AUTH.REGISTER_PAGE.title')}</h1>
    {Object.values(paras).map((para: any, i: number) => (
      <p key={i}>{para}</p>
    ))}
    <RegisterForm></RegisterForm>
  </>
}

export default RegisterPage;
import { ReactNode, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { adminGetContractByStructureAction, adminSetCurrentContractAction, selectCurrentAdminContract } from '../../Contract/state/contract-admin.slice';
import { adminGetInventoryByStructureAction, adminSetCurrentInventoryAction, selectCurrentAdminInventory } from '../../Inventory/state/inventory-admin.slice';
import LoadingScreen from '../../common/components/LoadingScreen';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { adminGetStructureAction, adminSetCurrentStructureAction, selectCurrentAdminStructure } from '../state/structure-admin.slice';

type Props = { redirectPath?: string, children?: ReactNode };

// const StructureAdminGuard = ({ redirectPath = ROUTE_ADMIN_HOME, children }: Props) => {
const StructureAdminGuard = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const { structureId } = useParams();

  const structure = useAppSelector(selectCurrentAdminStructure);
  const contract = useAppSelector(selectCurrentAdminContract);
  const inventory = useAppSelector(selectCurrentAdminInventory);

  useEffect(() => {
    if (!structureId)
      return;

    // dispatch(adminSetCurrentStructureAction(null))
    dispatch(adminGetStructureAction({ id: structureId }))
    dispatch(adminGetContractByStructureAction({ id: structureId }))
    dispatch(adminGetInventoryByStructureAction({ id: structureId }))

    return () => {
      if (!structureId) {
        dispatch(adminSetCurrentStructureAction(null))
        dispatch(adminSetCurrentContractAction(null))
        dispatch(adminSetCurrentInventoryAction(null))
      }
    };
  }, [structureId, dispatch])

  if (!structure || !contract || !inventory)
    return <LoadingScreen />

  return children ? (<>{children}</>) : <Outlet></Outlet>
};

export default StructureAdminGuard
import { User } from "@alcome-rep/alcome-types/dist/models";
import { selectUser } from "../../../Auth/state/auth.slice";
import Cartography from "../../../Map/components/Cartography/cartography";
import { selectCurrentAdminStructure } from "../../../Structure/state/structure-admin.slice";
import { selectCurrentStructure } from "../../../Structure/state/structure-portal.slice";
import LoadingScreen from "../../../common/components/LoadingScreen";
import Section from "../../../common/components/Panels/Section";
import { useAppSelector } from "../../../common/hooks";
import { selectCurrentAdminInventory } from "../../state/inventory-admin.slice";
import { selectCurrentInventory } from "../../state/inventory-portal.slice";


const InventoryMapStep = () => {
  const user = useAppSelector(selectUser);
  const inventory = useAppSelector(User.IsUserAdmin(user) ? selectCurrentAdminInventory : selectCurrentInventory);
  const structure = useAppSelector(User.IsUserAdmin(user) ? selectCurrentAdminStructure : selectCurrentStructure);;

  if (!inventory || !structure || !user)
    return <LoadingScreen />

  const canEdit = inventory.userCanEdit(user) ?? false

  return (
    <Section title="" mode="transparent">
      <Cartography user={user} structure={structure} layer={inventory} canEdit={canEdit} />
    </Section>
  );
};

export default InventoryMapStep;
import { ActionTypeEnum, IAction } from "@alcome-rep/alcome-types/dist/interfaces/crm"
import { Tag } from "antd"
import { useTranslation } from "react-i18next"

type PropsType = {
  action: IAction,
}


const ActionType = ({ action }: PropsType) => {
  const { t } = useTranslation();

  return <Tag className="space-x-2" color={t(`UI.TAG.ActionTypeEnum.${action?.type}`)}>
    {t(`TYPES.CRM.ACTIONS.ActionTypeEnum.${ActionTypeEnum[action?.type]}`)}
  </Tag>
}

export default ActionType
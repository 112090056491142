import { ISupportOrders } from "@alcome-rep/alcome-types/dist/interfaces";
import { Table } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import structureAdminApi from "../../../../Structure/services/structure-admin.api";
import FilterEntity from "../../../../common/components/Filters/FilterEntity";
import FilterPanel from "../../../../common/components/Panels/FilterPanel";
import PageCanvas from "../../../../common/components/Panels/PageCanvas";
import useQueryParams from "../../../../common/hooks/useQueryParams";
import useTableChange from "../../../../common/hooks/useTableChange";
import { ROUTES } from "../../../../common/router";
import supportOrderApi from "../../../services/supportOrder.api";
import { supportOrderListColumns } from "./SupportOrderListColumns.constant";

const AdminSupportOrderList = () => {

  const { t } = useTranslation()
  const navigate = useNavigate();

  const [data, setData] = useState<ISupportOrders[]>([]);
  const [count, setCount] = useState<number>();
  const [loading, setloading] = useState<boolean>(true);

  const tableChange = useTableChange();
  const queryParams = useQueryParams()

  useEffect(() => {
    setloading(true)
    supportOrderApi.findAll(queryParams.query, Number(queryParams.page), Number(queryParams.limit))
      .then(res => {
        setData(res.rows)
        setCount(res.count)
        setloading(false)
      })
      .catch(() => {
        setData([])
        setCount(0)
        setloading(false)
      })
  }, [setData, setCount, setloading, queryParams])

  const findStructure = (name: string) => {
    const query: any = {};
    if (isNaN(+name))
      query.name = '/' + name + '/'
    else
      query.siren = '/' + name + '/'
    return structureAdminApi.findAll({
      query,
      page: 1, pageSize: 5
    }).then(r => r.rows
      .map(p => ({
        label: p.name,
        value: String(p.id)
      }) as DefaultOptionType)
    )
  }

  const retrieveStructure = async (id: string) => {
    const structure = await structureAdminApi.getStructure(id)
    return { label: structure.name, value: String(structure.id) } as DefaultOptionType
  }

  return (
    <>
      <PageCanvas
        breadCrumbs={[
          ROUTES.ADMIN_USER_ROUTES.home,
          ROUTES.ADMIN_SUPPORT_ORDER_ROUTES.supportOrderList
        ]}
        title={<span>Demandes de soutien <small>({count ? count : 0})</small ></span >}
      >
        <FilterPanel>
          <FilterEntity
            filterName='Structure'
            queryParamName='structureId'
            findFn={findStructure}
            retrieveFn={retrieveStructure}
          />
        </FilterPanel>
        <Table<ISupportOrders>
          rowKey={'id'}
          columns={supportOrderListColumns(queryParams.query, t, navigate)}
          dataSource={data}
          loading={loading}
          onChange={tableChange}
          size="small"
          pagination={
            {
              hideOnSinglePage: true,
              current: queryParams.page,
              pageSize: queryParams.limit,
              total: count,
            }
          } />
      </PageCanvas>
    </>
  )
}

export default AdminSupportOrderList
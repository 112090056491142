import { AccountingTypeEnum, AnnualReviewExtractInfoType, InvoiceExtractInfoType } from "@alcome-rep/alcome-types/dist/interfaces"
import { format } from "date-fns"
import { fr } from "date-fns/locale"
import { numericFormatter } from "react-number-format"

const InfosComponent = ({ infos, accountingType }: { infos: AnnualReviewExtractInfoType[] | InvoiceExtractInfoType[], accountingType: AccountingTypeEnum }) => {

    return <table style={{ marginTop: "20px" }} className="arTable">
        <thead>
            <tr>
                {accountingType === AccountingTypeEnum.revenueOrder ? (
                    <th>Structure</th>
                    ) : <th>Fournisseur</th>}
                {accountingType === AccountingTypeEnum.revenueOrder ? (
                    <th>Date du titre</th>
                    ) : <th>Date de la facture</th>}
                <th>Date de comptabilisation</th>
                <th>Année</th>
                <th>Montant</th>
            </tr>
        </thead>
        <tbody>
            {accountingType === AccountingTypeEnum.revenueOrder && infos.map((arInfos) => {
                const revenueOrder = (arInfos as AnnualReviewExtractInfoType).revenueOrder
                return (
                    <tr key={(arInfos as AnnualReviewExtractInfoType)._id}>
                        <td>{(arInfos as AnnualReviewExtractInfoType).structureName}</td>
                        <td>{(arInfos as AnnualReviewExtractInfoType).revenueOrder?.revenueOrderDate && format(new Date(revenueOrder!.revenueOrderDate as Date), 'dd/MM/yyyy', { locale: fr })}</td>
                        <td>{(arInfos as AnnualReviewExtractInfoType).revenueOrder?.comptabilisationDate && format(new Date(revenueOrder!.comptabilisationDate as Date), 'dd/MM/yyyy', { locale: fr })}</td>
                        <td>{(arInfos as AnnualReviewExtractInfoType).revenueOrder?.year}</td>
                        <td>{numericFormatter(((arInfos as AnnualReviewExtractInfoType).amountStatement).toFixed(2), { thousandSeparator: ' ', decimalScale: 2 })} &euro;</td>
                    </tr>
                )
            }
            )}
            {accountingType === AccountingTypeEnum.invoices && infos.map((invoiceInfos) => {
                const invoice = (invoiceInfos as InvoiceExtractInfoType).invoice;
                const year = new Date(invoice.fileMetadata!.invoiceDate).getFullYear()
                const comptabilisationDate = invoice.comptabilisationDate ? (invoiceInfos as InvoiceExtractInfoType).invoice?.comptabilisationDate && invoice!.comptabilisationDate
                : new Date(invoice.fileMetadata!.invoiceDate);
                
                return (
                    <tr key={(invoiceInfos as InvoiceExtractInfoType).invoiceId}>
                        <td>{(invoiceInfos as InvoiceExtractInfoType).supplierCompany.name}</td>
                        <td>{(invoiceInfos as InvoiceExtractInfoType).invoice?.fileMetadata?.invoiceDate && format(new Date(invoice!.fileMetadata?.invoiceDate as Date), 'dd/MM/yyyy', { locale: fr })}</td>
                        <td>{format(new Date(comptabilisationDate as Date), 'dd/MM/yyyy', { locale: fr })}</td>
                        <td>{year}</td>
                        <td>{numericFormatter(((invoiceInfos as InvoiceExtractInfoType).invoice!.fileMetadata!.totalPrice).toFixed(2), { thousandSeparator: ' ', decimalScale: 2 })} &euro;</td>
                    </tr>
                )
            })}
        </tbody>
    </table>
}

export default InfosComponent
import { ILibraryDoc } from "@alcome-rep/alcome-types/dist/interfaces";
import ApiService from "../../common/services/apiService";
import apiClient from "../../common/services/apiClient";

const LIBRARYDOC_PATH_API = 'libraryDoc';

class LibraryDocApi extends ApiService<ILibraryDoc> {
  constructor() {
    super(LIBRARYDOC_PATH_API)
  }

  getLibraryDocById = (libraryDocId: string) => {
    return this.custom<ILibraryDoc>('get', `${libraryDocId}`)
  }

  downloadLibraryDoc = (libraryDocId: string, filename: string) => {
    return apiClient({
      url: `${LIBRARYDOC_PATH_API}/download/${libraryDocId}`,
      method: 'get',
      responseType: 'blob'
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data as any]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', String(filename)); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
  }

  downloadThumbnail = (libraryDocId: string) => {
    return this.custom<Blob>('get', `download/thumbnail/${libraryDocId}`)
  }

}

const libraryDocApi = new LibraryDocApi();
export default libraryDocApi;
// import confirm from 'antd/lib/modal/confirm';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useQuotas from "../../../AcquisitionOrder/hooks/useQuota.hook";
import CommunePopulation from "../../../Structure/components/common/CommunePopulation";
import LoadingScreen from "../../../common/components/LoadingScreen";
import DList from "../../../common/components/Panels/DList";
import Section from '../../../common/components/Panels/Section';
import { useAppSelector } from "../../../common/hooks";
import { ROUTES } from '../../../common/router';
import { getRoute } from '../../../common/tools/router-tools';
import { selectCurrentAdminProspect } from "../../state/prospect-admin.slice";
import ProspectDetails from './ProspectDetails';
import { Alert } from "antd";


const ProspectPage = () => {
  const navigate = useNavigate()
  // const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const prospect = useAppSelector(selectCurrentAdminProspect)

  const quotas = useQuotas(prospect?.id, true)

  if (!prospect) {
    navigate(getRoute(ROUTES.ADMIN_CRM.ADMIN_PROSPECT_ROUTES.prospectList))
    return <LoadingScreen />
  }
  // const deleteProspect = async () => {
  //   if (!prospect)
  //     return
  //   confirm({
  //     title: 'Supprimer un prospect',
  //     icon: <ExclamationCircleOutlined />,
  //     content: 'Êtes-vous sûr de vouloir supprimer le prospect ?',
  //     cancelText: 'Annuler',
  //     // okButtonProps: { className: "btn" },
  //     onOk() {
  //       dispatch(adminDeleteProspectAction(prospect))
  //     },
  //     onCancel() {
  //     },
  //   });
  // }


  return (
    <>
      <div className="xl:grid xl:grid-cols-3 xl:gap-4 my-4">

        <div className="col-span-3">
          <div>
            {prospect.comment ?
              <Section
                title="Commentaire"
              >
                <div>
                  {prospect.comment}
                </div>
              </Section >
              :
              <Alert type="info" banner message={<div>Aucun commentaire pour le moment.</div>} />
            }
          </div>
        </div>

        <div className="col-span-1">
          <Section
            title="Informations"
          >
            <DList items={[
              { title: 'Code ' + t('TYPES.STRUCTURE.Code.' + prospect.structureType), description: prospect.siren },
              { title: "Département", description: prospect.department },
              { title: "Population", description: <CommunePopulation structure={prospect} /> }
            ]} />
            {quotas && (
              <div>
                <h6>Quotas</h6>
                <div>Cendriers : {quotas.quotas.ashtrays}</div>
                <div>Eteignoirs: {quotas.quotas.extinguishers}</div>
                <div>Disponible (cendriers): {quotas.available.ashtrays}</div>
                <div>Disponible (eteignoirs): {quotas.available.extinguishers}</div>
              </div>
            )}
          </Section >
        </div>

        <div className="col-span-1">
          <Section
            title="Contact principal"
          >
            <DList items={[
              { title: 'Prénom', description: prospect.mainContact?.firstName },
              { title: "Nom", description: prospect.mainContact?.lastName },
              { title: "Email", description: prospect.mainContact?.email }
            ]} />
          </Section >
        </div>

        <div className="col-span-1">
          <Section
            title="Coordonnées"
          >
            <ProspectDetails prospect={prospect} />
          </Section >
        </div>

      </div >

    </>
  );
};

export default ProspectPage;
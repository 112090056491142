import { AnnualReviewStatusEnum, IInventory } from '@alcome-rep/alcome-types/dist/interfaces';
import { Button, Table, TablePaginationConfig, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { FilterValue } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import inventoryPortalApi from '../../../Inventory/services/inventory-admin.api';
import { useNavigate } from 'react-router-dom';
import { getRoute } from '../../../common/tools/router-tools';
import { ROUTES } from '../../../common/router';
import { EditOutlined } from '@ant-design/icons';


const InventoryToValidateList: React.FC = () => {

    const navigate = useNavigate();

    const [data, setData] = useState<IInventory[]>([]);
    const [count, setCount] = useState<number>(0);
    const [, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [, setCurrentQuery] = useState<any>({})
    const [loading, setloading] = useState<boolean>(true);

    const getData = async (
        pagination: TablePaginationConfig = { pageSize: 10, current: 1 },
        filters?: Record<string, FilterValue | null>,
    ) => {
        setloading(true)
        const data = await inventoryPortalApi.findInventory({ status: 10 }, pagination.current, pagination.pageSize)
        setData(data.rows)
        setCount(data.count)
        setCurrentPage(pagination.current as number)
        setPageSize(pagination.pageSize as number)
        setCurrentQuery(filters)
        setloading(false)
    }

    useEffect(() => {
        const getData2 = async (
            pagination: TablePaginationConfig = { pageSize: 10, current: 1 },
            filters?: Record<string, FilterValue | null>,
        ) => {
            setloading(true)
            const data = await inventoryPortalApi.findInventory({ status: 10 }, pagination.current, pagination.pageSize)
            setData(data.rows)
            setCount(data.count)
            setCurrentPage(pagination.current as number)
            setPageSize(pagination.pageSize as number)
            setCurrentQuery(filters)
            setloading(false)
        }
        getData2()
    }, [setData, count, pageSize]);

    const columns: ColumnsType<IInventory> = [
        {
            fixed: 'left',
            width: '50px',
            render: (value: any) => {
                return (
                    <div className='flex space-x-2'>
                        <Button
                            className="btn-link text-alc-blue font-semibold text-lg px-0"
                            onClick={() => navigate(getRoute(ROUTES.ADMIN_STRUCTURE_ROUTES.structureDetails, { structureId: value.structureId }))}
                            icon={<EditOutlined />}
                        />
                    </div>
                )
            }
        },
        {
            title: 'Structure',
            fixed: 'left',
            dataIndex: ['structure', 'name'],
            key: 'structure.name',
            align: 'center' as const,
            render: (value: any) => {
                return <div className=' text-center'>{value}</div>
            }
        },
        {
            title: 'Insee / Siren',
            fixed: 'left',
            dataIndex: ['structure', 'siren'],
            key: 'structureId.siren',
            align: 'center' as const,
            render: (value: any) => {
                return <div className=' text-center'>{value}</div>
            }
        },
        {
            title: 'Statut de l\'annexe',
            fixed: 'right',
            dataIndex: 'status',
            key: 'status',
            align: 'center' as const,
            render: (value: any) => {
                if (value === AnnualReviewStatusEnum.completed) return (<Tag color='orange'>Annexe à valider</Tag>)
                else return (<div>Statut manquant</div>)
            }
        }
    ];

    const showMore = () => {
        navigate(getRoute(ROUTES.ADMIN_STRUCTURE_ROUTES.structureList, {}, { 'inventory.status': 10 }))
    }

    return (
        <>
            <div className='mt-4'>
                <div className='grid grid-cols-1 grid-rows-2 gap-6'>
                    <Table<IInventory>
                        style={{ cursor: 'pointer' }}
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        onChange={getData}
                        rowKey="id"
                        pagination={false}
                        footer={() => <Button type='link' onClick={showMore}> Voir plus ... </Button>}
                    />
                </div>
            </div>
        </>

    );
};

export default InventoryToValidateList;
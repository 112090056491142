import { UserPortalRolesEnum } from "@alcome-rep/alcome-types/dist/interfaces/user"
import { Button, Form, Input, Select, TablePaginationConfig } from "antd"
import { useForm } from "antd/es/form/Form"
import { FilterValue } from "antd/es/table/interface"
import { useTranslation } from "react-i18next"
import Section from "../../../../common/components/Panels/Section"
import useFormRules from "../../../../common/hooks/useFormRules"
import userPortalApi from "../../../services/user-portal.api"

type PropsType = {
    getUserInStructure: (pagination?: TablePaginationConfig, filters?: Record<string, FilterValue | null>) => Promise<void>
}

const UserStructureProfilInviteForm = ({ getUserInStructure }: PropsType) => {

    const { t } = useTranslation()
    const { required, email } = useFormRules();
    const [form] = useForm()

    const inviteUser = (data: any) => {
        userPortalApi.inviteNewUser(data)
            .then(_ => form.resetFields())
            .then(_ => getUserInStructure())
    }

    return (
        <Section title={<span>Inviter un nouvel utilisateur à rejoindre la structure</span>}>
            <Form
                form={form}
                className='grid grid-cols-2 gap-x-4 mt-5'
                name="basic"
                layout='vertical'
                onFinish={inviteUser}
            >
                <Form.Item
                    label="Nom"
                    name="lastName"
                    rules={[required]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label="Prénom"
                    name="firstName"
                    rules={[required]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label="Fonction"
                    name="position"
                    rules={[required]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label="Rôle"
                    name="role"
                    rules={[required]}
                    initialValue={UserPortalRolesEnum.reader}
                >
                    <Select>
                        {Object.values(UserPortalRolesEnum)
                            .filter(k => !isNaN(+k))
                            // .filter(r => r >= UserPortalRolesEnum.structureAdmin)
                            .map(role => (
                                <Select.Option key={role} value={role}>{t(`TYPES.ROLES.PORTAL.${UserPortalRolesEnum[role as number]}`)}</Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[required, email]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label="Téléphone"
                    name="phone"
                    rules={[required]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" type="primary" className="bg-alc-blue">
                        Envoyer l'invitation
                    </Button>
                </Form.Item>
            </Form>
        </Section>
    )
}

export default UserStructureProfilInviteForm
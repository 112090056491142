import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import ApiService from '../services/apiService';

interface GetPayload {
  id: string;
}

interface DeletePayload {
  id: string;
}

interface UpdatePayload<T> {
  id: string;
  data: T;
}

const createEntityDucksFactory = <RESPT>(
  api: ApiService<RESPT, any>,
  actions: {
    get: ActionCreatorWithPayload<GetPayload>;
    set: ActionCreatorWithPayload<RESPT | null>;
    update: ActionCreatorWithPayload<UpdatePayload<RESPT>>;
    delete?: ActionCreatorWithPayload<DeletePayload>;
  }
) => {

  function* get({ payload }: ReturnType<typeof actions.get>) {
    try {
      const resp: RESPT = yield call(api.get, payload.id);
      yield put(actions.set(resp));
    } catch (error) {
      yield put(actions.set(null));
    }
  }

  function* update({ payload }: ReturnType<typeof actions.update>) {
    try {
      const resp: RESPT = yield call(api.update, payload.id, payload.data);
      yield put(actions.set(resp));
    } catch (error) {
      yield put(actions.set(null));
    }
  }

  function* deleteEl({ payload }: { payload: DeletePayload }) {
    try {
      yield call(api.delete, payload.id);
      yield put(actions.set(null));
    } catch (error) {
      // yield put(actions.set(null));
    }
  }

  return {
    get,
    update,
    delete: deleteEl
  };
};

export default createEntityDucksFactory;
import { RouteObject } from "react-router-dom";
import LibraryDocList from "../components/portal/LibraryDocList/LibraryDocList";


const routePrefix = 'portal'
const routeDomain = 'libraryDoc'

export const portalLibraryDocRouteNames = {
  libraryDocList: { path: `${routePrefix}/${routeDomain}`, title: `ROUTES.${routeDomain}.list` },
}

export const portalLibraryDocRoutes: RouteObject[] = [
  {
    path: portalLibraryDocRouteNames.libraryDocList.path.replace(`${routePrefix}/`, ''),
    element: <LibraryDocList />,
  }
]

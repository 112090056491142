import { Models } from "@alcome-rep/alcome-types"
import { IFileDocument } from "@alcome-rep/alcome-types/dist/interfaces"
import { ISupplierFileDocument } from "@alcome-rep/alcome-types/dist/interfaces/supplier/supplierFileDocument"
import { RcFile } from "antd/es/upload"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { selectUser } from "../../../../Auth/state/auth.slice"
import LoadingScreen from "../../../../common/components/LoadingScreen"
import Section from "../../../../common/components/Panels/Section"
import UserFileFields from "../../../../common/components/portal/UserFileFields/UserFileFields"
import { useAppSelector } from "../../../../common/hooks"
import supplierOrderApi from "../../../services/supplierOrder.api"

const SupplierOrderInvoices = () => {

  const { supplierOrderId } = useParams();
  const [supplierOrder, setSupplierOrder] = useState<Models.SupplierOrder>()
  const user = useAppSelector(selectUser)

  useEffect(() => {
    if (!supplierOrderId)
      return;
    supplierOrderApi.getSupplierOrderById(supplierOrderId)
      .then(res => setSupplierOrder(res))
  }, [supplierOrderId])

  const getInvoice = async (file: ISupplierFileDocument | IFileDocument): Promise<any> => {
    if (supplierOrder)
      return await supplierOrderApi.getFileDocument(file.propertyName as any, supplierOrder.id as string, supplierOrder.supplierCompanyId, file.timestamp as any)
  }

  const uploadFile = async (file: RcFile) => {
    if (supplierOrder) {
      return await supplierOrderApi.supplierOrderUploadDocument(supplierOrder.id as string, supplierOrder.supplierCompanyId, 'invoices', file)
        .then((sp) => setSupplierOrder(new Models.SupplierOrder(sp)))
    }
  }

  const deleteFile = (fileDoc: ISupplierFileDocument | IFileDocument): any => {
    if (supplierOrder) {
      return supplierOrderApi.deleteFileDocument(supplierOrder.id as string, supplierOrder.supplierCompanyId, fileDoc.propertyName, fileDoc.timestamp)
        .then(sp => setSupplierOrder(new Models.SupplierOrder(sp)))
    }
  }

  if (!supplierOrder)
    return <LoadingScreen />

  return <>
    <Section
      title="Déposez vos factures"
    >
      <UserFileFields
        canEdit={!user?.isSuperAdmin()}
        download={getInvoice}
        fileDocument={supplierOrder.invoices as ISupplierFileDocument[]}
        propertyName="invoices"
        upload={uploadFile}
        deleteFile={deleteFile}
      />
    </Section>
  </>
}

export default SupplierOrderInvoices
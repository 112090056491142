import { Models } from "@alcome-rep/alcome-types";
import { UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { User } from "@alcome-rep/alcome-types/dist/models";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/store";
import { AUTH_SERVICE, AuthStateType, LoginPayload } from "./types";

type AuthServiceStateType = {
  activeUserType: UserTypeEnum | null,
  isRefreshing: boolean,
  hasRefreshUser: boolean,
  auths: {
    [K in UserTypeEnum]: AuthStateType<Models.UserPortal | Models.UserAdmin | Models.UserMember | Models.UserSupplier> | null
  }
};

const authInitialState: AuthServiceStateType = {
  activeUserType: null,
  isRefreshing: false,
  hasRefreshUser: false,
  auths: {
    portal: null,
    admin: null,
    member: null,
    supplier: null
  }
}

export const authSlice = createSlice({
  name: AUTH_SERVICE,
  initialState: authInitialState,
  reducers: {
    loginAction: (state: AuthServiceStateType, { payload }: PayloadAction<LoginPayload>) => {
      state.auths[payload.userType] = null;
      state.isRefreshing = true;
    },
    loginSuccessAction: (state: AuthServiceStateType, { payload: auth }: PayloadAction<AuthStateType<Models.User>>) => {
      state.activeUserType = auth.user.userType;
      state.auths[auth.user.userType] = auth as AuthStateType<Models.UserPortal | Models.UserAdmin | Models.UserMember | Models.UserSupplier>;
      state.isRefreshing = false;
      state.hasRefreshUser = true;
    },
    setLoginAsAction: (state: AuthServiceStateType, { payload: auth }: PayloadAction<AuthStateType<Models.User>>) => {
      state.auths[auth.user.userType] = auth as AuthStateType<Models.UserPortal | Models.UserAdmin | Models.UserMember | Models.UserSupplier>;
    },
    resetLoginAsAction: (state: AuthServiceStateType, { payload: { userType } }: PayloadAction<{ userType: UserTypeEnum }>) => {
      state.auths[userType] = null;
    },
    loginErrorAction: (state: AuthServiceStateType, { payload: type }: PayloadAction<UserTypeEnum>) => {
      state.auths[type] = null;
      state.isRefreshing = false;
      state.hasRefreshUser = true;
    },

    refreshTokenAction: (state: AuthServiceStateType, { payload: userType }: PayloadAction<UserTypeEnum>) => {
      state.activeUserType = userType
      state.isRefreshing = true;
    },
    refreshTokenSuccessAction: (state: AuthServiceStateType, { payload: auth }: PayloadAction<AuthStateType<Models.User>>) => {
      state.auths[auth.user.userType] = auth as AuthStateType<Models.UserPortal | Models.UserAdmin | Models.UserMember | Models.UserSupplier>;
      state.isRefreshing = false;
      state.hasRefreshUser = true;
    },
    refreshTokenErrorAction: (state: AuthServiceStateType, { payload: type }: PayloadAction<UserTypeEnum>) => {
      state.auths[type] = null;
      state.isRefreshing = false;
      state.hasRefreshUser = true;
    },

    logoutAction: (state: AuthServiceStateType, { payload }: PayloadAction<undefined>) => {
      if (state.activeUserType)
        state.auths[state.activeUserType] = null
      state.activeUserType = null;
    },

    setActiveUserType: (state: AuthServiceStateType, { payload }: PayloadAction<UserTypeEnum>) => {
      state.activeUserType = payload;
    }
  }
});


export const {
  loginAction,
  loginSuccessAction,
  setLoginAsAction,
  resetLoginAsAction,
  loginErrorAction,

  refreshTokenAction,
  refreshTokenSuccessAction,
  refreshTokenErrorAction,

  logoutAction,

  setActiveUserType
} = authSlice.actions;

export default authSlice.reducer;

export const selectAuthService = (state: RootState) => state.authService

export const selectIsRefreshing = createSelector(selectAuthService, (authSvc) => authSvc.isRefreshing);
export const selectHasRefreshUser = createSelector(selectAuthService, (authSvc) => authSvc.hasRefreshUser);
export const selectActiveUserType = createSelector(selectAuthService, (authSvc) => authSvc.activeUserType);

export const selectAdminLoggedIn = createSelector(selectAuthService, (authSvc) => authSvc.auths.admin?.user);
export const selectUserPortalLoggedIn = createSelector(selectAuthService, (authSvc) => authSvc.auths.portal?.user);
export const selectUserSupplierLoggedIn = createSelector(selectAuthService, (authSvc) => authSvc.auths.supplier?.user);

export const selectAuth = createSelector(selectAuthService, (authSvc) => {
  // keep authSvc.auths for compatibility with previous saved states
  return authSvc.activeUserType !== null && authSvc.auths ? authSvc.auths[authSvc.activeUserType] : null
});

export const selectIsLoggedIn = createSelector(selectAuth, (authState) => authState !== null)

export const selectUser = createSelector(selectAuth, (auth) => auth?.user as User | undefined)
export const selectToken = createSelector(selectAuth, (auth) => auth?.token)

export const selectUserPortal = createSelector(selectUser, (user) => {
  if (!user || !Models.User.IsUserPortal(user))
    throw new Error()
  return user
})

export const selectUserAdmin = createSelector(selectUser, (user) => {
  if (!user || !Models.User.IsUserAdmin(user))
    throw new Error()
  return user
})

export const selectUserMember = createSelector(selectUser, (user) => {
  if (!user || !Models.User.IsUserMember(user))
    throw new Error()
  return user
})

export const selectUserSupplier = createSelector(selectUser, (user) => {
  if (!user || !Models.User.IsUserSupplier(user))
    throw new Error()
  return user
})

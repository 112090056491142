import { Models } from "@alcome-rep/alcome-types";
import React, { useEffect, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom";
import { defaultPagination } from "../../../../common/app-constants";
import { getSearchParams } from "../../../../common/tools/router-tools";
import { useTranslation } from "react-i18next";
import adminSupplierCompanyApi from "../../../services/admin-supplierCompany.api";
import { Table, TablePaginationConfig } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/es/table/interface";
import { ISupplierCompany } from "@alcome-rep/alcome-types/dist/interfaces/supplier";
import PageCanvas from "../../../../common/components/Panels/PageCanvas";
import { ROUTES } from "../../../../common/router";
import FilterPanel from "../../../../common/components/Panels/FilterPanel";
import FilterInput from "../../../../common/components/Filters/FilterInput";
import { supplierListColumns } from "./supplierListColumns";
import CreateSupplierCompany from "../CreateSupplierCompany/CreateSupplierCompany";

const SupplierCompanyListComponent = () => {

    const [data, setData] = useState<Models.SupplierCompany[]>([]);
    const [count, setCount] = useState<number>(0);
    const [loading, setloading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<Date>()

    let [searchParams, setSearchParams] = useSearchParams();

    const queryParams = useMemo(() => {
        const { page = 1, limit = defaultPagination.pageSize, sortField = 'name', sortOrder = 1, ...query } = getSearchParams(searchParams);
        return { page, limit, query, sortField, sortOrder }
    }, [searchParams])

    const navigate = useNavigate()
    const { t } = useTranslation()

    useEffect(() => {
        setloading(true)
        adminSupplierCompanyApi.findAllSuppliers(queryParams.query, Number(queryParams.page), Number(queryParams.limit), { [queryParams.sortField]: queryParams.sortOrder })
            .then(res => {
                setData(res.rows)
                setCount(res.count)
                setloading(false)
            })
            .catch(() => {
                setData([])
                setCount(0)
                setloading(false)
            })
    }, [setData, setCount, setloading, queryParams, refresh])

    const getData = async (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Models.SupplierCompany> | SorterResult<Models.SupplierCompany>[],
        extra?: TableCurrentDataSource<ISupplierCompany>
    ) => {
        const q = Object.keys(filters).reduce((acc, k) => {
            if (filters[k] !== null)
                acc[k] = filters[k];
            return acc;
        }, {} as any)

        setSearchParams({ page: pagination.current, limit: pagination.pageSize, ...q })
    }

    return (
        <PageCanvas
            breadCrumbs={[
                ROUTES.ADMIN_USER_ROUTES.home,
                ROUTES.ADMIN_SUPPLIER_COMPANY_ROUTES.supplierCompanyList
            ]}
            title={<span>Liste des fournisseurs <small>({count ? count : 0})</small ></span >}
            actions={
                <CreateSupplierCompany refresh={setRefresh} />
                // <ExportExcelButton fileName='structure-reporting' exportList={exportExcel} />
            }
        >
            <FilterPanel>
                <FilterInput searchParamName='name' label='Nom' isRegex={true} />
                <FilterInput searchParamName='siret' label='Siret' isRegex={true} />
            </FilterPanel>

            <div className=''>
                <Table<Models.SupplierCompany>
                    columns={supplierListColumns(queryParams.query, t, navigate)}
                    dataSource={data}
                    loading={loading}
                    onChange={getData}
                    size="small"
                    rowKey="id"
                    scroll={{ scrollToFirstRowOnChange: true, x: 1000 }}
                    pagination={
                        {
                            hideOnSinglePage: true,
                            current: queryParams.page,
                            pageSize: queryParams.limit,
                            total: count,
                        }
                    } />
            </div>
        </PageCanvas >
    )
}

export default SupplierCompanyListComponent
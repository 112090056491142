import { DispositifCategoryEnum } from "@alcome-rep/alcome-types/dist/interfaces"
import { ShippingRule } from "@alcome-rep/alcome-types/dist/interfaces/supplier"
import { SupplierCompany } from "@alcome-rep/alcome-types/dist/models"
import { CloseOutlined } from "@ant-design/icons"
import { Button, InputNumber, Select, Table, TableColumnsType } from "antd"
import { t } from "i18next"
import { useState } from "react"
import AmountValue from "../../../../common/components/Fields/AmountValue"
import adminSupplierCompanyApi from "../../../services/admin-supplierCompany.api"

const SupplierShippingRules = ({ supplier }: { supplier: SupplierCompany }) => {
  const [shippingRules, setShippingRules] = useState<ShippingRule[]>(supplier.shippingRules || [])

  const save = () => {
    adminSupplierCompanyApi.updateSupplierCompanyShippingRules(supplier.id!, { shippingRules })
  }


  const addRule = () => {
    const newShippingrules = [...shippingRules.map(s => ({ ...s }))]
    newShippingrules.push({
      ruleActivation: { category: DispositifCategoryEnum.ashtray, qty: 1 },
      rule: { shippingFees: 0, shippingQuantitySlice: 10, category: DispositifCategoryEnum.extinguisher }
    })

    setShippingRules(newShippingrules)
  }

  const removeRule = (index: number) => {
    const newShippingrules = [...shippingRules.map(s => ({ ...s }))]
    newShippingrules.splice(index, 1)
    setShippingRules(newShippingrules)
  }

  const updateRuleValue = (index: number, prop: string, subProp: string, value: any) => {
    const newShippingrules = [...shippingRules.map(s => ({ ...s }))];
    const rule: any = newShippingrules[index];
    rule[prop][subProp] = value
    setShippingRules(newShippingrules)
  }

  const columns: TableColumnsType<ShippingRule> = [
    {
      title: 'Activation de la règle',
      dataIndex: ['ruleActivation', 'category'],
      render: (v: DispositifCategoryEnum, _, index: number) => <Select
        value={v}
        className="w-52"
        onChange={val => updateRuleValue(index, 'ruleActivation', 'category', val)}
        options={Object.keys(DispositifCategoryEnum).map(k => ({ value: k, label: t('TYPES.INVENTORY.DispositifCategoryEnum.' + k) }))}
      />
    },
    {
      title: "Quantité commandée",
      dataIndex: ['ruleActivation', 'qty'],
      render: (v: number, _r: ShippingRule, index: number) => <InputNumber value={v} onChange={val => updateRuleValue(index, 'ruleActivation', 'qty', val)} />
    },
    {
      title: "Catégorie concernée",
      dataIndex: ['rule', 'category'],
      render: (v: DispositifCategoryEnum, _, index: number) => <Select
        value={v}
        className="w-52"
        onChange={val => updateRuleValue(index, 'rule', 'category', val)}
        options={Object.keys(DispositifCategoryEnum).map(k => ({ value: k, label: t('TYPES.INVENTORY.DispositifCategoryEnum.' + k) }))}
      />
    },
    { title: "Frais de livraison appliqué", dataIndex: ['rule', 'shippingFees'], render: (v: number) => <AmountValue amount={v} precision={2} /> },
    {
      title: "par tranche de",
      dataIndex: ['rule', 'shippingQuantitySlice'],
      render: (v: number, _r: any, index: number) => <InputNumber step={10} value={v} onChange={val => updateRuleValue(index, 'rule', 'shippingQuantitySlice', val)} />
    },
    { key: 'action', render: (_v, _item, index) => <Button onClick={_ => removeRule(index)} type="link" danger icon={<CloseOutlined />} /> },
  ]

  return <div>
    <Table<ShippingRule>
      rowKey={'ruleActivation'}
      dataSource={shippingRules}
      locale={{
        emptyText: () => "Aucune règle définie"
      }}
      columns={columns}
      pagination={false}
      footer={() => {
        return <div className="flex space-x-2">
          <Button type="link" onClick={_ => addRule()}>Ajouter une règle</Button>
        </div>
      }}
    />
    <div className="mt-4">
      <Button type='primary' onClick={_ => save()}>{t('COMMON.save')}</Button>
    </div>
  </div>
}

export default SupplierShippingRules
import { IContract, ILibraryDoc } from "@alcome-rep/alcome-types/dist/interfaces";
import ApiService from "../../common/services/apiService";
import { buildFindQuery } from "../tools/global-tools";
import { ApiListResult, ILogAction } from "@alcome-rep/alcome-types";

const LOG_ACTIONS_PATH_API = 'logActions';

class LogActionApi extends ApiService<IContract> {
    constructor() {
        super(LOG_ACTIONS_PATH_API)
    }

    logActionslist({ query = {}, page = 1, pageSize = 10, structureId }: { query?: any, page?: number, pageSize?: number, structureId: string }): Promise<ApiListResult<ILogAction>> {
        const queryString = buildFindQuery(query);
        return this.custom('get', `byStructure/${structureId}?${queryString}&page=${page}&limit=${pageSize}`)
    }
    findLibraryDocDownloadedByStructure(structureId:string){
        return this.custom<ApiListResult<ILibraryDoc>>('get',`${structureId}/libraryDoc`);
    }
}

const logActionApi = new LogActionApi();
export default logActionApi;
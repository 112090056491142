import { ISupportOrders } from "@alcome-rep/alcome-types/dist/interfaces"
import { EditOutlined } from "@ant-design/icons"
import { Button } from "antd"
import { ColumnsType } from "antd/es/table"
import { format } from "date-fns"
import { fr } from "date-fns/locale"
import { TFunction } from "i18next"
import { NavigateFunction } from "react-router-dom"
import { ROUTES } from "../../../../common/router"
import { getRoute } from "../../../../common/tools/router-tools"
import SupportOrderStatusComponent from "../SupportOrderStatus/SupportOrderStatusComponent"

export const portalSupportOrderListColumns = (query: any, t: TFunction, navigate: NavigateFunction): ColumnsType<ISupportOrders> => [
    {
        fixed: 'left',
        render: (value: any) => {
            return (
                <Button
                    type="link"
                    onClick={(e) => {
                        navigate(getRoute(ROUTES.PORTAL_SUPPORT_ORDER_ROUTES.supportOrderDetails, { supportOrderId: value.id }));
                    }}
                    icon={<EditOutlined />}
                />
            )
        }
    },
    {
        title: 'N°',
        dataIndex: 'supportOrderNumber',
        key: 'supportOrderNumber',
        align: 'center' as const,
        render: (value: any) => {
            return <div>{value}</div>
        },
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        align: 'center' as const,
        render: (value: any) => {
            return <div>{format(new Date(value as Date), "d MMMM yyyy", { locale: fr })}</div>
        },
    },
    // {
    //     title: 'Nb dispositifs',
    //     // dataIndex: '',
    //     key: ['hotspot', 'id'],
    //     align: 'center' as const,
    //     render: (value: any) => {
    //         return <div>{value}</div>
    //     },
    // },
    {
        title: 'Nb cendriers de rue',
        dataIndex: 'hotspots',
        key: 'hotspots',
        align: 'center' as const,
        render: (value: any) => {
            let nbDispositifs = 0;
            value.forEach((item: any) => {
                nbDispositifs += item.qty;
            });
            return <div>{nbDispositifs}</div>
        },
    },
    {
        title: 'Nb eteignoirs',
        dataIndex: 'extinguisherCount',
        key: 'extinguisherCount',
        align: 'center' as const,
        render: (value: any) => {
            return <div>{value}</div>
        },
    },
    {
        title: 'Statut',
        dataIndex: 'status',
        key: 'status',
        align: 'center' as const,
        render: (value: any) => {
            return <SupportOrderStatusComponent status={value} />
        },
    },


]
import { IUser, UserPortalRolesEnum } from '@alcome-rep/alcome-types/dist/interfaces/user';
import { BaseCommune } from '@alcome-rep/alcome-types/dist/models';
import { TablePaginationConfig } from 'antd/lib/table';
import { FilterValue } from 'antd/lib/table/interface';
import { useEffect, useMemo, useState } from 'react';
import { selectUserPortal } from '../../../../Auth/state/auth.slice';
import { selectCurrentStructure } from '../../../../Structure/state/structure-portal.slice';
import PageCanvas from '../../../../common/components/Panels/PageCanvas';
import { useAppSelector } from '../../../../common/hooks';
import { ROUTES } from '../../../../common/router';
import userPortalApi from '../../../services/user-portal.api';
import UserListOfUserInStructure from './UserListOfUserInStructure';
import UserStructureProfilDataForm from './UserStructureProfilDataForm';
import UserStructureProfilInviteForm from './UserStructureProfilInviteForm';
import UserStructureShippingAddress from './UserStructureShippingAddress';

const UserPortalStructureProfilPage = () => {

  const structure = useAppSelector(selectCurrentStructure)
  const user = useAppSelector(selectUserPortal)
  const userRole = user.role
  const structureId = structure?.id as string

  const [loading, setloading] = useState<boolean>(true);
  const [count, setCount] = useState<number>(1);
  const [userInStructure, setUserInStructure] = useState<IUser[]>([]);

  const getUserInStructure = useMemo(() => async (pagination: TablePaginationConfig = { pageSize: 10, current: 1 },
    filters?: Record<string, FilterValue | null>) => {
    const res = await userPortalApi.findUsersInStructure({ query: { ...filters, structureId }, page: pagination.current as number, pageSize: pagination.pageSize as number })
    setUserInStructure(res.rows)
    setCount(res.count)
    setloading(false)
  }, [structureId])


  useEffect(() => {
    if (structure) {
      getUserInStructure();
    }
  }, [getUserInStructure, structure]);

  return (
    <>
      <PageCanvas
        breadCrumbs={[
          ROUTES.PORTAL_USER_ROUTES.home,
          ROUTES.PORTAL_USER_ROUTES.administratifProfil
        ]}
        title={<div>
          <div>Profil administratif</div>
        </div>}
      >
        <div className='flex space-x-4'>

          <UserStructureProfilDataForm />

          <UserStructureShippingAddress />
        </div>
          {userRole === UserPortalRolesEnum.structureAdmin && (
            <div>
              <UserListOfUserInStructure
                loading={loading}
                count={count}
                structure={structure as BaseCommune}
                getUserInStructure={getUserInStructure}
                userInStructure={userInStructure}
              />
              <UserStructureProfilInviteForm getUserInStructure={getUserInStructure} />
            </div>
          )}
      </PageCanvas>
    </>
  );
};

export default UserPortalStructureProfilPage;
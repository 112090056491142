import { Form } from "antd"

const FilterPanel = ({ children }: any) => {
  return <Form
    layout="vertical"
  >
    <div className='filter-panel'>
      {children}
    </div>
  </Form>
}

export default FilterPanel
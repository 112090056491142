import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ReactNode } from "react";

export default function AddButton({ onClick, children }: { onClick: () => void, children: ReactNode }) {

    return (
        <Button
            onClick={_ => onClick()}
            type="link"
            htmlType='button'
            className='w-fit self-start'
            icon={<PlusCircleOutlined />}
        >
            {children}
        </Button>
    );
}
import { ApiListResult } from "@alcome-rep/alcome-types";
import { ICollectPoint, IHotspot, MapStats } from "@alcome-rep/alcome-types/dist/interfaces";
import { message } from "antd";
import { RcFile } from "antd/es/upload";
import apiClient from "../../common/services/apiClient";
import ApiService from "../../common/services/apiService";

const MAP_PATH_API = 'map';

class MapApi extends ApiService<any> {
  constructor() {
    super(MAP_PATH_API)
  }

  getHotspots(structureId: string) {
    return this.custom<ApiListResult<IHotspot>>('get', `hotspot?structureId=${structureId}&page=1&limit=-1`)
  }

  updateHotspot(id: string, data: any) {
    return this.custom<IHotspot>('put', `hotspot/${id}`, undefined, data)
      .then(res => {
        message.success('Hotspot modifié')
        return res;
      })
  }


  createHotspot(data: IHotspot, layerId: string, isAnnualReview = true) {
    return this.custom<IHotspot>('post', 'hotspot', undefined, {
      ...data,
      inventoryId: !isAnnualReview ? layerId : undefined,
      annualReviewId: isAnnualReview ? layerId : undefined

    }).then(res => {
      message.success('Hotspot ajouté')
      return res;
    })
  }

  deleteHotspot(id: string) {
    return this.custom<IHotspot>('delete', `hotspot/${id}`)
      .then(res => {
        message.success('Hotspot supprimé')
        return res;
      })
  }

  getCollectPoints(structureId: string, hotspotId?: string) {
    return this.custom<ApiListResult<ICollectPoint>>('get', `collectPoint?structureId=${structureId}&page=1&limit=-1${hotspotId ? '&hotspotId=' + hotspotId : ''}`)
  }

  updateCollectPoint(id: string, data: any) {
    return this.custom<ICollectPoint>('put', `collectPoint/${id}`, undefined, data)
      .then(res => {
        message.success('Point de collecte modifié')
        return res;
      })
  }

  createCollectPoint(data: ICollectPoint, layerId: string, isAnnualReview = true) {
    return this.custom<ICollectPoint>('post', 'collectPoint', undefined, {
      ...data,
      inventoryId: !isAnnualReview ? layerId : undefined,
      annualReviewId: isAnnualReview ? layerId : undefined
    }).then(res => {
      message.success('Point de collecte ajouté')
      return res;
    })
  }

  deleteCollectPoint(id: string) {
    return this.custom<ICollectPoint>('delete', `collectPoint/${id}`)
      .then(res => {
        message.success('Point de collecte supprimé')
        return res;
      })
  }

  getStats(structureId: string) {
    return this.custom<MapStats>('get', `mapStats/${structureId}`)
  }

  downloadPicture(collectPoint: ICollectPoint, structureId: string) {
    return apiClient({
      url: `map/collectPoint/download/${collectPoint.id}/structure/${structureId} `,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', collectPoint.pictureInstallation as string); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
  }

  uploadPictureInstallation(supportOrderId: string, collectPointId: string, file: RcFile, query?: string) {
    const formData = new FormData();
    formData.append('file', file);

    let route = "";
    if (query) {
      route = `collectPoint/${collectPointId}/supportOrder/${supportOrderId}/picture?extinguisherQuery=${query}`
    } else {
      route = `collectPoint/${collectPointId}/supportOrder/${supportOrderId}/picture`
    }
    return this.custom<ICollectPoint>('post', route, undefined, formData)
  }

  uploadPictureInstallationAcquisitionOrder(acquisitionOrderId: string, collectPointId: string, file: RcFile, query?: string) {
    const formData = new FormData();
    formData.append('file', file);
    return this.custom<ICollectPoint>('post', `collectPoint/${collectPointId}/acquisitionOrder/${acquisitionOrderId}/picture`, undefined, formData)
  }


}

const mapApi = new MapApi();
export default mapApi;
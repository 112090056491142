import { AnnualReview } from "@alcome-rep/alcome-types/dist/models";
import { message } from "antd";
import ApiService from "../../common/services/apiService";

const ANNUALREVIEW_PATH_API = 'annualReview';

class AnnualReviewApi extends ApiService<AnnualReview> {
  constructor() {
    super(ANNUALREVIEW_PATH_API)
  }

  /**
   * @description update an annual review
   * @param annualReviewId 
   * @param data 
   * @returns Updated annual review
   */
  updateAnnualReviewLatestInfos = ({ annualReviewId, data }: { annualReviewId: string, data: any }) => {
    return this.custom<AnnualReview>('put', `latest/infos/${annualReviewId}`, undefined, data)
      .then(r => {
        message.success('Enregistré')
        return r;
      })
      .catch(() => message.error('Problème lors de l\'enregistrement'))
  }

}

const annualReviewApi = new AnnualReviewApi();
export default annualReviewApi;
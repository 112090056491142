import { IContract } from '@alcome-rep/alcome-types/dist/interfaces';
import { call, put, takeLatest } from 'redux-saga/effects';
import { adminSetCurrentStructureAction } from '../../Structure/state/structure-admin.slice';
import createEntityDucksFactory from '../../common/state/ducks.factory';
import contractAdminApi from '../services/contract-admin.api';
import { adminGetContractAction, adminGetContractByStructureAction, adminSetCurrentContractAction } from './contract-admin.slice';

/**
 * SAGAS
 */

function* getStructureContract({ payload }: ReturnType<typeof adminSetCurrentStructureAction>) {
  try {
    const resp: IContract = yield call(contractAdminApi.getContractByStructure, payload.id!);

    yield put(adminSetCurrentContractAction(resp));
  } catch {
    yield put(adminSetCurrentContractAction(null));
  }
}

function* unsetContract({ payload }: ReturnType<typeof adminSetCurrentStructureAction>) {
  if (payload === null) {
    yield put(adminSetCurrentContractAction(null));
  }
}

const actions = createEntityDucksFactory<IContract>(contractAdminApi, {
  get: adminGetContractAction,
  set: adminSetCurrentContractAction,
  update: adminSetCurrentContractAction
})

export function* contractAdminSaga() {
  // yield takeLatest(adminUnsetCurrentContractAction, unsetContract);
  yield takeLatest(adminGetContractAction, actions.get);
  yield takeLatest(adminSetCurrentStructureAction, unsetContract);
  yield takeLatest(adminGetContractByStructureAction, getStructureContract);

}
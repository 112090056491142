import { AcquisitionItemOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { Tag } from "antd";
import { useTranslation } from "react-i18next";


const AcquisitionOrderItemStatus = ({ status }: { status: AcquisitionItemOrderStatusEnum }) => {

  const { t } = useTranslation();

  return <Tag color={t(`UI.TAG.AcquisitionItemOrderStatusEnum.${AcquisitionItemOrderStatusEnum[status]}`)} className={`${AcquisitionItemOrderStatusEnum[status]} text-white`}>
    {t(`TYPES.ACQUISITION_ORDER.AcquisitionItemOrderStatusEnum.${AcquisitionItemOrderStatusEnum[status]}`)}
  </Tag>
}
export default AcquisitionOrderItemStatus
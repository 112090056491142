import { ApiListResult } from "@alcome-rep/alcome-types";
import { ILibraryDoc } from "@alcome-rep/alcome-types/dist/interfaces";
import { message } from "antd";
import ApiService, { getTrackProgressAreas } from "../../common/services/apiService";
import { buildFindQuery } from "../../common/tools/global-tools";

const ADMIN_LIBRARYDOC_PATH_API = 'admin/libraryDoc';

export const progressKeys = {
    upload: getTrackProgressAreas(ADMIN_LIBRARYDOC_PATH_API).custom('POST'),
    update: getTrackProgressAreas(ADMIN_LIBRARYDOC_PATH_API).custom('PUT'),
}

class AdminLibraryDocApi extends ApiService<ILibraryDoc> {
    constructor() {
        super(ADMIN_LIBRARYDOC_PATH_API)
    }

    adminLibraryDocList = ({ query = {}, page = 1 }: { query?: any, page?: number }) => {
        const myQuery = { ...query }
        const queryString = buildFindQuery(myQuery);
        return this.custom<ApiListResult<ILibraryDoc>>('get', `?${queryString}&page=${page}`)
    }

    uploadLibraryDoc = (data: any) => {
        const formData = new FormData();
        if (!data.enabled)
            data.enabled = false
        Object.keys(data).forEach(k => {
            if ((k === 'file' || k === 'thumbnail')) {
                // necessary condition to avoid optional thumbnail set on data if undefined
                if (data[k])
                    formData.append(k, data[k][0].originFileObj);
            }
            else if (k === 'structureType') {
                data['structureType'].forEach((item: string) => formData.append('structureType[]', item))
            }
            else {
                formData.append(k, data[k]);
            }
        })
        return this.custom<ILibraryDoc>('post', '', undefined, formData)
            .then(res => {
                message.success('Le document a été crée !')
                return res;
            })
    }

    updateLibraryDoc = (libraryDocId: string, data: any) => {
        const formData = new FormData();
        if (!data.enabled)
            data.enabled = false
        Object.keys(data).forEach(k => {
            if ((k === 'file' || k === 'thumbnail')) {
                // necessary condition to avoid optional thumbnail set on data if undefined
                if (data[k])
                    formData.append(k, data[k][0].originFileObj);
            }
            else if (k === 'structureType') {
                data['structureType'].forEach((item: string) => formData.append('structureType[]', item))
            }
            else {
                formData.append(k, data[k]);
            }
        })
        return this.custom<ILibraryDoc>('put', `${libraryDocId}`, undefined, formData)
            .then(res => {
                message.success('Le document a été mis à jour !')
                return res;
            })
    }

    deleteLibraryDoc = (id: string) => {
        return this.custom<ILibraryDoc>('delete', `${id}`)
            .then(res => {
                message.success('Le document a été supprimé !')
                return res;
            })
    }

}

const adminLibraryDocApi = new AdminLibraryDocApi();
export default adminLibraryDocApi;
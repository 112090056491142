import { Breadcrumb } from "antd"
import { ReactNode } from "react"
import { RouteDeclaration, getRoute } from "../../tools/router-tools"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

type PropsType = {
  children: ReactNode,
  title?: string | ReactNode,
  actions?: ReactNode,
  breadCrumbs?: Array<RouteDeclaration[keyof RouteDeclaration]>
  params?: any
}

const PageCanvas = ({ children, title, actions, breadCrumbs, params }: PropsType) => {

  const { t } = useTranslation()

  return <div>
    {breadCrumbs && breadCrumbs.length &&
      <Breadcrumb
        itemRender={item => <Link to={item.path!}>{item.title}</Link>}
        className="mb-4"
        items={breadCrumbs.map((breadcrumb, i) => ({
          title: t(breadcrumb.title || ''),
          path: i < breadCrumbs.length - 1 ? getRoute(breadcrumb, params ? params : {}) ?? '' : ''
        }))} />

    }
    <div className='w-full flex items-center justify-start mb-4'>
      {title && <h1 className='h3'>{title}</h1>}
      {actions && <div className="actions ml-auto">{actions}</div>}
    </div>
    {children}
  </div >

}

export default PageCanvas
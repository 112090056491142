import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { county } from "../../tools/county.constants";

type PropsType = {
  className?: string,
  filterName?: string
}

const FilterDepartment = ({ className = `m-0`, filterName = 'department[]' }: PropsType) => {

  let [searchParams, setSearchParams] = useSearchParams();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false)

  // dpt list update on query params
  useEffect(() => {
    setSelectedItems(searchParams.getAll(filterName))
  }, [setSelectedItems, searchParams, filterName])


  const selectedItem = (item: any) => {
    searchParams.append(filterName, item);
    setSearchParams(searchParams);
  }

  const deselectedItem = (item: any) => {
    searchParams.delete(filterName);
    const items = [...selectedItems]
    items.splice(selectedItems.indexOf(item), 1)

    items.forEach(element => searchParams.append(filterName, element))
    setSearchParams(searchParams);
  }

  return (
    <>
      <Form.Item
        className={className}
        label="Départements"
      >
        <Select
          open={open}
          onDropdownVisibleChange={(visible) => setOpen(visible)}
          placeholder="Liste des départements"
          mode="multiple"
          options={county.map(v => ({ label: `${v.name} ${v.code}`, value: v.code }))}
          value={selectedItems}
          onSelect={selectedItem}
          onDeselect={deselectedItem}
          filterOption={(input, option) => {
            const rexp = new RegExp('^' + input, 'i');
            return rexp.test(option?.label ?? '') || rexp.test(option?.value ?? '')
          }}
        >
        </Select>
      </Form.Item>
    </>
  );
};

export default FilterDepartment;
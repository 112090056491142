import { Models } from "@alcome-rep/alcome-types"
import { SupplierOrderItemType, SupplierOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces/supplier"
import { IUserAdmin } from "@alcome-rep/alcome-types/dist/interfaces/user"
import { Button, DatePicker, DatePickerProps, Table } from "antd"
import confirm from "antd/es/modal/confirm"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import RegionalDelegateSection from "../../../../Dashboard/components/portal/RegionalDelegateSection"
import structureApi from "../../../../Structure/services/structure.api"
import userApi from "../../../../User/services/user.api"
import AmountValue from "../../../../common/components/Fields/AmountValue"
import LoadingScreen from "../../../../common/components/LoadingScreen"
import Section from "../../../../common/components/Panels/Section"
import supplierOrderApi from "../../../services/supplierOrder.api"
import DeliveryComponent from "../../common/DeliveryComponent"

const SupplierOrderInfos = () => {

    const { supplierOrderId } = useParams();
    const [supplierOrder, setSupplierOrder] = useState<Models.SupplierOrder>()
    const [deliveryDate, setDeliveryDate] = useState<Date>()
    const [regionalDelegate, setRegionalDelegate] = useState<IUserAdmin>()
    const [department, setDepartment] = useState<string>();

    useEffect(() => {
        if (!supplierOrderId)
            return;
        supplierOrderApi.getSupplierOrderById(supplierOrderId)
            .then(res => setSupplierOrder(res))
    }, [supplierOrderId])

    useEffect(() => {
        if (!supplierOrder)
            return;
        if (supplierOrder.structure) {
            structureApi.getStructureDepartment(supplierOrder!.structureId as string)
                .then(res => {
                    setDepartment(res)
                    userApi.findRegionalDelegate(`${res}`)
                        .then(resp => setRegionalDelegate(resp))
                })
        }
    }, [supplierOrder])

    const columns: any = [
        {
            title: 'Réf',
            dataIndex: ['product', 'reference'],
            align: 'left'
        },
        {
            title: 'Libellé',
            dataIndex: ['product', 'name']
        },
        {
            title: 'Quantité',
            dataIndex: 'quantity'
        },
        {
            title: 'Prix unitaire',
            dataIndex: ['product', 'price'],
            render: (value: number) => <AmountValue amount={value} precision={2} />,
        },
        {
            title: 'Prix total',
            key: 'total',
            render: (_value: number, item: SupplierOrderItemType) => <AmountValue amount={item.price} precision={2} />,
        },

    ]

    const acceptOrder = () => {
        confirm({
            title: "Confirmation",
            content: "Êtes vous sûr de vouloir accepter la commande ?",
            cancelText: 'Annuler',
            closable: true,
            okCancel: true,
            onOk: async () => {
                const data = await supplierOrderApi.updateSupplierOrderStatus(supplierOrder?.id!, { status: SupplierOrderStatusEnum.supplier_has_validate })
                setSupplierOrder(data);
            }
        })
    }

    const onDateChange: DatePickerProps['onChange'] = (date) => {
        setDeliveryDate(date?.toDate())
    };

    const setDeliveryDateConfirm = () => {
        confirm({
            title: "Définir la date d'expédition",
            cancelText: 'Annuler',
            closable: true,
            okCancel: true,
            content: (
                <>
                    <DatePicker onChange={onDateChange} format={'DD/MM/YYYY'} />
                </>
            ),
            onOk: async () => {
                const updatedDate = new Date(deliveryDate!)
                const data = await supplierOrderApi.update(supplierOrder?.id!, { deliveryDate: updatedDate })
                setSupplierOrder(new Models.SupplierOrder(data));
            }
        })
    }

    const markAsShipped = () => {
        confirm({
            title: "Confirmation",
            content: "La commande passera au status 'Livraison en cours'",
            cancelText: 'Annuler',
            closable: true,
            okCancel: true,
            onOk: async () => {
                const data = await supplierOrderApi.updateSupplierOrderStatus(supplierOrder?.id!, { status: SupplierOrderStatusEnum.supplier_has_shipped })
                setSupplierOrder(data);
                setDeliveryDateConfirm()
            }
        })
    }

    const markAsDelivered = () => {
        confirm({
            title: "Confirmation",
            content: "La commande passera au status 'Livraison terminée'",
            cancelText: 'Annuler',
            closable: true,
            okCancel: true,
            onOk: async () => {
                const data = await supplierOrderApi.updateSupplierOrderStatus(supplierOrder?.id!, { status: SupplierOrderStatusEnum.supplier_has_delivered })
                setSupplierOrder(data);
            }
        })
    }

    if (!supplierOrder)
        return <LoadingScreen />

    return (
        <>

            <div className="flex space-x-4">
                <div className="w-8/12">
                    <Section title="Livraison">
                        <DeliveryComponent supplierOrder={supplierOrder} />
                    </Section>
                </div>
                {regionalDelegate && (
                    <div className="w-4/12">
                        <Section title="Affaire suivie par :">
                            <RegionalDelegateSection regionalDelegate={regionalDelegate} department={department as string} />
                        </Section>
                    </div>
                )}
            </div>

            <Section
                title="Produits commandés"
                mode="transparent"
            >
                <Table<SupplierOrderItemType>
                    rowKey={'id'}
                    columns={columns}
                    dataSource={supplierOrder.items}
                    size="small"
                    footer={() => <div className="flex flex-col justify-center items-end">
                        <div><span className="font-semibold">Frais de livraison: </span><AmountValue amount={supplierOrder.shippingFees} precision={2} /></div>
                        <div><span className="font-semibold">TOTAL: </span><AmountValue amount={supplierOrder.total + supplierOrder.shippingFees} precision={2} /></div>
                    </div>}
                    pagination={false} />
            </Section>

            {supplierOrder.status === SupplierOrderStatusEnum.published && <Button
                className="print:hidden"
                type="primary"
                onClick={acceptOrder}
            >Accepter la commande
            </Button>}

            {supplierOrder.status === SupplierOrderStatusEnum.supplier_has_validate && <Button
                className="print:hidden"
                type="primary"
                onClick={markAsShipped}
            >Marquer comme expédiée
            </Button>}

            {supplierOrder.status === SupplierOrderStatusEnum.supplier_has_shipped && <Button
                className="print:hidden"
                type="primary"
                onClick={markAsDelivered}
            >Marquer comme livrée
            </Button>}
        </>
    )

}

export default SupplierOrderInfos
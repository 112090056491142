import { IContact } from '@alcome-rep/alcome-types/dist/interfaces/crm';
import { EditOutlined } from '@ant-design/icons';
import { Button, Input, Table } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FilterEntity from '../../../common/components/Filters/FilterEntity';
import FilterPanel from '../../../common/components/Panels/FilterPanel';
import PageCanvas from '../../../common/components/Panels/PageCanvas';
import QuickLinksPanel from '../../../common/components/Panels/QuickLinksPanel';
import { useAppDispatch } from '../../../common/hooks';
import useQueryParams from '../../../common/hooks/useQueryParams';
import useTableChange from '../../../common/hooks/useTableChange';
import { ROUTES } from '../../../common/router';
import { closeDrawerAction, openDrawerAction } from '../../../common/state/modal.slice';
import { getRoute } from '../../../common/tools/router-tools';
import contactAdminApi from '../../services/contact-admin.api';
import prospectAdminApi from '../../services/prospect-admin.api';
import ContactInfosForm from '../ContactDetails/ContactInfosForm';

const ContactListComponent = () => {
  const [data, setData] = useState<IContact[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setloading] = useState<boolean>(false);

  const [refresh, setRefresh] = useState<Date>();

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const tableChange = useTableChange();
  const queryParams = useQueryParams()

  useEffect(() => {
    setloading(true)
    contactAdminApi.findContacts({ query: queryParams.query, page: Number(queryParams.page), limit: Number(queryParams.limit) })
      .then(res => {
        setData(res.rows)
        setCount(res.count)
        setloading(false)
      })
      .catch(() => {
        setData([])
        setCount(0)
        setloading(false)
      })
  }, [setData, setCount, setloading, queryParams, refresh])

  const findProspects = (name: string) => {
    return prospectAdminApi.findAll({
      query: { name },
      page: 1, limit: 5, sort: { name: 1 }
    }).then(r => r.rows
      .map(p => ({
        label: p.name,
        value: String(p.id)
      }) as DefaultOptionType)
    )
  }

  const retrieveProspect = async (id: string) => {
    const prospect = await prospectAdminApi.getProspect(id)
    return { label: prospect.name, value: String(prospect.id) } as DefaultOptionType
  }

  const editContact = (contact?: IContact) => {
    dispatch(openDrawerAction({
      component: <ContactInfosForm
        prospectId={contact?.prospectId as string}
        formCb={data => {
          dispatch(closeDrawerAction())
          if (data === null || data)
            setRefresh(new Date())
        }}
        contact={contact}
      />,
      options: { title: "Contact", size: 'large' }
    }))
  }

  const columns: any = [
    {
      fixed: 'left',
      width: '50px',
      render: (value: any) => {
        return (
          <Button
            type="link"
            onClick={() => editContact(value)}
            icon={<EditOutlined />}
          />
        )
      }
    },
    {
      title: "Nom",
      fixed: 'left',
      key: "lastName",
      render: (value: any) => <span>{value.firstName} {value.lastName.toUpperCase()}</span>
    },
    {
      title: "Email",
      dataIndex: 'email',
      key: "email",
    },
    {
      title: "Prospect",
      dataIndex: ['prospect', 'name'],

      render: (value: any, item: any) =>
        item.prospect &&
        (<Link to={`${getRoute(ROUTES.ADMIN_CRM.ADMIN_PROSPECT_ROUTES.prospectDetails, { prospectId: item.prospectId })}`}
          className='font-semibold text-center'>{item.prospect.name}
        </Link>)
      ,
    },
  ];

  const [value, setValue] = useState("");
  const quickSearch = (str: string) => {
    if (str === "")
      navigate('')
    else
      navigate(`?email=/${str}/`)
  }


  return (
    <>
      <PageCanvas
        breadCrumbs={[
          ROUTES.ADMIN_USER_ROUTES.home,
          ROUTES.ADMIN_CRM.ADMIN_CONTACT_ROUTES.contactList
        ]}
        title={<span>Contacts <small>({count ? count : 0})</small ></span >}
      >
        <FilterPanel>
          <FilterEntity
            filterName='Prospect'
            multiple={false}
            queryParamName='prospectId'
            findFn={findProspects}
            retrieveFn={retrieveProspect}
          />

        </FilterPanel>

        <QuickLinksPanel>
          <Input
            type="text"
            placeholder='Recherche rapide par email'
            value={value}
            onChange={(e) => {
              quickSearch(e.target.value)
              setValue(e.target.value)
            }}
          />
        </QuickLinksPanel >

        <div className=''>
          <Table<IContact>
            columns={columns}
            dataSource={data}
            loading={loading}
            onChange={tableChange}
            size="small"
            rowKey="id"
            scroll={{ scrollToFirstRowOnChange: true, x: 1000 }}
            pagination={
              {
                hideOnSinglePage: true,
                current: queryParams.page,
                pageSize: queryParams.limit,
                total: count,
              }
            } />
        </div>
      </PageCanvas >

    </>
  );
};

export default ContactListComponent;
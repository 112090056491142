import { Contract } from "@alcome-rep/alcome-types/dist/models";
import { Draft, PayloadAction, createSelector } from "@reduxjs/toolkit";
import factoryCreateSlice from "../../common/state/slice.factory";
import { BaseServiceState } from "../../common/state/types";
import { RootState } from "../../common/store";

const slices = factoryCreateSlice<"AdminContract", Contract>('AdminContract', {
  adminGetContractByStructureAction(state: Draft<BaseServiceState<Contract, 'current'>>, { payload }: PayloadAction<Draft<{ id: string }>>) {
  },
})

export const {
  getAction: adminGetContractAction,
  setCurrentAction: adminSetCurrentContractAction,
  adminGetContractByStructureAction
} = slices.actions;

export default slices.reducer;

export const selectContractAdminService = (state: RootState) => state.contractAdminService
export const selectCurrentAdminContract = createSelector(selectContractAdminService, (contractSvc) => contractSvc.current)
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons"
import { Button } from "antd"
import { ReactNode, useState } from "react"

type TypeProps = {
  title?: string | ReactNode
  children?: ReactNode,
  actions?: ReactNode,
  mode?: 'standard' | 'contrast' | 'transparent' | 'collapsible',
  titleClass?: string,
  collapsed?: boolean,
  className?: string;
}
const Section = ({ title = "", children, actions, mode = 'standard', titleClass = "", collapsed = false, className }: TypeProps) => {
  const [close, setClose] = useState<boolean>(collapsed);

  return <div className={`section ${mode} ${className ?? ""}`}>
    {(title || actions) && 
      <div className='section-head flex justify-between'>
      {title && <h4 className={`section-title ${titleClass}`}>
        {mode === 'collapsible' && <Button onClick={_ => setClose(!close)} icon={close ? <CaretRightOutlined /> : <CaretDownOutlined />} size="small" type="text" />}
        <span>{title}</span>
      </h4>}
      {actions && <div className="ml-auto">{actions}</div>}
    </div>
    }

    <div className={`section-body ${close ? 'close' : 'open'}`}>
      {children}
    </div>
  </div>
}

export default Section
import { Models } from '@alcome-rep/alcome-types';
import { DownOutlined } from '@ant-design/icons';
import { Button, Drawer, Dropdown, MenuProps } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { logoutAction, selectAuth, selectUserSupplier } from '../../../Auth/state/auth.slice';
import UserSupplierProfil from '../../../User/components/supplier/UserSupplierProfil/UserSupplierProfilPage';
import AlcomeLogo from '../../../assets/alcome_logo.png';
import { useAppDispatch, useAppSelector } from '../../hooks';
import SupplierEditForm from '../../../SupplierCompany/components/common/SupplierEditForm/supplierEditForm';
import { closeDrawerAction, openDrawerAction } from '../../state/modal.slice';
import { ISupplierCompany } from '@alcome-rep/alcome-types/dist/interfaces/supplier';
import supplierCompanyApi from '../../../SupplierCompany/services/supplierCompany.api';

export default function SupplierNavBar() {
  const { t } = useTranslation()

  const auth = useAppSelector(selectAuth);
  const user = useAppSelector(selectUserSupplier);

  const dispatch = useAppDispatch();
  const logout = () => dispatch(logoutAction());

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true)
  };

  const onClose = () => {
    setOpen(false)
  };

  const [supplierCompany, setSupplierCompany] = useState<ISupplierCompany>()

  useEffect(() => {
    supplierCompanyApi.getMyCompany()
        .then(res => setSupplierCompany(res))
}, [user])

  const editInfos = () => {
    dispatch(openDrawerAction({
      component: <SupplierEditForm supplierCompany={supplierCompany} onSubmit={(data) => {
        dispatch(closeDrawerAction())
        if (data && supplierCompany)
          setSupplierCompany({ ...data })
      }} />,
      options: {
        title: 'Mes informations fournisseur'
      }
    }))
  }

  const items: MenuProps['items'] = [
    {
      key: 'userProfil',
      onClick: showDrawer,
      label: "Données personelles"
    },
    Models.User.IsUserSupplier(user) ? {
      key: 'companyProfil',
      onClick: editInfos,
      label: "Votre fiche fournisseur"
    } : null,
    {
      type: 'divider',
    },
    {
      key: 'logout',
      onClick: logout,
      label: "Se déconnecter"
    }
  ];

  const clickOutsideMenu = (e: any) => {
    if (e.target.closest(".sidebar") == null) toggleMenu(e);
  }

  const toggleMenu = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    let sidebar: HTMLDivElement | null = document.querySelector(".pageLayout-menu");
    if (sidebar === null) return;

    if (sidebar.style.width === "" || sidebar.style.width === "0px") {
      sidebar.style.width = "85%";
      sidebar.style.overflow = "auto";
      document.addEventListener("click", clickOutsideMenu);
      return;
    }

    sidebar.style.width = "0px";
    sidebar.style.overflow = "hidden";
    document.removeEventListener("click", clickOutsideMenu);
    return;
  }

  return (
    <>
      <header className=' bg-alc-blue px-4 h-full flex justify-center headerDeco'>
        <div className="w-full flex items-center justify-between">
          <div className='flex items-center flex-shrink-0 text-white mr-6'>
            <Link to={''}><img src={AlcomeLogo} alt="Alcome logo" className='max-h-8' /></Link>
          </div>

          <div className='print:hidden flex flex-col'>
            {auth && auth.user && (
              <>
                <Dropdown menu={{ items }}>
                  <Button className="btn btn-link text-white hover:text-alc-yellow" onClick={e => e.preventDefault()}>
                    {auth.user.email} <DownOutlined />
                  </Button>
                </Dropdown>
              </>
            )}
            {!auth && (
              <>
                <span className='font-semibold text-right'><a className='text-white' href={`mailto:${t('infos.email')}`}>{t('infos.email')}</a></span>
                <span className='font-light text-right'><a className='text-white' href={`mailto:${t('infos.tel')}`}>{t("infos.tel")}</a></span>
              </>
            )}
          </div>
          <Drawer
            title="Profil utilisateur"
            width='50%'
            onClose={_ => onClose()}
            open={open}
            styles={{ body: { paddingBottom: 80 } }}
          >
            {open && <UserSupplierProfil />}
          </Drawer>
        </div>
      </header>
    </>
  )
};
import { combineReducers } from 'redux';
import annualReviewAdminSlice, { ANNUALREVIEW_ADMIN_SERVICE } from '../../AnnualReview/state/annual-review-admin.slice';
import { ANNUALREVIEW_PORTAL_SERVICE } from '../../AnnualReview/state/types';
import authSlice from '../../Auth/state/auth.slice';
import registerSlice from '../../Auth/state/register.slice';
import { AUTH_SERVICE, REGISTER_SERVICE } from '../../Auth/state/types';
import contractAdminSlice from '../../Contract/state/contract-admin.slice';
import contractPortalSlice from '../../Contract/state/contract-portal.slice';
import { CONTRACT_ADMIN_SERVICE, CONTRACT_PORTAL_SERVICE } from '../../Contract/state/types';
import inventoryAdminSlice from '../../Inventory/state/inventory-admin.slice';
import inventoryPortalSlice from '../../Inventory/state/inventory-portal.slice';
import { INVENTORY_ADMIN_SERVICE, INVENTORY_PORTAL_SERVICE } from '../../Inventory/state/types';
import structureAdminSlice, { STRUCTURE_ADMIN_SERVICE } from '../../Structure/state/structure-admin.slice';
import structurePortalSlice from '../../Structure/state/structure-portal.slice';
import { STRUCTURE_PORTAL_SERVICE } from '../../Structure/state/types';
import modalSlice from '../state/modal.slice';
import { MODAL_SERVICE } from '../state/types';
// import annualReviewSlice from '../../AnnualReview/state/annual-review.slice';
import acquisitionOrderAdminSlice, { ACQUISITION_ORDER_ADMIN_SERVICE } from '../../AcquisitionOrder/state/acquisitionOrder-admin.slice';
import acquisitionOrderPortalSlice, { ACQUISITION_ORDER_SERVICE } from '../../AcquisitionOrder/state/acquisitionOrder-portal.slice';
import annualReviewPortalSlice from '../../AnnualReview/state/annual-review-portal.slice';
import prospectAdminSlice, { PROSPECT_ADMIN_SERVICE } from '../../Crm/state/prospect-admin.slice';

export default function createRootReducer(): typeof rootReducer {
  const rootReducer = combineReducers({
    [MODAL_SERVICE]: modalSlice,
    [AUTH_SERVICE]: authSlice,
    [REGISTER_SERVICE]: registerSlice,

    [CONTRACT_PORTAL_SERVICE]: contractPortalSlice,
    [CONTRACT_ADMIN_SERVICE]: contractAdminSlice,

    [STRUCTURE_PORTAL_SERVICE]: structurePortalSlice,
    [STRUCTURE_ADMIN_SERVICE]: structureAdminSlice,

    [INVENTORY_PORTAL_SERVICE]: inventoryPortalSlice,
    [INVENTORY_ADMIN_SERVICE]: inventoryAdminSlice,

    [ANNUALREVIEW_PORTAL_SERVICE]: annualReviewPortalSlice,
    [ANNUALREVIEW_ADMIN_SERVICE]: annualReviewAdminSlice,

    [ACQUISITION_ORDER_SERVICE]: acquisitionOrderPortalSlice,
    [ACQUISITION_ORDER_ADMIN_SERVICE]: acquisitionOrderAdminSlice,

    [PROSPECT_ADMIN_SERVICE]: prospectAdminSlice,
  });

  return (state, action) => rootReducer(state, action);
}

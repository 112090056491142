import { RouteObject } from "react-router-dom";


const routePrefix = 'admin'
const routeDomain = 'declaration'
const routeParam = 'declarationId'

export const adminDeclarationRouteNames = {
  declarationList: { path: `${routePrefix}/${routeDomain}`, title: `ROUTES.${routeDomain}.list` },
  declarationDetails: { path: `${routePrefix}/${routeDomain}/:${routeParam}`, title: "ROUTES.infos" }
}

export const adminDeclarationRoutes: RouteObject[] = [
  {
    path: adminDeclarationRouteNames.declarationList.path.replace(`${routePrefix}/`, ''),
    element: <></>,
  },
  {
    path: adminDeclarationRouteNames.declarationDetails.path.replace(`${routePrefix}/`, ''),
    element: <></>
  },
]

import { ISupportOrders } from "@alcome-rep/alcome-types/dist/interfaces";
import { User } from "@alcome-rep/alcome-types/dist/models";
import { Button, Table } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../../../Auth/state/auth.slice";
import PageCanvas from "../../../../common/components/Panels/PageCanvas";
import Section from "../../../../common/components/Panels/Section";
import { useAppSelector } from "../../../../common/hooks";
import useQueryParams from "../../../../common/hooks/useQueryParams";
import useTableChange from "../../../../common/hooks/useTableChange";
import { ROUTES } from "../../../../common/router";
import { getRoute } from "../../../../common/tools/router-tools";
import supportOrderApi from "../../../services/supportOrder.api";
import { portalSupportOrderListColumns } from "./PortalSupportOrderListColumns.constant";

const SupportOrderList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const DEFAULT_PAGE_SIZE = 10;
  const [data, setData] = useState<ISupportOrders[]>([]);
  const [count, setCount] = useState<number>();
  const [loading, setloading] = useState<boolean>(true);

  const user = useAppSelector(selectUser)
  const isAdmin = User.IsUserAdmin(user);

  const tableChange = useTableChange();
  const queryParams = useQueryParams()

  useEffect(() => {
    setloading(true)
    supportOrderApi.findAll(queryParams.query, Number(queryParams.page), Number(queryParams.limit))
      .then(res => {
        setData(res.rows)
        setCount(res.count)
        setloading(false)
      })
      .catch(() => {
        setData([])
        setCount(0)
        setloading(false)
      })
  }, [setData, setCount, setloading, queryParams])

  const goToCreate = () => {
    navigate(getRoute(ROUTES.PORTAL_SUPPORT_ORDER_ROUTES.supportOrderCreate))
  };

  return (
    <>
      <PageCanvas
        title="Demandes de soutien à l'acquisition de dispositifs de rue"
        breadCrumbs={[
          ROUTES.PORTAL_USER_ROUTES.home,
          ROUTES.PORTAL_SUPPORT_ORDER_ROUTES.supportOrderList
        ]}
      >
        {/* {!isAdmin &&
                    <div className='py-4 text-alc-blue flex space-x-8'>
                        <div className="card text-alc-blue font-semibold">
                            Dispositifs commandés <span className="text-alc-yellow">{ }</span>
                        </div>
                        <div className="card text-alc-blue font-semibold">
                            Eteignoirs commandés <span className="text-alc-yellow"> { }</span>
                        </div>
                    </div>
                } */}

        {/* {!isAdmin && <SupportCounter structure={}/>} */}
        <Section
          mode="transparent"
          title="Liste des demandes"
          actions={!isAdmin && <Button className="btn rounded-lg items-center" onClick={goToCreate}>
            Ajouter une demande
          </Button>}
        >
          <Table<ISupportOrders>
            rowKey={'id'}
            columns={portalSupportOrderListColumns(queryParams.query, t, navigate)}
            dataSource={data}
            loading={loading}
            onChange={tableChange}
            size="small"
            pagination={
              {
                hideOnSinglePage: true,
                defaultPageSize: DEFAULT_PAGE_SIZE,
                total: count
              }
            } />
        </Section>
      </PageCanvas>
    </>
  )
}

export default SupportOrderList
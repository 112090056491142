import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../../common/hooks";
import { ROUTES } from "../../common/router";
import { selectCurrentContract } from "../state/contract-portal.slice";
import { getRoute } from "../../common/tools/router-tools";

const NoContractFileGuard = ({ children }: any) => {
  const contract = useAppSelector(selectCurrentContract);

  if (contract && contract.contractFileId)
    return <Navigate to={getRoute(ROUTES.PORTAL_CONTRACT_ROUTES.dashboard)}></Navigate>

  if (children)
    return <>{children}</>

  return <Outlet />
}

export default NoContractFileGuard;
import { Tabs } from "antd";
import { TabsProps } from "antd/lib";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useLocation, useNavigate } from "react-router-dom";
import { RouteDeclaration, getRoute } from "../../tools/router-tools";

type PropsType = {
  tabRoutes: Array<RouteDeclaration[keyof RouteDeclaration] & { params?: Params, disabled?: boolean }>,
  white?: boolean,
  entity?: 'annualReview'
}

const NavTabs = ({ tabRoutes, white = false, entity }: PropsType) => {
  const [activeTab, setActiveTab] = useState<string>()

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const items: TabsProps['items'] = useMemo(() => {
    return tabRoutes.map((route, index) => {
      const entityLabel = i18n.exists(route.title ?? '') ? `${index + 1}.${t(route?.title ?? '')}` : '' 
      return {
        label: entity ? entityLabel : i18n.exists(route.title ?? '') ? t(route?.title ?? '') : '',
        key: getRoute(route, route.params),
        disabled: route.disabled ?? false
      }
    })
  }, [t, tabRoutes, i18n, entity])

  const tabChanged = (activeKey: string) => {
    navigate(activeKey)
  }

  useEffect(() => {
    let tab = items.find(item => item.key === pathname);
    if (!tab) {
      tab = [...items].reverse().find(item => pathname.includes(item.key));
    }
    if (!tab)
      return;
    setActiveTab(tab.key)
  }, [pathname, items])

  return <div className={`${white ? 'bg-white' : ''} px-2 rounded-md`}>
    <Tabs activeKey={activeTab} items={items} onChange={tabChanged}></Tabs>
  </div>
}

export default NavTabs;
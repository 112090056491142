import { selectCurrentAdminStructure } from "../../../../Structure/state/structure-admin.slice";
import { useAppSelector } from "../../../../common/hooks";
import { selectAdminCurrentAnnualReview } from "../../../state/annual-review-admin.slice";
import AnnualReviewComponent from "../../common/AnnualReviewDetails/AnnualReviewComponent"

const AdminAnnualReviewPage = () => {
    const annualReview = useAppSelector(selectAdminCurrentAnnualReview);
    const structure = useAppSelector(selectCurrentAdminStructure);

    if (!annualReview || !structure)
        throw new Error()

    return (
        <AnnualReviewComponent annualReview={annualReview} structure={structure} />
    )
}

export default AdminAnnualReviewPage
import { Models } from "@alcome-rep/alcome-types";
import { IContract } from "@alcome-rep/alcome-types/dist/interfaces";
import ApiService from "../../common/services/apiService";

const CONTRACT_PORTAL_PATH_API = 'portal/contract';
export type ContractUploadableProperties = 'contractFileId' | 'contractFileGeneratedId' | 'executiveDeliberationFileId' | 'delegationFileId';

class ContractPortalApi extends ApiService<IContract, ContractUploadableProperties> {
  constructor() {
    super(CONTRACT_PORTAL_PATH_API)
  }

  getMyContract = (): Promise<Models.Contract> => {
    return this.custom<IContract>('get', '')
      .then(r => new Models.Contract(r));
  }

  updateContract = (data: Partial<IContract>): Promise<IContract> => {
    return this.update('', data);
  }

  // ====== DEADLINE ======
  getContractDeadlines = (): Promise<any> => {
    return this.get('deadlines');
  }

  // ====== GENERATED CONTRACT FILE ======
  generateContractFile = () => {
    return this.custom<Models.Contract>('post', 'generate')
      .then(r => new Models.Contract(r))
  }

  downloadGeneratedContractFile = () => {
    return this.downloadFileDoc('contractFileGeneratedId', undefined, 'contract_alcome.pdf')
  }


  deleteGeneratedContractFile = () => {
    return this.deleteFileDoc('contractFileGeneratedId')
  }

  // ====== CONTRACT FILE ======
  uploadContractFile = (file: File) => {
    return this.uploadFileDoc<Models.Contract>('contractFileId', file)
      .then(r => new Models.Contract(r))
  }

  downloadContractFile = () => {
    return this.downloadFileDoc('contractFileId', undefined, 'contrat_alcome.pdf')
  }

  getContractFile = () => {
    return this.getFileDoc('contractFileId')
  }

  deleteContractFile = () => {
    return this.deleteFileDoc('contractFileId')
  }

  // ====== EXECUTIVE DELIBERATION FILE ======
  uploadExecutiveDeliberationFile = (file: File) => {
    return this.uploadFileDoc<Models.Contract>('executiveDeliberationFileId', file)
      .then(r => new Models.Contract(r))
  }

  downloadExecutiveDeliberationFile = () => {
    return this.downloadFileDoc('executiveDeliberationFileId', undefined, 'executive_deliberation.pdf')
  }

  getExecutiveDeliberationFile = () => {
    return this.getFileDoc('executiveDeliberationFileId')
  }

  deleteExecutiveDeliberationFile = () => {
    return this.deleteFileDoc('executiveDeliberationFileId')
  }

  // ====== DELEGATION FILE ======
  uploadDelegationFile = (file: File) => {
    return this.uploadFileDoc<Models.Contract>('delegationFileId', file)
      .then(r => new Models.Contract(r))
  }

  downloadDelegationFile = () => {
    return this.downloadFileDoc('delegationFileId', undefined, 'delegation_signature.pdf')
  }

  getDelegationFile = () => {
    return this.getFileDoc('delegationFileId')
  }

  deleteDelegationFile = () => {
    return this.deleteFileDoc('delegationFileId')
  }

}

const contractPortalApi = new ContractPortalApi();
export default contractPortalApi;
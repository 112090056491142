import { Models } from '@alcome-rep/alcome-types';
import { CommuneTypeEnum, ContractStatusEnum, IBaseCommune, IStructure, InventoryStatusEnum, StructureTypesEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ExportExcelButton, { ExportType } from '../../../../common/components/ExportExcel/ExportExcel';
import FilterEntity from '../../../../common/components/Filters/FilterEntity';
import FilterInput from '../../../../common/components/Filters/FilterInput';
import FilterInputNumber from '../../../../common/components/Filters/FilterInputNumber';
import FilterNavLink from '../../../../common/components/Filters/FilterNavLink';
import FilterPanel from '../../../../common/components/Panels/FilterPanel';
import PageCanvas from '../../../../common/components/Panels/PageCanvas';
import QuickLinksPanel from '../../../../common/components/Panels/QuickLinksPanel';
import useQueryParams from '../../../../common/hooks/useQueryParams';
import useTableChange from '../../../../common/hooks/useTableChange';
import { ROUTES } from '../../../../common/router';
import structureAdminApi from '../../../services/structure-admin.api';
import { structureListColumns } from './StructureListComponent.constant';


const StructureListComponent = () => {
  const [data, setData] = useState<Models.BaseCommune[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setloading] = useState<boolean>(false);

  const tableChange = useTableChange();
  const queryParams = useQueryParams()

  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    setloading(true)
    structureAdminApi.getStructuresWithContract(queryParams.query, Number(queryParams.page), Number(queryParams.limit), { [queryParams.sortField ?? 'name']: queryParams.sortOrder ?? 1 })
      .then(res => {
        setData(res.rows)
        setCount(res.count)
        setloading(false)
      })
      .catch(() => {
        setData([])
        setCount(0)
        setloading(false)
      })
  }, [setData, setCount, setloading, queryParams])

  const exportExcel = async (exportType: ExportType) => {
    let dataToExport: IStructure[] = [];
    switch (exportType) {
      case 'all':
        dataToExport = await structureAdminApi.getStructuresWithContract({ pageSize: -1 }).then(r => r.rows)  // page 1 limit -1 pour ne pas limiter le nombre d'éléments retournés
        break;
      case 'page':
        dataToExport = data
        break;
      case 'query':
        dataToExport = await structureAdminApi.getStructuresWithContract({ query: queryParams.query, page: 1, pageSize: -1 }).then(r => r.rows)
        break;
    }

    dataToExport = dataToExport.map(obj => ({

      code_postale: obj.address?.zip,
      adresse: `${obj.address?.street} , ${obj.address?.city}`,
      type_structure: t(`TYPES.STRUCTURE.StructureTypesEnum.${StructureTypesEnum[obj.structureType]}`),
      email: obj.email,
      téléphone: obj.phone,
      nom: obj.name,
      siren: obj.siren,
      population: (obj as IBaseCommune).population,
      type_commune: (obj as IBaseCommune).communes[0].type,
      nom_communes_rattache: (obj as IBaseCommune).communes && (obj as IBaseCommune).communes.map(c => c.name).join(";"),
      siren_communes_rattache: (obj as IBaseCommune).communes && (obj as IBaseCommune).communes.map(c => c.siren).join(";"),
      email_communes_rattache: (obj as IBaseCommune).communes && (obj as IBaseCommune).communes.map(c => c.email).join(";")

    }) as any)
    return dataToExport;
  }

  return (
    <PageCanvas
      breadCrumbs={[
        ROUTES.ADMIN_USER_ROUTES.home,
        ROUTES.ADMIN_STRUCTURE_ROUTES.structureList
      ]}
      title={<span>Liste des structures <small>({count ? count : 0})</small ></span >}
      actions={<ExportExcelButton fileName='structure-reporting' exportList={exportExcel} />}
    >
      <FilterPanel>
        <FilterInput searchParamName='name' label='Nom' isRegex={true} />
        <FilterInput searchParamName='siren' label='Siren / Insee' isRegex={true} />
        <FilterEntity
          filterName='Type de structure'
          multiple={false}
          queryParamName='structureType'
          options={
            Object.keys(StructureTypesEnum)
              .filter(k => typeof k === 'string')
              // .filter(v => v !== StructureTypesEnum.member_structure)
              .map(k => ({ label: t('TYPES.STRUCTURE.StructureTypesEnum.' + k), value: k }))
          }
        />
        <FilterEntity
          filterName='Type de commune'
          multiple={false}
          queryParamName='communeType'
          options={
            Object.keys(CommuneTypeEnum)
              .filter(k => typeof k === 'string')
              .map(k => ({ label: t('TYPES.STRUCTURE.CommuneTypeEnum.' + k), value: k }))
          }
        />
        <FilterInputNumber searchParamName='contractYear' label='Année de signature' />

      </FilterPanel>

      <FilterPanel>
        <FilterEntity
          filterName='Statut des annexes'
          multiple={false}
          queryParamName='inventory.status'
          options={
            Object.keys(InventoryStatusEnum)
              .filter((k): k is keyof typeof InventoryStatusEnum => !isNaN(+k))
              .map(k => ({ label: t('TYPES.INVENTORY.InventoryStatusEnum.' + InventoryStatusEnum[k]), value: k }))
          }
        />
        <FilterEntity
          filterName='Statut du contrat'
          multiple={false}
          queryParamName='contract.status'
          options={
            Object.keys(ContractStatusEnum)
              .filter((k): k is keyof typeof ContractStatusEnum => !isNaN(+k))
              .map(k => ({ label: t('TYPES.CONTRACT.ContractStatusEnumAdmin.' + ContractStatusEnum[k]), value: k }))
          }
        />
      </FilterPanel>

      <QuickLinksPanel>
        <FilterNavLink queryParams={`?contract.status=${ContractStatusEnum.refused}`} label="Contrats refusés" />
        <FilterNavLink queryParams={`?inventory.status=${InventoryStatusEnum.refused}`} label="Annexes refusées" />

      </QuickLinksPanel >

      <div className=''>
        <Table<Models.BaseCommune>
          columns={structureListColumns(queryParams.query, t, navigate)}
          dataSource={data}
          loading={loading}
          onChange={tableChange}
          size="small"
          rowKey="_id"
          scroll={{ scrollToFirstRowOnChange: true, x: 1800 }}
          pagination={
            {
              hideOnSinglePage: true,
              current: queryParams.page,
              pageSize: queryParams.limit,
              total: count,
            }
          } />
      </div>
    </PageCanvas >

  );
};

export default StructureListComponent;
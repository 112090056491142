import { Alert } from "antd"
import { AlertProps } from "antd/lib"
import { ReactNode } from "react"

type PropsType = {
  title?: string,
  body?: string | ReactNode,
  children?: ReactNode,
  type?: AlertProps['type'],
  icon?: ReactNode,
  className?: string
}

const Info = ({ body, title, type = "info", icon = null, className, children }: PropsType) => {
  return <Alert
    message={title}
    description={body ?? children}
    type={type}
    showIcon
    icon={icon}
    className={className}
  />
}
export default Info
import { IUser, UserTypeEnum } from '@alcome-rep/alcome-types/dist/interfaces/user';
import { Checkbox, Form, Input } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import userApi from '../../../User/services/user.api';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import useFormRules from '../../../common/hooks/useFormRules';
import { loginAction } from '../../state/auth.slice';
import { locallySaveStructureAction, locallySaveUserAction, selectLocallySavedStructure, selectLocallySavedUser } from '../../state/register.slice';
import NextStepComponent from './NextStepComponent';
import { ROUTES } from '../../../common/router';


const RegisterCreate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(selectLocallySavedUser)
  const structure = useAppSelector(selectLocallySavedStructure)

  if (structure === null) {
    navigate(ROUTES.LOGIN)
  }

  const submit = async (formData: IUser) => {
    const data = { user: { ...user, ...formData }, structure: structure! }
    try {
      await userApi.register(data);
      dispatch(loginAction({ email: formData.email, password: formData.password, userType: UserTypeEnum.portal }))

      // remove useless stored data in redux state
      dispatch(locallySaveStructureAction(null));
      dispatch(locallySaveUserAction(null));

    } catch { }
  }

  const { required, requireTrue, email, password } = useFormRules();

  return (
    <>
      <div>
        <h2 className='mb-4'>Votre profil</h2>
        <p>Ces informations vous concerne. Les informations que vous allez renseigner ici vous permettrons de vous connecter au portail par la suite.
          <br />
          <br />
          <b>Veuillez faire attention aux informations de contact renseignées ci-dessous.</b>
        </p>
        <Form
          name="basic"
          layout='vertical'
          onFinish={submit}
        >
          <div className='grid grid-cols-2 gap-x-4'>
            <Form.Item
              label="Email de connexion"
              name="email"
              rules={[required, email]}
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              label="Mot de passe"
              name="password"
              tooltip="8 caractères min., une majuscule, un chiffre et un caractère spéciale @$!%*?&"
              rules={[required, password]}
            >
              <Input type='password'></Input>
            </Form.Item>
          </div>
          <Form.Item
            name={'cgv'}
            valuePropName="checked"
            rules={[required, requireTrue]}
          >
            <Checkbox>J'autorise ce site à conserver mes données personnelles. Aucune exploitation commerciale ne sera faite des données conservées.</Checkbox>
          </Form.Item>
          <p className='text-xs'>
            Les informations portées sur ce formulaire suivies d’un astérisque sont obligatoires, à défaut nous ne serons pas en mesure de traiter votre demande d’adhésion.
            Les informations font l’objet d’un traitement informatisé par ALCOME, destiné à répondre et assurer le suivi de votre candidature.

            Vos données personnelles seront conservées jusqu’à la suppression de votre compte.

            Conformément à la réglementation applicable, vous disposez des droits (i) d’accès, (ii) de rectification, (iii) d’effacement, (iv) de limitation, (v) d’opposition, (vi) de portabilité et de (vii) de rédiger des directives post-mortem générales ou particulières relatives à la conservation, à l'effacement et à la communication sur vos données personnelles. En cas de litige vous disposez du droit de saisir une autorité de contrôle.

            Pour exercer ces droits, vous pouvez nous contacter à l’adresse suivante : rgpd@alcome.eco
          </p>

          <p className='text-xs'>

            Vous bénéficiez en toute hypothèse du droit de retirer votre consentement à tout moment en nous écrivant à : rgpd@alcome.eco.

            Si vous souhaiter obtenir davantage d’information concernant le traitement de vos données personnelles,
            nous vous invitons à consulter notre Politique de confidentialité via le lien suivant : <Link to='/policy/confidential' target={'_blank'} className='underline font-semibold'>Politique de confidentialité</Link>.
          </p>
          <Form.Item>
            <NextStepComponent />
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default RegisterCreate;
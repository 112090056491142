import { AnnualReview } from "@alcome-rep/alcome-types/dist/models";
import { Draft, PayloadAction, createSelector } from "@reduxjs/toolkit";
import factoryCreateSlice from "../../common/state/slice.factory";
import { RootState } from "../../common/store";
import { UploadDocumentPayload } from "../../Inventory/state/fileDoc.types";
import { BaseServiceState } from "../../common/state/types";
import { AdminUpdateRevenueOrderFilePayload } from "./types";

export const ANNUALREVIEW_ADMIN_SERVICE = "annualReviewAdminService"
// eslint-disable-next-line
export type ANNUALREVIEW_ADMIN_SERVICE = typeof ANNUALREVIEW_ADMIN_SERVICE; // Typescript line

export type AnnualReviewServiceState = {
  current: AnnualReview | null
};

const slices = factoryCreateSlice<"AdminAnnualReview", AnnualReview>('AdminAnnualReview', {
  adminUploadDocumentActions(state: Draft<AnnualReviewServiceState>, { payload }: PayloadAction<Draft<UploadDocumentPayload<AnnualReview>>>) { },
  adminRefuseOrderActions(state: Draft<AnnualReviewServiceState>, { payload }: PayloadAction<Draft<{ id: string, revenueOrderComment: string }>>) { },
  adminValidStatusActions(state: Draft<BaseServiceState<AnnualReview, 'current'>>, { payload }: PayloadAction<Draft<{ id: string }>>) {
  },
  adminRefuseStatusActions(state: Draft<BaseServiceState<AnnualReview, 'current'>>, { payload }: PayloadAction<Draft<{ id: string, comment?: string }>>) {
  },
  generateAnnualReviewDocumentActions(state: Draft<BaseServiceState<AnnualReview, 'current'>>, { payload }: PayloadAction<Draft<{ id: string, comment?: string }>>) {
  },
  adminSetStatementActions(state: Draft<BaseServiceState<AnnualReview, 'current'>>, { payload }: PayloadAction<Draft<{ annualReviewId: string }>>) {
  },
  adminGenerateStatementDocumentActions(state: Draft<BaseServiceState<AnnualReview, 'current'>>, { payload }: PayloadAction<Draft<{ annualReviewId: string }>>) {
  },
  adminValidateStatementActions(state: Draft<BaseServiceState<AnnualReview, 'current'>>, { payload }: PayloadAction<Draft<{ annualReviewId: string }>>) {
  },
  adminAcceptOrderActions(state: Draft<BaseServiceState<AnnualReview, 'current'>>, { payload }: PayloadAction<Draft<{ annualReviewId: string }>>) {
  },
  adminUpdateRevenueOrderFileActions(state: Draft<BaseServiceState<AnnualReview, 'current'>>, { payload }: PayloadAction<Draft<{ annualReviewId: string, propertyName: string, data: AdminUpdateRevenueOrderFilePayload }>>) {
  },
  adminSetStatementPaidActions(state: Draft<BaseServiceState<AnnualReview, 'current'>>, { payload }: PayloadAction<Draft<{ annualReviewId: string, amountStatement: number, paymentDate: Date }>>) {
  },
})


export const {
  getAction: adminGetAnnualReviewAction,
  updateAction: adminUpdateAnnualReviewAction,
  setCurrentAction: adminSetCurrentAnnualReviewAction,
  adminUploadDocumentActions,
  adminRefuseOrderActions,
  adminValidStatusActions,
  adminRefuseStatusActions,
  generateAnnualReviewDocumentActions,
  adminSetStatementActions,
  adminGenerateStatementDocumentActions,
  adminValidateStatementActions,
  adminAcceptOrderActions,
  adminUpdateRevenueOrderFileActions,
  adminSetStatementPaidActions
} = slices.actions;

export default slices.reducer;

export const selectAdminAnnualReviewService = (state: RootState) => state.annualReviewAdminService
// export const selectAdminCurrentAnnualReview= (state: RootState) => selectAdminAnnualReviewService(state).current
export const selectAdminCurrentAnnualReview = createSelector(selectAdminAnnualReviewService, (annualreviewSvc) => annualreviewSvc.current)
import { ContractStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces"
import { Contract } from "@alcome-rep/alcome-types/dist/models"
import { Tag } from "antd"
import { useTranslation } from "react-i18next"

type PropsType = {
  contract: Contract,
  translationString?: string
}

const ContractStatus = ({ contract, translationString = "ContractStatusEnum" }: PropsType) => {
  const { t } = useTranslation();

  return <Tag className="space-x-2" color={t(`UI.TAG.${translationString}.${ContractStatusEnum[contract?.status]}`)}>
    {t(`TYPES.CONTRACT.${translationString}.${ContractStatusEnum[contract?.status]}`)}
  </Tag>
}

export default ContractStatus
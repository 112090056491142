import { ExportOutlined } from '@ant-design/icons';
import { Button, Radio, RadioChangeEvent } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { useState } from 'react';
import * as XLSX from "xlsx";

type ExportExcelType = {
  fileName: string,
  exportList: (exportType: 'all' | 'query' | 'page') => Promise<Array<any>>,
}

export type ExportType = 'all' | 'query' | 'page'

const ExportModalOptions = ({ fileName, exportList }: ExportExcelType) => {
  const [value, setValue] = useState<'all' | 'query' | 'page'>('all');

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const exportExcel = async (exportType: any) => {
    const data: any[] = await exportList(exportType);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);

    XLSX.writeFile(wb, fileName + ".xlsx");
  }

  return <>
    <Radio.Group onChange={onChange} defaultValue={value}>
      <Radio value="all">Exporter toutes les données </Radio>
      <Radio value="page">Exporter les données de la page en cours</Radio>
      <Radio value="query">Exporter toutes les données de la recherche en cours</Radio>
    </Radio.Group>
    <div className='flex justify-end space-x-4'>
      <Button type="link" htmlType='button'>Annuler</Button>
      <Button type="primary" htmlType='submit' onClick={() => exportExcel(value)}>Exporter</Button>
    </div>
  </>
}

export { ExportModalOptions };

const ExportExcelButton = ({ fileName, exportList }: ExportExcelType) => {

  const [value, setValue] = useState<'all' | 'query' | 'page'>('all');

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const exportExcel = async (exportType: any) => {
    const data: any[] = await exportList(exportType);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);

    XLSX.writeFile(wb, fileName + ".xlsx");
  }

  const exportModal = async () => {
    confirm({
      title: 'Sélectionnez les données que vous voulez exporter',
      icon: <ExportOutlined />,
      content: (
        <>
          <Radio.Group onChange={onChange} defaultValue={value}>
            <Radio value="all">Exporter toutes les données </Radio>
            <Radio value="page">Exporter les données de la page en cours</Radio>
            <Radio value="query">Exporter toutes les données de la recherche en cours</Radio>
          </Radio.Group>
        </>
      ),
      okText: 'Exporter',
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okButtonProps: { className: "btn" },
      onOk() {
        exportExcel(value)
      },
      onCancel() {
      },
    });
  }

  return (
    <>
      <div>
        <Button htmlType="button" type="primary" className="bg-alc-blue " onClick={exportModal}>Exporter les données</Button>
      </div>
    </>
  );
};

export default ExportExcelButton

import { IUserAdmin, UserTypeEnum } from '@alcome-rep/alcome-types/dist/interfaces/user';
import { BaseCommune } from '@alcome-rep/alcome-types/dist/models';
import { EditOutlined, ExclamationCircleOutlined, ThunderboltOutlined, UserOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import StructureAnnualReviewList from '../../../../AnnualReview/components/admin/StructureAnnualReviewList/StructureAnnualReviewList';
import AdminContractFiles from '../../../../Contract/components/admin/AdminContractFiles/AdminContractFiles';
import AdminInventoryDetails from '../../../../Contract/components/admin/AdminInventoryDetails/AdminInventoryDetails';
import ContractSignatory from '../../../../Contract/components/admin/ContractSignatory/ContractSignatory';
import { selectCurrentAdminContract } from '../../../../Contract/state/contract-admin.slice';
import RegionalDelegateSection from '../../../../Dashboard/components/portal/RegionalDelegateSection';
import { selectCurrentAdminInventory } from '../../../../Inventory/state/inventory-admin.slice';
import StructureOrderList from '../../../../Order/components/StructureOrderList.tsx/StructureOrderList';
import userApi from '../../../../User/services/user.api';
import FeatureAvailable from '../../../../common/components/FeaturesAvailable/FeatureAvailable';
import LoadingScreen from '../../../../common/components/LoadingScreen';
import ActionPanel from '../../../../common/components/Panels/ActionPanel';
import PageCanvas from '../../../../common/components/Panels/PageCanvas';
import Section from '../../../../common/components/Panels/Section';
import LogAdminList from '../../../../common/components/admin/LogActions/logAdminList';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { ROUTES } from '../../../../common/router';
import { closeDrawerAction, openDrawerAction } from '../../../../common/state/modal.slice';
import { getRoute } from '../../../../common/tools/router-tools';
import structureAdminApi from '../../../services/structure-admin.api';
import structureApi from '../../../services/structure.api';
import { selectCurrentAdminCommune } from '../../../state/structure-admin.slice';
import StructureType from '../../common/StructureType';
import StructureUserList from '../StructureUserList/StructureUserList';
import StructureDetails from './StructureDetails';
import StructureFigures from './StructureFigures';
import StructureInfosForm from './StructureInfosForm';
import CommuneListComponent from './communeListComponents';
import KitDownloadedList from './KitDownloadedList';

const StructurePage = () => {
  const dispatch = useAppDispatch();

  const structure: BaseCommune | null = useAppSelector(selectCurrentAdminCommune)
  const contract = useAppSelector(selectCurrentAdminContract)
  const inventory = useAppSelector(selectCurrentAdminInventory)

  const [department, setDepartment] = useState<string>();
  const [regionalDelegate, setRegionalDelegate] = useState<IUserAdmin>()

  useEffect(() => {
    if (structure) {
      structureApi.getStructureDepartment(structure.id as string)
        .then(res => {
          setDepartment(res)
          userApi.findRegionalDelegate(`${res}`)
            .then(resp => setRegionalDelegate(resp))
        })
    }
  }, [structure])

  const navigate = useNavigate()

  const deleteStructure = async () => {
    confirm({
      title: 'Supprimer une structure',
      icon: <ExclamationCircleOutlined />,
      content: 'Êtes-vous sûr de vouloir supprimer la structure ?',
      cancelText: 'Annuler',
      closable: true,
      okCancel: true,
      okButtonProps: { className: "btn" },
      onOk() {
        structure && structureAdminApi.deleteStructure(structure.id as string)
          .then(_ => navigate(getRoute(ROUTES.ADMIN_STRUCTURE_ROUTES.structureList)))
      },
      onCancel() {
      },
    });
  }

  const editInfos = () => {
    dispatch(openDrawerAction({
      component: <StructureInfosForm structure={structure!} formCb={_ => dispatch(closeDrawerAction())} />,
      options: {
        title: "Profil administratif"
      }
    }))
  }

  const editCommunesList = () => {
    dispatch(openDrawerAction({
      component: <CommuneListComponent />,
      options: {
        title: "Liste des communes",
        // size:"large"
        width: '66%'
      }
    }))
  }

  const editUserList = () => {
    dispatch(openDrawerAction({
      component: <StructureUserList cb={() => dispatch(closeDrawerAction())} />,
      options: {
        title: "Liste des utilisateurs",
        width: '66%'
      }
    }))
  }

  const showLogs = () => {
    dispatch(openDrawerAction({
      component: <LogAdminList structure={structure} />,
      options: {
        title: "Historique des actions",
        width: '66%'
      }
    }))
  }

  if (!structure || !contract || !inventory)
    return <LoadingScreen />

  return (
    <>
      <PageCanvas breadCrumbs={[
        ROUTES.ADMIN_USER_ROUTES.home,
        ROUTES.ADMIN_STRUCTURE_ROUTES.structureList,
        ROUTES.ADMIN_STRUCTURE_ROUTES.structureDetails
      ]} title={<div>
        <div>{structure.name}</div>
        <div className='text-sm'><StructureType structure={structure} /></div>
      </div>}
        actions={<Link to={getRoute(ROUTES.ADMIN_CONTRACT_ROUTES.contractDetails, { structureId: structure.id })} className="font-semibold">Voir le contrat</Link>}
      >

        <StructureFigures contract={contract} inventory={inventory} structure={structure} />

        <div className="grid grid-cols-3 gap-4 my-4">
          <div className="col-span-1">

            <Section
              title="Informations"
              actions={<Button icon={<EditOutlined />} type="text" onClick={_ => editInfos()} />}
            >
              <StructureDetails structure={structure} />
              {regionalDelegate && (
                <div className='mt-2'>
                  <RegionalDelegateSection regionalDelegate={regionalDelegate} department={department as string} isPortal={false} />
                </div>
              )}
            </Section >

            <ActionPanel className="my-2" title={<UserOutlined />} content={<Button onClick={editUserList} type="link">Utilisateurs de la structure</Button>} />
            <ActionPanel className="my-2" title={<ThunderboltOutlined />} content={<Button onClick={showLogs} type="link">Historique des actions</Button>} />
            {structure.isGroupement() && (
              <ActionPanel className="my-2" title={<ThunderboltOutlined />} content={<Button onClick={editCommunesList} type="link">Liste des communes</Button>} />
            )}
          </div>

          <div className="col-span-2">
            <Section
              title="Fichiers / Contrat"
              mode="transparent"
            >
              <AdminContractFiles contract={contract} />
            </Section>

            <Section
              title="État des lieux / Annexes"
              mode="transparent"
            >
              <AdminInventoryDetails inventory={inventory}></AdminInventoryDetails>
            </Section>

            <Section
              title="Signataire du contrat"
              mode="standard"
            >
              <ContractSignatory contract={contract} inventory={inventory} />

            </Section>
          </div>

        </div >

        <Section
          title="Bilans annuels"
          mode="transparent"
        >
          <div >Consultez et complétez vos bilans annuels en cliquant sur la campagne souhaitée.</div>
          {structure && <StructureAnnualReviewList structure={structure} />}
        </Section >

        <div className='my-4'>
          <Section
            title="Liste des commandes"
            mode="transparent">
            <StructureOrderList structure={structure} />
          </Section>
        </div>
        
        <div className='my-4'>
          <Section
            title="Kit de communication téléchargés"
            mode='transparent'
          >
            <KitDownloadedList structure={structure} />
          </Section>
        </div>

        <FeatureAvailable userType={UserTypeEnum.admin} featureName='admin.structure.delete'>
          <Section
            title="Zone de danger"
            titleClass='!text-alc-danger'>
            <button className='font-semibold text-alc-danger underline' onClick={deleteStructure}>Supprimer la structure </button>
          </Section>
        </FeatureAvailable>


      </PageCanvas>
    </>
  );
};

export default StructurePage;
import { Models } from "@alcome-rep/alcome-types";
import { AnnualReviewStatusEnum, IAnnualReview, IInventory, InventoryStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { Button, Form, Radio } from "antd";
import { useTranslation } from "react-i18next";
import annualReviewAdminApi from "../../../../AnnualReview/services/annual-review-admin.api";
import { adminSetCurrentAnnualReviewAction } from "../../../../AnnualReview/state/annual-review-admin.slice";
import { setCurrentPortalAnnualReviewAction } from "../../../../AnnualReview/state/annual-review-portal.slice";
import inventoryAdminApi from "../../../../Inventory/services/inventory-admin.api";
import { adminSetCurrentInventoryAction } from "../../../../Inventory/state/inventory-admin.slice";
import { setCurrentInventoryAction } from "../../../../Inventory/state/inventory-portal.slice";
import { useAppDispatch } from "../../../hooks";
import { closeDrawerAction } from "../../../state/modal.slice";

const UpdateStatus = ({ entity, entityName }: { entity: any, entityName: string }) => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const submit = (formData: any) => {
    if (entityName === "inventory") {
      inventoryAdminApi.changeStatus(entity.id, { status: formData.status, dontNotif: true })
        .then((res: IInventory) => {
          dispatch(setCurrentInventoryAction(new Models.Inventory(res)))
          dispatch(adminSetCurrentInventoryAction(res))
        })
      dispatch(closeDrawerAction())
    } else if (entityName === "annualReview") {
      annualReviewAdminApi.changeStatus(entity.id, { status: formData.status, dontNotif: true })
        .then((res: IAnnualReview) => {
          dispatch(setCurrentPortalAnnualReviewAction(res))
          dispatch(adminSetCurrentAnnualReviewAction(res))
        })
      dispatch(closeDrawerAction())
    }
    // else if (entityName === "contract") {
    //     apiContract.default.adminChangeStatus({ id: entity.id, changeStatus: { status: formData.status, dontNotif: true } })
    //         .then(_ => setOpen(false))
    // } 
    return;
  }

  return (
    <>
      <Form
        name="basic"
        layout='vertical'
        onFinish={submit}
        initialValues={entity}
      >
        <Form.Item
          label="Statut"
          name="status"
          className=''
        >
          <Radio.Group>
            {/* {entityName === "contract" && (
                            Object.keys(ContractStatusEnum)
                                .filter(v => !isNaN(+v))
                                .map(v => (
                                    <div className="grid grid-cols-1">
                                        <Radio key={v} value={+v}>{t(`types.contract.ContractStatusEnum.${ContractStatusEnum[v as any]}`)}</Radio>
                                    </div>
                                ))
                        )} */}
            {entityName === "inventory" && (
              Object.keys(InventoryStatusEnum)
                .filter(v => !isNaN(+v))
                .map(v => (
                  <div className="grid grid-cols-1">
                    <Radio key={v} value={+v}>{t(`TYPES.INVENTORY.InventoryStatusEnum.${InventoryStatusEnum[v as any]}`)}</Radio>
                  </div>
                ))
            )}
            {entityName === "annualReview" && (
              Object.keys(AnnualReviewStatusEnum)
                .filter(v => !isNaN(+v))
                .map(v => (
                  <div className="grid grid-cols-1">
                    <Radio key={v} value={+v}>{t(`TYPES.ANNUAL_REVIEW.AnnualReviewStatusEnum.${AnnualReviewStatusEnum[v as any]}`)}</Radio>
                  </div>
                ))
            )}
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" className="bg-alc-blue " >
            Modifier les informations
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UpdateStatus;

import { Models } from "@alcome-rep/alcome-types";
import { IInventory } from "@alcome-rep/alcome-types/dist/interfaces";
import ApiService from "../../common/services/apiService";

const INVENTORY_PORTAL_PATH_API = 'portal/inventory';

export const progressKeys = {
  generateInventory: `POST_${INVENTORY_PORTAL_PATH_API}login`,
}

export type InventoryUploadableProperties = 'inventoryFileId' | 'hotspotSrcFile' | 'collectPointsSrcFile';

export type CreateInventoryPayload = {}

export type UpdateInventoryInfosPayload = Pick<
  IInventory,
  'communes' |
  'cleaningContext' |
  'inChargeOfTrashes' |
  'trashesContext' |
  'trashesCommunes' |
  'voiriesInteretCommunautaire' |
  'hotspots' |
  'collectPoints'
>


class InventoryPortalApi extends ApiService<IInventory, InventoryUploadableProperties> {
  constructor() {
    super(INVENTORY_PORTAL_PATH_API)
  }

  /**
   * @description Create an inventory
   * @param data 
   * @returns Return created inventory
   */
  createInventory = (data: CreateInventoryPayload): Promise<Models.Inventory> => {
    return this.create(data)
      .then(r => new Models.Inventory(r))
  }

  /**
   * @description get my inventory
   * @returns Return my inventory
   */
  getInventory = (): Promise<Models.Inventory> => {
    return this.get('')
      .then(r => new Models.Inventory(r))
  }

  /**
   * @description Update inventory infos
   * @param inventoryId 
   * @param data 
   * @returns Updated inventory
   */
  updateInventoryInfos = (inventoryId: string, data: UpdateInventoryInfosPayload): Promise<Models.Inventory> => {
    return this.update(inventoryId + '/infos', data)
      .then(r => new Models.Inventory(r))
  }

  /**
   * @description Download InventoryFileDoc
   * @param propertyName 
   * @returns InventoryFile
   */
  downloadInventoryFile = (): Promise<any> => {
    return this.downloadFileDoc('inventoryFileId')
  }
  
  /**
   * @description get InventoryFileDoc
   * @param propertyName 
   * @returns InventoryFile
   */
  getInventoryFile = (): Promise<any> => {
    return this.getFileDoc('inventoryFileId')
  }

  /**
   * @description Upload InventoryFileDoc
   * @param inventoryId 
   * @param file 
   * @returns Inventory updated
   */
  uploadInventoryFile = (inventoryId: string, file: File): Promise<Models.Inventory> => {
    return this.uploadFileDoc<Models.Inventory>('inventoryFileId', file, inventoryId)
      .then(r => new Models.Inventory(r))
  }

  // ====== STATUS ======

  /**
   * @description Validate inventory status
   * @param inventoryId 
   * @returns Inventory with updated status
   */
  completeInventoryStatus = (inventoryId: string): Promise<Models.Inventory> => {
    return this.custom<IInventory>('put', 'completeStatus', inventoryId)
      .then(r => new Models.Inventory(r))
  }

  // ====== DEADLINE ======
  getInventoryDeadlines = (): Promise<any> => {
    return this.get('deadlines');
  }

  checkHotspotCount(){
    return this.custom<number>('get','checkHotspotCount')
  }

}

const inventoryPortalApi = new InventoryPortalApi();
export default inventoryPortalApi;
import { DispositifCategoryEnum, DispositifTypeEnum, isAshtrayType, isExtinguisherType } from "@alcome-rep/alcome-types/dist/interfaces";
import { IProduct, ProductStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces/product";
import { Form, Image, Input, InputNumber, Radio, Select } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ShippingWeightSteps from "../../../../SupplierCompany/components/common/ShippingWeightSteps";
import SupplierShippingSteps from "../../../../SupplierCompany/components/common/SupplierEditForm/SupplierShippingSteps";
import LoadingScreen from "../../../../common/components/LoadingScreen";
import PageCanvas from "../../../../common/components/Panels/PageCanvas";
import Section from "../../../../common/components/Panels/Section";
import useFormRules from "../../../../common/hooks/useFormRules";
import { ROUTES } from "../../../../common/router";
import { getBase64 } from "../../../../common/tools/global-tools";
import productApi from "../../../services/product-api";
import AdminProductPriceSteps from "../../admin/AdminProductDetails/AdminProductPriceSteps";

// type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export const SupplierProductDetails = () => {
    const { productId } = useParams();
    const [form] = useForm()
    const { required } = useFormRules();
    const { t } = useTranslation();
    const [product, setProduct] = useState<IProduct>()
    const [productImages, setProductImages] = useState<string[]>([])

    const selectedCategory = useWatch('category', form)
    // const auth = useAppSelector(selectAuth)

    // const [suppliers, setSuppliers] = useState<{ value: string, label: string }[]>([])
    // const [selectedItem, setSelectedItem] = useState<string | null>(null)

    // const [previewOpen, setPreviewOpen] = useState(false);
    // const [previewImage, setPreviewImage] = useState('');
    // const [fileList, setFileList] = useState<UploadFile[]>([])

    const getData = useMemo(() => async () => {
        const res = await productApi.getProduct(productId as string);
        setProduct(res);
    }, [productId, setProduct])

    useEffect(() => {
        if (product && product.images) {
            Promise.all(
                product.images.map(path => {
                    return productApi.getProductImage(product.id!, path)
                        .then(blob => {
                            return getBase64(new Blob([blob]))
                        })
                })
            ).then(img => setProductImages(img))
        }

    }, [product])

    useEffect(() => {
        getData()
    }, [getData]);


    if (!product) {
        return <LoadingScreen></LoadingScreen>
    }

    return (
        <PageCanvas
            breadCrumbs={[
                ROUTES.SUPPLIER_USER_ROUTES.home,
                ROUTES.SUPPLIER_USER_ROUTES.productList,
                ROUTES.SUPPLIER_USER_ROUTES.productDetails
            ]}
            title={<span>Fiche produit</span>}
        >

            <Form
                form={form}
                name="basic"
                layout='vertical'
                initialValues={{ ...product, shippingSteps: product.shippingSteps || [], shippingWeightSteps: product.shippingWeightSteps || [] }}
            >
                <Form.Item name={['priceSteps']} hidden />
                <Form.Item name={['shippingSteps']} hidden />
                <Form.Item name={['shippingWeightSteps']} hidden />

                <Section title="Informations">
                    <div className="flex space-x-4">
                        <Form.Item
                            label="Nom du produit"
                            name="name"
                            className="flex-1"
                            rules={[required]}
                        >
                            <Input disabled></Input>
                        </Form.Item>

                        <Form.Item
                            label="Référence"
                            name="reference"
                            rules={[required]}
                            className="w-44"
                        >
                            <Input disabled></Input>
                        </Form.Item>
                    </div>

                    {product.productDocumentUrl && (
                        <div className="mb-4">
                            <label>Fiche technique : </label>
                            <a href={`${product.productDocumentUrl}`}>{product.productDocumentUrl}</a>
                        </div>
                    )}

                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[required]}
                    >
                        <TextArea disabled autoSize={{ minRows: 10 }} />
                    </Form.Item>

                    {/* <Form.Item
                        label="Statut"
                        name="status"
                        rules={[required]}
                    >
                        <Select disabled>
                            {Object.keys(ProductStatusEnum)
                                .filter(k => !isNaN(k as any))
                                .map(status => {
                                    return <Select.Option key={status} value={+status}>{t(`TYPES.PRODUCT.ProductStatusEnum.${ProductStatusEnum[status as any]}`)}</Select.Option>
                                })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Types de structure"
                        name='structureValibility'
                        rules={[required]}
                    >
                        <Select
                            disabled
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Selectionnez le type de structure"
                        >
                            {Object.keys(StructureTypesEnum).map(type => (
                                <Select.Option key={type} value={type}>{t(`TYPES.STRUCTURE.StructureTypesEnum.${type}`)}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item> */}

                </Section>

                <Section title="Disponibilité">

                    <div className="flex space-x-4">
                        <Form.Item
                            label="Catégorie"
                            name="category"
                            rules={[required]}
                            className="w-48"
                        >
                            <Select
                                disabled
                                options={Object.keys(DispositifCategoryEnum)
                                    .map(status => ({
                                        label: t(`TYPES.INVENTORY.DispositifCategoryEnum.${status as any}`),
                                        value: status
                                    }))
                                }
                            />
                        </Form.Item>

                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[required]}
                            className="flex-1"
                        >
                            <Select
                                disabled
                                options={Object.keys(DispositifTypeEnum)
                                    .filter((k: any) => selectedCategory === DispositifCategoryEnum.ashtray ? isAshtrayType(k) : isExtinguisherType(k))
                                    .map(status => ({ label: t(`TYPES.INVENTORY.DispositifTypeEnum.${status as any}`), value: status }))
                                }
                            />
                        </Form.Item>
                    </div>

                    <Form.Item
                        label="Disponibilité"
                        name="status"
                        rules={[required]}
                    // initialValue={ProductStatusEnum.product_available}
                    >
                        <Radio.Group disabled>
                            {Object.keys(ProductStatusEnum)
                                .filter(k => !isNaN(k as any))
                                .map(status =>
                                    <Radio disabled key={status} value={+status}>{t(`TYPES.PRODUCT.ProductStatusEnum.${ProductStatusEnum[status as any]}`)}</Radio>
                                )
                            }
                        </Radio.Group>

                    </Form.Item>


                </Section>

                {productImages.length > 0 && (
                    <Section title="Visuels">
                        <div className="flex space-x-6 flex-wrap items-center">
                            {productImages.map((blob, index) => (
                                <div key={index} className="max-h-32 w-32 mb-12">
                                    <div className="flex space-x-4 items-center bg-gray-50 p-2">
                                        <Image
                                            src={blob}
                                            className="object-container"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Section>
                )}
                <Section title="Tarif principal et poids">
                    <div className="flex space-x-8">

                        <div className="flex space-x-8">
                            <Form.Item
                                label="Poids"
                                name="weight"
                                className="w-20"
                                rules={[required]}
                            >
                                <InputNumber disabled suffix="kg" min={1}></InputNumber>
                            </Form.Item>

                            <Form.Item
                                label="Tarif"
                                name="price"
                                className="w-20"
                                rules={[required]}
                            >
                                <InputNumber disabled suffix="€" min={1}></InputNumber>
                            </Form.Item>

                            <Form.Item
                                label="Frais de livraison"
                                name="shippingFees"
                                className="w-44"
                            >
                                <InputNumber disabled suffix="€" />
                            </Form.Item>
                        </div>

                        {/* <Form.Item
              label="TVA"
              name="tax"
              rules={[required]}
            >
              <InputNumber min={0}></InputNumber>
            </Form.Item> */}
                    </div>
                </Section>

                <Section title="Paliers de tarif" mode="transparent">
                    <AdminProductPriceSteps readOnly={true} />
                </Section>

                <Section title="Paliers de frais de livraison" mode="transparent">
                    <SupplierShippingSteps property="shippingSteps" readOnly={true} />
                </Section>

                <Section title="Frais de livraison selon le poids" mode="transparent">
                    <ShippingWeightSteps property="shippingWeightSteps" readOnly={true} />
                </Section>
            </Form >

        </PageCanvas>
    )
}

export default SupplierProductDetails

import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { Dayjs, isDayjs } from "dayjs";

type PropsType = {
  value?: Dayjs | Date | string,
  stringFormat?: 'slash' | 'words',
  time?: boolean,
  date?: boolean
}

const DateField = ({ value, stringFormat = 'slash', time = false, date = true }: PropsType) => {
  const invalid = <> - </>;
  if (!value)
    return invalid;

  let tmp = value;
  try {
    if (isDayjs(value))
      tmp = value.toDate()
    else
      tmp = new Date(value)
  }
  catch (_) {
    return invalid;
  }

  let dateString = 'dd/MM/yyyy'
  if (stringFormat === 'words')
    dateString = 'd MMMM yyyy'

  let timeString = 'hh:mm'
  if (stringFormat === 'words')
    timeString = 'hh\'Hmm'
  // "' 'd MMMM yyyy'"
  return <span>{value && format(tmp, `${date === true ? `${dateString} ` : ''}${time === true ? `${timeString}` : ''}`, { locale: fr })}</span>
}

export default DateField
import { Dispatch } from '@reduxjs/toolkit';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';
import 'moment/locale/fr';
import LoadingScreen from '../../../../common/components/LoadingScreen';
import Section from '../../../../common/components/Panels/Section';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { debounce, randomNumberId } from '../../../../common/tools/global-tools';
import { selectAdminCurrentAnnualReview } from '../../../state/annual-review-admin.slice';
import { updateAnnualReviewLatestInfosAction } from '../../../state/annual-review-portal.slice';
import { selectCurrentPortalAnnualReview } from '../../../state/annual-review-portal.slice';
import AwarnessTableComponent from '../AnnualReviewTables/awarnessTable';
import PoliceArrestTableComponent from '../AnnualReviewTables/policeArrestsTable';
import PrecautionTableComponent from '../AnnualReviewTables/precautionTable';
import { selectUser } from '../../../../Auth/state/auth.slice';
import { User } from '@alcome-rep/alcome-types/dist/models';
import Info from '../../../../common/components/WarningUser/Info';
import { QuestionCircleOutlined } from '@ant-design/icons';
moment().locale('fr')


const AnnualReviewFormStep = () => {
  const user = useAppSelector(selectUser);
  const annualReviewSelectorToUse = User.IsUserAdmin(user) ? selectAdminCurrentAnnualReview : selectCurrentPortalAnnualReview;
  const annualReview = useAppSelector(annualReviewSelectorToUse);
  const [form] = useForm()

  const dispatch: Dispatch<any> = useAppDispatch()

  const save = (formData: any) => {
    if (annualReview) {
      dispatch(updateAnnualReviewLatestInfosAction({ id: annualReview.id, data: formData }))
    }
  }
  const change = (_changes: any, _values: any) => {
    Promise.resolve().then(() => {
      form.submit()
    })
  }

  if (!annualReview) {
    return <LoadingScreen />;
  }

  return (
    <>
      {annualReview && annualReview.status === 0 && (
        <Info type="warning" icon={<QuestionCircleOutlined />} body={<>Merci de remplir le formulaire des <span className="font-semibold">Actions menées</span>. Vos modifications seront automatiquement enregistrées. Une fois rempli, cliquez sur l'onglet <span className="font-semibold">2. Cartographie</span>.</>} className="mb-6" />
      )}

      <Form
        form={form}
        onFinish={save}
        onValuesChange={debounce(change, 1000)}
        initialValues={{
          ...annualReview,
          policeArrest: annualReview.policeArrest.map(item => ({
            id: item.id || randomNumberId(),
            description: item.description,
            policeArrestNumber: item.policeArrestNumber,
            arrestDate: moment(item.arrestDate)
          })),
          precautionaryMeasure: annualReview.precautionaryMeasure.map(item => ({ ...item, measureDate: moment(item.measureDate) })),
          awarenessAction: annualReview.awarenessAction.map(item => ({
            id: item.id || randomNumberId(),
            actionDetail: item.actionDetail,
            actionType: item.actionType,
            actionDate: moment(item.actionDate)
          }))
        }}
        layout="vertical"
        validateTrigger={['onChange']}
        className="grid grid-cols-1 gap-4"
      >
        <Section title='1.1 Arrêtés de Police' mode="transparent">
          <PoliceArrestTableComponent annualReview={annualReview}></PoliceArrestTableComponent>
        </Section>
        <Section title='1.2 Mesures préventives' mode="transparent">
          <PrecautionTableComponent annualReview={annualReview} ></PrecautionTableComponent>
        </Section>
        <Section title='1.3 Bilan des actions de sensibilisation' mode="transparent">
          <AwarnessTableComponent annualReview={annualReview} ></AwarnessTableComponent>
        </Section>
      </Form>
    </>
  );
};

export default AnnualReviewFormStep;

/* ======== CONSTANTES ======== */

import { ChildCommune, IStructureAddress, StructureTypesEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { BaseCommune } from "@alcome-rep/alcome-types/dist/models";

/* ======== TYPES ======== */
export type StructureServiceState = {
  structureForm: BaseCommune
  currentStructure: BaseCommune | null
};


export const STRUCTURE_PORTAL_SERVICE = "structurePortalService";
// eslint-disable-next-line
export type STRUCTURE_PORTAL_SERVICE = typeof STRUCTURE_PORTAL_SERVICE; // Typescript line

/* ======== PAYLOADS ======== */

export type ListStructurePayload = {
  structureType?: string
}

export type GetStructurePayload = {
  id: string
}

export interface CreateStructurePayload extends Partial<BaseCommune> {
  structureType: StructureTypesEnum
}

export type UpdateStructurePayload = {
  data: {
    siren: string;
    name: string
    phone: string;
    email: string;
    communes?: ChildCommune[];
    address: IStructureAddress;
  },
  id?: string,
}

export type UpdateStructureCommunesPayload = {
  communes?: ChildCommune[];
}

export type AdminUpdateStructurePayload = {
  data: {
    siren: string;
    name: string
    phone: string;
    email: string;
    communes?: ChildCommune[];
    address: IStructureAddress;
  },
  id: string,
}
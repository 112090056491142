import { CloseOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Spin } from 'antd';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Dispatch, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import useFormRules from '../../../../common/hooks/useFormRules';
import { getTrackProgressAreas } from '../../../../common/services/apiService';
import { useTrackProgress } from '../../../../common/services/trackProgress';
import contractPortalApi from '../../../services/contract-portal.api';
import { selectCurrentContract, setCurrentContractAction } from '../../../state/contract-portal.slice';

const GenerateContractButton = () => {
  const contractField = 'contractFileGeneratedId';
  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const { required } = useFormRules();

  const isLoading = useTrackProgress(contractPortalApi.trackProgressAreas.custom('post'))
  const isDownloading = useTrackProgress(getTrackProgressAreas('').fileUpload(contractField));

  const currentContract = useAppSelector(selectCurrentContract)
  const { contractFileGenerated, contractFileGeneratedId } = currentContract!

  const dispatch: Dispatch<any> = useAppDispatch()

  const form = Form.useFormInstance();

  useEffect(() => {
    if (!form)
      return;
    form.setFieldsValue({ ...form.getFieldsValue(), contractFileGeneratedId })
    if (!firstLoad)
      form?.validateFields()
    setFirstLoad(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, contractFileGeneratedId])


  const generateContract = async () => {
    if (!form || await form.validateFields()) {
      try {
        const contractUpdated = await contractPortalApi.generateContractFile()
        dispatch(setCurrentContractAction(contractUpdated))
      }
      catch (err: any) {
        if (err.errorFields.filter((f: any) => f.name.toString() !== '').length === 0) {

          const contractUpdated = await contractPortalApi.generateContractFile()
          dispatch(setCurrentContractAction(contractUpdated))

          // form.setFieldsValue({ ...currentContract, [contractField]: new Date() })
        }
      }
    }
  }

  const deleteFile = async () => {
    const contractUpdated = await contractPortalApi.deleteGeneratedContractFile()
    dispatch(setCurrentContractAction(contractUpdated))
  }

  const DisableIfErrors = () => {
    return (form?.getFieldsError()
      // error on hidden field file
      .filter(f => f.name.toString() !== 'contractFileGeneratedId')
      .flatMap(i => i.errors) || []).length > 0;
  }

  const downloadDoc = () => {
    contractPortalApi.downloadGeneratedContractFile()
  }

  return <>
    <Form.Item className='h-0' rules={[required]} name={['contractFileGenerated']}>
      <Input type={'hidden'}  ></Input>
    </Form.Item>
    <div className={!contractFileGeneratedId ? 'hidden' : 'block'}>
      <div className='py-1 px-2 flex flex-col space-y-2 items-start'>
        <div className=''>
          {!isDownloading && <CloudDownloadOutlined className='text-alc-yellow font-bold text-lg leading-4'></CloudDownloadOutlined>}
          {isDownloading && <Spin className='text-alc-yellow font-bold text-lg leading-4'></Spin>}
          <button type="button" onClick={downloadDoc} className='btn-link text-alc-blue underline font-semibold px-2'>Télécharger mon contrat</button>
        </div>
        <div className='flex space-x-2 text-xs'>
          {contractFileGenerated && (<span>{format((contractFileGenerated).timestamp, "'Généré le 'd MMMM yyyy HH'h'mm'", { locale: fr })}</span>)}
          <Button
            className='p-0 border-none h-2'
            onClick={() => deleteFile()}
            icon={<CloseOutlined className='' />}>
          </Button>
        </div>

      </div>
    </div>

    <div className={contractFileGenerated ? 'hidden' : 'block'}>
      <Button
        disabled={DisableIfErrors()}
        onClick={generateContract}
        loading={isLoading}
        type="primary"
        // className="btn bg-gray-100 text-black border-1 border-alc-blue"
        htmlType="button"
      >
        Générer mon contrat
      </Button>
    </div>
  </>
}

export default GenerateContractButton
import { Models } from "@alcome-rep/alcome-types";
import ApiService from "../../common/services/apiService";
import { AppUser } from "../types";
import { IUser } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { message } from "antd";

const USER_PORTAL_PATH_API = 'portal/user';

class UserPortalApi extends ApiService<AppUser> {
    constructor() {
        super(USER_PORTAL_PATH_API)
    }

    /**
     * @description Find users in structure
     * @returns Return List of users in structure
     */
    findUsersInStructure({ query, page, pageSize }: { query: any, page: number, pageSize: number }) {
        return this.find({ query, page, pageSize })
    }

    /**
     * @description Get User in structure
     * @param userId 
     * @returns Return the target user infos
     */
    getUserInStructure(userId: string) {
        return this.get(userId)
    }

    /**
     * @description Invite a user to the structure
     * @param data 
     * @returns Return user created
     */
    inviteNewUser(data: Partial<IUser>) {
        return this.custom('post', 'invite', undefined, data)
            .then(() => message.success('Invitation envoyé'))
            .catch(() => message.error('Erreur lors de l\'envoie de l\'invitation'))
    }

    /**
     * @description Portal user update his profil
     * @param userId 
     * @param data 
     * @returns Return user updated
     */
    updateMe(data: Partial<Models.UserPortal>) {
        return this.custom<Models.UserPortal>('put', 'me', undefined, data)
            .then(r => {
                if (r) {
                    message.success('Profil mis à jour')
                    return new Models.User(r)
                }
            })
            .catch(() =>
                message.error('Erreur lors de la modification du profil')
            )
    }

    /**
     * @description Structure admin update user in structure
     * @param userId 
     * @param data 
     * @returns Return the target user updated 
     */
    updateUserInStructure(userId: string, data: Partial<Models.UserPortal>) {
        return this.custom<Models.UserPortal>('put', `${userId}`, undefined, data)
            .then(r => {
                if (r) {
                    message.success('Utilisateur mis à jour')
                    return new Models.User(r)
                }
            })
            .catch(() =>
                message.error('Erreur lors de la modification de l\'utilisateur')
            )
    }

    /**
     * @description Structure Admin soft delete a user in structure
     * @param userId 
     * @returns Return soft deleted user
     */
    structureAdminSoftDeleteUser(userId: string) {
        return this.delete(userId)
            .then(r => {
                if (r) {
                    message.success('Utilisateur supprimé')
                    return new Models.User(r)
                }
            })
            .catch(() =>
                message.error('Erreur lors de la suppression de l\'utilisateur')
            )
    }

}

const userPortalApi = new UserPortalApi();
export default userPortalApi;
import { FileDocumentStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces"
import { RcFile } from "antd/es/upload"
import AdminFileField from "../../../../common/components/admin/AdminFileField/AdminFileField"
import { useAppDispatch, useAppSelector } from "../../../../common/hooks"
import contractAdminApi from "../../../services/contract-admin.api"
import { adminSetCurrentContractAction, selectCurrentAdminContract } from "../../../state/contract-admin.slice"

const AdminExecutiveDeliberationFileUpload = () => {
  const contract = useAppSelector(selectCurrentAdminContract)
  const dispatch = useAppDispatch()

  const contractExecutiveDeliberationUpload = async (file: RcFile) => {
    if (!contract)
      throw new Error()
    const contractUpdated = await contractAdminApi.uploadExecutiveDeliberationFile(contract.id!, file)
    dispatch(adminSetCurrentContractAction(contractUpdated))
  }

  const contractExecutiveDeliberationDownload = () => {
    if (!contract)
      throw new Error()
    return contractAdminApi.getExecutiveDeliberationFile(contract.id!)
  }

  const updateStatus = async (status: FileDocumentStatusEnum, comment?: string) => {
    const contractUpdated = await contractAdminApi.updateExecutiveDeliberationFileDoc(contract?.id ?? '', { status, comment })
    dispatch(adminSetCurrentContractAction(contractUpdated))
  }

  if (!contract)
    return <></>;

  return <AdminFileField
    fileDocument={contract.executiveDeliberationFile}
    upload={contractExecutiveDeliberationUpload}
    updateStatus={updateStatus}
    download={contractExecutiveDeliberationDownload}
    propertyName='executiveDeliberationFileId'
  ></AdminFileField>
}

export default AdminExecutiveDeliberationFileUpload
import { GeoPoint } from '@alcome-rep/alcome-types/dist/interfaces';
import { message } from 'antd';
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from 'react-i18next';
import MarkerIcon from '../../../assets/icons/marker.png';
import { GeoJsonType } from "../../mapTypes";

type GMapProps = {
    markers: GeoJsonType,
    polygons: GeoJsonType,
    selection?: string[],
    center: any,
    zoom: any,
    drawModes?: google.maps.drawing.OverlayType[],
    readOnly: boolean
    onMapInsert: (obj: { geoPoint: GeoPoint | GeoPoint[], surface?: number }) => void
    onMapSelect: (obj: string) => void
}

const defaultDrawingModes = ['marker', 'polygon'] as google.maps.drawing.OverlayType[]

const GMapComponent = ({ readOnly, center, zoom, markers, polygons, onMapInsert, onMapSelect, selection, drawModes = defaultDrawingModes }: GMapProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();
    const [drawingManager, setDrawingManager] = React.useState<google.maps.drawing.DrawingManager>();
    const { t } = useTranslation()

    useEffect(() => {
        if (map) {

            // map.data = new google.maps.Data();
            markers.features.forEach(feature => {
                console.log(feature)

                const foundFeature = map.data.getFeatureById(feature.id);
                if (foundFeature)
                    map.data.remove(foundFeature)
                map.data.addGeoJson(feature)
            })
            polygons.features.forEach(feature => {
                console.log(feature)

                const foundFeature = map.data.getFeatureById(feature.id);
                if (foundFeature)
                    map.data.remove(foundFeature)
                map.data.addGeoJson(feature)
            })

            map.data.forEach(f => {
                let found = markers.features.find(marker => marker.id === f.getId())
                if (found)
                    return;
                found = polygons.features.find(poly => poly.id === f.getId())
                if (!found)
                    map.data.remove(f);
            })
        }
    }, [map, markers, polygons])

    useEffect(() => {
        if (!map || !selection)
            return;
        map.data.forEach(feature => {
            feature.setProperty('isSelected', !!selection.find(id => id === String(feature.getId())))
        })

    }, [selection, map])

    if (map) {
        google.maps.event.clearListeners(map.data, 'mousedown');
        map.data.addListener("mousedown", (event: any) => {
            const dataId = event.feature.getProperty('dataId');
            console.log(event)
            console.log(dataId)
            const drawingMode = drawingManager?.getDrawingMode();
            if (drawingMode && drawModes.includes(drawingMode)) {
                if (event.latLng)
                    onMapInsert({ geoPoint: { lat: event.latLng.lat(), lng: event.latLng.lng() } })
            } else
                onMapSelect(dataId)
        });
    }

    useEffect(() => {
        if (ref.current && !map) {

            const newMap = new google.maps.Map(ref.current, { center, zoom, mapTypeId: google.maps.MapTypeId.ROADMAP, styles: [{ elementType: "labels", featureType: "poi.business", stylers: [{ visibility: "off" }] }] });

            // initData(newMap);

            const drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: null,
                drawingControl: true,
                markerOptions: {
                    icon: MarkerIcon,
                },
                polygonOptions: {
                    strokeColor: '#003285',
                    strokeOpacity: 0.7,
                    fillOpacity: 0.7,
                    fillColor: 'red',
                    // strokeWeight: 2
                },
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: drawModes
                }
            });

            if (!readOnly) {
                drawingManager.setMap(newMap);
                setDrawingManager(drawingManager);
            }
            // google.maps.event.addListener(
            //     newMap,
            //     'zoom_changed',
            //     () => {zoo}
            // )
            google.maps.event.addListener(
                drawingManager,
                "overlaycomplete",
                (event: google.maps.drawing.OverlayCompleteEvent) => {
                    drawingManager.setDrawingMode(null);
                    if (event.type === 'marker') {
                        const overlay: google.maps.Marker = event.overlay as google.maps.Marker;
                        const pos = overlay.getPosition();
                        if (pos) {
                            onMapInsert({ geoPoint: { lat: pos?.lat(), lng: pos?.lng() } })
                            overlay.setMap(null)
                        }
                    } else {
                        const overlay: google.maps.Polygon = event.overlay as google.maps.Polygon;
                        const path = overlay.getPath().getArray();
                        const surface = Math.round(google.maps.geometry.spherical.computeArea(overlay.getPath()));
                        if (surface === 0 || surface > 10000) {
                            overlay.setMap(null);
                            message.error(
                                t('global.map.surface')
                            );
                            return;
                        }
                        onMapInsert({ geoPoint: path.map(p => ({ lat: p.lat(), lng: p.lng() })), surface })
                        overlay.setMap(null)
                    }
                }
            );

            newMap.data.setStyle(function (feature) {
                const isSelected = selection === undefined || feature.getProperty('isSelected') === true;
                return {
                    strokeColor: '#003B71',
                    strokeWeight: isSelected ? 2 : 1,
                    strokeOpacity: 0.6,
                    fillColor: isSelected ? '#52c41a' : '#aeaeae',
                    // fillOpacity: 0.6,
                    opacity: isSelected ? 1 : 0.6,
                    icon: MarkerIcon
                };
            });

            setMap(newMap);
        }
    }, [center, zoom, ref, map, readOnly, onMapInsert, t, drawModes, selection]);

    useMemo(() => {
        if (map) {
            map.setCenter(center);
            map.setZoom(zoom);
        }
    }, [map, zoom, center])

    return (
        <>
            <div
                style={{
                    height: "calc(100vh - 330px)",
                    maxHeight: "calc(100vh - 330px)"
                }}
                ref={ref}
                id="map"
            />
        </>
    );
}

export default GMapComponent
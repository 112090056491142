import { CloseOutlined, ThunderboltFilled } from "@ant-design/icons"
import { ReactNode } from "react"
import { NavLink } from "react-router-dom"

type PropsType = {
  children: ReactNode,
  resetLink?: ReactNode | null,
  icon?: ReactNode | null
}

export const ResetLink = () => <NavLink
  className='p-1 px-4 underline'
  to={'.'}
>
  <span className="whitespace-nowrap"><CloseOutlined /> Réinitialiser les filtres</span>
</NavLink>

const QuickLinksPanel = ({ children, resetLink, icon }: PropsType) => {

  const finalIcon = icon !== null ?
    icon || <div className="flex"><ThunderboltFilled className="text-alc-blue" /></div>
    : null;

  const finalLink = resetLink !== null ?
    resetLink || <ResetLink />
    : null;

  return <div className='quick-links'>
    <div className='flex space-x-4 w-full'>
      {finalIcon}
      {children}
    </div>
    {finalLink}
  </div>
}

export default QuickLinksPanel
import { StructureTypesEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import { Form, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { selectCurrentStructure } from '../../../../Structure/state/structure-portal.slice';
import { updateStructureInfosAction } from '../../../../Structure/state/structure-portal.slice';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import useFormRules from '../../../../common/hooks/useFormRules';
import { ROUTES } from '../../../../common/router';
import NextStepComponent from '../../../../Auth/components/Register/NextStepComponent';
import LoadingScreen from '../../../../common/components/LoadingScreen';
import { getRoute } from '../../../../common/tools/router-tools';

const ContractConfirmStep: React.FC = () => {

  const { t } = useTranslation();
  const { required, zipCode, phoneNumber } = useFormRules()

  const navigate = useNavigate()
  const structure = useAppSelector(selectCurrentStructure)

  const dispatch = useAppDispatch()

  const nextStepFnc = () => {
    let nextStepLink = "";
    switch (structure?.structureType) {
      case StructureTypesEnum.commune:
        nextStepLink = getRoute(ROUTES.PORTAL_REGISTER_CONTRACT_ROUTES.STEPS_COMMUNE.signatory)
        break;
      case StructureTypesEnum.groupement_communes:
        nextStepLink = getRoute(ROUTES.PORTAL_REGISTER_CONTRACT_ROUTES.STEPS_GRP_COMMUNES.communes)
        break;
      default:
        break;
    }
    return navigate(nextStepLink)
  }

  const submit = (formData: any) => {
    dispatch(updateStructureInfosAction(formData))
    return nextStepFnc()
  }

  if (structure === null)
    return <LoadingScreen></LoadingScreen>

  return (
    <>
      <h2 className='mb-4'>{t('UI.REGISTER_CONTRACT.STRUCTURE_INFOS.title.' + structure.structureType)}</h2>
      <p>Veuillez complèter les informations :</p>
      <Form
        name="basic"
        layout='vertical'
        onFinish={submit}
        id="pa"
        initialValues={structure}
      >
        <div className="grid grid-cols-2 gap-x-4">
          <Form.Item
            label={'Code ' + t('TYPES.STRUCTURE.Code.' + structure?.structureType)}
          >
            <Input value={structure.siren} disabled></Input>
          </Form.Item>
          <Form.Item
            label={t('UI.REGISTER_CONTRACT.STRUCTURE_INFOS.fields.name.label.' + structure.structureType)}
          >
            <Input value={structure.name} disabled></Input>
          </Form.Item>
          <Form.Item
            label="Adresse"
            name={['address', 'street']}
            rules={[required]}
            className="col-span-2"
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="Code postal"
            name={['address', 'zip']}
            rules={[required, zipCode]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="Ville"
            name={['address', 'city']}
            rules={[required]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="Email"
            name={['email']}
            rules={[required]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="Téléphone"
            name={['phone']}
            rules={[required, phoneNumber]}
          >
            <Input></Input>
          </Form.Item>
        </div>

        <Form.Item>
          <NextStepComponent />
        </Form.Item>
      </Form>
    </>
  )
}

export default ContractConfirmStep;
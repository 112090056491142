import { ContractStatusEnum, InventoryStatusEnum, StructureTypesEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import { CalendarOutlined, ContainerOutlined, HomeOutlined, NotificationOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectCurrentContract } from '../../../Contract/state/contract-portal.slice';
import { selectCurrentInventory } from '../../../Inventory/state/inventory-portal.slice';
import { selectCurrentStructure } from '../../../Structure/state/structure-portal.slice';
import LoadingScreen from '../../components/LoadingScreen';
import { useAppSelector } from '../../hooks';
import { ROUTES } from '../../router';
import { getRoute } from '../../tools/router-tools';

const PortalSideBar = () => {
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>([])
  const [openedKeys, setOpenedKeys] = useState<string[]>()
  const navigate = useNavigate();
  const { pathname } = useLocation()

  const structure = useAppSelector(selectCurrentStructure)
  const contract = useAppSelector(selectCurrentContract)
  const inventory = useAppSelector(selectCurrentInventory)

  const { t } = useTranslation()

  const contractNotValidated = contract?.status! < ContractStatusEnum.validated;
  const annexeNotValidated = (inventory?.status! < InventoryStatusEnum.validated);

  const items: MenuProps['items'] = useMemo(() => ([
    { key: getRoute(ROUTES.PORTAL_USER_ROUTES.home), icon: <HomeOutlined />, label: "Accueil" },
    {
      key: 'contract', icon: <ContainerOutlined />, label: "Contrat", children: [
        { key: getRoute(ROUTES.PORTAL_CONTRACT_ROUTES.contractDetails), label: "Détails" },
        { key: getRoute(ROUTES.PORTAL_CONTRACT_ROUTES.inventoryDetails), label: "Annexes", disabled: contractNotValidated },
        { key: getRoute(ROUTES.PORTAL_CONTRACT_ROUTES.amendmentsDetails), label: "Avenants", disabled: contractNotValidated },
      ]
    },
    { key: getRoute(ROUTES.PORTAL_ANNUALREVIEW_ROUTES.portalAnnualReviewList), icon: <CalendarOutlined />, label: "Bilans annuels", disabled: annexeNotValidated },
    { key: getRoute(ROUTES.PORTAL_LIBRARYDOC_ROUTES.libraryDocList), icon: <NotificationOutlined />, label: "Communication", disabled: contractNotValidated },
    // { key: getRoute(ROUTES.PORTAL_SUPPORT_ORDER_ROUTES.supportOrderList), icon: <EuroCircleOutlined />, label: "Dispositifs de rue", disabled: annexeNotValidated },
    { key: getRoute(ROUTES.PORTAL_ACQUISITION_ORDER_ROUTES.acquisitionOrderList), icon: <PlusSquareOutlined />, label: "Demandes de dispositifs", disabled: annexeNotValidated },
  ]), [contractNotValidated, annexeNotValidated])

  useEffect(() => {
    setDefaultSelectedKeys([pathname])

    function findItem(path: string, items: MenuProps['items'], selection: ItemType[] = []): ItemType | undefined {
      if (items === undefined)
        return undefined;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        // if (!item)
        //   continue;
        if (item?.key?.toString() === path) {
          return item;
        }
        else if ((item as any).children) {
          const found = findItem(path, (item as any).children)
          if (found) {
            selection.push(item)
            return item; // returns parent that shall be a group}
          }
        }
      }
    }
    const selection: ItemType[] = []
    findItem(pathname, items, selection)
    setOpenedKeys(["contract", ...selection.map(s => s!.key!.toString())])


  }, [pathname, items, setOpenedKeys])

  const onClick = ({ key }: any) => {
    navigate(key);
  }

  if (!structure)
    return <LoadingScreen />


  return (
    <>
      <div className='font-semibold text-xl py-6 px-2' style={{ backgroundColor: '#dfe3ee' }}>
        <div className='flex flex-col items-center text-alc-blue'>
          <div className='text-center text-sm'>{t('TYPES.STRUCTURE.StructureTypesEnum.' + StructureTypesEnum[structure.structureType])} de</div>
          <div className='text-center'>{structure?.name.toUpperCase()}</div>
        </div>
      </div>
      <div
        style={{ backgroundColor: '#dfe3ee' }}
        className=" "
      >
        {openedKeys &&
          <Menu
            defaultOpenKeys={openedKeys}
            selectedKeys={defaultSelectedKeys}
            onSelect={onClick}
            style={{ width: 256 }}
            mode="inline"
            items={items}
          />
        }
      </div>
    </>
  );
};

export default PortalSideBar;
import { ISupplierCompany } from '@alcome-rep/alcome-types/dist/interfaces/supplier';
import { BarcodeOutlined, DeliveredProcedureOutlined, HomeFilled, ShoppingCartOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen';
import { ROUTES } from '../../router';
import { getRoute } from '../../tools/router-tools';
import supplierCompanyApi from '../../../SupplierCompany/services/supplierCompany.api';

const SupplierSideBar = () => {

    const [supplierCompany, setSupplierCompany] = useState<ISupplierCompany>()

    useEffect(() => {
        supplierCompanyApi.getMyCompany()
            .then(res =>
                setSupplierCompany(res)
            )
    }, [setSupplierCompany])

    if (!supplierCompany)
        return <LoadingScreen />

    const activeClass = ({ isActive }: { isActive: boolean }) => 'mainNavlink ' + (isActive ? 'active' : '')

    return (
        <>
            <div className='font-semibold text-xl py-6' style={{ backgroundColor: '#dfe3ee' }}>
                <div className='flex flex-col items-center text-alc-blue'>
                    <div className='text-center'>{supplierCompany?.name.toUpperCase()}</div>
                </div>
            </div>
            <div
                style={{ backgroundColor: '#dfe3ee' }}
                className="sideNav "
            >
                <div className='sidemenu '>
                    <NavLink className={activeClass} to={getRoute(ROUTES.SUPPLIER_USER_ROUTES.home)}><HomeFilled />Accueil</NavLink>
                    <NavLink className={activeClass} to={getRoute(ROUTES.SUPPLIER_USER_ROUTES.productList)}><BarcodeOutlined />Liste des produits </NavLink>
                    <NavLink className={activeClass} to={getRoute(ROUTES.SUPPLIER_USER_ROUTES.orderList)}><ShoppingCartOutlined />Liste des commandes </NavLink>
                    <NavLink className={activeClass} to={getRoute(ROUTES.SUPPLIER_USER_ROUTES.shippingWeightList)}><DeliveredProcedureOutlined /> Frais de livraison</NavLink>
                </div>
            </div>
        </>
    );
};

export default SupplierSideBar;
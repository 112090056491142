import { QuestionCircleOutlined, RedoOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Spin, Switch } from 'antd';
import Tooltip from 'antd/es/tooltip';
import TextArea from 'antd/lib/input/TextArea';
import Upload from 'antd/lib/upload';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import confirm from 'antd/lib/modal/confirm';
import useFormRules from '../../../../common/hooks/useFormRules';
import { ILibraryDoc, LibraryDocTypesEnum, StructureTypesEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import libraryDocApi from '../../../services/libraryDoc.api';
import adminLibraryDocApi, { progressKeys } from '../../../services/admin-libraryDoc.api';
import { useTrackProgress } from '../../../../common/services/trackProgress';


const AdminLibraryDocForm = ({ libDoc, onSubmit }: { libDoc?: ILibraryDoc, onSubmit: () => void }) => {

    const [editFile, setEditFile] = useState<boolean>(false)
    const [editThumbnail, setEditThumbnail] = useState<boolean>(false)

    const { required } = useFormRules();
    const { t } = useTranslation();

    const isUpdating = useTrackProgress(progressKeys.update)
    const isCreating = useTrackProgress(progressKeys.upload)
    const controller = new AbortController()

    const submit = async (formData: any) => {
        if (!libDoc)
            await adminLibraryDocApi.uploadLibraryDoc(formData)
        else
            await adminLibraryDocApi.updateLibraryDoc(libDoc.id as string, formData)

        onSubmit();
    }

    const downloadDoc = async (thumbnail = false) => {
        if (!libDoc)
            return;
        await libraryDocApi.downloadThumbnail(libDoc.id as string)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', thumbnail ? `${libDoc.thumbnail}` : `${libDoc.name}.${libDoc.ext}`); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.remove()
            });
    }

    const confirmDownload = (thumbnail = false) => {
        confirm({
            title: `Voulez vous télécharger le document ${(libDoc as ILibraryDoc).timestamp}.${(libDoc as ILibraryDoc).ext} ?`,
            icon: <QuestionCircleOutlined />,
            cancelText: 'Annuler',
            closable: true,
            okCancel: true,
            okText: 'Télécharger',
            okButtonProps: { className: "btn" },
            async onOk() {
                await downloadDoc(thumbnail)
            },
            onCancel() {
                controller.abort()
            },
        });
    }


    const isNew = !libDoc;
    const hasThumbnail = libDoc && libDoc.thumbnail;

    return (
        <>
            <div className=''>
                <Form
                    name="basic"
                    layout='vertical'
                    onFinish={submit}
                    initialValues={libDoc}
                >
                    <div className='grid grid-cols-2 gap-4'>
                        {isNew || editFile ?
                            <Form.Item
                                name="file"
                                label="Fichier"
                                getValueFromEvent={e => e?.fileList}
                                help={!isNew && (<button type="button" className='btn btn-link underline font-normal text-sm px-0' onClick={_ => setEditFile(false)}>Annuler</button>)}
                            >
                                <Upload name="file" multiple={false} showUploadList={true} maxCount={1} beforeUpload={_ => false}>
                                    <Button icon={<UploadOutlined />}>Selectionnez un fichier</Button>
                                </Upload>
                            </Form.Item> :
                            <Form.Item
                                label="Fichier">
                                <div className='flex space-x-4'>
                                    <button type="button" className='btn btn-link text-alc-blue font-normal px-0 py-0' onClick={_ => confirmDownload(false)}>{libDoc.timestamp}.{libDoc.ext}</button>
                                    <Tooltip title="charger un autre fichier">
                                        <button type="button" className='btn btn-link underline font-normal text-sm px-0 py-0' onClick={_ => setEditFile(true)}><RedoOutlined /></button>
                                    </Tooltip>
                                </div>
                            </Form.Item>
                        }
                        {isNew || !hasThumbnail || editThumbnail ?
                            <Form.Item
                                name="thumbnail"
                                label="Vignette"
                                help={(!isNew && hasThumbnail) && (<button type="button" className='btn btn-link underline font-normal text-sm px-0' onClick={_ => setEditThumbnail(false)}>Annuler</button>)}
                                getValueFromEvent={e => e?.fileList}
                            >
                                <Upload name="thumbnail" multiple={false} showUploadList={true} maxCount={1} beforeUpload={_ => false}>
                                    <Button icon={<UploadOutlined />}>Selectionnez une vignette</Button>
                                </Upload>
                            </Form.Item> :
                            <Form.Item label="Vignette">
                                <div className='flex space-x-4'>
                                    <button type="button" className='btn btn-link text-alc-blue font-normal px-0 py-0' onClick={_ => confirmDownload(true)}>{libDoc.thumbnail}</button>
                                    <Tooltip title="charger une autre vignette">
                                        <button type="button" className='btn btn-link underline font-normal text-sm px-0 py-0' onClick={_ => setEditThumbnail(true)}><RedoOutlined /></button>
                                    </Tooltip>
                                </div>
                            </Form.Item>
                        }
                    </div>
                    <div className='grid grid-cols-2 gap-4'>
                        <Form.Item
                            label="Nom"
                            name='name'
                            rules={[required]}
                        >
                            <Input placeholder="Nom du kit de communication"></Input>
                        </Form.Item>
                        <Form.Item
                            label="Type de document"
                            name='docType'
                            rules={[required]}
                        >
                            <Select>
                                {Object.keys(LibraryDocTypesEnum).map(type => (
                                    <Select.Option key={type} value={type}>{t(`TYPES.LIBRARYDOC.LibraryDocTypesEnum.${type}`)}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <Form.Item
                        label="Description"
                        name='description'
                        rules={[required]}
                    >
                        <TextArea placeholder="Description du kit de communication"></TextArea>
                    </Form.Item>

                    <Form.Item
                        label="Types de structure"
                        name='structureType'
                        rules={[required]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Selectionnez le type de structure"
                        >
                            {Object.keys(StructureTypesEnum).map(type => (
                                <Select.Option key={type} value={type}>{t(`TYPES.STRUCTURE.StructureTypesEnum.${type}`)}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Publier le kit'
                        name='enabled'
                        className="col-span-2"
                        valuePropName="checked"
                        labelAlign='right'
                    >
                        <Switch defaultChecked={false}></Switch>
                    </Form.Item>
                    <Form.Item>
                        {(isCreating || isUpdating) ?
                            <div className='flex space-x-4'>
                                <div className='font-semibold btn-link text-alc-blue'>
                                    Enregistrement en cours
                                </div>
                                <Spin className='text-2xl text-alc-blue'></Spin>
                            </div>
                            :
                            <Button htmlType="submit" type="primary" className="bg-alc-blue">
                                Enregistrer
                            </Button>
                        }
                    </Form.Item>
                </Form>
            </div>
        </>

    );

};

export default AdminLibraryDocForm;
import { RouteObject } from "react-router-dom";
import AnnualReviewCommunicationStep from "../components/common/AnnualReviewCommunication/annualReviewCommunicationStep";
import AnnualReviewFormStep from "../components/common/AnnualReviewForm/annualReviewFormStep";
import AnnualReviewMapStep from "../components/common/AnnualReviewMap/annualReviewMapStep";
import RevenueOrderStep from "../components/common/AnnualReviewOrder/annualReviewRevenueOrderStep";
import AnnualReviewRevisionStep from "../components/common/AnnualReviewRevision/annualReviewRevisionStep";
import StatementPaidStep from "../components/common/AnnualReviewStatement/annualReviewStatementPaid";
import StatementStep from "../components/common/AnnualReviewStatement/annualReviewStatementStep";
import AnnualReviewPortalGuard from "../components/guards/AnnualReviewPortalGuard";
import PortalAnnualReviewPage from "../components/portal/AnnualReviewDetails/PortalAnnualReviewPage";
import AnnualReviewPortalList from "../components/portal/AnnualReviewPortalList/AnnualReviewPortalList";


const routePrefix = 'portal'
const routeDomain = 'annualReview'
const routeParam = 'annualReviewId'

export const portalAnnualReviewRouteNames = {
  portalAnnualReviewList: { path: `${routePrefix}/${routeDomain}`, title: `ROUTES.${routeDomain}.list` },
  portalAnnualReviewDetails: { path: `${routePrefix}/${routeDomain}/:${routeParam}`, title: `ROUTES.${routeDomain}.actions` },
  StepMap: { path: `${routePrefix}/${routeDomain}/:${routeParam}/map`, title: `ROUTES.${routeDomain}.map` },
  StepCommunication: { path: `${routePrefix}/${routeDomain}/:${routeParam}/communication`, title: `ROUTES.${routeDomain}.communication` },
  StepReview: { path: `${routePrefix}/${routeDomain}/:${routeParam}/review`, title: `ROUTES.${routeDomain}.review` },
  StepStatement: { path: `${routePrefix}/${routeDomain}/:${routeParam}/statement`, title: `ROUTES.${routeDomain}.statement` },
  StepOrder: { path: `${routePrefix}/${routeDomain}/:${routeParam}/order`, title: `ROUTES.${routeDomain}.order` },
  StepPayment: { path: `${routePrefix}/${routeDomain}/:${routeParam}/payment`, title: `ROUTES.${routeDomain}.payment` },
}

export const portalAnnualReviewRoutes: RouteObject[] = [
  {
    path: "",
    // element: <ContractFileGuard />,
    children: [
      {
        path: portalAnnualReviewRouteNames.portalAnnualReviewList.path.replace(`${routePrefix}/`, ''),
        element: <AnnualReviewPortalList />,
      },
      {
        path: portalAnnualReviewRouteNames.portalAnnualReviewDetails.path.replace(`${routePrefix}/`, ''),
        element: <AnnualReviewPortalGuard> <PortalAnnualReviewPage /> </AnnualReviewPortalGuard>,
        children: [
          {
            path: '',
            element: <AnnualReviewFormStep />
          },
          {
            path: 'review',
            element: <AnnualReviewRevisionStep />
          },
          {
            path: 'statement',
            element: <StatementStep />
          },
          {
            path: 'communication',
            element: <AnnualReviewCommunicationStep />
          },
          {
            path: 'map',
            element: <AnnualReviewMapStep />
          },
          {
            path: 'order',
            element: <RevenueOrderStep />
          },
          {
            path: 'payment',
            element: <StatementPaidStep />
          }
        ]
      }
    ]
  }
]

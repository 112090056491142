import { RouteObject } from "react-router-dom"
import ProspectPage from "../components/ProspectDetails/ProspectPage"
import ProspectStructureInfos from "../components/ProspectDetails/ProspectStructureInfos"
import ProspectListPage from "../components/ProspectList/ProspectListPage"
import ProspectAdminGuard from "../guards/prospectAdminGuard"
import ProspectLayout from "../layouts/ProspectLayout"
import ProspectContactList from "../components/ContactList/ProspectContactList"
import ProspectActionList from "../components/ActionList/ProspectActionList"
import ProspectFiles from "../components/ProspectDetails/ProspectFiles"

const routePrefix = 'admin'
const routeDomain = 'prospect'
const routeParam = 'prospectId'

export const adminProspectRouteNames = {
  prospectList: { path: `${routePrefix}/${routeDomain}`, title: `ROUTES.${routeDomain}.list` },
  prospectDetails: { path: `${routePrefix}/${routeDomain}/:${routeParam}`, title: "ROUTES.prospect.details" },
  structure: { path: `${routePrefix}/${routeDomain}/:${routeParam}/structure`, title: "ROUTES.prospect.structure" },
  contacts: { path: `${routePrefix}/${routeDomain}/:${routeParam}/contacts`, title: "ROUTES.prospect.contacts" },
  actions: { path: `${routePrefix}/${routeDomain}/:${routeParam}/actions`, title: "ROUTES.prospect.actions" },
  files: { path: `${routePrefix}/${routeDomain}/:${routeParam}/files`, title: "ROUTES.prospect.files" }
}

export const adminProspectRoutes: RouteObject[] = [
  {
    path: adminProspectRouteNames.prospectList.path.replace(`${routePrefix}/`, ''),
    element: <ProspectListPage />,
  },
  {
    path: '',
    element: <ProspectAdminGuard />,
    children: [
      {
        path: adminProspectRouteNames.prospectDetails.path.replace(`${routePrefix}/`, ''),
        element: <ProspectLayout />,
        children: [
          { path: '', element: <ProspectPage /> },
          { path: 'structure', element: <ProspectStructureInfos /> },
          { path: 'contacts', element: <ProspectContactList /> },
          { path: 'actions', element: <ProspectActionList /> },
          { path: 'files', element: <ProspectFiles /> }
        ]
      }
    ]
  },
]
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { useNavigate, useSearchParams } from 'react-router-dom';
import userApi from '../../../User/services/user.api';
import useFormRules from '../../../common/hooks/useFormRules';
import { ROUTES } from '../../../common/router';
import msg from '../../../common/services/toast';
import { useTrackProgress } from '../../../common/services/trackProgress';



const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const { required, password: passwordMinLength } = useFormRules();
  const navigate = useNavigate()
  const isLoading = useTrackProgress(userApi.trackProgressAreas.custom('put'))
  const [searchParams] = useSearchParams()

  const onSubmit = async (data: { password: string }) => {
    try {
      await userApi.recoverPassword(searchParams.get('token') ?? '', data.password)
      msg.success("Mot de pâsse changé avec succès")
      navigate(ROUTES.LOGIN)
    } catch {
      msg.error("Le mot de passe doit contenir au minimum 8 caractères. 1 majuscule et un caractère spécial (@,$,!,%,*,?,&)")
    }
  }

  return (
    <>
      <h1>Changer votre mot de passe</h1>
      <Form onFinish={onSubmit} layout="vertical">

        <Form.Item
          label="Nouveau mot de passe"
          name="password"
          rules={[required, passwordMinLength]}
          tooltip="8 caractères min., une majuscule, un chiffre et un caractère spéciale @$!%*?&"
        >
          <Input.Password
            placeholder={t('resetPassword.password.placeholder', {
              defaultValue: 'New Password',
            })}
          />
        </Form.Item>
        <Button block type="primary" htmlType="submit" loading={isLoading}>{t('COMMON.save')}</Button>
      </Form>
    </>
  );
};

export default ResetPasswordForm;

import { AcquisitionOrderItemType, DispositifCategoryEnum, IAcquisitionOrders } from "@alcome-rep/alcome-types/dist/interfaces";
import { User } from "@alcome-rep/alcome-types/dist/models";
import { EditOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../../../Auth/state/auth.slice";
import { defaultPagination } from "../../../../common/app-constants";
import PageCanvas from "../../../../common/components/Panels/PageCanvas";
import { useAppSelector } from "../../../../common/hooks";
import { ROUTES } from "../../../../common/router";
import { getRoute } from "../../../../common/tools/router-tools";
import acquisitionOrderPortalApi from "../../../services/portal-acquisitionOrder.api";
import AcquisitionOrderStatus from "../../common/AcquisitionOrderStatus/AcquisitionOrderStatus";

const AcquisitionOrderList = () => {

  const navigate = useNavigate();

  const [data, setData] = useState<IAcquisitionOrders[]>([]);
  const [count, setCount] = useState<number>();
  const [loading, setloading] = useState<boolean>(true);

  const user = useAppSelector(selectUser)
  const isAdmin = User.IsUserAdmin(user);

  const getData = useMemo(() => () => {
    acquisitionOrderPortalApi.findAcquisitionOrders(
      { query: {}, page: 1, pageSize: defaultPagination.pageSize }
    ).then(res => {
      setData(res.rows)
      setCount(res.count)
      setloading(false)

    })
  }, [setData, setloading])

  useEffect(() => {
    getData();
  }, [getData]);

  const columns: any = [
    {
      fixed: 'left',
      render: (value: any) => {
        return (
          <Button
            type="link"
            onClick={(e) => {
              // navigate(`${APP_ROUTER_PATHS.supportOrderEdit.replace(/:supportOrderId/, value._id)}`);
              navigate(getRoute(ROUTES.PORTAL_ACQUISITION_ORDER_ROUTES.acquisitionOrderDetails, { acquisitionOrderId: value.id }));
            }}
            icon={<EditOutlined />}
          />
        )
      }
    },
    {
      title: 'N°',
      dataIndex: 'acquisitionOrderNumber',
      key: 'supportOrderNumber',
      align: 'center' as const,
      render: (value: any) => {
        return <div>{value}</div>
      },
    },
    {
      title: 'Date',
      dataIndex: 'orderDate',
      align: 'center' as const,
      render: (value: any) => {
        return <div>{format(new Date(value as Date), "d MMMM yyyy", { locale: fr })}</div>
      },
    },
    {
      title: 'Nb cendriers de rue',
      dataIndex: 'items',
      align: 'center' as const,
      render: (value: AcquisitionOrderItemType[]) => <span>{value.filter((v: any) => v.category === DispositifCategoryEnum.ashtray).reduce((acc, i) => acc + i.quantity, 0)}</span>,
    },
    {
      title: 'Nb eteignoirs',
      dataIndex: 'items',
      key: 'extinguisherCount',
      align: 'center' as const,
      render: (value: AcquisitionOrderItemType[]) => <span>{value.filter((v: any) => v.category === DispositifCategoryEnum.extinguisher).reduce((acc, i) => acc + i.quantity, 0)}</span>,
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      key: 'status',
      align: 'center' as const,
      render: (value: any) => <AcquisitionOrderStatus status={value} />
    },


  ]

  const goToCreate = () => {
    navigate(getRoute(ROUTES.PORTAL_ACQUISITION_ORDER_ROUTES.acquisitionOrderAshtrays))
  };

  return (
    <>
      <PageCanvas
        title="Demandes de dispositifs de rue"
        actions={!isAdmin && <Button type="primary" onClick={goToCreate}>
          Ajouter une demande
        </Button>}
      >
        {/* {!isAdmin &&
                    <div className='py-4 text-alc-blue flex space-x-8'>
                        <div className="card text-alc-blue font-semibold">
                            Dispositifs commandés <span className="text-alc-yellow">{ }</span>
                        </div>
                        <div className="card text-alc-blue font-semibold">
                            Eteignoirs commandés <span className="text-alc-yellow"> { }</span>
                        </div>
                    </div>
                } */}


        <Table<IAcquisitionOrders>
          rowKey={'id'}
          columns={columns}
          dataSource={data}
          loading={loading}
          onChange={getData}
          size="small"
          pagination={
            {
              hideOnSinglePage: true,
              defaultPageSize: defaultPagination.pageSize,
              total: count
            }
          } />
      </PageCanvas>
    </>
  )
}

export default AcquisitionOrderList


import { ILogAction } from '@alcome-rep/alcome-types';
import { IStructure } from '@alcome-rep/alcome-types/dist/interfaces';
import { Table, TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultPagination } from '../../../app-constants';
import logActionsApi from '../../../services/log-actions.api';
import { logActionsColumns } from './logActionsComponent.constant';

const LogAdminList = ({ structure }: { structure: IStructure | null }) => {
  const { t } = useTranslation()

  const [count, setCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(defaultPagination.pageSize);
  const [pageCount, setPageCount] = useState<number>(0);
  const [loading, setloading] = useState<boolean>(false);
  const [logActions, setLogActions] = useState<ILogAction[]>([]);

  const getLogActionsData = useMemo(() => async (
    pagination: TablePaginationConfig = { pageSize: defaultPagination.pageSize, current: 1 },
    filters?: Record<string, FilterValue | null>,
  ) => {
    setloading(true)
    const res = await logActionsApi.logActionslist(
      { page: pagination.current, pageSize: pagination.pageSize, query: filters, structureId: structure?.id as string }
    )
    setLogActions(res.rows)
    setCount(res.count)
    setPageSize(pagination.pageSize as number)
    setPageCount(Math.ceil(count / pageSize))
    setloading(false)
  }, [structure, count, pageSize])

  useEffect(() => {
    if (structure) {
      getLogActionsData();
    }
  }, [getLogActionsData, structure]);


  return <>
    <Table<ILogAction>
      columns={logActionsColumns(t)}
      dataSource={logActions}
      loading={loading}
      rowKey="id"
      onChange={getLogActionsData}
      scroll={{ scrollToFirstRowOnChange: true }}
      pagination={
        pageCount > 1 ?
          {
            defaultPageSize: defaultPagination.pageSize,
            total: count
          } : false
      } />
  </>
}

export default LogAdminList;
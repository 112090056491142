import { DispositifTypeEnum, IBaseCommune, ICollectPoint, IStructure, PlaceTypeEnum, StructureTypesEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { EditOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, InputNumber, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { useTranslation } from "react-i18next";
import useFormRules from "../../../common/hooks/useFormRules";
import { findAddressInfo } from "../../../common/tools/global-tools";

type CollectPointFormProps = {
  structure: IStructure,
  mode?: 'supportOrder' | 'acquisitionOrder',
  collectPoint?: ICollectPoint,
  onCancel: () => void,
  onOk: (collectPoint: ICollectPoint) => void,
  onDelete?: (collectPoint: ICollectPoint) => void,
  canEdit: boolean,
  dipositifTypes?: DispositifTypeEnum[]
};

const CollectPointForm = ({ structure, mode, collectPoint, onOk, onCancel, onDelete, canEdit = true, dipositifTypes }: CollectPointFormProps
) => {
  const [editOldValue, setEditOldValue] = useState(false);

  const [inputAddress, setInputAddress] = useState(collectPoint?.address ?? null);
  const [confirm, setConfirm] = useState(false);

  let [form] = Form.useForm<ICollectPoint>()

  const { t } = useTranslation();

  const { required } = useFormRules();

  return (
    <>

      <Form
        preserve={false}
        disabled={canEdit === false}
        form={form}
        onFinish={(values: any) => canEdit && onOk({ ...values, address: inputAddress })}
        layout="vertical"
        validateTrigger={['onBlur']}
        initialValues={collectPoint}
      >
        <div className="p-4 bg-gray-100 rounded-lg mb-4">
          {canEdit && (<div className="flex space-x-2 items-center">
            <label className="">Adresse du dispositif :</label>
            <div className="flex-1">
              <GooglePlacesAutocomplete
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ['fr'],
                  }
                }}
                debounce={500}
                apiOptions={{ language: 'fr', region: 'fr' }}
                selectProps={{
                  value: inputAddress as any,
                  onChange: (v: any) => {
                    const val = { ...collectPoint }
                    setInputAddress(v)
                    geocodeByAddress(v.label)
                      .then((results: google.maps.GeocoderResult[]) => {
                        const addressInfos = results[0].address_components;

                        val.address = {
                          label: v.label,
                          city: findAddressInfo(addressInfos, "locality"),
                          country: findAddressInfo(addressInfos, 'country'),
                          street: findAddressInfo(addressInfos, 'street_number') + ' ' + findAddressInfo(addressInfos, 'route'),
                          zip: findAddressInfo(addressInfos, "postal_code"),
                          region: findAddressInfo(addressInfos, "administrative_area_level_1") as any,
                        };
                        return getLatLng(results[0])
                      }).then(({ lat, lng }) => {
                        val.geoPoint = { lat, lng };
                        form.setFieldsValue({ ...val })
                      });
                  }
                }}
              />
            </div>
          </div>)}

          <div className="mt-2 flex space-x-4">
            <div >
              <Form.Item
                className="mb-0"
                label="Longitude"
                name={['geoPoint', 'lng']}
                rules={[required]}
              >
                <InputNumber placeholder="Longitude" />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                className="mb-0"
                label="Latitude"
                name={['geoPoint', 'lat']}
                rules={[required]}
              >
                <InputNumber placeholder="Latitude" />
              </Form.Item>
            </div>
          </div>
        </div>
        {structure && structure.structureType === StructureTypesEnum.groupement_communes && (
          <Form.Item
            label="Commune"
            required
            name="communeName"
            rules={[required]}
          >
            <Select>
              {structure && (structure as IBaseCommune).communes && (structure as IBaseCommune).communes.map(c => (<Select.Option key={c.name}>{c.name}</Select.Option>))}
            </Select>
          </Form.Item>)
        }

        <div className="grid gap-4 grid-cols-2">
          <Form.Item
            label="Type de dispositif"
            rules={[required]}
            name="dispositifType"
          >
            <Select>
              {Object.keys(DispositifTypeEnum)
                .filter((k): k is DispositifTypeEnum => typeof k === 'string')
                .filter(k => dipositifTypes ? dipositifTypes.includes(k) : true)
                .map(type => (
                  <Select.Option key={type} value={type}>{t(`TYPES.INVENTORY.DispositifTypeEnum.${type}`)}</Select.Option>
                ))}
            </Select>
          </Form.Item>
          {editOldValue || !collectPoint?.placeType || Object.keys(PlaceTypeEnum).includes(collectPoint?.placeType) ?
            <Form.Item
              label="Type de lieu"
              name="placeType"
              rules={[required]}
            >
              <Select>
                {Object.keys(PlaceTypeEnum).filter(k => typeof k === 'string').map(type => (
                  <Select.Option key={type} value={type}>{t(`TYPES.INVENTORY.PlaceTypeEnum.${type}`)}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            :
            <Form.Item
              label="Type de lieu"
              help={!Object.keys(PlaceTypeEnum).includes(collectPoint?.placeType) ? `${t(`TYPES.INVENTORY.PlaceTypeEnum.${collectPoint.placeType}`)} n'est plus une valeur disponible` : undefined}
            >
              <><span>{t(`TYPES.INVENTORY.PlaceTypeEnum.${collectPoint.placeType}`)}</span> <Button type="link" htmlType="button" onClick={_ => setEditOldValue(true)} icon={<EditOutlined />}></Button></>
            </Form.Item>
          }
        </div>
        
        {mode && mode === 'acquisitionOrder' && (
          <div className="flex space-x-4 justify-between">
            <Form.Item
              label="Date d'installation"
              name="dateInstallation"
              rules={[required]}
            >
              <DatePicker format={'DD/MM/YYYY'} />
            </Form.Item>
          </div>
        )}
        {mode && mode === 'supportOrder' && (
          <div className="flex space-x-4 justify-between">
            <Form.Item
              label="Prix unitaire"
              name="price"
              rules={[required]}
            >
              <InputNumber min={1}></InputNumber>
            </Form.Item>
            <Form.Item
              label="Date d'installation"
              name="dateInstallation"
              rules={[required]}
            >
              <DatePicker format={'DD/MM/YYYY'} />
            </Form.Item>
          </div>
        )}

        <Form.Item
          label="Commentaires"
          name="comments"
        >
          <TextArea disabled={canEdit === false} />
        </Form.Item>
        {canEdit && (<div className="flex space-x-4 justify-between">
          <div>
            {onDelete && collectPoint?.id && (
              <Button type="link" className="btn btn-link text-alc-danger" htmlType="button"
                onClick={() => {
                  setConfirm(true);
                  return confirm && collectPoint && onDelete && onDelete(collectPoint)
                }}>
                {confirm ? 'Cliquez pour confirmer' : 'Supprimer'}
              </Button>
            )}
          </div>
          <div>
            <Button type="link" className="btn btn-link" htmlType="button" onClick={onCancel}>Annuler</Button>
            <Button type="primary" className="btn" htmlType="submit" >Ok</Button>
          </div>
        </div>)}
      </Form >
      {!canEdit &&
        (<div className="text-right">
          <Button type="primary" className="btn" htmlType="button" onClick={onCancel}>Fermer</Button>
        </div>
        )
      }

    </>
  )
}

export default CollectPointForm
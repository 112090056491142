import { Models } from "@alcome-rep/alcome-types";
import { AnnualReviewStatusEnum, IAnnualReview, IBaseCommune, ICollectPoint, IHotspot, IInventory, IStructure, InventoryStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { Loader } from "@googlemaps/js-api-loader";
import { Button, Select } from "antd";
import { useEffect, useState } from "react";
import LoadingScreen from "../../../common/components/LoadingScreen";
import Section from "../../../common/components/Panels/Section";
import { isAnnualReview, isInventory } from "../../../common/tools/type-tools";
import CollectPointList from "../CollectPoint/collectPointList";
import MapComponent from "../Gmap/mapComponent";
import HotspotList from "../Hotspot/hotspotList";
import MapStatsComponent from "../MapStats/mapStats";

type PropsType = {
  user: Models.User,
  structure: IStructure,
  layer: IInventory | IAnnualReview,
  canEdit: boolean
};

const Cartography = ({ structure, layer, user, canEdit = true }: PropsType) => {
  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLEMAP_KEY || '',
      libraries: ["drawing", "places"]
    });
    loader.libraries.forEach(l => loader.importLibrary(l))
    // loader.load()
  }, [])

  const [modeMap, setModeMap] = useState<boolean>(false)

  const isAdmin = Models.User.IsUserAdmin(user);

  const [selectedCommune, setSelectedCommune] = useState<string | undefined>((structure.structureType !== 'commune') ? (structure as IBaseCommune).communes[0].name : undefined)

  // Affiche les dispositifs de collecte de l'inventaire uniquement si l'inventaire à été validé
  const initialCollectPoints = ((isInventory(layer) && ![InventoryStatusEnum.pending, InventoryStatusEnum.refused].includes(layer.status))
    || (isAnnualReview(layer) && ![AnnualReviewStatusEnum.pending, AnnualReviewStatusEnum.refused].includes(layer.status)) ?
    layer.collectPoints : undefined) as ICollectPoint[] | undefined;

  // Affiche les hotspots de l'inventaire uniquement si l'inventaire à été validé
  const initialHotspots = ((isInventory(layer) && ![InventoryStatusEnum.pending, InventoryStatusEnum.refused].includes(layer.status))
    || (isAnnualReview(layer) && ![AnnualReviewStatusEnum.pending, AnnualReviewStatusEnum.refused].includes(layer.status)) ?
    layer.hotspots : undefined) as IHotspot[] | undefined

  if (!structure)
    return <LoadingScreen />

  const isInventoryValidated = isInventory(layer) && layer.status === InventoryStatusEnum.validated;
  const isAnnualReviewValidated = isAnnualReview(layer) && layer.status === AnnualReviewStatusEnum.validated;
  
  return <Section mode="transparent">
    <div>
      {!modeMap && <Button size="large" onClick={() => setModeMap(true)} className="mb-4 w-full">
        {!canEdit ? 'Voir' : 'Placer'} les dispositifs de collecte et hotspots directement sur la carte
      </Button>}
      {modeMap && <Button type="default" htmlType="button" className="btn" onClick={() => setModeMap(false)}>Fermer la carte</Button>}
    </div>

    {isAdmin && (isInventoryValidated || isAnnualReviewValidated) &&
      <MapStatsComponent structure={structure} mapStats={layer.mapStats ? layer.mapStats : undefined}/>
    }

    {(structure.structureType !== 'commune') && !modeMap && (
      <div className="mb-6 mt-6">
        <span className="font-semibold">Selectionnez une commune :</span>
        <Select
          value={selectedCommune}
          showSearch
          options={(structure as IBaseCommune).communes.map(c => ({
            value: c.name,
            label: c.name
          }))}
          onChange={setSelectedCommune}
        />
      </div>
    )}

    {!modeMap &&
      <div className="grid grid-cols-2 gap-4">
        <CollectPointList structure={structure} selectedCommune={selectedCommune} canEdit={canEdit} layer={layer} initialCollectPoints={initialCollectPoints} />
        <HotspotList structure={structure} canEdit={canEdit} selectedCommune={selectedCommune} toggleMapModeCb={() => setModeMap(true)} initialHotspots={initialHotspots} />
      </div>
    }
    {modeMap && <MapComponent
      structure={structure}
      layer={layer}
      editMode={canEdit ? 'edit' : 'readOnly'}
      initialCollectPoints={canEdit ? undefined : initialCollectPoints}
      initialHotspots={canEdit ? undefined : initialHotspots}
    />
    }
  </Section>
}

export default Cartography
import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


type PropTypes = {
  previousEnabled?: boolean,
  submit?: (...args: any[]) => any,
  disable?: boolean
}

const NextStepComponent = ({ submit, previousEnabled = true, disable = false }: PropTypes) => {

  const { t } = useTranslation();
  const navigate = useNavigate()

  return (
    <>
      <div>
        <hr />
        <div className='flex justify-between items-center'>
          {previousEnabled ? <Button onClick={_ => navigate(-1)} type="link" icon={<LeftOutlined />} >{t('COMMON.back')}</Button> : <div></div>}
          {submit === undefined ?
            <Button disabled={disable} htmlType="submit" className="bg-alc-blue text-white text-center">{t('COMMON.continue')}</Button>
            :
            <Button disabled={disable} htmlType="button" onClick={_ => submit()} className="bg-alc-blue text-white text-center">{t('COMMON.continue')}</Button>
          }
        </div>
      </div>
    </>
  );
};

export default NextStepComponent;
import { IStructure } from "@alcome-rep/alcome-types/dist/interfaces";
import { BaseCommune } from "@alcome-rep/alcome-types/dist/models";
import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import useFormRules from "../../../../common/hooks/useFormRules";

type PropsType = {
    structure: BaseCommune,
    submit: (structureData: IStructure) => void,
    isShipping?: boolean,
    index?: number
}

const AddressForm = ({ structure, submit, isShipping, index }: PropsType) => {
    const { t } = useTranslation();

    const { required } = useFormRules();
    return (
        <>
            {structure && <Form
                name="basic"
                initialValues={structure}
                layout='vertical'
                onFinish={submit}
                className="grid grid-cols-2 gap-x-4"
                id="pa"
            >
                {isShipping && (
                    <>
                        <Form.Item
                            label={t('UI.STRUCTURE_DETAILS.fields.shipping.label')}
                            name={['shippingAddress', index!, 'label']}
                            className="col-span-2"
                            rules={[required]}
                        >
                            <Input></Input>
                        </Form.Item>
                        <Form.Item
                            label={t('UI.STRUCTURE_DETAILS.fields.shipping.service')}
                            name={['shippingAddress', index!, 'service']}
                            className="col-span-2"
                        >
                            <Input></Input>
                        </Form.Item>
                        <Form.Item
                            label={t('UI.STRUCTURE_DETAILS.fields.street.label')}
                            name={['shippingAddress', index!,'street']}
                            className="col-span-2"
                            rules={[required]}
                        >
                            <Input></Input>
                        </Form.Item>
                        <Form.Item
                            label={t('UI.STRUCTURE_DETAILS.fields.zip.label')}
                            name={['shippingAddress', index!,'zip']}
                            rules={[required]}
                        >
                            <Input></Input>
                        </Form.Item>
                        <Form.Item
                            label={t('UI.STRUCTURE_DETAILS.fields.city.label')}
                            name={['shippingAddress', index!,'city']}
                            rules={[required]}
                        >
                            <Input></Input>
                        </Form.Item>
                    </>
                )}

                {!isShipping && (
                    <>
                        <Form.Item
                            label={t('UI.STRUCTURE_DETAILS.fields.street.label')}
                            name={['address', 'street']}
                            className="col-span-2"
                        >
                            <Input></Input>
                        </Form.Item>
                        <Form.Item
                            label={t('UI.STRUCTURE_DETAILS.fields.zip.label')}
                            name={['address', 'zip']}
                        >
                            <Input></Input>
                        </Form.Item>
                        <Form.Item
                            label={t('UI.STRUCTURE_DETAILS.fields.city.label')}
                            name={['address', 'city']}
                        >
                            <Input></Input>
                        </Form.Item>
                        <Form.Item
                            label={t('UI.STRUCTURE_DETAILS.fields.email.label')}
                            name={['email']}
                        >
                            <Input></Input>
                        </Form.Item>
                        <Form.Item
                            label={t('UI.STRUCTURE_DETAILS.fields.phone.label')}
                            name={['phone']}
                        >
                            <Input></Input>
                        </Form.Item>
                    </>
                )}
                <Form.Item>
                    <Button htmlType="submit" type="primary"  >
                        Modifier les informations
                    </Button>
                </Form.Item>
            </Form>}
        </>
    )
}

export default AddressForm
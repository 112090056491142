import { Models } from "@alcome-rep/alcome-types";
import { IFileDocument } from "@alcome-rep/alcome-types/dist/interfaces";
import { ISupplierFileDocument } from "@alcome-rep/alcome-types/dist/interfaces/supplier/supplierFileDocument";
import { EditOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { selectUser } from "../../../../Auth/state/auth.slice";
import adminSupplierOrderApi from "../../../../SupplierOrder/services/admin-supplierOrder.api";
import LoadingScreen from "../../../../common/components/LoadingScreen";
import Section from "../../../../common/components/Panels/Section";
import UserFileFields from "../../../../common/components/portal/UserFileFields/UserFileFields";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks";
import { openDrawerAction } from "../../../../common/state/modal.slice";
import supplierOrderApi from "../../../services/supplierOrder.api";
import FileMetadataForm from "../FileMetadataForm/FileMetadataForm";

const AdminSupplierOrderInvoices = () => {

    const dispatch = useAppDispatch();

    const { supplierOrderId }: any = useParams();

    const user = useAppSelector(selectUser)

    const [supplierOrder, setSupplierOrder] = useState<Models.SupplierOrder>()
    const [refresh, setRefresh] = useState<Date>()

    useEffect(() => {
        if (!supplierOrderId)
            return

        adminSupplierOrderApi.getSupplierOrderById(supplierOrderId)
            .then(res => {
                setSupplierOrder(res)
            })

    }, [supplierOrderId, refresh])

    const getInvoice = async (file: ISupplierFileDocument | IFileDocument): Promise<any> => {
        if (supplierOrder)
            return await supplierOrderApi.getFileDocument(file.propertyName as any, supplierOrder.id as string, supplierOrder.supplierCompanyId, file.timestamp as any)
    }

    const displayTsx = (file: ISupplierFileDocument) => {
        return (
            <Button
                type="link"
                onClick={() => dispatch(openDrawerAction({
                    component: <FileMetadataForm fileDoc={file} setRefresh={setRefresh} />,
                    options: {
                        title: 'Modifier les métadonnées du fichier',
                        size: 'large'
                    }
                }))}
                icon={<EditOutlined />}

            >modifier</Button>
            
        )
    }

    if (!supplierOrder)
        return <LoadingScreen />

    return (
        <>
            <div className="print:hidden">
                {
                    supplierOrder.invoices!.length > 0 ?
                        <div>
                            <Section title="Factures">
                                <UserFileFields
                                    canEdit={!user?.isSuperAdmin()}
                                    download={getInvoice}
                                    fileDocument={(supplierOrder.invoices ?? []) as ISupplierFileDocument[]}
                                    propertyName="invoices"
                                    modifyFileMetadata={displayTsx}
                                    displayStatus={true}
                                />
                            </Section>
                        </div>
                        :
                        <Alert message={<div>Aucune facture disponible pour le moment.</div>} />
                }
            </div>
        </>
    )
}

export default AdminSupplierOrderInvoices
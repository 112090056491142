import { IAcquisitionOrders, IFileDocument } from "@alcome-rep/alcome-types/dist/interfaces"
import { ISupplierFileDocument } from "@alcome-rep/alcome-types/dist/interfaces/supplier/supplierFileDocument"
import { User } from "@alcome-rep/alcome-types/dist/models"
import { RcFile } from "antd/es/upload"
import { selectUser } from "../../../../Auth/state/auth.slice"
import Section from "../../../../common/components/Panels/Section"
import UserFileFields from "../../../../common/components/portal/UserFileFields/UserFileFields"
import { useAppDispatch, useAppSelector } from "../../../../common/hooks"
import acquisitionOrderApi from "../../../services/acquisitionOrder.api"
import acquisitionOrderAdminApi from "../../../services/admin-acquisitionOrder.api"
import { setCurrentPortalAcquisitionOrderAction } from "../../../state/acquisitionOrder-portal.slice"

type PropsType = {
  acquisitionOrder: IAcquisitionOrders;
}

const AcquisitionOrderMaterialInvoices = ({ acquisitionOrder }: PropsType) => {

  const user = useAppSelector(selectUser);
  const isAdmin = User.IsUserAdmin(user);
  const dispatch = useAppDispatch();

  const getInvoice = async (file: IFileDocument | ISupplierFileDocument): Promise<any> => {
    if (isAdmin)
      return await acquisitionOrderAdminApi.getFileDocument(file.propertyName as any, acquisitionOrder.id as string, file.timestamp)
    else
      return await acquisitionOrderApi.getFileDocument(file.propertyName as any, acquisitionOrder.id as string, file.timestamp)
  }

  const uploadFile = async (file: RcFile) => {
    if (acquisitionOrder) {
      return await acquisitionOrderApi.uploadFiles('invoices', acquisitionOrder.id as string, file)
        .then((sp) => dispatch(setCurrentPortalAcquisitionOrderAction(sp)))
    }
  }

  const deleteFile = (fileDoc: IFileDocument | ISupplierFileDocument): any => {
    if (acquisitionOrder) {
      return acquisitionOrderApi.deleteFile(fileDoc.propertyName as keyof IAcquisitionOrders, acquisitionOrder.id as string, fileDoc.timestamp)
        .then((sp) => dispatch(setCurrentPortalAcquisitionOrderAction(sp)))
    }
  }

  return (
    <>
      {(!isAdmin || (acquisitionOrder.invoices && acquisitionOrder.invoices?.length > 0)) && (
        <Section title="Justificatifs" titleClass="!text-lg mt-4" mode="transparent">
          <UserFileFields
            canEdit={!user?.isSuperAdmin()}
            download={getInvoice}
            fileDocument={acquisitionOrder.invoices as IFileDocument[]}
            propertyName="invoices"
            upload={uploadFile}
            deleteFile={deleteFile}
          />
        </Section>
      )}
    </>
  )
}

export default AcquisitionOrderMaterialInvoices
import { Button, Drawer } from "antd";
import { useState } from "react";
import CreateProductForm from "./CreateProductForm";
import { useNavigate } from "react-router-dom";
import { getRoute } from "../../../../common/tools/router-tools";
import { ROUTES } from "../../../../common/router";
import { IProduct } from "@alcome-rep/alcome-types/dist/interfaces/product";

type Props = {
  refresh: (value: Date) => any
}

const AdminCreateProduct = ({ refresh }: Props) => {

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true)
  };

  const navigate = useNavigate();

  const onClose = (data?: IProduct) => {
    setOpen(false)
    if (data)
      navigate(getRoute(ROUTES.ADMIN_PRODUCT_ROUTES.productDetails, { productId: data.id }))
  };

  return (
    <>
      <Button className="btn" onClick={showDrawer}>Créer un produit</Button>
      <Drawer
        title="Produit"
        width='50%'
        onClose={_ => onClose()}
        open={open}
        styles={{ body: { paddingBottom: 80 } }}
      >
        {open && <CreateProductForm close={onClose} />}
      </Drawer>
    </>
  )
}

export default AdminCreateProduct
import { Inventory } from "@alcome-rep/alcome-types/dist/models";
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../common/store";
import { UpdateInventoryInfosPayload } from "../services/inventory-portal.api";
import { CreateInventoryPayload, INVENTORY_PORTAL_SERVICE, InventoryServiceState } from "./types";

const inventoryInitialState: InventoryServiceState = {
  currentInventory: null
};

export const inventoryPortalSlice = createSlice({
  name: INVENTORY_PORTAL_SERVICE,
  initialState: inventoryInitialState,
  reducers: {

    getInventoryAction: (state: InventoryServiceState, _payload: PayloadAction) => {
      state.currentInventory = null;
    },

    createInventoryAction: (state: InventoryServiceState, _payload: PayloadAction<CreateInventoryPayload>) => {
      state.currentInventory = null;
    },

    updateInventoryInfosAction: (state: InventoryServiceState, _payload: PayloadAction<UpdateInventoryInfosPayload>) => {
      state.currentInventory = null;
    },

    // updateInventoryMapAction: (state: InventoryServiceState, _payload: PayloadAction<UpdateInventoryInfosPayload>) => {
    //     state.currentInventory = null;
    // },

    setCurrentInventoryAction: (state: InventoryServiceState, { payload: inventory }: PayloadAction<Inventory>) => {
      state.currentInventory = inventory;
    },

    unsetCurrentInventoryAction: (state: InventoryServiceState, _payload: PayloadAction) => {
      state.currentInventory = null;
    },

  }
});


export const {
  getInventoryAction,
  createInventoryAction,
  updateInventoryInfosAction,
  setCurrentInventoryAction,
  unsetCurrentInventoryAction
} = inventoryPortalSlice.actions;

export default inventoryPortalSlice.reducer;

export const selectInventoryService = (state: RootState) => state.inventoryPortalService
export const selectCurrentInventory = createSelector(selectInventoryService, (inventorySvc) => inventorySvc.currentInventory)
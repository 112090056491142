import { Menu } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../common/router';

const AuthTabs = () => {
  const { t } = useTranslation();

  const items: ItemType[] = [
    {
      key: 'register',
      label: <NavLink to={ROUTES.REGISTER} className={({ isActive }) => isActive ? 'font-semibold text-alc-blue' : ''}>
        {t('UI.AUTH.TABS.register')}
      </NavLink>
    },
    {
      key: 'login',
      label: <NavLink to={ROUTES.LOGIN} className={({ isActive }) => isActive ? 'font-semibold text-alc-blue' : ''}>
        {t('UI.AUTH.TABS.login')}
      </NavLink>
    }
  ]

  return (<Menu onClick={() => { }} className="mb-8" mode="horizontal" items={items}></Menu>)
}

export default AuthTabs;
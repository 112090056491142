import { IAcquisitionOrders } from "@alcome-rep/alcome-types/dist/interfaces";
import { createSelector } from "@reduxjs/toolkit";
import factoryCreateSlice from "../../common/state/slice.factory";
import { RootState } from "../../common/store";

export const ACQUISITION_ORDER_SERVICE = "acquisitionOrderPortalService";
// eslint-disable-next-line
export type ACQUISITION_ORDER_SERVICE = typeof ACQUISITION_ORDER_SERVICE; // Typescript line


const slices = factoryCreateSlice<"acquisitionPortalOrder", IAcquisitionOrders>('acquisitionPortalOrder')

export const {
  getAction: getPortalAcquisitionOrderAction,
  updateAction: updatePortalAcquisitionOrderAction,
  setCurrentAction: setCurrentPortalAcquisitionOrderAction,
} = slices.actions;
export default slices.reducer;

export const selectPortalAcquisitionOrderService = (state: RootState) => state.acquisitionOrderPortalService
export const selectCurrentPortalAcquisitionOrder = createSelector(selectPortalAcquisitionOrderService, (acquisitionSvc) => acquisitionSvc.current)
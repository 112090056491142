import { IAction } from "@alcome-rep/alcome-types/dist/interfaces/crm";
import ApiService, { ApiSortType } from "../../common/services/apiService";
import { buildFindQuery, buildSortQuery } from "../../common/tools/global-tools";
import { ApiListResult, Models } from "@alcome-rep/alcome-types";

const ACTION_ADMIN_PATH_API = 'admin/action';

class ActionAdminApi extends ApiService<IAction> {
  constructor() {
    super(ACTION_ADMIN_PATH_API)
  }

  findActions({ query, page, limit, sort }: { query: any, page: number, limit: number, sort?: ApiSortType }) {
    return this.find({ query, page, pageSize: limit, sort })
  }

  findProspects({ query, page, limit, sort }: { query: any, page: number, limit: number, sort?: ApiSortType }) {
    const myQuery = { ...query }

    let queryString = buildFindQuery(myQuery);
    if (sort) {
      queryString += buildSortQuery(sort)
    }
    return this.custom<ApiListResult<Models.Prospect>>('get', `prospects?${queryString}&page=${page}&limit=${limit}`)
      .then(r => ({ ...r, rows: r.rows.map(row => new Models.Prospect(row)) }))
  }

  findProspectActions(prospectId: string, { query, page, limit, sort }: { query: any, page: number, limit: number, sort?: ApiSortType }) {
    return this.find({ query: { ...query, prospectId }, page, pageSize: limit, sort })
  }

  getAction = (actionId: string) => {
    return this.get(actionId)
  }

  updateAction(actionId: string, data: any) {
    return this.update(actionId, data)
  }

  deleteAction(actionId: string) {
    return this.delete(actionId)
  }

}

const actionAdminApi = new ActionAdminApi();
export default actionAdminApi;
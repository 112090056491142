import { IAmendment, StructureTypesEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table, TablePaginationConfig, Tag, message } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import StructureType from '../../../../Structure/components/common/StructureType';
import { defaultPagination } from '../../../../common/app-constants';
import FilterEntity from '../../../../common/components/Filters/FilterEntity';
import FilterInput from '../../../../common/components/Filters/FilterInput';
import FilterPanel from '../../../../common/components/Panels/FilterPanel';
import PageCanvas from '../../../../common/components/Panels/PageCanvas';
import { useAppDispatch } from '../../../../common/hooks';
import { ROUTES } from '../../../../common/router';
import { openDrawerAction } from '../../../../common/state/modal.slice';
import { getRoute, getSearchParams } from '../../../../common/tools/router-tools';
import amendmentAdminApi from '../../../services/amendment-admin.api';
import AdminCreateAmendment from '../AdminCreateAmendment/AdminCreateAmendment';


const AdminAmendmentList = () => {

  const { t } = useTranslation()

  const [data, setData] = useState<IAmendment[]>([]);
  const [count, setCount] = useState<number>(1);
  const [loading, setloading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<Date>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  let [searchParams, setSearchParams] = useSearchParams();

  const queryParams = useMemo(() => {
    const { page = 1, limit = defaultPagination.pageSize, sortField = 'name', sortOrder = 1, ...query } = getSearchParams(searchParams);
    return { page, limit, sortField, sortOrder, query }
  }, [searchParams])

  useEffect(() => {
    setloading(true)
    amendmentAdminApi.findAmendments(queryParams.query, Number(queryParams.page), Number(queryParams.limit))
      .then(res => {
        setData(res.rows)
        setCount(res.count)
        setloading(false)
      })
      .catch(() => {
        setData([])
        setCount(0)
        setloading(false)
      })
  }, [setData, setCount, setloading, queryParams, refresh])

  const getData = async (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter?: SorterResult<IAmendment> | SorterResult<IAmendment>[],
    extra?: TableCurrentDataSource<IAmendment>
  ) => {
    // setSortedInfo(sorter as SorterResult<any>);

    const q = Object.keys(filters).reduce((acc, k) => {
      if (filters[k] !== null)
        acc[k] = filters[k];
      return acc;
    }, {} as any)

    setSearchParams({ page: pagination.current, limit: pagination.pageSize, ...q })
  }


  const columns = [
    {
      key: 'actions',
      width: '60px',
      render: (_: any, record: any) => <Button
        type="link"
        icon={<EditOutlined />}
        onClick={_ => navigate(getRoute(ROUTES.ADMIN_ADMENDMENT_ROUTES.amendmentDetails, { amendmentId: record.id }))}
      />
    },
    {
      title: 'N° de l\'avenant',
      dataIndex: 'amendmentNumber',
      key: 'amendmentNumber',
    },
    {
      title: 'Nom',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type de structure',
      dataIndex: 'structureTypes',
      key: 'structureTypes',
      render: (value: any) => Array.isArray(value) && value.map((v, i) => (<StructureType key={i} mode="tag" structure={{ structureType: v } as any} />))
    },
    {
      title: 'Version du contrat',
      dataIndex: 'contractVersion',
      key: 'contractVersion',
    },
    {
      title: 'Publié',
      dataIndex: 'published',
      key: 'published',
      render: (value: any) => {
        if (value)
          return <Tag color='green'>Oui</Tag>
        else
          return <Tag color='red'>Non</Tag>
      }
    },
    {
      title: 'Date avenant',
      dataIndex: 'date',
      key: 'date',
      render: (av: any) => (<span>{format(parseISO(av), "' 'd MMMM yyyy'", { locale: fr })}</span>)
    },
    {
      key: 'delete',
      width: '60px',
      render: (_: any, record: any) => <Button
        className='text-alc-danger'
        type="link"
        icon={<DeleteOutlined />}
        onClick={_ => amendmentAdminApi.deleteAmendment(record.id).then(res => {
          if(res){
            setRefresh(new Date())
            message.success("Avenant supprimé avec succès")
            return res
          }else{
            setRefresh(new Date())
            message.error("Erreur lors de la suppression")
          }
        })}
      />
    },
  ];

  return (
    <>
      <PageCanvas
        breadCrumbs={[
          ROUTES.ADMIN_USER_ROUTES.home,
          ROUTES.ADMIN_ADMENDMENT_ROUTES.amendmentList
        ]}
        title={<span>Liste des Avenants <small>({count ? count : 0})</small ></span >}
        actions={<Button className="btn" onClick={() => dispatch(openDrawerAction({
          component: <AdminCreateAmendment onSubmit={() => setRefresh(new Date())} />,
          options: {
            title: 'Ajouter un avenant au contrat',
            size: 'large'
          }
        }))}> + Ajouter un avenant </Button>}
      >
        <FilterPanel>
          <FilterInput searchParamName='amendmentNumber' label="N° de l'avenant" isRegex={true} />
          <FilterInput searchParamName='name' label='Nom' isRegex={true} />
          <FilterEntity
            filterName='Type de structure'
            multiple={false}
            queryParamName='structureTypes'
            options={
              Object.keys(StructureTypesEnum)
                .filter(k => typeof k === 'string')
                // .filter(v => v !== StructureTypesEnum.member_structure)
                .map(k => ({ label: t('TYPES.STRUCTURE.StructureTypesEnum.' + k), value: k }))
            }
          />
        </FilterPanel>
        <div className='grid grid-cols-1 grid-rows-2 gap-6'>
          <Table
            style={{ cursor: 'pointer' }}
            columns={columns}
            dataSource={data}
            loading={loading}
            onChange={getData}
            rowKey="id"
            pagination={{
              pageSize: 10,
              total: count
            }} />
        </div>
      </PageCanvas >
    </>

  );
};

export default AdminAmendmentList;
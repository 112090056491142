import { RcFile } from "antd/es/upload"
import { selectCurrentContract, setCurrentContractAction } from "../../../state/contract-portal.slice"
import UserFileField from "../../../../common/components/portal/UserFileField/UserFileField"
import { useAppDispatch, useAppSelector } from "../../../../common/hooks"
import contractPortalApi from "../../../services/contract-portal.api"

const PortalContractFileUpload = () => {
  const dispatch = useAppDispatch()
  const contract = useAppSelector(selectCurrentContract)

  const contractUpload = async (file: RcFile) => {
    if (!contract)
      throw new Error()
    const contractUpdated = await contractPortalApi.uploadContractFile(file);
    dispatch(setCurrentContractAction(contractUpdated))
  }

  const contractDownload = () => {
    if (!contract)
      throw new Error()
    return contractPortalApi.getContractFile()
  }

  if (!contract)
    return <></>;

  return <UserFileField
    fileDocument={contract.contractFile}
    upload={contractUpload}
    download={contractDownload}
    propertyName='contractFileId'
  ></UserFileField>
}

export default PortalContractFileUpload
import { UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { ReactNode } from "react";
import { Params, RouteObject, createSearchParams } from "react-router-dom";
import { defaultPagination } from "../app-constants";
import { ROUTES } from "../router";
import { buildFindQuery } from "./global-tools";

export type RouteDeclaration = { [K: string]: { path: string, element?: ReactNode, title?: string } }

export const generateRoutesFromDeclaration = (routesDeclaration: RouteDeclaration): RouteObject[] => {
  return Object.values(routesDeclaration)
}

export const getRoute = (route: RouteDeclaration[keyof RouteDeclaration], params: Params = {}, query?: any) => {
  let q = ""
  if (query)
    q = '?' + buildFindQuery(query);
  return Object.keys(params)
    .reduce((acc, k) => acc.replace(new RegExp(':' + k, 'igm'), params[k] ?? ''), '/' + route.path) + q
}

export const getHomeRoute = (userType: UserTypeEnum) => {
  switch (userType) {
    case UserTypeEnum.portal:
      return '/' + ROUTES.PORTAL_USER_ROUTES.home.path;
    case UserTypeEnum.admin:
      return '/' + ROUTES.ADMIN_USER_ROUTES.home.path;
    case UserTypeEnum.supplier:
      return '/' + ROUTES.SUPPLIER_USER_ROUTES.home.path;
  }
  return '/'
}

export const buildSearchParams = (params: Record<string, string | number>, searchParams: URLSearchParams) => {
  const newSearchParams = createSearchParams(searchParams);
  Object.keys(params)
    .forEach(param => newSearchParams.set(param, params[param].toString()))
  return newSearchParams.toString();
}

export const getPaginationParams = (searchParams: URLSearchParams): { page: number, pageSize: number, skip: number } => {
  const page = Number(searchParams.get('page') ?? defaultPagination.page);
  const pageSize = Number(searchParams.get('pageSize') ?? defaultPagination.pageSize);
  const skip = (page - 1) * (pageSize);
  return {
    page,
    pageSize,
    skip
  }
}

export const getSearchParams = (searchParams: URLSearchParams) => {
  return Array.from(searchParams).reduce((acc, param) => {
    // multi value param (array)
    if (acc[param[0]] !== undefined && !Array.isArray(acc[param[0]])) {
      acc[param[0]] = [acc[param[0]]]
    }

    if (Array.isArray(acc[param[0]]))
      acc[param[0]].push(param[1])
    else
      acc[param[0]] = param[1];
    return acc;
  }, {} as any)
}

import { SupplierOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces/supplier";
import { Tag } from "antd";
import { useTranslation } from "react-i18next";


const SupplierOrderStatus = ({ status }: { status: SupplierOrderStatusEnum }) => {

  const { t } = useTranslation();

  return <Tag color={t(`UI.TAG.SupplierOrderStatusEnum.${SupplierOrderStatusEnum[status]}`)} className={`${SupplierOrderStatusEnum[status]} text-white`}>
    {t(`TYPES.SUPPLIER_ORDER.SupplierOrderStatusEnum.${SupplierOrderStatusEnum[status]}`)}
  </Tag>
}
export default SupplierOrderStatus
import { IShippingAddress } from "@alcome-rep/alcome-types/dist/interfaces";
import { BaseCommune } from "@alcome-rep/alcome-types/dist/models";
import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import useFormRules from "../../../../common/hooks/useFormRules";

const AddShippingAddressForm = ({ structure, submit }: { structure: BaseCommune, submit: (shippingAddressData: IShippingAddress) => void }) => {
    const { t } = useTranslation();
    const { required } = useFormRules();

    return (
        <>
            {structure && <Form
                name="basic"
                initialValues={structure}
                layout='vertical'
                onFinish={submit}
                className="grid grid-cols-2 gap-x-4"
                id="pa"
            >
                <Form.Item
                    label={t('UI.STRUCTURE_DETAILS.fields.shipping.label')}
                    name={['label']}
                    className="col-span-2"
                    rules={[required]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label={t('UI.STRUCTURE_DETAILS.fields.shipping.service')}
                    name={['service']}
                    className="col-span-2"
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label={t('UI.STRUCTURE_DETAILS.fields.street.label')}
                    name={['street']}
                    className="col-span-2"
                    rules={[required]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label={t('UI.STRUCTURE_DETAILS.fields.zip.label')}
                    name={['zip']}
                    rules={[required]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label={t('UI.STRUCTURE_DETAILS.fields.city.label')}
                    name={['city']}
                    rules={[required]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" type="primary"  >
                        Ajouter une adresse de livraison
                    </Button>
                </Form.Item>
            </Form>}
        </>
    )
}

export default AddShippingAddressForm
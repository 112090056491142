import { RouteObject } from "react-router-dom"
import ActionListPage from "../components/ActionList/ActionListPage"

const routePrefix = 'admin'
const routeDomain = 'action'
const routeParam = 'actionId'

export const adminActionRouteNames = {
  actionList: { path: `${routePrefix}/${routeDomain}`, title: `ROUTES.${routeDomain}.list` },
  actionDetails: { path: `${routePrefix}/${routeDomain}/:${routeParam}`, title: "ROUTES.infos" }
}

export const adminActionRoutes: RouteObject[] = [
  {
    path: adminActionRouteNames.actionList.path.replace(`${routePrefix}/`, ''),
    element: <ActionListPage />,
  },
  // {
  //   path: adminActionRouteNames.actionDetails.path.replace(`${routePrefix}/`, ''),
  //   element: <ActionPage />
  // },
]
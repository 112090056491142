import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import NextStepComponent from '../../../../Auth/components/Register/NextStepComponent';
import { ROUTES } from '../../../../common/router';
import { getRoute } from '../../../../common/tools/router-tools';

const ContractStartStep: React.FC = () => {

  const navigate = useNavigate()
  const nextStepFnc = () => {
    return navigate(getRoute(ROUTES.PORTAL_REGISTER_CONTRACT_ROUTES.confirm_infos))
  }

  return (
    <>
      <div className='explication-text'>
        <div className='mb-4'>
          <center>
            <img src='https://alcome.eco/wp-content/uploads/2021/05/alcome_logo_AB_rvb.jpg' className='w-64' alt='Alcome' />
          </center>
        </div>
        <div className="p-4 mb-4 text-sm rounded-lg" style={{ backgroundColor: "rgb(236 254 255)", color: "rgb(30 64 175)", border: "1px solid rgb(30 64 175)" }} role="alert">
          <span className="font-medium">ALCOME met à votre disposition un guide vous aidant dans votre phase de contractualisation. Vous pouvez le télécharger en cliquant sur le bouton ci-dessous:</span><div style={{ marginTop: "15px" }}>
            <a className="btn rounded-lg py-2" download href="/inscription_et_contrat.pdf">Télécharger le guide</a></div>
        </div>
        <br />
        Vous pouvez dès à présent télécharger les documents qui vous serons utiles pour la contractualisation. Afin de faciliter le processus de contractualisation Alcome a édité une délibération type qui vous suffira de complèter et de signer.
        <div className='flex my-4'>
          <div className='flex-1 flex flex-col items-center space-y-4'>
            <h4>Contrat Type</h4>
            <div className='text-center'>Vous pouvez télécharger le contrat type en cliquant ci-dessous.</div>
            <Link to="/communes-groupements-contrat-type.pdf" target="_blank">
              <img src="/download-icon.png" className='w-16' alt='icone téléchargement' />
            </Link>
          </div>
          <div className='flex-1 flex flex-col items-center space-y-4'>
            <h4>Délibération Type</h4>
            <div className='text-center'>Vous pouvez télécharger la délibération type en cliquant ci-dessous.</div>
            <Link to="/communes-groupements-deliberation-type.pdf" target="_blank">
              <img src="/download-icon.png" className='w-16' alt='icone téléchargement' />
            </Link>
          </div>
        </div>
      </div >

      <NextStepComponent submit={nextStepFnc} previousEnabled={false} />
    </>
  )
}

export default ContractStartStep;
import { RouteObject } from "react-router-dom"
import { AdminContractRoutes } from "../../Contract/routes/admin-contract.routes"
import StructurePage from "../components/admin/StructureDetails/StructurePage"
import StructureListPage from "../components/admin/StructureList/StructureListPage"
import StructureAdminGuard from "../guards/structureAdminGuard"

const routePrefix = 'admin'
const routeDomain = 'structure'
const routeParam = 'structureId'

export const adminStructureRouteNames = {
  structureList: { path: `${routePrefix}/${routeDomain}`, title: `ROUTES.${routeDomain}.list` },
  structureDetails: { path: `${routePrefix}/${routeDomain}/:${routeParam}`, title: "ROUTES.infos" }
}

export const adminStructureRoutes: RouteObject[] = [
  {
    path: adminStructureRouteNames.structureList.path.replace(`${routePrefix}/`, ''),
    element: <StructureListPage />,
  },
  {
    path: '',
    element: <StructureAdminGuard></StructureAdminGuard>,
    children: [
      {
        path: adminStructureRouteNames.structureDetails.path.replace(`${routePrefix}/`, ''),
        element: <StructurePage />
      },
      ...AdminContractRoutes,
    ]
  }
]
import { ContactTypeEnum, IContact } from '@alcome-rep/alcome-types/dist/interfaces/crm';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Progress, Radio, Table } from 'antd';
import { useEffect, useState } from 'react';
import Section from '../../../common/components/Panels/Section';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { closeDrawerAction, openDrawerAction } from '../../../common/state/modal.slice';
import contactAdminApi from '../../services/contact-admin.api';
import { selectCurrentAdminProspect } from '../../state/prospect-admin.slice';
import ContactInfosForm from '../ContactDetails/ContactInfosForm';
import ContactStatus from '../ContactDetails/ContactStatus';

const ProspectContactList = () => {
  const [data, setData] = useState<IContact[]>([]);

  const prospect = useAppSelector(selectCurrentAdminProspect)
  if (!prospect)
    throw new Error()

  const [refresh, setRefresh] = useState<Date>();

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!prospect || !prospect.id)
      return;

    contactAdminApi.findProspectContacts(prospect.id!)
      .then(res => {
        setData(res.rows)
      })
      .catch(() => {
        setData([])
      })
  }, [prospect, refresh])

  const editContact = (contact?: IContact) => {
    dispatch(openDrawerAction({
      component: <ContactInfosForm
        prospectId={prospect.id as string}
        formCb={data => {
          dispatch(closeDrawerAction())
          if (data === null || data)
            setRefresh(new Date())
        }}
        contact={contact}
      />,
      options: { title: "Contact", size: 'large' }
    }))
  }

  const columns: any = [
    {
      fixed: 'left',
      width: '50px',
      render: (value: any) => {
        return (
          <Button
            type="link"
            onClick={() => editContact(value)}
            icon={<EditOutlined />}
          />
        )
      }
    },
    {
      title: "Prénom",
      fixed: 'left',
      dataIndex:'firstName',
      key: "firstName",
    },
    {
      title: "Nom",
      fixed: 'left',
      dataIndex:'lastName',
      key: "lastName",
    },
    {
      title: "Mobile",
      dataIndex: 'mobile',
      key: "mobile",
    },
    {
      title: "Email",
      dataIndex: 'email',
      key: "email",
    },
    {
      title: "Poste",
      dataIndex: 'fonction',
      key: "fonction",
    },
    {
      title: "Décisionnaire",
      dataIndex: "decisionMakingPower",
      key: "decisionMakingPower",
      render: (value: any) => <Progress showInfo={false} percent={value * 20} steps={5} />,
    },
    {
      title: "Statut",
      dataIndex: "status",
      key: "status",
      render: (_value: any, contact: IContact) => <ContactStatus contact={contact} />
    },
    {
      title: "Contact principal",
      dataIndex: "type",
      key: "type",
      render: (value: any) => {
        return (<Radio checked={value === ContactTypeEnum.mainContact} />)
      },
    }
  ];

  const openDrawer = () => {
    dispatch(openDrawerAction({
      options: { title: 'Nouveau contact', size: "large" },
      component: <ContactInfosForm prospectId={prospect.id as string} formCb={() => setRefresh(new Date())} />
    }))
  }

  return (
    <Section
      title="Contacts du prospect"
      actions={<Button type="primary" onClick={openDrawer} icon={<PlusOutlined />}>Nouveau contact</Button>}
    >
      <Table<IContact>
        columns={columns}
        dataSource={data}
        size="small"
        rowKey="id"
        // scroll={{ scrollToFirstRowOnChange: true, x: 1000 }}
        pagination={false}
      />

    </Section>
  );
};

export default ProspectContactList;
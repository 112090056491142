import { FileDocumentStatusEnum, IFileDocument } from "@alcome-rep/alcome-types/dist/interfaces"
import { ISupplierFileDocument } from "@alcome-rep/alcome-types/dist/interfaces/supplier/supplierFileDocument"
import { Tag } from "antd"
import { useTranslation } from "react-i18next"

type PropsType = {
  fileDocument: IFileDocument | ISupplierFileDocument
}

const FileDocumentStatus = ({ fileDocument }: PropsType) => {
  const { t } = useTranslation();

  if (fileDocument?.status === undefined) {
    return <span> – </span>
  }

  return <Tag className="space-x-2" color={t(`UI.TAG.FileDocumentStatusEnum.${FileDocumentStatusEnum[fileDocument?.status]}`)}>
    {t(`TYPES.FILEDOCUMENT.FileDocumentStatusEnum.${FileDocumentStatusEnum[fileDocument?.status]}`)}
  </Tag>
}

export default FileDocumentStatus
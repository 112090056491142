import { IUser, IUserPortal, UserPortalRolesEnum } from '@alcome-rep/alcome-types/dist/interfaces/user';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { selectUserPortal } from '../../../../Auth/state/auth.slice';
import LoadingScreen from '../../../../common/components/LoadingScreen';
import Section from '../../../../common/components/Panels/Section';
import { useAppSelector } from '../../../../common/hooks';
import userPortalApi from '../../../services/user-portal.api';
import PageCanvas from '../../../../common/components/Panels/PageCanvas';
import { ROUTES } from '../../../../common/router';

const UserPortalDetailsPage = () => {

  const { t } = useTranslation();
  const [data, setData] = useState<IUser>();
  const { userId }: any = useParams();
  const navigate = useNavigate()

  const user = useAppSelector(selectUserPortal);
  const admin = user.role === UserPortalRolesEnum.structureAdmin

  useEffect(() => {
    userPortalApi.getUserInStructure(userId)
      .then(res => setData(res))
  }, [userId]);

  const submit = (userData: IUserPortal) => {
    userPortalApi.updateUserInStructure(userId, userData)
      .then(_ =>
        userPortalApi.getUserInStructure(userId)
      )
  }

  const deleteUser = () => {
    confirm({
      title: 'Voulez-vous supprimer cet utilisateur ?',
      icon: <QuestionCircleOutlined />,
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okText: 'Supprimer l\'utilisateur',
      okButtonProps: { className: "btn" },
      onOk() {
        userPortalApi.structureAdminSoftDeleteUser(userId)
          .then(r => navigate(-1))
      },
      onCancel() {

      },
    })
  }

  if (!data) {
    return <><LoadingScreen></LoadingScreen></>
  }

  return (
    <PageCanvas
      breadCrumbs={[
        ROUTES.PORTAL_USER_ROUTES.home,
        ROUTES.PORTAL_USER_ROUTES.administratifProfil,
        ROUTES.PORTAL_USER_ROUTES.userDetails
      ]}
      title={<div>
        <div>Profil de l'utilisateur</div>
      </div>}
    >
      <Section>
        {data && (
          <Form
            name="basic"
            layout='vertical'
            onFinish={submit}
            initialValues={data}
          >
            <Form.Item
              label="Prénom de l'utilisateur"
              name="firstName"
              className='mt-3'
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              label="Nom de l'utilisateur"
              name="lastName"
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              label="Role de l'utilisateur"
              name="role"
            >
              <Select>
                {Object.values(UserPortalRolesEnum)
                  .filter(k => !isNaN(+k))
                  .map(role => (
                    <Select.Option key={role} value={role}>{t(`TYPES.ROLES.PORTAL.${UserPortalRolesEnum[role as number]}`)}</Select.Option>
                  ))}
              </Select>
            </Form.Item >
            <Form.Item
              label="Email de l'utilisateur"
              name="email"
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              label="Télephone de l'utilisateur"
              name="phone"
            >
              <Input></Input>
            </Form.Item>
            {
              admin && (
                <Form.Item>
                  <div>
                    <Button htmlType="submit" type="primary" className="bg-alc-blue ">
                      Modifier l'utilisateur
                    </Button>
                    <Button onClick={deleteUser} className="bg-alc-danger text-white ml-5">
                      Supprimer l'utilisateur
                    </Button>
                  </div>
                </Form.Item>
              )
            }
          </Form >
        )}
      </Section>
    </PageCanvas>
  );
};

export default UserPortalDetailsPage;
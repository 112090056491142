import { Outlet } from 'react-router-dom';
import MemberNavBar from './MemberNavbar';

const MemberLayout = () => {
    return (
        <>
            <div className="pageLayout overflow-auto">
                <div className='pageLayout-header'>
                    <div>
                        <MemberNavBar></MemberNavBar>
                    </div>
                </div>

                <div className='pageLayout-page'>
                    <div className='pageLayout-content'>
                        <Outlet></Outlet>
                    </div>
                </div>
            </div>
        </>)
}
export default MemberLayout;
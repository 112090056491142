import { InventoryStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces"
import { Inventory } from "@alcome-rep/alcome-types/dist/models"
import { Tag } from "antd"
import { useTranslation } from "react-i18next"

type PropsType = {
  inventory: Inventory
}

const InventoryStatus = ({ inventory }: PropsType) => {
  const { t } = useTranslation();

  return <Tag className="space-x-2" color={t(`UI.TAG.InventoryStatusEnum.${InventoryStatusEnum[inventory.status]}`)}>
    {'' + t(`TYPES.INVENTORY.InventoryStatusEnum.${InventoryStatusEnum[inventory.status]}`)}
  </Tag>
}

export default InventoryStatus
import { AcquisitionItemOrderStatusEnum, AcquisitionOrderItemType, AcquisitionOrderStatusEnum, DispositifCategoryEnum, DispositifTypeEnum, IShippingAddress, StructureQuotasType } from "@alcome-rep/alcome-types/dist/interfaces";
import { ShippingRegionEnum, SupplierOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces/supplier";
import { User } from "@alcome-rep/alcome-types/dist/models";
import { Alert, Button, Form, Input, Select, Table, TableColumnType, message } from "antd";
import { useForm } from "antd/es/form/Form";
import confirm from "antd/es/modal/confirm";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { selectUser } from "../../../../Auth/state/auth.slice";
import { selectCurrentStructure } from "../../../../Structure/state/structure-portal.slice";
import supportOrderApi from "../../../../SupportOrder/services/supportOrder.api";
import AmountValue from "../../../../common/components/Fields/AmountValue";
import Section from "../../../../common/components/Panels/Section";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks";
import useFormRules from "../../../../common/hooks/useFormRules";
import { ROUTES } from "../../../../common/router";
import msg from "../../../../common/services/toast";
import { getRoute } from "../../../../common/tools/router-tools";
import acquisitionOrderPortalApi from "../../../services/portal-acquisitionOrder.api";
import { selectCurrentAdminAcquisitionOrder, setCurrentAdminAcquisitionOrderAction, updateAdminAcquisitionOrderStatusActions } from "../../../state/acquisitionOrder-admin.slice";
import { selectCurrentPortalAcquisitionOrder, setCurrentPortalAcquisitionOrderAction } from "../../../state/acquisitionOrder-portal.slice";
import AdminAcquisitionOrderItemStatusEdit from "../../admin/AdminAcquisitionOrderItemStatusEdit/AdminAcquisitionOrderItemStatusEdit";
import AcquisitionOrderItemStatus from "../AcquisitionOrderStatus/AcquisitionOrderItemStatus";
import acquisitionOrderAdminApi from "../../../services/admin-acquisitionOrder.api";
import adminSupplierOrderApi from "../../../../SupplierOrder/services/admin-supplierOrder.api";

const AcquisitionOrderResume = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser);
  const isAdmin = User.IsUserAdmin(user);
  const acquisitionOrder = useAppSelector(User.IsUserAdmin(user) ? selectCurrentAdminAcquisitionOrder : selectCurrentPortalAcquisitionOrder);
  const structure = useAppSelector(selectCurrentStructure);
  const [quotas, setQuotas] = useState<StructureQuotasType>();
  const [isOverQuotas, setIsOverQuotas] = useState<boolean>(false);
  const [items, setItems] = useState<AcquisitionOrderItemType[]>(acquisitionOrder ? acquisitionOrder.items ?? [] : []);

  const [shippingAddressSelected, setShippingAddressSelected] = useState<IShippingAddress>()

  const { t } = useTranslation();
  const [form] = useForm();
  const { required } = useFormRules();
  const navigate = useNavigate();

  const editable = !acquisitionOrder || !acquisitionOrder.id || isAdmin;

  let comment: null | string = null;

  useEffect(() => {
    if (acquisitionOrder)
      setItems(acquisitionOrder.items ?? [])
  }, [acquisitionOrder])


  useEffect(() => {
    if (User.IsUserPortal(user) && structure && acquisitionOrder && !acquisitionOrder.id) {
      // const { firstName, lastName, phone, email } = user;
      // const contact = acquisitionOrder.contact || { structure: structure.name, firstName, lastName, phone, email }
      const contact = acquisitionOrder.contact || { structure: structure.name }

      // const { street, street2, city, zip, country, label, region = ShippingRegionEnum.metropole } = structure.address
      // const shippingAddress = acquisitionOrder.createdAt ? acquisitionOrder.shippingAddress : shippingAddressSelected || Object.assign({ street, street2, city, zip, country, label, region })
      const shippingAddress = acquisitionOrder.createdAt ? acquisitionOrder.shippingAddress : shippingAddressSelected

      const total = items.filter(item => !!item.price).reduce((total, item) => total + item.price, 0);
      dispatch(User.IsUserAdmin(user)
        ? setCurrentAdminAcquisitionOrderAction({ ...acquisitionOrder, total, contact, shippingAddress })
        : setCurrentPortalAcquisitionOrderAction({ ...acquisitionOrder, total, contact, shippingAddress })
      )
      form.setFieldsValue({ ...acquisitionOrder, total, contact, shippingAddress })
    } else {
      form.setFieldsValue({ ...acquisitionOrder })
    }
  },// eslint-disable-next-line 
    [form, shippingAddressSelected])

  // fecthing support quotas 
  useEffect(() => {
    if (!structure)
      return
    supportOrderApi.getQuotas(structure.id!)
      .then(r => setQuotas(r))
  }, [structure])

  // refresh quotas
  useEffect(() => {
    if (!quotas)
      return
    setIsOverQuotas(
      items.reduce((total, item) => total = total + (item.category === DispositifCategoryEnum.ashtray ? item.quantity : 0), 0) > quotas?.available.ashtrays
      ||
      items.reduce((total, item) => total = total + (item.category === DispositifCategoryEnum.extinguisher ? item.quantity : 0), 0) > quotas?.available.extinguishers
    )
  }, [items, quotas])

  const setFormWithShippingAddress = (addressName: string) => {
    if (!structure)
      return;
    const shippingAddress = structure.shippingAddress.find(sA => sA.label === addressName)
    if (shippingAddress) {
      setShippingAddressSelected(shippingAddress)
    }
    return;
  }


  const submit = async (data: any) => {
    delete data.selectShipping
    try {
      const newAcquisition = await acquisitionOrderPortalApi.createAcquisitionOrder(data)
      navigate(getRoute(ROUTES.PORTAL_ACQUISITION_ORDER_ROUTES.acquisitionOrderDetails, { acquisitionOrderId: (await newAcquisition).id }))
    } catch (err) {
      msg.error('Erreur dans la création de la demande')
    }
  }

  const adminUpdateAcquisitionOrder = (data: any) => {
    if (acquisitionOrder)
      acquisitionOrderAdminApi.updateAcquisition(acquisitionOrder.id as string, data)
        .then(res => res && message.success('Demande mise à jours'))
        .catch(() => msg.error('Erreur dans la création de la demande'))
  }

  const displayDrMessage = (count: number) => {
    if (count > 0) {
      if (count === 1) {
        message.info('Une commande nécessite une validation manuelle.', 5)
      } else {
        message.info('Plusieurs commandes nécessites une validation manuelle.', 5)
      }
    } else {
      message.info('La demande a bien été validé')
    }
  }

  const validateOrder = () => {
    confirm({
      cancelText: 'Annuler',
      closable: true,
      okCancel: true,
      title: 'Confirmation',
      content: "Souhaitez vous valider la demande et générer les bons de commandes aux différents fournisseurs ?",
      onOk: () => {
        // dispatch(updateAdminAcquisitionOrderStatusActions({ id: acquisitionOrder?.id, data: { status: AcquisitionOrderStatusEnum.acquisition_order_completed } }))
        if (acquisitionOrder) {
          acquisitionOrderAdminApi.updateOrderStatus(acquisitionOrder.id as string, { status: AcquisitionOrderStatusEnum.acquisition_order_completed })
            .then(res => {
              adminSupplierOrderApi.findAllSupplierOrders({ acquisitionOrderId: res.id, status: SupplierOrderStatusEnum.pending })
                .then(res => {
                  displayDrMessage(res.count)
                })
              dispatch(setCurrentAdminAcquisitionOrderAction(res))
            })
        }

      },
      onCancel: () => { }
    })
  }

  const refuseOrder = () => {
    confirm({
      cancelText: 'Annuler',
      closable: true,
      okCancel: true,
      title: 'Souhaitez vous refuser la demande ?',
      // content: "Souhaitez vous refuser la demande ?",
      content: (<>Ajouter un commentaire <textarea className='block w-full p-2' onChange={e => comment = (e.target.value)} ></textarea></>),
      onOk: () => {
        dispatch(updateAdminAcquisitionOrderStatusActions({ id: acquisitionOrder?.id, data: { status: AcquisitionOrderStatusEnum.acquisition_order_refused, acquisitionOrderComment: comment } }))
        comment = null
      },
      onCancel: () => { }
    })
  }

  const columns: TableColumnType<AcquisitionOrderItemType>[] = [
    ...User.IsUserAdmin(user) && acquisitionOrder?.status === AcquisitionOrderStatusEnum.acquisition_order_pending
      ? [{
        title: "Actions",
        align: "start",
        dataIndex: 'action',
        render: (_v: any, _item: any, index: number) => <AdminAcquisitionOrderItemStatusEdit acquisitionOrder={acquisitionOrder} itemIndex={index} />
      } as TableColumnType<AcquisitionOrderItemType>]
      : [],
    {
      title: 'Hotpost',
      dataIndex: ['hotspot', 'name'],
      align: 'center',
      render: (name: string) => <span>{name ?? null}</span>,
    },
    {
      title: 'Catégorie',
      dataIndex: ['category'],
      render: (v: DispositifCategoryEnum) => v && <span>{t('TYPES.INVENTORY.DispositifCategoryEnum.' + v)}</span>,
    },
    {
      title: 'Type',
      dataIndex: ['type'],
      render: (v: DispositifTypeEnum) => v && <span>{t('TYPES.INVENTORY.DispositifTypeEnum.' + v)}</span>,
    },

    {
      title: 'Quantité',
      dataIndex: ['quantity'],
      align: "center",
    },
    {
      title: 'Financement',
      dataIndex: ['isSupport'],
      align: "left",
      render: (isSupport: boolean, item: AcquisitionOrderItemType) => isSupport ? "Soutien financier" : "Acquisition"
    },
    {
      title: 'Produit',
      align: "left",
      dataIndex: ['product', 'name'],
      render: (v, record) => record.isSupport ? <span className="font-semibold">N/A</span> : (!!v ? v : <span className="text-alc-danger">Produit manquant</span>)
    },


    // ...acquisitionOrder && acquisitionOrder?.id && isAdmin
    //   ? [
    //     {
    //       title: 'Total',
    //       dataIndex: ['price'],
    //       align: "right" as AlignType,
    //       key: Math.random(),
    //       render: (v: number) => <AmountValue amount={v} precision={2} />
    //     },
    //     {
    //       title: 'Frais de livraison',
    //       dataIndex: ['shippingFees'],
    //       key: Math.random(),
    //       align: "right" as AlignType,
    //       render: (v: number) => <AmountValue amount={v} precision={2} />
    //     }
    //   ] : [],
    ...acquisitionOrder && acquisitionOrder?.id ? [
      {
        title: 'Statut',
        align: "end",
        dataIndex: ['status'],
        key: Math.random(),
        render: (v: AcquisitionItemOrderStatusEnum) => <AcquisitionOrderItemStatus status={v} />
      } as TableColumnType<AcquisitionOrderItemType>]
      : []

  ];

  const hasInvalidItems = () => {
    return items.filter(item => !item.isSupport).find(item => !item.product) !== undefined
  }

  const itemsToProceed = (items ?? []).filter(item => item.status === AcquisitionItemOrderStatusEnum.pending).length;
  const allItemsRefused = (items ?? []).filter(i => i.status === AcquisitionItemOrderStatusEnum.refused).length === items.length;
  const disableValidationCond = allItemsRefused ? true : itemsToProceed ? true : false;

  const canEdit = !acquisitionOrder?.id && User.IsUserPortal(user);

  const getFooter = () => {

    if (User.IsUserAdmin(user)) {
      if (acquisitionOrder?.status === AcquisitionOrderStatusEnum.acquisition_order_pending)
        return <div className="flex justify-between">
          <span className="font-semibold">{itemsToProceed > 0 ? `Il reste ${itemsToProceed} articles à traiter` : 'Tous les articles ont été traités'}</span>
          <div className="space-x-4">
            <Button
              // disabled={itemsToProceed > 0 && items.filter(i => i.status === AcquisitionItemOrderStatusEnum.refused).length === items.length}
              disabled={disableValidationCond}
              onClick={validateOrder}
              type="primary"
            >Valider la demande</Button>
            {(items ?? []).find(i => i.status === AcquisitionItemOrderStatusEnum.refused) &&
              <Button
                className="!text-alc-danger"
                onClick={refuseOrder}
                type="link"
              >Refuser la demande</Button>
            }
          </div>
        </div>
      else if (acquisitionOrder && acquisitionOrder.status >= AcquisitionOrderStatusEnum.acquisition_order_completed && acquisitionOrder.items.find((item) => !item.isSupport))
        return <div className="flex justify-between">
          <span className="font-semibold">Des commandes fournisseurs ont été générées</span>
          <Link to={getRoute(ROUTES.ADMIN_SUPPLIER_ORDER_ROUTES.supplierOrderList, {}, { supplierOrderNumber: '/' + acquisitionOrder.acquisitionOrderNumber.replace(/^C-/, 'CSUP-') + '/' })}>Voir les commandes fournisseurs</Link>
        </div>
    }

    return null
  }

  // if (!acquisitionOrder || !acquisitionOrder.id) {
  //   return <LoadingScreen />
  // }

  const validateAllItems = () => {
    if (acquisitionOrder)
      acquisitionOrderAdminApi.updateAllItemsStatus(acquisitionOrder.id as string)
        .then(res => setItems(res.items))
  }

  return <>
    {canEdit && <div className="flex flex-col space-y-2">
      {canEdit && isOverQuotas && <Alert banner message="Votre commande ne respecte pas les quotas disponibles. Merci de vérifier votre saisie." type="error" />}
      {canEdit && hasInvalidItems() && <Alert banner message="Certaines lignes n'ont pas de produit associé. Merci de renseigner tous les produits" type="error" />}
    </div>}

    <Section
      mode='transparent'
      title="Récapitulatif de la demande"
    >

      <Table<AcquisitionOrderItemType>
        dataSource={items}
        columns={columns}
        className="extinguisher-table"
        rowKey={'id'}
        size='small'
        locale={
          { emptyText: "Aucun article." }
        }
        pagination={false}
        footer={getFooter}
        summary={(_pageData) => {
          const qtyIndex = columns.findIndex(c => c.dataIndex?.toString() === "quantity")
          const totalIndex = columns.findIndex(c => c.dataIndex?.toString() === "price")
          return (
            <>
              <Table.Summary.Row key={Math.random()}>
                {columns.filter((_c, i) => i < qtyIndex - 1).map((_c, i) => <Table.Summary.Cell key={Math.random()} index={i} />)}
                <Table.Summary.Cell key={Math.random()} index={qtyIndex - 1} className="font-semibold text-right">Soutiens</Table.Summary.Cell>
                <Table.Summary.Cell key={Math.random()} index={qtyIndex} className="font-semibold text-center">{items.filter(i => i.isSupport).reduce((total, item) => total + item.quantity, 0)}</Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row key={Math.random()}>
                {columns.filter((_c, i) => i < qtyIndex - 1).map((_c, i) => <Table.Summary.Cell key={Math.random()} index={i} />)}
                <Table.Summary.Cell key={Math.random()} index={qtyIndex - 1} className="font-semibold text-right">Acquisitions</Table.Summary.Cell>
                <Table.Summary.Cell key={Math.random()} index={qtyIndex} className="font-semibold text-center">{items.filter(i => !i.isSupport).reduce((total, item) => total + item.quantity, 0)}</Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row key={Math.random()}>
                {columns.filter((_c, i) => i < qtyIndex - 1).map((_c, i) => <Table.Summary.Cell key={Math.random()} index={i} />)}

                <Table.Summary.Cell key={Math.random()} index={qtyIndex - 1} className="font-semibold uppercase text-right">Total</Table.Summary.Cell>
                <Table.Summary.Cell key={Math.random()} index={qtyIndex} className="font-semibold text-center">
                  {(items || []).reduce((total, item) => total + item.quantity, 0)}
                </Table.Summary.Cell>
                {isAdmin && items.find(item => !item.isSupport) && (
                  <>
                    {columns.filter((_c, i) => i < totalIndex - qtyIndex - 1).map((_c, i) => <Table.Summary.Cell key={Math.random()} index={i} />)}
                    <Table.Summary.Cell key={Math.random()} index={qtyIndex} className="font-semibold text-left" align="right">
                      <AmountValue amount={acquisitionOrder!.total + acquisitionOrder!.shippingFees!} precision={2} />
                    </Table.Summary.Cell>
                    <Table.Summary.Cell key={Math.random()} index={qtyIndex} className="font-semibold text-left" align="right">
                      <AmountValue amount={acquisitionOrder?.shippingFees} precision={2} />
                    </Table.Summary.Cell>
                  </>
                )}

              </Table.Summary.Row>
              {isAdmin && acquisitionOrder?.status === AcquisitionOrderStatusEnum.acquisition_order_pending && (
                <Table.Summary.Row key={Math.random()}>
                  <Table.Summary.Cell key={Math.random()} index={0}>
                    <Button type="link" onClick={validateAllItems}>Valider tous les produits</Button>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
            </>
          );
        }}
      />

    </Section>


    <Form
      onChange={() => form.resetFields(['selectShipping'])}
      layout="vertical"
      form={form}
      onFinish={submit}
      // initialValues={acquisitionOrder!}
      initialValues={acquisitionOrder!}
    >
      <Form.Item name="items" hidden />

      {items.find(item => !item.isSupport) !== undefined && (
        <>
          <Section
            title="Contact de livraison"
          >
            <div className="grid grid-cols-2 w-full lg:w-8/12 gap-x-4">
              <Form.Item
                rules={[required]}
                label={'Structure / organisme'}
                name={['contact', 'structure']}
                className="col-span-2"
              >
                <Input readOnly={true}></Input>
              </Form.Item>

              <Form.Item
                rules={[required]}
                label={'Prénom'}
                name={['contact', 'firstName']}
              >
                <Input readOnly={!editable}></Input>
              </Form.Item>

              <Form.Item
                rules={[required]}
                label={'Nom'}
                name={['contact', 'lastName']}
              >
                <Input readOnly={!editable}></Input>
              </Form.Item>


              <Form.Item
                rules={[required]}
                label={'Email'}
                name={['contact', 'email']}
              >
                <Input readOnly={!editable}></Input>
              </Form.Item>

              <Form.Item
                rules={[required]}
                label={'Téléphone'}
                name={['contact', 'phone']}
              >
                <Input readOnly={!editable}></Input>
              </Form.Item>
            </div>
          </Section >
          <Section
            title="Adresse de livraison"
          >
            <div className="grid grid-cols-2 w-full lg:w-8/12 gap-x-4">
              {structure && structure.shippingAddress && acquisitionOrder && !acquisitionOrder.createdAt && (
                <Form.Item
                  label="Charger une adresse de livraison"
                  name="selectShipping"
                >
                  <Select
                    onChange={v => setFormWithShippingAddress(v)}
                    options={structure!.shippingAddress.map(r => ({ label: r.label, value: r.label }))}
                  />
                </Form.Item>
              )}
              <>
                <Form.Item
                  label={t('UI.STRUCTURE_DETAILS.fields.shipping.service')}
                  name={['shippingAddress', 'service']}
                  className="col-span-2"
                >
                  <Input readOnly={!editable}></Input>
                </Form.Item>
                <Form.Item
                  rules={[required]}
                  label={t('UI.STRUCTURE_DETAILS.fields.street.label')}
                  name={['shippingAddress', 'street']}
                  className="col-span-2"
                >
                  <Input readOnly={!editable}></Input>
                </Form.Item>
                <Form.Item
                  rules={[required]}
                  label={t('UI.STRUCTURE_DETAILS.fields.zip.label')}
                  name={['shippingAddress', 'zip']}
                >
                  <Input readOnly={!editable}></Input>
                </Form.Item>
                <Form.Item
                  rules={[required]}
                  label={t('UI.STRUCTURE_DETAILS.fields.city.label')}
                  name={['shippingAddress', 'city']}
                >
                  <Input readOnly={!editable}></Input>
                </Form.Item>
              </>
              <Form.Item
                rules={[required]}
                label={"Région"}
                name={['shippingAddress', 'region']}
              >
                <Select
                  className="w-32"
                  options={Object.keys(ShippingRegionEnum).map(r => ({ label: t('TYPES.PRODUCT.ShippingRegionEnum.' + r), value: r }))}
                />
              </Form.Item>


            </div>
          </Section >
        </>
      )
      }

      {User.IsUserPortal(user) && !acquisitionOrder?.id &&
        <Button type="primary" disabled={items.length === 0 || hasInvalidItems()} htmlType="submit">Envoyer la demande</Button>
      }
      {isAdmin &&
        <Button type="primary" onClick={() => adminUpdateAcquisitionOrder(form.getFieldsValue())}>Modifier la demande</Button>
      }
    </Form >
  </>
}

export default AcquisitionOrderResume
import { Models } from "@alcome-rep/alcome-types";
import { UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { refreshTokenAction, selectHasRefreshUser, selectIsRefreshing, selectUser } from "../../Auth/state/auth.slice";
import { getContractAction, selectCurrentContract } from "../../Contract/state/contract-portal.slice";
import { getInventoryAction, selectCurrentInventory } from "../../Inventory/state/inventory-portal.slice";
import { getMyStructureAction, selectCurrentStructure } from "../../Structure/state/structure-portal.slice";
import LoadingScreen from "../../common/components/LoadingScreen";
import { useAppDispatch, useAppSelector } from "../../common/hooks";
import { ROUTES } from "../../common/router";

const UserPortalGuard = ({ children }: any) => {
  const user = useAppSelector(selectUser);
  const structure = useAppSelector(selectCurrentStructure);
  const contract = useAppSelector(selectCurrentContract);
  const inventory = useAppSelector(selectCurrentInventory);

  const isRefreshing = useAppSelector(selectIsRefreshing);
  const hasRefreshUser = useAppSelector(selectHasRefreshUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!hasRefreshUser && !isRefreshing) {
      dispatch(refreshTokenAction(UserTypeEnum.portal))
    }
  }, [dispatch, isRefreshing, hasRefreshUser])

  useEffect(() => {
    if (!user || !hasRefreshUser)
      return;

    dispatch(getMyStructureAction())
    dispatch(getContractAction())
    dispatch(getInventoryAction())
  }, [user, hasRefreshUser, dispatch])

  if (!hasRefreshUser)
    return <LoadingScreen />
  else if (!Models.User.IsUserPortal(user)) {
    return <Navigate to={ROUTES.LOGIN}></Navigate>
  }
  else if (!structure || !inventory || !contract) {
    return <LoadingScreen />
  }

  if (children)
    return <>{children}</>

  return <Outlet />
}

export default UserPortalGuard;
import { IStructure } from "@alcome-rep/alcome-types/dist/interfaces";
import ApiService from "../../common/services/apiService";
import { Models } from "@alcome-rep/alcome-types";

const STRUCTURE_PORTAL_PATH_API = 'portal/structure';

class StructurePortalApi extends ApiService<IStructure> {
  constructor() {
    super(STRUCTURE_PORTAL_PATH_API)
  }

  /**
   * @description Portal User get his structure
   * @returns StructureDoc
   */
  getMyStructure = (): Promise<Models.BaseCommune> => {
    return this.custom<IStructure>('get', '')
      .then(r => new Models.BaseCommune(r))
  }

  /**
   * @description Portal User update his structure
   * @param data 
   * @returns Updated structure
   */
  updateStructureInfos = (data: any) => {
    return this.custom<IStructure>('put', 'infos', undefined, data)
      .then(s => new Models.BaseCommune(s))
  }

  /**
   * @description Portal User update the communes of his structure
   * @param data 
   * @returns Updated Structure (updated communes)
   */
  updateStructureCommunes = (data: any) => {
    return this.custom<IStructure>('put', 'communes', undefined, data)
      .then(s => new Models.BaseCommune(s))
  }

}

const structurePortalApi = new StructurePortalApi();
export default structurePortalApi;
import { Models } from "@alcome-rep/alcome-types";
import { IAnnualReview, IInventory } from "@alcome-rep/alcome-types/dist/interfaces";
import { AnnualReview } from "@alcome-rep/alcome-types/dist/models";
import { message } from "antd";
import { UploadDocumentPayload } from "../../Inventory/state/fileDoc.types";
import ApiService, { getTrackProgressAreas } from "../../common/services/apiService";

const ANNUALREVIEW_PORTAL_PATH_API = 'portal/annualReview';

export const progressKeys = {
  uploadPortal: getTrackProgressAreas(ANNUALREVIEW_PORTAL_PATH_API).custom('POST'),
}

class AnnualReviewPortalApi extends ApiService<AnnualReview> {
  constructor() {
    super(ANNUALREVIEW_PORTAL_PATH_API)
  }

  /**
  * @description find all annual reviews
  * @param query
  * @returns annual reviews list
  */
  findAll() {
    return this.find({ query: {}, page: 1, pageSize: -1 })
  }

  /**
   * @description get an annual review
   * @param annualReviewId 
   * @returns AnnualReview
   */
  getAnnualReview = (annualReviewId: string) => {
    return this.get(`${annualReviewId}/bilan`)
  }

  /**
   * @description update an annual review
   * @param annualReviewId 
   * @param data 
   * @returns Updated annual review
   */
  updateAnnualReview = (annualReviewId: string, data: any) => {
    return this.update(annualReviewId, data)
  }

  // /**
  //  * @description update an annual review
  //  * @param annualReviewId 
  //  * @param data 
  //  * @returns Updated annual review
  //  */
  // updateAnnualReviewLatestInfos = ({ annualReviewId, data }: { annualReviewId: string, data: any }) => {
  //   return this.custom<AnnualReview>('put', `latest/infos/${annualReviewId}`, undefined, data)
  //     .then(r => {
  //       message.success('Enregistré')
  //       return r;
  //     })
  //     .catch(() => message.error('Problème lors de l\'enregistrement'))
  // }

  /**
   * @description create an annual review for a specific year
   * @param data 
   * @returns Created annual review
   */
  createAnnualReview = (structureId: string, year: number) => {
    return this.custom<AnnualReview>("post", structureId, undefined, { year })
      .then(r => new AnnualReview(r))
  }

  /**
   * Indicate if user used Communication kit
   * @param annualReviewId  
   * @returns 
   */
  updateCommunicationKitUsed = ({ annualReviewId, data }: { annualReviewId: string, data: any }) => {
    return this.custom('put', `communicationKit/${annualReviewId}`, undefined, data)
      .then(res => {
        message.success('Informations mises à jour')
        return res
      })
  }

  // ====== ANNUALREVIEW FILE ======
  uploadAnnualReviewFile = (data: UploadDocumentPayload<AnnualReview>) => {
    return this.uploadFileDoc<AnnualReview>(data.propertyName, data.file, data.id)
      .then(r => new Models.AnnualReview(r))
  }

  downloadAnnualReviewFile = (propertyName: keyof Models.AnnualReview, fileName: string) => {
    return this.downloadFileDoc(propertyName, undefined, fileName)
  }

  getAnnualReviewFile = (propertyName: keyof AnnualReview, annualReviewId: string) => {
    return this.getFileDoc(propertyName, annualReviewId)
  }

  deleteAnnualReviewFile = (propertyName: keyof Models.AnnualReview, entityId: string) => {
    return this.deleteFileDoc(propertyName, entityId)
  }

  uploadArrayFile = ({ annualReviewId, data, formData }: any) => {
    return this.custom('put', `uploadArrayFile/${annualReviewId}/${data.propertyName}/${data.subPropertyName}/${data.index}/${data.timestamp}`, undefined, formData)
      .then(res => {
        message.success(' Fichiers envoyés !')
        return res;
      }).catch(res => {
        return message.error('Erreur lors de l\'envoi du fichier')
      })
  }

  downloadArrayFile = (annualReviewId: string, propertyName: string, subPropertyName: string, fileName: string) => {
    return this.downloadArrayFileDoc(annualReviewId, propertyName, subPropertyName, fileName, fileName)
  }

  deleteArrayFile = (annualReviewId: string, propertyName: string, subPropertyName: string, index: number): Promise<Models.Inventory> => {
    return this.custom<IInventory>('delete', `${annualReviewId}/deleteArrayFile/${propertyName}/${subPropertyName}/${index}/`)
      .then((r: IInventory) => new Models.Inventory(r))
  }

  userValidateStatus = ({ annualReviewId }: any) => {
    return this.custom<IAnnualReview>('put', `${annualReviewId}/validateStatus`)
      .then(res => {
        if (res.status === 200) {
          message.success('Bilan envoyé')
        }
        return res;
      })
  }

  userAcceptStatement = ({ annualReviewId }: any) => {
    return this.custom<IAnnualReview>('put', `${annualReviewId}/statementAccepted`)
      .then(res => {
        if (res.status === 200) {
          message.success('Décompte liquidatif accepté')
        }
        return res;
      })
  }

  userRefuseStatement = ({ annualReviewId, statementComment }: any) => {
    return this.custom<IAnnualReview>('put', `${annualReviewId}/statementRefused`, undefined, { statementComment })
      .then(res => {
        if (res.status === 200) {
          message.success('Décompte liquidatif refusé')
        }
        return res;
      })
  }

  sendOrder = ({ annualReviewId }: { annualReviewId: string }) => {
    return this.custom<IAnnualReview>('put', `${annualReviewId}/sendOrder`)
    // .then(r => new Models.AnnualReview(r))
  }

}

const annualReviewPortalApi = new AnnualReviewPortalApi();
export default annualReviewPortalApi;
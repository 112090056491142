import { ReactNode } from "react"

type PropTypes = {
  title?: string | ReactNode,
  content: string | ReactNode
  className?: string
}
const ActionPanel = ({ title, content, className }: PropTypes) => {
  return <div className={`bg-white p-2 rounded-lg flex justify-between  items-center ${className}`}>
    {title && (<div className="text-alc-blue">{title}</div>)}
    {content && (<div className="">{content}</div>)}
  </div>
}

export default ActionPanel
import { Models, appFeatures, setFeatureRoles } from '@alcome-rep/alcome-types';
import { IStructure } from '@alcome-rep/alcome-types/dist/interfaces';
import { IUser, IUserAdmin, IUserPortal, IUserSupplier, UserAdminRolesEnum, UserPortalRolesEnum, UserSupplierRolesEnum, UserTypeEnum } from '@alcome-rep/alcome-types/dist/interfaces/user';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space, Table } from 'antd';
import { MenuProps } from 'antd/lib';
import { FilterValue, SorterResult, TableCurrentDataSource, TablePaginationConfig } from 'antd/lib/table/interface';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { selectUserAdmin } from '../../../../Auth/state/auth.slice';
import { selectCurrentAdminStructure } from '../../../../Structure/state/structure-admin.slice';
import { defaultPagination } from '../../../../common/app-constants';
import { ExportModalOptions, ExportType } from '../../../../common/components/ExportExcel/ExportExcel';
import FeatureAvailable from '../../../../common/components/FeaturesAvailable/FeatureAvailable';
import FilterEntity from '../../../../common/components/Filters/FilterEntity';
import FilterInput from '../../../../common/components/Filters/FilterInput';
import FilterPanel from '../../../../common/components/Panels/FilterPanel';
import PageCanvas from '../../../../common/components/Panels/PageCanvas';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { closeDrawerAction, openDrawerAction } from '../../../../common/state/modal.slice';
import { getSearchParams } from '../../../../common/tools/router-tools';
import rolesSettingsApi from '../../../services/roleSettings/roles-settings.api';
import userAdminApi from '../../../services/user-admin.api';
import AdminCreateAccount from '../AdminCreateAccount/AdminCreateAccountPage';
import RoleSettings from '../UserRolesSettings/UserRolesSettingsPage';
import { userListColumns } from './UserListComponent.constant';

const UserListPage = () => {
  const [data, setData] = useState<Models.User[]>([]);
  const [count, setCount] = useState<number>(1);
  const [loading, setloading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<Date>();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const user = useAppSelector(selectUserAdmin);
  const structure = useAppSelector(selectCurrentAdminStructure) as IStructure;

  let [searchParams, setSearchParams] = useSearchParams();

  const queryParams = useMemo(() => {
    const { page = 1, limit = defaultPagination.pageSize, ...query } = getSearchParams(searchParams);
    return { page, limit, query }
  }, [searchParams])

  const { userType } = useParams();

  useEffect(() => {
    setloading(true)
    userAdminApi.findAllUsers({ ...queryParams.query, userType }, Number(queryParams.page), Number(queryParams.limit))
      .then(res => ({
        count: res.count,
        rows: res.rows.map(row => new Models.User(row as Models.User))
      }))
      .then(res => {
        setData(res.rows)
        setCount(res.count)
        setloading(false)
      })
      .catch(() => {
        setData([])
        setCount(0)
        setloading(false)
      })
  }, [setData, setCount, setloading, queryParams, userType, refresh])

  const roleOptions = useMemo(() => {
    if (userType === undefined)
      return;
    let options: any[] = [];
    switch (userType as UserTypeEnum) {
      case UserTypeEnum.admin:
        options = Object.keys(UserAdminRolesEnum)
          .filter((k): k is keyof typeof UserAdminRolesEnum => isNaN(+k))
          .map(k => ({ value: String(UserAdminRolesEnum[k]), label: t(`TYPES.ROLES.${userType.toUpperCase()}.` + k) }))
        break;
      case UserTypeEnum.portal:
        options = Object.keys(UserPortalRolesEnum)
          .filter((k): k is keyof typeof UserPortalRolesEnum => isNaN(+k))
          .map(k => ({ value: String(UserPortalRolesEnum[k]), label: t(`TYPES.ROLES.${userType.toUpperCase()}.` + k) }))
        break;
      case UserTypeEnum.supplier:
        options = Object.keys(UserSupplierRolesEnum)
          .filter((k): k is keyof typeof UserSupplierRolesEnum => isNaN(+k))
          .map(k => ({ value: String(UserSupplierRolesEnum[k]), label: t(`TYPES.ROLES.${userType.toUpperCase()}.` + k) }))
        break;
    }
    return options;
  }, [userType, t])

  const getData = async (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IUser> | SorterResult<IUser>[],
    extra?: TableCurrentDataSource<IUser>
  ) => {
    const q = Object.keys(filters).reduce((acc, k) => {
      if (filters[k] !== null)
        acc[k] = filters[k];
      return acc;
    }, {} as any)

    setSearchParams({ page: pagination.current, limit: pagination.pageSize, ...q })
  }

  const exportExcel = async (exportType: ExportType) => {
    let dataToExport: any[];

    switch (exportType) {
      case 'all':
        dataToExport = await userAdminApi.findAllUsers({ userType }, 1, -1)
          .then(r => r.rows)
        break;
      case 'page':
        dataToExport = data as any[]
        break;
      case 'query':
        dataToExport = await userAdminApi.findAllUsers(queryParams, 1, -1)
          .then(r => r.rows)
        break;
    }

    switch (userType as UserTypeEnum) {
      case UserTypeEnum.admin:
        dataToExport = dataToExport.map((obj: IUserAdmin) => ({
          role: t(`types.roles.${UserAdminRolesEnum[obj.role]}`),
          nom: obj.lastName,
          prenom: obj.firstName,
          email: obj.email,
        }) as any)
        break;
      case UserTypeEnum.portal:
        dataToExport = dataToExport.map(obj => ({
          role: t(`types.roles.${UserPortalRolesEnum[obj.role]}`),
          date_creation_compte: (obj as IUserPortal).cgv && format(new Date((obj as IUserPortal).cgv), "d MMMM yyyy", { locale: fr }),
          structure: (obj as IUserPortal).structureId && obj.structure?.name,
          nom: obj.lastName,
          prenom: obj.firstName,
          email: obj.email,
          telephone: (obj as IUserPortal).phone,
          fonction: (obj as IUserPortal).position
        }) as any)
        break;
      case UserTypeEnum.supplier:
        dataToExport = dataToExport.map((obj: IUserSupplier) => ({
          role: t(`types.roles.${UserSupplierRolesEnum[obj.role]}`),
          date_creation_compte: obj.createdAt && format(new Date(obj.createdAt), "d MMMM yyyy", { locale: fr }),
          fournisseur: new Models.UserSupplier(obj).supplierCompany?.name,
          nom: obj.lastName,
          prenom: obj.firstName,
          email: obj.email,
          telephone: obj.phone
        }) as any)
        break;
    }

    return dataToExport;
  }

  const refreshRoles = () => {
    rolesSettingsApi.getFeatureFile()
      .then(res => {
        setFeatureRoles(appFeatures, res as any)
        return res
      })
  };

  const refreshList = () => {
    dispatch(closeDrawerAction())
    setRefresh(new Date())
  }

  const dispatch = useAppDispatch()

  const items: MenuProps['items'] = [
    {
      label: 'Ajouter un utilisateur', key: '1', onClick: () => dispatch(openDrawerAction({
        options: { title: "Ajouter un utilisateur" },
        component: <AdminCreateAccount userType={userType as UserTypeEnum} onClose={refreshList}></AdminCreateAccount>,
      }))
    },
    {
      label: 'Paramétrage des rôles', key: '2', onClick: () => dispatch(openDrawerAction({
        options: { title: "Paramétrage des roles", size: 'large' },
        component: <RoleSettings onClose={refreshRoles}></RoleSettings>
      })),
    },
    {
      label: 'Exporter les données', key: '3', onClick: () => dispatch(openDrawerAction({
        options: { title: "Exporter les utilisateurs" },
        component: <ExportModalOptions fileName='user-reporting' exportList={exportExcel} />,
      }))
    },
  ];

  return (
    <>
      <FeatureAvailable userType={UserTypeEnum.admin} featureName='admin.user.list'>
        <PageCanvas
          title={<span>{t('TYPES.USER_TYPE.' + userType)} <small>({count ? count : 0})</small ></span >}
          actions={
            <Dropdown menu={{ items }} trigger={['click']}>
              <Button onClick={(e) => e.preventDefault()}><Space>Actions<DownOutlined /></Space></Button>
            </Dropdown>
          }
        >
          <FilterPanel>
            <FilterInput searchParamName='lastName' label='Nom' isRegex={true} />
            <FilterInput searchParamName='email' label='Email' isRegex={true} />
            <FilterInput searchParamName='phone' label='Téléphone' isRegex={true} />
            <FilterEntity
              className="m-0 col-span-1"
              filterName='Rôle'
              queryParamName='role'
              options={roleOptions}
            />
            <FilterInput searchParamName='structureName' label='Structure' isRegex={true} />
          </FilterPanel>

          <Table
            columns={userListColumns(t, navigate, dispatch, user, structure, userType as UserTypeEnum)}
            dataSource={data}
            loading={loading}
            onChange={getData}
            rowKey="id"
            scroll={{ scrollToFirstRowOnChange: true, x: 1400 }}
            pagination={
              {
                hideOnSinglePage: true,
                current: queryParams.page,
                pageSize: queryParams.limit,
                total: count,
              }
            }
          />
        </PageCanvas  >
      </FeatureAvailable >
    </>

  );
};

export default UserListPage;
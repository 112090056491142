import { StructureTypesEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AlcomeLogoARVB from '../../../assets/alcome_logo_AB_rvb.jpg';
import { useAppSelector } from '../../../common/hooks';
import { ROUTES } from '../../../common/router';
import { selectLocallySavedStructure } from '../../state/register.slice';

const StartStep: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const structureType = useAppSelector(selectLocallySavedStructure)?.structureType;

  const nextStepFnc = () => {
    let nextStepLink = "";
    switch (structureType) {
      case StructureTypesEnum.commune:
        nextStepLink = ROUTES.REGISTER_ROUTES.COMMUNE_STEP_INFOS;
        break;
      case StructureTypesEnum.groupement_communes:
        nextStepLink = ROUTES.REGISTER_ROUTES.GRP_COMMUNES_STEP_INFOS;
        break;
      default:
        break;
    }
    return navigate(nextStepLink)
  }

  return (
    <>
      <div className='mb-6'>
        <center>
          <img src={AlcomeLogoARVB}
            className='h-16' alt='Logo Alcome' />
        </center>
      </div>
      <h2>Votre inscription en 3 étapes</h2>
      <ul>
        <li>Créer votre compte</li>
        <li>Renseigner les informations administratives liées au contrat avec Alcome</li>
        <li>Télécharger une délibération type et prenez connaissance du contrat.
            <p className="mb-0"><b>Attention : la délibération ne doit pas faire mention de la notion de recyclage, ni d'un montant total de soutien annuel versé par Alcome, même estimé.</b> Seul le barème en €/habitant en fonction de la typologie de la commune sera accepté.</p>
            La version personnalisée du contrat sera à téléchargeable après le dépôt de votre délibération en conseil municipal / communautaire.
            <p>Vous aurez besoin du Code INSEE (commune) / SIREN (groupement) de votre collectivité. Il s’agit d’un code à 5 chiffres pour une commune, à 9 chiffres pour un EPCI. Vous pouvez le trouver facilement en le recherchant sur un moteur de recherche internet.</p>
          </li>
      </ul>
      <p>Vous disposerez de 90 jours après la signature du contrat pour complèter les annexes.</p>
      <hr />
      <div className='flex justify-between'>
        <Button onClick={_ => navigate(ROUTES.REGISTER)} className='btn-link' icon={<LeftOutlined />}>{t('COMMON.back')}</Button>
        <Button onClick={nextStepFnc} className='btn'>{t('COMMON.next')}</Button>
      </div>
    </>
  )
}

export default StartStep;
import { RcFile } from "antd/es/upload"
import { selectCurrentContract, setCurrentContractAction } from "../../../state/contract-portal.slice"
import UserFileField from "../../../../common/components/portal/UserFileField/UserFileField"
import { useAppDispatch, useAppSelector } from "../../../../common/hooks"
import contractPortalApi from "../../../services/contract-portal.api"
import FileField from "../../../../common/components/portal/FileField/FileField"
import { useTranslation } from "react-i18next"

const PortalDelegationFileUpload = ({ isInForm = false }) => {
  const contract = useAppSelector(selectCurrentContract)
  const dispatch = useAppDispatch()
  const { t } = useTranslation();

  const contractDelegationUpload = (file: RcFile) => {
    contractPortalApi.uploadDelegationFile(file)
      .then(contractUpdated => dispatch(setCurrentContractAction(contractUpdated)))
  }

  const contractDelegationDownload = () => {
    return contractPortalApi.downloadDelegationFile()
  }

  const contractDelegationView = () => {
    return contractPortalApi.getDelegationFile()
  }

  const contractDelegationDelete = () => {
    return contractPortalApi.deleteDelegationFile()
      .then(contractUpdated => dispatch(setCurrentContractAction(contractUpdated)))
  }

  if (!contract)
    return <></>;

  if (isInForm)
    return <FileField
      contract={contract}
      propertyName="delegationFileId"
      upload={contractDelegationUpload}
      download={contractDelegationDownload}
      remove={contractDelegationDelete}
      label={t("TYPES.FILEDOCUMENT.delegationFileId")}
      isRequired={false}
    />

  return <UserFileField
    fileDocument={contract.delegationFile}
    upload={contractDelegationUpload}
    download={contractDelegationView}
    propertyName='delegationFileId'
  ></UserFileField>
}

export default PortalDelegationFileUpload
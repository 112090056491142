import { RouteObject } from "react-router-dom";
import InventoryAnnexeStep from "../../Inventory/components/common/InventoryAnnexeStep";
import PreventionForm from "../../Inventory/components/common/PreventionForm";
import SalubriteForm from "../../Inventory/components/common/SalubriteForm";
import InventoryMapStep from "../../Inventory/components/common/inventoryMapStep";
import InventoryPage from "../../Inventory/components/common/InventoryPage";
import ContractPage from "../components/common/ContractPage/ContractPage";
import ContractLayout from "../layouts/ContractLayout";
import AmendmentPage from "../../Amendment/components/common/AmendmentPage";

const routePrefix = 'admin'
const routeDomain = 'structure/:structureId/contract'

export const adminContractRouteNames = {
  contractDetails: { path: `${routePrefix}/${routeDomain}`, title: "ROUTES.contract.details" },
  inventoryDetails: { path: `${routePrefix}/${routeDomain}/inventory`, title: "ROUTES.inventory.details" },
  amendmentsDetails: { path: `${routePrefix}/${routeDomain}/amendments`, title: "ROUTES.amendments.list" },
  INVENTORY: {
    PREVENTION: { path: `${routePrefix}/${routeDomain}/inventory`, title: "ROUTES.inventory.prevention" },
    SALUBRITE: { path: `${routePrefix}/${routeDomain}/inventory/salubrite`, title: "ROUTES.inventory.salubrite" },
    MAP: { path: `${routePrefix}/${routeDomain}/inventory/map`, title: "ROUTES.inventory.map" },
    FILES: { path: `${routePrefix}/${routeDomain}/inventory/annexes`, title: "ROUTES.inventory.files" },
  }
}

export const AdminContractRoutes: RouteObject[] = [
  {
    path: "",
    element: <ContractLayout />,
    children: [
      {
        path: adminContractRouteNames.contractDetails.path.replace(`${routePrefix}/`, ''),
        element: <ContractPage />
      },
      {
        path: adminContractRouteNames.inventoryDetails.path.replace(`${routePrefix}/`, ''),
        element: <InventoryPage />,
        children: [
          {
            path: '',
            element: <PreventionForm />
          },
          {
            path: 'salubrite',
            element: <SalubriteForm />
          },
          {
            path: 'map',
            element: <InventoryMapStep />
          },
          {
            path: 'annexes',
            element: <InventoryAnnexeStep />
          }
        ]
      },
      {
        path: adminContractRouteNames.amendmentsDetails.path.replace(`${routePrefix}/`, ''),
        element: <AmendmentPage />
      }
    ]
  },
]


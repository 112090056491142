import { RouteObject } from "react-router-dom";
import WelcomeLayout from "../common/layouts/WelcomeLayout/WelcomeLayout";
import LoginPage from "./components/Login/LoginPage";
import RegisterPage from "./components/Register/RegisterPage";
import StartStep from "./components/Register/StarterStep";
import CommuneForm from "./components/StepsCommune/CommuneForm";
import RegisterUserProfile from "./components/Register/RegisterUserProfile";
import RegisterCreate from "./components/Register/RegisterCreate";
import LoginGuard from "./guards/LoginGuard";
import GrpCommunesForm from "./components/StepsGrpCommunes/GrpCommunesForm";
import ForgottenPasswordForm from "./components/PasswordChange/forgottenPasswordForm";
import ResetPasswordForm from "./components/PasswordChange/resetPasswordForm";

export const buildAuthRoutes = () => {
  const registerRadice = 'register';
  const communeRadice = 'commune';
  const grpCommunesRadice = 'grp_communes';
  const memberRadice = 'member';
  return {
    STARTER_STEP: `/${registerRadice}/starter`,

    PROFILE_STEP: `/${registerRadice}/profile`,
    SUBMIT_STEP: `/${registerRadice}/submit`,

    COMMUNE_STEP_INFOS: `/${registerRadice}/${communeRadice}/infos`,
    GRP_COMMUNES_STEP_INFOS: `/${registerRadice}/${grpCommunesRadice}/infos`,
    MEMBER_STEP_INFOS: `/${registerRadice}/${memberRadice}/infos`,
  }
}

const AuthRoutes: RouteObject[] = [
  {
    path: "",
    element: <LoginGuard><WelcomeLayout></WelcomeLayout></LoginGuard>,
    children: [
      {
        path: 'connect', children: [
          {
            path: 'login',
            element: <LoginPage></LoginPage>
          },
          {
            path: 'ask-password',
            element: <ForgottenPasswordForm />
          },
          {
            path: 'change-password',
            element: <ResetPasswordForm />
          },
        ]
      },
      {
        path: 'register',
        children: [
          {
            path: '',
            element: <RegisterPage />
          },
          {
            path: 'starter',
            element: <StartStep />
          },
          {
            path: 'profile',
            element: <RegisterUserProfile />
          },
          {
            path: 'submit',
            element: <RegisterCreate />
          },
          {
            path: 'commune',
            children: [
              {
                path: 'infos',
                element: <CommuneForm />
              }
            ]
          },
          {
            path: 'grp_communes',
            children: [
              {
                path: 'infos',
                element: <GrpCommunesForm />
              }
            ]
          },
          {
            path: 'member',
            children: [
              {
                path: 'infos',
                element: <></>
              }
            ]
          }
        ]
      },
    ]
  }
]

export default AuthRoutes;
import { DispositifCategoryEnum, DispositifTypeEnum, isAshtrayType, isExtinguisherType } from "@alcome-rep/alcome-types/dist/interfaces";
import { IProduct, ProductStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces/product";
import { IAuth } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Image, Input, InputNumber, Radio, Select, Upload, UploadFile, UploadProps } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { selectAuth } from "../../../../Auth/state/auth.slice";
import adminSupplierCompanyApi from "../../../../SupplierCompany/services/admin-supplierCompany.api";
import LoadingScreen from "../../../../common/components/LoadingScreen";
import PageCanvas from "../../../../common/components/Panels/PageCanvas";
import Section from "../../../../common/components/Panels/Section";
import { useAppSelector } from "../../../../common/hooks";
import useFormRules from "../../../../common/hooks/useFormRules";
import { ROUTES } from "../../../../common/router";
import { getBase64 } from "../../../../common/tools/global-tools";
import productAdminApi from "../../../services/product-admin-api";
import productApi from "../../../services/product-api";
import AdminProductPriceSteps from "./AdminProductPriceSteps";
import SupplierShippingSteps from "../../../../SupplierCompany/components/common/SupplierEditForm/SupplierShippingSteps";
import ShippingWeightSteps from "../../../../SupplierCompany/components/common/ShippingWeightSteps";

// type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export const AdminProductDetails = () => {

  const { productId } = useParams();
  const [form] = useForm()
  const selectedCategory = useWatch('category', form)

  const { required } = useFormRules();
  const { t } = useTranslation();
  const auth = useAppSelector(selectAuth)

  const [product, setProduct] = useState<IProduct>()
  const [productImages, setProductImages] = useState<string[]>([])

  const [suppliers, setSuppliers] = useState<{ value: string, label: string }[]>([])
  const [selectedItem, setSelectedItem] = useState<string | null>(null)

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const getData = useMemo(() => async () => {
    const res = await productApi.getProduct(productId as string);
    setProduct(res);
  }, [productId, setProduct])

  useEffect(() => {
    if (product && product.images) {
      Promise.all(
        product.images.map(path => {
          return productApi.getProductImage(product.id!, path)
            .then(blob => {
              return getBase64(new Blob([blob]))
            })
        })
      ).then(img => setProductImages(img))
    }

  }, [product])

  const fetchSupplier = useMemo(() => {
    return (search?: string) => {
      const q: any = {};
      if (search && search.length >= 2)
        q.name = '/' + search + '/'
      adminSupplierCompanyApi.findAllSuppliers(q, 1, -1)
        .then(r => setSuppliers(r.rows.map(row => ({
          value: row.id!,
          label: `${row.name}`
        }))))
    }
  }, [setSuppliers])

  useEffect(() => {
    fetchSupplier()
    getData()
  }, [fetchSupplier, getData]);

  const onSearch = async (value: string) => {
    fetchSupplier(value)
  }

  const clear = () => {
    setSuppliers([])
    setSelectedItem(null)
  }

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const updateProduct = async (formData: IProduct) => {
    await productAdminApi.updateProduct(productId as string, formData)
  }

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }: any) =>
    setFileList(newFileList);

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const deleteImg = async (fileName: string) => {
    const productUpdated = await productAdminApi.deleteImg(productId as string, fileName)
    setProduct(productUpdated)
  }

  if (!product) {
    return <LoadingScreen></LoadingScreen>
  }

  return (
    <PageCanvas
      breadCrumbs={[
        ROUTES.ADMIN_USER_ROUTES.home,
        ROUTES.ADMIN_PRODUCT_ROUTES.productList,
        ROUTES.ADMIN_PRODUCT_ROUTES.productDetails
      ]}
      title={<span>Fiche produit</span>}
    >

      <Form
        form={form}
        name="basic"
        layout='vertical'
        onFinish={updateProduct}
        initialValues={{ ...product, shippingSteps: product.shippingSteps || [], shippingWeightSteps: product.shippingWeightSteps || [] }}
      >
        <Form.Item name={['priceSteps']} hidden />
        <Form.Item name={['shippingSteps']} hidden />
        <Form.Item name={['shippingWeightSteps']} hidden />

        <Section title="Informations">
          <Form.Item
            label="Fournisseur"
            name="supplierCompanyId"
          >
            <Select
              showSearch
              placeholder="Fournisseur"
              optionFilterProp="children"
              onSearch={onSearch}
              filterOption={filterOption}
              onClear={clear}
              onSelect={selectedItem as any}
              value={selectedItem}
              options={suppliers}
            >
            </Select>
          </Form.Item>

          <div className="flex space-x-4">
            <Form.Item
              label="Nom du produit"
              name="name"
              className="flex-1"
              rules={[required]}
            >
              <Input></Input>
            </Form.Item>

            <Form.Item
              label="Référence"
              name="reference"
              rules={[required]}
              className="w-44"
            >
              <Input></Input>
            </Form.Item>
          </div>

          <Form.Item
            label="Fiche technique"
            name="productDocumentUrl"
          >
            <Input></Input>
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[required]}
          >
            <TextArea autoSize={{ minRows: 10 }} />
          </Form.Item>

        </Section>

        <Section title="Disponibilité">

          <div className="flex space-x-4">
            <Form.Item
              label="Catégorie"
              name="category"
              rules={[required]}
              className="w-48"
            >
              <Select
                options={Object.keys(DispositifCategoryEnum)
                  .map(status => ({
                    label: t(`TYPES.INVENTORY.DispositifCategoryEnum.${status as any}`),
                    value: status
                  }))
                }
              />
            </Form.Item>

            <Form.Item
              label="Type"
              name="type"
              rules={[required]}
              className="flex-1"
            >
              <Select
                options={Object.keys(DispositifTypeEnum)
                  .filter((k: any) => selectedCategory === DispositifCategoryEnum.ashtray ? isAshtrayType(k) : isExtinguisherType(k))
                  .map(status => ({ label: t(`TYPES.INVENTORY.DispositifTypeEnum.${status as any}`), value: status }))
                }
              />
            </Form.Item>
          </div>

          <Form.Item
            label="Disponibilité"
            name="status"
            rules={[required]}
          // initialValue={ProductStatusEnum.product_available}
          >
            <Radio.Group >
              {Object.keys(ProductStatusEnum)
                .filter(k => !isNaN(k as any))
                .map(status =>
                  <Radio key={status} value={+status}>{t(`TYPES.PRODUCT.ProductStatusEnum.${ProductStatusEnum[status as any]}`)}</Radio>
                )
              }
            </Radio.Group>

          </Form.Item>


        </Section>

        <Section title="Visuels">
          <div className="flex space-x-6 flex-wrap items-center">
            {productImages.map((blob, index) => (
              <div key={index} className="max-h-32 w-32 mb-12">
                <div className="flex space-x-4 items-center bg-gray-50 p-2">
                  <Image
                    src={blob}
                    className="object-container"
                  />
                  <Button
                    type="link"
                    danger
                    onClick={() => deleteImg(product.images[index])}
                    icon={<DeleteOutlined />}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="mb-2">
            <label>Ajouter des visuels</label>
          </div>
          <Upload
            action={`${process.env.REACT_APP_API_URL}/admin/product/${productId}/files`}
            method="PUT"
            headers={{ Authorization: `Bearer ${(auth as IAuth).token}` }}
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            multiple={true}
          >
            {fileList.length >= 8 ? null : uploadButton}
          </Upload>
          {previewImage && (
            <Image
              wrapperStyle={{ display: 'none' }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage('')
              }}
              src={previewImage}
            />
          )}
        </Section>

        <Section title="Tarif principal et poids">
          <div className="flex space-x-8">

            <div className="flex space-x-8">
              <Form.Item
                label="Poids"
                name="weight"
                className="w-20"
                rules={[required]}
              >
                <InputNumber suffix="kg" min={1}></InputNumber>
              </Form.Item>

              <Form.Item
                label="Tarif"
                name="price"
                className="w-20"
                rules={[required]}
              >
                <InputNumber suffix="€" min={1}></InputNumber>
              </Form.Item>

              <Form.Item
                label="Frais de livraison"
                name="shippingFees"
                className="w-44"
              >
                <InputNumber suffix="€" />
              </Form.Item>
            </div>

            {/* <Form.Item
              label="TVA"
              name="tax"
              rules={[required]}
            >
              <InputNumber min={0}></InputNumber>
            </Form.Item> */}
          </div>
        </Section>

        <Section title="Paliers de tarif" mode="transparent">
          <AdminProductPriceSteps />
        </Section>

        <Section title="Paliers de frais de livraison" mode="transparent">
          <SupplierShippingSteps property="shippingSteps" />
        </Section>

        <Section title="Frais de livraison selon le poids" mode="transparent">
          <ShippingWeightSteps property="shippingWeightSteps" />
        </Section>


        <Button htmlType="submit" type="primary" >{t('COMMON.save')}</Button>

      </Form >

    </PageCanvas >
  )
}

export default AdminProductDetails

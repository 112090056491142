import { IAcquisitionOrders } from '@alcome-rep/alcome-types/dist/interfaces';
import { takeLatest } from 'redux-saga/effects';
import createEntityDucksFactory from '../../common/state/ducks.factory';
import acquisitionOrderPortalApi from '../services/portal-acquisitionOrder.api';
import { getPortalAcquisitionOrderAction, setCurrentPortalAcquisitionOrderAction, updatePortalAcquisitionOrderAction } from './acquisitionOrder-portal.slice';

/**
 * SAGAS
 */
// function* deleteAnnualReviewDocument({ payload }: ReturnType<typeof deleteAnnualReviewDocumentActions>) {
//   try {
//     const resp: AnnualReview = yield call(annualReviewPortalApi.deleteAnnualReviewFile, payload);
//     yield put(setCurrentAnnualReviewAction(resp));
//   } catch {
//     yield put(setCurrentAnnualReviewAction(null));
//   }
// }

const actions = createEntityDucksFactory<IAcquisitionOrders>(acquisitionOrderPortalApi, {
  get: getPortalAcquisitionOrderAction,
  set: setCurrentPortalAcquisitionOrderAction,
  update: updatePortalAcquisitionOrderAction,
})

export function* acquisitionSaga() {
  yield takeLatest(getPortalAcquisitionOrderAction, actions.get);
  yield takeLatest(updatePortalAcquisitionOrderAction, actions.update);

}

import { DispositifTypeEnum, GeoPoint, ICollectPoint, IHotspot, ISupportOrders, SupportOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { CloseOutlined, DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber, Modal, Upload } from "antd";
import { RcFile, UploadProps } from "antd/lib/upload";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { selectUser } from "../../../../Auth/state/auth.slice";
import { useAppSelector } from "../../../../common/hooks";
import CollectPointModal from "../../../../Map/components/CollectPoint/collectPointModal";
import MapComponent from "../../../../Map/components/Gmap/mapComponent";
import mapApi from "../../../../Map/services/map.api";
import { selectCurrentStructure } from "../../../../Structure/state/structure-portal.slice";
import supportOrderApi from "../../../services/supportOrder.api";

const SupportOrderCollectPointSelection = ({ supportOrder }: { supportOrder: ISupportOrders }) => {

  const structure = useAppSelector(selectCurrentStructure);
  const [currentCollectPoint, setCurrentCollectPoint] = useState<ICollectPoint>();
  const [currentHotspot, setCurrentHotspot] = useState<IHotspot>();

  const [currentCollectPointKey, setCurrentCollectPointKey] = useState<number>();
  const [currentHotspotKey, setCurrentHotspotKey] = useState<number>();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const user = useAppSelector(selectUser)
  const isAdmin = user?.userType === UserTypeEnum.admin
  const canEdit = !isAdmin && supportOrder.status === SupportOrderStatusEnum.support_order_declarations_pending;
  const { t } = useTranslation()

  const openModal = (hotspotKey: number, collectPointKey: number) => {
    setCurrentHotspotKey(hotspotKey)
    setCurrentCollectPointKey(collectPointKey)

    const hotspots = form.getFieldValue('hotspots');
    setCurrentHotspot(hotspots[hotspotKey].hotspot!);
    setIsModalOpen(true)
  }

  const insertGeoPoint = (geoPoint: GeoPoint | GeoPoint[]) => {
    const polygon = new google.maps.Polygon()
    polygon.setPath(currentHotspot!.geoPoints)
    if (!google.maps.geometry.poly.containsLocation(geoPoint as GeoPoint, polygon)) {
      alert("Le point de collecte doit se situer dans le hotspot " + currentHotspot?.name + " en surbrillance");
      return;
    }

    setCurrentCollectPoint({
      geoPoint: Array.isArray(geoPoint) ? geoPoint[0] : geoPoint
    } as ICollectPoint)

    setIsModalOpen(false)
  }

  const handleOk = async (data: ICollectPoint) => {
    const hotspots = form.getFieldValue('hotspots') as ISupportOrders['hotspots'];
    const collectPoints = hotspots[currentHotspotKey!].collectPoints;
    if (collectPoints !== undefined) {
      const cp = (await mapApi.createCollectPoint(data, supportOrder.annualReviewId, true))
      collectPoints[currentCollectPointKey!] = cp;
      form.setFieldValue('hotspots', hotspots)
    }

    if (supportOrder) {
      const support = form.getFieldsValue();
      await supportOrderApi.updateSupportOrder(supportOrder._id as string, support)
      setCurrentCollectPoint(undefined)
    }
  }

  const handleCancel = () => {
    setCurrentCollectPoint(undefined)
  }

  const handleCancelModal = () => {
    setIsModalOpen(false)
  }

  const updatePrice = (collectPoint: ICollectPoint, value: number) => {
    mapApi.updateCollectPoint(collectPoint.id!, {
      price: value,
      placeType: collectPoint.placeType,
      dispositifType: collectPoint.dispositifType,
      geoPoint: collectPoint.geoPoint,
    } as ICollectPoint)

    const hotspots: ISupportOrders['hotspots'] = form.getFieldValue('hotspots');
    const cp = hotspots?.flatMap(hs => hs.collectPoints).find(cp => cp?.id === collectPoint.id)
    if (cp) {
      cp.price = value;
      supportOrderApi.updateSupportOrder(supportOrder._id!, { hotspots })

    }
  }

  const deleteCollectPoint = async (collectPoint: ICollectPoint) => {
    await Modal.confirm({
      title: "Suppression",
      content: 'Êtes-vous sûr de vouloir supprimer ce point de collecte ?',
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      async onOk() {
        const hotspots: ISupportOrders['hotspots'] = form.getFieldValue('hotspots');
        const hotspot = hotspots.find(hs => hs.collectPoints && hs.collectPoints.find(cs => cs.id === collectPoint.id));
        const collectPoints = hotspot && hotspot.collectPoints;
        const cpIndex = collectPoints && collectPoints.findIndex(cp => cp.id === collectPoint.id);
        if (collectPoints && typeof cpIndex === "number" && cpIndex >= 0) {
          collectPoints.splice(cpIndex, 1)

          await supportOrderApi.updateSupportOrder(supportOrder._id!, { hotspots })
          await mapApi.deleteCollectPoint(collectPoint._id!);
          // hotspot.collectPoints[hotspot.collectPoints.indexOf(collectPoint)] = {} as any;
          collectPoints[cpIndex] = {} as any

          form.setFieldValue('hotspots', hotspots)
        }
      }
    })

  }

  const form = Form.useFormInstance()

  // const changeCollectPointSelection = (selection: ICollectPoint | IHotspot) => {
  //   if (hotspotKey !== undefined && collectPointKey !== undefined) {
  //     const hotspots = form.getFieldValue(`hotspots`);
  //     hotspots[hotspotKey]['collectPoints'][collectPointKey] = selection;
  //     form.setFieldValue('hotspots', hotspots)
  //   }

  // }

  const getProps = (collectpointId: string): UploadProps => ({
    beforeUpload: file => {
      addPictureToCp(collectpointId, file);
      return false;
    }
  })

  const addPictureToCp = (collectpointId: string, file: RcFile) => {
    mapApi.uploadPictureInstallation(supportOrder.id as string, collectpointId, file)
      .then(r => {
        const updatedHp = form.getFieldValue('hotspots') as ISupportOrders['hotspots'];
        const findHp = updatedHp.find(h => h.collectPoints && h.collectPoints.find(c => c.id === collectpointId))

        if (r && findHp) {
          const findCp = findHp.collectPoints?.find(c => c.id === collectpointId);
          if (findCp) {
            findCp.pictureInstallation = r.pictureInstallation
            form.setFieldValue('hotspots', updatedHp)
          }
        }
      })
  }

  const downloadPicture = async (collectpoint: ICollectPoint) => {
    await mapApi.downloadPicture(collectpoint, collectpoint.structureId)
  }

  return <>

    <Modal
      className="h-dvh !w-10/12 !lg:w-9/12"
      title="Sélectionnez les points de collecte"
      open={isModalOpen}
      onCancel={handleCancelModal}
      destroyOnClose={true}
      footer={false}
    >
      <p>Cliquez sur le marqueur présent sur le menu de la carte pour insérer un point de collecte.</p>
      <div className="container">
        <MapComponent
          structure={structure!}
          editMode="clickLocation"
          onMapInsert={insertGeoPoint}
          initialSelection={[currentHotspot!]}
        />
      </div>
    </Modal>

    <CollectPointModal
      structure={structure!}
      mode="supportOrder"
      canEdit={true}
      collectPoint={currentCollectPoint}
      onOk={handleOk}
      onCancel={handleCancel}
      dipositifTypes={[
        DispositifTypeEnum.cendrier_mural,
        DispositifTypeEnum.cendrier_mobilier_urbain,
        DispositifTypeEnum.cendrier_sondage,
        DispositifTypeEnum.cendrier_sur_pied_inf_10l,
        DispositifTypeEnum.cendrier_sur_pied_sup_10l,
      ]}
    />

    {
      <div className="ant-table-container">
        <Form.List name="hotspots">
          {(fields) => (
            fields.map((field) => (
              <div key={field.key} className="mt-10">

                <div className="text-alc-blue" style={{ fontWeight: "bold", textTransform: "uppercase" }}>Nom du Hotspot : <span className="font-semibold">{form.getFieldValue('hotspots')[field.key]['hotspot']['name']} ({supportOrder.hotspots[field.key].qty} dispositifs de rue)</span></div>
                <table style={{ tableLayout: 'auto' }} className="w-full">
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell" style={{ width: "50%", textAlign: "center" }}>Type de dispositifs</th>
                      <th className="ant-table-cell" style={{ minWidth: "180px", textAlign: "center" }}>Prix unitaire</th>
                      <th className="ant-table-cell" style={{ minWidth: "180px", textAlign: "center" }}>Photo du dispositif</th>
                      {canEdit && <th className="ant-table-cell" style={{ minWidth: "100px", textAlign: "center" }}>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    <Form.List name={[field.key, 'collectPoints']}>
                      {(cpFields) => (
                        cpFields.map(f => {
                          const collectPoint = form.getFieldValue('hotspots')[field.key].collectPoints[f.key] as ICollectPoint;
                          if (!collectPoint.id)
                            return <tr key={f.key}>
                              <td colSpan={isAdmin ? 2 : 3} className="text-left">
                                {canEdit && <Button type="link" onClick={_ => openModal(field.key, f.key)}>N° {(f.key + 1)} : Cliquez pour déclarer l'installation du dispositif</Button>}
                                {!canEdit && <span>Dispositif en attente</span>}
                              </td>
                            </tr>

                          return (
                            <tr key={f.key}>
                              <td className="text-center">
                                <Form.Item className="m-0" name={[f.key, 'dispositifType']}>
                                  {collectPoint.dispositifType && t('TYPES.INVENTORY.DispositifTypeEnum.' + collectPoint.dispositifType)}
                                </Form.Item>
                              </td>
                              <td className="ant-table-cell  ">
                                {canEdit && <Form.Item className="m-0" name={[f.key, 'price']}>
                                  <InputNumber
                                    className="input-text"
                                    addonAfter="€"
                                    onChange={value => value !== null && +value > 0 && updatePrice(collectPoint, +value)}
                                  />
                                </Form.Item>
                                }
                                {!canEdit && collectPoint.price + ' €'}
                              </td>
                              {collectPoint.pictureInstallation ?
                                <td>
                                  <Button
                                    icon={<DownloadOutlined />}
                                    className="btn-link"
                                    onClick={() => downloadPicture(collectPoint)}
                                  >Télécharger la photo</Button>
                                </td>
                                :
                                <td>
                                  {
                                    canEdit ?
                                      <Upload {...getProps(collectPoint.id)}>
                                        <Button htmlType='button' icon={<UploadOutlined />}>Chargez votre photo</Button>
                                      </Upload>
                                      :
                                      <div>Aucune photo pour le moment.</div>
                                  }
                                </td>
                              }
                              {canEdit && <td className="text-right">
                                {collectPoint.id && <Button type="link" icon={<CloseOutlined className="text-alc-danger text-sm" />} onClick={() => deleteCollectPoint(collectPoint)}></Button>}
                              </td>
                              }
                            </tr>
                          )
                        })
                      )}
                    </Form.List>
                  </tbody>
                </table>
              </div>
            ))
          )}
        </Form.List>
      </div>
    }
  </>
}

export default SupportOrderCollectPointSelection
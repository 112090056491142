import { Models } from "@alcome-rep/alcome-types";
import { ISupplierOrder } from "@alcome-rep/alcome-types/dist/interfaces/supplier";
import { ISupplierFileDocument } from "@alcome-rep/alcome-types/dist/interfaces/supplier/supplierFileDocument";
import { message } from "antd";
import ApiService from "../../common/services/apiService";

const ADMIN_SUPPLIER_FILE_DOC_PATH_API = 'admin/supplierFileDoc';

class AdminSupplierFileDocApi extends ApiService<ISupplierFileDocument> {
  constructor() {
    super(ADMIN_SUPPLIER_FILE_DOC_PATH_API)
  }

  updateSupplierFile(fileDocId: string, data: any) {
    return this.custom<ISupplierOrder>("put", `${fileDocId}`, undefined, data)
      .then(res => {
        message.success('Métadonnées mises à jour avec succès')
        return new Models.SupplierOrder(res)
      })
      .catch(err => {
        message.error('Erreur lors de la mise à jour')
        return err
      })
  }


}

const adminSupplierFileDocApi = new AdminSupplierFileDocApi();
export default adminSupplierFileDocApi;
import { RouteObject } from "react-router-dom";
import AcquisitionOrderAshtrays from "../components/common/AcquisitionOrderAshtrays/AcquisitionOrderAshtrays";
import AcquisitionOrderExtinguishers from "../components/common/AcquisitionOrderExtinguishers/AcquisitionOrderExtinguishers";
import AcquisitionOrderResume from "../components/common/AcquisitionOrderResume/AcquisitionOrderResume";
import AcquisitionOrderList from "../components/portal/AcquisitionOrderList/AcquisitionOrderList";
import AcquisitionOrderLayout from "../layouts/AcquisitionOrderLayout";
import AcquisitionOrderMaterial from "../components/common/AcquisitionOrderMaterial/AcquisitionOrderMaterial";


const routePrefix = 'portal'
const routeDomain = 'acquisitionOrder'
const routeParam = 'acquisitionOrderId'

export const portalAcquisitionOrderRouteNames = {
  acquisitionOrderList: { path: `${routePrefix}/${routeDomain}`, title: `ROUTES.${routeDomain}.list` },
  acquisitionOrderDetails: { path: `${routePrefix}/${routeDomain}/:${routeParam}`, title: `ROUTES.${routeDomain}.details` },
  acquisitionOrderExtinguishers: { path: `${routePrefix}/${routeDomain}/:${routeParam}/extinguishers`, title: `ROUTES.${routeDomain}.extinguishers` },
  acquisitionOrderAshtrays: { path: `${routePrefix}/${routeDomain}/:${routeParam}/ashtrays`, title: `ROUTES.${routeDomain}.ashtrays` },
  acquisitionOrderDeclaration: { path: `${routePrefix}/${routeDomain}/:${routeParam}/declaration`, title: `ROUTES.${routeDomain}.declaration` },
}

export const portalAcquisitionOrderRoutes: RouteObject[] = [
  {
    path: portalAcquisitionOrderRouteNames.acquisitionOrderList.path.replace(`${routePrefix}/`, ''),
    element: <AcquisitionOrderList />,
  },
  {
    path: portalAcquisitionOrderRouteNames.acquisitionOrderDetails.path.replace(`${routePrefix}/`, ''),
    element: <AcquisitionOrderLayout />,
    children: [
      {
        path: 'ashtrays',
        element: <AcquisitionOrderAshtrays />
      },
      {
        path: 'extinguishers',
        element: <AcquisitionOrderExtinguishers />
      },
      {
        path: '',
        element: <AcquisitionOrderResume />
      },
      {
        path: 'declaration',
        element: <AcquisitionOrderMaterial />
      }
    ]
  },
]

import { ContractStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces"
import { Contract } from "@alcome-rep/alcome-types/dist/models"
import DateField from "../../../../common/components/Fields/DateField"

type PropsType = {
  contract: Contract
}

const ContractDate = ({ contract }: PropsType) => {
  return contract.contractDate && contract.status === ContractStatusEnum.validated ? <DateField value={contract.contractDate} /> : <span> - </span>
}

export default ContractDate
import { IAmendment, StructureTypesEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import { CloseOutlined, FilePdfOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Form, Input, Spin, Upload, message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LoadingScreen from '../../../../common/components/LoadingScreen';
import PageCanvas from '../../../../common/components/Panels/PageCanvas';
import Section from '../../../../common/components/Panels/Section';
import { ROUTES } from '../../../../common/router';
import { useTrackProgress } from '../../../../common/services/trackProgress';
import amendmentAdminApi, { progressKeys } from '../../../services/amendment-admin.api';

const AdminAmendmentDetails = () => {

  const { t } = useTranslation();

  const [data, setData] = useState<IAmendment>();
  const { amendmentId }: any = useParams();
  const isLoading = useTrackProgress(progressKeys.upload);

  const getData = useMemo(() => async () => {
    const res = await amendmentAdminApi.getAmendmentById(amendmentId)
    const amendm: IAmendment = await res
    if (amendm.date)
      (amendm as any).date = moment(amendm.date)
    setData(amendm)
  }, [amendmentId])

  useEffect(() => {
    getData();
  }, [getData]);


  const beforeUpload = (file: RcFile) => {
    const isPDF = file.type === 'application/pdf';
    if (!isPDF) {
      message.error(`${file.name} n'est pas un fichier pdf`);
    } else {
      amendmentAdminApi.uploadAmendmentDoc(amendmentId, file).then(response => setData(response));
    }
    return false;
  }

  const submit = (amendmentData: IAmendment) => {
    const res = amendmentAdminApi.updateAmendment(amendmentId, { ...amendmentData, published: true }).then(response => setData(response))
    if (!res) {
      return message.error('erreur')
    } else {
      return message.success('Avenant mis à jour')
    }
  }

  const downloadDoc = () => {
    amendmentAdminApi.downloadAmendmentDoc(amendmentId)
  }
  const deleteFile = () => {
    amendmentAdminApi.deleteAmendmentDoc(amendmentId).then(response => setData(response))
  }

  const unpublishedAmendment = () => {
    if (data) {
      const amObj: Partial<IAmendment> = {
        amendmentNumber: data.amendmentNumber,
        date: data.date,
        name: data.name,
        published: false,
        structureTypes: data.structureTypes,
        contractVersion: data.contractVersion
      }
      const res = amendmentAdminApi.updateAmendment(amendmentId, amObj)
      if (!res) {
        return message.error('erreur')
      } else {
        return message.success('Avenant mis à jour')
      }
    }
  }

  if (!data)
    return <LoadingScreen />

  return (
    <>
      <PageCanvas
        breadCrumbs={[
          ROUTES.ADMIN_USER_ROUTES.home,
          ROUTES.ADMIN_ADMENDMENT_ROUTES.amendmentList,
          ROUTES.ADMIN_ADMENDMENT_ROUTES.amendmentDetails
        ]}
        title={<span>Avenant numéro #{data.amendmentNumber}</span >}
      >
        <Section>

          <Form
            name="basic"
            layout='vertical'
            onFinish={submit}
            initialValues={data}
          >
            <Form.Item
              label="N° de l'avenant"
              name="amendmentNumber"
              className='mt-5 mb-5'
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              label="Nom de l'avenant"
              name="name"
              className='mt-5 mb-5'
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              label="Date de l'avenant"
              name="date"
            >
              <DatePicker format={'DD/MM/YYYY'} />
            </Form.Item>
            <Form.Item
              label="Types de structure"
              name="structureTypes"
            >
              <Checkbox.Group >
                {Object.keys(StructureTypesEnum).filter(k => typeof k === 'string').map(k => <Checkbox value={k}>{t('TYPES.STRUCTURE.StructureTypesEnum.' + k)}</Checkbox>)}
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              label="Version du contrat"
              name="contractVersion"
              className='mt-5 mb-5'
            >
              <Input></Input>
            </Form.Item>

            <div className={`${!data.amendmentFile && 'hidden'}`}>
              <div className='border-1 border-gray-200 py-1 px-2 flex justify-between'>
                <div><FilePdfOutlined className='text-alc-gray'></FilePdfOutlined></div>
                <button type="button" onClick={downloadDoc} className='btn-link px-2 text-alc-gray'>Fichier PDF de l'avenant</button>
                <div>
                  <Button
                    className='p-0 border-none h-2'
                    htmlType='button'
                    onClick={() => deleteFile()}
                    icon={<CloseOutlined className='text-xs' />}>
                  </Button>
                </div>
              </div>
            </div>
            <div className={`${data.amendmentFile && 'hidden'} flex space-x-2`}>
              <Upload
                beforeUpload={beforeUpload}
                accept="application/pdf"
                showUploadList={false}>
                <Button className='h-16 bg-gray-50' htmlType='button'>
                  <div className='flex space-x-2 justify-center items-center'>
                    <div className=''>
                      {!isLoading && <UploadOutlined className='text-2xl text-alc-blue'></UploadOutlined>}
                      {isLoading && <Spin className='text-2xl text-alc-blue'></Spin>}
                    </div>
                    <div className='flex flex-col space-y-0 '>
                      {!isLoading && [
                        (<span className='text-alc-gray font-semibold text-lg'>{t("COMMON.FILEUPLOAD.dragndrop")}</span>),
                        (<span className='text-alc-gray'>{t("COMMON.FILEUPLOAD.dragndrop2")}</span>)
                      ]}
                      {isLoading && [
                        (<span className='text-alc-gray font-semibold text-lg'>{t("COMMON.FILEUPLOAD.loading")}</span>),
                        (<span className='text-alc-gray'>{t("COMMON.FILEUPLOAD.wait")}</span>)
                      ]}
                    </div>
                  </div>
                </Button>
              </Upload>
            </div>
            <div className='flex space-x-4'>
              <Form.Item>
                <Button htmlType="submit" type="primary" className="bg-alc-blue " style={{ marginTop: 30 }}>
                  Publier les modifications
                </Button>
              </Form.Item>
              {
                data.published &&
                <Button className="btn-link text-alc-danger hover:text-alc-yellow" onClick={unpublishedAmendment} style={{ marginTop: 30 }}>
                  Annuler la publication de l'avenant
                </Button>
              }
            </div>
          </Form>

        </Section>
      </PageCanvas>


      {/* <div className='p-8'>
                <div className='flex space-x-6 mb-4'>
                    <button className='font-semibold text-alc-blue' onClick={() => navigate(-1)}>{<StepBackwardOutlined />} Retour</button>
                    <h3>Données de l'avenant</h3>
                </div>
                <div className='card'>
                    {data && (
                        <Form
                            name="basic"
                            layout='vertical'
                            onFinish={submit}
                            initialValues={data}
                        >
                            <Form.Item
                                label="N° de l'avenant"
                                name="amendmentNumber"
                                className='mt-5 mb-5'
                            >
                                <Input></Input>
                            </Form.Item>
                            <Form.Item
                                label="Nom de l'avenant"
                                name="name"
                                className='mt-5 mb-5'
                            >
                                <Input></Input>
                            </Form.Item>
                            <Form.Item
                                label="Date de l'avenant"
                                name="date"
                            >
                                <DatePicker format={'DD/MM/YYYY'} />
                            </Form.Item>
                            <Form.Item
                                label="Types de structure"
                                name="structureTypes"
                            >
                                <Checkbox.Group >
                                    {Object.keys(StructureTypesEnum).filter(k => typeof k === 'string').map(k => <Checkbox value={k}>{t('TYPES.STRUCTURE.StructureTypesEnum.' + k)}</Checkbox>)}
                                </Checkbox.Group>
                            </Form.Item>
                            <Form.Item
                                label="Version du contrat"
                                name="contractVersion"
                                className='mt-5 mb-5'
                            >
                                <Input></Input>
                            </Form.Item>

                            <div className={`${!data.amendmentFile && 'hidden'}`}>
                                <div className='border-1 border-gray-200 py-1 px-2 flex justify-between'>
                                    <div><FilePdfOutlined className='text-alc-gray'></FilePdfOutlined></div>
                                    <button type="button" onClick={downloadDoc} className='btn-link px-2 text-alc-gray'>Fichier PDF de l'avenant</button>
                                    <div>
                                        <Button
                                            className='p-0 border-none h-2'
                                            htmlType='button'
                                            onClick={() => deleteFile()}
                                            icon={<CloseOutlined className='text-xs' />}>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className={`${data.amendmentFile && 'hidden'} flex space-x-2`}>
                                <Upload
                                    beforeUpload={beforeUpload}
                                    accept="application/pdf"
                                    showUploadList={false}>
                                    <Button className='h-16 bg-gray-50' htmlType='button'>
                                        <div className='flex space-x-2 justify-center items-center'>
                                            <div className=''>
                                                {!isLoading && <UploadOutlined className='text-2xl text-alc-blue'></UploadOutlined>}
                                                {isLoading && <Spin className='text-2xl text-alc-blue'></Spin>}
                                            </div>
                                            <div className='flex flex-col space-y-0 '>
                                                {!isLoading && [
                                                    (<span className='text-alc-gray font-semibold text-lg'>{t("COMMON.FILEUPLOAD.dragndrop")}</span>),
                                                    (<span className='text-alc-gray'>{t("COMMON.FILEUPLOAD.dragndrop2")}</span>)
                                                ]}
                                                {isLoading && [
                                                    (<span className='text-alc-gray font-semibold text-lg'>{t("COMMON.FILEUPLOAD.loading")}</span>),
                                                    (<span className='text-alc-gray'>{t("COMMON.FILEUPLOAD.wait")}</span>)
                                                ]}
                                            </div>
                                        </div>
                                    </Button>
                                </Upload>
                            </div>
                            <div className='flex space-x-4'>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary" className="bg-alc-blue " style={{ marginTop: 30 }}>
                                        Publier les modifications
                                    </Button>
                                </Form.Item>
                                {
                                    data.published &&
                                    <Button className="btn-link text-alc-danger hover:text-alc-yellow" onClick={unpublishedAmendment} style={{ marginTop: 30 }}>
                                        Annuler la publication de l'avenant
                                    </Button>
                                }
                            </div>
                        </Form>
                    )}
                </div>
            </div> */}
    </>
  );
};

export default AdminAmendmentDetails;
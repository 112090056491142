import { ReactNode, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import LoadingScreen from '../../common/components/LoadingScreen';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { adminGetProspectAction, adminSetCurrentProspectAction, selectCurrentAdminProspect } from '../state/prospect-admin.slice';

type Props = { redirectPath?: string, children?: ReactNode };

const ProspectAdminGuard = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const { prospectId } = useParams();

  const prospect = useAppSelector(selectCurrentAdminProspect);

  useEffect(() => {
    if (!prospectId)
      return;

    dispatch(adminGetProspectAction({ id: prospectId }))

    return () => {
      if (!prospectId) {
        dispatch(adminSetCurrentProspectAction(null))
      }
    };
  }, [prospectId, dispatch])

  if (!prospect)
    return <LoadingScreen />

  return children ? (<>{children}</>) : <Outlet></Outlet>
};

export default ProspectAdminGuard
import { Models } from '@alcome-rep/alcome-types';
import { UserTypeEnum } from '@alcome-rep/alcome-types/dist/interfaces/user';
import { Button, Form, Input } from 'antd';
import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { refreshTokenAction, selectUserSupplier } from '../../../../Auth/state/auth.slice';
import Section from '../../../../common/components/Panels/Section';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import useFormRules from '../../../../common/hooks/useFormRules';
import msg from '../../../../common/services/toast';
import userSupplierApi from '../../../services/user-supplier.api';
import userApi from '../../../services/user.api';


const UserSupplierProfil = () => {

  const { t } = useTranslation();
  const user = useAppSelector(selectUserSupplier);

  const dispatch: Dispatch<any> = useAppDispatch()

  const submit = (userData: Models.UserSupplier) => {
    userSupplierApi.updateInfos(userData)
    dispatch(refreshTokenAction(UserTypeEnum.portal))
  }

  const { required, phoneNumber, email, password: passwordMinLength } = useFormRules();

  const changePassword = async (passwordData: any) => {
    try {
      await userApi.changePassword(passwordData);
      msg.success("Mot de passe changé avec succès")
    }
    catch {
      msg.error("Le mot de passe doit contenir au minimum 8 caractères. 1 majuscule et un caractère spécial (@,$,!,%,*,?,&)")
    }
  }

  return (
    <>
      <Section title={<span>Mes données personnelles</span>}>
        <Form
          name="basic"
          initialValues={user}
          layout='vertical'
          onFinish={submit}
        >
          <Form.Item
            label={t('UI.PROFIL.firstName')}
            name="firstName"
            rules={[required]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label={t('UI.PROFIL.lastName')}
            name="lastName"
            rules={[required]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label={t('UI.PROFIL.phone')}
            name="phone"
            rules={[required, phoneNumber]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label={t('UI.PROFIL.email')}
            name="email"
            rules={[required, email]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary" className="bg-alc-blue">
              Modifier les paramètres
            </Button>
          </Form.Item>
        </Form>
      </Section>

      <Section title={<span>Accès au compte</span>}>
        <Form
          name="basic"
          layout='vertical'
          onFinish={changePassword}
        >
          <Form.Item
            label={t('UI.PROFIL.password')}
            name="oldPassword"
            rules={[required]}
          >
            <Input type={'password'}></Input>
          </Form.Item>
          <Form.Item
            label={t('UI.PROFIL.newPassword')}
            tooltip={t('UI.PROFIL.tooltipPassword')}
            name="password"
            rules={[required, passwordMinLength]}
          >
            <Input type={'password'}></Input>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary" className="bg-alc-blue">
              Modifier le mot de passe
            </Button>
          </Form.Item>
        </Form>
      </Section>

    </>
  );
};

export default UserSupplierProfil;
import { Models } from "@alcome-rep/alcome-types";
import { FileDocumentStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { FileMetadata, ISupplierFileDocument, ProductMetadata } from "@alcome-rep/alcome-types/dist/interfaces/supplier/supplierFileDocument";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Table, TableColumnType } from "antd";
import { selectUserAdmin } from "../../../../Auth/state/auth.slice";
import LoadingScreen from "../../../../common/components/LoadingScreen";
import Section from "../../../../common/components/Panels/Section";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks";
import { closeDrawerAction } from "../../../../common/state/modal.slice";
import adminSupplierFileDocApi from "../../../services/adminSupplierFileDoc.api";

const FileMetadataForm = ({ fileDoc, setRefresh }: { fileDoc: ISupplierFileDocument, setRefresh: (date: Date) => void }) => {

    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUserAdmin)
    const isAdmin = Models.User.IsUserAdmin(user);
    // const isFinancialManager = user.role === UserAdminRolesEnum.financialManager;

    let comment: null | string = null;

    const updateFileMetadata = async (fileMetadata: FileMetadata) => {
        if (!fileDoc)
            return;
        try {
            await adminSupplierFileDocApi.updateSupplierFile(fileDoc.id as string, fileMetadata)
            dispatch(closeDrawerAction())
            setRefresh(new Date())
        } catch { }
    }

    const columns: TableColumnType<ProductMetadata>[] = [
        {
            title: 'Référence produit',
            dataIndex: ['productRef'],
            align: 'center',
            render: (name: string, rec: any, index: number) => {
                return (
                    <Form.Item
                        name={['fileMetadata', 'productMetadata', index, 'productRef']}
                    >
                        <Input></Input>
                    </Form.Item>
                )
            },
        },
        {
            title: 'Nom du produit',
            dataIndex: ['productName'],
            render: (v: string, rec: any, index: number) => {
                return (
                    <Form.Item
                        name={['fileMetadata', 'productMetadata', index, 'productName']}
                    >
                        <Input></Input>
                    </Form.Item>
                )
            },
        },
        {
            title: 'Quantité',
            dataIndex: ['quantity'],
            render: (v: number, rec: any, index: number) => {
                return (
                    <Form.Item
                        name={['fileMetadata', 'productMetadata', index, 'quantity']}
                    >
                        <Input></Input>
                    </Form.Item>
                )
            },
        },

        {
            title: 'Prix unitaire',
            dataIndex: ['unitPrice'],
            align: "center",
            render: (v: number, rec: any, index: number) => {
                return (
                    <Form.Item
                        name={['fileMetadata', 'productMetadata', index, 'unitPrice']}
                    >
                        <Input></Input>
                    </Form.Item>
                )
            },
        },
    ];

    const validateInvoice = async () => {

        await Modal.confirm({
            cancelText: 'Annuler',
            closable: true,
            okCancel: true,
            title: "Valider la facture",
            content: `Etes-vous sûr de vouloir valider cette facture ?`,
            async onOk() {
                await adminSupplierFileDocApi.updateSupplierFile(fileDoc.id as string, { status: FileDocumentStatusEnum.approved })
                dispatch(closeDrawerAction())
                setRefresh(new Date())
            }
        });

    }

    const refuseInvoice = async () => {
        await Modal.confirm({
            title: 'Refuser la facture',
            icon: <ExclamationCircleOutlined />,
            content: (<>
              Ajouter un commentaire <textarea className='block w-full p-2' onChange={e => comment = (e.target.value)} ></textarea>
            </>),
            cancelText: 'Annuler',
            closable: true,
            okCancel: true,
            okButtonProps: { className: "btn" },
            async onOk() {
                await adminSupplierFileDocApi.updateSupplierFile(fileDoc.id as string, { status: FileDocumentStatusEnum.declined, comment })
                comment = null
                dispatch(closeDrawerAction())
                setRefresh(new Date())
            },
            onCancel() {
            },
          });

    }


    if (!fileDoc)
        return <LoadingScreen />

    return (
        <Form
            name="basic"
            initialValues={fileDoc}
            layout='vertical'
            onFinish={updateFileMetadata}
        >
            <Section
                mode='transparent'
                title="Détails de la facture"
            >
                <Table<ProductMetadata>
                    dataSource={fileDoc.fileMetadata?.productMetadata as ProductMetadata[]}
                    columns={columns}
                    className="extinguisher-table"
                    rowKey={'id'}
                    size='small'
                    locale={
                        { emptyText: "Aucun article." }
                    }
                    pagination={false}
                    summary={(_pageData) => {
                        return (
                            <>
                                <div className="mt-4">
                                    <Form.Item
                                        label="FRAIS DE LIVRAISON"
                                        className="font-semibold"
                                        name={['fileMetadata', 'totalShippingFees']}
                                    >
                                        <Input></Input>
                                    </Form.Item>
                                    <Form.Item
                                        label="TOTAL"
                                        className="font-semibold"
                                        name={['fileMetadata', 'totalPrice']}
                                    >
                                        <Input></Input>
                                    </Form.Item>
                                </div>
                            </>
                        );
                    }}
                />
            </Section>
            <Form.Item>
                <Button className="text-alc-blue hover:text-alc-yellow!" htmlType="submit" type="link"  >
                    Modifier les données
                </Button>
            </Form.Item>
            {isAdmin && fileDoc.status !== FileDocumentStatusEnum.approved && (
                <div>
                    <Section mode="transparent" title="Validation facture">
                        <div className="flex space-x-4">
                            <Button onClick={validateInvoice} type="primary">
                                Valider la facture
                            </Button>
                            <Button className="text-alc-danger hover:text-alc-danger" onClick={refuseInvoice} type="link">
                                Refuser la facture
                            </Button>
                        </div>
                    </Section>
                </div>
            )}
        </Form>
    )

}

export default FileMetadataForm
import { Models } from '@alcome-rep/alcome-types'
import { AnnualReviewStatusEnum, IAnnualReview } from '@alcome-rep/alcome-types/dist/interfaces'
import { User } from '@alcome-rep/alcome-types/dist/models'
import { CloseOutlined, DeleteOutlined, DownloadOutlined, QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons'
import { Dispatch } from '@reduxjs/toolkit'
import { Button, DatePicker, Form, Input, Upload } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import confirm from 'antd/es/modal/confirm'
import TextArea from 'antd/lib/input/TextArea'
import { RcFile } from 'antd/lib/upload'
import dayjs from 'dayjs'
import { selectUser } from '../../../../Auth/state/auth.slice'
import DateField from '../../../../common/components/Fields/DateField'
import Section from '../../../../common/components/Panels/Section'
import { useAppDispatch, useAppSelector } from '../../../../common/hooks'
import useFormRules from '../../../../common/hooks/useFormRules'
import { mimeTypeAccepted, randomNumberId } from '../../../../common/tools/global-tools'
import annualReviewAdminApi from '../../../services/annual-review-admin.api'
import annualReviewPortalApi from '../../../services/annual-review-portal.api'
import { setCurrentPortalAnnualReviewAction, uploadAnnualReviewArrayFileActions } from '../../../state/annual-review-portal.slice'
import { useState } from 'react'
import AddButton from '../../../../common/components/Buttons/addButton'

type PoliceArrestTableComponentType = {
  annualReview: Models.AnnualReview,
  // canEdit: boolean
}

const PoliceArrestTableComponent = ({ annualReview }: PoliceArrestTableComponentType) => {
  const user = useAppSelector(selectUser);

  const admin = User.IsUserAdmin(user)
  const canEdit = !admin && annualReview && annualReview.status <= AnnualReviewStatusEnum.refused ? true : false
  const propertyName = "policeArrest";
  const subPropertyName = "arrestProof";

  const [collapsed, setCollapsed] = useState<boolean>(true);

  const dispatch: Dispatch<any> = useAppDispatch()

  const uploadDocument = async (file: any, index: any) => {
    const formData = new FormData();
    try {
      formData.append('files', file as RcFile);
      dispatch(uploadAnnualReviewArrayFileActions({
        annualReviewId: annualReview.id as string, data: {
          propertyName,
          subPropertyName,
          index,
          timestamp: Date.now()
        }, formData
      }))
    } catch { }
    return false;
  };

  const { required } = useFormRules()

  const downloadFile = async (index: number) => {
    const call = User.IsUserAdmin(user) ? annualReviewAdminApi.adminDownloadArrayFile : annualReviewPortalApi.downloadArrayFile;
    await call(
      annualReview.id!,
      propertyName,
      subPropertyName,
      (annualReview[propertyName] as any)[index][subPropertyName] as string
    )
  }

  const deleteFile = async (index: number) => {
    confirm({
      title: `Suppression du fichier`,
      icon: <QuestionCircleOutlined />,
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      async onOk() {
        const updatedAnnualReview = await annualReviewPortalApi.deleteArrayFile(annualReview.id!, propertyName, subPropertyName, index);
        dispatch(setCurrentPortalAnnualReviewAction(updatedAnnualReview))
      },
      onCancel() {
      },
    });
  }

  const removeArrest = async (index: number, removeFormMethod: any) => {
    confirm({
      title: `Supprimer la ligne ?`,
      icon: <QuestionCircleOutlined />,
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      // okButtonProps: { className: "btn" },
      async onOk() {
        if (annualReview.policeArrest[index] && annualReview.policeArrest[index].arrestProof) {
          const updatedAnnualReview = await annualReviewPortalApi.deleteArrayFile(annualReview.id!, propertyName, subPropertyName, index);
          dispatch(setCurrentPortalAnnualReviewAction(updatedAnnualReview))
        }
        removeFormMethod(index);
      },
      onCancel() {
      },
    });
  }

  const form = useFormInstance<IAnnualReview>()
  const policeArrest = useWatch('policeArrest', form) || (annualReview.policeArrest ?? [])

  const ListItemHasError = (fieldName: string) => {
    return form.getFieldsError()
      .filter(f => f.errors.length)
      .map(f => f.name.join('.'))
      .find(f => f.includes(fieldName))
      !== undefined
  }

  return (
    <Form.List name={'policeArrest'}>
      {(fields, { add, remove }) => (
        <div className="grid grid-cols-1 gap-4">
          {fields.map(({ name, key }, i) => (
            <Section
              key={key}
              mode="collapsible"
              collapsed={collapsed}
              titleClass={ListItemHasError('policeArrest.' + name) ? '!text-alc-danger' : ''}
              title={<span>#{policeArrest[name].policeArrestNumber ?? 'NUM'} - <DateField value={(policeArrest[name].arrestDate)} /></span>}
              actions={canEdit && <Button
                type='text'
                size='small'
                onClick={_ => removeArrest(name, remove)}
                icon={<CloseOutlined className='text-alc-danger' readOnly={!canEdit} />}>
              </Button>}
            >
              <Form.Item hidden name={[name, 'id']} className='hidden'>
                <Input type="hidden" />
              </Form.Item>
              <div className='flex justify-between gap-4'>
                <Form.Item label="Date de l'arrêté" name={[name, 'arrestDate']} rules={[required]}><DatePicker placeholder="Date" className='w-full' readOnly={!canEdit} format={'DD/MM/YYYY'} /></Form.Item>
                <Form.Item label="Numéro de l'arrêté" name={[name, 'policeArrestNumber']} rules={[required]} className='grow'><Input placeholder="Numéro" readOnly={!canEdit} /></Form.Item>

                <Form.Item label="Document">
                  {annualReview.policeArrest[key] && annualReview.policeArrest[key].arrestProof ?
                    <div className='flex space-x-4'>
                      <Button
                        icon={<DownloadOutlined />}
                        onClick={_ => downloadFile(name)}
                        type='link'
                        className='px-0'
                      >Télécharger
                      </Button>
                      {canEdit && <Button
                        icon={<DeleteOutlined />}
                        onClick={_ => deleteFile(name)}
                        type='link'
                        className='px-0'
                      />}
                    </div>
                    : canEdit ? (
                      <Upload
                        disabled={annualReview.policeArrest[name] === undefined}
                        showUploadList={false}
                        beforeUpload={file => uploadDocument(file, name)}
                        accept={mimeTypeAccepted()}
                      >
                        <Button disabled={!policeArrest[name].policeArrestNumber && !policeArrest[name].description} htmlType='button' icon={<UploadOutlined />}>Chargez un fichier</Button>
                      </Upload>
                    )
                      : <span>Aucun document</span>
                  }
                </Form.Item>

              </div>
              <Form.Item label="Description" name={[name, 'description']} rules={[required]} className='mb-0'><TextArea rows={2} readOnly={!canEdit} /></Form.Item>

            </Section>
          ))}

          {canEdit && <AddButton onClick={() => {add({ id: randomNumberId(), arrestDate: dayjs() }); setCollapsed(false)}}>Ajouter un arrêté de police</AddButton>}
        </div>
      )}
    </Form.List>
  );
}

export default PoliceArrestTableComponent;
import { ISupportOrders } from "@alcome-rep/alcome-types/dist/interfaces"
import { EditOutlined } from "@ant-design/icons"
import { Button } from "antd"
import { ColumnsType } from "antd/es/table"
import { format } from "date-fns"
import { fr } from "date-fns/locale"
import { TFunction } from "i18next"
import { Link, NavigateFunction } from "react-router-dom"
import { ROUTES } from "../../../../common/router"
import { getRoute } from "../../../../common/tools/router-tools"
import SupportOrderStatusComponent from "../../common/SupportOrderStatus/SupportOrderStatusComponent"

export const supportOrderListColumns = (query: any, t: TFunction, navigate: NavigateFunction): ColumnsType<ISupportOrders> => [
  {
    fixed: 'left',
    align: 'center' as const,
    render: (value: any) => <Button
      type="link"
      onClick={() => navigate(getRoute(ROUTES.ADMIN_SUPPORT_ORDER_ROUTES.supportOrderDetails, { supportOrderId: value.id }))}
      icon={<EditOutlined />}
    />
  },
  {
    title: 'N°',
    dataIndex: 'supportOrderNumber',
    key: 'supportOrderNumber',
    align: 'center' as const,
    render: (value: any) => {
      return <div>{value}</div>
    },
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    align: 'center' as const,
    render: (value: any) => {
      return <div>{format(new Date(value as Date), "d MMMM yyyy", { locale: fr })}</div>
    },
  },
  {
    title: 'Nom',
    dataIndex: ['structure', 'name'],
    key: 'structure.name',
    align: 'center' as const,
    render: (value: any, item: ISupportOrders) => {
      return <Link to={getRoute(ROUTES.ADMIN_STRUCTURE_ROUTES.structureDetails, { structureId: item.structureId })}>{value}</Link>
    },
  },
  {
    title: 'INSEE',
    dataIndex: ['structure', 'siren'],
    key: 'structure.siren',
    align: 'center' as const,
    render: (value: any) => {
      return <div>{value}</div>
    },
    // filters: [],
    // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => {
    //     return (
    //         <>
    //             <Input
    //                 autoFocus
    //                 placeholder='N° Siren'
    //                 value={selectedKeys[0]}
    //                 onChange={(e) => {
    //                     setSelectedKeys(e.target.value ? [`${e.target.value}`] : [])
    //                 }}
    //                 onPressEnter={() => {
    //                     confirm();
    //                 }}
    //                 onBlur={() => {
    //                     confirm();
    //                 }}
    //             ></Input>
    //             <Button onClick={() => {
    //                 confirm();
    //             }} type='primary' className='btn'>Rechercher</Button>

    //             <Button onClick={() => {
    //                 clearFilters();
    //             }} type='primary' className='btn'>Effacer</Button>
    //         </>
    //     )
    // },
    // filterIcon: () => {
    //     return <SearchOutlined />
    // },
  },
  {
    title: 'Nb cendriers de rue',
    dataIndex: 'hotspots',
    key: 'hotspots',
    align: 'center' as const,
    render: (value: any) => {
      let nbDispositifs = 0;
      value.forEach((item: any) => {
        nbDispositifs += item.qty;
      });
      return <div>{nbDispositifs}</div>
    },
  },
  {
    title: 'Nb eteignoirs',
    dataIndex: 'extinguisherCount',
    key: 'extinguisherCount',
    align: 'center' as const,
    render: (value: any) => {
      return <div>{value}</div>
    },
  },
  {
    title: 'Statut',
    dataIndex: 'status',
    key: 'status',
    align: 'center' as const,
    render: (value: any) => {
      return <SupportOrderStatusComponent status={value} />
    },
  }

]
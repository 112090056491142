import { IAction, IContact } from '@alcome-rep/alcome-types/dist/interfaces/crm';
import { EditOutlined, MoreOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Table } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { useEffect, useState } from 'react';
import DateField from '../../../common/components/Fields/DateField';
import Section from '../../../common/components/Panels/Section';
import Info from '../../../common/components/WarningUser/Info';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import useQueryParams from '../../../common/hooks/useQueryParams';
import useTableChange from '../../../common/hooks/useTableChange';
import { openDrawerAction } from '../../../common/state/modal.slice';
import actionAdminApi from '../../services/action-admin.api';
import { selectCurrentAdminProspect } from '../../state/prospect-admin.slice';
import ActionInfosForm from '../ActionDetails/ActionInfosForm';
import ActionStatus from '../ActionDetails/ActionStatus';
import ActionType from '../ActionDetails/ActionType';

const ProspectActionList = () => {
  const prospect = useAppSelector(selectCurrentAdminProspect);
  if (!prospect)
    throw new Error()

  const [refresh, setRefresh] = useState<Date>();
  const [data, setData] = useState<IAction[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setloading] = useState<boolean>(false);

  const tableChange = useTableChange();
  const queryParams = useQueryParams()

  useEffect(() => {
    setloading(true)
    actionAdminApi.find({ query: { ...queryParams.query, prospectId: prospect.id }, page: Number(queryParams.page), pageSize: Number(queryParams.limit) })
      .then(res => {
        setData(res.rows)
        setCount(res.count)
        setloading(false)
      })
      .catch(() => {
        setData([])
        setCount(0)
        setloading(false)
      })
  }, [setData, setCount, setloading, queryParams, refresh, prospect])


  const deleteAction = (value: any) => {
    confirm({
      title: 'Voulez-vous supprimer l\'action ?',
      icon: <QuestionCircleOutlined />,
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okText: 'Oui',
      okButtonProps: { className: "btn" },
      onOk() {
        actionAdminApi.deleteAction(value.id as string)
          .then(_ => setRefresh(new Date()))
      },
      onCancel() {
      },
    });
  }

  const duplicateAction = (value: IAction) => {
    confirm({
      title: 'Voulez-vous dupliquer l\'action ?',
      icon: <QuestionCircleOutlined />,
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okText: 'Oui',
      okButtonProps: { className: "btn" },
      onOk() {
        actionAdminApi.create({
          actionDate: new Date(),
          comment: value.comment,
          prospectId: (value.prospect as any).id,
          contactsId: value.contactsId,
          type: value.type,
          status: value.status,
        })
          .then(res => {
            editAction()
            setActionId(res.id)
            return res
          })
      },
      onCancel() {
      },
    });
  }

  const getItems = (action: IAction): MenuProps['items'] => ([
    {
      label: "Dupliquer",
      key: 'clone',
      onClick: _ => duplicateAction(action)
    },
    {
      label: "Supprimer",
      key: 'delete',
      onClick: _ => deleteAction(action)
    }
  ])

  const columns: any = [
    {
      title: 'Actions',
      fixed: 'left',
      render: (value: any) => {
        return (
          <div className='flex space-x-4'>
            <Button
              type="link"
              onClick={(e) => {
                editAction(value)
              }}
              icon={<EditOutlined />}
            />
            <Dropdown menu={{ items: getItems(value) }}>
              <Button type="link" onClick={e => e.stopPropagation()} icon={<MoreOutlined />} />
            </Dropdown>
          </div>
        )
      }
    },
    {
      title: "Date de l'action",
      dataIndex: "actionDate",
      fixed: 'left',
      key: "actionDate",
      render: (value: any, item: any) => <DateField value={value} />
    },
    {
      title: "Contact(s)",
      dataIndex: 'contacts',
      key: "contacts",
      render: (value: IContact[]) =>
        (value || [])
          .map((v, i) => (<div key={i} className='mb-1'>
            {`${v.firstName} ${v.lastName}`}
          </div>)
          )
    },
    {
      title: "Type d'action",
      dataIndex: "type",
      key: "type",
      render: (_value: any, action: IAction) => <ActionType action={action} />
    },
    {
      title: "Statut de l'action",
      dataIndex: "status",
      key: "status",
      render: (_value: any, action: IAction) => <ActionStatus action={action} />
    },


  ];

  const [actionId, setActionId] = useState<string | undefined>();

  const dispatch = useAppDispatch()

  const editAction = (action?: IAction) => {
    dispatch(openDrawerAction({
      component: <ActionInfosForm formCb={_ => setRefresh(new Date())} action={action} prospectIdToEdit={prospect.id}></ActionInfosForm>,
      options: {
        title: actionId ? "Modifier l'action" : "Ajouter une action"
      }
    }))
  };

  // const editFileList = (action: IAction) => {
  //   dispatch(openDrawerAction({
  //     component: <ProspectFiles actionId={action.id} />,
  //     options: {
  //       title: "Liste des fichiers rattachés à l'action"
  //     }
  //   }))
  // };

  return (
    <div className='mt-4'>
      <Section
        mode='transparent'
        title="Liste des actions"
        actions={<Button onClick={_ => editAction()} icon={<PlusOutlined />} type="primary" >Nouvelle action</Button>}
      >

        {!loading && data.length > 0 ? (
          <Table<IAction>
            style={{ cursor: 'pointer' }}
            columns={columns}
            dataSource={data}
            loading={loading}
            onChange={tableChange}
            rowKey="id"
            size='small'
            pagination={{
              pageSize: 10,
              total: count
            }}
            expandable={{
              rowExpandable: (record) => record.comment !== undefined,
              expandedRowRender: (record) => <div>Commentaire : {record.comment}</div>,
              defaultExpandAllRows: true,
            }}
          />
        ) :
          <Info body='Aucune action pour le moment' />
        }

      </Section>
    </div>

  );

};

export default ProspectActionList;
import { IProspect, ProspectTypesEnum } from "@alcome-rep/alcome-types/dist/interfaces/crm"
import { Tag } from "antd"
import { useTranslation } from "react-i18next"

type PropsType = {
  prospect: IProspect,
}


const ProspectType = ({ prospect }: PropsType) => {
  const { t } = useTranslation();

  return <Tag className="space-x-2" color={t(`UI.TAG.ProspectTypesEnum.${prospect.prospectType}`)}>
    {t(`TYPES.CRM.PROSPECTS.ProspectTypesEnum.${ProspectTypesEnum[prospect.prospectType]}`)}
  </Tag>
}

export default ProspectType
import { IFileDocument } from '@alcome-rep/alcome-types/dist/interfaces';
import { CloseOutlined, EyeOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useRef, useState } from 'react';

type GenerateFileButtonType = {
  isLoading?: any,
  file?: IFileDocument | string,
  generateFile: () => void,
  deleteFile?: () => void,
  readOnly?: boolean,
  downloadFile?: () => Promise<Blob>,
}

const GenerateFileButton = ({ isLoading, file, generateFile, deleteFile, downloadFile, readOnly = false }: GenerateFileButtonType) => {

  const iFrame = useRef(null)
  const [visible, setVisible] = useState(false);

  const showDocument = async (documentFile: IFileDocument) => {
    if (downloadFile && documentFile !== undefined) {
      setVisible(true)
      const res = await downloadFile()
      // contractApi.default.getDocument(currentFileDocument.propertyName, `contrat-alcome-${currentFileDocument.timestamp}.pdf`)
      const blobContent = new Blob([res], { type: "application/pdf" });
      if (iFrame.current !== null) {
        (iFrame.current as HTMLIFrameElement).src = URL.createObjectURL(blobContent);
      }
    }
  }

  return <>
    {file && (
      <div >
        <div className='py-1 px-2 flex flex-col space-y-2 items-start'>
          <div className=''>
            {readOnly === false && generateFile && <Button
              onClick={generateFile}
              loading={isLoading}
              type="link"
              icon={<ReloadOutlined className='text-alc-yellow font-bold text-sm px-0 leading-4'></ReloadOutlined>}
              className={`p-0`}
              htmlType="button">
            </Button>}
            {downloadFile && <Button htmlType="button" onClick={_ => showDocument(file as IFileDocument)} type='link' icon={<EyeOutlined/>}>Voir le document</Button>}
          </div>
          <div className='flex space-x-2 text-xs'>
            {file && (<span>{format((file as IFileDocument).timestamp, "'Généré le 'd MMMM yyyy HH'h'mm'", { locale: fr })}</span>)}
            {!readOnly && deleteFile &&
              <Button
                className='p-0 border-none h-2'
                onClick={() => deleteFile()}
                icon={<CloseOutlined className='' />}>
              </Button>
            }
          </div>

        </div>
      </div>
    )}
    {!file && !readOnly && (
      <div>
        {generateFile &&
          <Button
            onClick={generateFile}
            loading={isLoading}
            type="primary"
            icon={<ReloadOutlined className='text-alc-yellow font-bold text leading-4'></ReloadOutlined>}
            className={`btn`}
            htmlType="button">
            Générer le fichier
          </Button>
        }
      </div>
    )}
    <Modal
      title="Aperçu"
      styles={{ body: { height: 800 } }}
      centered
      open={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      width={1000}
      footer={null}
    >
      <iframe title="frame" ref={iFrame} className="w-full h-full" ></iframe>
    </Modal>
  </>
}

export default GenerateFileButton
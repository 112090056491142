import { Prospect } from "@alcome-rep/alcome-types/dist/models";
import { Button, Form, Input, Select } from "antd";
import { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../common/hooks";
import { adminUpdateProspectAction } from "../../state/prospect-admin.slice";
import TextArea from "antd/es/input/TextArea";
import { InterestValueEnum } from "@alcome-rep/alcome-types/dist/interfaces/crm";

type PropTypes = {
  prospect: Prospect,
  formCb?: (data?: FormType) => void
}

type FormType = Partial<Prospect>

const ProspectInfosForm = ({ prospect, formCb }: PropTypes) => {

  const { t } = useTranslation();

  const dispatch: Dispatch<any> = useAppDispatch()

  const submit = (prospectData: FormType) => {
    dispatch(adminUpdateProspectAction({ id: prospect.id, data: prospectData }))
    formCb && formCb(prospectData)
  }

  return (
    <>
      <Form<FormType>
        name="basic"
        initialValues={prospect}
        layout='vertical'
        onFinish={submit}
      >
        <div>
          {/* <Form.Item
            label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.siren.label')}
            name="siren"
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.name.label')}
            name="name"
          >
            <Input></Input>
          </Form.Item> */}
          <Form.Item
            label="Intérêt du prospect"
            name="interestValue"
          >
            <Select>
              {Object.keys(InterestValueEnum)
                .filter(v => typeof v === "string")
                .map(v => (
                  <Select.Option key={v} value={v}>{t(`TYPES.CRM.PROSPECTS.InterestValueEnum.${v}`)}</Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.street.label')}
            name={['address', 'street']}
          >
            <Input></Input>
          </Form.Item>

          <Form.Item
            label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.city.label')}
            name={['address', 'city']}
          >
            <Input></Input>
          </Form.Item>

          <Form.Item
            label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.zip.label')}
            name={['address', 'zip']}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.email.label')}
            name="email"
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.phone.label')}
            name="phone"
          >
            <Input></Input>
          </Form.Item>

          <Form.Item
            label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.comment.label')}
            name="comment"
          >
            <TextArea></TextArea>
          </Form.Item>

          <div className="text-right">
            <Button htmlType="button" type="link" onClick={_ => formCb && formCb()} >{t('COMMON.cancel')}</Button>
            <Button htmlType="submit" type="primary" >{t('COMMON.save')}</Button>
          </div>
        </div>
      </Form>
    </>
  )
}

export default ProspectInfosForm
import { User } from "@alcome-rep/alcome-types/dist/models";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../common/hooks";
import { ROUTES } from "../../common/router";
import { getRoute } from "../../common/tools/router-tools";
import { selectAuth, selectIsRefreshing } from "../state/auth.slice";

const LoginGuard = ({ children }: any) => {
  const auth = useAppSelector(selectAuth)
  const isRefreshing = useAppSelector(selectIsRefreshing)
  const [initialAuth] = useState(auth)

  const navigate = useNavigate();

  useEffect(() => {
    if (auth && (!initialAuth || initialAuth.user !== auth.user)) {
      if (isRefreshing)
        return;
      if (auth && User.IsUserAdmin(auth.user))
        navigate(getRoute(ROUTES.ADMIN_USER_ROUTES.home))
      else if (auth && User.IsUserPortal(auth.user)) {
        navigate(getRoute(ROUTES.PORTAL_REGISTER_CONTRACT_ROUTES.start))
      } else if (auth && User.IsUserSupplier(auth.user)) {
        navigate(ROUTES.SUPPLIER_USER_ROUTES.home.path)
      }
    }
  }, [auth, initialAuth, isRefreshing, navigate])

  return <>{children}</>
}

export default LoginGuard
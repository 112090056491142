import { ISupportOrders, SupportOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces"
import { UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user"
import { Alert, Button, message } from "antd"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { selectUser } from "../../../../Auth/state/auth.slice"
import { useAppSelector } from "../../../../common/hooks"
import supportOrderAdminApi from "../../../services/admin-supportOrder.api"
import supportOrderApi from "../../../services/supportOrder.api"

const SupportOrderState = () => {

    const { supportOrderId }: any = useParams();

    const [supportOrder, setSupportOrder] = useState<ISupportOrders | undefined>();

    const user = useAppSelector(selectUser)
    const isAdmin = user!.userType === UserTypeEnum.admin

    useEffect(() => {
        supportOrderApi.getSupportOrder(supportOrderId)
            .then(res => {
                setSupportOrder(res)
            })
    }, [supportOrderId])


    const setSupportOrderStatus = async (status: SupportOrderStatusEnum) => {
        const updatedSupportOrder = await supportOrderAdminApi.updateSupportOrderSetCompleted(supportOrder!.id as string, status)
        if (updatedSupportOrder) {
            setSupportOrder(updatedSupportOrder)
            message.success('Mise à jour de la demande de soutien effectuée')
        }
    }

    if (!supportOrder) {
        return <></>
    }
    return (
        <div>
            {supportOrder.status === SupportOrderStatusEnum.support_order_declarations_approved && (
                <div>
                    <Alert message={<div>La demande de soutien est en attente d'approbation par Alcome.</div>} />
                    {
                        isAdmin && (
                            <Button type="primary" htmlType="button" className="btn mt-5" onClick={() => setSupportOrderStatus(SupportOrderStatusEnum.support_order_declarations_approved)}>
                                Approuver la demande de soutien
                            </Button>
                        )
                    }
                </div>
            )}
        </div>
    )
}

export default SupportOrderState
import { RouteObject } from "react-router-dom";
import AccountingExport from "../components/AccountingExport/AccountingExport";
import RevenueOrderExport from "../components/RevenueOrderExport/RevenueOrderExport";
import InvoicesExport from "../components/InvoicesExport/InvoicesExport";


const routePrefix = 'admin'
const routeDomain = 'accountingExport'

export const adminAccountingExportRouteNames = {
  accountingexportList: { path: `${routePrefix}/${routeDomain}`, title: `ROUTES.${routeDomain}.list` },
  accountingexportRevenueOrderList: { path: `${routePrefix}/${routeDomain}/revenueOrder`, title: `ROUTES.${routeDomain}.revenueOrder` },
  accountingexportInvoicesList: { path: `${routePrefix}/${routeDomain}/invoices`, title: `ROUTES.${routeDomain}.supplierInvoices` },
}

export const adminAccountingExportRoutes: RouteObject[] = [
  {
    path:adminAccountingExportRouteNames.accountingexportList.path.replace(`${routePrefix}/`, ''),
    element:<AccountingExport />,
    children: [
      {
        path:'revenueOrder',
        element: <RevenueOrderExport />
      },
      {
        path:'invoices',
        element: <InvoicesExport />
      },
    ]
  }

]

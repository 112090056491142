import { ApiListResult, Models } from "@alcome-rep/alcome-types";
import { IStructure, StructureStatsType } from "@alcome-rep/alcome-types/dist/interfaces";
import { AxiosResponse } from "axios";
import ApiService, { ApiSortType } from "../../common/services/apiService";
import { buildFindQuery, buildSortQuery } from "../../common/tools/global-tools";
import { defaultPagination } from "../../common/app-constants";
import { BaseCommune } from "@alcome-rep/alcome-types/dist/models";

const STRUCTURE_ADMIN_PATH_API = 'admin/structure';

class StructureAdminApi extends ApiService<IStructure> {
  constructor() {
    super(STRUCTURE_ADMIN_PATH_API)
  }

  /**
   * @description find all structures / find all structures with queryParams
   * @param query
   * @returns structure list
   */
  findAll({ query, page, pageSize }: { query: any, page: number, pageSize: number }) {
    return this.find({ query, page, pageSize })
  }

  /**
   * @description aggregation : retrieve allStructures with Contract
   * @param query
   * @returns structure with contract
   */
  getStructuresWithContract(query = {}, page = defaultPagination.page, limit = defaultPagination.pageSize, sort?: ApiSortType)
    : Promise<ApiListResult<BaseCommune>> {
    let queryString = buildFindQuery({ ...query, page, limit });
    if (sort) {
      queryString += buildSortQuery(sort)
    }
    return this.custom<ApiListResult<IStructure>>('get', `withContract?${queryString}`)
      .then(res => ({
        ...res,
        rows: res.rows.map(r => new Models.BaseCommune(r))
      }))
  }

  /**
   * @description get structure stats for admin users
   * @returns stats
   */
  getStructureAdminStats(): Promise<StructureStatsType> {
    return this.custom('get', 'adminStats')
  }

  /**
   * @description idk
   * @returns structures
   */
  getExportStructures(): Promise<AxiosResponse<any, any>> {
    return this.custom('get', 'exportStructures')
  }

  /**
   * @description get a structure
   * @param structureId 
   * @returns StructureDoc
   */
  getStructure = (structureId: string) => {
    return this.get(structureId)
      .then(s => new Models.BaseCommune(s))
  }

  /**
   * @description update a structure
   * @param structureId 
   * @param data 
   * @returns Updated structure
   */
  updateStructure(structureId: string, data: any) {
    return this.update(structureId, data)
  }

  /**
   * @description Delete a structure
   * @param structureId 
   * @returns Deleted structure
   */
  deleteStructure(structureId: string) {
    return this.delete(structureId)
  }

}

const structureAdminApi = new StructureAdminApi();
export default structureAdminApi;
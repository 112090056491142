import { ISupportOrders, SupportOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { Loader } from "@googlemaps/js-api-loader";
import { Alert, Button } from "antd";
import { useEffect, useState } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { selectUser } from "../../Auth/state/auth.slice";
import LoadingScreen from "../../common/components/LoadingScreen";
import Figure from "../../common/components/Panels/Figure";
import NavTabs from "../../common/components/Panels/NavTabs";
import PageCanvas from "../../common/components/Panels/PageCanvas";
import { useAppSelector } from "../../common/hooks";
import { ROUTES } from "../../common/router";
import SupportOrderStatusComponent from "../components/common/SupportOrderStatus/SupportOrderStatusComponent";
import supportOrderApi from "../services/supportOrder.api";
import { BaseCommune } from "@alcome-rep/alcome-types/dist/models";
import structureAdminApi from "../../Structure/services/structure-admin.api";
import { getRoute } from "../../common/tools/router-tools";

const SupportOrderLayout = () => {
  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLEMAP_KEY || '',
      libraries: ["drawing", "places"]
    });
    loader.libraries.forEach(l => loader.importLibrary(l))
    // loader.load()
  }, [])

  const { supportOrderId }: any = useParams();
  const [supportOrder, setSupportOrder] = useState<ISupportOrders | undefined>();
  const [structure, setStructure] = useState<BaseCommune | undefined>();

  const user = useAppSelector(selectUser)
  const isAdmin = user!.userType === UserTypeEnum.admin

  useEffect(() => {
    if (!supportOrderId)
      setSupportOrder({} as ISupportOrders)
    else
      supportOrderApi.getSupportOrder(supportOrderId)
        .then(res => {
          setSupportOrder(res)
        })
  }, [supportOrderId])

  useEffect(() => {
    if (!supportOrder || !isAdmin)
      return;

    structureAdminApi.getStructure(supportOrder.structureId)
      .then(s => setStructure(s))

  }, [supportOrder, isAdmin])

  const sendDeclaration = async () => {
    const updatedSupport = (await supportOrderApi.getSupportOrder(supportOrderId));
    setSupportOrder(updatedSupport)
    if (updatedSupport?.invoices?.length === 0) {
      alert("Vous devez ajouter au moins un document de facturation")
      return;
    }

    const cpQty = updatedSupport?.hotspots.reduce((acc: any, hs: any) => acc + hs.qty, 0);
    const cpFilled = updatedSupport?.collectPointCount;
    if (cpFilled !== cpQty) {
      alert("Vous devez renseigner tous les points de collecte")
      return;
    }

    if (updatedSupport?.extinguisherCount !== updatedSupport?.extinguishers!.length) {
      alert("Vous devez renseigner tous les éteignoirs")
      return;
    }
    supportOrderApi.sendDeclaration(supportOrderId)
      .then(r => setSupportOrder(r))
  }


  const tabsRoutes = isAdmin ?
    [
      { ...ROUTES.ADMIN_SUPPORT_ORDER_ROUTES.supportOrderDetails, params: { supportOrderId: supportOrder?.id } },
      { ...ROUTES.ADMIN_SUPPORT_ORDER_ROUTES.supportOrderInvoices, params: { supportOrderId: supportOrder?.id } },
      { ...ROUTES.ADMIN_SUPPORT_ORDER_ROUTES.supportOrderMaterialDeclaration, params: { supportOrderId: supportOrder?.id } },
      { ...ROUTES.ADMIN_SUPPORT_ORDER_ROUTES.supportOrderState, params: { supportOrderId: supportOrder?.id } },
    ]
    :
    [
      { ...ROUTES.PORTAL_SUPPORT_ORDER_ROUTES.supportOrderDetails, params: { supportOrderId: supportOrder?.id } },
      { ...ROUTES.PORTAL_SUPPORT_ORDER_ROUTES.supportOrderInvoices, params: { supportOrderId: supportOrder?.id } },
      { ...ROUTES.PORTAL_SUPPORT_ORDER_ROUTES.supportOrderMaterialDeclaration, params: { supportOrderId: supportOrder?.id } },
      { ...ROUTES.PORTAL_SUPPORT_ORDER_ROUTES.supportOrderState, params: { supportOrderId: supportOrder?.id } },
    ]

  if (!supportOrder)
    return <LoadingScreen />

  return (
    <>
      {!isAdmin && supportOrder.status === SupportOrderStatusEnum.support_order_declarations_completed && (
        <Alert banner message="Votre déclaration est en cours de validation par Alcome" />
      )}
      <PageCanvas title={`Demande numéro: ${supportOrder.supportOrderNumber}`}>

        <div className="grid grid-cols-4 gap-4 my-4">
          {isAdmin && <div className="col-span-2">
            <Figure value={structure?.name} title="Structure" actions={<Link to={getRoute(ROUTES.ADMIN_STRUCTURE_ROUTES.structureDetails, { structureId: structure?.id })}>voir</Link>} />
          </div>}
          {isAdmin && <Figure value={structure?.population} title="Population" />}
          <Figure value={<SupportOrderStatusComponent status={supportOrder.status} />} title="Statut" />

        </div>

        {supportOrder.status >= SupportOrderStatusEnum.support_order_declarations_pending && (
          <NavTabs tabRoutes={tabsRoutes} />
        )}

        <Outlet></Outlet>

        {!isAdmin && false && supportOrder?.status === SupportOrderStatusEnum.support_order_declarations_pending && (
          <Button
            htmlType="button"
            className="btn mt-8 rounded-lg"
            onClick={() => sendDeclaration()}>Envoyer votre déclaration
          </Button>
        )}
      </PageCanvas>
    </>
  )
}

export default SupportOrderLayout
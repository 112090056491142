import { Models } from '@alcome-rep/alcome-types';
import { AnnualReviewStatusEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import { Table } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import structureAdminApi from '../../../../Structure/services/structure-admin.api';
import FilterEntity from '../../../../common/components/Filters/FilterEntity';
import FilterInputNumber from '../../../../common/components/Filters/FilterInputNumber';
import FilterPanel from '../../../../common/components/Panels/FilterPanel';
import PageCanvas from '../../../../common/components/Panels/PageCanvas';
import QuickLinksPanel, { ResetLink } from '../../../../common/components/Panels/QuickLinksPanel';
import useQueryParams from '../../../../common/hooks/useQueryParams';
import useTableChange from '../../../../common/hooks/useTableChange';
import annualReviewAdminApi from '../../../services/annual-review-admin.api';
import { annualReviewListColumns } from './AdminAnnualReviewListComponent.constant';
import AnnualReviewListStatusFilter from './AdminAnnualReviewListStatusFilter';
import FilterDelegate from '../../../../common/components/Filters/FilterDelegate';
import { useAppSelector } from '../../../../common/hooks';
import { selectUser } from '../../../../Auth/state/auth.slice';

const AnnualReviewListComponent = () => {
  const [data, setData] = useState<Models.AnnualReview[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setloading] = useState<boolean>(false);
  const user = useAppSelector(selectUser);

  const tableChange = useTableChange();
  const queryParams = useQueryParams()

  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    setloading(true)
    annualReviewAdminApi.findAll(queryParams.query, Number(queryParams.page), Number(queryParams.limit), { [queryParams.sortField ?? 'name']: queryParams.sortOrder ?? 1 })
      .then(res => {
        setData(res.rows)
        setCount(res.count)
        setloading(false)
      })
      .catch(() => {
        setData([])
        setCount(0)
        setloading(false)
      })
  }, [setData, setCount, setloading, queryParams])

  const findStructure = (name: string) => {
    const query: any = {};
    if (isNaN(+name))
      query.name = '/' + name + '/'
    else
      query.siren = '/' + name + '/'
    return structureAdminApi.findAll({
      query,
      page: 1, pageSize: 5
    }).then(r => r.rows
      .map(p => ({
        label: p.name,
        value: String(p.id)
      }) as DefaultOptionType)
    )
  }

  const retrieveStructure = async (id: string) => {
    const structure = await structureAdminApi.getStructure(id)
    return { label: structure.name, value: String(structure.id) } as DefaultOptionType
  }

  return (
    <PageCanvas
      title={<span>Liste des bilans <small>({count ? count : 0})</small ></span >}
    >

      <FilterDelegate initialValue={user!.id} />
      <FilterPanel>
        <FilterEntity
          filterName='Structure'
          queryParamName='structureId'
          findFn={findStructure}
          retrieveFn={retrieveStructure}
        />
        <FilterEntity
          filterName='Statut'
          multiple={false}
          queryParamName='status'
          options={
            Object.keys(AnnualReviewStatusEnum)
              .filter((k): k is keyof typeof AnnualReviewStatusEnum => !isNaN(+k))
              .map(k => ({ label: t('TYPES.ANNUAL_REVIEW.AnnualReviewStatusEnum.' + AnnualReviewStatusEnum[k]), value: k }))
          }
        />
        <FilterInputNumber searchParamName='startDate' label='Année' />

        <div className='ml-auto col-span-3'>
          <ResetLink />
        </div>
      </FilterPanel>

      <QuickLinksPanel resetLink={null} icon={null}>
        <AnnualReviewListStatusFilter />
        {/* <div className='grid grid-cols-2 gap-2 w-full'>
          <div className='contents'>
            <FilterNavLink queryParams={`?status=${AnnualReviewStatusEnum.completed}`} label="Bilan à valider" />
            <FilterNavLink queryParams={`?status=${AnnualReviewStatusEnum.pending}`} label="Bilan en attente / refusés" />
          </div>
          <div className='contents'>
            <FilterNavLink queryParams={`?status=${AnnualReviewStatusEnum.statement_refused}`} label="Décomptes refusés" />
            <FilterNavLink queryParams={`?status=${AnnualReviewStatusEnum.statement_pending}`} label="Décomptes à valider" />
          </div>
          <div className='contents'>
            <FilterNavLink queryParams={`?status=${AnnualReviewStatusEnum.order_sent}`} label="Titre de recette à valider" />
            <FilterNavLink queryParams={`?status=${AnnualReviewStatusEnum.order_refuse}`} label="Titres de recette refusés" />
          </div>
        </div> */}
      </QuickLinksPanel >

      <div className=''>
        <Table<Models.AnnualReview>
          columns={annualReviewListColumns(queryParams.query, t, navigate)}
          dataSource={data}
          loading={loading}
          onChange={tableChange}
          size="small"
          rowKey="id"
          scroll={{ scrollToFirstRowOnChange: true, x: 1200 }}
          pagination={
            {
              hideOnSinglePage: true,
              current: queryParams.page,
              pageSize: queryParams.limit,
              total: count,
            }
          } />
      </div>
    </PageCanvas >

  );
};

export default AnnualReviewListComponent;
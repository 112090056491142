import { IProduct } from "@alcome-rep/alcome-types/dist/interfaces/product";
import ApiService from "../../common/services/apiService";
import { message } from "antd";
import { ShippingStep } from "@alcome-rep/alcome-types/dist/interfaces/supplier";

const PRODUCT_ADMIN_PATH_API = 'admin/product';

class ProductAdminApi extends ApiService<IProduct> {
  constructor() {
    super(PRODUCT_ADMIN_PATH_API)
  }

  adminCreateProduct(data: Partial<IProduct>) {
    return this.create(data)
  }

  updateProduct(productId: string, data: Partial<IProduct>) {
    return this.update(productId, data)
      .then(() => message.success('Produit mis à jour'))
      .catch(() => message.error('Erreur lors de la mise à jour du produit'))
  }

  uploadProductImages = (productId: string, data: any) => {
    const formData = new FormData();
    if (!data.enabled)
      data.enabled = false
    Object.keys(data).forEach(k => {
      if (k === 'file') {
        formData.append(k, data[k][0].originFileObj);
      }
    })
    return this.custom<IProduct>('put', `${productId}/files`, undefined, formData)
      .then(res => {
        message.success('Images envoyé')
        return res;
      })
  }

  updatePriceSteps(productId: string, data: { priceSteps: ShippingStep[] }) {
    return this.custom<IProduct>('put', ':productId/priceSteps', { productId }, data)
      .then(res => {
        message.success('Tarifs mis à jour')
        return res
      })
      .catch(err => {
        message.error('Erreur lors de la mise à jour')
        return err
      })
  }

  updateShippingSteps(productId: string, data: { shippingSteps: ShippingStep[] }) {
    return this.custom<IProduct>('put', ':productId/shippingSteps', { productId }, data)
      .then(res => {
        message.success('Tarifs mis à jour')
        return res
      })
      .catch(err => {
        message.error('Erreur lors de la mise à jour')
        return err
      })
  }


  deleteImg(productId: string, fileName: string) {
    return this.custom<IProduct>('put', `${productId}/image/${fileName}`)
  }

}

const productAdminApi = new ProductAdminApi();
export default productAdminApi;
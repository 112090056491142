import { createBrowserRouter } from "react-router-dom";
import { adminAccountingExportRouteNames, adminAccountingExportRoutes } from "../AccountingExport/routes/accountingExport-admin.routes";
import { adminAcquisitionOrderRouteNames, adminAcquisitionOrderRoutes } from "../AcquisitionOrder/routes/acquisition-order-admin.routes";
import { portalAcquisitionOrderRouteNames, portalAcquisitionOrderRoutes } from "../AcquisitionOrder/routes/acquisition-order-portal.routes";
import { adminAmendmentRouteNames, adminAmendmentRoutes } from "../Amendment/routes/amendment-admin.routes";
import { adminAnnualReviewRouteNames, adminAnnualReviewRoutes } from "../AnnualReview/routes/admin-annual-review.routes";
import { portalAnnualReviewRouteNames, portalAnnualReviewRoutes } from "../AnnualReview/routes/portal-annual-review.routes";
import AuthRoutes, { buildAuthRoutes } from "../Auth/routes";
import ContractFileGuard from "../Contract/guards/ContractGuard";
import NoContractFileGuard from "../Contract/guards/NoContractGuard";
import { adminContractRouteNames } from "../Contract/routes/admin-contract.routes";
import { PortalContractRoutes, PortalRegisterContractRoute, portalContractRouteNames, portalRegisterContractRouteNames } from "../Contract/routes/portal-contract.routes";
import { adminActionRouteNames, adminActionRoutes } from "../Crm/routes/action-admin.routes";
import { adminContactRouteNames, adminContactRoutes } from "../Crm/routes/contact-admin.routes";
import { adminProspectRouteNames, adminProspectRoutes } from "../Crm/routes/prospect-admin.routes";
import { adminDeclarationRouteNames, adminDeclarationRoutes } from "../Declaration/routes/declaration-admin.routes";
import { adminLibraryDocRouteNames, adminLibraryDocRoutes } from "../Kit/routes/libraryDoc-admin.routes";
import { portalLibraryDocRouteNames, portalLibraryDocRoutes } from "../Kit/routes/libraryDoc-portal.routes";
import { adminMemberRouteNames, adminMemberRoutes } from "../Member/routes/member-admin.routes";
import { adminOrderRouteNames, adminOrderRoutes } from "../Order/routes/order-admin.routes";
import { adminProductRouteNames, adminProductRoutes } from "../Product/routes/product-admin.routes";
import { adminStructureRouteNames, adminStructureRoutes } from "../Structure/routes/structure-admin.routes";
import { supplierCompanyRouteNames, supplierCompanyRoutes } from "../SupplierCompany/routes/admin-supplierCompany.routes";
import { adminSupplierOrderRouteNames, adminSupplierOrderRoutes } from "../SupplierOrder/routes/admin-supplierOrder.routes";
import { supplierOrderRouteNames, supplierOrderRoutes } from "../SupplierOrder/routes/supplierOrder.routes";
import { adminSupportOrderRouteNames, adminSupportOrderRoutes } from "../SupportOrder/routes/support-order-admin.routes";
import { portalSupportOrderRouteNames, portalSupportOrderRoutes } from "../SupportOrder/routes/support-order-portal.routes";
import AdminGuard from "../User/guards/AdminGuard";
import UserMemberGuard from "../User/guards/UserMemberGuard";
import UserPortalGuard from "../User/guards/UserPortalGuard";
import UserSupplierGuard from "../User/guards/UserSupplierGuard";
import { adminUserRouteNames, adminUserRoutes } from "../User/routes/user-admin.routes";
import { memberUserRouteNames, memberUserRoutes } from "../User/routes/user-member.routes";
import { userPortalRouteNames, userPortalRoutes } from "../User/routes/user-portal.routes";
import { supplierUserRouteNames, supplierUserRoutes } from "../User/routes/user-supplier.routes";
import ModalService from "../User/services/modal.service";
import ConfidentialPolicytStep from "./components/MiscPages/ConfidentialPolicy";
import CookiePolicyStep from "./components/MiscPages/CookiePolicy";
import RedirectGuard from "./guards/RedirectGuard";
import AdminLayout from "./layouts/AdminLayouts/AdminLayout";
import AppLayout from "./layouts/AppLayout/AppLayout";
import MemberLayout from "./layouts/MemberLayouts/MemberLayout";
import NotFound from "./layouts/NotFound/NotFound";
import PortalLayout from "./layouts/PortalLayouts/PortalLayout";
import SupplierLayout from "./layouts/SupplierLayouts/SupplierLayout";
import WelcomeLayout from "./layouts/WelcomeLayout/WelcomeLayout";

const MAIN_ROUTES = {
  HOME: '/',
  LOGIN: '/connect/login',
  ASK_PASSWORD: '/connect/ask-password',
  CHANGE_PASSWORD: '/connect/change-password',
  REGISTER: '/register',
  DASHBOARD: '/portal',
}

const REGISTER_ROUTES = buildAuthRoutes();

const PORTAL_REGISTER_CONTRACT_ROUTES = portalRegisterContractRouteNames;
const PORTAL_CONTRACT_ROUTES = portalContractRouteNames;
const PORTAL_ANNUALREVIEW_ROUTES = portalAnnualReviewRouteNames;
const PORTAL_LIBRARYDOC_ROUTES = portalLibraryDocRouteNames;
const PORTAL_SUPPORT_ORDER_ROUTES = portalSupportOrderRouteNames;
const PORTAL_ACQUISITION_ORDER_ROUTES = portalAcquisitionOrderRouteNames;

const ADMIN_PROSPECT_ROUTES = adminProspectRouteNames;
const ADMIN_ACTION_ROUTES = adminActionRouteNames;
const ADMIN_CONTACT_ROUTES = adminContactRouteNames;

const ADMIN_STRUCTURE_ROUTES = adminStructureRouteNames;
const ADMIN_CONTRACT_ROUTES = adminContractRouteNames;
const ADMIN_ANNUALREVIEW_ROUTES = adminAnnualReviewRouteNames;
const ADMIN_USER_ROUTES = adminUserRouteNames;
const ADMIN_ORDER_ROUTES = adminOrderRouteNames;
const ADMIN_MEMBER_ROUTES = adminMemberRouteNames;
const ADMIN_DECLARATION_ROUTES = adminDeclarationRouteNames;
const ADMIN_LIBRARYDOC_ROUTES = adminLibraryDocRouteNames;
const ADMIN_ADMENDMENT_ROUTES = adminAmendmentRouteNames;
const ADMIN_ACCOUNTING_EXPORT_ROUTES = adminAccountingExportRouteNames;

const ADMIN_SUPPLIER_COMPANY_ROUTES = supplierCompanyRouteNames;
const ADMIN_SUPPLIER_ORDER_ROUTES = adminSupplierOrderRouteNames;

const ADMIN_PRODUCT_ROUTES = adminProductRouteNames;

const ADMIN_SUPPORT_ORDER_ROUTES = adminSupportOrderRouteNames;
const ADMIN_ACQUISITION_ORDER_ROUTES = adminAcquisitionOrderRouteNames;

const PORTAL_USER_ROUTES = userPortalRouteNames;

const SUPPLIER_USER_ROUTES = supplierUserRouteNames;
const SUPPLIER_ORDER_ROUTES = supplierOrderRouteNames;

const MEMBER_USER_ROUTES = memberUserRouteNames;


export const ROUTES = {
  ...MAIN_ROUTES,
  REGISTER_ROUTES,

  PORTAL_REGISTER_CONTRACT_ROUTES,

  PORTAL_USER_ROUTES,
  PORTAL_CONTRACT_ROUTES,
  PORTAL_ANNUALREVIEW_ROUTES,
  PORTAL_LIBRARYDOC_ROUTES,
  PORTAL_SUPPORT_ORDER_ROUTES,
  PORTAL_ACQUISITION_ORDER_ROUTES,

  ADMIN_CRM: {
    ADMIN_PROSPECT_ROUTES,
    ADMIN_ACTION_ROUTES,
    ADMIN_CONTACT_ROUTES
  },

  ADMIN_STRUCTURE_ROUTES,
  ADMIN_CONTRACT_ROUTES,
  ADMIN_ANNUALREVIEW_ROUTES,
  ADMIN_USER_ROUTES,
  ADMIN_ORDER_ROUTES,
  ADMIN_MEMBER_ROUTES,
  ADMIN_DECLARATION_ROUTES,
  ADMIN_LIBRARYDOC_ROUTES,
  ADMIN_ADMENDMENT_ROUTES,
  ADMIN_ACCOUNTING_EXPORT_ROUTES,

  ADMIN_SUPPLIER_COMPANY_ROUTES,
  ADMIN_SUPPLIER_ORDER_ROUTES,

  ADMIN_PRODUCT_ROUTES,
  ADMIN_SUPPORT_ORDER_ROUTES,
  ADMIN_ACQUISITION_ORDER_ROUTES,

  SUPPLIER_USER_ROUTES,
  SUPPLIER_ORDER_ROUTES,

  MEMBER_USER_ROUTES

}

console.log(ROUTES)

export default createBrowserRouter([
  {
    path: "/",
    element: <>
      <AppLayout />
      <ModalService />
    </>
    ,
    errorElement: <NotFound />,
    children: [
      {
        path: '',
        // element: <Navigate to={ROUTES.LOGIN}></Navigate>,
        element: <RedirectGuard />,
      },
      {
        path: "",
        children: [...AuthRoutes]
      },
      {
        path: "portal",
        // Need layout in next line
        element: <UserPortalGuard />,
        children: [
          {
            path: '',
            element: <ContractFileGuard><PortalLayout /></ContractFileGuard>,
            children: [
              ...userPortalRoutes,
              ...PortalContractRoutes,
              ...portalAnnualReviewRoutes,
              ...portalLibraryDocRoutes,
              ...portalSupportOrderRoutes,
              ...portalAcquisitionOrderRoutes
            ]
          },
          {
            path: 'start-contract',
            element: <NoContractFileGuard />,
            children: [...PortalRegisterContractRoute],
          },
          // {
          //   path: "",
          //   element: <ContractFileGuard></ContractFileGuard>,
          //   children: [
          //     ...PortalContractRoutes,
          //     {
          //       path: "annualReview",
          //       children: [
          //         ...portalAnnualReviewRoutes
          //       ]
          //     }
          //   ]
          // },
          // {
          //   path: "annualReview",
          //   element: <ContractFileGuard>
          //     {/* <AnnualReviewPortalList /> */}
          //     </ContractFileGuard>,
          //   children: [
          //     ...portalAnnualReviewRoutes
          //   ]
          // }
        ]
      },
      {
        path: "admin",
        element: <AdminGuard><AdminLayout /></AdminGuard>,
        children: [
          // ...adminDashboardRoutes,
          ...adminUserRoutes,

          ...adminProspectRoutes,
          ...adminActionRoutes,
          ...adminContactRoutes,

          // ...adminUserRoutes,
          ...adminStructureRoutes,
          ...adminAnnualReviewRoutes,
          ...adminOrderRoutes,

          ...adminMemberRoutes,
          ...adminDeclarationRoutes,

          ...adminLibraryDocRoutes,
          ...adminAmendmentRoutes,
          ...adminLibraryDocRoutes,
          ...adminAccountingExportRoutes,

          ...supplierCompanyRoutes,
          ...adminProductRoutes,
          ...adminSupportOrderRoutes,
          ...adminSupplierOrderRoutes,
          ...adminAcquisitionOrderRoutes
        ]
      },
      {
        path: "supplier",
        element: <UserSupplierGuard><SupplierLayout /></UserSupplierGuard>,
        children: [
          ...supplierUserRoutes,
          ...supplierOrderRoutes
        ]
      },
      {
        path: "member",
        element: <UserMemberGuard><MemberLayout /></UserMemberGuard>,
        children: [
          ...memberUserRoutes
        ]
      },
      {
        path: 'policy',
        element: <WelcomeLayout></WelcomeLayout>,
        children: [
          { path: 'confidential', element: <ConfidentialPolicytStep /> },
          { path: 'cookies', element: <CookiePolicyStep /> },
        ]
      }
    ]
  }

]);
import { Spin } from "antd";

type Props = {
  fullVPHeight?: boolean;
  isGlobal?: boolean;
};

const LoadingScreen = ({ fullVPHeight, isGlobal }: Props) => {

  return <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: fullVPHeight ? '100vh' : '100%',
    }}
  >
    <Spin />
  </div>
};

export default LoadingScreen;

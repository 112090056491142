import { IFileDocument } from "@alcome-rep/alcome-types/dist/interfaces";
import { buildFindQuery, buildSortQuery, getExtensionFromMimeType } from "../tools/global-tools";
import apiClient from "./apiClient";
import { trackProgress } from "./trackProgress";
import { ApiListResult } from "@alcome-rep/alcome-types";

export const getTrackProgressAreas = (key: string) => ({
  find: `GET_${key}`,
  get: `GET_${key}:id`,
  update: `PUT_${key}:id`,
  create: `POST_${key}`,
  delete: `DELETE_${key}:id`,
  custom: (method: string) => `${method.toUpperCase()}_custom_${key}:id`,
  fileUpload: (propertyName: string) => `UPLOAD_${propertyName}`,
})

export type ApiSortType = { [K: string]: 1 | -1 };

export type FindQueryParams<T> = { 
  page?: number, 
  pageSize?: number, 
  sort?: ApiSortType, 
  query?: Partial<T> 
}

class ApiService<K, UploadableProps = undefined | keyof K> {

  public trackProgressAreas = getTrackProgressAreas(this.path)

  constructor(public path: string) {
    if (path[0] !== '/')
      this.path = '/' + path;
    if (path[path.length - 1] !== '/')
      this.path += '/';
  }

  find = ({ query = {}, page = 1, pageSize = 10, sort }: FindQueryParams<K>): Promise<ApiListResult<K>> => {
    const myQuery = { ...query }

    let queryString = buildFindQuery(myQuery);
    if (sort) {
      queryString += buildSortQuery(sort)
    }
    return trackProgress(
      apiClient.get<ApiListResult<K>>(`${this.path}?${queryString}&page=${page}&limit=${pageSize}`),
      this.trackProgressAreas.find
    ).then(r => r.data);
  }

  get = (id: string): Promise<K> => {
    return trackProgress(
      apiClient.get<K>(`${this.path}${id}`),
      this.trackProgressAreas.get
    ).then(r => r.data)
  }

  create = (data: Partial<K>) => {
    return trackProgress(
      apiClient.post<K>(`${this.path}`, data),
      this.trackProgressAreas.create
    ).then(r => r.data)
  }

  update = (id: string, data: Partial<K>) => {
    return trackProgress(
      apiClient.put<K>(`${this.path}${id}`, data),
      this.trackProgressAreas.update
    ).then(response => {
      return response.data
    })
  }

  delete = (id: string, data?: Partial<K>) => {
    return trackProgress(
      apiClient.delete<K>(`${this.path}${id}`, data),
      this.trackProgressAreas.delete
    ).then(response => {
      return response.data
    })
  }

  custom = <R>(
    method: 'get' | 'put' | 'post' | 'put' | 'delete' = 'get',
    path: string,
    params?: any,
    data?: Partial<K> | any
  ) => {
    const paramNames = path.match(/:\w+/g);
    let query = path;
    paramNames?.forEach(paramName => query = query.replace(paramName, params[paramName.replace(':', '')]))

    const call = ['put', 'post'].indexOf(method) >= 0 ?
      apiClient[method]<R>(`${this.path}${query}`, data) :
      apiClient[method]<R>(`${this.path}${query}`)

    return trackProgress(
      call.then(response => {
        return response.data
      }),
      this.trackProgressAreas.custom(method)
    )
  }

  uploadFileDoc = <T>(propertyName: UploadableProps, file: File, entityId = "", query?: string) => {
    const formData = new FormData();
    formData.append('file', file);
    return trackProgress(
      apiClient.post<T>(
        `${this.path}${entityId ? entityId + '/' : ''}fileDoc/${propertyName}?${query}`,
        formData,
      ),
      this.trackProgressAreas.fileUpload(String(propertyName))
    ).then(response => {
      return response.data
    })
    // return this.custom<T>('post', `${entityId ? entityId + '/' : ''}fileDoc/${propertyName}`, undefined, { file })
  }

  getFileDoc = (propertyName: UploadableProps, entityId = "") => {
    return apiClient({
      url: `${this.path}${entityId ? entityId + '/' : ''}fileDoc/${propertyName}`,
      method: 'get',
      responseType: 'blob'
    }).then(r => r.data)
  }

  downloadFileDoc = (propertyName: UploadableProps, entityId = "", fileName?: string) => {
    return trackProgress(
      apiClient({
        url: `${this.path}${entityId ? entityId + '/' : ''}fileDoc/${propertyName}`,
        method: 'get',
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data as any]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', String(fileName || propertyName)); //or any other extension
        document.body.appendChild(link);
        link.click();
      }),
      this.trackProgressAreas.get
    )
  }

  downloadArrayFileDoc = (entityId: string, propertyName: string, subPropertyName: string, file: string, fileName?: string) => {
    return trackProgress(
      apiClient({
        url: `${this.path}${entityId}/downloadArrayFile/${propertyName}/${subPropertyName}/${file}`,
        method: 'get',
        responseType: 'blob'
      }).then((response) => {
        const ext = getExtensionFromMimeType(response.data.type)
        const url = window.URL.createObjectURL(new Blob([response.data as any]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${propertyName}_${subPropertyName}.${ext}`); //or any other extension
        document.body.appendChild(link);
        link.click();
      }),
      this.trackProgressAreas.get
    )
  }

  updateFileDoc = <T>(propertyName: UploadableProps, data: Partial<IFileDocument>, entityId = ""): Promise<T> => {
    return this.custom<T>('put', `${entityId ? entityId + '/' : ''}fileDoc/${propertyName}`, undefined, data)
  }

  deleteFileDoc = (propertyName: UploadableProps, entityId = "") => {
    return this.delete(`${entityId ? entityId + '/' : ''}fileDoc/${propertyName}`)
  }

}

export default ApiService;
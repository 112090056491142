import { Button, Drawer, Modal } from "antd";
import Section from "../../../common/components/Panels/Section";
import { format, sub } from "date-fns";
import { fr } from "date-fns/locale";
import { numericFormatter } from "react-number-format";
import { AccountingTypeEnum, AnnualReviewExtractInfoType, IAccountingExport } from "@alcome-rep/alcome-types/dist/interfaces";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import InfosComponent from "../InfosComponent/InfosComponent";
import { useEffect, useState } from "react";
import accountingExportAdminApi from "../../services/admin-accountingExportApi";
import annualReviewAdminApi from "../../../AnnualReview/services/annual-review-admin.api";
import LoadingScreen from "../../../common/components/LoadingScreen";
import HeaderSectionExport from "../../HeaderSectionExport/HeaderSectionExport";
import { findNotExportedRevenueOrderType } from "../AccountingExport/AccountingExport";

const RevenueOrderExport = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [selectedExport, setSelectedExport] = useState<IAccountingExport | undefined>();

    const [refresh, setRefresh] = useState<Date>(new Date())
    const [period, setPeriod] = useState<string>()
    const [listAcExports, setListAcExports] = useState<IAccountingExport[]>([]);
    const [statements, setStatements] = useState<findNotExportedRevenueOrderType[]>([]);
    const [statementsSelected, setStatementsSelected] = useState<any>([]);

    useEffect(() => {
        setLoading(true);
        accountingExportAdminApi.accountingList({ accountingType: AccountingTypeEnum.revenueOrder }).then(res => {
            setLoading(false);
            setListAcExports((res as any).rows);
        });
    }, [setListAcExports, setLoading, refresh]);

    const fetchStatementsToExport = async () => {
        if (!period) {
            await Modal.warning({
                title: "Données manquantes",
                content: `Vous devez sélectionner une période comptable à extraire.`
            });

            return;
        }

        const [year, month] = period?.split('-').map(e => +e as number)
        setLoading(true);
        const res = await annualReviewAdminApi.findStatementsToExport(year, month)
        setStatements(res);
        setStatementsSelected(res.map(s => s._id));
        setLoading(false);
    }

    const generateExport = async () => {
        if (statementsSelected?.length === 0) {
            await Modal.warning({
                title: "Données manquantes",
                content: `Vous devez sélectioner au moins un titre de recette pour réaliser l'export.`
            });

            return;
        }
        if (!period)
            return;

        await Modal.confirm({
            cancelText: 'Annuler',
            closable: true,
            okCancel: true,
            title: "Confirmation",
            content: `Etes-vous sûr de vouloir continuer l'extraction ? Cette opération est irréversible.`,
            async onOk() {
                let dataExportedResult = await accountingExportAdminApi.createAccountingExport({ period: period.replace('-', ""), listId: statementsSelected, accountingType: AccountingTypeEnum.revenueOrder })

                setStatements([]);
                setStatementsSelected([]);

                if (dataExportedResult.accountingResume.errors.length) {
                    await Modal.warning({
                        title: "Fichier incomplet",
                        content: `Nous n'avons pas pu exporter tous les titres de recette. Veuillez vérifier le fichier.`
                    });
                }
                else {
                    await Modal.success({
                        title: "Exporté",
                        content: `L'export est maintenant terminé. Vous pouvez télécharger le fichier.`
                    });
                }
                setRefresh(new Date());
            }
        });
    }

    const downloadAccountingFile = (filePath: string) => {
        return accountingExportAdminApi.downloadAccountingExportFile(filePath)
    }

    const toggleSelection = (statementId: string) => {
        const newSelection = [...statementsSelected];
        const foundIndex = newSelection.indexOf(statementId);
        if (foundIndex >= 0)
            newSelection.splice(foundIndex, 1)
        else
            newSelection.push(statementId)
        setStatementsSelected(newSelection)
    }

    if (loading) {
        return <LoadingScreen />
    }

    return (
        <>
            <HeaderSectionExport entity="revenueOrder" fetchStatementsToExport={fetchStatementsToExport} setPeriod={setPeriod} />

            {statements.length > 0 &&
                <Section title={`Liste des titres de recette trouvés pour la période de ${(format(new Date(`${period}-01`), 'MMMM yyyy', { locale: fr })).toLocaleUpperCase()}`}>
                    <table style={{ marginTop: "20px" }} className="arTable">
                        <thead>
                            <tr>
                                <th>Exporter</th>
                                <th>Nom</th>
                                <th>Année</th>
                                <th>Date du titre</th>
                                <th>Date de comptabilisation</th>
                                <th>Montant</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                period && statements.map((item: findNotExportedRevenueOrderType) => {
                                    const statementPeriod = new Date(item.revenueOrder.revenueOrderDate!).toISOString().match(/\d{4}-\d{2}/);
                                    const comptabilisationDate = statementPeriod![0] === period ? item.revenueOrder.revenueOrderDate! : new Date(+period.slice(0, 4), +period.slice(5, 7) - 1, 1)
                                    // const comptabilisationDate = statementPeriod![0].replace('-', '') === period ? item.revenueOrder.revenueOrderDate! : new Date(+period.slice(0, 4), +period.slice(5, 7) - 1, 1)

                                    const subOd = sub(new Date(item.revenueOrder.revenueOrderDate!), { minutes: 1 });
                                    const subCd = sub(new Date(comptabilisationDate), { minutes: 1 });

                                    return (
                                        <tr key={item._id}>
                                            <td><input type="checkbox" name="revenueOrderSelected" defaultChecked={true} onChange={_ => toggleSelection(item._id)} /></td>
                                            <td>{item.communes[0].name}</td>
                                            {/* <td>{item.structure.name}</td> */}
                                            <td>{item.revenueOrder.year}</td>
                                            <td>{format(new Date(subOd), 'dd/MM/yyyy', { locale: fr })}</td>
                                            <td>{format(new Date(subCd), 'dd/MM/yyyy', { locale: fr })}</td>
                                            <td>{numericFormatter((item.revenueOrder.amount!).toFixed(2), { thousandSeparator: ' ', decimalScale: 2 })} &euro;</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                    <div style={{ marginTop: "20px" }}><Button className="btn rounded-lg items-center" onClick={() => generateExport()}>Générer le fichier</Button></div>
                </Section>
            }

            <Section title="Historique des extractions">
                {/* <h4 className="section-title mb-4">Historique des extractions</h4> */}
                <table style={{ marginTop: "20px" }} className="arTable">
                    <thead>
                        <tr>
                            <th>Date d'extraction</th>
                            <th>Période</th>
                            <th>Nb communes</th>
                            <th>Nb erreurs</th>
                            <th>Montant</th>
                            <th>#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            listAcExports.map((item: IAccountingExport) => {
                                return (
                                    <tr key={item.id}>
                                        <td>{format(new Date((item as any).createdAt), 'dd/MM/yyyy', { locale: fr })}</td>
                                        <td>{item.period}</td>
                                        <td>{item.accountingResume?.annualReviews?.length}</td>
                                        <td>{item.accountingResume?.errors?.length} </td>
                                        <td>{numericFormatter((item.accountingResume.totalAmount).toFixed(2), { thousandSeparator: ' ', decimalScale: 2 })} &euro;</td>
                                        <td>
                                            <Button icon={<EyeOutlined />} className="btn-link" onClick={() => setSelectedExport(item)}></Button>
                                            <Button icon={<DownloadOutlined />} className="btn-link" onClick={() => downloadAccountingFile(item.exportedFilePath as string)}></Button>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </Section>

            <Drawer
                width={'40%'}
                title="Résumé"
                onClose={_ => setSelectedExport(undefined)}
                open={selectedExport !== undefined}
            >
                {selectedExport && <>
                    <h5 className="my-2">Informations</h5>
                    <dl className="grid grid-cols-2">
                        <dt>Créé le</dt>
                        <dd>{format(new Date((selectedExport as any).createdAt), 'dd/MM/yyyy', { locale: fr })}</dd>
                        <dt>Période</dt>
                        <dd>{selectedExport.period}</dd>
                        <dt>Montant</dt>
                        <dd>{numericFormatter((selectedExport.accountingResume.totalAmount).toFixed(2), { thousandSeparator: ' ', decimalScale: 2 })} &euro;</dd>
                    </dl>

                    <h5 className="my-2">Décomptes exportés</h5>
                    {selectedExport.accountingResume.annualReviews && selectedExport.accountingResume.annualReviews.length ?
                        <InfosComponent infos={selectedExport.accountingResume.annualReviews} accountingType={AccountingTypeEnum.revenueOrder} />
                        : <p>Aucun décompte exporté</p>}

                    <h5 className="my-2">Décomptes en erreur (non exportés)</h5>
                    {selectedExport.accountingResume.errors.length ?
                        <InfosComponent infos={selectedExport.accountingResume.errors as AnnualReviewExtractInfoType[]} accountingType={AccountingTypeEnum.revenueOrder} />
                        : <p>Aucun décompte en erreur</p>}

                </>}
            </Drawer>
        </>
    )
}

export default RevenueOrderExport
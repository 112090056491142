import { IFileDocument, ISupportOrders, SupportOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { RcFile } from "antd/lib/upload";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { selectUser } from "../../../../Auth/state/auth.slice";
import Section from "../../../../common/components/Panels/Section";
import UserFileFields from "../../../../common/components/portal/UserFileFields/UserFileFields";
import { useAppSelector } from "../../../../common/hooks";
import supportOrderApi from "../../../services/supportOrder.api";
import { ISupplierFileDocument } from "@alcome-rep/alcome-types/dist/interfaces/supplier/supplierFileDocument";
import msg from "../../../../common/services/toast";

const SupportOrderInvoices = () => {

  const [supportOrder, setSupportOrder] = useState<ISupportOrders | undefined>();
  const { supportOrderId }: any = useParams();

  const user = useAppSelector(selectUser)
  const isAdmin = user!.userType === UserTypeEnum.admin

  useEffect(() => {
    if (supportOrderId)
      supportOrderApi.getSupportOrder(supportOrderId)
        .then(res => {
          console.log(res)
          setSupportOrder(res)
        })
  }, [supportOrderId])

  const getFile = async (fileDoc: IFileDocument | ISupplierFileDocument): Promise<any> => {
    if (supportOrder) {
      return supportOrderApi.getFileDocument(fileDoc.propertyName as any, supportOrder.id as string)
    }
  }

  const deleteFile = (fileDoc: IFileDocument | ISupplierFileDocument): any => {
    if (supportOrder) {
      return supportOrderApi.deleteFileDocument(supportOrder.id as string, fileDoc.propertyName, fileDoc.timestamp)
        .then(supportOrder => setSupportOrder(supportOrder))
    }
  }

  const uploadFile = async (file: RcFile) => {
    try {
      const updatedSupportOrder = await supportOrderApi.supportOrderUploadDocument(supportOrderId, 'invoices', file)
      setSupportOrder(updatedSupportOrder)
    } catch {
      msg.error("Seuls les fichiers au format pdf sont acceptés.")
    }

  }

  if (!supportOrder)
    return <></>

  return (
    <Section title="Téléverser les factures">
      {!isAdmin && <div className="mt-5">
        Téléverser toutes les factures justifiants de l'achat de dispositifs de rue commandés lié à votre demande de soutien. Pour ajouter une facture il vous suffit de cliquer sur le bouton "Glissez-déposez". Seul les fichiers PDF sont autorisés.
      </div>
      }
      {isAdmin && <div>
        Retrouvez ici les factures déposées par la structure
      </div>
      }
      <div className="mt-5">
        <UserFileFields
          canEdit={!isAdmin && supportOrder.status === SupportOrderStatusEnum.support_order_declarations_pending}
          download={getFile}
          fileDocument={supportOrder.invoices as IFileDocument[]}
          propertyName="invoices"
          upload={uploadFile}
          deleteFile={deleteFile}
        />
      </div>
    </Section>
  )
}

export default SupportOrderInvoices
import { IAnnualReview } from '@alcome-rep/alcome-types/dist/interfaces';
import { AnnualReview } from '@alcome-rep/alcome-types/dist/models';
import { call, put, takeLatest } from 'redux-saga/effects';
import createEntityDucksFactory from '../../common/state/ducks.factory';
import annualReviewAdminApi from '../services/annual-review-admin.api';
import { adminAcceptOrderActions, adminGenerateStatementDocumentActions, adminGetAnnualReviewAction, adminRefuseOrderActions, adminRefuseStatusActions, adminSetCurrentAnnualReviewAction, adminSetStatementActions, adminSetStatementPaidActions, adminUpdateAnnualReviewAction, adminUpdateRevenueOrderFileActions, adminUploadDocumentActions, adminValidStatusActions, adminValidateStatementActions, generateAnnualReviewDocumentActions } from './annual-review-admin.slice';

/**
 * SAGAS
 */

// function* getAnnualReview({ payload }: ReturnType<typeof adminGetAnnualReviewAction>) {
//   try {
//     const resp: AnnualReview = yield call(annualReviewAdminApi.getAnnualReview, payload.id);

//     yield put(setCurrentAnnualReviewAction(resp));
//   } catch {
//     yield put(unsetCurrentAnnualReviewAction());
//   }
// }

// function* updateAnnualReview({ payload }: ReturnType<typeof adminUpdateAnnualReviewAction>) {
//   try {
//     const resp: AnnualReview = yield call(annualReviewAdminApi.updateAnnualReview, payload.id, payload.data);

//     yield put(setCurrentAnnualReviewAction(resp));
//   } catch {
//     yield put(unsetCurrentAnnualReviewAction());
//   }
// }

function* adminUpload({ payload }: ReturnType<typeof adminUploadDocumentActions>) {
  try {
    const resp: AnnualReview = yield call(annualReviewAdminApi.uploadAnnualReviewFile, payload);

    if (
      payload.propertyName === 'annualReviewFileId' ||
      payload.propertyName === 'revenueOrderFileId'

    ) {
      yield put(adminSetCurrentAnnualReviewAction(resp));
    }
  } catch {
    yield put(adminSetCurrentAnnualReviewAction(null));
  }
}

function* adminValidStatus({ payload }: ReturnType<typeof adminValidStatusActions>) {
  try {
    const resp: IAnnualReview = yield call(annualReviewAdminApi.adminValidateStatus, { annualReviewId: payload.annualReviewId });

    yield put(adminSetCurrentAnnualReviewAction(resp));
  } catch {
    yield put(adminSetCurrentAnnualReviewAction(null));
  }
}

function* adminRefuseStatus({ payload }: ReturnType<typeof adminRefuseStatusActions>) {
  try {
    const resp: IAnnualReview = yield call(annualReviewAdminApi.adminRefuseStatus, { annualReviewId: payload.annualReviewId, comment: payload.comment });

    yield put(adminSetCurrentAnnualReviewAction(resp));
  } catch {
    yield put(adminSetCurrentAnnualReviewAction(null));
  }
}

function* generateAnnualReview({ payload }: ReturnType<typeof generateAnnualReviewDocumentActions>) {
  try {
    const resp: IAnnualReview = yield call(annualReviewAdminApi.generateAnnualReview, payload);

    yield put(adminSetCurrentAnnualReviewAction(resp));
  } catch {
    yield put(adminSetCurrentAnnualReviewAction(null));
  }
}

function* adminSetStatement({ payload }: ReturnType<typeof adminSetStatementActions>) {
  try {
    const resp: IAnnualReview = yield call(annualReviewAdminApi.setStatement, { annualReviewId: payload.annualReviewId });

    yield put(adminSetCurrentAnnualReviewAction(resp));
  } catch {
    yield put(adminSetCurrentAnnualReviewAction(null));
  }
}

function* adminGenerateStatementDocument({ payload }: ReturnType<typeof adminGenerateStatementDocumentActions>) {
  try {
    const resp: IAnnualReview = yield call(annualReviewAdminApi.generateStatementDocument, { annualReviewId: payload.annualReviewId });

    yield put(adminSetCurrentAnnualReviewAction(resp));
  } catch {
    yield put(adminSetCurrentAnnualReviewAction(null));
  }
}

function* adminValidateStatement({ payload }: ReturnType<typeof adminValidateStatementActions>) {
  try {
    const resp: IAnnualReview = yield call(annualReviewAdminApi.adminValidateStatement, { annualReviewId: payload.annualReviewId });

    yield put(adminSetCurrentAnnualReviewAction(resp));
  } catch {
    yield put(adminSetCurrentAnnualReviewAction(null));
  }
}

function* acceptOrder({ payload }: ReturnType<typeof adminAcceptOrderActions>) {
  try {
    const resp: AnnualReview = yield call(annualReviewAdminApi.adminAcceptOrder, { annualReviewId: payload.annualReviewId });
    yield put(adminSetCurrentAnnualReviewAction(resp));
  } catch {
    yield put(adminSetCurrentAnnualReviewAction(null));
  }
}

function* refuseOrder({ payload }: ReturnType<typeof adminRefuseOrderActions>) {
  try {
    const resp: AnnualReview = yield call(annualReviewAdminApi.adminRefuseOrder, { annualReviewId: payload.annualReviewId, revenueOrderComment: payload.revenueOrderComment });
    yield put(adminSetCurrentAnnualReviewAction(resp));
  } catch {
    yield put(adminSetCurrentAnnualReviewAction(null));
  }
}

function* adminUpdateRevenueOrderFile({ payload }: ReturnType<typeof adminUpdateRevenueOrderFileActions>) {
  try {
    const resp: AnnualReview = yield call(annualReviewAdminApi.adminUpdateFileStatus, { annualReviewId: payload.annualReviewId, propertyName: payload.propertyName, data: payload.data });
    yield put(adminSetCurrentAnnualReviewAction(resp));
  } catch {
    yield put(adminSetCurrentAnnualReviewAction(null));
  }
}

function* adminSetStatementPaid({ payload }: ReturnType<typeof adminSetStatementPaidActions>) {
  try {
    const resp: AnnualReview = yield call(annualReviewAdminApi.adminSetStatementPaid, { annualReviewId: payload.annualReviewId, amountStatement: payload.amountStatement, paymentDate: payload.paymentDate });
    yield put(adminSetCurrentAnnualReviewAction(resp));
  } catch {
    yield put(adminSetCurrentAnnualReviewAction(null));
  }
}


const actions = createEntityDucksFactory<AnnualReview>(annualReviewAdminApi, {
  get: adminGetAnnualReviewAction,
  set: adminSetCurrentAnnualReviewAction,
  update: adminUpdateAnnualReviewAction
})


export function* annualreviewAdminSaga() {
  yield takeLatest(adminGetAnnualReviewAction, actions.get);
  yield takeLatest(adminUpdateAnnualReviewAction, actions.update);
  yield takeLatest(adminUploadDocumentActions, adminUpload);
  yield takeLatest(adminValidStatusActions, adminValidStatus);
  yield takeLatest(adminRefuseStatusActions, adminRefuseStatus);
  yield takeLatest(generateAnnualReviewDocumentActions, generateAnnualReview);
  yield takeLatest(adminSetStatementActions, adminSetStatement);
  yield takeLatest(adminGenerateStatementDocumentActions, adminGenerateStatementDocument);
  yield takeLatest(adminValidateStatementActions, adminValidateStatement);
  yield takeLatest(adminAcceptOrderActions, acceptOrder);
  yield takeLatest(adminRefuseOrderActions, refuseOrder);
  yield takeLatest(adminUpdateRevenueOrderFileActions, adminUpdateRevenueOrderFile);
  yield takeLatest(adminSetStatementPaidActions, adminSetStatementPaid);
  // yield takeLatest(adminGetAnnualReviewAction, getAnnualReview);
  // yield takeLatest(adminUpdateAnnualReviewAction, updateAnnualReview);
}
import { GeoPoint, IAnnualReview, ICollectPoint, IHotspot, IInventory, IStructure } from "@alcome-rep/alcome-types/dist/interfaces";
import { EnvironmentOutlined, GatewayOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { geocodeByAddress } from "react-google-places-autocomplete";
import LoadingScreen from "../../../common/components/LoadingScreen";
import { getStringAddress, randomNumberId } from "../../../common/tools/global-tools";
import { geometryFromData, isAnnualReview, isCollectPoint, isHotspot } from "../../../common/tools/type-tools";
import { GeoJsonType } from "../../mapTypes";
import mapApi from "../../services/map.api";
import CollectPointModal from "../CollectPoint/collectPointModal";
import HotSpotModal from "../Hotspot/hotspotModal";
import GMapComponent from "./GMapComponent";
import Info from "../../../common/components/WarningUser/Info";

type MapProps = {
  structure: IStructure;
  layer?: IInventory | IAnnualReview;
  editMode: 'readOnly' | 'edit' | 'selectHotspots' | 'selectUniqueHotspot' | 'selectCollectPoints' | 'clickLocation'
  onSelectionChanged?: (element: Array<IHotspot | ICollectPoint>) => void;
  onMapInsert?: (geoPoint: GeoPoint | GeoPoint[]) => void;
  initialCollectPoints?: ICollectPoint[];
  initialHotspots?: IHotspot[];
  initialSelection?: Array<IHotspot | ICollectPoint>;
}

const MapComponent = ({ structure, layer, editMode, onSelectionChanged, onMapInsert, initialCollectPoints, initialHotspots, initialSelection }: MapProps) => {
  // useEffect(() => {
  //   const loader = new Loader({
  //     apiKey: process.env.REACT_APP_GOOGLEMAP_KEY || '',
  //     libraries: ["drawing", "places"]
  //   });
  //   loader.libraries.forEach(l => loader.importLibrary(l))
  //   // loader.load()
  // }, [])

  if (editMode === 'edit' && (initialCollectPoints || initialHotspots))
    editMode = 'readOnly'

  const [center, setCenter] = useState<GeoPoint>({ lat: 47, lng: 1.2786204453372862 });
  const [zoom, setZoom] = useState<number>(2);

  const [collectPoints, setCollectPoints] = useState<ICollectPoint[]>(initialCollectPoints || [])
  const [hotspots, setHotspots] = useState<IHotspot[]>(initialHotspots || [])
  const [selectedElements, setSelectedElements] = useState<Array<ICollectPoint | IHotspot>>(initialSelection || [])

  const structureId = structure?.id

  const render = (status: Status) => {
    if (status === Status.LOADING) return <h3>{status} ..</h3>;
    if (status === Status.FAILURE) return <h3>{status} ...</h3>;
    return <></>;
  };

  const mapMarkers: GeoJsonType = useMemo(() => {
    return {
      type: "FeatureCollection",
      features: collectPoints.map((point, i) => {
        if (!point._id)
          point._id = 'C' + randomNumberId();
        return {
          type: "Feature",
          id: point._id as string,
          properties: { dataId: point._id as string },
          geometry: geometryFromData(point)
        }
      })
    };
  }, [collectPoints])

  const mapPolygons: GeoJsonType = useMemo(() => {
    return {
      type: "FeatureCollection",
      features: hotspots.map((spot, i) => {
        if (!spot._id)
          spot._id = 'H' + randomNumberId();
        return {
          type: "Feature",
          id: spot._id as string,
          properties: { dataId: spot._id as string },
          geometry: geometryFromData(spot)
        }
      })
    };
  }, [hotspots])

  useEffect(() => {
    if (!structure)
      return;
    const { address } = structure;

    if (address) {
      geocodeByAddress(getStringAddress(address)).then(results => {
        if (results && results.length) {
          setCenter(results[0].geometry.location.toJSON());
          setZoom(14);
        }
      })
    }
  }, [structure])

  const getHotspots = useCallback(() => {
    if (structureId)
      mapApi.getHotspots(structureId)
        .then(r => setHotspots(r.rows));
  }, [structureId])

  const getCollectPoints = useCallback(() => {
    if (structureId) {
      mapApi.getCollectPoints(structureId)
        .then(r => setCollectPoints(r.rows))
    }
  }, [structureId])

  useEffect(() => {
    if (!initialCollectPoints)
      getCollectPoints();
    else
      setCollectPoints(initialCollectPoints)
    if (!initialHotspots)
      getHotspots();
    else
      setHotspots(initialHotspots)
  }, [getHotspots, getCollectPoints, initialCollectPoints, initialHotspots])

  useEffect(() => {
    if (onSelectionChanged && selectedElements !== initialSelection) {// && ['selectHotspots', 'selectCollectPoints'].includes(editMode)) {
      onSelectionChanged(selectedElements)
    }
  }, [selectedElements, onSelectionChanged, initialSelection])

  const mapSelect = (obj: any) => {
    if (editMode === 'edit' || editMode === 'readOnly') {
      let foundObj: ICollectPoint | IHotspot | undefined = collectPoints.find(c => c._id === obj)
      if (foundObj)
        setSelectedElements([foundObj])
      else {
        foundObj = hotspots.find(c => c._id === obj);
        if (foundObj)
          setSelectedElements([foundObj])
      }
    } else if (editMode === 'selectCollectPoints') {
      const exist = selectedElements.find(c => c._id === obj)
      if (exist) {
        setSelectedElements([...selectedElements.filter(el => el._id !== obj)])
      } else {
        let foundObj: ICollectPoint | undefined = collectPoints.find(c => c._id === obj)
        if (foundObj)
          setSelectedElements([...selectedElements, foundObj])
      }
    } else if (editMode === 'selectHotspots') {
      const exist = selectedElements.find(c => c._id === obj)
      if (exist) {
        setSelectedElements([...selectedElements.filter(el => el._id !== obj)])
      } else {
        let foundObj: IHotspot | undefined = hotspots.find(c => c._id === obj)
        if (foundObj)
          setSelectedElements([...selectedElements, foundObj])
      }
    } else if (editMode === 'selectUniqueHotspot') {
      const exist = selectedElements.find(c => c._id === obj)
      if (!exist) {
        let foundObj: IHotspot | undefined = hotspots.find(c => c._id === obj)
        if (foundObj)
          onSelectionChanged && onSelectionChanged([foundObj])
      }
    }
  }

  const mapInsert = (e: { geoPoint: GeoPoint | GeoPoint[], surface?: number }) => {
    if (editMode === 'edit') {
      if (!Array.isArray(e.geoPoint)) {
        const newData = {
          geoPoint: e.geoPoint,
        } as ICollectPoint;

        setSelectedElements([newData])
      }
      else {
        const newData = {
          geoPoints: e.geoPoint,
          surface: e.surface
        } as IHotspot;

        setSelectedElements([newData])
      }
    } else if (editMode === 'clickLocation' && onMapInsert) {
      onMapInsert(e.geoPoint)
    }
  }

  const updateData = async (data: IHotspot | ICollectPoint) => {
    if (!selectedElements.length)
      return;
    if (editMode === 'edit' && layer) {
      const selectedElement = selectedElements[0];

      if (isHotspot(selectedElement) && isHotspot(data)) {
        if (selectedElement._id) {
          await mapApi.updateHotspot(selectedElement._id, data)
        } else {
          await mapApi.createHotspot(data, layer.id!, isAnnualReview(layer))
        }
        setSelectedElements([]);
        getHotspots();
      } else if (isCollectPoint(selectedElement) && isCollectPoint(data)) {
        if (selectedElement._id) {
          await mapApi.updateCollectPoint(selectedElement._id, data)
        } else {
          await mapApi.createCollectPoint(data, layer.id!, isAnnualReview(layer))
        }
        setSelectedElements([]);
        getCollectPoints();
      }
    }
  }

  const deleteData = async () => {
    if (!selectedElements.length || editMode !== 'edit')
      return;
    const selectedElement = selectedElements[0]
    if (isHotspot(selectedElement)) {
      await mapApi.deleteHotspot(selectedElement._id!);
      setSelectedElements([]);
      getHotspots();
    }
    else if (isCollectPoint(selectedElement)) {
      await mapApi.deleteCollectPoint(selectedElement._id!);
      setSelectedElements([]);
      getCollectPoints();
    }
  }

  if (!structure)
    return <LoadingScreen />

  return <>
    {editMode === 'edit' && (<Info icon={<QuestionCircleOutlined />} type="warning" className="my-4">À l'aide de la carte interactive, cliquez sur le picto <EnvironmentOutlined /> pour placer un dispositif de collecte et sur le picto tracé <GatewayOutlined /> pour définir un hotspot. Laissez le champ vide si vous n'en n'avez pas.</Info>)}

    <Wrapper
      libraries={["drawing", "places"]}
      apiKey={process.env.REACT_APP_GOOGLEMAP_KEY || ''}
      render={render}
    >
      <GMapComponent
        center={center}
        zoom={zoom}
        readOnly={['readOnly', 'selectHotspots', 'selectUniqueHotspot'].includes(editMode)}
        markers={mapMarkers}
        selection={selectedElements ? selectedElements.map(el => el._id!) : undefined}
        polygons={mapPolygons}
        onMapInsert={mapInsert}
        onMapSelect={(id) => mapSelect(id)}
        drawModes={editMode === 'clickLocation' ? [google.maps.drawing.OverlayType.MARKER] : undefined}
      />

    </Wrapper>

    {['readOnly', 'edit'].includes(editMode) && (
      <CollectPointModal
        structure={structure}
        canEdit={editMode === 'edit'}
        collectPoint={(selectedElements as ICollectPoint[]).length && isCollectPoint(selectedElements[0]) ? selectedElements[0] : undefined}
        onOk={updateData}
        onDelete={deleteData}
        onCancel={() => setSelectedElements([])}
      />
    )}

    {['readOnly', 'edit'].includes(editMode) && (
      <HotSpotModal
        structure={structure}
        canEdit={editMode === 'edit'}
        hotspot={selectedElements.length && isHotspot(selectedElements[0]) ? selectedElements[0] : undefined}
        onOk={updateData}
        onDelete={deleteData}
        onCancel={() => setSelectedElements([])}
      />
    )}
  </>
}

export default MapComponent;
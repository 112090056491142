import { Models } from "@alcome-rep/alcome-types";
import { Table } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { supplierOrderListColumns } from "../../../../SupplierOrder/components/admin/AdminSupplierOrderList/AdminSupplierListColumns";
import adminSupplierOrderApi from "../../../../SupplierOrder/services/admin-supplierOrder.api";
import useQueryParams from "../../../../common/hooks/useQueryParams";
import useTableChange from "../../../../common/hooks/useTableChange";

const AdminSupplierCompanyOrderList = ({ supplierId }: { supplierId: string }) => {

  const [data, setData] = useState<Models.SupplierOrder[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setloading] = useState<boolean>(false);
  const [refresh] = useState<Date>()

  const tableChange = useTableChange();
  const queryParams = useQueryParams()

  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    setloading(true)
    adminSupplierOrderApi.findAllSupplierOrders({ ...queryParams.query, supplierCompanyId: supplierId }, Number(queryParams.page), Number(queryParams.limit))
      .then(res => {
        setData(res.rows)
        setCount(res.count)
        setloading(false)
      })
      .catch(() => {
        setData([])
        setCount(0)
        setloading(false)
      })
  }, [setData, setCount, setloading, queryParams, refresh, supplierId])

  return (
    <Table<Models.SupplierOrder>
      columns={supplierOrderListColumns(queryParams.query, t, navigate)}
      dataSource={data}
      loading={loading}
      onChange={tableChange}
      size="small"
      rowKey="id"
      scroll={{ scrollToFirstRowOnChange: true, x: 1000 }}
      pagination={
        {
          hideOnSinglePage: true,
          current: queryParams.page,
          pageSize: queryParams.limit,
          total: count,
        }
      } />
  )
}

export default AdminSupplierCompanyOrderList
import { IAmendment } from "@alcome-rep/alcome-types/dist/interfaces"
import { Button, Modal } from "antd"
import amendmentPortalApi from "../../../Amendment/services/amendment-portal.api"
import { useRef, useState } from "react";
import { getContractAction } from "../../../Contract/state/contract-portal.slice";
import { useAppDispatch } from "../../../common/hooks";

type AmendmentAlertBannerProps = {
    amendment: IAmendment;
}

const AmendmentAlertBanner = ({ amendment }: AmendmentAlertBannerProps) => {

    const iFrame = useRef(null);
    const dispatch = useAppDispatch();
    const [visible, setVisible] = useState(false);

    const refreshContract = () => {
        setVisible(false)
        dispatch(getContractAction())
    }

    const showAmendment = async () => {
        if (amendment) {
            setVisible(true)
            const res = await amendmentPortalApi.generateAmendmentFile(amendment.id as string)
            const blobContent = new Blob([res], { type: "application/pdf" });
            if (iFrame.current !== null) {
                (iFrame.current as HTMLIFrameElement).src = URL.createObjectURL(blobContent);
                // contractApi.default.setAgreementDate(contract.id as string, amendment.id as string)
            }
        }
    }

    return (
        <div className='flex-none'>
            <div className='flex space-x-4 font-semibold items-center'>
                <div>
                    Un avenant au contrat est disponible. Veuillez cliquer sur le bouton "Consulter" pour en prendre connaissance.
                    Une fois le document consulté vous pourrez le retrouver depuis le menu "Contrat".
                </div>
                <Button className='btn text-center ' onClick={() => showAmendment()}>Consulter</Button>
            </div>
            <Modal
                title="Avenant au contrat"
                styles={{ body: { height: 800 } }}
                centered
                open={visible}
                onOk={() => refreshContract()}
                onCancel={() => refreshContract()}
                width={1000}
                footer={null}
            >
                <iframe title="contract" ref={iFrame} className="w-full h-full" ></iframe>
            </Modal>
        </div>
    )
}

export default AmendmentAlertBanner
import { ISupplierOrder } from "@alcome-rep/alcome-types/dist/interfaces/supplier"

const DeliveryComponent = ({ supplierOrder }: { supplierOrder: ISupplierOrder }) => {
    return (
        <>
            <h6>Adresse de livraison</h6>
            {supplierOrder.shippingAddress?.service && (
                <div>Service : {supplierOrder.shippingAddress?.service}</div>
            )}
            <div>{supplierOrder.shippingAddress?.street}</div>
            <div>{supplierOrder.shippingAddress?.street2}</div>
            <div>
                <span>{supplierOrder.shippingAddress?.zip}</span>, <span>{supplierOrder.shippingAddress?.city}</span>
            </div>
            <h6>Contact</h6>
            <div>{supplierOrder.contact?.firstName} {supplierOrder.contact?.lastName}</div>
            <div>{supplierOrder.contact?.email}</div>
            <div>{supplierOrder.contact?.phone}</div>
        </>
    )
}

export default DeliveryComponent
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-http-backend';

import { isDev } from '../../config';

// Maps to public/locales/:language_code
export const LANGUAGE_CODES = {
  FR: 'fr',
};

// see: https://www.i18next.com/overview/configuration-options
i18next
  .use(XHR)
  .use(initReactI18next)
  .init({
    debug: isDev,
    ns: "translation",
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json?cache='+new Date().getTime(),
    },
    lng: LANGUAGE_CODES.FR,
    fallbackLng: LANGUAGE_CODES.FR,
    supportedLngs: [LANGUAGE_CODES.FR],
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      transWrapTextNodes: 'span'
    },
  });

export const t = i18next.t.bind(i18next);

export default i18next;

import { IContract, SignatoryTypesEnum, StructureTypesEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import { Form, Input, Radio } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NextStepComponent from '../../../../Auth/components/Register/NextStepComponent';
import { selectCurrentStructure } from '../../../../Structure/state/structure-portal.slice';
import LoadingScreen from '../../../../common/components/LoadingScreen';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import useFormRules from '../../../../common/hooks/useFormRules';
import { ROUTES } from '../../../../common/router';
import { getRoute } from '../../../../common/tools/router-tools';
import { selectCurrentContract, updateContractAction } from '../../../state/contract-portal.slice';
import PortalDelegationFileUpload from '../FileUploads/PortalDelegationUpload';

const CommuneSignatory = () => {
  const contract = useAppSelector(selectCurrentContract);
  const signatoryType = contract?.signatory.type;
  const structure = useAppSelector(selectCurrentStructure);

  const [showFileField, setShowFileField] = useState<boolean>(contract?.signatory.type === SignatoryTypesEnum.delegate);
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { required } = useFormRules();


  const [form] = useForm()

  const dispatch: Dispatch<any> = useAppDispatch()

  useEffect(() => {
    setShowFileField(signatoryType === SignatoryTypesEnum.delegate)
  }, [signatoryType])

  const submit = (formData: IContract) => {
    const data = { ...formData };
    delete data.delegationFileId;
    dispatch(updateContractAction(data))
    if (structure?.structureType === StructureTypesEnum.groupement_communes)
      return navigate(getRoute(ROUTES.PORTAL_REGISTER_CONTRACT_ROUTES.STEPS_GRP_COMMUNES.deliberation))
    else
      return navigate(getRoute(ROUTES.PORTAL_REGISTER_CONTRACT_ROUTES.STEPS_COMMUNE.deliberation))
  }

  const valuesChanged = (_changedValues: any, allValues: any) => {
    setShowFileField(allValues.signatory.type === SignatoryTypesEnum.delegate);
  }

  if (!contract)
    return <LoadingScreen />

  return (
    <>
      <h2 className='mb-4'>{t('UI.REGISTER_CONTRACT.SIGNATORY.title')}</h2>
      <p>{t('UI.REGISTER_CONTRACT.SIGNATORY.explanations.' + structure?.structureType)}</p>
      <Form
        onValuesChange={valuesChanged}
        name="basic"
        layout='vertical'
        onFinish={submit}
        form={form}
        initialValues={contract}
        id="pa"
      >
        <Form.Item
          label={t('UI.REGISTER_CONTRACT.SIGNATORY.fields.type.label')}
          name={['signatory', 'type']}
          rules={[required]}

        >
          <Radio.Group
            options={
              Object.keys(SignatoryTypesEnum)
                .filter(v => typeof v === "string")
                .map(v => ({ value: v as string, label: t(`TYPES.CONTRACT.SignatoryTypesEnum.${v}`) }))
            } />
        </Form.Item>

        <div className='grid grid-cols-2 gap-x-4'>
          <Form.Item
            label={t('UI.REGISTER_CONTRACT.SIGNATORY.fields.firstName.label')}
            name={["signatory", "firstName"]}
            rules={[required]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label={t('UI.REGISTER_CONTRACT.SIGNATORY.fields.lastName.label')}
            name={["signatory", "lastName"]}
            rules={[required]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label={t('UI.REGISTER_CONTRACT.SIGNATORY.fields.function.label')}
            name={["signatory", "function"]}
            rules={[required]}
          >
            <Input></Input>
          </Form.Item>
        </div>

        {showFileField && (
          <PortalDelegationFileUpload isInForm={true} />
        )}

        <Form.Item>
          <NextStepComponent />
        </Form.Item>
      </Form >
    </>
  )
}

export default CommuneSignatory;
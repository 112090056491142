import { Modal } from "antd";
import HotspotForm from "./hotspotForm";
import { IHotspot, IStructure } from "@alcome-rep/alcome-types/dist/interfaces";

type HotSpotFormProps = {
    structure: IStructure
    hotspot?: IHotspot,
    onCancel: () => void,
    onOk: (hotSpot: IHotspot) => void,
    onDelete?: (hotSpot: IHotspot) => void,
    canEdit: boolean,
};

const HotSpotModal = ({ structure, hotspot, onOk, onCancel, onDelete, canEdit = true }: HotSpotFormProps) => {


    return (
        <Modal
            title="Hotspot"
            centered={false}
            forceRender={true}
            open={hotspot !== undefined}
            footer={null}
            closable={false}
            okButtonProps={{ className: 'btn' }}
        >
            {hotspot !== undefined &&
                <HotspotForm
                    structure={structure}
                    canEdit={canEdit}
                    hotspot={hotspot}
                    onOk={onOk}
                    onDelete={onDelete}
                    onCancel={onCancel}
                />
            }
        </Modal>
    )
}

export default HotSpotModal
import { AnnualReviewStatusEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import { UserTypeEnum } from '@alcome-rep/alcome-types/dist/interfaces/user';
import { AnnualReview } from '@alcome-rep/alcome-types/dist/models';
import { CheckOutlined, ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Dispatch } from '@reduxjs/toolkit';
import { Alert, Button } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import moment from 'moment';
import 'moment/locale/fr';
import { selectUser } from '../../../../Auth/state/auth.slice';
import GenerateFileButton from '../../../../common/components/GenerateFileButton/GenerateFileButton';
import LoadingScreen from '../../../../common/components/LoadingScreen';
import Info from '../../../../common/components/WarningUser/Info';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { useTrackProgress } from '../../../../common/services/trackProgress';
import annualReviewAdminApi, { progressKeys } from '../../../services/annual-review-admin.api';
import annualReviewPortalApi, { progressKeys as portalProgressKeys } from '../../../services/annual-review-portal.api';
import { adminRefuseStatusActions, adminValidStatusActions, generateAnnualReviewDocumentActions, selectAdminCurrentAnnualReview } from '../../../state/annual-review-admin.slice';
import { selectCurrentPortalAnnualReview, userPortalValidStatusActions } from '../../../state/annual-review-portal.slice';
import AnnualReviewRefusedMentionComponent from '../AnnualReviewRefusedMention/AnnualReviewRefusedMention';
moment().locale('fr')

type PropsType = {
  isAdmin?: boolean
}

const AnnualReviewRevisionStep = ({ isAdmin }: PropsType) => {
  const annualReviewActionToUse = isAdmin ? selectAdminCurrentAnnualReview : selectCurrentPortalAnnualReview;
  const annualReview = useAppSelector(annualReviewActionToUse);

  let comment: null | string = null;

  const isLoading = useTrackProgress(isAdmin ? progressKeys.upload : portalProgressKeys.uploadPortal);
  const user = useAppSelector(selectUser);
  const dispatch: Dispatch<any> = useAppDispatch()

  if (!annualReview) {
    return <><LoadingScreen></LoadingScreen></>
  }

  const admin = (user && user.userType === UserTypeEnum.admin) || false
  const canGenerate = admin && annualReview?.status === AnnualReviewStatusEnum.completed ? true : false;
  const adminCanValidate = admin && annualReview?.status === AnnualReviewStatusEnum.completed && annualReview?.annualReviewFileId;

  const userCanSend = !admin && (annualReview.policeArrest.length > 0 || annualReview.precautionaryMeasure.length > 0 || annualReview.awarenessAction.length > 0) && (annualReview.communicationKitUsed !== undefined) ? true : false;

  const download = (propertyName: keyof AnnualReview) => (): Promise<Blob> => {
    if (!admin) {
      return annualReviewPortalApi.getAnnualReviewFile(propertyName, annualReview.id as string)
    } else {
      return annualReviewAdminApi.getAnnualReviewFile(propertyName, annualReview.id as string)
    }
  }

  const completeAnnualreview = async () => {
    dispatch(userPortalValidStatusActions({ annualReviewId: annualReview.id }))
  }

  const validateAnnualreview = async () => {
    confirm({
      title: 'Validation du bilan',
      icon: <ExclamationCircleOutlined />,
      content: 'Êtes-vous sûr de vouloir valider ce bilan ?',
      cancelText: 'Annuler',
      closable: true,
      okCancel: true,
      okButtonProps: { className: "btn" },
      onOk() {
        dispatch(adminValidStatusActions({ annualReviewId: annualReview.id }))
      },
      onCancel() {
      },
    });
  }

  const refuseAnnualReview = async () => {
    confirm({
      title: 'Refus du bilan',
      icon: <ExclamationCircleOutlined />,
      content: (<>Ajouter un commentaire <textarea className='block w-full p-2' onChange={e => comment = (e.target.value)} ></textarea></>),
      cancelText: 'Annuler',
      closable: true,
      okCancel: true,
      okButtonProps: { className: "btn" },
      onOk() {
        dispatch(adminRefuseStatusActions({ annualReviewId: annualReview.id as string, comment: comment || '' }))
        comment = null
      },
      onCancel() {
      },
    });
  }

  const generateAnnualReview = async () => {
    dispatch(generateAnnualReviewDocumentActions(annualReview.id))
  }

  return (
    <div className="flex flex-col gap-4">

      {annualReview.status <= AnnualReviewStatusEnum.refused && !userCanSend && (
        <Info type="error" body={<>Des informations sont manquantes, merci de vérifier les formulaire <span className="font-semibold">1.1, 1.2, 1.3, 2 & 3</span>.</>} />
      )}

      {annualReview.status >= AnnualReviewStatusEnum.statement_pending && (
        <Info icon={<CheckOutlined />} body="Votre bilan à été validé" type='success' />
      )}

      {annualReview.status === AnnualReviewStatusEnum.refused && annualReview.annualReviewFile && annualReview.annualReviewFile.comment && (
        <div>
          <Alert type='error' message={<div>Motifs du refus : {annualReview.annualReviewFile.comment}</div>} />
        </div>
      )}

      {annualReview.status === AnnualReviewStatusEnum.completed && admin && (
        <Info type="warning" icon={<QuestionCircleOutlined />} body={<>Consultez le bilan fourni par la structure à l'aide des pages <span className="font-semibold">1, 2 et 3</span>. Générez ensuite le bilan. Vous pourrez ensuite valider ou refuser le bilan proposé par la structure.</>} />
      )}

      {annualReview.status <= AnnualReviewStatusEnum.validated && (
        <>
          {!admin && annualReview.status <= AnnualReviewStatusEnum.refused && (
            <>
              <p className="mb-0">Une fois les étapes précédentes renseignées, cliquez pour envoyer vos informations. Nos équipes valideront ensuite les données saisies pour vous permettre de télécharger le document correspondant</p>
              <Button disabled={!userCanSend} type="primary" htmlType="button" className="mb-0 self-start" onClick={completeAnnualreview}>
                Envoyer les informations
              </Button>
            </>
          )}

          {!admin && annualReview.status === AnnualReviewStatusEnum.completed && (
            <Info icon={<QuestionCircleOutlined />} body="Votre bilan sera prochainement accessible au téléchargement. Vous serez notifié lorsque nos équipes auront validé les informations." type="info" />
          )}
          {admin && annualReview.status <= AnnualReviewStatusEnum.refused && (
            <>
              {!annualReview.annualReviewFileId && <p>Le bilan est en cours de saisie par l'utilisateur</p>}
              <AnnualReviewRefusedMentionComponent annualReview={annualReview}></AnnualReviewRefusedMentionComponent>
            </>
          )}

          {annualReview.status >= AnnualReviewStatusEnum.validated && (
            <Info icon={<CheckOutlined />} type="success" body="Le bilan a été validé par Alcome." />
          )}

          {((admin && annualReview.status >= AnnualReviewStatusEnum.completed) || (!admin && annualReview.status >= AnnualReviewStatusEnum.validated)) && (
            <GenerateFileButton
              isLoading={isLoading}
              downloadFile={download('annualReviewFileId' as keyof AnnualReview)}
              generateFile={generateAnnualReview}
              readOnly={!canGenerate}
              file={annualReview.annualReviewFile} />
          )}

          {adminCanValidate && (
            <div className="flex gap-4">
              <Button type="primary" htmlType="button" className="btn" onClick={validateAnnualreview}>
                Valider le bilan
              </Button>
              <Button
                htmlType="button"
                type="link"
                className="text-alc-danger"
                onClick={refuseAnnualReview}>
                Refuser le bilan
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AnnualReviewRevisionStep;
import { Button, Drawer } from "antd";
import { useState } from "react";
import CreateSupplierCompanyForm from "./CreateSupplierCompanyForm";

type Props = {
    refresh: (value: Date) => any
}

const CreateSupplierCompany = ({ refresh }: Props) => {

    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true)
    };

    const onClose = () => {
        setOpen(false)
        refresh(new Date())
    };

    return (
        <>
            <Button className="btn" onClick={showDrawer}>Créer un fournisseur</Button>
            <Drawer
                title="Fournisseur"
                width='50%'
                onClose={_ => onClose()}
                open={open}
                styles={{ body: { paddingBottom: 80 } }}
            >
                {open && <CreateSupplierCompanyForm close={onClose} />}
            </Drawer>
        </>
    )
}

export default CreateSupplierCompany
import { ILibraryDoc } from "@alcome-rep/alcome-types/dist/interfaces"
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Checkbox } from "antd"
import { useState } from "react"

type Props = {
    libDoc: ILibraryDoc,
    finalizeDl: (libDoc: ILibraryDoc) => void;
}

const LibraryDocCgu = ({ libDoc, finalizeDl }: Props) => {

    const [checked, setChecked] = useState<boolean>(false)

    return (
        <div>
            <div>
                <Checkbox onChange={(e) => {
                    checked ? setChecked(false) : setChecked(true)
                }}>J'accepte les CGU</Checkbox>
            </div>
            {checked && (
                <div className="mt-4">
                    <Button type="link" onClick={() => finalizeDl(libDoc)} icon={<DownloadOutlined />}>Télécharger "{libDoc.name}"</Button>
                </div>
            )}
        </div>
    )
}

export default LibraryDocCgu
import { Inventory } from "@alcome-rep/alcome-types/dist/models";
import { Draft, PayloadAction, createSelector } from "@reduxjs/toolkit";
import factoryCreateSlice from "../../common/state/slice.factory";
import { BaseServiceState } from "../../common/state/types";
import { RootState } from "../../common/store";

const slices = factoryCreateSlice<"AdminInventory", Inventory>('AdminInventory', {
  adminGetInventoryByStructureAction(state: Draft<BaseServiceState<Inventory, 'current'>>, { payload }: PayloadAction<Draft<{ id: string }>>) {
  },
})

export const {
  getAction: adminGetInventoryAction,
  setCurrentAction: adminSetCurrentInventoryAction,
  adminGetInventoryByStructureAction
} = slices.actions;

export default slices.reducer;

export const selectAdminInventoryService = (state: RootState) => state.inventoryAdminService
export const selectCurrentAdminInventory = createSelector(selectAdminInventoryService, (inventorySvc) => inventorySvc.current)
import { AcquisitionItemOrderStatusEnum, AcquisitionOrderItemType, AcquisitionOrderStatusEnum, DispositifCategoryEnum, DispositifTypeEnum, isExtinguisherType } from "@alcome-rep/alcome-types/dist/interfaces";
import { IProduct } from "@alcome-rep/alcome-types/dist/interfaces/product";
import { User } from "@alcome-rep/alcome-types/dist/models";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Button, InputNumber, Select, Table, TableColumnType } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectUser } from "../../../../Auth/state/auth.slice";
import ProductSelector from "../../../../Product/components/common/ProductSelector";
import { selectCurrentStructure } from "../../../../Structure/state/structure-portal.slice";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks";
import msg from "../../../../common/services/toast";
import { randomStringId } from "../../../../common/tools/global-tools";
import useQuotas from "../../../hooks/useQuota.hook";
import { selectCurrentAdminAcquisitionOrder, setCurrentAdminAcquisitionOrderAction } from "../../../state/acquisitionOrder-admin.slice";
import { selectCurrentPortalAcquisitionOrder, setCurrentPortalAcquisitionOrderAction } from "../../../state/acquisitionOrder-portal.slice";


const AcquisitionOrderExtinguishers = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const acquisitionOrder = useAppSelector(User.IsUserAdmin(user) ? selectCurrentAdminAcquisitionOrder : selectCurrentPortalAcquisitionOrder);
  const canEdit = User.IsUserPortal(user) && !acquisitionOrder?.id

  const structure = useAppSelector(selectCurrentStructure);
  const quotas = useQuotas(acquisitionOrder?.structureId || structure?.id);
  const [isOverQuotas, setIsOverQuotas] = useState<boolean>(false);

  const [items, setItems] = useState<AcquisitionOrderItemType[]>([]);

  useEffect(() => {
    if (acquisitionOrder)
      setItems((acquisitionOrder.items ?? []).filter(item => item.category === DispositifCategoryEnum.extinguisher))
  },// eslint-disable-next-line
    [])

  useEffect(() => {
    if (items && acquisitionOrder && !acquisitionOrder.id) {
      const newItems = [
        ...(acquisitionOrder.items || []).filter(item => item.category === DispositifCategoryEnum.ashtray),
        ...items
      ];
      dispatch(User.IsUserAdmin(user)
        ? setCurrentAdminAcquisitionOrderAction({ ...acquisitionOrder, items: newItems, status: AcquisitionOrderStatusEnum.acquisition_order_pending })
        : setCurrentPortalAcquisitionOrderAction({ ...acquisitionOrder, items: newItems, status: AcquisitionOrderStatusEnum.acquisition_order_pending }))
    }
  },// eslint-disable-next-line  
    [items, dispatch])

  useEffect(() => {
    if (!quotas)
      return
    setIsOverQuotas(items.reduce((total, item) => total = total + (!item.hotspot ? item.quantity : 0), 0) > quotas?.available.extinguishers)
  }, [items, quotas])

  const { t } = useTranslation();

  const addItem = (isSupport = false) => {
    // const hasSupportLine = items.find(item => item.isSupport === true && !item.hotspotId) !== undefined
    const newItems = [...items.map(i => ({ ...i }))];
    const position = newItems.length;
    newItems.splice(position, 0, {
      isSupport,
      id: randomStringId(),
      category: DispositifCategoryEnum.extinguisher,
      quantity: 1,
      // type: DispositifTypeEnum.cendrier_mural,
      status: AcquisitionItemOrderStatusEnum.pending,
    } as AcquisitionOrderItemType)
    setItems(newItems)
  }

  const removeItem = (item: AcquisitionOrderItemType) => {
    const newItems = [...items];
    newItems.splice(items.indexOf(item), 1)
    setItems(newItems)
  }

  const getDataSource = useMemo(() => {
    return () => (items ?? []).filter(el => el.hotspotId === undefined) || []
  }, [items])

  const columns: TableColumnType<AcquisitionOrderItemType>[] = [

    {
      title: 'Type',
      dataIndex: ['type'],
      render: (v: DispositifTypeEnum, item: AcquisitionOrderItemType) =>
        <Select
          disabled={!canEdit}
          value={v}
          options={Object.values(DispositifTypeEnum)
            .filter(isExtinguisherType)
            .map(k => ({ value: k, label: t('TYPES.INVENTORY.DispositifTypeEnum.' + k) }))
          }
          onChange={v => {
            const index = items.findIndex(i => i === item);
            const newItems = [...items.map(i => ({ ...i }))];
            newItems[index].type = v
            setItems(newItems)
          }}
          className="w-64"
        />
    },
    {
      title: 'Quantité',
      dataIndex: 'quantity',
      align: "center",
      render: (_value: number, item: any) => {
        return <InputNumber
          value={item.quantity}
          min={1}
          readOnly={!canEdit}
          onChange={e => {
            const index = items.findIndex(i => i === item);
            const newItems = [...items.map(i => ({ ...i }))];
            newItems[index].quantity = e ?? 1
            setItems(newItems)
          }}
        />
      }
    },
    {
      title: 'Financement',
      dataIndex: 'isSupport',
      align: "left",
      render: (isSupport: boolean, item: AcquisitionOrderItemType) => {
        return <Select
          disabled={!canEdit}
          className="w-44"
          options={[{ label: "Soutien financier", value: true }, { label: "Acquisition", value: false }]}
          onChange={v => {
            const newItems = [...items.map(item => ({ ...item }))]
            const newItem = newItems[items.indexOf(item)]
            newItem.isSupport = v
            setItems(newItems)
          }}
          value={isSupport}
        />
      }
    },
    {
      title: 'Produit',
      align: "left",
      dataIndex: ['product'],
      render: (product: IProduct, item: AcquisitionOrderItemType) => {
        if (item.isSupport) {
          return null
        } else {
          return canEdit ? <ProductSelector
            product={product}
            category={DispositifCategoryEnum.extinguisher}
            type={item.type}
            productSelectedCb={product => {
              const newItems = [...items.map(i => ({ ...i }))];
              const newItem: AcquisitionOrderItemType | undefined = newItems.find(i => i.id === item?.id);
              if (!newItem)
                return;
              const doublon = newItems.find(i => i.isSupport === false && !i.hotspotId && i.category === DispositifCategoryEnum.extinguisher && i.product && i.id !== newItem.id && i.product.id === product.id);
              if (doublon) {
                doublon.quantity += newItem.quantity
                newItems.splice(newItems.indexOf(newItem), 1)
                msg.info("Ce produit est déjà présent pour ce hotspot. Les lignes ont été réunies.")
              } else {
                newItem.product = product
                newItem.price = product.price
                newItem.type = product.type
                // newItem.category = product.category
              }
              setItems(newItems)
            }}
          />
            : <span>{product.name}</span>
        }
      }
    },
    {
      title: '', key: 'delete', render: (item: AcquisitionOrderItemType) => canEdit && <Button danger type="link" onClick={_ => removeItem(item)} icon={<DeleteOutlined />} />
    },
  ];

  return <>
    {canEdit && isOverQuotas && <Alert className="my-2" banner message="Quota d'éteignoirs atteint." />}

    <Table<AcquisitionOrderItemType>
      dataSource={getDataSource()}
      columns={columns}
      className="extinguisher-table"
      rowKey={'id'}
      size='small'
      locale={
        { emptyText: "Aucun article." }
      }
      pagination={false}
      footer={() =>
        canEdit && <div className="flex space-x-2 justify-end">
          {/* <Button type="link" icon={<EuroOutlined />} disabled={items.find(item => item.isSupport && !item.hotspotId) !== undefined} onClick={_ => addItem(true)}>demander un soutien</Button> */}
          <Button type="primary" icon={<PlusOutlined />} onClick={_ => addItem()}>Ajouter un dispositif</Button>

          {/* <div>{canEdit && <Button type="link" className="px-0" icon={<PlusOutlined />} onClick={_ => addItem()}>Ajouter un article</Button>}</div> */}
          {/* <div></div> */}
        </div>
      }
    />

  </>
}

export default AcquisitionOrderExtinguishers
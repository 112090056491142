import { Modal } from "antd";
import CollectPointForm from "./collectPointForm";
import { DispositifTypeEnum, ICollectPoint, IStructure } from "@alcome-rep/alcome-types/dist/interfaces";

type CollectPointFormProps = {
  structure: IStructure,
  mode?: 'supportOrder' | 'acquisitionOrder',
  collectPoint?: ICollectPoint,
  onCancel: () => void,
  onOk: (collectPoint: ICollectPoint) => void,
  onDelete?: (collectPoint: ICollectPoint) => void,
  canEdit: boolean,
  dipositifTypes?: DispositifTypeEnum[]
};

const CollectPointModal = ({ structure, mode, collectPoint, onOk, onCancel, onDelete, canEdit = true, dipositifTypes }: CollectPointFormProps
) => {
  return (
    <Modal
      title="Dispositif de collecte"
      centered={false}
      destroyOnClose={true}
      forceRender={true}
      open={!!collectPoint}
      footer={null}
      closable={false}
      okButtonProps={{ className: 'btn' }}
    >
      {collectPoint !== undefined &&
        <CollectPointForm
          structure={structure}
          mode={mode ? mode : undefined}
          canEdit={canEdit}
          collectPoint={collectPoint}
          onOk={onOk}
          onDelete={onDelete}
          onCancel={onCancel}
          dipositifTypes={dipositifTypes}
        />
      }
    </Modal>
  )
}

export default CollectPointModal
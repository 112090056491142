import { IAnnualReview, ICollectPoint, IInventory, IStructure, StructureTypesEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { EditOutlined } from "@ant-design/icons";
import { Button, List } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddButton from "../../../common/components/Buttons/addButton";
import SeeMoreButton from "../../../common/components/Buttons/seeMore";
import { isAnnualReview } from "../../../common/tools/type-tools";
import mapApi from "../../services/map.api";
import CollectPointModal from "./collectPointModal";

type PropsType = {
  canEdit: boolean
  structure: IStructure
  selectedCommune?: string,
  pageSize?: number,
  layer: IInventory | IAnnualReview,
  initialCollectPoints?: ICollectPoint[]
}

const CollectPointList = ({ canEdit, structure, selectedCommune, pageSize = 6, layer, initialCollectPoints }: PropsType) => {

  const [collectPointsLimit, setCollectPointsLimit] = useState<number>(pageSize);
  const [collectPoints, setCollectPoints] = useState<ICollectPoint[]>([]);
  const [selectedCollectPoint, setSelectedCollectPoint] = useState<ICollectPoint>();
  const [filteredCollectPoints, setFilteredCollectPoints] = useState<ICollectPoint[]>([]);

  const structureId = structure.id;

  const { t } = useTranslation()

  if (canEdit && initialCollectPoints)
    canEdit = false

  const getCollectPoints = useCallback(() => {
    if (structureId)
      return mapApi.getCollectPoints(structureId)
        .then(r => setCollectPoints(r.rows))
  }, [structureId])

  useEffect(() => {
    if (!initialCollectPoints)
      getCollectPoints();
    else
      setCollectPoints(initialCollectPoints)
  }, [getCollectPoints, initialCollectPoints, setCollectPoints])


  useEffect(() => {
    const isGrp = (structure.structureType === StructureTypesEnum.groupement_communes);
    const updatedCp = [...collectPoints]
      .sort((p1: any, p2: any) => p1.communeName < p2.communeName ? 1 : -1)
      .filter(cp => selectedCommune !== undefined ? cp.communeName?.toLowerCase() === selectedCommune.toLowerCase() : isGrp ? null : true);

    setFilteredCollectPoints(updatedCp)
  }, [collectPoints, selectedCommune, structure])

  const updateData = async (data: ICollectPoint) => {
    if (!selectedCollectPoint)
      return;

    if (selectedCollectPoint.id) {
      await mapApi.updateCollectPoint(selectedCollectPoint.id, data)
    } else {
      await mapApi.createCollectPoint(data, layer.id!, isAnnualReview(layer))
    }
    setSelectedCollectPoint(undefined);
    getCollectPoints()
  }

  const deleteData = async () => {
    if (!selectedCollectPoint)
      return;
    // await mapApi.deleteCollectPoint(selectedCollectPoint.id || selectedCollectPoint._id!);
    await mapApi.deleteCollectPoint(selectedCollectPoint.id as string);
    setSelectedCollectPoint(undefined);
    getCollectPoints();
  }

  return (
    <div>
      <List
        header={<div className="flex justify-between">
            <span className="text-lg">Dispositifs de collecte</span>
            {canEdit && (<AddButton onClick={() => setSelectedCollectPoint({} as ICollectPoint)}>Ajouter un point de collecte manuellement</AddButton>)}
          </div>}
        size="small"
        dataSource={filteredCollectPoints.slice(0, collectPointsLimit)}
        renderItem={(collectPoint, index) => (
          <List.Item 
            actions={[
              <Button 
                icon={<EditOutlined className="text-alc-blue"></EditOutlined>}
                type="link" htmlType="button" onClick={() => setSelectedCollectPoint(filteredCollectPoints[index])} />
            ]}
          >
            <div className="flex flex-col">
              {collectPoint.communeName && (index === 0 || filteredCollectPoints[index - 1].communeName !== collectPoint.communeName) &&
                (<div className="font-semibold p-1">{collectPoint.communeName}</div>)
              }

              <span>{t(`TYPES.INVENTORY.PlaceTypeEnum.${collectPoint.placeType}`)}</span>
              <span className="text-xs italic">{t(`TYPES.INVENTORY.DispositifTypeEnum.${collectPoint.dispositifType}`)}</span>

              {collectPoint.comments && (
                <span className="text-xs italic">Commentaire : {collectPoint.comments}</span>
              )}
            </div>
          </List.Item>
        )}
      />

      {(filteredCollectPoints.length > collectPointsLimit) && <SeeMoreButton onClick={() => setCollectPointsLimit(collectPointsLimit + 6)}>Voir plus...</SeeMoreButton>}

      <CollectPointModal
        structure={structure}
        canEdit={canEdit}
        collectPoint={selectedCollectPoint}
        onOk={updateData}
        onDelete={deleteData}
        onCancel={() => setSelectedCollectPoint(undefined)}
      />
    </div>
  );
}

export default CollectPointList
import { IContract } from '@alcome-rep/alcome-types/dist/interfaces';
import { CloseOutlined, FilePdfOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Spin, Upload, message } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { RcFile } from 'antd/lib/upload';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTrackProgressAreas } from '../../../services/apiService';
import { useTrackProgress } from '../../../services/trackProgress';

type Props = {
    contract: IContract,
    propertyName: keyof IContract,
    label: string,
    tooltip?: string,
    isRequired?: boolean,
    isDisabled?: boolean,
    upload: (file: RcFile) => void,
    download: () => void,
    remove: () => void
}

const FileField = ({
    contract,
    propertyName,
    upload,
    download,
    remove,
    label,
    tooltip,
    isRequired = true,
    isDisabled = false }: Props) => {
    const form = Form.useFormInstance();
    const [checkFile, setCheckFile] = useState(false);

    const isLoading = useTrackProgress(getTrackProgressAreas('').fileUpload(propertyName));

    const fileProperty = contract[propertyName]

    useEffect(() => {
        if (!form)
            return;
        form.setFieldsValue({ ...form.getFieldsValue(), [propertyName]: fileProperty })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, propertyName, fileProperty])

    const { t } = useTranslation();

    const onCheckboxChange = (e: { target: { checked: boolean } }) => {
        setCheckFile(e.target.checked);
        form.validateFields()
    };

    const beforeUpload = (file: RcFile) => {
        const isPDF = file.type === 'application/pdf';
        if (!isPDF) {
            message.error(`${file.name} n'est pas un fichier pdf`);
        } else {
            upload(file)
        }
        return false;
    }

    const deleteFile = () => {
        remove()
    }

    const downloadDoc = () => {
        download();
        // const currentFileSaved = (contract as any)[field.name as string] as IFileDocument;
        // api.downloadDocument(field.name as string, `alcome-${field.name}-${currentFileSaved.timestamp}.pdf`)
    }

    return <div className='mb-4'>
        <div className={`${contract[propertyName] && 'hidden'} flex space-x-2`}>
            <div>
                <Form.Item validateTrigger={['onChange']}
                    label={label}
                    tooltip={tooltip}
                    name={propertyName}
                    className={`mb-0`}
                    valuePropName="file"
                    rules={[
                        {
                            required: !checkFile,
                            message: 'requis',
                        },
                    ]}
                >
                    <Upload
                        beforeUpload={beforeUpload}
                        accept="application/pdf"
                        showUploadList={false}>
                        <Button className='h-16 bg-gray-50'>
                            <div className='flex space-x-2 justify-center items-center'>
                                <div className=''>
                                    {!isLoading && <UploadOutlined className='text-2xl text-alc-blue'></UploadOutlined>}
                                    {isLoading && <Spin className='text-2xl text-alc-blue'></Spin>}
                                </div>
                                <div className='flex flex-col space-y-0 '>
                                    {!isLoading && [
                                        (<span key={1} className='text-alc-gray font-semibold text-lg'>{t("COMMON.FILEUPLOAD.dragndrop")}</span>),
                                        (<span key={2} className='text-alc-gray'>{t("COMMON.FILEUPLOAD.dragndrop2")}</span>)
                                    ]}
                                    {isLoading && [
                                        (<span key={1} className='text-alc-gray font-semibold text-lg'>{t("COMMON.FILEUPLOAD.loading")}</span>),
                                        (<span key={2} className='text-alc-gray'>{t("COMMON.FILEUPLOAD.wait")}</span>)
                                    ]}
                                </div>
                            </div>
                        </Button>
                    </Upload>
                </Form.Item>

            </div>
            {isRequired && (
                <Checkbox className='mt-14' onChange={onCheckboxChange}>
                    <span className="text-xs ">Me le rappeler plus tard</span>
                </Checkbox>
            )}
        </div>
        <div className={`${!contract[propertyName] && 'hidden'}`}>
            <p className='mb-2 text-alc-gray'>{label}</p>
            <div className='border-1 border-gray-200 py-1 px-2 flex justify-between'>
                <div><FilePdfOutlined className='text-alc-gray'></FilePdfOutlined></div>
                <button type="button" onClick={downloadDoc} className='btn-link w-full px-2 text-alc-gray'>{label}</button>
                <div>
                    {!isDisabled && (
                        <Button
                            className='p-0 border-none h-2'
                            onClick={() => deleteFile()}
                            icon={<CloseOutlined className='text-xs' />}>
                        </Button>
                    )}
                </div>
            </div>
        </div>
    </div>
}

export default FileField
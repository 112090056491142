import { IStructure, StructureTypesEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import ApiService from "../../common/services/apiService";
import { buildFindQuery } from "../../common/tools/global-tools";
import { CodeInsee } from "@alcome-rep/alcome-types";

const STRUCTURE_PATH_API = 'structure';

class StructureApi extends ApiService<IStructure> {
  constructor() {
    super(STRUCTURE_PATH_API)
  }

  /**
   * @description get structure by siren
   * @param query 
   * @returns StructureDoc
   */
  getBySiren(query: any) {
    const queryString = buildFindQuery({ ...query });
    return this.custom('get', `findBySiren?${queryString}`)
  }

  isRegistered(code: string) {
    return this.custom('get', 'isRegistered?code=' + code)
      .then(r => r !== false)
  }

  getInseeCodes(code: string, type?: StructureTypesEnum): Promise<CodeInsee[]> {
    return this.custom<CodeInsee[]>('get', `inseeCodes?code=${code}${type ? '&structureType=' + type : ''}`)
      .then(r => r
        .map((c) => ({
          code: String((c as any)['insee'] || (c as any)['siren']),
          nom: String(c.nom || (c as any)['grouping_name']),
          population: Number(c.population || (c as any).pop || +(c as any).population_number),
          type: c.type,
          year: c.year
        }))
      )
  }

  getStructureDepartment = (structureId: string) => {
    return this.custom<string>('get', `${structureId}/department`)
  }

}

const structureApi = new StructureApi();
export default structureApi;
import { IUserAdmin } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { Dispatch } from "@reduxjs/toolkit";
import { Button, Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { refreshTokenAction, selectUserAdmin } from "../../../../Auth/state/auth.slice";
import Section from "../../../../common/components/Panels/Section";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks";
import useFormRules from "../../../../common/hooks/useFormRules";
import userAdminApi from "../../../services/user-admin.api";
import { county } from "../../../../common/tools/county.constants";

const AdminProfilDataForm = () => {

    const user = useAppSelector(selectUserAdmin);
    const { t } = useTranslation();

    const { required, email } = useFormRules();
    const dispatch: Dispatch<any> = useAppDispatch()

    const submit = async (userData: IUserAdmin) => {
        try {
            await userAdminApi.updateMe(userData)
            user && dispatch(refreshTokenAction(user?.userType))
        } catch { }

    }

    return (
        <Section title={<span>Mes données personnelles</span>}>
            <Form
                name="basic"
                initialValues={user}
                layout='vertical'
                onFinish={submit}
            >
                <Form.Item
                    label={t('UI.PROFIL.firstName')}
                    name="firstName"
                    rules={[required]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label={t('UI.PROFIL.lastName')}
                    name="lastName"
                    rules={[required]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label={t('UI.PROFIL.email')}
                    name="email"
                    rules={[required, email]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label="Département(s) à charges"
                    name="departments"
                >
                    <Select
                        placeholder="Liste des départements"
                        mode="multiple"
                        options={county.map(v => ({ label: `${v.name} ${v.code}`, value: v.code }))}
                        allowClear
                        filterOption={(input, option) => {
                            const rexp = new RegExp('^' + input, 'i');
                            return rexp.test(option?.label ?? '') || rexp.test(option?.value ?? '')
                        }}
                    />

                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" type="primary" className="bg-alc-blue">
                        Modifier les paramètres
                    </Button>
                </Form.Item>
            </Form>
        </Section>
    )
}

export default AdminProfilDataForm
import { useEffect, useState } from "react";
import AmountValue from "../../../common/components/Fields/AmountValue";
import DList from "../../../common/components/Panels/DList";
import Section from '../../../common/components/Panels/Section';
import Info from "../../../common/components/WarningUser/Info";
import { useAppSelector } from "../../../common/hooks";
import { selectCurrentAdminProspect } from "../../state/prospect-admin.slice";
import contractAdminApi from "../../../Contract/services/contract-admin.api";
import { Models } from "@alcome-rep/alcome-types";
import inventoryAdminApi from "../../../Inventory/services/inventory-admin.api";
import DateField from "../../../common/components/Fields/DateField";
import ContractStatus from "../../../Contract/components/admin/ContractData/ContractStatus";
import InventoryStatus from "../../../Inventory/components/admin/InventoryData/InventoryStatus";
import annualReviewAdminApi from "../../../AnnualReview/services/annual-review-admin.api";
import AnnualReviewStatus from "../../../AnnualReview/components/common/AnnualReviewData/AnnualReviewStatus";


const ProspectStructureInfos = () => {

  const prospect = useAppSelector(selectCurrentAdminProspect)
  const [contract, setContract] = useState<Models.Contract>()
  const [inventory, setInventory] = useState<Models.Inventory>()
  const [annualReviews, setAnnualReviews] = useState<Models.AnnualReview[]>()

  useEffect(() => {
    if (!prospect || !prospect.structureId)
      return;

    contractAdminApi.getContractByStructure(prospect.structureId)
      .then(s => setContract(s))
    inventoryAdminApi.getInventoryByStructureId(prospect.structureId)
      .then(s => setInventory(s))
    annualReviewAdminApi.getStructureAnnualReviews(prospect.structureId)
      .then(s => setAnnualReviews(s.rows))

  }, [prospect])

  if (!prospect) {
    throw new Error()
  }

  return (
    <>
      {!prospect.structureId && (<Info body="La structure n'est pas encore inscrite" />)}
      <div className="grid grid-cols-3 gap-4 my-4">
        <div className="col-span-3">
          <Section
            title="Aide financière"
          >
            <DList items={[
              { title: 'Montant estimé', description: <AmountValue amount={prospect.amountStatement} precision={2} /> },
              { title: "Cendriers", description: <AmountValue amount={prospect.ashtrayQuantity} hideSuffix={true} /> },
            ]} />
          </Section >
        </div>

        <div className="col-span-1">
          < Section
            title="Contrat"
          >
            {!contract && 'N/A'}
            {contract &&
              <DList items={[
                { title: "Status", description: <ContractStatus contract={contract} /> },
                { title: 'Date', description: <DateField value={contract.contractDate} /> },
              ]} />
            }
          </Section >
        </div>
        <div className="col-span-1">
          < Section
            title="Annexes"
          >
            {!inventory && 'N/A'}
            {inventory &&
              <DList items={[
                { title: "Status", description: <InventoryStatus inventory={inventory} /> },
                { title: 'Date', description: <DateField value={inventory.date} /> },
              ]} />
            }
          </Section >
        </div>

        <div className="col-span-1">
          <Section
            title="Dernier bilan"
          >
            {!annualReviews && 'N/A'}
            {annualReviews && annualReviews.length &&
              <DList items={[
                { title: "Status", description: <AnnualReviewStatus annualReview={annualReviews[0]} /> },
                { title: 'Année', description: new Date(annualReviews[0].startDate).getFullYear() },
              ]} />
            }
          </Section >
        </div>

        <div className="col-span-2">
        </div>

      </div >

    </>
  );
};

export default ProspectStructureInfos;
import { ApiListResult, ILogAction, Models } from "@alcome-rep/alcome-types";
import { AnnualReviewStatusEnum, IAnnualReview } from "@alcome-rep/alcome-types/dist/interfaces";
import { AnnualReview } from "@alcome-rep/alcome-types/dist/models";
import { message } from "antd";
import { UploadDocumentPayload } from "../../Inventory/state/fileDoc.types";
import ApiService, { ApiSortType, getTrackProgressAreas } from "../../common/services/apiService";
import { AdminUpdateRevenueOrderFilePayload } from "../state/types";
import { findNotExportedRevenueOrderType } from "../../AccountingExport/components/AccountingExport/AccountingExport";

const ANNUALREVIEW_ADMIN_PATH_API = 'admin/annualReview';

export const progressKeys = {
  upload: getTrackProgressAreas(ANNUALREVIEW_ADMIN_PATH_API).custom('POST'),
}

class AnnualReviewAdminApi extends ApiService<AnnualReview> {
  constructor() {
    super(ANNUALREVIEW_ADMIN_PATH_API)
  }

  /**
   * @description find all annual reviews
   * @param query
   * @param page page you want
   * @param pageSize number of items per page
   * @param sort sort object
   * @returns annual reviews list
   */
  findAll(query: any, page: number, pageSize: number, sort?: ApiSortType) {
    return this.find({ query, page, pageSize })
  }

  /**
   * @description find structure annual reviews
   * @param query
   * @returns structure annual reviews list
   */
  getStructureAnnualReviews(structureId: string) {
    return this.custom<ApiListResult<AnnualReview>>("get", "byStructure/:structureId", { structureId })
      .then(r => ({
        count: r.count,
        rows: r.rows.map(ar => new Models.AnnualReview(ar))
      }))
  }

  /**
   * @description update an annual review
   * @param annualReviewId 
   * @param data 
   * @returns Updated annual review
   */
  updateAnnualReview(annualReviewId: string, data: any) {
    return this.update(annualReviewId, data)
  }

  /**
   * @description create an annual review for a specific year
   * @param data 
   * @returns Created annual review
   */
  createAnnualReview(structureId: string, year: number) {
    return this.custom<AnnualReview>("post", structureId, undefined, { year })
      .then(r => new AnnualReview(r))
  }

  /**
   * @description change annualReview status
   * @param annualReviewId 
   * @param changeStatus 
   * @returns annualReview updated
   */
  changeStatus(annualReviewId: string, changeStatus: { status: AnnualReviewStatusEnum, dontNotif: boolean }): Promise<IAnnualReview> {
    return this.custom('put', `${annualReviewId}/changeStatus`, undefined, {changeStatus})
  }

  /**
   * @description Admin find if users downloaded kits
   * @param annualReviewId 
   * @returns Array of downloaded kits logActions
   */
  adminfindKitsDownloaded(annualReviewId: string): Promise<ILogAction[]> {
    return this.custom('get', `kitDownloads/${annualReviewId}`)
  }

  // ====== ANNUALREVIEW FILE ======
  uploadAnnualReviewFile = (data: UploadDocumentPayload<AnnualReview>) => {
    return this.uploadFileDoc<AnnualReview>(data.propertyName, data.file, data.id)
      .then(r => new Models.AnnualReview(r))
  }

  downloadAnnualReviewFile = (propertyName: keyof Models.AnnualReview, fileName: string) => {
    return this.downloadFileDoc(propertyName, undefined, fileName)
  }

  getAnnualReviewFile = (propertyName: keyof AnnualReview, annualReviewId: string) => {
    return this.getFileDoc(propertyName, annualReviewId)
  }

  deleteAnnualReviewFile = (propertyName: keyof Models.AnnualReview, entityId: string) => {
    return this.deleteFileDoc(propertyName, entityId)
  }


  adminDownloadArrayFile = (annualReviewId: string, propertyName: string, subPropertyName: string, fileName: string) => {
    return this.downloadArrayFileDoc(annualReviewId, propertyName, subPropertyName, fileName)
  }

  adminValidateStatus = ({ annualReviewId }: { annualReviewId: string }) => {
    return this.custom<IAnnualReview>('put', `${annualReviewId}/validateStatus`)
      .then(res => {
        if (res.status === 200) {
          message.success('Bilan validé')
        }
        return res;
      })
  }

  adminRefuseStatus = ({ annualReviewId, comment }: { annualReviewId: string, comment?: string }) => {
    return this.custom<IAnnualReview>('put', `${annualReviewId}/refuseStatus`, undefined, { comment })
      .then(res => {
        if (res.status === 200) {
          message.success('Bilan refusé')
        }
        return res;
      })
  }

  generateAnnualReview = (annualReviewId: string) => {
    return this.custom('post', `generate/${annualReviewId}`)
      .then(res => {
        message.success('Bilan généré !')
        return res;
      })
  }

  setStatement = ({ annualReviewId }: { annualReviewId: string }) => {
    return this.custom('put', `${annualReviewId}/statement`)
      .then(res => {
        message.success('Calcul du décompte liquidatif effectué !')
        return res;
      })
  }

  generateStatementDocument = ({ annualReviewId }: { annualReviewId: string }) => {
    return this.custom('post', `${annualReviewId}/generateStatement`)
      .then(res => {
        message.success('Le décompte liquidatif a été généré avec succès !')
        return res;
      })
  }

  adminValidateStatement = ({ annualReviewId }: { annualReviewId: string }) => {
    return this.custom('put', `${annualReviewId}/validateStatement`)
      .then(res => {
        message.success('Le décompte liquidatif a été validé !')
        return res;
      })
  }

  adminAcceptOrder = ({ annualReviewId }: any) => {
    return this.custom<IAnnualReview>('put', `${annualReviewId}/acceptOrder`)
      .then(res => {
        if (res.status === 200) {
          message.success('Titre de recette accepté')
        }
        return res;
      })
  }

  adminRefuseOrder = ({ annualReviewId, revenueOrderComment }: any) => {
    return this.custom<IAnnualReview>('put', `${annualReviewId}/refuseOrder`, undefined, { revenueOrderComment })
      .then(res => {
        if (res.status === 200) {
          message.success('Titre de recette refusé')
        }
        return res;
      })
  }

  adminUpdateFileStatus = ({ annualReviewId, propertyName, data }: { annualReviewId: string, propertyName: string, data: AdminUpdateRevenueOrderFilePayload }) => {
    return this.custom<IAnnualReview>('put', `fileDoc/${annualReviewId}/${propertyName}`, undefined, data)
      .then(res => {
        if (res.status === 200) {
          message.success('Statut du fichier mis à jour')
        }
        return res;
      })
  }

  adminSetStatementPaid = ({ annualReviewId, amountStatement, paymentDate }: { annualReviewId: string, amountStatement: number, paymentDate: Date }) => {
    return this.custom<IAnnualReview>('put', `${annualReviewId}/statementPaid`, undefined, { amountStatement, paymentDate })
      .then(res => {
        if (res.status === 200) {
          message.success('Statut du bilan mis à jour')
        }
        return res;
      })
  }

  findStatementsToExport = (year: number, month: number) => {
    return this.custom<findNotExportedRevenueOrderType[]>('get', `statementsToExport?year=${year}&month=${month}`)
      .then(res => res)
  }

}

const annualReviewAdminApi = new AnnualReviewAdminApi();
export default annualReviewAdminApi;
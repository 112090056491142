import { BaseCommune } from "@alcome-rep/alcome-types/dist/models";
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../common/store";
import { STRUCTURE_PORTAL_SERVICE, StructureServiceState, UpdateStructureCommunesPayload } from "./types";

const structureInitialState: StructureServiceState = {
  structureForm: {} as BaseCommune,
  currentStructure: null
};

export const structurePortalSlice = createSlice({
  name: STRUCTURE_PORTAL_SERVICE,
  initialState: structureInitialState,
  reducers: {

    getMyStructureAction: (state: StructureServiceState, _payload: PayloadAction) => {
      state.currentStructure = null;
    },

    updateStructureInfosAction: (state: StructureServiceState, _payload: PayloadAction<any>) => {
      state.currentStructure = null;
    },

    updateStructureCommunesAction: (state: StructureServiceState, _payload: PayloadAction<UpdateStructureCommunesPayload>) => {
      state.currentStructure = null;
    },

    setCurrentStructureAction: (state: StructureServiceState, { payload: structure }: PayloadAction<BaseCommune>) => {
      state.currentStructure = structure;
    },

    unsetCurrentStructureAction: (state: StructureServiceState, _payload: PayloadAction) => {
      state.currentStructure = null;
    },


  }
});


export const {
  getMyStructureAction,
  updateStructureInfosAction,
  updateStructureCommunesAction,
  setCurrentStructureAction,
  unsetCurrentStructureAction,
} = structurePortalSlice.actions;

export default structurePortalSlice.reducer;

export const selectStructureService = (state: RootState) => state.structurePortalService
export const selectCurrentStructure = createSelector(selectStructureService, (structureSvc) => structureSvc.currentStructure)
export const selectStructureForm = createSelector(selectStructureService, (structureSvc) => structureSvc.structureForm)
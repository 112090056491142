import { Models } from '@alcome-rep/alcome-types';
import { ContractStatusEnum, StructureTypesEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import { InterestValueEnum, ProspectTypesEnum } from '@alcome-rep/alcome-types/dist/interfaces/crm';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { selectUserAdmin } from '../../../Auth/state/auth.slice';
import FilterDelegate from '../../../common/components/Filters/FilterDelegate';
import FilterDepartment from '../../../common/components/Filters/FilterDepartment';
import FilterEntity from '../../../common/components/Filters/FilterEntity';
import FilterPanel from '../../../common/components/Panels/FilterPanel';
import PageCanvas from '../../../common/components/Panels/PageCanvas';
import QuickLinksPanel from '../../../common/components/Panels/QuickLinksPanel';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import useQueryParams from '../../../common/hooks/useQueryParams';
import useTableChange from '../../../common/hooks/useTableChange';
import { ROUTES } from '../../../common/router';
import { closeDrawerAction, openDrawerAction } from '../../../common/state/modal.slice';
import prospectAdminApi from '../../services/prospect-admin.api';
import CreateProspect from '../CreateProspect/CreateProspect';
import { prospectListColumns } from './ProspectListComponent.constant';

const ProspectListComponent = () => {
  const [data, setData] = useState<Models.Prospect[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setloading] = useState<boolean>(false);
  
  const dispatch = useAppDispatch();
  // const [, setOpen] = useState(false);
  const user = useAppSelector(selectUserAdmin)
  let [searchParams, setSearchParams] = useSearchParams();

  const tableChange = useTableChange();
  const queryParams = useQueryParams()

  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    setloading(true)
    prospectAdminApi.findAll({ query: queryParams.query, page: Number(queryParams.page), limit: Number(queryParams.limit) })
      .then(res => {
        setData(res.rows)
        setCount(res.count)
        setloading(false)
      })
      .catch(() => {
        setData([])
        setCount(0)
        setloading(false)
      })
  }, [setData, setCount, setloading, queryParams])

  const showDrawer = () => {
    // setOpen(true);
    dispatch(openDrawerAction({
      options: { title: 'Créer une fiche de prospection', size: "large" },
      component: <CreateProspect onSubmit={() => dispatch(closeDrawerAction())} />

    }))
  };

  const [value, setValue] = useState("");
  const quickSearch = (str: string) => {
    if (str === ""){
      // navigate('')
      setSearchParams(searchParams);
    }
    else if (/^\d/.test(str) === true){
      searchParams.set('siren', `/${str}/`);
      setSearchParams(searchParams);
      // navigate(`?siren=/${str}/`)
    }
    else{
      searchParams.set('name', `/${str}/`);
      setSearchParams(searchParams);
      // navigate(`?name=/${str}/`)
    }
  }

  return (
    <>
      <PageCanvas
        breadCrumbs={[
          ROUTES.ADMIN_USER_ROUTES.home,
          ROUTES.ADMIN_CRM.ADMIN_PROSPECT_ROUTES.prospectList
        ]}
        title={<span>Fiches de prospection <small>({count ? count : 0})</small ></span >}
        actions={<Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
          Nouvelle fiche de prospection
        </Button>}
      >
        <FilterPanel>
          <FilterDelegate initialValue={user.id} />

          <FilterEntity
            filterName='Type de structure'
            multiple={false}
            queryParamName='structureType'
            options={
              Object.keys(StructureTypesEnum)
                .filter(k => typeof k === 'string')
                // .filter(v => v !== StructureTypesEnum.member_structure)
                .map(k => ({ label: t('TYPES.STRUCTURE.StructureTypesEnum.' + k), value: k }))
            }
          />
          <FilterEntity
            filterName='Type de prospect'
            multiple={false}
            queryParamName='prospectType'
            options={
              Object.keys(ProspectTypesEnum)
                .filter(k => typeof k === 'string')
                .map(k => ({ label: t('TYPES.STRUCTURE.ProspectTypesEnum.' + k), value: k }))
            }
          />
          <FilterDepartment />
          <FilterEntity
            filterName='Statut du contrat'
            multiple={false}
            queryParamName='contractStatus'
            options={
              Object.keys(ContractStatusEnum)
                .filter(v => !isNaN(+v))
                .map(k => ({ label: t('TYPES.CONTRACT.ContractStatusEnum.' + ContractStatusEnum[k as any]), value: k }))
            }
          />
          <FilterEntity
            filterName='Intérêt du prospect'
            multiple={false}
            queryParamName='interestValue'
            options={
              Object.keys(InterestValueEnum)
                .filter(k => typeof k === 'string')
                .map(k => ({ label: t('TYPES.STRUCTURE.InterestValueEnum.' + k), value: k }))
            }
          />
          <FilterEntity
            filterName='Nettoiement'
            multiple={false}
            queryParamName='inChargeOfCleaning'
            options={[{ label: 'En charge du nettoiement', value: "true" }, { label: 'Déchargé du nettoiement', value: "false" }]}
          />


        </FilterPanel>

        <QuickLinksPanel>
          <Input
            type="text"
            placeholder='Recherche rapide par nom ou siren'
            value={value}
            onChange={(e) => {
              quickSearch(e.target.value)
              setValue(e.target.value)
            }}
          />
        </QuickLinksPanel >

        <div className=''>
          <Table<Models.Prospect>
            columns={prospectListColumns(queryParams.query, t, navigate)}
            dataSource={data}
            loading={loading}
            onChange={tableChange}
            size="small"
            rowKey="_id"
            // scroll={{ scrollToFirstRowOnChange: true, x: 1800 }}
            pagination={
              {
                hideOnSinglePage: true,
                current: queryParams.page,
                pageSize: queryParams.limit,
                total: count,
              }
            } />
        </div>
      </PageCanvas >

    </>
  );
};

export default ProspectListComponent;
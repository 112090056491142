import { RouteObject } from "react-router-dom";
import AdminSupplierOrderInfos from "../components/admin/AdminSupplierOrderInfos/AdminSupplierOrderInfos";
import AdminSupplierOrderList from "../components/admin/AdminSupplierOrderList/AdminSupplierOrderList";
import SupplierOrderLayout from "../components/layout/SupplierOrderLayout";
import AdminSupplierOrderInvoices from "../components/admin/AdminSupplierOrderInvoices/AdminSupplierOrderInvoices";
import AdminSupplierOrderDeliveryNotes from "../components/admin/AdminSupplierOrderDeliveryNotes/AdminSupplierOrderDeliveryNotes";

const routePrefix = 'admin'
const routeDomain = 'supplierOrder'
const routeParam = 'supplierOrderId'

export const adminSupplierOrderRouteNames = {
  supplierOrderList: { path: `${routePrefix}/${routeDomain}/list`, title: `ROUTES.${routeDomain}.list` },
  supplierOrderDetails: { path: `${routePrefix}/${routeDomain}/details/:${routeParam}`, title: `ROUTES.${routeDomain}.details` },
  supplierOrderInfos: { path: `${routePrefix}/${routeDomain}/details/:${routeParam}/infos`, title: `ROUTES.${routeDomain}.infos` },
  supplierOrderInvoices: { path: `${routePrefix}/${routeDomain}/details/:${routeParam}/invoices`, title: `ROUTES.${routeDomain}.invoices` },
  supplierOrderDeliveryNotes: { path: `${routePrefix}/${routeDomain}/details/:${routeParam}/deliveryNotes`, title: `ROUTES.${routeDomain}.delivery` },
}

export const adminSupplierOrderRoutes: RouteObject[] = [
  {
    path: '',
    children: [
      {
        path: adminSupplierOrderRouteNames.supplierOrderList.path.replace(`${routePrefix}/`, ''),
        element: <AdminSupplierOrderList />
      },
      {
        path: adminSupplierOrderRouteNames.supplierOrderDetails.path.replace(`${routePrefix}/`, ''),
        element: <SupplierOrderLayout />,
        children: [
          {
            path: 'infos',
            element: <AdminSupplierOrderInfos />
          },
          {
            path: 'invoices',
            element: <AdminSupplierOrderInvoices />
          },
          {
            path: 'deliveryNotes',
            element: <AdminSupplierOrderDeliveryNotes />
          }
        ]
      },
    ]
  }
]


import { DispositifTypeEnum, GeoPoint, ICollectPoint, ISupportOrders, SupportOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { CloseOutlined, DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber, Modal, Upload, UploadProps } from "antd";
import { RcFile } from "antd/lib/upload";
import { useState } from "react";
import { selectUser } from "../../../../Auth/state/auth.slice";
import CollectPointModal from "../../../../Map/components/CollectPoint/collectPointModal";
import MapComponent from "../../../../Map/components/Gmap/mapComponent";
import mapApi from "../../../../Map/services/map.api";
import { selectCurrentStructure } from "../../../../Structure/state/structure-portal.slice";
import { useAppSelector } from "../../../../common/hooks";
import supportOrderApi from "../../../services/supportOrder.api";

const SupportOrderEteignoirsSelection = ({ supportOrder }: { supportOrder: ISupportOrders }) => {

  const structure = useAppSelector(selectCurrentStructure);

  const [currentCollectPoint, setCurrentCollectPoint] = useState<ICollectPoint>();
  const [currentCollectPointKey, setCurrentCollectPointKey] = useState<number>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const user = useAppSelector(selectUser);
  const isAdmin = user!.userType === UserTypeEnum.admin;
  const canEdit = !isAdmin && supportOrder.status === SupportOrderStatusEnum.support_order_declarations_pending;

  const openModal = (collectPointKey: number) => {
    setCurrentCollectPointKey(collectPointKey)
    setIsModalOpen(true)
  }

  const insertGeoPoint = (geoPoint: GeoPoint | GeoPoint[]) => {
    setCurrentCollectPoint({
      geoPoint: Array.isArray(geoPoint) ? geoPoint[0] : geoPoint
    } as ICollectPoint)

    setIsModalOpen(false)
  }

  const handleOk = async (data: ICollectPoint) => {
    const extinguishers = form.getFieldValue('extinguishers') as ISupportOrders['extinguishers'];
    if (extinguishers !== undefined) {
      const cp = (await mapApi.createCollectPoint(data, supportOrder.annualReviewId, true))
      extinguishers[currentCollectPointKey!] = cp;
      form.setFieldValue('extinguishers', extinguishers)
    }

    if (supportOrder) {
      const support = form.getFieldsValue();
      await supportOrderApi.updateSupportOrder(supportOrder._id as string, support)
      setCurrentCollectPoint(undefined)
    }
  }

  const handleCancel = () => {
    setCurrentCollectPoint(undefined)
  }

  const handleCancelModal = () => {
    setIsModalOpen(false)
  }

  const updatePrice = (collectPoint: ICollectPoint, value: number) => {
    mapApi.updateCollectPoint(collectPoint.id!, {
      price: value,
      placeType: collectPoint.placeType,
      dispositifType: collectPoint.dispositifType,
      geoPoint: collectPoint.geoPoint,
    } as ICollectPoint)

    const extinguishers: ISupportOrders['extinguishers'] = form.getFieldValue('extinguishers');
    const cp = extinguishers!.find(cp => cp?.id === collectPoint.id)
    if (cp) {
      cp.price = value;
      supportOrderApi.updateSupportOrder(supportOrder._id!, { extinguishers })

    }
  }

  const deleteCollectPoint = async (collectPoint: ICollectPoint) => {
    await Modal.confirm({
      title: "Suppression",
      content: 'Êtes-vous sûr de vouloir supprimer cet éteignoir ?',
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      async onOk() {
        const extinguishers: ISupportOrders['extinguishers'] = form.getFieldValue('extinguishers');
        const cpIndex = extinguishers!.findIndex(cs => cs.id === collectPoint.id);
        if (cpIndex >= 0) {
          extinguishers!.splice(cpIndex, 1)

          await supportOrderApi.updateSupportOrder(supportOrder._id!, { extinguishers })
          await mapApi.deleteCollectPoint(collectPoint._id!);
          extinguishers![cpIndex] = {} as any
          form.setFieldValue('extinguishers', extinguishers)
        }

      }
    })

  }

  const form = Form.useFormInstance()

  // const changeCollectPointSelection = (selection: ICollectPoint | IHotspot) => {
  //   if (hotspotKey !== undefined && collectPointKey !== undefined) {
  //     const hotspots = form.getFieldValue(`hotspots`);
  //     hotspots[hotspotKey]['collectPoints'][collectPointKey] = selection;
  //     form.setFieldValue('hotspots', hotspots)
  //   }

  // }

  const getProps = (collectpointId: string): UploadProps => ({
    beforeUpload: file => {
      addPictureToCp(collectpointId, file);
      return false;
    }
  })

  const addPictureToCp = (collectpointId: string, file: RcFile) => {
    mapApi.uploadPictureInstallation(supportOrder.id as string, collectpointId, file, "true")
      .then(r => {
        const extinguishers = form.getFieldValue('extinguishers') as ISupportOrders['extinguishers'];
        const findCp = extinguishers!.find(ex => ex.id === collectpointId)

        if (r && findCp) {
          findCp.pictureInstallation = r.pictureInstallation
          form.setFieldValue('extinguishers', extinguishers)
        }
      })
  }

  const downloadPicture = async (collectpoint: ICollectPoint) => {
    await mapApi.downloadPicture(collectpoint, collectpoint.structureId)
  }


  return <>

    <Modal
      className="h-dvh !w-10/12 !lg:w-9/12"
      title="Ajouter un éteignoir"
      open={isModalOpen}
      onCancel={handleCancelModal}
      destroyOnClose={true}
      footer={false}
    >
      <p>Ajoutez un éteignoir en sélectionnant le pointeur dans le menu de la carte.</p>
      <div className="container">
        <MapComponent
          structure={structure!}
          editMode="clickLocation"
          onMapInsert={insertGeoPoint}
        />
      </div>
    </Modal>

    <CollectPointModal
      structure={structure!}
      mode="supportOrder"
      canEdit={true}
      collectPoint={currentCollectPoint}
      onOk={handleOk}
      onCancel={handleCancel}
      dipositifTypes={[
        DispositifTypeEnum.eteignoir_corbeille,
        DispositifTypeEnum.cendrier_corbeille
      ]}
    />

    {
      <div className="ant-table-container">
        <div className="text-alc-blue">{supportOrder.extinguisherCount} dispositifs commandés</div>
        <div>
          <table style={{ tableLayout: 'auto' }} className="w-full">
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell">Type de dispositifs</th>
                <th className="ant-table-cell">Prix unitaire</th>
                {canEdit && <th className="ant-table-cell" style={{ minWidth: "100px" }}>Photo du dispositif</th>}
                {canEdit && <th className="ant-table-cell">Action</th>}
              </tr>
            </thead>
            <tbody>
              <Form.List name={['extinguishers']}>
                {(fields) => (
                  fields.map(field => {

                    const collectPoint = form.getFieldValue('extinguishers')[field.key] as ICollectPoint;

                    if (!collectPoint.id)
                      return (
                        <>
                          <tr key={field.key}>
                            <td colSpan={isAdmin ? 2 : 3} className="text-left">
                              {canEdit && <Button type="link" onClick={_ => openModal(field.key)}>N° {(field.key + 1)} : Cliquez pour déclarer l'installation du dispositif</Button>}
                            </td>
                          </tr>
                          {!canEdit && <div className="text-alc-blue font-semibold mt-4">Éteignoir en attente</div>}
                        </>
                      )

                    return (
                      <tr key={field.key}>
                        <td>
                          Éteignoir
                        </td>
                        <td className="ant-table-cell  ">
                          {canEdit && <Form.Item className="m-0" name={[field.key, 'price']}>
                            <InputNumber
                              className="input-text"
                              addonAfter="€"
                              onChange={value => value !== null && +value > 0 && updatePrice(collectPoint, +value)}
                            />
                          </Form.Item>
                          }
                          {!canEdit && collectPoint.price + ' €'}
                        </td>
                        {collectPoint.pictureInstallation ?
                          <Button
                            icon={<DownloadOutlined />}
                            className="btn-link"
                            onClick={() => downloadPicture(collectPoint)}
                          >Télécharger la photo</Button>
                          :
                          <td>
                            {
                              canEdit ?
                                <Upload {...getProps(collectPoint.id)}>
                                  <Button htmlType='button' icon={<UploadOutlined />}>Chargez votre photo</Button>
                                </Upload>
                                :
                                <div>Aucune photo pour le moment.</div>
                            }
                          </td>
                        }
                        {canEdit && <td className="text-right">
                          {collectPoint.id && <Button type="link" icon={<CloseOutlined className="text-alc-danger text-sm" />} onClick={() => deleteCollectPoint(collectPoint)}></Button>}
                        </td>
                        }
                      </tr>
                    )
                  })
                )}
              </Form.List>
            </tbody>
          </table>
        </div>
      </div >
    }
  </>
}

export default SupportOrderEteignoirsSelection
import { IAcquisitionOrders } from '@alcome-rep/alcome-types/dist/interfaces';
import { call, put, takeLatest } from 'redux-saga/effects';
import createEntityDucksFactory from '../../common/state/ducks.factory';
import acquisitionOrderAdminApi from '../services/admin-acquisitionOrder.api';
import { getAdminAcquisitionOrderAction, setCurrentAdminAcquisitionOrderAction, updateAdminAcquisitionOrderItemStatusActions, updateAdminAcquisitionOrderStatusActions } from './acquisitionOrder-admin.slice';

/**
 * SAGAS
 */

const actions = createEntityDucksFactory<IAcquisitionOrders>({
  ...acquisitionOrderAdminApi,
  get: acquisitionOrderAdminApi.getAcquisitionOrder,
  update: acquisitionOrderAdminApi.updateOrderStatus
},
  {
    get: getAdminAcquisitionOrderAction,
    set: setCurrentAdminAcquisitionOrderAction,
    update: updateAdminAcquisitionOrderStatusActions,
  })

function* adminUpdateItemStatus({ payload: { acquisitionOrderId, itemIndex, status } }: ReturnType<typeof updateAdminAcquisitionOrderItemStatusActions>) {
  try {
    const resp: IAcquisitionOrders = yield call(acquisitionOrderAdminApi.updateItemOrderStatus, acquisitionOrderId, itemIndex, status);

    yield put(setCurrentAdminAcquisitionOrderAction(resp));
  } catch {
    yield put(setCurrentAdminAcquisitionOrderAction(null));
  }
}


export function* acquisitionAdminSaga() {
  yield takeLatest(getAdminAcquisitionOrderAction, actions.get);
  yield takeLatest(updateAdminAcquisitionOrderStatusActions, actions.update);
  yield takeLatest(updateAdminAcquisitionOrderItemStatusActions, adminUpdateItemStatus);
}

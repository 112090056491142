import { Models } from "@alcome-rep/alcome-types";
import apiClient from "../../common/services/apiClient";
import { trackProgress } from "../../common/services/trackProgress";
import { AppAuth } from "../types";
import ApiService from "../../common/services/apiService";
import { IAuth, IUser } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { AuthStateType } from "../state/types";

const AUTH_PATH_API = 'auth';

export const progressKeys = {
  login: `POST_${AUTH_PATH_API}login`,
  refreshToken: `POST_${AUTH_PATH_API}refreshToken`,
}

class AuthApi extends ApiService<IAuth> {
  constructor() {
    super(AUTH_PATH_API)
  }

  /**
  * @description alias for create 
  * @param userInfos 
  * @returns 
  */
  register(userInfos: Partial<IUser>) {
    return this.custom('post', 'register', undefined, userInfos)
  }

  /**
   * @description Login 
   * @param email 
   * @param password 
   * @returns Token & user infos
   */
  login(email: string, password: string) {
    return trackProgress(
      apiClient.post<AppAuth>(`${AUTH_PATH_API}/login`, { email, password }),
      progressKeys.login
    ).then(r => ({ token: r.data.token, user: new Models.User(r.data.user) }))
  }

  /**
   * @description Refresh token
   * @param token 
   * @returns Refreshed user & token
   */
  refreshToken(token: string) {
    return trackProgress(
      apiClient.post<AppAuth>(`${AUTH_PATH_API}/refreshToken`, { token }),
      progressKeys.refreshToken
    ).then(r => ({ token: r.data.token, user: new Models.User(r.data.user) }))
  }

  /**
   * @description Admin login as an other user
   * @param userId 
   * @returns Targeted user infos
   */
  loginAs = (userId: string): Promise<AuthStateType<Models.User>> => {
    return this.custom<AuthStateType<Models.User>>('post', `loginAs`, undefined, { userId })
  }

}

const authApi = new AuthApi();
export default authApi;
import { BaseCommune } from "@alcome-rep/alcome-types/dist/models";
import { useTranslation } from "react-i18next";

type PropsType = {
  structure: BaseCommune
}

const CommuneType = ({ structure }: PropsType) => {
  const { t } = useTranslation();
  return <span>{structure.communes.length && structure.communes[0].type ? t('TYPES.STRUCTURE.CommuneTypeEnum.' + structure.communes[0].type) : '-'}</span>
}

export default CommuneType;
import { ContractStatusEnum, IContract } from '@alcome-rep/alcome-types/dist/interfaces';
import { Button, Table, TablePaginationConfig, Tag } from 'antd';
import { ColumnsType, FilterValue } from 'antd/lib/table/interface';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import contractAdminApi from '../../../Contract/services/contract-admin.api';
import { ROUTES } from '../../../common/router';
import { getRoute } from '../../../common/tools/router-tools';
import { EditOutlined } from '@ant-design/icons';
import { Models } from '@alcome-rep/alcome-types';
import { Contract } from '@alcome-rep/alcome-types/dist/models';
import ContractStatus from '../../../Contract/components/admin/ContractData/ContractStatus';

const ContractToValidateList: React.FC = () => {

  const navigate = useNavigate();

  const [contractFileStatus, setContractFileStatus] = useState<string | null>(null)
  const [status, setStatus] = useState<number>(10);
  const [data, setData] = useState<IContract[]>([]);
  const [, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [, setCurrentQuery] = useState<any>({})
  const [loading, setloading] = useState<boolean>(true);

  const getData = useMemo(() => async (
    pagination: TablePaginationConfig = { pageSize: 10, current: 1 },
    filters?: Record<string, FilterValue | null>,
  ) => {
    setloading(true)
    if (contractFileStatus !== null) {
      const data = await contractAdminApi.findContracts({ status, contractFileStatus: contractFileStatus }, pagination.current, pagination.pageSize)
      setData(data.rows)
      setloading(false)
    } else {
      const data = await contractAdminApi.findContracts({ status }, pagination.current, pagination.pageSize)
      setData(data.rows)
      setloading(false)
    }
    setCurrentPage(pagination.current as number)
    setPageSize(pagination.pageSize as number)
    setCurrentQuery(filters)
  }, [contractFileStatus, status])

  useEffect(() => {
    getData();
  }, [getData, pageSize]);

  const columns: ColumnsType<Models.Contract> = [
    {
      fixed: 'left',
      width: '50px',
      render: (value: any) => {
        return (
          <div className='flex space-x-2'>
            <Button
              className="btn-link text-alc-blue font-semibold text-lg px-0"
              onClick={() => navigate(getRoute(ROUTES.ADMIN_STRUCTURE_ROUTES.structureDetails, { structureId: value.structure.id }))}
              icon={<EditOutlined />}
            />
          </div>
        )
      }
    },
    {
      title: 'Structure',
      dataIndex: ['structure', 'name'],
      key: 'structure.name',
      align: 'center' as const,
      render: (value: any, item: Models.Contract) => {
        return <Link className='font-semibold' to={getRoute(ROUTES.ADMIN_STRUCTURE_ROUTES.structureDetails, { structureId: item.structure!.id })}>{value}</Link>
      }
    },
    {
      title: 'Insee / Siren',
      dataIndex: ['structure', 'siren'],
      key: 'structure.siren',
      align: 'center' as const,
      render: (value: any) => {
        return <div className=' text-center'>{value}</div>
      }
    },
    {
      title: 'Type de structure',
      dataIndex: ['structure', 'structureType'],
      key: 'structure.structureType',
      render: (value: any) => {
        if (value === 'groupement_communes') return (<Tag color='blue'>GROUPEMENT</Tag>)
        else return (<Tag color='green'>COMMUNE</Tag>)
      },
    },
    {
      title: 'Statut du contrat',
      dataIndex: 'status',
      key: 'status',
      render: (value: any) => <ContractStatus contract={{ status: value } as Contract} translationString="ContractStatusEnumAdmin" />
    }
  ];

  const showMore = () => {
    navigate(getRoute(ROUTES.ADMIN_STRUCTURE_ROUTES.structureList, {}, { 'contract.status': status }))
  }

  return (
    <>
      <div className='quick-links'>
        <button className={status === ContractStatusEnum.completed ? 'text-alc-blue font-bold underline' : 'text-alc-blue font-semibold'}
          onClick={_ => {
            setStatus(ContractStatusEnum.completed)
            setContractFileStatus(null)
          }}>
          Contrats à valider
        </button>
        <button className={status === ContractStatusEnum.pending ? 'text-alc-blue font-bold underline' : 'text-alc-blue font-semibold'}
          onClick={_ => {
            setStatus(ContractStatusEnum.pending)
            setContractFileStatus('refused')
          }}>
          Contrats refusés
        </button>
      </div>
      <div className='mt-4'>
        <Table<IContract>
          columns={columns}
          dataSource={data}
          loading={loading}
          onChange={getData}
          rowKey="id"
          pagination={false}
          footer={() => <Button type='link' onClick={showMore}> Voir plus ... </Button>}
        />
      </div>
    </>

  );
};

export default ContractToValidateList;
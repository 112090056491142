import { Checkbox, Form } from 'antd';
import { Dispatch } from 'react';
import { useNavigate } from 'react-router-dom';
import NextStepComponent from '../../../../Auth/components/Register/NextStepComponent';
import LoadingScreen from '../../../../common/components/LoadingScreen';
import PageCanvas from '../../../../common/components/Panels/PageCanvas';
import Section from '../../../../common/components/Panels/Section';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import useFormRules from '../../../../common/hooks/useFormRules';
import { ROUTES } from '../../../../common/router';
import { getRoute } from '../../../../common/tools/router-tools';
import { selectCurrentStructure, updateStructureCommunesAction } from '../../../../Structure/state/structure-portal.slice';

const GrpCommunesConflictStarter = () => {

  const navigate = useNavigate()
  const structure = useAppSelector(selectCurrentStructure);
  const dispatch: Dispatch<any> = useAppDispatch()
  const { required, requireTrue } = useFormRules();

  const submit = (formData: any) => {
    dispatch(updateStructureCommunesAction(formData))
    return navigate(getRoute(ROUTES.PORTAL_REGISTER_CONTRACT_ROUTES.STEPS_GRP_COMMUNES.conflict_recap))
  }


  if (!structure)
    return <LoadingScreen />

  return (
    <>
      <PageCanvas title="Responsabilité de nettoiement">
        <Form
          name="basic"
          layout='vertical'
          onFinish={submit}
          id="conflict"
          initialValues={structure}
        >
          <Form.List name="communes">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Section mode={'transparent'} key={name} title={structure.communes[name].name}>
                    <Form.Item
                      name={[key, 'inChargeOfCleaning']}
                      valuePropName="checked"
                      rules={[required, requireTrue]}
                    >
                      <Checkbox>Je confirme que le groupement de communes est bien charge du nettoiement des voies publiques</Checkbox>
                    </Form.Item>
                  </Section>
                ))}
              </>
            )}
          </Form.List>

          <NextStepComponent />
        </Form>

      </PageCanvas>

    </>
  )
}

export default GrpCommunesConflictStarter;
import { CaseReducer, Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BaseServiceState } from "./types";

const factoryCreateSlice = <Name extends string, T>(serviceName: Name, customReducer: {
  [K: string]: (state: Draft<BaseServiceState<T, "current">>, payload: PayloadAction<any>) => void
} = {}) => {
  type STATE = BaseServiceState<T, 'current'>;
  const initialState: STATE = { current: null }

  const reducers = {
    getAction(state: Draft<STATE>, { payload }: PayloadAction<{ id: string }>) {
      state.current = null;
    },

    updateAction(state: Draft<STATE>, { payload }: PayloadAction<Draft<{ id: string, data: T }>>) {
      // update should not set current to null to avoid blinking refresh  
      // state.current = null;
    },

    deleteAction(state: Draft<STATE>, { payload }: PayloadAction<Draft<{ id: string }>>) {
    },

    setCurrentAction(state: Draft<STATE>, { payload }: PayloadAction<Draft<T> | null>) {
      state.current = payload;
    },
    ...customReducer
  }

  type Keys = typeof reducers & typeof customReducer

  return createSlice<STATE, { [K in keyof Keys]: CaseReducer<STATE, PayloadAction<any>> }>({
    name: serviceName,
    initialState,
    reducers
  })
};

export default factoryCreateSlice
import { RouteObject } from "react-router-dom"
import ContactListPage from "../components/ContactList/ContactListPage"

const routePrefix = 'admin'
const routeDomain = 'contact'
const routeParam = 'contactId'

export const adminContactRouteNames = {
  contactList: { path: `${routePrefix}/${routeDomain}`, title: `ROUTES.${routeDomain}.list` },
  contactDetails: { path: `${routePrefix}/${routeDomain}/:${routeParam}`, title: "ROUTES.infos" }
}

export const adminContactRoutes: RouteObject[] = [
  {
    path: adminContactRouteNames.contactList.path.replace(`${routePrefix}/`, ''),
    element: <ContactListPage />,
  },
  // {
  //   path: adminContactRouteNames.contactDetails.path.replace(`${routePrefix}/`, ''),
  //   element: <ProspectPage />
  // },
]
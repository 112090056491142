import { IStructure, StructureTypesEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import { Button, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "../../../common/hooks";
import { ROUTES } from '../../../common/router';
import { locallySaveStructureAction, selectLocallySavedStructure } from '../../state/register.slice';

const RegisterForm = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const structure = useAppSelector(selectLocallySavedStructure);

  const submit = (formData: IStructure) => {
    dispatch(locallySaveStructureAction(formData));
    navigate(ROUTES.REGISTER_ROUTES.STARTER_STEP)
  }

  return <>
    <Form<IStructure>
      name="basic"
      layout='vertical'
      initialValues={structure || {}}
      onFinish={submit}
    >
      <Form.Item
        label="Type de structure"
        name={['structureType']}
        rules={[{ required: true, message: t('UI.AUTH.REGISTER_FORM.selectValidation') }]}
      >
        <Select>
          {/* {Object.keys(StructureTypesEnum)
                            .filter(v => v !== StructureTypesEnum.member_structure)
                            .map(v => (
                                <Select.Option key={v} value={v}>{t(`TYPES.STRUCTURE.StructureTypesEnum.${v}`)}</Select.Option>
                            ))} */}
          {Object.keys(StructureTypesEnum)
            .filter(v => typeof v === "string")
            // .filter(v => v !== StructureTypesEnum.member_structure)
            .map(v => (
              <Select.Option key={v} value={v}>{t(`TYPES.STRUCTURE.StructureTypesEnum.${v}`)}</Select.Option>
            ))}
        </Select>
      </Form.Item>
      <div className='flex justify-end'>
        <Button htmlType='submit' className='btn'>Suivant</Button>
      </div>
    </Form>
  </>
}

export default RegisterForm;
import { BaseCommune } from "@alcome-rep/alcome-types/dist/models"
import useQuotas from "../../../../AcquisitionOrder/hooks/useQuota.hook"
import AmountValue from "../../../../common/components/Fields/AmountValue"
import StructureType from "../../common/StructureType"
import useAshtray from "../../../../AcquisitionOrder/hooks/useAshtray.hook"
import useAmountStatement from "../../../../AcquisitionOrder/hooks/useAmountStatement.hook"
import { useAppSelector } from "../../../../common/hooks"
import { selectUser } from "../../../../Auth/state/auth.slice"
import { Models } from "@alcome-rep/alcome-types"

type PropsType = {
  structure: BaseCommune,
}
const StructureDetails = ({ structure }: PropsType) => {

  const user = useAppSelector(selectUser);
  const isAdmin = Models.User.IsUserAdmin(user);
  const quotas = useQuotas(structure.id);
  const ashtray = useAshtray(structure);
  const amountStatement = useAmountStatement(structure);

  return <div className="flex flex-col space-y-2">
    <div>
      <div className="font-semibold">{structure.name}</div>
      <StructureType structure={structure} />
      {isAdmin && structure.accountingTier && (
        <div className="font-semibold">Compte tier : {structure.accountingTier}</div>
      )}
    </div>
    <div>
      <h6>Adresse</h6>
      <div>{structure.address.street}</div>
      <div>{structure.address.street2}</div>
      <div>{structure.address.zip}, {structure.address.city}</div>
    </div>
    <div>
      <h6>Contact</h6>
      <div>{structure.phone}</div>
      <div>{structure.email}</div>
    </div>
    {quotas && (
      <div>
        <h6>Quotas</h6>
        <div>Cendriers : {quotas.quotas.ashtrays}</div>
        <div>Eteignoirs: {quotas.quotas.extinguishers}</div>
        <div>Disponible (cendriers): {quotas.available.ashtrays}</div>
        <div>Disponible (eteignoirs): {quotas.available.extinguishers}</div>
      </div>
    )}

    {amountStatement !== undefined && amountStatement !== 0 && (
      <div>
        <h6>Aide financière estimé</h6>
        <div>Jusqu'à <AmountValue amount={amountStatement} precision={2} /></div>
      </div>
    )}
    {ashtray !== undefined && ashtray !== 0 && (
      <div>
        <h6>Cendrier de poche</h6>
        <div>Jusqu'à <AmountValue amount={ashtray} hideSuffix={true} /> cendriers de poche commandable.</div>
      </div>
    )}

  </div>
}

export default StructureDetails
import { IShippingAddress } from "@alcome-rep/alcome-types/dist/interfaces"
import { BaseCommune } from "@alcome-rep/alcome-types/dist/models"
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { Dispatch } from "@reduxjs/toolkit"
import { Alert, Button, message } from "antd"
import confirm from "antd/es/modal/confirm"
import structurePortalApi from "../../../../Structure/services/structure-portal.api"
import { selectCurrentStructure, setCurrentStructureAction } from "../../../../Structure/state/structure-portal.slice"
import Section from "../../../../common/components/Panels/Section"
import { useAppDispatch, useAppSelector } from "../../../../common/hooks"
import { closeDrawerAction, openDrawerAction } from "../../../../common/state/modal.slice"
import AddShippingAddressForm from "./AddShippingAddressForm"
import AddressForm from "./AddressForm"


const UserStructureShippingAddress = () => {
    const structure = useAppSelector(selectCurrentStructure)
    const dispatch: Dispatch<any> = useAppDispatch()

    const addShippingAdrress = async (shippingAddressData: IShippingAddress) => {
        if (!structure)
            return;
        try {
            let arr = [];
            if (!structure.shippingAddress || structure.shippingAddress.length === 0) {
                arr.push(shippingAddressData)
            }
            else {
                arr = [...structure.shippingAddress]
                arr.push(shippingAddressData)
            }

            const updatedStructure = await structurePortalApi.updateStructureInfos({ shippingAddress: arr })
            dispatch(setCurrentStructureAction(updatedStructure));
            dispatch(closeDrawerAction())
            message.success('Adresse de livraison ajoutée !')
        } catch { }
    }

    const updateShippingAddress = async (data: any, index: number) => {
        if (!structure)
            return;
        const newData = data.shippingAddress[index]
        let arr = [...structure.shippingAddress];
        arr[index] = newData

        const updatedStructure = await structurePortalApi.updateStructureInfos({ shippingAddress: arr })
        dispatch(setCurrentStructureAction(updatedStructure));
        dispatch(closeDrawerAction())
        message.success('Adresse de livraison mis à jours !')
    }

    const deleteShippingAddress = (index: number) => {
        let updatedShippingAddresses = structure?.shippingAddress
        updatedShippingAddresses!.splice(index, 1)
        confirm({
            title: 'Confirmation',
            icon: <QuestionCircleOutlined />,
            cancelText: 'Annuler',
            closable: true,
            okCancel: true,
            content: "Souhaitez vous supprimer cette adresse de livraison ?",
            onOk: async () => {
                const updatedStructure = await structurePortalApi.updateStructureInfos({ shippingAddress: updatedShippingAddresses })
                dispatch(setCurrentStructureAction(updatedStructure));
            },
            onCancel: () => { }
        })
    }

    return (
        <div className="w-1/2">
            <Section
                mode="transparent"
                title={
                    <div className="flex justify-between">
                        <span>Adresses de livraisons </span>
                        <Button
                            type="link"
                            onClick={(e) => {
                                dispatch(openDrawerAction({
                                    component: <AddShippingAddressForm structure={structure as BaseCommune} submit={addShippingAdrress} />,
                                    options: { title: "Ajouter une adresse de livraison", size: 'large' }
                                }))
                            }}
                            icon={<PlusCircleOutlined />} />
                    </div>
                }>

                {((structure && structure.shippingAddress === null) || (!structure?.shippingAddress) || (structure?.shippingAddress.length === 0)) && (
                    <Alert banner message={<div>Aucune adresse de livraison enregistrée</div>} />
                )}

                {structure?.shippingAddress && structure.shippingAddress.map((sA, index) => {
                    return (
                        <>
                            <Section>
                                <div className="flex justify-between font-semibold text-lg">
                                    <div>
                                        {sA.label}
                                    </div>
                                    <div className="flex space-x-4">
                                        <Button
                                            type="link"
                                            onClick={(e) => {
                                                dispatch(openDrawerAction({
                                                    component: <AddressForm isShipping={true} structure={structure as BaseCommune} index={index} submit={(value: any) => updateShippingAddress(value, index)} />,
                                                    options: { title: "Modifier mon adresse de livraison", size: 'large' }
                                                }))
                                            }}
                                            icon={<EditOutlined />} />
                                        <Button
                                            className="text-alc-danger"
                                            type="link"
                                            onClick={() => deleteShippingAddress(index)}
                                            icon={<DeleteOutlined />} />
                                    </div>
                                </div>
                            </Section>
                        </>
                    )
                })}
            </Section>
        </div>
    )
}

export default UserStructureShippingAddress

import { useTranslation } from "react-i18next";
import AuthTabs from "../AuthTabs";
import LoginForm from "./LoginForm";

const LoginPage = () => {
  const { t } = useTranslation();
  return <>
    <AuthTabs ></AuthTabs>
    <h1>{t('UI.AUTH.LOGIN_PAGE.title')}</h1>
    <p>{t('UI.AUTH.LOGIN_PAGE.p1')}</p>
    <LoginForm></LoginForm>
  </>
}

export default LoginPage;
import { call, put, takeLatest } from 'redux-saga/effects';
import structurePortalApi from '../services/structure-portal.api';
import { getMyStructureAction, setCurrentStructureAction, unsetCurrentStructureAction, updateStructureCommunesAction, updateStructureInfosAction } from './structure-portal.slice';
import { BaseCommune } from '@alcome-rep/alcome-types/dist/models';

/**
 * SAGAS
 */
function* updateStructureInfos({ payload }: ReturnType<typeof updateStructureInfosAction>) {
  try {
    const resp: BaseCommune = yield call(structurePortalApi.updateStructureInfos, payload);

    yield put(setCurrentStructureAction(resp));
  } catch {
    yield put(unsetCurrentStructureAction());
  }
}

function* updateStructureCommunes({ payload }: ReturnType<typeof updateStructureCommunesAction>) {
  try {
    const resp: BaseCommune = yield call(structurePortalApi.updateStructureCommunes, payload);

    yield put(setCurrentStructureAction(resp));
  } catch {
    yield put(unsetCurrentStructureAction());
  }
}

function* getMyStructure(_: ReturnType<typeof getMyStructureAction>) {
  try {
    const resp: BaseCommune = yield call(structurePortalApi.getMyStructure);

    yield put(setCurrentStructureAction(resp));
  } catch {
    yield put(unsetCurrentStructureAction());
  }
}

export function* structurePortalSaga() {
  yield takeLatest(updateStructureInfosAction, updateStructureInfos);
  yield takeLatest(updateStructureCommunesAction, updateStructureCommunes);
  yield takeLatest(getMyStructureAction, getMyStructure);

}
import { AcquisitionOrderItemType, AcquisitionOrderStatusEnum, DispositifCategoryEnum, IAcquisitionOrders } from "@alcome-rep/alcome-types/dist/interfaces";
import { EditOutlined } from "@ant-design/icons";
import { Button, Table, TableColumnType } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import structureAdminApi from "../../../../Structure/services/structure-admin.api";
import DateField from "../../../../common/components/Fields/DateField";
import FilterEntity from "../../../../common/components/Filters/FilterEntity";
import FilterInput from "../../../../common/components/Filters/FilterInput";
import FilterNavLink from "../../../../common/components/Filters/FilterNavLink";
import FilterPanel from "../../../../common/components/Panels/FilterPanel";
import PageCanvas from "../../../../common/components/Panels/PageCanvas";
import QuickLinksPanel from "../../../../common/components/Panels/QuickLinksPanel";
import useQueryParams from "../../../../common/hooks/useQueryParams";
import useTableChange from "../../../../common/hooks/useTableChange";
import { ROUTES } from "../../../../common/router";
import { getRoute } from "../../../../common/tools/router-tools";
import acquisitionOrderAdminApi from "../../../services/admin-acquisitionOrder.api";
import AcquisitionOrderStatus from "../../common/AcquisitionOrderStatus/AcquisitionOrderStatus";
import FilterDelegate from "../../../../common/components/Filters/FilterDelegate";
import { useAppSelector } from "../../../../common/hooks";
import { selectUser } from "../../../../Auth/state/auth.slice";

const AdminAcquisitionOrderList = () => {
  const [data, setData] = useState<IAcquisitionOrders[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setloading] = useState<boolean>(false);
  const [refresh] = useState<Date>()

  const user = useAppSelector(selectUser);

  const tableChange = useTableChange();
  const queryParams = useQueryParams()

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setloading(true)
    acquisitionOrderAdminApi.findAcquisitionOrders({ query: queryParams.query, page: Number(queryParams.page), limit: Number(queryParams.limit) })
      .then(res => {
        setData(res.rows)
        setCount(res.count)
        setloading(false)
      })
      .catch(() => {
        setData([])
        setCount(0)
        setloading(false)
      })
  }, [setData, setCount, setloading, queryParams, refresh])


  const columns: TableColumnType<IAcquisitionOrders>[] = [
    {
      fixed: 'left',
      render: (value: any) => {
        return (
          <Button
            type="link"
            onClick={(e) => {
              // navigate(`${APP_ROUTER_PATHS.supportOrderEdit.replace(/:supportOrderId/, value._id)}`);
              navigate(getRoute(ROUTES.ADMIN_ACQUISITION_ORDER_ROUTES.acquisitionOrderDetails, { acquisitionOrderId: value.id }));
            }}
            icon={<EditOutlined />}
          />
        )
      }
    },
    {
      title: 'N°',
      dataIndex: 'acquisitionOrderNumber',
      className: 'font-semibold',
      render: (value: any) => {
        return <div>{value}</div>
      },
    },
    {
      title: 'Date',
      dataIndex: 'orderDate',
      className: 'font-semibold',
      align: 'center' as const,
      render: (value: any) => <DateField value={value} />,
    },
    {
      title: 'Structure',
      dataIndex: ['structure', 'name'],
      align: 'left',
      render: (structureName: string, order: IAcquisitionOrders) =>
        <Link to={getRoute(ROUTES.ADMIN_STRUCTURE_ROUTES.structureDetails, { structureId: order.structureId })}>{structureName}</Link>,
    },
    {
      title: 'Nb cendriers de rue',
      dataIndex: 'items',
      align: 'center' as const,
      render: (value: AcquisitionOrderItemType[]) => <div className="flex flex-col">
        <span>Dispositif(s): {value.filter((v: AcquisitionOrderItemType) => v.category === DispositifCategoryEnum.ashtray && !v.isSupport).reduce((acc: number, i) => acc + i.quantity, 0)}</span>
        <span>Soutien(s): {value.filter((v: any) => v.category === DispositifCategoryEnum.ashtray && v.isSupport).reduce((acc: number, i) => acc + i.quantity, 0)}</span>
      </div>,
    },
    {
      title: 'Nb éteignoirs',
      dataIndex: 'items',
      key: 'extinguisherCount',
      align: 'center' as const,
      render: (value: any) => <div className="flex flex-col">
        <span>Dispositif(s): {value.filter((v: AcquisitionOrderItemType) => v.category === DispositifCategoryEnum.extinguisher && !v.isSupport).reduce((acc: number, i: any) => acc + 1, 0)}</span>
        <span>Soutien(s): {value.filter((v: any) => v.category === DispositifCategoryEnum.extinguisher && v.isSupport).reduce((acc: number, i: any) => acc + 1, 0)}</span>
      </div>,
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      key: 'status',
      align: 'center' as const,
      render: (value: any) => <AcquisitionOrderStatus status={value} />
    },


  ]

  const findStructures = (name: string) => structureAdminApi.findAll({ query: { name: '/' + name + '/' }, page: 1, pageSize: 5 })
    .then(r => r.rows.map(s => ({ label: s.name, value: s.id })))
  const retrieveStructure = (id: string) => structureAdminApi.get(id)


  return (
    <>
      <PageCanvas
        breadCrumbs={[
          ROUTES.ADMIN_USER_ROUTES.home,
          ROUTES.ADMIN_ACQUISITION_ORDER_ROUTES.acquisitionOrderList
        ]}
        title={<span>Acquisitions de dispositifs <small>({count ? count : 0})</small ></span >}
      >
        <FilterPanel>
          <FilterDelegate initialValue={user!.id} />
          <FilterInput
            searchParamName="acquisitionOrderNumber"
            label="Acquisition N°"
            isRegex={true}
          />
          <FilterEntity
            filterName='Structure'
            multiple={false}
            queryParamName='structureId'
            findFn={findStructures}
            retrieveFn={retrieveStructure}
          />

          <FilterEntity
            filterName='Statut'
            multiple={false}
            queryParamName='status'
            options={Object.keys(AcquisitionOrderStatusEnum)
              .filter(k => !isNaN(k as any))
              .map(k => ({ value: k, label: t('TYPES.ACQUISITION_ORDER.AcquisitionOrderStatusEnum.' + AcquisitionOrderStatusEnum[k as any]) }))
            }
          />
        </FilterPanel>

        <QuickLinksPanel>
          <FilterNavLink queryParams={`?status=${AcquisitionOrderStatusEnum.acquisition_order_pending}`} label="Demandes en attente" />
        </QuickLinksPanel >


        <Table<IAcquisitionOrders>
          rowKey={'id'}
          columns={columns}
          dataSource={data}
          loading={loading}
          onChange={tableChange}
          size="small"
          pagination={
            {
              hideOnSinglePage: true,
              current: queryParams.page,
              pageSize: queryParams.limit,
              total: count,
            }
          } />
      </PageCanvas >
    </>
  )
}

export default AdminAcquisitionOrderList

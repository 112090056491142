import { Models } from "@alcome-rep/alcome-types";
import { ISupplierCompany } from "@alcome-rep/alcome-types/dist/interfaces/supplier";
import { Button, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { selectUser } from "../../../../Auth/state/auth.slice";
import { useAppSelector } from "../../../../common/hooks";
import useFormRules from "../../../../common/hooks/useFormRules";
import adminSupplierCompanyApi from "../../../services/admin-supplierCompany.api";
import supplierCompanyApi from "../../../services/supplierCompany.api";

type Props = {
    supplierCompany?: ISupplierCompany
    onSubmit: (data?: ISupplierCompany) => void
}

type FormType = Partial<ISupplierCompany>

const SupplierEditForm = ({ supplierCompany, onSubmit }: Props) => {

    const [form] = useForm()
    const { required } = useFormRules();

    const { t } = useTranslation();

    const { supplierCompanyId } = useParams();

    const user = useAppSelector(selectUser)
    const isAdmin = Models.User.IsUserAdmin(user)
    const isSupplierUser = Models.User.IsUserSupplier(user)

    const editSupplierCompany = async (formData: Partial<ISupplierCompany>) => {
        if (isSupplierUser) {
            const updatedSupplier = await supplierCompanyApi.updateSupplierCompany(formData)
            onSubmit(updatedSupplier)
        } else {
            const updatedSupplier = await adminSupplierCompanyApi.updateSupplierCompany(supplierCompanyId as string, formData)
            onSubmit(updatedSupplier)
        }
    }

    return (

        <Form<FormType>
            form={form}
            name="basic"
            layout='vertical'
            onFinish={editSupplierCompany}
            initialValues={supplierCompany}
        >
            <div className="flex space-x-8">
                <Form.Item
                    label="Nom"
                    name="name"
                    rules={[required]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label="Siret"
                    name="siret"
                    rules={[required]}
                >
                    <Input></Input>
                </Form.Item>
            </div>
            <Form.Item
                label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.street.label')}
                name={['address', 'street']}
                rules={[required]}
            >
                <Input></Input>
            </Form.Item>

            <Form.Item
                label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.city.label')}
                name={['address', 'city']}
                rules={[required]}
            >
                <Input></Input>
            </Form.Item>

            <Form.Item
                label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.zip.label')}
                name={['address', 'zip']}
                rules={[required]}
            >
                <Input></Input>
            </Form.Item>

            {isAdmin && (
                <Form.Item
                    label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.accountingTier.label')}
                    name="accountingTier"
                >
                    <Input></Input>
                </Form.Item>
            )}

            <Button htmlType="submit" type="primary" >{t('COMMON.save')}</Button>

        </Form >
    )
}

export default SupplierEditForm
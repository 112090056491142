import { ApiListResult } from "@alcome-rep/alcome-types";
import { IContact } from "@alcome-rep/alcome-types/dist/interfaces/crm";
import ApiService, { ApiSortType } from "../../common/services/apiService";
import { buildFindQuery, buildSortQuery } from "../../common/tools/global-tools";

const CONTACT_ADMIN_PATH_API = 'admin/contact';

class ContactAdminApi extends ApiService<IContact> {
  constructor() {
    super(CONTACT_ADMIN_PATH_API)
  }

  findContacts({ query, page, limit, sort }: { query: any, page: number, limit: number, sort?: ApiSortType }) {
    const myQuery = { ...query }

    let queryString = buildFindQuery(myQuery);
    if (sort) {
      queryString += buildSortQuery(sort)
    }
    return this.custom<ApiListResult<IContact>>('get', `?${queryString}&page=${page}&limit=${limit}`)
  }

  findProspectContacts(prospectId: string) {
    return this.custom<ApiListResult<IContact>>('get', `list/${prospectId}`)
  }

  updateContact(contactId: string, data: Partial<IContact>) {
    return this.update(contactId, data)
  }

  createContact(data: Partial<IContact>) {
    return this.create(data)
  }

  deleteContact(contactId: string) {
    return this.delete(contactId)
  }

}

const contactAdminApi = new ContactAdminApi();
export default contactAdminApi;
import { Models } from "@alcome-rep/alcome-types"

type PropsType = {
  prospect: Models.Prospect
}
const ProspectDetails = ({ prospect: structure }: PropsType) => {
  return <>
    <div className="font-semibold">{structure.name}</div>
    {/* <StructureType structure={structure} /> */}
    <h6>Adresse</h6>
    <div>{structure.address.street}</div>
    <div>{structure.address.street2}</div>
    <div>{structure.address.zip}, {structure.address.city}</div>
    <h6>Contact</h6>
    <div>{structure.phone}</div>
    <div>{structure.email}</div>
  </>
}

export default ProspectDetails
import { RouteObject } from "react-router-dom";
import SupplierOrderLayout from "../components/layout/SupplierOrderLayout";
import SupplierOrderInfos from "../components/userSuppliers/UserSupplierOrderInfos/UserSupplierOrderInfos";
import SupplierOrderInvoices from "../components/userSuppliers/UserSupplierOrderInvoices/UserSupplierOrderInvoices";
import UserSupplierOrderDeliveryNotes from "../components/userSuppliers/UserSupplierOrderDeliveryNotes/UserSupplierOrderDeliveryNotes";
import UserSupplierOrderList from "../../SupplierCompany/components/userSuppliers/UserSupplierOrderList/UserSupplierOrderList";

const routePrefix = 'supplier'
const routeDomain = 'supplierOrder'
const routeParam = 'supplierOrderId'

export const supplierOrderRouteNames = {
  supplierOrderList: { path: `${routePrefix}/${routeDomain}/list`, title: `ROUTES.${routeDomain}.list` },
  supplierOrderDetails: { path: `${routePrefix}/${routeDomain}/details/:${routeParam}`, title: `ROUTES.${routeDomain}.details` },
  supplierOrderInfos: { path: `${routePrefix}/${routeDomain}/details/:${routeParam}/infos`, title: `ROUTES.${routeDomain}.infos` },
  supplierOrderInvoices: { path: `${routePrefix}/${routeDomain}/details/:${routeParam}/invoices`, title: `ROUTES.${routeDomain}.invoices` },
  supplierOrderDeliveryNotes: { path: `${routePrefix}/${routeDomain}/details/:${routeParam}/deliveryNotes`, title: `ROUTES.${routeDomain}.delivery` },
}

export const supplierOrderRoutes: RouteObject[] = [
  {
    path: '',
    children: [
      {
        path: supplierOrderRouteNames.supplierOrderList.path.replace(`${routePrefix}/`, ''),
        element: <UserSupplierOrderList />
      },
      {
        path: supplierOrderRouteNames.supplierOrderDetails.path.replace(`${routePrefix}/`, ''),
        element: <SupplierOrderLayout />,
        children: [
          {
            path: 'infos',
            element: <SupplierOrderInfos />
          },
          {
            path: 'invoices',
            element: <SupplierOrderInvoices/>
          },
          {
            path: 'deliveryNotes',
            element: <UserSupplierOrderDeliveryNotes/>
          }
        ]
      },
    ]
  }
]


import { Models, timeLimit } from "@alcome-rep/alcome-types";
import { AnnualReviewStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { AnnualReview } from "@alcome-rep/alcome-types/dist/models";
import { EditOutlined } from "@ant-design/icons";
import { Button, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { addDays, differenceInDays } from "date-fns";
import { TFunction } from "i18next";
import { Link, NavigateFunction } from "react-router-dom";
import { ROUTES } from "../../../../common/router";
import { getRoute } from "../../../../common/tools/router-tools";
import AmountValue from "../../../../common/components/Fields/AmountValue";

export const annualReviewListColumns = (query: any, t: TFunction, navigate: NavigateFunction): ColumnsType<Models.AnnualReview> => [
  {
    // title: 'Actions',
    fixed: 'left',
    width: "40px",
    align: 'center',
    render: (value: any, _, i) => {
      return (
        <Button
          id={`action-button-${i}`}
          type="link"
          icon={<EditOutlined />}
          onClick={(_e) =>
            navigate(getRoute(ROUTES.ADMIN_ANNUALREVIEW_ROUTES.annualReviewDetails, { annualReviewId: value.id }))}
        />
      )
    }
  },
  {
    fixed: 'left',
    title: 'Année',
    width: '100px',
    dataIndex: 'startDate',
    className: 'font-semibold',
    render: v => <span>{new Date(v).getFullYear()}</span>
  },
  {
    title: 'Structure',
    dataIndex: ['structure', 'name'],
    key: 'structure.name',
    render: (structureName: string, item: AnnualReview) => <Link
      to={getRoute(ROUTES.ADMIN_STRUCTURE_ROUTES.structureDetails, { structureId: item.structureId })}
    >{structureName}</Link>
  },
  {
    title: 'Insee / Siren',
    width: '120px',
    dataIndex: ['structure', 'siren'],
    key: 'structure.siren',
    align: 'center',
  },
  {
    title: 'Montant du décompte liquidatif',
    dataIndex: 'amountStatement',
    key: 'amountStatement',
    align: 'center' as const,
    render: (value: any) => <AmountValue amount={value} precision={2} />,
    sorter: {}
  },
  {
    title: 'Jours restant décompte',
    dataIndex: [],
    key: 'decompte',
    align: 'center' as const,
    render: (value: any) => {
      const validationDate = value.statementValidation && new Date(value.statementValidation)
      const annualReviewAcceptationDate = value.statementAccepted && new Date(value.statementAccepted)
      const afterDate = addDays(validationDate, timeLimit.deadlineStatement);
      if (validationDate && !annualReviewAcceptationDate) {
        const limitDate = differenceInDays(afterDate, new Date())
        if (limitDate >= 0) {
          return <div className=' text-center'><div>reste {limitDate} jours</div></div>
        } else {
          return <div className=' text-center text-alc-danger font-semibold'> Délai dépassé </div>
        }
      } else if (!validationDate) {
        return <div className=' text-center'>-</div>
      }
      else {
        return <div className=' text-center'><Tag color='green'>OK</Tag></div>
      }
    }
  },
  {
    title: 'Statut du bilan',
    fixed: 'right',
    dataIndex: 'status',
    key: 'status',
    width: "140px",
    align: 'center' as const,
    render: (value: any) => {
      if (value === AnnualReviewStatusEnum.pending) return (<Tag color='red'>Bilan en cours</Tag>)
      else if (value === AnnualReviewStatusEnum.completed) return (<Tag color='orange'>Bilan à valider</Tag>)
      else if (value === AnnualReviewStatusEnum.validated) return (<Tag color='green'>Bilan Validé</Tag>)
      else if (value === AnnualReviewStatusEnum.statement_pending) return (<div><Tag color='green'>Bilan Validé</Tag><Tag color='orange'>Décompte en Attente</Tag></div>)
      else if (value === AnnualReviewStatusEnum.statement_refused) return (<div><Tag color='green'>Bilan Validé</Tag><Tag color='red'>Décompte Refusé</Tag></div>)
      else if (value === AnnualReviewStatusEnum.statement_accepted) return (<div><Tag color='green'>Bilan Validé</Tag><Tag color='green'>Décompte Accepté</Tag></div>)
      else if (value === AnnualReviewStatusEnum.order_sent) return (<div><Tag color='green'>Bilan Validé</Tag><Tag color='green'>Décompte Accepté</Tag><Tag color='orange'>Titre de recette envoyé</Tag></div>)
      else if (value === AnnualReviewStatusEnum.order_validate) return (<div><Tag color='green'>Bilan Validé</Tag><Tag color='green'>Décompte Accepté</Tag><Tag color='green'>Titre de recette accepté</Tag></div>)
      else if (value === AnnualReviewStatusEnum.order_refuse) return (<div><Tag color='green'>Bilan Validé</Tag><Tag color='green'>Décompte Accepté</Tag><Tag color='red'>Titre de recette refusé</Tag></div>)
      else if (value === AnnualReviewStatusEnum.statement_paid) return (<div><Tag color='green'>Bilan Validé</Tag><Tag color='green'>Décompte Accepté</Tag><Tag color='green'>Titre de recette accepté</Tag><Tag color='green'>Paiement effectué</Tag></div>)
      else return (<div>Statut manquant</div>)
    },
  },

];
import { RouteObject } from "react-router-dom";
import UserDetailsPage from "../components/admin/AdminUserDetails/AdminUserDetailsPage";
import UserListPage from "../components/admin/UserList/UserListPage";
import AdminDashBoardPage from "../../Dashboard/components/admin/AdminDashboardPage";

const routePrefix = 'admin'
const routeDomain = 'user'
const routeParam = 'userId'
const routeUserTypeParam = 'userType'

export const adminUserRouteNames = {
  home: { path: `${routePrefix}/home`, title: 'ROUTES.dashboard' },
  userList: { path: `${routePrefix}/${routeDomain}/:${routeUserTypeParam}`, title: `ROUTES.${routeDomain}.list` },
  userDetails: { path: `${routePrefix}/${routeDomain}/details/:${routeParam}`, title: "ROUTES.infos" }
}

export const adminUserRoutes: RouteObject[] = [
  {
    path: adminUserRouteNames.home.path.replace(`${routePrefix}/`, ''),
    element: <AdminDashBoardPage />,
  },
  {
    path: adminUserRouteNames.userList.path.replace(`${routePrefix}/`, ''),
    element: <UserListPage />,
  },
  {
    path: adminUserRouteNames.userDetails.path.replace(`${routePrefix}/`, ''),
    element: <UserDetailsPage isAdmin={true}/>
  },
]
import { RouteObject } from "react-router-dom";
import SupplierDashboardPage from "../../Dashboard/components/supplier/SupplierDashboardPage";
import SupplierProductDetails from "../../Product/components/supplier/SupplierProductDetails/SupplierProductDetails";
import SupplierProductList from "../../Product/components/supplier/SupplierProductList/SupplierProductList";
import CompanyShippingWeightSettings from "../../SupplierCompany/components/userSuppliers/CompanyShippingWeightSettings/CompanyShippingWeightSettings";
import UserSupplierOrderDetails from "../../SupplierCompany/components/userSuppliers/UserSupplierOrderDetails/UserSupplierOrderDetails";
import UserSupplierOrderList from "../../SupplierCompany/components/userSuppliers/UserSupplierOrderList/UserSupplierOrderList";

const routePrefix = 'supplier'
const routeDomain = 'user'
const routeParam = 'orderId'
const routeProductParam = 'productId'

export const supplierUserRouteNames = {
  home: { path: `${routePrefix}/home`, title: `ROUTES.${routePrefix}.home` },
  productList: { path: `${routePrefix}/${routeDomain}/product/list`, title: `ROUTES.${routePrefix}.productList` },
  productDetails: { path: `${routePrefix}/${routeDomain}/product/:${routeProductParam}/details`, title: `ROUTES.${routePrefix}.productDetails` },
  orderList: { path: `${routePrefix}/${routeDomain}/orderList`, title: `ROUTES.${routePrefix}.orderList` },
  orderDetails: { path: `${routePrefix}/${routeDomain}/:${routeParam}/details`, title: `ROUTES.${routePrefix}.orderDetails` },
  shippingWeightList: { path: `${routePrefix}/${routeDomain}/shippingWeightList`, title: `ROUTES.${routePrefix}.shippingWeightList` },
}

export const supplierUserRoutes: RouteObject[] = [
  {
    path: '',
    children: [
      {
        path: supplierUserRouteNames.home.path.replace(`${routePrefix}/`, ''),
        element: <SupplierDashboardPage />,
      },
      {
        path: supplierUserRouteNames.productList.path.replace(`${routePrefix}/`, ''),
        element: <SupplierProductList />,
      },
      {
        path: supplierUserRouteNames.productDetails.path.replace(`${routePrefix}/`, ''),
        element: <SupplierProductDetails />
      },
      {
        path: supplierUserRouteNames.orderList.path.replace(`${routePrefix}/`, ''),
        element: <UserSupplierOrderList />,
      },
      {
        path: supplierUserRouteNames.orderDetails.path.replace(`${routePrefix}/`, ''),
        element: <UserSupplierOrderDetails />,
      },
      {
        path: supplierUserRouteNames.shippingWeightList.path.replace(`${routePrefix}/`, ''),
        element: <CompanyShippingWeightSettings />,
      }
    ]
  }
]
import { User } from "@alcome-rep/alcome-types/dist/models"
import { Outlet } from "react-router-dom"
import { selectUser } from "../../../Auth/state/auth.slice"
import { selectCurrentAdminStructure } from "../../../Structure/state/structure-admin.slice"
import { selectCurrentStructure } from "../../../Structure/state/structure-portal.slice"
import LoadingScreen from "../../../common/components/LoadingScreen"
import NavTabs from "../../../common/components/Panels/NavTabs"
import Section from "../../../common/components/Panels/Section"
import { useAppSelector } from "../../../common/hooks"
import { ROUTES } from "../../../common/router"
import { selectCurrentAdminInventory } from "../../state/inventory-admin.slice"
import { selectCurrentInventory } from "../../state/inventory-portal.slice"
import { InventoryStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces"
import DateField from "../../../common/components/Fields/DateField"

const InventoryPage = () => {
  const user = useAppSelector(selectUser)
  const inventory = useAppSelector(User.IsUserAdmin(user) ? selectCurrentAdminInventory : selectCurrentInventory)
  const structure = useAppSelector(User.IsUserAdmin(user) ? selectCurrentAdminStructure : selectCurrentStructure)

  if (!inventory)
    return <LoadingScreen />

  const tabRoutes = User.IsUserAdmin(user) ? [
    { ...ROUTES.ADMIN_CONTRACT_ROUTES.INVENTORY.PREVENTION, params: { structureId: structure?.id } },
    { ...ROUTES.ADMIN_CONTRACT_ROUTES.INVENTORY.SALUBRITE, params: { structureId: structure?.id } },
    { ...ROUTES.ADMIN_CONTRACT_ROUTES.INVENTORY.MAP, params: { structureId: structure?.id } },
    { ...ROUTES.ADMIN_CONTRACT_ROUTES.INVENTORY.FILES, params: { structureId: structure?.id } }
  ] :
    [
      ROUTES.PORTAL_CONTRACT_ROUTES.INVENTORY.PREVENTION,
      ROUTES.PORTAL_CONTRACT_ROUTES.INVENTORY.SALUBRITE,
      ROUTES.PORTAL_CONTRACT_ROUTES.INVENTORY.MAP,
      ROUTES.PORTAL_CONTRACT_ROUTES.INVENTORY.FILES
    ]

  return (
    <>
      <Section title={
        <>
          <span>Etat des lieux</span>
          {inventory.status >= InventoryStatusEnum.validated && <span className="text-sm block">Date de validation: <DateField value={inventory.date} /></span>}
        </>}>
        <NavTabs tabRoutes={tabRoutes} />

        <Outlet />
      </Section>
    </>
  );

}

export default InventoryPage
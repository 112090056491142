import { ISupplierCompany } from "@alcome-rep/alcome-types/dist/interfaces/supplier";
import { message } from "antd";
import ApiService from "../../common/services/apiService";

const SUPPLIER_COMPANY_PATH_API = 'supplierCompany';

class SupplierCompanyApi extends ApiService<ISupplierCompany> {
  constructor() {
    super(SUPPLIER_COMPANY_PATH_API)
  }

  /**
   * @description Get current user
   * @returns User
   */
  getMyCompany() {
    return this.custom<ISupplierCompany>('get', '')
  }

  /**
     * @description Update me as Admin user
     * @param data 
     * @returns My infos updated
     */
  updateSupplierCompany(data: Partial<ISupplierCompany>) {
    return this.custom<ISupplierCompany>('put', 'infos', undefined, data)
      .then(res => {
        message.success('Succès')
        return res
      })
      .catch(err => {
        message.error('Erreur lors de la mise à jour')
        return err
      })
  }


}

const supplierCompanyApi = new SupplierCompanyApi();
export default supplierCompanyApi;
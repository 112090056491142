import { Models } from '@alcome-rep/alcome-types';
import { AnnualReviewStatusEnum, FileDocumentStatusEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import { User } from '@alcome-rep/alcome-types/dist/models';
import { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { selectAuth } from '../../../../Auth/state/auth.slice';
import StructureType from '../../../../Structure/components/common/StructureType';
import LoadingScreen from '../../../../common/components/LoadingScreen';
import NavTabs from '../../../../common/components/Panels/NavTabs';
import PageCanvas from '../../../../common/components/Panels/PageCanvas';
import Section from '../../../../common/components/Panels/Section';
import { useAppSelector } from '../../../../common/hooks';
import { ROUTES } from '../../../../common/router';
import { getRoute } from '../../../../common/tools/router-tools';
import AnnualReviewRefusedMentionComponent from '../AnnualReviewRefusedMention/AnnualReviewRefusedMention';
import AnnualReviewFigures from './AnnualReviewFigures';

type PropsType = {
  annualReview: Models.AnnualReview
  structure: Models.BaseCommune
}

const AnnualReviewComponent = ({ annualReview, structure }: PropsType) => {

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = useAppSelector(selectAuth)?.user;

  const admin = new Models.User(user).isSuperAdmin();
  const userCanValidate = !admin && annualReview?.status <= AnnualReviewStatusEnum.refused

  const year = new Date(annualReview.startDate).getFullYear();
  const currentYear = new Date().getFullYear();
  const isNewAr = year === currentYear;

  const comments = annualReview.annualReviewFile && annualReview.annualReviewFile.history && annualReview.annualReviewFile.history.filter(a => a.status === FileDocumentStatusEnum.declined && !!a.comment) ? annualReview.annualReviewFile.history.filter(a => !!a.comment) : []

  const [breadCrumbs, setBreadCrumbs] = useState(admin ? [
    ROUTES.ADMIN_USER_ROUTES.home,
    ROUTES.ADMIN_ANNUALREVIEW_ROUTES.annualReviewList,
    ROUTES.ADMIN_ANNUALREVIEW_ROUTES.annualReviewDetails
  ] : [
    ROUTES.PORTAL_USER_ROUTES.home,
    ROUTES.PORTAL_ANNUALREVIEW_ROUTES.portalAnnualReviewList,
    ROUTES.PORTAL_ANNUALREVIEW_ROUTES.portalAnnualReviewDetails
  ]);

  /** Force redirect to map completion if the annual review is not ready to be completed */
  useEffect(() => {
    if(isNewAr){
      if(admin){
        navigate(getRoute(ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepMap, { annualReviewId: annualReview.id }))
      }else{
        navigate(getRoute(ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepMap, { annualReviewId: annualReview.id }))
      }
    }
  }, [annualReview, isNewAr, navigate, pathname, admin]);

  /** Redirect on the route that the user was currently editing, only executed on component load */
  useEffect(() => {
    if (isNewAr) return;

    if (!admin) {
      if (annualReview.status >= AnnualReviewStatusEnum.order_validate) { navigate(getRoute(ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepPayment, { annualReviewId: annualReview.id })); return; }
      if (annualReview.status >= AnnualReviewStatusEnum.statement_accepted) { navigate(getRoute(ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepOrder, { annualReviewId: annualReview.id })); return; }
      if (annualReview.status >= AnnualReviewStatusEnum.validated) { navigate(getRoute(ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepStatement, { annualReviewId: annualReview.id })); return; }
      if (annualReview.status === AnnualReviewStatusEnum.completed) { navigate(getRoute(ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepReview, { annualReviewId: annualReview.id })); return; }
    }
    else {
      if (annualReview.status >= AnnualReviewStatusEnum.order_validate) { navigate(getRoute(ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepPayment, { annualReviewId: annualReview.id })); return; }
      if (annualReview.status >= AnnualReviewStatusEnum.statement_accepted) { navigate(getRoute(ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepOrder, { annualReviewId: annualReview.id })); return; }
      if (annualReview.status >= AnnualReviewStatusEnum.validated) { navigate(getRoute(ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepStatement, { annualReviewId: annualReview.id })); return; }
      if (annualReview.status === AnnualReviewStatusEnum.completed) { navigate(getRoute(ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepReview, { annualReviewId: annualReview.id })); return; }
    }
  }, [annualReview, isNewAr, navigate, admin]);

  /** Update breadcrumbs from current route */
  useEffect(() => {
    switch (pathname) {

      case getRoute(ROUTES.ADMIN_ANNUALREVIEW_ROUTES.annualReviewDetails, { annualReviewId: annualReview.id }): { setBreadCrumbs(b => [...b.slice(0, -1), ROUTES.ADMIN_ANNUALREVIEW_ROUTES.annualReviewDetails]); break; }
      case getRoute(ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepMap, { annualReviewId: annualReview.id }): { setBreadCrumbs(b => [...b.slice(0, -1), ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepMap]); break; }
      case getRoute(ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepCommunication, { annualReviewId: annualReview.id }): { setBreadCrumbs(b => [...b.slice(0, -1), ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepCommunication]); break; }
      case getRoute(ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepReview, { annualReviewId: annualReview.id }): { setBreadCrumbs(b => [...b.slice(0, -1), ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepReview]); break; }
      case getRoute(ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepStatement, { annualReviewId: annualReview.id }): { setBreadCrumbs(b => [...b.slice(0, -1), ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepStatement]); break; }
      case getRoute(ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepOrder, { annualReviewId: annualReview.id }): { setBreadCrumbs(b => [...b.slice(0, -1), ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepOrder]); break; }
      case getRoute(ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepPayment, { annualReviewId: annualReview.id }): { setBreadCrumbs(b => [...b.slice(0, -1), ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepPayment]); break; }

      case getRoute(ROUTES.PORTAL_ANNUALREVIEW_ROUTES.portalAnnualReviewDetails, { annualReviewId: annualReview.id }): { setBreadCrumbs(b => [...b.slice(0, -1), ROUTES.PORTAL_ANNUALREVIEW_ROUTES.portalAnnualReviewDetails]); break; }
      case getRoute(ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepMap, { annualReviewId: annualReview.id }): { setBreadCrumbs(b => [...b.slice(0, -1), ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepMap]); break; }
      case getRoute(ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepCommunication, { annualReviewId: annualReview.id }): { setBreadCrumbs(b => [...b.slice(0, -1), ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepCommunication]); break; }
      case getRoute(ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepReview, { annualReviewId: annualReview.id }): { setBreadCrumbs(b => [...b.slice(0, -1), ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepReview]); break; }
      case getRoute(ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepStatement, { annualReviewId: annualReview.id }): { setBreadCrumbs(b => [...b.slice(0, -1), ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepStatement]); break; }
      case getRoute(ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepOrder, { annualReviewId: annualReview.id }): { setBreadCrumbs(b => [...b.slice(0, -1), ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepOrder]); break; }
      case getRoute(ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepPayment, { annualReviewId: annualReview.id }): { setBreadCrumbs(b => [...b.slice(0, -1), ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepPayment]); break; }

      default: { break; }
    }
  }, [pathname, annualReview.id]);

  if (!annualReview) { return <LoadingScreen />; }

  const availableTabs = admin ? [
    ...isNewAr ? [] : [{ ...ROUTES.ADMIN_ANNUALREVIEW_ROUTES.annualReviewDetails, params: { annualReviewId: annualReview.id } }],
    { ...ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepMap, params: { annualReviewId: annualReview.id } },
    ...isNewAr ? [] : [{ ...ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepCommunication, params: { annualReviewId: annualReview.id } }],
    ...isNewAr ? [] : [...annualReview.status <= AnnualReviewStatusEnum.validated ? [{ ...ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepReview, params: { annualReviewId: annualReview.id } }] : []],
    ...annualReview.status >= AnnualReviewStatusEnum.validated ? [{ ...ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepStatement, params: { annualReviewId: annualReview.id } }] : [],
    ...annualReview.status >= AnnualReviewStatusEnum.statement_accepted ? [{ ...ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepOrder, params: { annualReviewId: annualReview.id } }] : [],
    ...annualReview.status >= AnnualReviewStatusEnum.order_validate ? [{ ...ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepPayment, params: { annualReviewId: annualReview.id } }] : [],
    ...comments.length > 0 ? [{ ...ROUTES.ADMIN_ANNUALREVIEW_ROUTES.RefuseHistory, params: { annualReviewId: annualReview.id } }] : [],
    // ...annualReview.status === AnnualReviewStatusEnum.statement_paid ? [{ ...ROUTES.ADMIN_ANNUALREVIEW_ROUTES.StepPayment, params: { annualReviewId: annualReview.id } }] : [],
  ] : [
    ...isNewAr ? [] : [{ ...ROUTES.PORTAL_ANNUALREVIEW_ROUTES.portalAnnualReviewDetails, params: { annualReviewId: annualReview.id } }],
    { ...ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepMap, params: { annualReviewId: annualReview.id } },
    ...isNewAr ? [] : [{ ...ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepCommunication, params: { annualReviewId: annualReview.id } }],
    ...isNewAr ? [] : [{ ...ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepReview, params: { annualReviewId: annualReview.id } }],
    ...annualReview.status >= AnnualReviewStatusEnum.validated ? [{ ...ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepStatement, params: { annualReviewId: annualReview.id } }] : [],
    ...annualReview.status >= AnnualReviewStatusEnum.statement_accepted ? [{ ...ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepOrder, params: { annualReviewId: annualReview.id } }] : [],
    ...annualReview.status >= AnnualReviewStatusEnum.order_validate ? [{ ...ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepPayment, params: { annualReviewId: annualReview.id } }] : [],
    // ...annualReview.status === AnnualReviewStatusEnum.statement_paid ? [{ ...ROUTES.PORTAL_ANNUALREVIEW_ROUTES.StepPayment, params: { annualReviewId: annualReview.id } }] : [],
  ];

  return (
    <PageCanvas
      breadCrumbs={breadCrumbs}
      title={<div>
        <div>Bilan {new Date(annualReview.startDate).getFullYear()}</div>
        <div className='text-sm'>
          {User.IsUserAdmin(user) &&
            <Link to={getRoute(ROUTES.ADMIN_STRUCTURE_ROUTES.structureDetails, { structureId: structure.id })}><StructureType structure={structure} /> de {structure.name}</Link>
          }
        </div>
      </div>}
    >

      <div className="grid grid-cols-1 gap-6">
        <AnnualReviewFigures annualReview={annualReview} />

        {userCanValidate && (
          <AnnualReviewRefusedMentionComponent annualReview={annualReview} />
        )}

        <Section>
          <NavTabs tabRoutes={availableTabs} entity={'annualReview'} />
          <Outlet />
        </Section>
      </div>
    </PageCanvas>
  );
};

export default AnnualReviewComponent;
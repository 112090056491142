import { Checkbox, Form } from 'antd';
import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useFormRules from '../../../../common/hooks/useFormRules';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { ROUTES } from '../../../../common/router';
import { selectCurrentContract, updateContractAction } from '../../../state/contract-portal.slice';
import NextStepComponent from '../../../../Auth/components/Register/NextStepComponent';
import LoadingScreen from '../../../../common/components/LoadingScreen';
import { getRoute } from '../../../../common/tools/router-tools';

const CommuneConflictStarter = () => {

  const { t } = useTranslation();
  const { required, requireTrue } = useFormRules();
  const contract = useAppSelector(selectCurrentContract)

  const navigate = useNavigate()
  const dispatch: Dispatch<any> = useAppDispatch()

  const submit = (formData: any) => {
    if (contract)
      dispatch(updateContractAction(formData))
    return navigate(getRoute(ROUTES.PORTAL_REGISTER_CONTRACT_ROUTES.STEPS_COMMUNE.conflict_recap))
  }

  if (!contract)
    return <LoadingScreen />

  return (
    <>
      <h2 className='mb-4'>{t('UI.REGISTER_CONTRACT.CONFLICT_STARTER.title')}</h2>
      <Form
        name="basic"
        layout='vertical'
        onFinish={submit}
        id="conflict"
        initialValues={contract}
      >
        <Form.Item
          name={'inChargeOfCleaning'}
          valuePropName="checked"
          rules={[required, requireTrue]}
        >
          <Checkbox>
            {t('UI.REGISTER_CONTRACT.CONFLICT_STARTER.fields.inChargeOfCleaning.label')}
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <NextStepComponent />
        </Form.Item>
      </Form>

    </>
  )
}

export default CommuneConflictStarter;
import { AcquisitionItemOrderStatusEnum, AcquisitionOrderStatusEnum, IAcquisitionOrders } from "@alcome-rep/alcome-types/dist/interfaces";
import ApiService, { ApiSortType } from "../../common/services/apiService";
import apiClient from "../../common/services/apiClient";

const ACQUISITION_ORDER_ADMIN_PATH_API = 'admin/acquisitionOrder';

class AdminAcquitionOrderApi extends ApiService<IAcquisitionOrders> {
  constructor() {
    super(ACQUISITION_ORDER_ADMIN_PATH_API)
  }

  findAcquisitionOrders = ({ query, page, limit, sort }: { query: any, page: number, limit: number, sort?: ApiSortType }) => {
    return this.find({ query, page, pageSize: limit, sort })
  }

  getAcquisitionOrder = (acquisitionOrderId: string) => {
    return this.get(acquisitionOrderId)
  }

  updateAllItemsStatus = (acquisitionOrderId: string) => {
    return this.custom<IAcquisitionOrders>('put', `${acquisitionOrderId}/items`)
  }

  updateItemOrderStatus = (acquisitionOrderId: string, itemIndex: number, status: AcquisitionItemOrderStatusEnum) => {
    return this.custom<IAcquisitionOrders>('put', `:acquisitionOrderId/items/:itemIndex/status`, { acquisitionOrderId, itemIndex }, { status })
  }

  updateOrderStatus = (id: string, { status, acquisitionOrderComment }: Partial<IAcquisitionOrders>) => {
    return this.custom<IAcquisitionOrders>('put', `:acquisitionOrderId/status`, { acquisitionOrderId: id }, { status, acquisitionOrderComment })
  }

  updateOrderDeclarationStatus = (acquisitionOrderId: string, status: AcquisitionOrderStatusEnum) => {
    return this.custom<IAcquisitionOrders>('put', `${acquisitionOrderId}/status/${status}`)
  }

  updateAcquisition = (acquisitionOrderId: string, data: any) => {
    return this.custom<IAcquisitionOrders>('put', `${acquisitionOrderId}`, undefined, data)
  }

  getFileDocument(propertyName: keyof IAcquisitionOrders, acquisitionOrderId: string, timestamp: any) {
    return apiClient({
      url: `${ACQUISITION_ORDER_ADMIN_PATH_API}/${acquisitionOrderId}/fileDoc/${propertyName}/${timestamp}`,
      method: 'get',
      responseType: 'blob'
    }).then(r => r.data)
  }



}

const acquisitionOrderAdminApi = new AdminAcquitionOrderApi();
export default acquisitionOrderAdminApi;
import { Button } from "antd";
import Section from "../../common/components/Panels/Section";
import { useTranslation } from "react-i18next";

type PropsType = {
    fetchStatementsToExport: () => void;
    setPeriod: (value: string) => void;
    entity: 'revenueOrder' | 'invoices';
}

const HeaderSectionExport = ({ fetchStatementsToExport, setPeriod, entity }: PropsType) => {

    const { t } = useTranslation();

    return (
        <Section>
            <h4 className="section-title mb-4">{t(`UI.ADMIN.ACCOUNTING_EXPORT.headerSection.title.${entity}`)}</h4>
            <p>{t(`UI.ADMIN.ACCOUNTING_EXPORT.headerSection.paragraph.${entity}`)}</p>

            <input type="month" min="2024-01" id="frmPeriod" onChange={e => setPeriod(e.target.value)} className="formInput" />
            <div style={{ marginTop: "20px" }}><Button className="btn rounded-lg items-center" onClick={fetchStatementsToExport}>{t(`UI.ADMIN.ACCOUNTING_EXPORT.headerSection.button.${entity}`)}</Button></div>
        </Section>
    )

}

export default HeaderSectionExport;
import { IAcquisitionOrders } from "@alcome-rep/alcome-types/dist/interfaces";
import ApiService from "../../common/services/apiService";
import msg from "../../common/services/toast";
import { message } from "antd";

const ACQUISITION_ORDER_PORTAL_PATH_API = '/portal/acquisitionOrder';

class AcquisitionOrderPortalApi extends ApiService<IAcquisitionOrders> {
  constructor() {
    super(ACQUISITION_ORDER_PORTAL_PATH_API)
  }

  findAcquisitionOrders({ query, page, pageSize }: { query: any, page: number, pageSize: number }) {
    return this.find({ query, page, pageSize })
  }

  createAcquisitionOrder(data: IAcquisitionOrders) {
    return this.create(data)
      .then(r => {
        msg.success('Commande créée avec succès!')
        return r;
      })
  }

  getAcquisitionOrder(acquisitionOrderId: string) {
    return this.get(acquisitionOrderId)
  }

  sendDeclaration(acquisitionOrderId: string) {
    return this.custom<IAcquisitionOrders>('put', `${acquisitionOrderId}/sendDeclaration`)
    .then(res => {
      if(res.items){
        message.success('Déclaration envoyée avec succès')
      }
    })
  }

  updateAcquisitionOrder(acquisitionOrderId: string, data: Partial<IAcquisitionOrders>) {
    return this.update(acquisitionOrderId, data)
  }

  deleteFileDocument(acquisitionOrderId: string, propertyName: string, timestamp?: number) {
    return this.custom<IAcquisitionOrders>('delete', `fileDoc/${acquisitionOrderId}/${propertyName}?timestamp=${timestamp}`)
  }

  deleteAcquisitionOrder(acquisitionOrderId: string) {
    return this.delete(acquisitionOrderId)
  }

  getFileDocument(propertyName: keyof IAcquisitionOrders, acquisitionOrderId: string) {
    return this.getFileDoc(propertyName, acquisitionOrderId)
  }

  uploadPictureInstallation(propertyName: keyof IAcquisitionOrders, acquisitionOrderId: string, file: any) {
    return this.uploadFileDoc(propertyName, file, acquisitionOrderId)
  }

}

const acquisitionOrderPortalApi = new AcquisitionOrderPortalApi();
export default acquisitionOrderPortalApi;
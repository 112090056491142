import { RouteObject } from "react-router-dom";


const routePrefix = 'admin'
const routeDomain = 'member'
const routeParam = 'memberId'

export const adminMemberRouteNames = {
  memberList: { path: `${routePrefix}/${routeDomain}`, title: `ROUTES.${routeDomain}.list` },
  memberDetails: { path: `${routePrefix}/${routeDomain}/:${routeParam}`, title: "ROUTES.infos" }
}

export const adminMemberRoutes: RouteObject[] = [
  {
    path: adminMemberRouteNames.memberList.path.replace(`${routePrefix}/`, ''),
    element: <></>,
  },
  {
    path: adminMemberRouteNames.memberDetails.path.replace(`${routePrefix}/`, ''),
    element: <></>
  },
]

import { ISupportOrders, SupportOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import ApiService from "../../common/services/apiService";

const SUPPORT_ORDER_ADMIN_PATH_API = 'admin/supportOrder';

class AdminSupportOrderApi extends ApiService<ISupportOrders> {
  constructor() {
    super(SUPPORT_ORDER_ADMIN_PATH_API)
  }

  async updateSupportOrderSetCompleted(supportOrderId: string, status: SupportOrderStatusEnum) {
    return this.custom<ISupportOrders>('put', `${supportOrderId}/status/${status}`)
}
}

const supportOrderAdminApi = new AdminSupportOrderApi();
export default supportOrderAdminApi;
import { ContactStatusEnum, IContact } from "@alcome-rep/alcome-types/dist/interfaces/crm"
import { Tag } from "antd"
import { useTranslation } from "react-i18next"

type PropsType = {
  contact: IContact,
}

const ContactStatus = ({ contact }: PropsType) => {
  const { t } = useTranslation();

  return <Tag className="space-x-2" color={t(`UI.TAG.ContactStatusEnum.${ContactStatusEnum[contact?.status]}`)}>
    {t(`TYPES.CRM.CONTACTS.ContactStatusEnum.${ContactStatusEnum[contact?.status]}`)}
  </Tag>
}

export default ContactStatus
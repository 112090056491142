import { Inventory } from "@alcome-rep/alcome-types/dist/models";
import { NavLink } from "react-router-dom";
import Section from "../../../../common/components/Panels/Section";
import { ROUTES } from "../../../../common/router";
import { getRoute } from "../../../../common/tools/router-tools";
import { EyeOutlined } from "@ant-design/icons";

type PropsType = {
    inventory: Inventory
}

const AdminInventoryDetails = ({ inventory }: PropsType) => {

    if (!inventory) {
        throw new Error()
    }
    return (
        <Section>
            <NavLink type="link" to={getRoute(ROUTES.ADMIN_CONTRACT_ROUTES.inventoryDetails, { structureId: inventory.structureId })}><EyeOutlined /> Voir les annexes</NavLink>
        </Section>
    );
};

export default AdminInventoryDetails;
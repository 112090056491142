import ApiService from "../../../common/services/apiService";

const ROLES_SETTINGS_PATH_API = 'admin/app';

class RolesSettingsApi extends ApiService<any> {
    constructor() {
        super(ROLES_SETTINGS_PATH_API)
    }

    /**
     * @description Get featureFile
     * @param data 
     * @returns featureFile
     */
    getFeatureFile() {
        return this.custom('get', 'featureFile')
    }

    /**
     * @description Update featureFile
     * @param data 
     * @returns featureFile updated
     */
    updateFeatureFile(data: any) {
        return this.custom('put', 'updateFeatureFile', undefined, data)
    }

}

const rolesSettingsApi = new RolesSettingsApi();
export default rolesSettingsApi;

import useQuotas from "../../../../AcquisitionOrder/hooks/useQuota.hook";
import Section from "../../../../common/components/Panels/Section";

const SupportCounter = ({ structureId }: { structureId: string }) => {
  const quotas = useQuotas(structureId);

  if (!quotas)
    return <></>

  return <>
    <div className="grid grid-cols-2 gap-6">
      <Section title="Cendriers de rue">
        <div className="counterAllowed">Quota : {quotas.quotas.ashtrays}</div>
        <div className="counterAllowed">Déja commandé : {quotas.used.ashtrays}</div>
        <div className="text-xs">*le quota est valable jusqu'à la fin de l'agrément ALCOME</div>
      </Section>
      <Section title="Dispositifs associés aux corbeilles">
        <div className="counterAllowed">Quota : {quotas.quotas.extinguishers}</div>
        <div className="counterAllowed">Déja commandé : {quotas.used.extinguishers}</div>
        <div className="text-xs">*le quota est valable jusqu'à la fin de l'agrément ALCOME</div>
      </Section>
    </div>
  </>
}

export default SupportCounter
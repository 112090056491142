import { TablePaginationConfig } from 'antd';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const useTableChange = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  return useMemo(() => (pagination: TablePaginationConfig) => {
    searchParams.set('page', String(pagination.current))
    searchParams.set('limit', String(pagination.pageSize))
    setSearchParams(searchParams)
  }, [searchParams, setSearchParams])
};

export default useTableChange;

import { Tag } from "antd";
import ContractStatus from "../../../Contract/components/admin/ContractData/ContractStatus";
import { Contract } from "@alcome-rep/alcome-types/dist/models";

export const columnsStats = () => [
  {
    title: 'Indicateur',
    dataIndex: 'label',
    key: 'label'
  },
  {
    title: 'Nombre',
    dataIndex: 'value',
    key: 'value'
  }
];

export const columsExcel = () => [
  {
    title: 'Nom',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Insee / Siren',
    dataIndex: 'siren',
    key: 'siren',

  },
  {
    title: 'Type de structure',
    dataIndex: 'structureType',
    key: 'structureType',
    render: (value: any) => {
      if (value === 'groupement_communes') return (<Tag color='blue'>GROUPEMENT</Tag>)
      else return (<Tag color='green'>COMMUNE</Tag>)
    },
  },
  {
    title: 'Typologie',
    dataIndex: ['inseeRef', 'type'],
    key: 'inseeRef.type',
  },
  {
    title: 'Population',
    dataIndex: ['inseeRef', 'pop'],
    key: 'inseeRef.pop',
  },
  {
    title: 'Statut du contrat',
    dataIndex: ['contract', 'status'],
    // key: 'contract.status',
    render: (value: any) => <ContractStatus contract={{ status: value } as Contract} translationString="ContractStatusEnumAdmin" />
    // if (value === 0) return (<Tag color='red'>En cours</Tag>)
    // else if (value === 10) return (<Tag color='orange'>À valider</Tag>)
    // else return (<Tag color='green'>Validé</Tag>)
    ,

  },

  {
    title: 'Date inscription',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (value: any) => {
      let tmp = new Date(value).toLocaleDateString("fr");
      if (tmp === 'Invalid Date') return '';
      return tmp;
    }
  },
  {
    title: 'Date signature',
    dataIndex: ['contract', 'contractDate'],
    key: 'contract.contractDate',
    render: (value: any) => {
      let tmp = new Date(value).toLocaleDateString("fr");
      if (tmp === 'Invalid Date') return '';
      return tmp;
    }
  },
  {
    title: 'Année',
    dataIndex: 'contractYear',
    key: 'contractYear',
  },
  {
    title: 'Prévisionnel ' + (new Date()).getFullYear(),
    dataIndex: 'forecastAmount',
    key: 'forecastAmount',
  },
  {
    title: 'Email',
    dataIndex: ['user', 'email'],
    key: 'user.email',
  },
  {
    title: 'Téléphone',
    dataIndex: ['user', 'phone'],
    key: 'user.phone',
  },
]
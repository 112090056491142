import { IAmendment } from '@alcome-rep/alcome-types/dist/interfaces';
import { IUserAdmin } from '@alcome-rep/alcome-types/dist/interfaces/user';
import { Alert } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import amendmentPortalApi from '../../../Amendment/services/amendment-portal.api';
import ContractSignatory from '../../../Contract/components/admin/ContractSignatory/ContractSignatory';
import PortalContractFiles from '../../../Contract/components/portal/PortalContractFiles/PortalContractFiles';
import { selectCurrentContract } from '../../../Contract/state/contract-portal.slice';
import { selectCurrentInventory } from '../../../Inventory/state/inventory-portal.slice';
import StructureDetails from '../../../Structure/components/admin/StructureDetails/StructureDetails';
import StructureFigures from '../../../Structure/components/admin/StructureDetails/StructureFigures';
import StructureType from '../../../Structure/components/common/StructureType';
import structureApi from '../../../Structure/services/structure.api';
import { selectCurrentStructure } from '../../../Structure/state/structure-portal.slice';
import userApi from '../../../User/services/user.api';
import LoadingScreen from '../../../common/components/LoadingScreen';
import PageCanvas from '../../../common/components/Panels/PageCanvas';
import Section from '../../../common/components/Panels/Section';
import { useAppSelector } from '../../../common/hooks';
import { ROUTES } from '../../../common/router';
import { getRoute } from '../../../common/tools/router-tools';
import RegionalDelegateSection from './RegionalDelegateSection';
import AmendmentAlertBanner from './amendmentAlertBanner';

const PortalDashBoardPage = () => {

  const structure = useAppSelector(selectCurrentStructure)
  const contract = useAppSelector(selectCurrentContract)
  const inventory = useAppSelector(selectCurrentInventory)

  const [department, setDepartment] = useState<string>();
  const [regionalDelegate, setRegionalDelegate] = useState<IUserAdmin>()
  const [amendments, setAmendments] = useState<IAmendment[]>([])

  const lastUpdate = contract?.updatedAt;

  useEffect(() => {
    if (lastUpdate)
      amendmentPortalApi.getAmendmentToSign()
        .then(res => {
          setAmendments(res.rows)
        })
  }, [lastUpdate])

  useEffect(() => {

    if (structure) {
      structureApi.getStructureDepartment(structure.id as string)
        .then(res => {
          setDepartment(res)
          userApi.findRegionalDelegate(`${res}`)
            .then(resp => setRegionalDelegate(resp))
        })
    }
  }, [structure])

  if (!contract || !structure || !inventory)
    return <LoadingScreen></LoadingScreen>

  return (
    <>
      <div className='mb-4'>
        {amendments.length > 0 && amendments.map(amendment => (
          <Alert key={amendment.id} message={
            <AmendmentAlertBanner amendment={amendment} />
          } banner />
        ))}
      </div>
      <PageCanvas
        breadCrumbs={[
          ROUTES.PORTAL_CONTRACT_ROUTES.dashboard,
        ]}
        title={<div>
          <div>{structure.name}</div>
          <div className='text-sm'><StructureType structure={structure} /></div>
        </div>}
        actions={<Link to={getRoute(ROUTES.PORTAL_CONTRACT_ROUTES.contractDetails)} className="font-semibold">Voir le contrat</Link>}
      >
        <StructureFigures contract={contract} inventory={inventory} structure={structure} />

        <div className="grid grid-cols-3 gap-4 my-4">
          <div className="col-span-1">

            <Section
              title="Informations"
            // actions={<Button icon={<EditOutlined />} type="text" onClick={_ => editInfos()} />}
            >
              <StructureDetails structure={structure} />
              {regionalDelegate && (
                <div className='mt-2'>
                  <RegionalDelegateSection regionalDelegate={regionalDelegate} department={department as string} isPortal={true}/>
                </div>
              )}
            </Section >

          </div>

          <div className="col-span-2">
            <Section
              title="Fichiers / Contrat"
              mode="transparent"
            >
              <PortalContractFiles contract={contract} />
            </Section>

            <Section
              title="Signataire du contrat"
              mode="standard"
            >
              <ContractSignatory contract={contract} inventory={inventory} />

            </Section>
          </div>

        </div >


      </PageCanvas>

    </>
  );
};

export default PortalDashBoardPage;
import { Button, Form, Input } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import userApi from '../../../User/services/user.api';
import useFormRules from '../../../common/hooks/useFormRules';
import msg from '../../../common/services/toast';
import { useTrackProgress } from '../../../common/services/trackProgress';

const ForgottenPasswordForm = () => {
  const { t } = useTranslation();
  const [sent, setSent] = useState<boolean>(false)
  const { required, email } = useFormRules();

  const isLoading = useTrackProgress(userApi.trackProgressAreas.custom('put'))

  const onSubmit = async (data: { email: string }) => {
    await userApi.askForPassword(data.email)
    msg.success('Demande envoyée')
    setSent(true)
  }

  return (
    <>
      <h1>Mot de passe oublié ?</h1>
      {!sent &&
        <>
          <p className="subtitle">Entrez votre email et recevez un lien de réinitilisation de mot de passe.</p>
          <Form onFinish={onSubmit} layout="vertical">
            <Form.Item
              label={t('fields.email.label', { defaultValue: 'E-mail' })}
              name="email"
              rules={[required, email]}
            >
              <Input
                autoFocus
                placeholder={t('fields.email.placeholder', { defaultValue: 'E-mail' })}
              />
            </Form.Item>
            <Button block type="primary" htmlType="submit" loading={isLoading}>
              Réinitialiser votre mot de passe
            </Button>
          </Form>
        </>
      }
      {sent && <>
        <p>Votre demande a été transmise et un email vous a été envoyé. Suivez les instructions pour terminer la procédure de changement de mot de passe.</p>
      </>}
    </>
  );
};

export default ForgottenPasswordForm;


// Reste dans le Type.ts

import { Inventory } from "@alcome-rep/alcome-types/dist/models";

export type InventoryServiceState = {
  currentInventory: Inventory | null
};

export const INVENTORY_PORTAL_SERVICE = "inventoryPortalService";
// eslint-disable-next-line
export type INVENTORY_PORTAL_SERVICE = typeof INVENTORY_PORTAL_SERVICE; // Typescript line

export const INVENTORY_ADMIN_SERVICE = "inventoryAdminService";
// eslint-disable-next-line
export type INVENTORY_ADMIN_SERVICE = typeof INVENTORY_ADMIN_SERVICE; // Typescript line



export interface CreateInventoryPayload extends Partial<Inventory> {

}


import { SignatoryTypesEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { Contract } from "@alcome-rep/alcome-types/dist/models";
import AdminContractFileUpload from "../FileUploads/AdminContractFileUpload";
import AdminDelegationFileUpload from "../FileUploads/AdminDelegationUpload";
import AdminExecutiveDeliberationFileUpload from "../FileUploads/AdminExecutiveDeliberationUpload";

type PropsType = {
  contract: Contract
}

const AdminContractFiles = ({ contract }: PropsType) => {

  if (!contract) {
    throw new Error()
  }
  return (
    <>
      <AdminContractFileUpload />
      <AdminExecutiveDeliberationFileUpload />
      {contract.signatory && contract.signatory.type === SignatoryTypesEnum.delegate && (
        <AdminDelegationFileUpload />
      )}
    </>

  );
};

export default AdminContractFiles;
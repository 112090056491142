import { hasFeatureAccess } from "@alcome-rep/alcome-types";
import { IUser } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { UserPortal } from "@alcome-rep/alcome-types/dist/models";
import { EditOutlined, MoreOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Dropdown, Table, message } from "antd";
import confirm from "antd/es/modal/confirm";
import { ColumnsType } from "antd/es/table";
import { MenuProps } from "antd/lib";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import authApi from "../../../../Auth/services/auth.api";
import { resetLoginAsAction, selectUserAdmin, setLoginAsAction } from "../../../../Auth/state/auth.slice";
import userAdminApi from "../../../../User/services/user-admin.api";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks";
import { ROUTES } from "../../../../common/router";
import { closeDrawerAction } from "../../../../common/state/modal.slice";
import { getHomeRoute, getRoute } from "../../../../common/tools/router-tools";
import { selectCurrentAdminStructure } from "../../../state/structure-admin.slice";

type PropsType = {
  cb?: () => void
}

const StructureUserList = ({ cb }: PropsType) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const user = useAppSelector(selectUserAdmin)
  const structure = useAppSelector(selectCurrentAdminStructure)

  const [userInStructure, setUserInStructure] = useState<UserPortal[]>();
  const [loading, setloading] = useState<boolean>(true);

  const { structureId }: any = useParams();

  useEffect(() => {
    setloading(true)
    userAdminApi.findAllUserPortal({ structureId }, 1, -1)
      .then(res => {
        setUserInStructure(res.rows);
        setloading(false)
      })
  }, [structureId]);

  const loginAs = async (user: IUser) => {
    const userFullName = `${user.firstName} ${user.lastName}`
    confirm({
      title: 'Connexion',
      icon: <UserOutlined />,
      content: `Voulez-vous vous connecter en tant que ${userFullName} ?`,
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okText: 'Oui',
      okButtonProps: { className: "btn" },
      async onOk() {
        dispatch(resetLoginAsAction({ userType: user.userType }))
        const auth = await authApi.loginAs(user._id!)
        dispatch(setLoginAsAction(auth))
        window.open(getHomeRoute(auth.user.userType), "_blank", "noreferrer");
        cb && cb()
      },
      onCancel() {
        cb && cb()
      },
    });

  }

  const invalidateToken = (userId: string, userFullName?: string) => {
    confirm({
      title: 'Jeton de connexion',
      icon: <UserOutlined />,
      content: `Êtes-vous sûr de vouloir invalider le jeton de ${userFullName} ?`,
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okText: 'Oui',
      okButtonProps: { className: "btn" },
      onOk() {
        userAdminApi.invalidateToken(userId).then(_ => message.success("Jeton invalidé"))
        cb && cb()
      },
      onCancel() {
        cb && cb()
      },
    });

  }

  const editUser = (userId: string) => {
    navigate(getRoute(ROUTES.ADMIN_USER_ROUTES.userDetails, { userId: userId }))
    dispatch(closeDrawerAction())
  }


  const userListColumns: ColumnsType<UserPortal> = [{
    title: 'Actions',
    fixed: 'left',
    align: 'left',
    render: (value: any) => {
      const canLoginAs = hasFeatureAccess('admin.structure.loginAs', { userRole: user?.role })
      const canInvalidateToken = hasFeatureAccess('admin.structure.invalidateToken', { userRole: user?.role, structureType: structure?.structureType })

      const items: MenuProps['items'] = []
      if (canLoginAs)
        items.push({
          key: 'loginAs',
          onClick: _ => loginAs(value),
          icon: <UserOutlined />,
          label: "Se connecter en tant que..."
        })
      if (canInvalidateToken) {
        items.push(
          {
            key: 'invalidateToken',
            onClick: _ => {
              const userFullName = `${value.firstName} ${value.lastName}`
              return invalidateToken(value._id, userFullName)
            },
            icon: <UserOutlined className='font-semibold text-lg' />,
            label: "Invalider le jeton de connexion"
          }
        )
      }

      return (
        <div className='flex space-x-4'>
          <Button htmlType="button" type="link" className="px-0" onClick={() => editUser(value._id)} icon={<EditOutlined />} />
          <Dropdown menu={{ items }}>
            <Button type="link" htmlType="button" icon={<MoreOutlined className="text-lg" />} onClick={e => e.stopPropagation()}></Button>
          </Dropdown>
        </div>
      )
    }
  },
  {
    title: 'Nom',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'Prénom',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Téléphone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Fonction',
    dataIndex: 'position',
    key: 'position',
  },

  ]

  return (
    <>

      {(
        <Table<UserPortal>
          columns={userListColumns}
          dataSource={userInStructure}
          loading={loading}
          rowKey="id"
          pagination={false} />
      )}
    </>

  )
}

export default StructureUserList
import { CleaningContextEnum, InventoryStatusEnum } from '@alcome-rep/alcome-types/dist/interfaces'
import { User } from '@alcome-rep/alcome-types/dist/models'
import { CloseOutlined, PercentageOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, InputNumber, Switch } from 'antd'
import { useForm, useWatch } from 'antd/lib/form/Form'
import TextArea from 'antd/lib/input/TextArea'
import { Dispatch } from 'react'
import { useTranslation } from 'react-i18next'
import { selectUser } from '../../../Auth/state/auth.slice'
import LoadingScreen from '../../../common/components/LoadingScreen'
import Section from '../../../common/components/Panels/Section'
import { useAppDispatch, useAppSelector } from '../../../common/hooks'
import useFormRules from '../../../common/hooks/useFormRules'
import msg from '../../../common/services/toast'
import { randomNumberId } from '../../../common/tools/global-tools'
import inventoryPortalApi from '../../services/inventory-portal.api'
import { selectCurrentAdminInventory } from '../../state/inventory-admin.slice'
import { selectCurrentInventory, setCurrentInventoryAction } from '../../state/inventory-portal.slice'

const SalubriteForm = () => {
  const [form] = useForm()
  const inChargeOfTrashes = useWatch('inChargeOfTrashes', form)

  const user = useAppSelector(selectUser)
  const inventory = useAppSelector(User.IsUserAdmin(user) ? selectCurrentAdminInventory : selectCurrentInventory)

  const dispatch: Dispatch<any> = useAppDispatch()

  const { required, email, phoneNumber } = useFormRules()
  
  const { t } = useTranslation()

  const save = async (formData?: any) => {
    if (!inventory)
      return
    if (!formData)
      formData = form.getFieldsValue();

    try {
      const updatedInventory = await inventoryPortalApi.updateInventoryInfos(inventory.id!, {
        ...formData,
        communes: (formData.communes && formData.communes.map((c: any) => ({ ...c, email: c.email ? c.email : undefined })))
      })
      msg.success('Enregistré')
      dispatch(setCurrentInventoryAction(updatedInventory));
    } catch (_) {
      msg.error('Erreur')
    }
  }

  const canEdit = inventory?.userCanEdit(user);

  // just a workaround because conditionnal fields display wont work on url change (line 75 {form.getFieldvalue...})
  // setTimeout(() => setRefresh(new Date()), 800)
  if (!inventory)
    return <LoadingScreen />

  return (
    <Section mode="transparent" title='Etat des lieux relatifs à l’organisation de la salubrité publique'>
      <Form
        form={form}
        onFinish={save}
        // initialValues={inventory}
        initialValues={{
          ...inventory,
          trashesCommunes: inventory.trashesCommunes
            .map(item => ({
              id: item ? item.id || randomNumberId() : randomNumberId(),
              name: item ? item.name : "",
              siren: item ? item.siren : "",
              email: item ? item.email : "",
              phone: item ? item.phone : ""
            }))
        }}
        layout="vertical"
        className='w-full '
        validateTrigger={['onChange']}
      // onValuesChange={_ => setRefresh(new Date())}
      >
        <Form.Item name={['cleaningContext']} label={t('UI.PORTAL.INVENTORY.SALUBRITE.organisation')}>
          <Checkbox.Group className='flex flex-col' >
            {Object.keys(CleaningContextEnum)
              .filter(s => typeof s === 'string')
              .map(context => (
                <div key={context}>
                  <Checkbox value={context} disabled={!canEdit}>
                    {t('TYPES.INVENTORY.CleaningContextEnum.' + context)}
                  </Checkbox>
                </div>
              ))}
          </Checkbox.Group>
        </Form.Item>
        <Form.Item name='cleaningContextOther' label={'Autre'}>
          <TextArea disabled={inventory.status >= InventoryStatusEnum.completed}></TextArea>
        </Form.Item>
        <hr />
        <Form.Item
          // initialValue={false}
          name={['inChargeOfTrashes']}
          label={t('UI.PORTAL.INVENTORY.SALUBRITE.trashes')}
          valuePropName="checked">
          <Switch disabled={!canEdit} defaultChecked={false}></Switch>
        </Form.Item>
        {inChargeOfTrashes && (
          // {form.getFieldValue('inChargeOfTrashes') === true && (
          <Form.Item
            name={['trashesContext']}
            rules={[
              ({ getFieldValue }) => ({
                validator(_rule, _value) {
                  const trashContextValue = getFieldValue('trashesContext');
                  const sum = Object.keys(trashContextValue).reduce((sum, k) => sum += +trashContextValue[k] || 0, 0);

                  if (sum !== 100) {
                    return Promise.reject('La somme doit être égale à 100%')
                  }
                  return Promise.resolve()
                }
              })
            ]}>
            <label>Si oui, préciser la part prise en charge dans les différents services :</label>

            <div className='grid grid-cols-2 grid-rows-2 gap-2 my-2'>
              <Form.Item name={['trashesContext', 'cleaning']} label="Nettoiement ou propreté" className='mb-0'>
                <InputNumber min={0} max={100} addonAfter={(<PercentageOutlined></PercentageOutlined>)}
                  disabled={!canEdit}></InputNumber>
              </Form.Item>
              <Form.Item name={['trashesContext', 'trashes']} label="Gestion des déchets" className='mb-0'>
                <InputNumber min={0} max={100} addonAfter={(<PercentageOutlined></PercentageOutlined>)}
                  disabled={!canEdit}></InputNumber>
              </Form.Item>
              <Form.Item name={['trashesContext', 'voirie']} label="Gestion de la voirie" className='mb-0'>
                <InputNumber min={0} max={100} addonAfter={(<PercentageOutlined></PercentageOutlined>)}
                  disabled={!canEdit}></InputNumber>
              </Form.Item>
              <Form.Item name={['trashesContext', 'espacesVerts']} label="Service des espaces verts" className='mb-0'>
                <InputNumber min={0} max={100} addonAfter={(<PercentageOutlined></PercentageOutlined>)}
                  disabled={!canEdit}></InputNumber>
              </Form.Item>
            </div>
            {/* {!isTrashContextValid && (<div className="text-alc-danger font-semibold my-4 py-2">La somme doit être égale à 100%</div>)} */}
          </Form.Item>
        )}
        {form.getFieldValue('inChargeOfTrashes') !== true && (
          <>
            <label>Si non, renseignez les communes ou personnes publiques qui interviennent dans la collecte :</label>
            <div className='grid mt-4 w-full gap-1' style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr 4rem' }}>
              <div className='text-center font-semibold text-alc-blue border-b-1 border-alc-blue'>Code INSEE</div>
              <div className='text-center font-semibold text-alc-blue border-b-1 border-alc-blue'>Nom</div>
              <div className='text-center font-semibold text-alc-blue border-b-1 border-alc-blue'>Téléphone</div>
              <div className='text-center font-semibold text-alc-blue border-b-1 border-alc-blue'>Email</div>
              <div className=''></div>

              <Form.List
                // rules={[
                //     { validator: (rule, value) => value.length === 0 ? Promise.reject() : Promise.resolve() }
                // ]}
                name="trashesCommunes">
                {(fields, { add, remove }, { errors }) => (
                  <div className='contents'>
                    {fields.length === 0 && (
                      <div className='col-span-5'>
                        <div className='text-center italic font-sm border-y-1 border-white text-alc-danger'>Vous devez renseigner au moins une commune</div>
                      </div>
                    )}
                    {fields.map(({ name, key, ...rest }) => (
                      <div key={key} className='contents'>
                        <div><Form.Item {...rest} className='mb-0' rules={[required]} name={[name, 'siren']} ><Input disabled={!canEdit} autoComplete='none'></Input></Form.Item></div>
                        <div><Form.Item {...rest} className='mb-0' rules={[required]} name={[name, 'name']}><Input disabled={!canEdit} autoComplete='none'></Input></Form.Item></div>
                        <div><Form.Item {...rest} className='mb-0' rules={[required, phoneNumber]} name={[name, 'phone']}><Input disabled={!canEdit} autoComplete='none'></Input></Form.Item></div>
                        <div><Form.Item {...rest} className='mb-0' rules={[required, email]} name={[name, 'email']}><Input disabled={!canEdit} autoComplete='none'></Input></Form.Item></div>
                        <div className='text-right'>
                          {canEdit && <Button className='border-none' onClick={_ => remove(name)} icon={
                            <CloseOutlined className='text-alc-danger' disabled={!canEdit}>
                            </CloseOutlined>}>
                          </Button>}
                        </div>
                      </div>
                    ))}
                    <div className='col-span-5 text-left'>
                      {canEdit && <Button onClick={_ => add({ /*id: randomNumberId()*/ })} type="link" htmlType='button' icon={<PlusCircleOutlined></PlusCircleOutlined>}>Ajouter</Button>}
                    </div>
                  </div>
                )}
              </Form.List>
            </div>
          </>)}
        <hr />
        <div id="salubrite">
          <Form.Item
            rules={[required]}
            name={['voiriesInteretCommunautaire']}
            label="Évaluez la part du budget de nettoyage/maintien de la propreté concerné par des voiries d’intérêt communautaire :">
            <InputNumber min={0} max={100} addonAfter={(<PercentageOutlined></PercentageOutlined>)}
              disabled={inventory.status > InventoryStatusEnum.refused}></InputNumber>
          </Form.Item>

        </div>
        {canEdit && (
          <Button type="primary" htmlType="submit" className="btn">{t('COMMON.save')}</Button>
        )}
      </Form>
    </Section>)
}
export default SalubriteForm
import { ActionStatusEnum, ActionTypeEnum, IAction, IContact } from '@alcome-rep/alcome-types/dist/interfaces/crm';
import { EditOutlined, MoreOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Dropdown, Table } from 'antd';
import confirm from 'antd/es/modal/confirm';
import { DefaultOptionType } from 'antd/es/select';
import { MenuProps } from 'antd/lib';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { selectUserAdmin } from '../../../Auth/state/auth.slice';
import DateField from '../../../common/components/Fields/DateField';
import FilterDelegate from '../../../common/components/Filters/FilterDelegate';
import FilterEntity from '../../../common/components/Filters/FilterEntity';
import FilterNavLink from '../../../common/components/Filters/FilterNavLink';
import FilterPanel from '../../../common/components/Panels/FilterPanel';
import PageCanvas from '../../../common/components/Panels/PageCanvas';
import QuickLinksPanel from '../../../common/components/Panels/QuickLinksPanel';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import useQueryParams from '../../../common/hooks/useQueryParams';
import useTableChange from '../../../common/hooks/useTableChange';
import { ROUTES } from '../../../common/router';
import { closeDrawerAction, openDrawerAction } from '../../../common/state/modal.slice';
import { getRoute } from '../../../common/tools/router-tools';
import actionAdminApi from '../../services/action-admin.api';
import contactAdminApi from '../../services/contact-admin.api';
import prospectAdminApi from '../../services/prospect-admin.api';
import ActionInfosForm from '../ActionDetails/ActionInfosForm';
import ActionStatus from '../ActionDetails/ActionStatus';
import ActionType from '../ActionDetails/ActionType';

const ActionListComponent = () => {
  const [data, setData] = useState<IAction[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setloading] = useState<boolean>(false);

  const [refresh, setRefresh] = useState<Date>();

  const user = useAppSelector(selectUserAdmin)
  const dispatch = useAppDispatch()

  const tableChange = useTableChange();
  const queryParams = useQueryParams()

  const { t } = useTranslation()

  useEffect(() => {
    setloading(true)
    actionAdminApi.findActions({ query: {...queryParams.query}, page: Number(queryParams.page), limit: Number(queryParams.limit) })
      .then(res => {
        setData(res.rows)
        setCount(res.count)
        setloading(false)
      })
      .catch(() => {
        setData([])
        setCount(0)
        setloading(false)
      })
  }, [setData, setCount, setloading, queryParams, refresh])

  const findProspects = (name: string) => {
    return actionAdminApi.findProspects({
      query: { name },
      page: 1, limit: 5, sort: { name: 1 }
    }).then(r => r.rows
      .map(p => ({
        label: p.name,
        value: String(p.id)
      }) as DefaultOptionType)
    )
  }

  const retrieveProspect = async (id: string) => {
    const prospect = await prospectAdminApi.getProspect(id)
    return { label: prospect.name, value: String(prospect.id) } as DefaultOptionType
  }

  const findContacts = (name: string) => {
    return contactAdminApi.findContacts({
      query: { lastName: name },
      page: 1,
      limit: 5
    }).then(r => r.rows
      .map(p => ({
        label: `${p.firstName} ${p.lastName}`,
        value: String(p.id)
      }) as DefaultOptionType)
    )
  }
  const retrieveContact = (id: string) => {
    return contactAdminApi.get(id)
      .then(res => ({ label: `${res.firstName} ${res.lastName}`, value: String(res.id) }) as DefaultOptionType)
  }

  const editAction = (action?: IAction) => {
    dispatch(openDrawerAction({
      component: <ActionInfosForm
        prospectIdToEdit={action?.prospectId}
        formCb={data => {
          dispatch(closeDrawerAction())
          if (data === null || data)
            setRefresh(new Date())
        }}
        action={action}
      />,
      options: { title: "Action" }
    }))
  }

  const deleteAction = (value: IAction) => {
    confirm({
      title: 'Voulez-vous supprimer l\'action ?',
      icon: <QuestionCircleOutlined />,
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okText: 'Oui',
      okButtonProps: { className: "btn" },
      async onOk() {
        await actionAdminApi.deleteAction(value.id as string);
        setRefresh(new Date());
      },
      onCancel() {
      },
    });
  }

  const duplicateAction = (value: IAction) => {
    confirm({
      title: 'Voulez-vous dupliquer l\'action ?',
      icon: <QuestionCircleOutlined />,
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okText: 'Oui',
      okButtonProps: { className: "btn" },
      async onOk() {
        const newAction = await actionAdminApi.create({
          actionDate: new Date(),
          comment: value.comment,
          prospectId: (value.prospect as any).id,
          contactsId: value.contactsId,
          type: value.type,
          status: value.status,
        })
        editAction(newAction)
        setRefresh(new Date());
      },
      onCancel() {
      },
    });
  }


  const columns: any = [
    {
      fixed: 'left',
      width: '50px',
      render: (value: any) => {
        const getItems = (action: IAction): MenuProps['items'] => [
          { label: "Dupliquer", key: 'clone', onClick: () => duplicateAction(action) },
          { label: "Supprimer", key: 'delete', onClick: () => deleteAction(action) }
        ]

        return (
          <div className='flex space-x-2'>
            <Button
              className="btn-link text-alc-blue font-semibold text-lg px-0"
              onClick={() => editAction(value)}
              icon={<EditOutlined />}
            />

            <Dropdown menu={{ items: getItems(value) }}>
              <Button
                type="link"
                icon={<MoreOutlined />}
              />
            </Dropdown>
          </div>
        )
      }
    },
    {
      title: "Date de l'action",
      dataIndex: "actionDate",
      fixed: 'left',
      width: '150px',
      key: "actionDate",
      render: (value: any) => <DateField value={value} />
    },
    {
      title: "Prospect",
      dataIndex: ['prospect', 'name'],

      render: (value: any, item: any) =>
        item.prospect &&
        (<Link to={`${getRoute(ROUTES.ADMIN_CRM.ADMIN_PROSPECT_ROUTES.prospectDetails, { prospectId: item.prospectId })}`}
          className='font-semibold text-center'>{item.prospect.name}
        </Link>)
      ,
    },

    {
      title: "Contact(s)",
      width: '250px',
      dataIndex: 'contacts',
      key: "contact",
      render: (value: IContact[]) =>
        (value ?? [])
          .map((v, i) => (<div key={i} className='mb-1'>
            {`${v.firstName} ${v.lastName}`}
          </div>)
          )
    },
    {
      title: "Type d'action",
      dataIndex: "type",
      width: '250px',
      key: "type",
      filterMultiple: false,
      render: (value: any) => <ActionType action={{ type: value } as IAction} />
    },
    {
      title: "Statut de l'action",
      key: "status",
      dataIndex: "status",
      filterMultiple: false,
      render: (value: any) => <ActionStatus action={{ status: value } as IAction} />
    },

  ];

  return (
    <>
      <PageCanvas
        breadCrumbs={[
          ROUTES.ADMIN_USER_ROUTES.home,
          ROUTES.ADMIN_CRM.ADMIN_ACTION_ROUTES.actionList
        ]}
        title={<span>Liste d'actions <small>({count ? count : 0})</small ></span >}
        actions={<Button type="primary" onClick={_ => editAction()} icon={<PlusOutlined />}>
          Nouvelle action
        </Button>}
      >
        <FilterPanel>
          <FilterDelegate initialValue={user.id} />

          <FilterEntity
            filterName='Prospect'
            multiple={false}
            queryParamName='prospectId'
            findFn={findProspects}
            retrieveFn={retrieveProspect}
          />
          <FilterEntity
            filterName='Type action'
            multiple={false}
            queryParamName='type'
            options={
              Object.keys(ActionTypeEnum)
                .filter(k => typeof k === 'string')
                .map(k => ({ label: t('TYPES.CRM.ACTIONS.ActionTypeEnum.' + k), value: k }))
            }
          ></FilterEntity>

          {/* <FilterDepartment /> */}

          <FilterEntity
            filterName='Contact'
            multiple={false}
            queryParamName='contactsId[]'
            findFn={findContacts}
            retrieveFn={retrieveContact}
          ></FilterEntity>

          <FilterEntity
            filterName='Statut action'
            multiple={false}
            queryParamName='status'
            options={
              Object.keys(ActionStatusEnum)
                .filter(k => typeof k === 'string')
                .map(k => ({ label: t('TYPES.CRM.ACTIONS.ActionStatusEnum.' + k), value: k }))
            }
          ></FilterEntity>

        </FilterPanel>

        <QuickLinksPanel>
          <FilterNavLink queryParams={`?status=${ActionStatusEnum.upcomingAction}`} label="Actions à venir" />
        </QuickLinksPanel >

        <div className=''>
          <Table<IAction>
            columns={columns}
            dataSource={data}
            loading={loading}
            onChange={tableChange}
            rowKey={'id'}
            size="small"
            scroll={{ scrollToFirstRowOnChange: true, x: 1000 }}
            pagination={
              {
                hideOnSinglePage: true,
                current: queryParams.page,
                pageSize: queryParams.limit,
                total: count,
              }
            } />
        </div>
      </PageCanvas >

    </>
  );
};

export default ActionListComponent;
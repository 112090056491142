import { Form, Input } from "antd";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { defaultDebounce } from "../../app-constants";
import { debounce } from "../../tools/global-tools";

type PropsType = {
  label?: string
  searchParamName: string,
  isRegex?: boolean
}

const FilterInput = ({ label, searchParamName, isRegex = false }: PropsType) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let [currentValue, setCurrentValue] = useState<string | null>();

  const debounceInput = useMemo(() => {
    return debounce((newVal: string | undefined) => {
      if (!!newVal)
        searchParams.set(searchParamName, isRegex ? `/${newVal}/` : newVal)
      else
        searchParams.delete(searchParamName)
      setSearchParams(searchParams)
    }, defaultDebounce);
  }, [isRegex, searchParamName, searchParams, setSearchParams])

  const changeInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    const v = e.target?.value;
    setCurrentValue(v);

    debounceInput(v)
  }

  const value = searchParams.get(searchParamName)
  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  return <Form.Item
    label={label}
    className="m-0">
    <Input
      value={currentValue ? currentValue.replace(/^\/?(.+?)\/?$/, '$1') : undefined}
      allowClear
      onChange={changeInputValue}
    />
  </Form.Item >
}

export default FilterInput;
import { ApiListResult } from "@alcome-rep/alcome-types";
import { ISupportOrders } from "@alcome-rep/alcome-types/dist/interfaces";
import { RcFile } from "antd/es/upload";
import { defaultPagination } from "../../common/app-constants";
import ApiService from "../../common/services/apiService";
import { buildFindQuery } from "../../common/tools/global-tools";
// import { UploadDocumentPayload } from "../../Inventory/state/fileDoc.types";

const SUPPORT_ORDER_PATH_API = '/supportOrder';

class SupportOrderApi extends ApiService<ISupportOrders> {
    constructor() {
        super(SUPPORT_ORDER_PATH_API)
    }

    findAll(query = {}, page = defaultPagination.page, limit = defaultPagination.pageSize) {
        let queryString = buildFindQuery({ ...query, page, limit });
        return this.custom<ApiListResult<ISupportOrders>>('get', `?${queryString}`)
            .then(res => ({
                ...res,
                rows: res.rows.map(r => r)
            }))
    }

    getQuotas(structureId: string) {
        return this.custom<any>('get', `${structureId}/quotas`)
    }
    getProspectQuotas(prospectId: string) {
        return this.custom<any>('get', `${prospectId}/quotas/prospect`)
    }

    createSupportOrder(data: ISupportOrders) {
        return this.create(data)
    }

    getSupportOrder(supportOrderId: string) {
        return this.get(supportOrderId)
    }

    sendDeclaration(supportOrderId: string) {
        return this.custom<ISupportOrders>('put', `${supportOrderId}/sendDeclaration`)
    }

    updateSupportOrder(supportOrderId: string, data: Partial<ISupportOrders>) {
        return this.update(supportOrderId, data)
    }

    deleteFileDocument(supportOrderId: string, propertyName: string, timestamp?: number) {
        return this.custom<ISupportOrders>('delete', `fileDoc/${supportOrderId}/${propertyName}?timestamp=${timestamp}`)
    }

    deleteSupportOrder(supportOrderId: string) {
        return this.delete(supportOrderId)
    }

    getFileDocument(propertyName: keyof ISupportOrders, supportOrderId: string) {
        return this.getFileDoc(propertyName, supportOrderId)
    }

    supportOrderUploadDocument(supportOrderId: string, propertyName: string, file: RcFile) {
        const formData = new FormData();
        formData.append('file', file);
        return this.custom<ISupportOrders>('post', `${supportOrderId}/fileDoc/${propertyName}`, undefined, formData)
    }

}

const supportOrderApi = new SupportOrderApi();
export default supportOrderApi;
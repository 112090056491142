import { IAnnualReview, ISupportOrders } from "@alcome-rep/alcome-types/dist/interfaces";
import { StructureQuotasType } from "@alcome-rep/alcome-types/dist/interfaces/supportOrder";
import { Loader } from "@googlemaps/js-api-loader";
import { Button, Form, InputNumber } from "antd";
import { useForm, useWatch } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import annualReviewPortalApi from "../../../../AnnualReview/services/annual-review-portal.api";
import { selectCurrentStructure } from "../../../../Structure/state/structure-portal.slice";
import LoadingScreen from "../../../../common/components/LoadingScreen";
import PageCanvas from "../../../../common/components/Panels/PageCanvas";
import Section from "../../../../common/components/Panels/Section";
import { useAppSelector } from "../../../../common/hooks";
import useFormRules from "../../../../common/hooks/useFormRules";
import { ROUTES } from "../../../../common/router";
import { getRoute } from "../../../../common/tools/router-tools";
import supportOrderApi from "../../../services/supportOrder.api";
import SupportOrderHotspotSelection from "../SupportOrderHotSpotSelection/SupportOrderHotspotSelection";

const CreateSupportOrder = () => {
  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLEMAP_KEY || '',
    libraries: ["drawing", "places"]
  });
  // loader.load()
  loader.libraries.forEach(l => loader.importLibrary(l))

  const structure = useAppSelector(selectCurrentStructure);
  const [quotas, setQuotas] = useState<StructureQuotasType>();

  useEffect(() => {
    if (!structure)
      return
    supportOrderApi.getQuotas(structure.id!)
      .then(r => setQuotas(r))
  }, [structure])


  const [isInvalid, setIsInvalid] = useState<boolean>(true)


  const navigate = useNavigate();
  const [form] = useForm();
  const ashtraysDemands = useWatch('hotspots', form) as ISupportOrders['hotspots']

  const { required } = useFormRules()

  const submit = async (data: any) => {
    const qtyCendriers = data.hotspots.reduce(
      (acc: number, cur: any) => acc + cur.qty, 0
    );

    if (data.extinguisherCount === 0 && qtyCendriers === 0) {
      alert("Vous renseigner des quantités pour des cendriers de rue et / ou dispositifs associés aux corbeilles");
      return;
    }

    let annualReview: IAnnualReview;
    const annualReviews = (await annualReviewPortalApi.findAll());
    if (annualReviews.count === 0 && structure) {
      annualReview = (await annualReviewPortalApi.createAnnualReview(structure.id as string, new Date().getFullYear()))
    } else {
      annualReview = annualReviews.rows.sort((a1: IAnnualReview, a2: IAnnualReview) => new Date(a1.startDate).getFullYear() < new Date(a2.startDate).getFullYear() ? 1 : -1)[0]
    }
    const newSupport = (await supportOrderApi.create({ ...data, annualReviewId: annualReview.id }));
    navigate(getRoute(ROUTES.PORTAL_SUPPORT_ORDER_ROUTES.supportOrderDetails, { supportOrderId: newSupport.id }))

  }

  useEffect(() => {
    if (!quotas)
      return
    const totalAshtrays = (ashtraysDemands ?? []).reduce((total, hs) => total + hs.qty, 0)
    setIsInvalid(totalAshtrays > 0 && totalAshtrays > (quotas.available.ashtrays ?? 0))
  }, [ashtraysDemands, quotas])



  const downloadGuide = () => {
    const element = document.createElement("a");
    element.href = "/guide-technique-dispositif-de-rue.pdf";
    element.download = "guide-technique-dispositif-de-rue.pdf";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  if (!quotas)
    return <LoadingScreen />

  return (
    <PageCanvas
      title="Gestion des demandes de soutien à l'acquisition de dispositifs de rue"
      breadCrumbs={[
        ROUTES.PORTAL_USER_ROUTES.home,
        ROUTES.PORTAL_SUPPORT_ORDER_ROUTES.supportOrderList,
        ROUTES.PORTAL_SUPPORT_ORDER_ROUTES.supportOrderCreate,
      ]}
    >
      <Section title="GUIDE DE PRÉCONISATIONS TECHNIQUES SUR LES DISPOSITIFS DE RUE">
        <p>
          Télécharger et consulter le guide de préconisations techniques sur les dispositifs de rue pour vous aider à faire votre demande.
        </p>
        <p>
          <Button htmlType="button" className="btn rounded-lg" onClick={downloadGuide}>Télécharger le guide</Button>
        </p>
        <p>ALCOME propose soit la mise à disposition sans frais soit un soutien financier à
          l'acquisition de dispositifs de rue. Sont qualifiés de « dispositifs de rue » les
          équipements suivants :</p>

        <p>
          <b>1. Les dispositifs de collecte associés aux corbeilles de rue :</b> dispositifs conçus pour éteindre et/ou recueillir les mégots de cigarettes des fumeurs et installés directement sur les corbeilles de rue. Ils sont classés en deux catégories distinctes :
        </p>
        <ul style={{ paddingLeft: "35px" }}>
          <li>a. Les éteignoirs sur corbeilles ou écrases-mégots, intégrés à la corbeille ou ajoutés séparément</li>
          <li>b. Les cendriers sur corbeille, intégrés à la corbeille ou ajoutés séparément</li>
        </ul>

        <p><b>2. Les cendriers de rue :</b> des dispositifs installés dans les espaces publics, spécifiquement conçus pour recueillir les mégots de cigarettes des fumeurs. Ils sont classés en quatre catégories distinctes :
        </p>
        <ul style={{ paddingLeft: "35px" }}>
          <li>a. Cendrier mural</li>
          <li>b. Cendrier sur mobilier urbain, à l'exclusion des cendriers associés aux corbeilles de rue</li>
          <li>c. Cendrier de sondage</li>
          <li>d. Cendrier sur pied de capacité inférieure à 10 litres</li>
          <li>e. Cendrier sur pied de capacité supérieure à 10 litres</li>
        </ul>
      </Section>

      {/* <SupportCounter /> */}

      <Form<ISupportOrders>
        form={form}
        onFinish={submit}
        initialValues={{ 'extinguisherCount': 0, 'hotspots': [] }}
      >
        <Form.Item name="hotspots" hidden />
        <Section title="Cendriers de rue">

          <p>Cliquez sur le lien "+ Selection des hotspots". Une fenêtre vous permettra de sélection un ou plusieurs hotspots. Pour sélectionner les hotspots cliquez simplement dessus. Une fois votre sélection réalisée cliquez sur le bouton "Valider ma sélection" et renseignez le nombre de cendrier de rue souhaité pour chaque hotspot.</p>
          <SupportOrderHotspotSelection collectPointsAllowed={quotas.available.ashtrays} />
          {isInvalid && <span className="text-alc-danger italic text-sm">Votre demande ne respecte pas les quotas mentionnés ci-dessus. Merci de vérifier votre saisie.</span>}
        </Section>

        <Section title="Dispositifs associés aux corbeilles">
          <p>Renseigner le nombre de dispositifs associés aux corbeilles pour lesquels vous souhaitez demander un soutien d'Alcome.</p>
          <Form.Item name="extinguisherCount" rules={[
            required,
            {
              message: 'Quota autorisé: ' + quotas.available.extinguishers,
              validator: (_, value) => {
                if (value <= quotas.available.extinguishers!) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('Some message here');
                }
              }
            }
          ]}>
            <InputNumber min={0} ></InputNumber>
          </Form.Item>
        </Section>

        <Button disabled={true || isInvalid} htmlType="submit" className="btn mt-8 rounded-lg">Valider la demande</Button>
      </Form>


    </PageCanvas>
  )
}

export default CreateSupportOrder
import { Hotspot, IAnnualReview, ICollectPoint, IHotspot, IInventory } from "@alcome-rep/alcome-types/dist/interfaces";
import { GeoJsonFeature } from "../../Map/mapTypes";

export const isHotspot = (mapElement?: ICollectPoint | IHotspot): mapElement is IHotspot => mapElement !== undefined && (mapElement as any).geoPoints !== undefined;
export const isCollectPoint = (mapElement?: ICollectPoint | IHotspot): mapElement is ICollectPoint => mapElement !== undefined && (mapElement as any).geoPoint !== undefined;

export const isInventory = (entity: IInventory | IAnnualReview): entity is IInventory => (entity as IInventory)['inventoryType'] !== undefined
export const isAnnualReview = (entity: IInventory | IAnnualReview): entity is IAnnualReview => (entity as IAnnualReview)['annualReviewType'] !== undefined

export const geometryFromData = (mapPoint: ICollectPoint | IHotspot): GeoJsonFeature['geometry'] => {
  if (isHotspot(mapPoint))
    return {
      type: "Polygon",
      coordinates: [
        [...(mapPoint as Hotspot).geoPoints.map(point => [+point.lng, +point.lat]),
        [+(mapPoint as Hotspot).geoPoints[0].lng, +(mapPoint as Hotspot).geoPoints[0].lat]]
      ]

    }
  return {
    type: "Point",
    coordinates: [+(mapPoint as ICollectPoint).geoPoint.lng, +(mapPoint as ICollectPoint).geoPoint.lat]
  }

}
import { DownloadOutlined, EditOutlined, EllipsisOutlined, FileTextFilled, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Card, Drawer, Dropdown, MenuProps, Tag } from "antd";
import Meta from 'antd/lib/card/Meta';
import confirm from 'antd/lib/modal/confirm';
import { TablePaginationConfig } from "antd/lib/table";
import { FilterValue } from "antd/lib/table/interface";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import adminLibraryDocApi from "../../../services/admin-libraryDoc.api";
import { ILibraryDoc, LibraryDocTypesEnum, StructureTypesEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import libraryDocApi from "../../../services/libraryDoc.api";
import { fallBackImage } from "../../../../common/tools/global-tools";
import AdminLibraryDocForm from "../AdminLibraryDocForm/AdminLibraryDocForm";
import PageCanvas from "../../../../common/components/Panels/PageCanvas";

const AdminLibraryDocList = () => {

  const [data, setData] = useState<ILibraryDoc[]>([]);
  const [, setCount] = useState<number>(1);
  const [, setCurrentPage] = useState<number>(1);
  const [, setPageSize] = useState<number>(30);
  const [, setCurrentQuery] = useState<any>({})
  const [, setloading] = useState<boolean>(true);

  const { t } = useTranslation()

  const controller = new AbortController()

  const getData = useMemo(() => async (
    pagination: TablePaginationConfig = { pageSize: 30, current: 1 },
    filters?: Record<string, FilterValue | null>
  ) => {
    const data = await adminLibraryDocApi.adminLibraryDocList(
      { query: filters }
    )
    setData(data.rows)
    setCount(data.count)
    setCurrentPage(pagination.current as number)
    setPageSize(pagination.pageSize as number)
    setCurrentQuery(filters)
    setloading(false)
  }, [])

  useEffect(() => {
    getData();
  }, [getData]);

  const [openNew, setOpenNew] = useState<boolean>(false);
  const [selectedDoc, setSelectedDoc] = useState<ILibraryDoc | undefined>(undefined);

  const deleteDoc = async (docId: string) => {
    confirm({
      title: 'Voulez-vous supprimer ce document ?',
      icon: <QuestionCircleOutlined />,
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okButtonProps: { className: "btn" },
      async onOk() {
        await adminLibraryDocApi.deleteLibraryDoc(docId)
        getData()
      },
      onCancel() {
      },
    });
  }

  const colors = (type: StructureTypesEnum) => {
    switch (type) {
      case StructureTypesEnum.commune:
        return 'green'
      case StructureTypesEnum.groupement_communes:
        return 'blue'
      default:
        return 'purple'
    }
  }

  const downloadDoc = async (libDoc: ILibraryDoc) => {
    await libraryDocApi.downloadLibraryDoc(libDoc.id as string, `${libDoc.name}.${libDoc.ext}`)
  }

  const confirmDownload = (libDoc: ILibraryDoc) => {
    confirm({
      title: `Voulez vous télécharger le document ${libDoc.timestamp}.${libDoc.ext} ?`,
      icon: <QuestionCircleOutlined />,
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okText: 'Télécharger',
      okButtonProps: { className: "btn" },
      async onOk() {
        await downloadDoc(libDoc)
      },
      onCancel() {
        controller.abort()
      },
    });
  }

  const getItems = (libDoc: ILibraryDoc): MenuProps['items'] => ([
    {
      key: 'edit',
      label:
        <Button type="text" onClick={_ => { setSelectedDoc(libDoc); setOpenNew(true); }}>Modifier</Button>
    },
    {
      key: 'delete',
      label:
        <Button type="text" onClick={_ => deleteDoc(libDoc.id as string)}>Supprimer</Button>
    }
  ])

  const typeCard = (docTypeFilter: LibraryDocTypesEnum) => {
    return data && data.filter(d => d.docType === docTypeFilter).map((libDoc, i) => {
      return (
        <div key={i}>
          <Card
            className="shadow kit-card"
            hoverable
            cover={libDoc.thumbnail ?
              (<img className="h-48 object-cover" crossOrigin='anonymous' src={`${process.env.REACT_APP_API_URL}/libraryDoc/download/thumbnail/${libDoc.id}?rand=${Math.random()}`} alt={`${libDoc.description}`} />)
              : (<img alt='default thumbnail' className="h-48 object-cover" crossOrigin='anonymous' src={fallBackImage} />)
            }
            actions={[
              <DownloadOutlined key="setting" onClick={_ => confirmDownload(libDoc)} />,
              <EditOutlined key="edit" onClick={_ => { setSelectedDoc(libDoc); setOpenNew(true); }} />,
              <Dropdown key="ellipsis" menu={{ items: getItems(libDoc) }}>
                <Button className="btn-link text-alc-blue font-semibold text-lg px-0" onClick={e => e.stopPropagation()}>
                  <EllipsisOutlined />
                </Button>
              </Dropdown>

            ]}
            extra={
              <div className="flex justify-between w-full">
                <div className='font-semibold text-alc-blue'>{t(`TYPES.LIBRARYDOC.LibraryDocTypesEnum.${libDoc.docType}`)}</div>
                <Tag color={libDoc.enabled === true ? 'green' : 'red'}>{libDoc.enabled === true ? 'Publié' : 'Non publié'}</Tag>
              </div>
            }
          >
            <Meta title={libDoc.name} description={
              <div className='flex space-x-1'>
                {libDoc.structureType.map((v: any, i: number) => <Tag key={i} color={colors(v)}>{t(`TYPES.STRUCTURE.StructureTypesEnum.${v}`)}</Tag>)}
              </div>
            } />
          </Card>
        </div>
      )
    }
    )
  }

  return (
    <>
      <PageCanvas
        title="Docuthèque"
        actions={
          <Button type='primary' icon={<FileTextFilled />} onClick={_ => setOpenNew(true)}>Ajouter</Button>
        }
      >
        {Object.values(LibraryDocTypesEnum).map((o: LibraryDocTypesEnum) => {
          let title: string = "";
          let infoCount: number = 0;
          let kitCount: number = 0;
          let methodCount: number = 0;
          if (o === LibraryDocTypesEnum.info) {
            title = "Documents d'information"
            infoCount = data.filter(d => d.docType === o).map(d => d).length
          } else if (o === LibraryDocTypesEnum.kit) {
            title = "Kits de sensibilisation"
            kitCount = data.filter(d => d.docType === o).map(d => d).length
          } else if (o === LibraryDocTypesEnum.methodological_sheet) {
            title = "Fiches méthodologique"
            methodCount = data.filter(d => d.docType === o).map(d => d).length
          }
          return (
            <div>
              <div className="mb-6">
                {infoCount > 0 && (
                  <div className="text-alc-blue font-semibold text-lg mb-4">{title}</div>
                )}
                {kitCount > 0 && (
                  <div className="text-alc-blue font-semibold text-lg mb-4">{title}</div>
                )}
                {methodCount > 0 && (
                  <div className="text-alc-blue font-semibold text-lg mb-4">{title}</div>
                )}
                <div className="flex space-x-6">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4">
                    {typeCard(o)}
                  </div>
                </div>
              </div>
            </div >
          )
        })}

      </PageCanvas >
      <Drawer
        title='Document'
        width='50%'
        onClose={_ => {
          setOpenNew(false);
          setSelectedDoc(undefined);
          getData();
        }}
        open={openNew}
        style={{ paddingBottom: 80 }}
      >
        {openNew && <AdminLibraryDocForm libDoc={selectedDoc} onSubmit={() => { setOpenNew(false); setSelectedDoc(undefined); getData(); }}></AdminLibraryDocForm>}
      </Drawer>
    </>

  );
};

export default AdminLibraryDocList;
import { CollectPoint, ICollectPoint, IHotspot, ISupportOrders, SupportOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber, Modal, Table } from "antd";
import { useState } from "react";
import { selectUser } from "../../../../Auth/state/auth.slice";
import { useAppSelector } from "../../../../common/hooks";
import { isHotspot } from "../../../../common/tools/type-tools";
import MapComponent from "../../../../Map/components/Gmap/mapComponent";
import mapApi from "../../../../Map/services/map.api";
import { selectCurrentStructure } from "../../../../Structure/state/structure-portal.slice";

type PropsType = { supportOrder?: ISupportOrders, collectPointsAllowed?: number }

const SupportOrderHotspotSelection = ({ supportOrder, collectPointsAllowed }: PropsType) => {
  const [hotspotsList, setHotspotsList] = useState<Array<IHotspot | ICollectPoint>>([]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const structure = useAppSelector(selectCurrentStructure);
  const supperOrderAndPending = supportOrder && supportOrder?.status < SupportOrderStatusEnum.support_order_completed;

  const user = useAppSelector(selectUser);
  const isAdmin = user && user.userType === UserTypeEnum.admin;

  const openModal = () => {
    setHotspotsList((form.getFieldValue('hotspots') as ISupportOrders['hotspots'] || []).map(h => h.hotspot!))
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
    changeHotspotSelection(hotspotsList)
    setHotspotsList([])
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setHotspotsList([])
  }

  const form = Form.useFormInstance()
  const formHotspots = Form.useWatch('hotspots', { form, preserve: true })

  const changeHotspotSelection = (selection: Array<ICollectPoint | IHotspot>) => {
    const currentHotspots: ISupportOrders['hotspots'] = form.getFieldValue('hotspots') || []
    const newList = selection
      .filter((s): s is IHotspot => isHotspot(s))
      .map((s) => ({
        hotspotId: s.id!,
        hotspot: s,
        qty: currentHotspots.find(hs => hs.hotspotId === s.id)?.qty ?? 1,
        collectPointsId: []
      }))

    form.setFieldValue('hotspots', newList)
    collectPointsInsideHotspot()
  }

  const collectPointsInsideHotspot = async () => {
    if (structure?.id) {
      const r = await mapApi.getCollectPoints(structure.id)
      const hotspots = form.getFieldValue('hotspots') as ISupportOrders['hotspots'] || [];
      hotspots.forEach(hotspot => {
        const CPinHotspot = r.rows.filter(collectPoint => {
          if (hotspot.hotspot) {
            const polygon = new google.maps.Polygon()
            polygon.setPath(hotspot.hotspot.geoPoints)
            return google.maps.geometry.poly.containsLocation(collectPoint.geoPoint, polygon);
          }
          return false
        })
        hotspot.existingCollectPointsId = CPinHotspot.map(cp => cp.id!)

      })
      form.setFieldValue('hotspots', hotspots)
    }
  }

  const columns = [
    {
      title: 'Hotspot',
      dataIndex: ['hotspot', 'name'],
    },
    {
      title: 'Surface (m2)',
      dataIndex: ['hotspot', 'surface'],
    },
    ...isAdmin ? [{
      title: 'm2 par dispositif',
      key: 'dispositifsm2',
      render: (value: ISupportOrders['hotspots'][0]) => (
        <span>{((value.hotspot?.surface || 0) / ((value.existingCollectPointsId?.length || 0) + (value.qty || 0))).toFixed(0)}</span>
      )
    }] : [],
    {
      title: 'Nb de dispositifs déjà présents',
      dataIndex: 'existingCollectPointsId',
      render: (value: CollectPoint[]) => <span>{value?.length ?? 0}</span>
    },
    {
      title: 'Nb de dispositifs souhaités',
      dataIndex: 'qty',
      render: (_value: number, _record: any, index: number) => {
        return <InputNumber
          required
          value={form.getFieldValue(['hotspots', index, 'qty'])}
          onChange={e => form.setFieldValue(['hotspots', index, 'qty'], e)}
          readOnly={supportOrder && supportOrder.status >= SupportOrderStatusEnum.support_order_completed}
        />
      }
    },
  ];

  return <>
    {(supperOrderAndPending || !supportOrder) && (
      <Button type="link" className="px-0" htmlType="button" onClick={openModal} icon={<PlusOutlined />}> Sélection des hotspots</Button>
    )}
    <Modal
      className="h-dvh !w-10/12 !lg:w-9/12"
      title="Sélectionnez les hotspots"
      okText="Valider ma sélection"
      cancelText="Annuler"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose={true}
    >
      <p>Cliquez sur les hotspots pour lesquels vous souhaitez demander un soutien pour l'installation d'un dispositif de rue. Une fois les hotspots sélectionnés cliquez sur "Valider ma sélection".</p>
      <div className="container">
        <MapComponent
          structure={structure!}
          editMode="selectHotspots"
          onSelectionChanged={setHotspotsList}
          initialSelection={hotspotsList}
        />
      </div>
    </Modal>

    {
      hotspotsList && (
        <div className="ant-table-container">
          <Table
            bordered
            rowKey={"hotspotId"}
            dataSource={formHotspots || supportOrder?.hotspots}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
          />
        </div>
      )
    }
  </>
}

export default SupportOrderHotspotSelection
import AdminProfilDataForm from './AdminProfilDataForm';
import AdminProfilPasswordForm from './AdminProfilPasswordForm';


const AdminProfilPage = () => {

    return (
        <>
            <AdminProfilDataForm />
            <AdminProfilPasswordForm />
        </>
    );
};

export default AdminProfilPage;
import { applyMiddleware, createStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import createRootReducer from './rootReducer';
import rootSaga from './rootSaga';

import { AUTH_SERVICE, REGISTER_SERVICE } from '../../Auth/state/types';
import { isDev } from '../../config';
import createTransform from 'redux-persist/es/createTransform';

const SetTransform = createTransform(

  // transform state on its way to being serialized and persisted.
  (inboundState: RootState, key) => {
    // convert mySet to an Array.
    return { ...inboundState };
  },

  // transform state being rehydrated
  (outboundState: RootState, key) => {
    // convert mySet back to a Set.
    return {
      auths: {
        portal: null,
        admin: null,
        crm: null,
        member: null
      },
      ...outboundState,
      activeUserType: null,
      isRefreshing: false,
      hasRefreshUser: false
    };
  },

  // define which reducers this transform gets called for.
  { whitelist: [AUTH_SERVICE] }
) as any;

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    version: 1,
    transforms: [SetTransform],
    whitelist: [
      AUTH_SERVICE,
      REGISTER_SERVICE,
    ],
    debug: isDev,
  },
  createRootReducer()
);


const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

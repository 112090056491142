import { Models } from "@alcome-rep/alcome-types";
import { IAmendment } from "@alcome-rep/alcome-types/dist/interfaces";
import { defaultPagination } from "../../common/app-constants";
import ApiService, { getTrackProgressAreas } from "../../common/services/apiService";
import apiClient from "../../common/services/apiClient";


const AMENDMENT_ADMIN_PATH_API = 'admin/amendment';

export const progressKeys = {
  upload: getTrackProgressAreas(AMENDMENT_ADMIN_PATH_API).fileUpload('POST'),
}

class AmendmentAdminApi extends ApiService<IAmendment> {
  constructor() {
    super(AMENDMENT_ADMIN_PATH_API)
  }

  findAmendments(query: any, page: number = defaultPagination.page, pageSize: number = defaultPagination.pageSize) {
    return this.find({ query, page, pageSize })
      .then(r => ({
        count: r.count,
        rows: r.rows.map(row => new Models.Amendment(row))
      })
      )
  }

  getAmendmentById = (id: string) => this.get(id)

  createAmendment = (data: IAmendment) => this.create(data)
    .then(r => new Models.Amendment(r))

  updateAmendment = (id: string, data: Partial<IAmendment>) => this.update(id, data)
    .then(r => new Models.Amendment(r))

  uploadAmendmentDoc = (id: any, file: File): Promise<Models.Amendment> => {
    const formData = new FormData();
    formData.append('file', file);
    return this.custom('post', `${id}/fileDoc`, undefined, formData)
  }

  getAmendmentDoc = (amendmentId: string) => {
    return this.custom('get', `${amendmentId}/fileDoc`)
  }

  getAmendmentDocByStructureId = (structureId:string) => {
    return apiClient({
      url: `${AMENDMENT_ADMIN_PATH_API}/fileDoc/byStructure/${structureId}`,
      method: 'get',
      responseType: 'blob'
    }).then(r => r.data)
  }

  downloadAmendmentDoc = (amendmentId: string) => {
    return this.custom('get', `${amendmentId}/fileDoc`)
      .then((response) => {
        console.log(response)
        const url = window.URL.createObjectURL(new Blob([response as any]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', String(`${amendmentId}.pdf`)); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
  }

  deleteAmendment(amendmentId: string): Promise<Models.Amendment>{
    return this.custom('delete', `${amendmentId}`)
  }
  deleteAmendmentDoc = (amendmentId: string): Promise<Models.Amendment> => {
    return this.custom('delete', `${amendmentId}/fileDoc`)
  }


}

const amendmentAdminApi = new AmendmentAdminApi();
export default amendmentAdminApi;
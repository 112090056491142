import { ISupplierCompany } from "@alcome-rep/alcome-types/dist/interfaces/supplier"
import { useEffect, useState } from "react"
import LoadingScreen from "../../../../common/components/LoadingScreen"
import PageCanvas from "../../../../common/components/Panels/PageCanvas"
import supplierCompanyApi from "../../../services/supplierCompany.api"
import ShippingWeightSteps from "../../common/ShippingWeightSteps"

const CompanyShippingWeightSettings = () => {

  const [supplierCompany, setSupplierCompany] = useState<ISupplierCompany>()

  useEffect(() => {
    supplierCompanyApi.getMyCompany()
      .then(res => setSupplierCompany(res))
  }, [])

  if (!supplierCompany)
    return <LoadingScreen />

  return (
    <>
      <PageCanvas title="Grille tarifaire des frais de livraison cendrier selon le poids">
        <ShippingWeightSteps property="shippingWeightStepsAshtray" readOnly={true} initialData={supplierCompany.shippingWeightStepsAshtray} />
      </PageCanvas>

      <PageCanvas title="Grille tarifaire des frais de livraison éteignoir selon le poids">
        <ShippingWeightSteps property="shippingWeightStepsExtinguisher" readOnly={true} initialData={supplierCompany.shippingWeightStepsExtinguisher} />
      </PageCanvas>
    </>
  )
}

export default CompanyShippingWeightSettings
import { ActionStatusEnum, IAction } from "@alcome-rep/alcome-types/dist/interfaces/crm"
import { Tag } from "antd"
import { useTranslation } from "react-i18next"

type PropsType = {
  action: IAction,
}

const ActionStatus = ({ action }: PropsType) => {
  const { t } = useTranslation();

  return <Tag className="space-x-2" color={t(`UI.TAG.ActionStatusEnum.${action?.status}`)}>
    {t(`TYPES.CRM.ACTIONS.ActionStatusEnum.${ActionStatusEnum[action?.status]}`)}
  </Tag>
}

export default ActionStatus
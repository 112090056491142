import { Models } from '@alcome-rep/alcome-types';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from "antd";
import { useState } from 'react';
import { Link } from "react-router-dom";
import { logoutAction, selectUser } from "../../../Auth/state/auth.slice";
import AlcomeLogo from '../../../assets/alcome_logo.png';
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ROUTES } from '../../router';
import { getRoute } from '../../tools/router-tools';

const AppNavBar = () => {
  const [, setOpen] = useState(false);

  const user = useAppSelector(selectUser);

  const dispatch = useAppDispatch();
  const logout = () => dispatch(logoutAction());

  const items: MenuProps['items'] = [
    {
      key: 'userProfil',
      label: <Button className='btn-link text-black px-0 hover:text-black' onClick={_ => setOpen(true)}>Données personelles</Button>
    },
    Models.User.IsStructureAdmin(user) ? {
      key: 'administratifProfil',
      label: <Link className='btn-link' to={ROUTES.PORTAL_USER_ROUTES.administratifProfil.path}>Votre profil administratif</Link>
    } : null,
    {
      type: 'divider',
    },
    { key: 'logout', label: <Link className='btn-link' to='' onClick={logout}>Se déconnecter</Link> }
  ]

  return <>
    <header className='bg-alc-blue px-4 h-full shadow-lg flex justify-center'>
      <div className="w-full flex items-center justify-between">
        <div className='flex items-center flex-shrink-0 text-white mr-6'>
          <Link to={getRoute(ROUTES.PORTAL_USER_ROUTES.home)}><img src={AlcomeLogo} alt="Alcome logo" className='max-h-8' /></Link>
        </div>

        <div className='flex flex-col'>
          {user && user && (
            <>
              <Dropdown menu={{ items }}>
                <Button className="btn btn-link text-white hover:text-alc-yellow" onClick={e => e.preventDefault()}>
                  {user.email} <DownOutlined />
                </Button>
              </Dropdown>
            </>
          )}
        </div>
      </div>
    </header>
  </>
}

export default AppNavBar;
import { IAccountingExport } from "@alcome-rep/alcome-types/dist/interfaces";
import ApiService from "../../common/services/apiService";
import { trackProgress } from "../../common/services/trackProgress";
import apiClient from "../../common/services/apiClient";
import { ApiListResult } from "@alcome-rep/alcome-types";
import { buildFindQuery } from "../../common/tools/global-tools";

const ACCOUNTING_EXPORT_ADMIN_PATH_API = 'admin/accountingExport';

class AccountingExportAdminApi extends ApiService<IAccountingExport> {
  constructor() {
    super(ACCOUNTING_EXPORT_ADMIN_PATH_API)
  }

  /**
   * @description find all accountingExports
   * @param query
   * @returns accountingExports list
   */
  accountingList(query?: any) {
    query = buildFindQuery(query)
    return this.custom<ApiListResult<IAccountingExport>>('get', `?${query ? query + '&' : ''}page=${1}&limit=${-1}`)
  }

  /**
* @description create an accountingExport
* @param data 
* @returns Created accountingExport
*/
  createAccountingExport(data: any) {
    return this.custom<IAccountingExport>('post', '', undefined, data)
      .then(r => r)
  }

  /**
   * @description Download AccountingExportFile
   * @param filePath 
   * @returns AccountingExportFile
   */
  downloadAccountingExportFile(filePath: string) {
    return trackProgress(
      apiClient({
        url: `${this.path}file?filePath=${filePath}`,
        method: 'get',
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data as any]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', String(filePath)); //or any other extension
        document.body.appendChild(link);
        link.click();
      }),
      this.trackProgressAreas.get
    )
  }

}

const accountingExportAdminApi = new AccountingExportAdminApi();
export default accountingExportAdminApi;
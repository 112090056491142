import { Models } from "@alcome-rep/alcome-types";
import { BaseCommune, Structure } from "@alcome-rep/alcome-types/dist/models";
import { Draft, PayloadAction, createSelector } from "@reduxjs/toolkit";
import factoryCreateSlice from "../../common/state/slice.factory";
import { BaseServiceState } from "../../common/state/types";
import { RootState } from "../../common/store";

export const STRUCTURE_ADMIN_SERVICE = "structureAdminService";
// eslint-disable-next-line
export type STRUCTURE_ADMIN_SERVICE = typeof STRUCTURE_ADMIN_SERVICE; // Typescript line

export type AdminStructureServiceState = {
  current: Structure | null
};


const slices = factoryCreateSlice<"AdminStructure", Models.BaseCommune>('AdminStructure', {
  adminDeleteStructureAction(state: Draft<BaseServiceState<Models.BaseCommune, 'current'>>, { payload }: PayloadAction<Draft<{ id: string }>>) {
  },

})


export const {
  getAction: adminGetStructureAction,
  updateAction: adminUpdateStructureAction,
  setCurrentAction: adminSetCurrentStructureAction,
  adminDeleteStructureAction,
} = slices.actions;

export default slices.reducer;

export const selectStructureService = (state: RootState) => state.structureAdminService
export const selectCurrentAdminStructure = createSelector(selectStructureService, (structureSvc) => structureSvc.current)
export const selectCurrentAdminCommune = createSelector(selectCurrentAdminStructure, (structure) => structure && Structure.IsBaseCommune(structure) ? new BaseCommune(structure) : null)
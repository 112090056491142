import { RouteObject } from "react-router-dom";
import AdminLibraryDocList from "../components/admin/AdminLibraryDocList/AdminLibraryDocList";


const routePrefix = 'admin'
const routeDomain = 'libraryDoc'
const routeParam = 'libraryDocId'

export const adminLibraryDocRouteNames = {
  libraryDocList: { path: `${routePrefix}/${routeDomain}/list`, title: `ROUTES.${routeDomain}.list` },
  libraryDocDetails: { path: `${routePrefix}/${routeDomain}/:${routeParam}`, title: "ROUTES.infos" }
}

export const adminLibraryDocRoutes: RouteObject[] = [
  {
    path: adminLibraryDocRouteNames.libraryDocList.path.replace(`${routePrefix}/`, ''),
    element: <AdminLibraryDocList />,
  }
]

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from '../../common/store';
import authApi from "../services/auth.api";
import { AppAuth } from "../types";
import { loginAction, loginErrorAction, loginSuccessAction, refreshTokenAction } from "./auth.slice";

function* login({ payload: { email, password } }: ReturnType<typeof loginAction>) {
  try {
    const resp: AppAuth = yield call(authApi.login, email, password);

    yield put(loginSuccessAction(resp));

  } catch (err) {
    // yield put(loginErrorAction());
  }
}


function* refresh({ payload: userType }: ReturnType<typeof refreshTokenAction>) {
  try {
    const { authService }: RootState = yield select();

    const activeUserType = authService.activeUserType
    if (activeUserType === null)
      return;
    const token = authService.auths[activeUserType]?.token;
    if (token) {
      const resp: AppAuth = yield call(authApi.refreshToken, token);

      yield put(loginSuccessAction(resp));

      // if (Models.User.IsUserPortal(resp.user)) {
      //   yield put(getMyStructureAction());
      //   yield put(getContractAction());
      //   // yield put(getInventoryAction());
      // }
    } else
      yield put(loginErrorAction(userType));
  } catch (err) {
    yield put(loginErrorAction(userType));
  }
}

export function* authSaga() {
  yield takeLatest(loginAction, login);
  yield takeLatest(refreshTokenAction, refresh);
}

import { Form, Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

type PropsType = {
  filterName: string,
  queryParamName: string,
  options?: DefaultOptionType[],
  findFn?: (value: string) => Promise<DefaultOptionType[]>,
  retrieveFn?: (value: string) => Promise<DefaultOptionType>,
  multiple?: boolean,
  className?: string,
  defaultValue?: any | any[]
}

const FilterEntity = ({ filterName, options: initialOptions = [], queryParamName, multiple = false, defaultValue, findFn, retrieveFn, className = `m-0` }: PropsType) => {

  const [options, setOptions] = useState<DefaultOptionType[]>(initialOptions)
  const [selectedItem, setSelectedItem] = useState<string | string[]>()
  let [searchParams, setSearchParams] = useSearchParams();

  // useEffect(() => {
  //   setOptions(initialOptions)
  // }, [initialOptions])

  useEffect(() => {
    if (!multiple) {
      const queryParam = searchParams.get(queryParamName);
      if (queryParam !== null) {
        setSelectedItem(queryParam)
      } else {
        if (findFn)
          setOptions([])
        setSelectedItem(undefined)
      }
    }
    else {
      const queryParams = searchParams.getAll(queryParamName);
      if (queryParams !== null) {
        setSelectedItem(queryParams)
      }
    }
  }, [setSelectedItem, searchParams, multiple, queryParamName, findFn])


  useEffect(() => {
    if (!multiple) {
      const initialValue: string = searchParams.get(queryParamName) || defaultValue
      if (initialValue) {
        if (retrieveFn)
          retrieveFn(initialValue)
            .then(r => setOptions(opt => ([...opt, r])))

        searchParams.set(queryParamName, initialValue);
        setSearchParams(searchParams);
      }
    } else {
      const initialValue: string[] = searchParams.get(queryParamName) || defaultValue
      if (initialValue) {
        initialValue.forEach(val => {
          searchParams.append(queryParamName, val);
        })
        setSearchParams(searchParams);
      }
    }
  },
    // eslint-disable-next-line
    []
  )

  const selectItem = (item: any) => {
    if (multiple) {
      searchParams.append(queryParamName, item);
      setSearchParams(searchParams);
    }
    else {
      searchParams.set(queryParamName, item);
      setSearchParams(searchParams);
    }
  }

  const clear = () => {
    searchParams.delete(queryParamName);
    setSearchParams(searchParams);
  }

  const deselectItem = (item: any) => {
    searchParams.delete(queryParamName);
    if (Array.isArray(selectedItem)) {
      const items = [...selectedItem]
      items.splice(selectedItem.indexOf(item), 1)

      items.forEach(element => searchParams.append(queryParamName, element))
      setSearchParams(searchParams);
    }
  }

  const onSearch = async (value: string) => {
    if (findFn) {
      const items = await findFn(value);
      setOptions(items)
    }
  }

  return (
    <Form.Item
      className={className}
      label={filterName}
    >
      <Select
        allowClear
        showSearch
        placeholder={filterName}
        optionFilterProp="children"
        onSearch={onSearch}
        onClear={clear}
        onSelect={selectItem}
        onDeselect={deselectItem}
        mode={multiple ? 'multiple' : undefined}
        value={selectedItem}
      >
        {options && options.map((v, i) => (
          <Select.Option key={i} value={v.value}>{`${v.label}`}</Select.Option>
        ))}
      </Select>
    </Form.Item >
  );
};

export default FilterEntity;
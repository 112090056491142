import fr_FR from 'antd/lib/locale/fr_FR';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from "react-router-dom";
import LoadingScreen from './common/components/LoadingScreen';
import { store } from './common/store';
import StorePersistGate from './common/store/StorePersistGate';
import './index.css';
import reportWebVitals from './reportWebVitals';
import router from './common/router';
import './common/services/i18next';
import ErrorBoundary from 'antd/es/alert/ErrorBoundary';
import { ConfigProvider } from 'antd';
import antdConfig from './antd-theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ConfigProvider theme={antdConfig} locale={fr_FR}>
        <Provider store={store.default}>
          <StorePersistGate loading={<LoadingScreen />}>
            <RouterProvider router={router} />
          </StorePersistGate>
        </Provider>
      </ConfigProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

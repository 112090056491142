import { ILibraryDoc, IStructure } from "@alcome-rep/alcome-types/dist/interfaces";
import { Table } from "antd";
import { useEffect, useState } from "react";
import { defaultPagination } from "../../../../common/app-constants";
import useTableChange from "../../../../common/hooks/useTableChange";
import logActionApi from "../../../../common/services/log-actions.api";
import { libraryDocListColumns } from "./libraryDocListColumns";

type Props = {
    structure: IStructure;
}

const KitDownloadedList = ({ structure }: Props) => {

    const [data, setData] = useState<ILibraryDoc[]>([]);
    const [count, setCount] = useState<number>(0);
    const [loading, setloading] = useState<boolean>(false);

    const tableChange = useTableChange();

    useEffect(() => {
        if (structure)
            setloading(true)
        logActionApi.findLibraryDocDownloadedByStructure(structure.id as string)
            .then(res => {
                setData(res.rows)
                setCount(res.count)
                setloading(false)
            })
            .catch(() => {
                setData([])
                setCount(0)
                setloading(false)
            })
    }, [setData, setCount, setloading, structure])

    return (
        <Table<ILibraryDoc>
            columns={libraryDocListColumns()}
            dataSource={data}
            loading={loading}
            onChange={tableChange}
            size="small"
            rowKey="_id"
            pagination={
                {
                    hideOnSinglePage: true,
                    current: defaultPagination.page,
                    pageSize: defaultPagination.pageSize,
                    total: count,
                }
            } />
    )
}

export default KitDownloadedList;
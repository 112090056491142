import { ReactNode } from "react"

type PropTypes = {
  title?: string,
  value?: string | ReactNode,
  actions?: ReactNode,
  className?: string
}
const Figure = ({ title, value, actions, className }: PropTypes) => {
  return <div className={`bg-white p-4 rounded-lg flex flex-col justify-between ${className}`}>
    {title && (
      <div className="flex justify-between items-center">
        <div className="text-alc-blue">{title}</div>
        {actions ? actions : <></>}
      </div>
    )}
    {value !== undefined ? (<div className="text-xl font-semibold">{value}</div>) : ' - '}
  </div>
}

export default Figure
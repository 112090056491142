import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { refreshTokenAction } from '../../Auth/state/auth.slice';
import { RootState } from './';
import { persistor } from './configureStore';

const mapStateToProps = (state: RootState) => ({
  activeUserType: state.authService.activeUserType
});

const mapDispatchToProps = {
  // isRegistrating: isRegistratingAction,
  refreshToken: refreshTokenAction,
  // getMe: getMeActions.request,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    children: ReactNode;
    loading: ReactNode;
  };

const StorePersistGate = ({ children, loading, refreshToken, activeUserType, /*getMe*/ }: Props) => (
  <PersistGate
    loading={loading}
    onBeforeLift={() => {
      // avoid blocking state
      // isRegistrating(false);

      // if (activeUserType)
      //   refreshToken(activeUserType);

    }}
    persistor={persistor}
  >
    {children}
  </PersistGate>
);

export default connect(mapStateToProps, mapDispatchToProps)(StorePersistGate);

import { AnnualReviewStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces"
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, EuroCircleOutlined, LikeOutlined } from "@ant-design/icons"
import { Steps } from "antd"
import { StepProps } from "antd/lib"
import { ReactNode, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"

const AnnualReviewListStatusFilter = () => {
  const [currentStep, setCurrentStep] = useState<number>(-1)
  const [subStatuses, setSubStatuses] = useState<StepProps[]>([])
  const [currentSubStep, setCurrentSubStep] = useState<number>(-1)

  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation()

  const status = searchParams.get('status');

  const [statusMapping, setStatusMapping] = useState<any>()

  useMemo(()=> {
    const initStatusMapping: { [K in keyof typeof AnnualReviewStatusEnum]?: { step: number, icon: ReactNode } } = {
      [AnnualReviewStatusEnum.pending]: { step: 0, icon: <ClockCircleOutlined /> },
      [AnnualReviewStatusEnum.refused]: { step: 1, icon: <CloseCircleOutlined /> },
      [AnnualReviewStatusEnum.completed]: { step: 2, icon: <CheckCircleOutlined /> },
      [AnnualReviewStatusEnum.validated]: { step: 3, icon: <LikeOutlined /> },
      [AnnualReviewStatusEnum.statement_pending]: { step: 10, icon: <ClockCircleOutlined /> },
      [AnnualReviewStatusEnum.statement_refused]: { step: 11, icon: <CloseCircleOutlined /> },
      [AnnualReviewStatusEnum.statement_accepted]: { step: 12, icon: <LikeOutlined /> },
      [AnnualReviewStatusEnum.order_sent]: { step: 20, icon: <CheckCircleOutlined /> },
      [AnnualReviewStatusEnum.order_refuse]: { step: 21, icon: <CloseCircleOutlined /> },
      [AnnualReviewStatusEnum.order_validate]: { step: 22, icon: <LikeOutlined /> },
      [AnnualReviewStatusEnum.statement_paid]: { step: 23, icon: <EuroCircleOutlined /> },
    }
    setStatusMapping(initStatusMapping)
  },[])

  useEffect(() => {
    if (status === null) {
      setCurrentStep(-1)
      setCurrentSubStep(-1)
    } else {
      const step = statusMapping[status as any];
      if (step !== undefined) {
        const mainStep = Number(step.step.toString().padStart(2, '0')[0])
        const subStep = Number(step.step.toString().padStart(2, '0')[1])

        setCurrentStep(mainStep)
        setCurrentSubStep(subStep)
        const items = Object.keys(statusMapping)
          .filter((k): k is keyof typeof AnnualReviewStatusEnum => !!k)
          .filter(k => mainStep === Number(statusMapping[k]?.step.toString().padStart(2, '0')[0]))
          .map(k => ({ icon: statusMapping[k]?.icon, title: t('TYPES.ANNUAL_REVIEW.AnnualReviewStatusEnum.' + AnnualReviewStatusEnum[k]) }))
        setSubStatuses(items)
      }
    }
  }, [statusMapping,t,status])

  const onStatusChange = (current: number) => {
    const step = Number(String(current) + String(0))
    const status = Object.keys(statusMapping)
      .filter((k): k is keyof typeof AnnualReviewStatusEnum => !!k)
      .find(k => statusMapping[k]?.step === step)
    if (status) {
      searchParams.set('status', String(status))
      setSearchParams(searchParams)
    }
  }

  const onSubStatusChange = (current: number) => {
    const step = Number(String(currentStep) + String(current))
    const status = Object.keys(statusMapping)
      .filter((k): k is keyof typeof AnnualReviewStatusEnum => !!k)
      .find(k => statusMapping[k]?.step === step)
    if (status) {
      searchParams.set('status', String(status))
      setSearchParams(searchParams)
    }
  }

  return <div className='w-full'>
    <Steps current={currentStep} size="small" onChange={onStatusChange} direction='horizontal' items={[
      { title: "Bilan" },
      { title: "Décompte" },
      { title: "Titre" },
    ]} />
    {currentStep > -1 &&
      <Steps current={currentSubStep} onChange={onSubStatusChange} size="small" direction='horizontal' className='my-2' items={subStatuses} />
    }
  </div>
}

export default AnnualReviewListStatusFilter
import { RouteObject } from "react-router-dom";
import SupplierCompanyListComponent from "../components/admin/SupplierCompanyList/SupplierCompanyListComponent";
import AdminSupplierCompanyDetails from "../components/admin/SupplierCompanyDetails/AdminSupplierCompanyDetails";

const routePrefix = 'admin'
const routeDomain = 'supplierCompany'
const routeParam = 'supplierCompanyId'

export const supplierCompanyRouteNames = {
    supplierCompanyList: { path: `${routePrefix}/${routeDomain}/list`, title: `ROUTES.${routeDomain}.list` },
    supplierCompanyDetails: { path: `${routePrefix}/${routeDomain}/details/:${routeParam}`, title: `ROUTES.${routeDomain}.details` },
}

export const supplierCompanyRoutes: RouteObject[] = [
    {
        path: '',
        children: [
            {
                path: supplierCompanyRouteNames.supplierCompanyList.path.replace(`${routePrefix}/`, ''),
                element: <SupplierCompanyListComponent />
            },
            {
                path: supplierCompanyRouteNames.supplierCompanyDetails.path.replace(`${routePrefix}/`, ''),
                element: <AdminSupplierCompanyDetails />
            },
        ]
    }
]


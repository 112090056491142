import { IProduct } from "@alcome-rep/alcome-types/dist/interfaces/product";
import { PriceStep, ShippingRegionEnum, ShippingStep } from "@alcome-rep/alcome-types/dist/interfaces/supplier";
import { DeleteOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import { Button, InputNumber, Table, TableColumnType } from "antd";
import { useWatch } from "antd/es/form/Form";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { Select } from "antd/lib";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import msg from "../../../../common/services/toast";
import { randomStringId } from "../../../../common/tools/global-tools";

type PropsType = {
  readOnly?: boolean,
  property: string
}
type TableRow = ShippingStep & { [K in keyof PriceStep]?: PriceStep[K] }

const SupplierShippingSteps = ({ readOnly, property }: PropsType) => {

  const { t } = useTranslation()
  // const [shippingSteps, setShippingSteps] = useState<ShippingStep[]>(product.shippingSteps ?? [])
  const form = useFormInstance<IProduct>()
  const shippingSteps = useWatch(property, form) as ShippingStep[];

  const [selectedRegion, setSelectedRegion] = useState<ShippingRegionEnum>()
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>()

  useEffect(() => {
    setExpandedRowKeys((shippingSteps ?? []).map(sh => sh.id))
  }, [shippingSteps, form])

  const getShippingStepsCopy = () => {
    return [...shippingSteps.map(sh => ({ ...sh, steps: sh.steps.map(step => ({ ...step })) }))]
  }

  const addRegion = () => {
    if (!selectedRegion)
      return;
    if (shippingSteps.find(sh => sh.region === selectedRegion)) {
      msg.error("Des tarifs sont déjà présents pour cette région et ce type de dispositif.")
      return;
    }
    const newShippingSteps = getShippingStepsCopy();
    const newStep: ShippingStep = {
      id: randomStringId(),
      region: selectedRegion,
      steps: [{
        id: randomStringId(),
        floor: 1,
        ceil: 10,
        price: 100,
        delay: 7
      }]
    }
    newShippingSteps.push(newStep)
    form.setFieldValue(property, newShippingSteps)
  }

  const addStep = (shippingStep: ShippingStep) => {
    shippingStep.steps.push({ id: randomStringId(), ceil: 2, floor: 1, delay: 7, price: 50 })
    const newShippingSteps = getShippingStepsCopy();
    form.setFieldValue(property, newShippingSteps)
  }

  const deleteStep = (priceStep: TableRow, index: number) => {
    const newShippingSteps = getShippingStepsCopy()
    const shippingStepIndex = newShippingSteps.findIndex(sh => sh.steps.find(step => step.id === priceStep.id));
    const shippingStep = newShippingSteps[shippingStepIndex]
    if (!shippingStep)
      return;
    shippingStep.steps.splice(index, 1)
    if (!shippingStep.steps.length)
      newShippingSteps.splice(shippingStepIndex, 1)
    form.setFieldValue(property, newShippingSteps)
  }

  const updateSteps = (value: number, regionIndex: number, index: number, field: 'ceil' | 'floor') => {
    const newShippingSteps = getShippingStepsCopy();
    const steps = newShippingSteps[regionIndex].steps;
    steps[index][field] = value;

    form.setFieldValue(property, newShippingSteps)
  }

  const updateValue = (regionIndex: number, index: number, field: 'delay' | 'price', value: any) => {
    const newShippingSteps = getShippingStepsCopy();
    newShippingSteps[regionIndex].steps[index][field] = value
    form.setFieldValue(property, newShippingSteps)
  }

  const columns = [
    {
      title: "Palier",
      dataIndex: 'ceil',
      render: (q: any, item: TableRow, index: number) => {
        console.log(item)
        if (item.region) {
          return <><span>{t('TYPES.PRODUCT.ShippingRegionEnum.' + item.region)}</span> <RightOutlined /></>
        } else {
          const regionIndex = getShippingStepsCopy().findIndex(sh => sh.steps.find(step => step.id === item.id));
          if (q === undefined) return <></>
          return <div className="flex space-x-4 items-center" >
            <span>de</span>
            <InputNumber
              disabled={readOnly}
              value={shippingSteps[regionIndex].steps[index].floor}
              onChange={v => v !== null && updateSteps(v, regionIndex, index, 'floor')}
            />
            <span>à</span>
            <InputNumber
              disabled={readOnly}
              min={shippingSteps[regionIndex].steps[index].floor}
              value={shippingSteps[regionIndex].steps[index].ceil}
              onChange={v => v !== null && updateSteps(v, regionIndex, index, 'ceil')}
            />
            <span>produits</span>
          </div >
        }
      }
    }, {
      dataIndex: 'price',
      title: 'Frais de port',
      render: (v, item, index) => {
        const regionIndex = getShippingStepsCopy().findIndex(sh => sh.steps.find(step => step.id === item.id));
        if (regionIndex === -1)
          return null
        return <InputNumber
          disabled={readOnly}
          value={shippingSteps[regionIndex].steps[index].price}
          onChange={v => updateValue(regionIndex, index, "price", v)}
        />
      }
    }, {
      dataIndex: 'delay',
      title: 'Délai',
      render: (v, item, index) => {
        const regionIndex = getShippingStepsCopy().findIndex(sh => sh.steps.find(step => step.id === item.id));
        if (regionIndex === -1)
          return null
        return <InputNumber
          disabled={readOnly}
          value={shippingSteps[regionIndex].steps[index].delay}
          onChange={v => updateValue(regionIndex, index, "delay", v)}
        />
      }
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      width: '50px',
      render: (_v: any, item: TableRow, index: number) => {
        if (readOnly)
          return null
        return item.ceil
          ? <Button type="link" danger icon={<DeleteOutlined />}
            onClick={_ => deleteStep(item, index)}
          />
          : <Button size="small" type="link" onClick={_ => addStep(item)} icon={<PlusOutlined />}>Tarif</Button>
      }
    }] as TableColumnType<ShippingStep>[]

  return <>
    <Table<TableRow>
      rowKey='id'
      dataSource={shippingSteps}
      locale={{
        emptyText: () => "Aucun frais de port"
      }}
      expandable={{ childrenColumnName: "steps", defaultExpandAllRows: true, expandedRowKeys, expandIcon: undefined }}
      columns={columns}
      pagination={false}
      footer={() => {
        return readOnly ? null : <div className="flex space-x-2">
          <Select className="w-32" value={selectedRegion} onChange={setSelectedRegion} options={Object.keys(ShippingRegionEnum).map(r => ({ label: t('TYPES.PRODUCT.ShippingRegionEnum.' + r), value: r }))} />
          <Button disabled={!selectedRegion} type="link" onClick={_ => addRegion()}>Ajouter des tarifs</Button>
        </div>
      }}
    />
  </>
}

export default SupplierShippingSteps;
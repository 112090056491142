import { ISupplierCompany } from "@alcome-rep/alcome-types/dist/interfaces/supplier"
import { useEffect, useState } from "react"
import supplierCompanyApi from "../../../services/supplierCompany.api"
import LoadingScreen from "../../../../common/components/LoadingScreen"
import Section from "../../../../common/components/Panels/Section"

const UserSupplierInfos = () => {

    const [supplierCompany, setSupplierCompany] = useState<ISupplierCompany>()

    useEffect(() => {
        supplierCompanyApi.getMyCompany()
            .then(res =>
                setSupplierCompany(res)
            )
    }, [setSupplierCompany])

    if (!supplierCompany)
        return <LoadingScreen />

    return <>
        <Section title="Information entreprise">
            <div className="font-semibold mb-2 text-xl">{supplierCompany.name.toLocaleUpperCase()}</div>
            <h6 className="mb-2">Adresse</h6>
            <div>{supplierCompany.address.street}</div>
            <div>{supplierCompany.address.street2}</div>
            <div>{supplierCompany.address.zip}, {supplierCompany.address.city}</div>
        </Section>
    </>
}

export default UserSupplierInfos
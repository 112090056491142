import { Form, InputNumber } from "antd";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { debounce } from "../../../common/tools/global-tools";
import { defaultDebounce } from "../../app-constants";

type PropsType = {
  label?: string
  searchParamName: string
}

const FilterInputNumber = ({ label, searchParamName }: PropsType) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const debounceInput = debounce((value: number | null) => {
    if (value !== null)
      searchParams.set(searchParamName, String(value))
    else
      searchParams.delete(searchParamName)
    setSearchParams(searchParams)
  }, defaultDebounce);

  return <Form.Item
    label={label}
    className="m-0">
    <InputNumber

      defaultValue={searchParams.get(searchParamName) ?? undefined}
      onChange={debounceInput}
    />
  </Form.Item>
}

export default FilterInputNumber;
import { IStructure } from '@alcome-rep/alcome-types/dist/interfaces';
import { call, put, takeLatest } from 'redux-saga/effects';
import createEntityDucksFactory from '../../common/state/ducks.factory';
import structureAdminApi from '../services/structure-admin.api';
import { adminDeleteStructureAction, adminGetStructureAction, adminSetCurrentStructureAction, adminUpdateStructureAction } from './structure-admin.slice';

/**
 * SAGAS
 */

// function* getStructure({ payload }: ReturnType<typeof adminGetStructureAction>) {
//   try {
//     const resp: IStructure = yield call(structureAdminApi.getStructure, payload.id);

//     yield put(setCurrentAdminStructureAction(resp));
//   } catch {
//     yield put(unsetCurrentAdminStructureAction());
//   }
// }


// function* updateStructure({ payload }: ReturnType<typeof adminUpdateStructureAction>) {
//   try {
//     const resp: IStructure = yield call(structureAdminApi.updateStructure, payload.id, payload.data);

//     yield put(setCurrentAdminStructureAction(resp));
//   } catch {
//     yield put(unsetCurrentAdminStructureAction());
//   }
// }

function* deleteStructure({ payload }: ReturnType<typeof adminDeleteStructureAction>) {
  try {
    const resp: IStructure = yield call(structureAdminApi.deleteStructure, payload.id);

    yield put(adminSetCurrentStructureAction(resp));
  } catch {
    yield put(adminSetCurrentStructureAction(null));
  }
}

const actions = createEntityDucksFactory<IStructure>(structureAdminApi, {
  get: adminGetStructureAction,
  set: adminSetCurrentStructureAction,
  update: adminUpdateStructureAction,
})

export function* structureAdminSaga() {
  yield takeLatest(adminGetStructureAction, actions.get);
  yield takeLatest(adminUpdateStructureAction, actions.update);
  yield takeLatest(adminDeleteStructureAction, deleteStructure);

}
// export function* structureAdminSaga() {
//   yield takeLatest(adminGetStructureAction, getStructure);
//   yield takeLatest(adminUpdateStructureAction, updateStructure);
//   yield takeLatest(adminDeleteStructureAction, deleteStructure);

// }
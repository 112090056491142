import React from 'react';

const CookiePolicyStep: React.FC = () => {

    return (
        <>
            <div className="p-6">
                <h2>POLITIQUE DE GESTION DES COOKIES</h2>
                <p><strong>Date de création</strong>: 29 juin 2023</p>
                <p><strong>Date de mise à jour</strong>: NA</p>
                <p><em>En jaune est mis en évidence la mise à jour de la version précédente</em></p>
                <h4>Déclaration relative à l’utilisation de cookies</h4>
                <p>Lors de la consultation de notre portail internet (ci-après désigné le « Site »), des cookies sont déposés sur l’ordinateur, la tablette ou tout terminal utilisé par vous (ci-après désigné l’«&nbsp;Utilisateur&nbsp;»).</p>
                <p>Ceci nous (la société ALCOME) permet d’analyser la fréquentation des pages et d’améliorer le Site. Les cookies sont utilisés selon leur nature et aux fins ci-après indiquées.</p>
                <p>L’Utilisateur a la possibilité d’accepter ou de refuser les cookies en utilisant le mécanisme intégré au bandeau ou les outils décrits ci-dessous.</p>
                <h4>Qu’est-ce qu’un cookie ?</h4>
                <p>Un cookie (ci-après le(s) "cookie(s) ") est un fichier texte déposé sur un terminal lors de la visite du Site ou de la consultation d’une publicité. Un cookie a notamment pour but de collecter des informations relatives à la navigation de l’Utilisateur sur le Site et d’adresser à l’Utilisateur des services et contenus personnalisés.</p>
                <h4>Identification des cookies</h4>
                <p>Il existe différents types de cookies :</p>
                <p><strong>COOKIES TIERS ET COOKIES PROPRIETAIRES</strong></p>
                <p>Un cookie est dit « propriétaire » ou « tiers » selon le domaine dont il émane. Les cookies propriétaires sont ceux installés par le Site lorsque l’Utilisateur est en train de le consulter. Les cookies tiers sont des cookies installés par un domaine autre que celui du Site (à titre d’exemple, un cookie peut être déposé par une régie publicitaire tierce sur le Site). Lorsqu’un Utilisateur consulte le Site et qu’une autre entité installe un cookie par l’intermédiaire du Site, ce cookie est un cookie tiers.</p>
                <p><strong>COOKIES DE SESSION</strong></p>
                <p>Ces cookies permettent aux exploitants du Site de suivre les actions d’un Utilisateur le temps d’une session de navigation. La session de navigation commence dès que l’Utilisateur ouvre la fenêtre du navigateur et finit lorsqu’il ferme cette fenêtre. Les cookies de session ont un caractère temporaire. Une fois le navigateur fermé, tous les cookies de session sont supprimés.</p>
                <p><strong>COOKIES PERSISTANTS</strong></p>
                <p>Ces cookies restent sur l’appareil de l’Utilisateur après la fermeture de la session de son navigateur et ce, pour la durée prévue par chacun de ces cookies. Ils sont activés chaque fois que l’Utilisateur consulte le Site.</p>
                <h4>Quels types de cookies l’Editeur utilise et pour quelles finalités ?</h4>
                <p>Différents types de cookies sont utilisés sur le Site, ils ont des finalités différentes. Certains sont nécessaires pour utiliser le Site, d’autres sont optionnels selon vos choix.</p>
                <p><strong>LES COOKIES TECHNIQUES STRICTEMENT NECESSAIRES</strong></p>
                <p>Ces cookies sont nécessaires au fonctionnement du Site. Ils permettent à l’Utilisateur d’utiliser les principales fonctionnalités du Site. Sans ces cookies, l’Utilisateur ne peut pas utiliser le Site normalement. Ces cookies requièrent uniquement une information de l’Utilisateur pour être déposés sur le terminal de l’Utilisateur et n’autorisent aucun suivi comportemental ou d’identification.</p>
                <p><strong>LES COOKIES FONCTIONNELS DE PERSONNALISATION</strong></p>
                <p>Ces cookies permettent de personnaliser l’expérience de l’Utilisateur sur le Site en mémorisant ses préférences et permettent notamment :</p>
                <ul>
                    <li>d'adapter la présentation du Site aux préférences d'affichage du terminal (langue utilisée, résolution d'affichage, système d'exploitation utilisé, etc…) lors des visites des Utilisateurs sur le Site, selon les matériels et les navigateurs ;</li>
                    <li>de mémoriser des informations relatives aux formulaires que l'Utilisateur a remplis sur le Site ou à des informations que l'Utilisateur a choisies sur le Site ;</li>
                    <li>d'offrir à l'Utilisateur un accès à son compte ou à tout autre espace réservé grâce à ses identifiants ;</li>
                    <li>de mettre en œuvre des mesures de sécurité, par exemple lorsqu'il est demandé à l'Utilisateur de se connecter à nouveau à un contenu ou à un service après une certaine durée écoulée.</li>
                </ul>
                <h4>Comment l’Utilisateur peut-il gérer les cookies ?</h4>
                <p>Lors de sa première connexion, l'Utilisateur est informé que les partenaires de l’Editeur et tout autre tiers identifié peuvent déposer des cookies via le Site.</p>
                <p>Seul l'émetteur d'un cookie est susceptible de lire des informations qui y sont contenues et l’Editeur n’a aucun accès sur les cookies que des tiers peuvent être amenés à utiliser. L'émission et l'utilisation de cookies par des tiers sont soumises aux politiques de confidentialité de ces tiers en plus des dispositions de la présente Politique. En conséquence l'Utilisateur est invité à se rendre sur les sites Internet de ces tiers identifiés ci-dessus s’il souhaite obtenir davantage d'informations sur les cookies qu'ils enregistrent et comment l'Utilisateur peut les gérer.</p>
                <p><strong>LA GESTION PAR L’UTILISATEUR DU DEPOT DES COOKIES</strong></p>
                <p>L'Utilisateur peut gérer le dépôt des cookies de la manière suivante :</p>
                <ul>
                    <li>Le dépôt des cookies techniques strictement nécessaires est activé par défaut et ne peut être désactivé par l’Utilisateur, ces cookies étant indispensables au fonctionnement du Site ;</li>
                    <li>L’Utilisateur désactive ou active l’implantation des cookies soumis au recueil de son consentement, à savoir les cookies de mesure d’audience et les cookies de publicité via le bandeau d’information qui est disponible lors de sa première visite sur le Site. Toute désactivation entraine le refus par l’Utilisateur du dépôt du cookie en question ;</li>
                    <li>En poursuivant sa navigation, c'est-à-dire lorsque l'Utilisateur a cliqué sur un élément du Site (image, lien, bouton « rechercher » etc.) ou s'est rendu sur une autre page du Site, l’Utilisateur valide l’implantation des cookies, selon le paramétrage par défaut proposé.</li>
                </ul>
                <p>Si l'Utilisateur active l'enregistrement de cookies dans son terminal, les cookies intégrés dans les pages et contenus qu'il a consultés pourront être stockés temporairement dans un espace dédié de son terminal. Ils y seront lisibles uniquement par leur émetteur. Le consentement donné par l'Utilisateur n'est valable que pour une durée de treize (13) mois à compter du premier dépôt dans l'équipement du terminal de l'Utilisateur, faisant suite à l'expression du consentement de ce dernier.</p>
                <p>Si l'Utilisateur désactive l'enregistrement de cookies dans son terminal ou son navigateur, ou s'il supprime ceux qui y sont enregistrés, l'Utilisateur est informé que sa navigation et son expérience sur le Site pourraient être différentes des autres Utilisateurs ayant activé les cookies (contenus non personnalisés). Cela pourrait également être le cas lorsque l’Editeur ou l’un de nos prestataires ne pourrait pas reconnaître, à des fins de compatibilité technique, le type de navigateur utilisé par le terminal, les paramètres de langue et d'affichage ou le pays depuis lequel le terminal semble connecté à Internet. Le cas échéant, l’Editeur décline toute responsabilité pour les conséquences liées au fonctionnement dégradé du Site résultant du refus de cookies par l'Utilisateur.</p>
                <p><strong>POUR EN SAVOIR PLUS</strong></p>
                <p>L’Utilisateur peut se rendre sur le site internet de la CNIL afin de recueillir des informations concernant les cookies via notamment la page suivante&nbsp;: <a href="https://www.cnil.fr/fr/site-web-cookies-et-autres-traceurs">https://www.cnil.fr/fr/site-web-cookies-et-autres-traceurs</a>.</p>
                <p>L'Utilisateur peut se connecter au site Youronlinechoices, proposé par les professionnels de la publicité digitale regroupés au sein de l'association européenne EDAA (European Digital Advertising Alliance) et géré en France par l'Interactive Advertising Bureau France. L'Utilisateur pourra ainsi connaître les entreprises inscrites à cette plate-forme et qui offrent la possibilité de refuser ou d'accepter les cookies utilisés par ces entreprises pour adapter les publicités susceptibles d'être affichées sur son terminal aux informations de navigation de l'Utilisateur à : <a href="http://www.youronlinechoices.com/fr/controler-ses-cookies/">http://www.youronlinechoices.com/fr/controler-ses-cookies/</a>.
                    ALCOME - 88 avenue des Ternes 75017 PARIS - SAS au capital social de 100 000 €</p>
                <p>N° TVA Intracommunautaire FR86 892015355 - Code NAF 3821Z - Siret 89201535500019</p>
            </div>
        </>
    )
}

export default CookiePolicyStep;
import { IContract } from '@alcome-rep/alcome-types/dist/interfaces';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from '../../common/store';
import contractPortalApi from '../services/contract-portal.api';
import { getContractAction, setCurrentContractAction, unsetCurrentContractAction, updateContractAction } from './contract-portal.slice';



function* updateContractInfos({ payload }: ReturnType<typeof updateContractAction>) {
  try {
    const { contractPortalService: { currentContract } }: RootState = yield select();
    if (!currentContract || !currentContract.id)
      return;
    const resp: IContract = yield call(contractPortalApi.updateContract, payload);

    yield put(setCurrentContractAction(resp));
  } catch {
    yield put(unsetCurrentContractAction());
  }
}


function* getMyContract(_: ReturnType<typeof getContractAction>) {
  try {
    const resp: IContract = yield call(contractPortalApi.getMyContract);

    yield put(setCurrentContractAction(resp));
  } catch {
    yield put(unsetCurrentContractAction());
  }
}

export function* contractPortalSaga() {
  yield takeLatest(updateContractAction, updateContractInfos);
  yield takeLatest(getContractAction, getMyContract);

}
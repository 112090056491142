import { Outlet } from 'react-router-dom';
import AdminNavBar from './AdminNavBar';
import AdminSideBar from './AdminSideBar';

// export const AdminStructureLayout = () => {

//   const { t } = useTranslation();

//   const structure = useAppSelector(selectCurrentAdminStructure)

//   return (
//     <>
//       <div className='flex justify-between'>
//         <div>
//           {structure && (<h2 className='mb-1 text-center'>{structure.name}</h2>)}
//         </div>
//         <div></div>
//       </div>
//       <div className='items-center text-center'>
//         {structure && (<div className='mb-3 text-alc-blue font-semibold'>{t(`TYPES.STRUCTURE.StructureTypesEnum.${structure.structureType}`)}</div>)}
//       </div>
//       <Outlet></Outlet>
//     </>
//   )
// }

const AdminLayout = ({ disableSideBar = false }) => {
  return (
    <>
      <div className="pageLayout overflow-auto">
        <div className='pageLayout-header'>
          <div>
            <AdminNavBar></AdminNavBar>
          </div>
        </div>
        <div className='pageLayout-menu'>
          <div className='h-full'>
            {!disableSideBar && <AdminSideBar></AdminSideBar>}
          </div>
        </div>

        <div className='pageLayout-page'>
          <div className='pageLayout-content'>
            <Outlet></Outlet>
          </div>
        </div>
      </div>
    </>)
}
export default AdminLayout;
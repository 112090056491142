import { FileDocumentStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces"
import { RcFile } from "antd/es/upload"
import AdminFileField from "../../../../common/components/admin/AdminFileField/AdminFileField"
import { useAppDispatch, useAppSelector } from "../../../../common/hooks"
import contractAdminApi from "../../../services/contract-admin.api"
import { selectCurrentAdminContract, adminSetCurrentContractAction } from "../../../state/contract-admin.slice"

const AdminContractFileUpload = () => {
  const dispatch = useAppDispatch()
  const contract = useAppSelector(selectCurrentAdminContract)

  const contractUpload = async (file: RcFile) => {
    if (!contract)
      throw new Error()
    const contractUpdated = await contractAdminApi.uploadContractFile(contract.id!, file)
    dispatch(adminSetCurrentContractAction(contractUpdated))
  }

  const contractDownload = () => {
    if (!contract)
      throw new Error()
    return contractAdminApi.getContractFile(contract.id!)
  }

  const updateStatus = async (status: FileDocumentStatusEnum, comment?: string) => {
    if (!contract)
      throw new Error()
    const contractUpdated = await contractAdminApi.updateContractFileDoc(contract.id!, { status, comment })
    dispatch(adminSetCurrentContractAction(contractUpdated))
  }

  if (!contract)
    return <></>;

  return <AdminFileField
    fileDocument={contract.contractFile}
    upload={contractUpload}
    download={contractDownload}
    propertyName='contractFileId'
    updateStatus={updateStatus}
  ></AdminFileField>
}

export default AdminContractFileUpload
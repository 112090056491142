import { Models } from '@alcome-rep/alcome-types';
import { IAnnualReview, InventoryStatusEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import { EditOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Table, Tag } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { ColumnsType } from 'antd/lib/table';
import { getYear } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { selectAuth } from '../../../../Auth/state/auth.slice';
import { selectCurrentContract } from '../../../../Contract/state/contract-portal.slice';
import { selectCurrentInventory } from '../../../../Inventory/state/inventory-portal.slice';
import { selectCurrentStructure } from '../../../../Structure/state/structure-portal.slice';
import DateField from '../../../../common/components/Fields/DateField';
import PageCanvas from '../../../../common/components/Panels/PageCanvas';
import { useAppSelector } from '../../../../common/hooks';
import { ROUTES } from '../../../../common/router';
import { getRoute } from '../../../../common/tools/router-tools';
import annualReviewPortalApi from '../../../services/annual-review-portal.api';
import AnnualReviewStatus from '../../common/AnnualReviewData/AnnualReviewStatus';
import Info from '../../../../common/components/WarningUser/Info';
import { AnnualReview } from '@alcome-rep/alcome-types/dist/models';

type TableType = { year: number, annualReview?: IAnnualReview }

const AnnualReviewPortalList = () => {

  const DEFAULT_PAGE_SIZE = 10;

  const [data, setData] = useState<TableType[]>([]);
  const [loading, setloading] = useState<boolean>(true);
  const structure = useAppSelector(selectCurrentStructure);
  const contract = useAppSelector(selectCurrentContract);
  const inventory = useAppSelector(selectCurrentInventory);
  const user = useAppSelector(selectAuth)?.user;
  const admin = new Models.User(user).isSuperAdmin();

  const getData = useMemo(() => () => {
    if (structure && contract !== null && contract.contractDate) {
      annualReviewPortalApi.find(
        { page: 1, pageSize: DEFAULT_PAGE_SIZE, query: { structureId: structure.id } }
      )
        .then(res => {
          let tableData: TableType[] = [];
          const contractYear = new Date(contract.contractDate as Date).getFullYear();
          for (let i = getYear(new Date()); i >= contractYear; i--) {
            const existingAR = res.rows.find(r => new Date(r.startDate).getFullYear() === i)
            tableData.push({ year: i, annualReview: existingAR });
          }

          setData(tableData)
          setloading(false)
        })
    }
  }, [structure, contract, setData, setloading])

  useEffect(() => {
    getData();
  }, [getData]);

  const submitAnnualReview = async (data: number) => {
    if (structure) {
      return await annualReviewPortalApi.createAnnualReview(structure.id as string, data)
    }
  }

  const confirmCreation = (year: number) => {
    confirm({
      title: `Voulez vous créer le bilan annuel pour l'année ${year} ?`,
      icon: <QuestionCircleOutlined />,
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okButtonProps: { className: "btn" },
      onOk() {
        submitAnnualReview(year)
          .then(() => getData())
      },
      onCancel() {
      },
    });
  }

  const annualReviewsTableColumns: ColumnsType<TableType> = [
    {
      title: 'Actions',
      key: 'bilan',
      width: '250px',
      render: (value: TableType) => {
        if (value.annualReview) return <Link type="link" to={getRoute(ROUTES.PORTAL_ANNUALREVIEW_ROUTES.portalAnnualReviewDetails, { annualReviewId: value.annualReview.id })}><EditOutlined /> Consulter le bilan annuel</Link>;
        if (value.year < 2025) return <Button type="link" onClick={_ => confirmCreation(value.year)} className='px-0'><PlusOutlined /> Créer le bilan annuel</Button>;
        return;
      }
    },
    {
      title: 'Année',
      dataIndex: 'year',
      key: 'year',
      render: (value: number) => (<span>{value}</span>)
    },
    {
      title: 'Info',
      dataIndex: 'annualReview',
      key: 'info',
      render: (value: AnnualReview | null) => {
        if (value) {
          const arDate = new Date(value.startDate).getFullYear();
          if (arDate === (new Date().getFullYear() - 1)) {
            return <Tag color='green'>Campagne en cours</Tag>
          } else if (arDate >= new Date().getFullYear()) {
            return <Tag color='orange'>Prochainement disponible</Tag>
          }else{
            return <Tag color='blue'>Campagne passée</Tag>
          }
        }else{
          return <Tag color='orange'>Prochainement disponible</Tag>
        }
      }
    },
    {
      title: 'Période',
      key: 'period',
      render: (_v: any, item: TableType) => {
        if (item && item.annualReview) return <span><DateField value={item.annualReview.startDate} /> au <DateField value={item.annualReview.endDate} /></span>;
        return <span>{`01/01/${item.year} au 31/12/${item.year}`}</span>;
      }
    },
    {
      title: 'Statut',
      key: 'status',
      render: (_v: any, item: TableType) => <>{item.annualReview && <AnnualReviewStatus annualReview={item.annualReview} />}</>
    },


    
/** @deprecated  
      {
        title: 'Cartographie',
        // dataIndex: 'status',
        // key: 'status',
        render: (value: TableType) => {
            if (value.annualReview) {
                const annualReview = value.annualReview as IStructureAnnualReview;
                return <div className='flex space-x-4'>
                    <Link to={`${APP_ROUTER_PATHS.adminAnnualReviewFiche.replace(/:structureId/, (annualReview.structure as any).id as string)}/${annualReview.id}/map`} onClick={e => e.stopPropagation()}>
                        <span >Dispositifs de collecte : {annualReview.collectPoints ? annualReview.collectPoints.length : 0}</span>
                        <span> – </span>
                        <span >Hotspots : {annualReview.hotSpots ? annualReview.hotSpots.length : 0}</span>
                    </Link>
                </div>;
            }
            return <></>
        }
    },
    {
        title: 'Statut',
        dataIndex: ['annualReview', 'status'],
        key: 'status',
        className: 'text-right',
        align: 'right' as 'right',
        render: (value: any) => value && (<span className='font-semibold text-alc-blue'>{t(`types.annualReview.AnnualReviewStatusEnum.${AnnualReviewStatusEnum[value]}`)}</span>)
    }, */
  ];

  return (
    <PageCanvas
      breadCrumbs={admin ? [
        ROUTES.ADMIN_USER_ROUTES.home,
        ROUTES.ADMIN_ANNUALREVIEW_ROUTES.annualReviewList
      ] : [
        ROUTES.PORTAL_USER_ROUTES.home,
        ROUTES.PORTAL_ANNUALREVIEW_ROUTES.portalAnnualReviewList
      ]}
      title={
        <>
          Bilans annuels
          <span className='block text-sm font-normal'>
            Consultez et complétez vos bilans annuels en cliquant sur la campagne souhaitée.
          </span>
        </>
      }
    >
      {inventory && inventory.status >= InventoryStatusEnum.completed ? (
        <Table<TableType>
          columns={annualReviewsTableColumns}
          dataSource={data}
          loading={loading}
          onChange={getData}
          rowKey="year"
          pagination={false} />
        ) : (
          <Info icon={<QuestionCircleOutlined />} type="warning" title="Pour accéder à vos bilans, vous devez compléter les annexes au contrat." />
        )
      }
    </PageCanvas>
  );
};

export default AnnualReviewPortalList;
import { ISupplierOrder } from "@alcome-rep/alcome-types/dist/interfaces/supplier"
import { Button, Form, Input } from "antd"

type Props = {
    supplierOrder: ISupplierOrder;
    submit: (supplierOrderData: ISupplierOrder) => void;
}

const AdminUpdateSupplierOrderPrice = ({ supplierOrder, submit }: Props) => {

    return (
        <div>
            {supplierOrder && (
                <Form
                    name="basic"
                    initialValues={supplierOrder}
                    layout='vertical'
                    onFinish={submit}
                    className="grid grid-cols-2 gap-x-4"
                    id="pa"
                >
                    <Form.Item
                        label="Frais de livraison"
                        name={['shippingFees']}
                        className="col-span-2"
                    >
                        <Input></Input>
                    </Form.Item>
                    <Form.Item
                        label="Montant Total"
                        name={['total']}
                        className="col-span-2"
                    >
                        <Input></Input>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" type="primary"  >
                            Modifier
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </div>
    )
}

export default AdminUpdateSupplierOrderPrice
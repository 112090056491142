import { Outlet } from 'react-router-dom';
import SupplierNavBar from './SupplierNavbar';
import SupplierSideBar from './SupplierSideBar';

const SupplierLayout = ({ disableSideBar = false }) => {
    return (
        <>
            <div className="pageLayout overflow-auto">
                <div className='pageLayout-header'>
                    <div>
                        <SupplierNavBar></SupplierNavBar>
                    </div>
                </div>
                <div className='pageLayout-menu'>
                    <div>
                        {!disableSideBar && <SupplierSideBar></SupplierSideBar>}
                    </div>
                </div>

                <div className='pageLayout-page'>
                    <div className='pageLayout-content'>
                        <Outlet></Outlet>
                    </div>
                </div>
            </div>
        </>)
}
export default SupplierLayout;
import { ContractStatusEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import { User } from '@alcome-rep/alcome-types/dist/models';
import { selectUser } from '../../../../Auth/state/auth.slice';
import { selectCurrentAdminInventory } from '../../../../Inventory/state/inventory-admin.slice';
import { selectCurrentInventory } from '../../../../Inventory/state/inventory-portal.slice';
import DateField from '../../../../common/components/Fields/DateField';
import LoadingScreen from '../../../../common/components/LoadingScreen';
import Section from '../../../../common/components/Panels/Section';
import { useAppSelector } from '../../../../common/hooks';
import { selectCurrentAdminContract } from '../../../state/contract-admin.slice';
import { selectCurrentContract } from '../../../state/contract-portal.slice';
import AdminContractFiles from '../../admin/AdminContractFiles/AdminContractFiles';
import ContractSignatory from '../../admin/ContractSignatory/ContractSignatory';
import PortalContractFiles from '../../portal/PortalContractFiles/PortalContractFiles';

const ContractPage = () => {
  const user = useAppSelector(selectUser);
  const inventory = useAppSelector(User.IsUserAdmin(user) ? selectCurrentAdminInventory : selectCurrentInventory);
  const contract = useAppSelector(User.IsUserAdmin(user) ? selectCurrentAdminContract : selectCurrentContract);

  if (!inventory || !contract) {
    return <LoadingScreen />
  }

  return (
    <>
      <div className='grid grid-cols-1 xl:grid-cols-2 gap-6'>
        <div className="grid grid-cols-1 gap-6">
          <Section title='Informations' mode="standard">
            {contract.status >= ContractStatusEnum.validated ?
              <h6>Date du contrat: <DateField value={contract.contractDate} /></h6>
              : <span className='italic'>Contrat en cours</span>
            }
          </Section>

          <Section
            title="Fichiers / Contrat"
            mode="transparent"
          >
            {User.IsUserAdmin(user) ? <AdminContractFiles contract={contract} /> : <PortalContractFiles contract={contract} />}
          </Section>
        </div>

        <Section
          title="Signataire du contrat"
          mode="standard"
        >
          <ContractSignatory contract={contract} inventory={inventory} />

        </Section>
      </div>
    </>
  );
};

export default ContractPage;
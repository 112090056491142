import { ILibraryDoc } from "@alcome-rep/alcome-types/dist/interfaces";
import ApiService from "../../common/services/apiService";

const PORTAL_LIBRARYDOC_PATH_API = 'portal/libraryDoc';

class PortalLibraryDocApi extends ApiService<ILibraryDoc> {
  constructor() {
    super(PORTAL_LIBRARYDOC_PATH_API)
  }
  userLibraryDocList = ({ query = {} }: { query?: any, page?: number }) => {
    return this.find({ query, page: 1, pageSize: - 1 })
    // const myQuery = { ...query }
    // const queryString = buildFindQuery(myQuery);
    // return this.custom<ApiListResult<ILibraryDoc>>('get', `?${queryString}&page=${page}`)
  }
}

const portalLibraryDocApi = new PortalLibraryDocApi();
export default portalLibraryDocApi;
import { ILogAction } from "@alcome-rep/alcome-types";
import { AnnualReviewStatusEnum, IAnnualReview } from "@alcome-rep/alcome-types/dist/interfaces";
import { UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { Button, Form, Radio } from "antd";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectUser } from "../../../../Auth/state/auth.slice";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks";
import annualReviewAdminApi from "../../../services/annual-review-admin.api";
import { selectAdminCurrentAnnualReview } from "../../../state/annual-review-admin.slice";
import { updateAnnualReviewKitAction } from "../../../state/annual-review-portal.slice";
import { selectCurrentPortalAnnualReview } from "../../../state/annual-review-portal.slice";
import { CheckCircleOutlined, ExclamationCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import Info from "../../../../common/components/WarningUser/Info";

type PropsType = {
  isAdmin?: boolean
}

const AnnualReviewCommunicationStep = ({ isAdmin }: PropsType) => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const annualReviewSelectorToUse = isAdmin ? selectAdminCurrentAnnualReview : selectCurrentPortalAnnualReview;
  const annualReview = useAppSelector(annualReviewSelectorToUse) as IAnnualReview;
  const admin = (user && user.userType === UserTypeEnum.admin) || false;
  const canEdit = !admin && annualReview.status <= AnnualReviewStatusEnum.refused;
  // const alreadySaved = annualReview && annualReview.communicationKitUsed;
  // const [admin] = useState((user && user.role === UserRolesEnum.superAdmin) || false);

  const [logActions, setLogActions] = useState<ILogAction[]>()

  const getKitDownloaded = useMemo(() => async () => {
    annualReviewAdminApi.adminfindKitsDownloaded(annualReview?.id as string)
      .then(res => setLogActions(res))
  }, [annualReview])

  useEffect(() => {
    if (admin) getKitDownloaded();
  }, [getKitDownloaded, admin]);

  const submit = (formData?: any) => {
    dispatch(updateAnnualReviewKitAction({ annualReviewId: annualReview?.id as string, data: formData }))
  };

  if (!annualReview) {
    return <></>
  }

  return (
    <div className="grid grid-cols-1 gap-6">
      {admin && (
        <Info type={(logActions?.length ?? 0) > 0 ? "success" : "error"} icon={(logActions?.length ?? 0) > 0 ? <CheckCircleOutlined /> : <ExclamationCircleOutlined />} body={<>
          {"Nombre de téléchargements de kits de communication entre le "}
          <span className="font-semibold">{format(new Date(annualReview.startDate as Date), 'dd/MM/yyyy', { locale: fr })}</span>
          {" et le "}
          <span className="font-semibold">{format(new Date(annualReview.endDate as Date), 'dd/MM/yyyy', { locale: fr })}</span>
          {" : "}
          <span className="font-semibold">{logActions?.length ?? 0}</span>.
        </>} />
      )}

      {annualReview.status === AnnualReviewStatusEnum.pending && (
        <Info type="warning" icon={<QuestionCircleOutlined />} body={<>Précisez si vous avez utilisé des kits de communication founis par Alcome, puis enregistrez vos modifications. Après avoir enregistré, cliquez sur l'onglet <span className="font-semibold">4. Bilan</span> afin de valider votre bilan.</>} />
      )}

      <Form
        name="basic"
        layout='vertical'
        onFinish={submit}
        initialValues={annualReview}
      >
        <Form.Item
          label='Avez vous utilisé les kits de communication mis à votre disposition par Alcome ?'
          name='communicationKitUsed'
          className={`col-span-2 ${admin ? "mb-0" : ""}`}
          labelAlign='right'
        >
          <Radio.Group disabled={!canEdit}>
            <Radio key={"yes"} value={1}>Oui</Radio>
            <Radio key={"no"} value={0}>Non</Radio>
          </Radio.Group>
        </Form.Item>
        
        {(!admin) && (
          <Button disabled={annualReview.status >= AnnualReviewStatusEnum.completed} htmlType="submit" className="btn">{t('COMMON.save')}</Button>
        )}
      </Form>
    </div>
  )
}

export default AnnualReviewCommunicationStep
import { AxiosResponse } from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import inventoryPortalApi from '../services/inventory-portal.api';
import { createInventoryAction, getInventoryAction, setCurrentInventoryAction, unsetCurrentInventoryAction, updateInventoryInfosAction } from './inventory-portal.slice';
import { RootState } from '../../common/store';
import { Models } from '@alcome-rep/alcome-types';
import { Inventory } from '@alcome-rep/alcome-types/dist/models';

/**
 * SAGAS
 */
function* create({ payload }: ReturnType<typeof createInventoryAction>) {
  try {
    const resp: AxiosResponse<Inventory> = yield call(inventoryPortalApi.createInventory, payload);

    yield put(setCurrentInventoryAction(resp.data));
  } catch (err) {
    yield put(unsetCurrentInventoryAction());
  }
}

function* updateInventoryInfos({ payload }: ReturnType<typeof updateInventoryInfosAction>) {
  try {
    const { inventoryPortalService: { currentInventory } }: RootState = yield select();
    const resp: Models.Inventory = yield call(inventoryPortalApi.updateInventoryInfos, currentInventory?.id as string, payload);

    yield put(setCurrentInventoryAction(resp));
  } catch {
    yield put(unsetCurrentInventoryAction());
  }
}

function* getInventory(_: ReturnType<typeof getInventoryAction>) {
  try {
    const resp: Models.Inventory = yield call(inventoryPortalApi.getInventory);

    yield put(setCurrentInventoryAction(resp));
  } catch {
    yield put(unsetCurrentInventoryAction());
  }
}

export function* InventoryPortalSaga() {
  yield takeLatest(createInventoryAction, create);
  yield takeLatest(updateInventoryInfosAction, updateInventoryInfos);
  yield takeLatest(getInventoryAction, getInventory);

}
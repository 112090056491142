import { calculateAshtrayQuantity } from '@alcome-rep/alcome-types';
import { IBaseCommune } from '@alcome-rep/alcome-types/dist/interfaces';
import { useEffect, useState } from 'react';


const useAshtray = (structure: IBaseCommune) => {

    const [ashtray, setAshtray] = useState<number>();

    // fecthing support quotas 
    useEffect(() => {
        if (!structure)
            return
        if (!structure.population)
            return
        setAshtray(calculateAshtrayQuantity(structure.population ?? 0))
    }, [structure])

    return ashtray
};

export default useAshtray;

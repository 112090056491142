import { Models } from "@alcome-rep/alcome-types"
import { Table } from "antd"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { selectUserSupplier } from "../../../../Auth/state/auth.slice"
import FilterPanel from "../../../../common/components/Panels/FilterPanel"
import PageCanvas from "../../../../common/components/Panels/PageCanvas"
import { useAppSelector } from "../../../../common/hooks"
import useQueryParams from "../../../../common/hooks/useQueryParams"
import useTableChange from "../../../../common/hooks/useTableChange"
import { ROUTES } from "../../../../common/router"
import productApi from "../../../services/product-api"
import { supplierProductListColumns } from "./SupplierProductColumns.constant"

const SupplierProductList = () => {

  const [data, setData] = useState<Models.Product[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setloading] = useState<boolean>(false);

  const user = useAppSelector(selectUserSupplier);

  const tableChange = useTableChange();
  const queryParams = useQueryParams()

  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    setloading(true)
    productApi.findAllProducts(
      { ...queryParams.query, supplierCompanyId: user.supplierCompanyId },
      Number(queryParams.page),
      Number(queryParams.limit),
      { [queryParams.sortField ?? 'name']: queryParams.sortOrder ?? 1 })
      .then(res => {
        setData(res.rows)
        setCount(res.count)
        setloading(false)
      })
      .catch(() => {
        setData([])
        setCount(0)
        setloading(false)
      })
  }, [setData, setCount, setloading, queryParams, user.supplierCompanyId])

  return (
    <PageCanvas
      breadCrumbs={[
        ROUTES.SUPPLIER_USER_ROUTES.home,
        ROUTES.SUPPLIER_USER_ROUTES.productList
      ]}
      title={<span>Liste des Produits <small>({count ? count : 0})</small ></span >}
    >
      <FilterPanel>
        {/* <FilterInput searchParamName='name' label='Nom' isRegex={true} /> */}
        {/* <FilterInput searchParamName='siret' label='Siret' isRegex={true} /> */}
      </FilterPanel>

      <div>
        <Table<Models.Product>
          columns={supplierProductListColumns(queryParams.query, t, navigate)}
          dataSource={data}
          loading={loading}
          onChange={tableChange}
          size="small"
          rowKey="id"
          pagination={
            {
              hideOnSinglePage: true,
              current: queryParams.page,
              pageSize: queryParams.limit,
              total: count,
            }
          } />
      </div>
    </PageCanvas >
  )
}

export default SupplierProductList
import { ISupplierCompany } from "@alcome-rep/alcome-types/dist/interfaces/supplier"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import LoadingScreen from "../../../../common/components/LoadingScreen"
import Section from "../../../../common/components/Panels/Section"
import supplierCompanyApi from "../../../services/supplierCompany.api"

const UserSupplierOrderDetails = () => {

    const { orderId } = useParams();
    const [supplierCompany, setSupplierCompany] = useState<ISupplierCompany>()

    useEffect(() => {
        supplierCompanyApi.getMyCompany()
            .then(res =>
                setSupplierCompany(res)
            )
    }, [setSupplierCompany])

    if (!supplierCompany)
        return <LoadingScreen />

    return <>
        <Section title={`Commande N° ${orderId}`}>
            
        </Section>
    </>
}

export default UserSupplierOrderDetails
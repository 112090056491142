import { Models } from "@alcome-rep/alcome-types";
import { ISupplierOrder, SupplierOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces/supplier";
import { message } from "antd";
import ApiService from "../../common/services/apiService";
import { ISupplierFileDocument } from "@alcome-rep/alcome-types/dist/interfaces/supplier/supplierFileDocument";

const ADMIN_SUPPLIER_COMPANY_PATH_API = 'admin/supplierOrder';

class AdminSupplierOrderApi extends ApiService<ISupplierOrder> {
  constructor() {
    super(ADMIN_SUPPLIER_COMPANY_PATH_API)
  }

  findAllSupplierOrders(query?: any, page?: number, limit?: number) {
    return this.find({ query, page, pageSize: limit })
      .then(r => ({ ...r, rows: r.rows.map(o => new Models.SupplierOrder(o)) }))
  }


  getSupplierOrderById(supplierOrderId: string) {
    return this.get(supplierOrderId)
      .then(o => new Models.SupplierOrder(o))
  }


  updateSupplierOrder(supplierOrderId: string, data: any) {
    return this.custom<ISupplierOrder>("put", `${supplierOrderId}`, undefined, data)
      .then(res => {
        message.success('Commande mise à jour avec succès')
        return new Models.SupplierOrder(res)
      })
      .catch(err => {
        message.error('Erreur lors de la mise à jour')
        return err
      })
  }

  updateSupplierOrderStatus(supplierOrderId: string, data: { status: SupplierOrderStatusEnum }) {
    return this.update(supplierOrderId, data)
      .then(res => {
        message.success('Commande mise à jour avec succès')
        return new Models.SupplierOrder(res)
      })
      .catch(err => {
        message.error('Erreur lors de la mise à jour')
        return err
      })
  }

  financialManagerApproveOrder(supplierOrderId: string) {
    return this.custom<ISupplierOrder>('put', `${supplierOrderId}/financialManagerApproveOrder`)
      .then(res => {
        message.success('Commande mise à jour avec succès')
        return new Models.SupplierOrder(res)
      })
      .catch(err => {
        message.error('Erreur lors de la mise à jour')
        return err
      })
  }

  findInvoicesToExport(year: number, month: number) {
    return this.custom<ISupplierFileDocument[]>('get',`findInvoicesToExport?year=${year}&month=${month}`)
  }


}

const adminSupplierOrderApi = new AdminSupplierOrderApi();
export default adminSupplierOrderApi;
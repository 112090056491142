import { Button, Form, Input } from "antd"
import useFormRules from "../../../../common/hooks/useFormRules"
import { useForm } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import adminSupplierCompanyApi from "../../../services/admin-supplierCompany.api";
import { ISupplierCompany } from "@alcome-rep/alcome-types/dist/interfaces/supplier";
import Section from "../../../../common/components/Panels/Section";

type Props = {
    close: () => void
}

const CreateSupplierCompanyForm = ({ close }: Props) => {

    const [form] = useForm()
    const { required } = useFormRules();
    const { t } = useTranslation();

    const createSupplierCompany = (formData: ISupplierCompany) => {
        adminSupplierCompanyApi.createCompany(formData)
            .then(() => {
                close()
            })
    }

    return (
        <Section title={<span>Création d'un fournisseur</span>}>
            <Form
                form={form}
                name="basic"
                layout='vertical'
                onFinish={createSupplierCompany}
            >
                <div className="flex space-x-8">
                    <Form.Item
                        label="Nom"
                        name="name"
                        rules={[required]}
                    >
                        <Input></Input>
                    </Form.Item>
                    <Form.Item
                        label="Siret"
                        name="siret"
                        rules={[required]}
                    >
                        <Input></Input>
                    </Form.Item>
                </div>
                    <Form.Item
                        label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.street.label')}
                        name={['address', 'street']}
                        rules={[required]}
                    >
                        <Input></Input>
                    </Form.Item>

                    <Form.Item
                        label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.city.label')}
                        name={['address', 'city']}
                        rules={[required]}
                    >
                        <Input></Input>
                    </Form.Item>

                    <Form.Item
                        label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.zip.label')}
                        name={['address', 'zip']}
                        rules={[required]}
                    >
                        <Input></Input>
                    </Form.Item>

                <Button htmlType="submit" type="primary" >Créer un fournisseur</Button>

            </Form >
        </Section>
    )
}

export default CreateSupplierCompanyForm
import { Outlet } from 'react-router-dom';

const AppLayout = () => (<>
    <Outlet></Outlet>
    <div className="hidden w-1/12"></div>
    <div className="hidden w-2/12"></div>
    <div className="hidden w-3/12"></div>
    <div className="hidden w-4/12"></div>
    <div className="hidden w-5/12"></div>
    <div className="hidden w-6/12"></div>
    <div className="hidden w-7/12"></div>
    <div className="hidden w-8/12"></div>
    <div className="hidden w-9/12"></div>
    <div className="hidden w-10/12"></div>
    <div className="hidden w-11/12"></div>
    <div className="hidden w-full"></div>
</>)

export default AppLayout;
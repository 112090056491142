import { ThemeConfig } from 'antd'
import tailwindTheme from './tailwind-theme';

const antdConfig: ThemeConfig = {
  // hashed: false,
  token: {
    // borderRadius: 4,
    // colorPrimary: tailwindTheme.colors.alc.blue,
    // colorInfo: "#02417d",
    // colorSuccess: tailwindTheme.colors.alc.success,
    // colorError: tailwindTheme.colors.alc.danger,
    // colorTextBase: tailwindTheme.colors.alc.gray,
  },
  components: {
    Menu: {
      itemBorderRadius: 0,
      subMenuItemBorderRadius: 0,
      // itemColor: tailwindTheme.colors.alc.blue,
      // itemSelectedColor: tailwindTheme.colors.alc.yellow,
      itemHoverColor: tailwindTheme.colors.alc.yellow,// '#1890ff',
      horizontalItemHoverColor: tailwindTheme.colors.alc.yellow,// '#1890ff',
      itemSelectedColor: tailwindTheme.colors.alc.yellow,
      itemSelectedBg: 'transparent',
      activeBarWidth: 3,
      itemMarginInline: 0,
      // itemHoverBg: 'transparent',
    },
    Form: {
      // labelColor: tailwindTheme.colors.alc.gray,
      // labelHeight: 40,
      // verticalLabelPadding: 0,
      // colorErrorText: tailwindTheme.colors.alc.danger,
    },
    Input: {
      // colorBgContainer: tailwindTheme.colors.gray[100],
      // lineHeight: 2.75,
      // fontSize: 14,
      // colorTextPlaceholder: tailwindTheme.colors.gray[400],
      // colorBorder: 'transparent',
    },
    InputNumber: {
      // lineHeight: 2.75,
      // colorBgContainer: tailwindTheme.colors.gray[100],
      // colorBorder: 'transparent',
    },
    Button: {
      // lineHeight: 0,
      // controlHeight: 40,
      // primaryShadow: undefined
      // colorPrimaryHover: 'white',
    },
    Modal: {
      // titleColor: tailwindTheme.colors.alc.blue,
      // titleFontSize: 18,
      // titleLineHeight: 2,
      // fontWeightStrong: 200
    },
    Drawer: {
      // colorText: tailwindTheme.colors.alc.blue,
      // fontWeightStrong: 200,
      // fontSizeLG: 18,
    },
    Select: {
      // optionSelectedBg: tailwindTheme.colors.gray[100],
      // optionSelectedColor: tailwindTheme.colors.alc.blue,
      // optionSelectedFontWeight: 'normal',
      // controlHeight: 40,
      // colorBgContainer: tailwindTheme.colors.gray[100],
      // colorBorder: 'transparent',
    },
    DatePicker: {
      // lineHeight: 2.75,
      // colorBgContainer: tailwindTheme.colors.gray[100],
      // colorBorder: 'transparent',
    },
    Tabs: {
      // itemActiveColor: 'black',
      // itemSelectedColor: 'black',
    },
    Progress: {
      defaultColor: tailwindTheme.colors.alc.yellow
    }
  },
}

export default antdConfig;
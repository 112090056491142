import { StructureTypesEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import { ContactTypeEnum, InterestValueEnum, ProspectTypesEnum } from '@alcome-rep/alcome-types/dist/interfaces/crm';
import { Button, Form, Input, Radio, Select, Switch, message } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as reactPhone from 'react-phone-number-input/input';
import { useNavigate } from 'react-router-dom';
import { CodeInseeField } from '../../../Structure/components/InseeSiren/codeInseeField';
import useFormRules from '../../../common/hooks/useFormRules';
import { ROUTES } from '../../../common/router';
import { getRoute } from '../../../common/tools/router-tools';
import contactAdminApi from '../../services/contact-admin.api';
import prospectAdminApi from '../../services/prospect-admin.api';
import AmountValue from '../../../common/components/Fields/AmountValue';
import { CodeInsee, calculateAmountStatement, calculateAshtrayQuantity } from '@alcome-rep/alcome-types';
import confirm from "antd/es/modal/confirm";

const CreateProspect = ({ onSubmit }: { onSubmit: () => void }) => {

  const { t } = useTranslation();
  const navigate = useNavigate()

  const [form] = useForm()
  const structureType = useWatch('structureType', form)
  const [value, setValue] = useState()

  const { required, email, phoneNumber } = useFormRules();

  const submit = async (prospectData: any) => {
    const prospect = { ...prospectData, contact: undefined }
    prospectAdminApi.create(prospect).then(res => {
      if (prospectData.contact && prospectData.contact.lastName)
        contactAdminApi.create({ ...prospectData.contact, prospectId: res.id as string, type: ContactTypeEnum.mainContact })
          .then(res => res)
      message.success('Prospect créé')
      navigate(getRoute(ROUTES.ADMIN_CRM.ADMIN_PROSPECT_ROUTES.prospectDetails, { prospectId: res.id }))
      onSubmit();
    }).catch(() => {
      prospectAdminApi.findAll({ query: { siren: prospectData.siren }, page: 1, limit: 1 }).then(res => {
        confirm({
          title: "La fiche de prospection a déja été créée",
          content: `Souhaitez vous accéder à la fiche de prospection ${prospectData.name} ?`,
          closable: true,
          cancelText: "Annuler",
          okCancel: true,
          onOk: () => {
            navigate(getRoute(ROUTES.ADMIN_CRM.ADMIN_PROSPECT_ROUTES.prospectDetails, { prospectId: res.rows[0].id }))
            onSubmit();
          },
          onCancel: () => { }
        })
      })
    })

  }

  const [ashtray, setAshtray] = useState<number | undefined>()
  const [amountStatement, setAmountStatement] = useState<number | undefined>()

  const loadStructureData = (name: string, siren: string, isRegistered?: boolean, insee?: CodeInsee) => {
    form.resetFields(['name', 'population',
      'email', 'phone', 'comment',
      'structure', 'prospectType', 'interestValue',
      ['address', 'street'], ['address', 'zip'], ['address', 'city']
    ])
    form.setFieldValue('name', name)
    form.setFieldValue('siren', siren)
    if (insee) {
      setAshtray(calculateAshtrayQuantity(insee?.population ?? 0))
      if (!insee.type)
        setAmountStatement(insee.population * 1.18)
      else
        setAmountStatement(calculateAmountStatement([{ ...insee } as any]))
    } else {
      setAshtray(undefined);
      setAmountStatement(undefined);
    }
    // prospectAdminApi.getAshtrayQuantity(data.population)
    //     .then(res => setAshtray(res))

    // prospectAdminApi.calculateStatement(data.siren)
    //     .then(res => setAmountStatement(res))

    // form.setFieldsValue({ ...data, structureId: data.id });
  }

  return (
    <>
      <div className=''>
        <Form
          layout='vertical'
          onFinish={submit}
          form={form}
        >
          <div>
            <div className='font-semibold text-alc-blue mb-4'>
              1 / Détails de la structure
            </div>
            <div className='grid grid-cols-2 gap-x-4 gap-y-0'>
              <Form.Item
                label="Type de structure"
                name={['structureType']}
                rules={[required]}
              >
                <Select>
                  {Object.keys(StructureTypesEnum).filter(v => typeof v === "string").map(v => (
                    <Select.Option key={v} value={v}>{t(`TYPES.STRUCTURE.StructureTypesEnum.${v}`)}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {structureType &&
                <Form.Item
                  name="siren"
                  rules={[required]}
                >
                  <CodeInseeField
                    structureType={structureType}
                    selectCommune={loadStructureData}
                  />
                </Form.Item>
              }
            </div>
          </div>
          {amountStatement !== undefined && amountStatement !== 0 && (
            <div className='py-2 font-semibold text-alc-blue'>
              <span>
                L'aide financière pour cette structure s'élève à <AmountValue amount={amountStatement} precision={2} />
              </span>
            </div>
          )}
          {ashtray !== undefined && ashtray !== 0 && (
            <div className='py-2 font-semibold text-alc-blue'>
              <span>La structure à la possibilité de commander jusqu'à <AmountValue amount={ashtray} hideSuffix={true} /> cendriers.</span>
            </div>
          )}
          {structureType && <>
            <div className='grid grid-cols-3 gap-x-4 gap-y-0'>
              <Form.Item
                label={t('UI.STRUCTURE_DETAILS.fields.name.label')}
                name={['name']}
                rules={[required]}
              >
                <Input ></Input>
              </Form.Item>
              {/* <Form.Item
                                label={t('UI.STRUCTURE_DETAILS.fields.details.population.label')}
                                name={['population']}
                            >
                                <InputNumber className='w-44'></InputNumber>
                            </Form.Item> */}
              {/* <Form.Item
                                label={t('UI.STRUCTURE_DETAILS.fields.details.communeType.label')}
                                name={['communeType']}
                            >
                                <Select>
                                    {Object.keys(CommuneTypeEnum)
                                        .filter(v => typeof v === "string")
                                        .map(v => (
                                            <Select.Option key={v} value={v}>{t(`TYPES.STRUCTURE.CommuneTypeEnum.${v}`)}</Select.Option>
                                        ))}

                                </Select>
                            </Form.Item> */}
            </div>
            <div>
              <div className='font-semibold text-alc-blue mb-4'>
                2 / Détails sur le prospect
              </div>
              <div className='grid grid-cols-2 gap-x-4 gap-y-0'>
                <Form.Item
                  label="Type de prospect"
                  name={['prospectType']}
                  rules={[required]}
                // className="col-span-2"
                >
                  <Radio.Group >
                    {Object.keys(ProspectTypesEnum).filter(v => typeof v === "string").map(v => (
                      <Radio key={v} value={v}>{t(`TYPES.STRUCTURE.ProspectTypesEnum.${v}`)}</Radio>
                    ))}
                  </Radio.Group>

                </Form.Item>
                <Form.Item
                  label="Intérêt du prospect"
                  name={['interestValue']}
                  rules={[required]}
                >
                  <Radio.Group buttonStyle="solid">
                    {Object.keys(InterestValueEnum).filter(v => typeof v === "string").map(v => (
                      <Radio.Button key={v} value={v}>{t(`TYPES.STRUCTURE.InterestValueEnum.${v}`)}</Radio.Button>
                    ))}
                  </Radio.Group>

                </Form.Item>
                <Form.Item
                  label="En charge du nettoiement"
                  name={['inChargeOfCleaning']}
                  initialValue={true}
                >
                  <Switch defaultChecked />
                </Form.Item>
              </div>
              <Form.Item
                label="Commentaire"
                name={'comment'}
              >
                <TextArea placeholder="Commentaire"></TextArea>
              </Form.Item>

            </div>
            <div>
              <div className='font-semibold text-alc-blue mb-4'>
                3/ Coordonnées de la structure
              </div>
              <div className='grid grid-cols-3 gap-x-4 gap-y-0'>
                <Form.Item
                  label={t('UI.STRUCTURE_DETAILS.fields.street.label')}
                  name={['address', 'street']}
                >
                  <Input placeholder="Addresse"></Input>
                </Form.Item>
                <Form.Item
                  label={t('UI.STRUCTURE_DETAILS.fields.zip.label')}
                  name={['address', 'zip']}
                >
                  <Input placeholder="Code postal"></Input>
                </Form.Item>
                <Form.Item
                  label={t('UI.STRUCTURE_DETAILS.fields.city.label')}
                  name={['address', 'city']}
                >
                  <Input placeholder="Ville"></Input>
                </Form.Item>
              </div>
              <div className='grid grid-cols-2 gap-x-4 gap-y-0'>
                <Form.Item
                  label="Email"
                  name={'email'}
                  rules={[email]}
                >
                  <Input placeholder="Email"></Input>
                </Form.Item>
                <Form.Item
                  label="Téléphone"
                  name={'phone'}
                  rules={[phoneNumber]}
                >
                  <reactPhone.default
                    className="ant-input"
                    country="FR"
                    placeholder="Téléphone"
                    value={value}
                    onChange={(e: any) => setValue(e)} />
                </Form.Item>
              </div>
              <Form.Item
                // hidden
                className='hidden'
                // rules={[required]}
                // label="structureId"
                name={'structure'}
              >
                <Input type="hidden"></Input>
              </Form.Item>
            </div>
            <div>
              <div className='font-semibold text-alc-blue mb-4'>
                4/ Contact principal
              </div>
              <div className='grid grid-cols-2 gap-x-4 gap-y-0'>
                <Form.Item
                  label="Prénom"
                  name={['contact', 'firstName']}
                >
                  <Input ></Input>
                </Form.Item>
                <Form.Item
                  label="Nom"
                  name={['contact', 'lastName']}
                >
                  <Input ></Input>
                </Form.Item>
                <Form.Item
                  label="Email"
                  name={['contact', 'email']}
                  rules={[email]}
                >
                  <Input ></Input>
                </Form.Item>
                <Form.Item
                  label="Fonction"
                  name={['contact', 'fonction']}
                >
                  <Input ></Input>
                </Form.Item>
              </div>
            </div>
            <Form.Item>
              {/* {console.log(form.getFieldsError())} */}
              <Button htmlType="submit" type="primary" className="bg-alc-blue">
                Créer la fiche de prospection
              </Button>
            </Form.Item>
          </>}
        </Form>
      </div>
    </>

  );

};

export default CreateProspect;
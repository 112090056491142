import { BaseCommune, Contract, Inventory } from "@alcome-rep/alcome-types/dist/models"
import Figure from "../../../../common/components/Panels/Figure"
import ContractStatus from "../../../../Contract/components/admin/ContractData/ContractStatus"
import ContractDate from "../../../../Contract/components/admin/ContractData/ContractDate"
import CommunePopulation from "../../common/CommunePopulation"
import CommuneType from "../../common/CommuneType"
import InventoryStatus from "../../../../Inventory/components/admin/InventoryData/InventoryStatus"

type PropsType = {
  structure: BaseCommune,
  contract: Contract,
  inventory: Inventory,
}

const StructureFigures = ({ structure, contract, inventory }: PropsType) => {
  return <>
    <div className="grid grid-cols-3 gap-4 my-4">
      <Figure title="Statut du contrat" value={<ContractStatus contract={contract} />} />
      <Figure title="Statut des annexes" value={<InventoryStatus inventory={inventory} />} />
      <Figure title="Date du contract" value={<ContractDate contract={contract} />} />
      <Figure title={`Code ${structure.isGroupement() ? 'Siren' : "INSEE"}`} value={structure.siren} />
      <Figure title="Population" value={<CommunePopulation structure={structure} />} />
      {structure.isCommune() && <Figure title="Type de commune" value={<CommuneType structure={structure} />} />}
      {structure.isGroupement() && <Figure title="Nombre de communes" value={structure.communes.length} />}
    </div>
  </>
}

export default StructureFigures
import { IAmendment, StructureTypesEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { Button, Checkbox, DatePicker, Form, Input, message } from "antd";
import { useTranslation } from "react-i18next";
import amendmentAdminApi from "../../../services/amendment-admin.api";
import useFormRules from "../../../../common/hooks/useFormRules";
import { useAppDispatch } from "../../../../common/hooks";
import { closeDrawerAction } from "../../../../common/state/modal.slice";


const AdminCreateAmendment = ({ onSubmit }: { onSubmit: () => void }) => {

    const { t } = useTranslation();
    const { required } = useFormRules();
    const dispatch = useAppDispatch();

    const submit = async (amendmentData: IAmendment) => {
        const res = await amendmentAdminApi.create(amendmentData)
        if (!res) {
            return message.error('erreur')
        } else {
            dispatch(closeDrawerAction())
            onSubmit()
            return message.success('Avenant créé avec succès !')
        }
    }

    return (
        <div>
            <Form
                name="basic"
                layout='vertical'
                onFinish={submit}
            >
                <Form.Item
                    label="N° de l'avenant"
                    name="amendmentNumber"
                    className='mt-5 mb-5'
                    rules={[required]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label="Nom de l'avenant"
                    name="name"
                    className='mt-5 mb-5'
                    rules={[required]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    label="Date de l'avenant"
                    name="date"
                    rules={[required]}
                >
                    <DatePicker format={'DD/MM/YYYY'} />
                </Form.Item>
                <Form.Item
                    label="Types de structure"
                    name="structureTypes"
                    rules={[required]}
                >
                    <Checkbox.Group >
                        {Object.keys(StructureTypesEnum).filter(k => typeof k === 'string').map(k => <Checkbox value={k}>{t('TYPES.STRUCTURE.StructureTypesEnum.' + k)}</Checkbox>)}
                    </Checkbox.Group>
                </Form.Item>
                <Form.Item
                    label="Version du contrat"
                    name="contractVersion"
                    className='mt-5 mb-5'
                    rules={[required]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" type="primary" className="bg-alc-blue " style={{ marginTop: 30 }}>
                        {t('COMMON.save')}
                    </Button>
                </Form.Item>
            </Form>

        </div>
    );
};

export default AdminCreateAmendment;
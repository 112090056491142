import { Rule, RuleObject } from 'antd/lib/form';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const required = (t: TFunction): Rule => ({
  required: true,
  message: t('FORM.VALIDATION.required', { defaultValue: 'Field is required' }),
});

const email = (t: TFunction): Rule => ({
  type: 'email',
  message: t('FORM.VALIDATION.email.invalid'),
});

const requireTrue = (t: TFunction): Rule => ({
  required: true,
  validator: (rule: RuleObject, value: any) => {
    if (!value) {
      return Promise.reject(rule.message)
    }
    return Promise.resolve()
  },
  message: t('FORM.VALIDATION.requireTrue.invalid'),
});

const passwordMinLength = (t: TFunction): Rule => ({
  // pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w]).{8,}$/,
  message: t('FORM.VALIDATION.password')
});


const phone = (t: TFunction): Rule => ({
  // pattern: /^0?[1-7][0-9]{8}$/,
  pattern: /^(?:(?:\+|00)33|0)?\s*[1-9](?:[\s.-]*\d{2}){4}$/,
  message: t('FORM.VALIDATION.phone.invalid')
});

const zipCode = (t: TFunction): Rule => ({
  pattern: /^[0-9]{5,5}$/,
  message: t('FORM.VALIDATION.zipCode.invalid')
});


const phoneNumber = (t: TFunction): Rule => ({
  pattern: /^(?:(?:\+|00)33|0)?\s*[1-9](?:[\s.-]*\d{2}){4}$/,
  message: t('FORM.VALIDATION.phone.invalid')
});


const useFormRules = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      required: required(t),
      email: email(t),
      password: passwordMinLength(t),
      phone: phone(t),
      zipCode: zipCode(t),
      requireTrue: requireTrue(t),
      phoneNumber: phoneNumber(t),
    }),
    [t]
  );
};

export default useFormRules;

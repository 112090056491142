import { Models } from "@alcome-rep/alcome-types";
import { IContract, IFileDocument } from "@alcome-rep/alcome-types/dist/interfaces";
import { defaultPagination } from "../../common/app-constants";
import ApiService from "../../common/services/apiService";
import { AdminSignatoryPayload } from "./types";

const CONTRACT_ADMIN_PATH_API = 'admin/contract';
export type ContractUploadableProperties = 'contractFileId' | 'contractFileGeneratedId' | 'executiveDeliberationFileId' | 'delegationFileId';

class ContractAdminApi extends ApiService<IContract, ContractUploadableProperties> {
  constructor() {
    super(CONTRACT_ADMIN_PATH_API)
  }

  findContracts(query: any, page: number = defaultPagination.page, pageSize: number = defaultPagination.pageSize) {
    return this.find({ query, page, pageSize })
      .then(r => ({
        count: r.count,
        rows: r.rows.map(row => new Models.Contract(row))
      })
      )
  }

  getContractByStructure = (structureId: string) => {
    return this.get(`byStructure/${structureId}`)
      .then(r => new Models.Contract(r))
  }

  getContract = (contractId: string) => {
    return this.get(contractId)
      .then(r => new Models.Contract(r))
  }

  updateContractSignatory(contractId: string, data: AdminSignatoryPayload) {
    return this.update(`${contractId}/signatory`, data)
      .then(r => new Models.Contract(r))
  }

  // ====== GENERATED CONTRACT FILE ======
  getGeneratedContractFile = () => {
    return this.downloadFileDoc('contractFileGeneratedId')
  }

  updateGeneratedContractFileDoc = (contractId: string, fileDoc: Partial<IFileDocument>): Promise<Models.Contract> => {
    return this.updateFileDoc<Models.Contract>('contractFileId', fileDoc, contractId)
      .then(r => new Models.Contract(r))
  }

  deleteGeneratedContractFile = () => {
    return this.deleteFileDoc('contractFileGeneratedId')
  }

  // ====== CONTRACT FILE ======
  uploadContractFile = (contractId: string, file: File) => {
    return this.uploadFileDoc<Models.Contract>('contractFileId', file, contractId)
      .then(r => new Models.Contract(r))
  }

  updateContractFileDoc = (contractId: string, fileDoc: Partial<IFileDocument>): Promise<Models.Contract> => {
    return this.updateFileDoc<Models.Contract>('contractFileId', fileDoc, contractId)
      .then(r => new Models.Contract(r))
  }

  getContractFile = (contractId: string) => {
    return this.getFileDoc('contractFileId', contractId)
  }

  deleteContractFile = () => {
    return this.deleteFileDoc('contractFileId')
  }

  // ====== EXECUTIVE DELIBERATION FILE ======
  uploadExecutiveDeliberationFile = (contractId: string, file: File) => {
    return this.uploadFileDoc<Models.Contract>('executiveDeliberationFileId', file, contractId)
      .then(r => new Models.Contract(r))
  }

  updateExecutiveDeliberationFileDoc = (contractId: string, fileDoc: Partial<IFileDocument>): Promise<Models.Contract> => {
    return this.updateFileDoc<Models.Contract>('executiveDeliberationFileId', fileDoc, contractId)
      .then(r => new Models.Contract(r))
  }

  getExecutiveDeliberationFile = (contractId: string) => {
    return this.getFileDoc('executiveDeliberationFileId', contractId)
  }

  deleteExecutiveDeliberationFile = () => {
    return this.deleteFileDoc('executiveDeliberationFileId')
  }

  // ====== DELEGATION FILE ======
  uploadDelegationFile = (contractId: string, file: File) => {
    return this.uploadFileDoc<Models.Contract>('delegationFileId', file, contractId)
      .then(r => new Models.Contract(r))
  }

  updateDelegationFileDoc = (contractId: string, fileDoc: Partial<IFileDocument>): Promise<Models.Contract> => {
    return this.updateFileDoc<Models.Contract>('delegationFileId', fileDoc, contractId)
      .then(r => new Models.Contract(r))
  }

  getDelegationFile = (contractId: string): any => {
    return this.getFileDoc('delegationFileId', contractId)
  }

  deleteDelegationFile = () => {
    return this.deleteFileDoc('delegationFileId')
  }

}

const contractAdminApi = new ContractAdminApi();
export default contractAdminApi;
import { SupplierOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces/supplier"
import { Steps } from "antd"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"

const AdminSupplierOrderListStatusFilter = () => {
  const [currentStep, setCurrentStep] = useState<number>(-1)
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation()

  const status = searchParams.get('status');

  const items = useMemo(() => Object.keys(SupplierOrderStatusEnum)
    .filter(k => !isNaN(k as any))
    .map(k => ({ key: k, title: t('TYPES.SUPPLIER_ORDER.SupplierOrderStatusEnum.' + SupplierOrderStatusEnum[k as any]) }))
    , [t])


  useEffect(() => {
    if (status === null) {
      setCurrentStep(-1)
    } else {
      setCurrentStep(items.findIndex(item => item.key === status));
    }
  }, [status, items])

  const onStatusChange = (current: number) => {
    const step = Number(String(current))
    const status = items[step]

    if (status) {
      searchParams.set('status', String(status.key))
      setSearchParams(searchParams)
    }
  }

  return <div className='w-full'>
    <Steps
      current={currentStep}
      size="small"
      onChange={onStatusChange}
      direction='horizontal'
      items={items} />

  </div>
}

export default AdminSupplierOrderListStatusFilter
import { RouteObject } from "react-router-dom";
import AdminSupportOrderEdit from "../components/admin/AdminSupportOrderEdit/AdminSupportOrderEdit";
import AdminSupportOrderList from "../components/admin/AdminSupportOrderList/AdminSupportOrderList";
import SupportOrderInvoices from "../components/common/SupportOrderInvoices/SupportOrderInvoices";
import SupportOrderMaterial from "../components/common/SupportOrderMaterial/SupportOrderMaterial";
import SupportOrderState from "../components/common/SupportOrderState/SupportOrderState";
import SupportOrderLayout from "../layouts/SupportOrderLayout";


const routePrefix = 'admin'
const routeDomain = 'supportOrder'
const routeParam = 'supportOrderId'

export const adminSupportOrderRouteNames = {
  supportOrderList: { path: `${routePrefix}/${routeDomain}`, title: `ROUTES.${routeDomain}.list` },
  supportOrderDetails: { path: `${routePrefix}/${routeDomain}/:${routeParam}/details`, title: `ROUTES.${routeDomain}.details` },
  supportOrderInvoices: { path: `${routePrefix}/${routeDomain}/:${routeParam}/invoices`, title: `ROUTES.${routeDomain}.invoices` },
  supportOrderMaterialDeclaration: { path: `${routePrefix}/${routeDomain}/:${routeParam}/materialDeclaration`, title: `ROUTES.${routeDomain}.material` },
  supportOrderState: { path: `${routePrefix}/${routeDomain}/:${routeParam}/supportState`, title: `ROUTES.${routeDomain}.state` }
}

export const adminSupportOrderRoutes: RouteObject[] = [
  {
    path: "",
    children: [
      {
        path: adminSupportOrderRouteNames.supportOrderList.path.replace(`${routePrefix}/`, ''),
        element: <AdminSupportOrderList />,
      },
      // {
      //   path: adminSupportOrderRouteNames.supportOrderDetails.path.replace(`${routePrefix}/`, ''),
      //   element: <AdminSupportOrderEdit />
      // },
    ]
  },
  {
    path: '',
    element: <SupportOrderLayout />,
    children: [
      {
        path: adminSupportOrderRouteNames.supportOrderDetails.path.replace(`${routePrefix}/`, ''),
        element: <AdminSupportOrderEdit />
      },
      {
        path: adminSupportOrderRouteNames.supportOrderInvoices.path.replace(`${routePrefix}/`, ''),
        element: <SupportOrderInvoices />
      },
      {
        path: adminSupportOrderRouteNames.supportOrderMaterialDeclaration.path.replace(`${routePrefix}/`, ''),
        element: <SupportOrderMaterial />
      },
      {
        path: adminSupportOrderRouteNames.supportOrderState.path.replace(`${routePrefix}/`, ''),
        element: <SupportOrderState />
      },
    ]
  }
]

import { Models } from "@alcome-rep/alcome-types";
import { ISupplierOrder, SupplierOrderItemType, SupplierOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces/supplier";
import { IUserAdmin, UserAdminRolesEnum } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { SupplierOrder, User } from "@alcome-rep/alcome-types/dist/models";
import { EditOutlined } from "@ant-design/icons";
import { Button, Table, message } from "antd";
import confirm from "antd/es/modal/confirm";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { selectUser } from "../../../../Auth/state/auth.slice";
import RegionalDelegateSection from "../../../../Dashboard/components/portal/RegionalDelegateSection";
import structureApi from "../../../../Structure/services/structure.api";
import adminSupplierOrderApi from "../../../../SupplierOrder/services/admin-supplierOrder.api";
import userApi from "../../../../User/services/user.api";
import AmountValue from "../../../../common/components/Fields/AmountValue";
import LoadingScreen from "../../../../common/components/LoadingScreen";
import Section from "../../../../common/components/Panels/Section";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks";
import { ROUTES } from "../../../../common/router";
import { closeDrawerAction, openDrawerAction } from "../../../../common/state/modal.slice";
import { getRoute } from "../../../../common/tools/router-tools";
import DeliveryComponent from "../../common/DeliveryComponent";
import AdminUpdateSupplierOrderPrice from "../AdminUpdateSupplierOrderPrice/AdminUpdateSupplierOrderPrice";
import AdminUpdateSupplierOrderDates from "../AdminUpdateSupplierOrderDates/AdminUpdateSupplierOrderDates";

const AdminSupplierOrderInfos = () => {

    const { supplierOrderId }: any = useParams();

    const [supplierOrder, setSupplierOrder] = useState<Models.SupplierOrder>()
    const [regionalDelegate, setRegionalDelegate] = useState<IUserAdmin>()
    const [department, setDepartment] = useState<string>();
    const [refresh, setRefresh] = useState<Date>();

    const user = useAppSelector(selectUser);
    const isAdmin = User.IsUserAdmin(user);
    const isFinancialManager = User.IsUserAdmin(user) && user.role === UserAdminRolesEnum.financialManager;

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!supplierOrderId)
            return

        adminSupplierOrderApi.getSupplierOrderById(supplierOrderId)
            .then(res => {
                setSupplierOrder(res)
            })

    }, [supplierOrderId, refresh])

    useEffect(() => {
        if (!supplierOrder)
            return;
        if (supplierOrder.structure) {
            structureApi.getStructureDepartment(supplierOrder!.structureId as string)
                .then(res => {
                    setDepartment(res)
                    userApi.findRegionalDelegate(`${res}`)
                        .then(resp => setRegionalDelegate(resp))
                })
        }
    }, [supplierOrder])

    const columns: any = [
        {
            title: 'Réf',
            dataIndex: ['product', 'reference'],
            align: 'left'
        },
        {
            title: 'Libellé',
            dataIndex: ['product', 'name'],
            render: (v: any, item: any) => <Link to={getRoute(ROUTES.ADMIN_PRODUCT_ROUTES.productDetails, { productId: item.product.id })}>{v}</Link>
        },
        {
            title: 'Qté',
            dataIndex: 'quantity'
        },
        // {
        //   title: 'Prix unitaire',
        //   dataIndex: ['product', 'price'],
        //   render: (value: number) => <AmountValue amount={value} />,
        // },
        {
            title: 'Total',
            width: '100px',
            dataIndex: ['price'],
            render: (value: number, item: SupplierOrderItemType) => <div><AmountValue amount={value} precision={2} /> </div>,
        },
        // {
        //     width: '200px',
        //     title: 'Frais de livraison',
        //     dataIndex: ['shippingFees'],
        //     align: 'right',
        //     render: (value: number, item: SupplierOrderItemType) => <AmountValue amount={value} precision={2} />,
        // },

    ]

    const updateOrderdata = async (supplierOrderData: ISupplierOrder) => {
        if (!supplierOrder)
            return;
        try {
            await adminSupplierOrderApi.updateSupplierOrder(supplierOrder.id, supplierOrderData)
            dispatch(closeDrawerAction())
            setRefresh(new Date())
            message.success('Bon de commande mis à jours !')
        } catch { }
    }


    const financialManagerValidateOrder = () => {
        if (!supplierOrder)
            return;

        confirm({
            title: 'Confirmation',
            content: "Souhaitez vous valider la commande ?",
            cancelText: 'Annuler',
            closable: true,
            okCancel: true,
            onOk: () => {
                adminSupplierOrderApi.financialManagerApproveOrder(supplierOrder.id as string)
                    .then(() => setRefresh(new Date()))
            },
            onCancel: () => { }
        })
    }

    const updateDates = () => {
        dispatch(openDrawerAction({
            component: <AdminUpdateSupplierOrderDates supplierOrder={supplierOrder as SupplierOrder} submit={(data) => {
                updateOrderdata(data);
                setRefresh(new Date());
            }} />,
            options: { title: "Modifier les dates du bon de commande" }
        }))
        setRefresh(new Date())
    }

    if (!supplierOrder)
        return <LoadingScreen />

    return (
        <>
            <div className="flex space-x-4">
                <div className="w-8/12">
                    <Section title="Livraison" actions={
                        !isFinancialManager ? <Button type="link" onClick={updateDates} icon={<EditOutlined />}></Button> : undefined
                    }>
                        <DeliveryComponent supplierOrder={supplierOrder} />
                    </Section>
                </div>
                {regionalDelegate && (
                    <div className="w-4/12">
                        <Section title="Affaire suivie par :">
                            <RegionalDelegateSection regionalDelegate={regionalDelegate} department={department as string} />
                        </Section>
                    </div>
                )}
            </div>

            <Section
                title="Produits commandés"
                mode="transparent"
                actions={<>
                    {
                        // isFinancialManager && supplierOrder.invoices && supplierOrder.invoices.length === 0 && (
                        isAdmin && supplierOrder.invoices && supplierOrder.invoices.length === 0 && (
                            <Button
                                type="link"
                                onClick={(e) => {
                                    dispatch(openDrawerAction({
                                        component: <AdminUpdateSupplierOrderPrice supplierOrder={supplierOrder} submit={(data) => {
                                            updateOrderdata(data);
                                            setRefresh(new Date());
                                        }} />,
                                        options: { title: "Modifier le prix du bon de commande" }
                                    }))
                                }}
                                icon={<EditOutlined />} />
                        )
                    }
                </>}
            >
                <Table<SupplierOrderItemType>
                    rowKey={'id'}
                    columns={columns}
                    dataSource={supplierOrder.items}
                    size="small"
                    footer={() => <div className="flex flex-col justify-center items-end">
                        <div><span className="font-semibold">Frais de livraison: </span><AmountValue amount={supplierOrder.shippingFees} precision={2} /></div>
                        <div><span className="font-semibold">TOTAL: </span><AmountValue amount={supplierOrder.total + supplierOrder.shippingFees} precision={2} /></div>
                    </div>}
                    pagination={false} />
            </Section>

            {/* {isFinancialManager && supplierOrder.status === SupplierOrderStatusEnum.pending && ( */}
            {isAdmin && supplierOrder.status === SupplierOrderStatusEnum.pending && (
                <Button type="primary" onClick={financialManagerValidateOrder}> Valider la commande</Button>
            )}
        </>
    )
}

export default AdminSupplierOrderInfos
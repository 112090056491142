import { RouteObject } from "react-router-dom";
import CreateSupportOrder from "../components/common/CreateSupportOrder/CreateSupportOrder";
import SupportOrderEdit from "../components/common/EditSupportOrder/EditSupportOrder";
import SupportOrderInvoices from "../components/common/SupportOrderInvoices/SupportOrderInvoices";
import SupportOrderList from "../components/common/SupportOrderList/SupportOrderList";
import SupportOrderMaterial from "../components/common/SupportOrderMaterial/SupportOrderMaterial";
import SupportOrderState from "../components/common/SupportOrderState/SupportOrderState";
import SupportOrderLayout from "../layouts/SupportOrderLayout";


const routePrefix = 'portal'
const routeDomain = 'supportOrder'
const routeParam = 'supportOrderId'

export const portalSupportOrderRouteNames = {
  supportOrderList: { path: `${routePrefix}/${routeDomain}`, title: `ROUTES.${routeDomain}.list` },
  supportOrderCreate: { path: `${routePrefix}/${routeDomain}/create`, title: `ROUTES.${routeDomain}.create` },
  supportOrderDetails: { path: `${routePrefix}/${routeDomain}/:${routeParam}/details`, title: `ROUTES.${routeDomain}.details` },
  supportOrderInvoices: { path: `${routePrefix}/${routeDomain}/:${routeParam}/invoices`, title: `ROUTES.${routeDomain}.invoices` },
  supportOrderMaterialDeclaration: { path: `${routePrefix}/${routeDomain}/:${routeParam}/materialDeclaration`, title: `ROUTES.${routeDomain}.material` },
  supportOrderState: { path: `${routePrefix}/${routeDomain}/:${routeParam}/supportState`, title: `ROUTES.${routeDomain}.state` }
}

export const portalSupportOrderRoutes: RouteObject[] = [
  {
    path: portalSupportOrderRouteNames.supportOrderList.path.replace(`${routePrefix}/`, ''),
    element: <SupportOrderList />
  },
  {
    path: portalSupportOrderRouteNames.supportOrderCreate.path.replace(`${routePrefix}/`, ''),
    element: <CreateSupportOrder />
  },
  {
    path: '',
    element: <SupportOrderLayout />,
    children: [
      {
        path: portalSupportOrderRouteNames.supportOrderDetails.path.replace(`${routePrefix}/`, ''),
        element: <SupportOrderEdit />
      },
      {
        path: portalSupportOrderRouteNames.supportOrderInvoices.path.replace(`${routePrefix}/`, ''),
        element: <SupportOrderInvoices />
      },
      {
        path: portalSupportOrderRouteNames.supportOrderMaterialDeclaration.path.replace(`${routePrefix}/`, ''),
        element: <SupportOrderMaterial />
      },
      {
        path: portalSupportOrderRouteNames.supportOrderState.path.replace(`${routePrefix}/`, ''),
        element: <SupportOrderState />
      },
    ]
  }

]

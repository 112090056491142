import { CodeInsee } from "@alcome-rep/alcome-types";
import { ChildCommune, StructureTypesEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Input, Select, Spin } from "antd";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import NextStepComponent from "../../../../Auth/components/Register/NextStepComponent";
import PageCanvas from "../../../../common/components/Panels/PageCanvas";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks";
import { ROUTES } from "../../../../common/router";
import msg from "../../../../common/services/toast";
import { getRoute } from "../../../../common/tools/router-tools";
import structureApi from "../../../../Structure/services/structure.api";
import { selectCurrentStructure, updateStructureCommunesAction } from "../../../../Structure/state/structure-portal.slice";
import { selectCurrentContract } from "../../../state/contract-portal.slice";

const RegisterAllCommunes = () => {
  // const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch();

  const structure = useAppSelector(selectCurrentStructure)
  const contract = useAppSelector(selectCurrentContract)

  if (!structure || !contract)
    throw new Error()

  const [communes, setCommunes] = useState<ChildCommune[]>(structure?.communes || [])
  const [newItem, setNewItem] = useState<ChildCommune>({} as ChildCommune)

  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<CodeInsee[]>([]);

  const deleteCommune = (index: number) => () => {
    const newChildren = [...communes];
    newChildren.splice(index, 1)
    setCommunes([...newChildren])
  }

  const addCommune = () => {
    if (newItem.name && newItem.email && newItem.siren) {
      setCommunes([...communes, newItem])
      setNewItem({} as any)
    }
    if (!newItem.email) {
      msg.error('Veuillez renseigner une adresse e-mail.')
    }
  }

  const save = () => {
    dispatch(updateStructureCommunesAction({ communes }))
    return navigate(getRoute(ROUTES.PORTAL_REGISTER_CONTRACT_ROUTES.STEPS_GRP_COMMUNES.signatory))
  }


  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      if (value) {
        setFetching(true);
        structureApi.getInseeCodes('^' + value, StructureTypesEnum.commune)
          .then(newOptions => {
            setOptions(newOptions);
            setFetching(false);
          });

      }
    };

    return loadOptions;
  }, []);

  const selectCode = async (item: any) => {
    const isRegistered = await structureApi.isRegistered(item.label)
    if (isRegistered) {
      msg.error('Un compte est déjà établi pour ce code. Merci de choisir une autre commune ou bien de contacter notre support.')
    } else {
      const option = options[item.key];
      setNewItem({
        ...newItem,
        name: option.nom,
        siren: option.code,
        population: option.population,
        type: option.type
      })
    }
  }

  const optionsEl = options.map((d, i) => <Select.Option key={i} >{d.code}</Select.Option>);

  return <>
    <PageCanvas title="Les communes du groupement">
      <div className='text-alc-blue italic'>
        <p>
          Renseignez le code INSEE de votre commune, ajoutez l'addresse mail et n'oubliez pas de cliquer sur le bouton "+".
          Enfin enregistrez votre saisie.
        </p>
      </div>

      <table className='table-personnes-publiques'>
        <thead>
          <tr className=''>
            <th>Code INSEE</th>
            <th>Nom</th>
            <th>Email</th>
            <th>Population</th>
            <th>Type de commune</th>
            {/* <th></th> */}
          </tr>
        </thead>
        <tbody>
          {communes.length === 0 && (
            <tr className='empty'>
              <td colSpan={4}>
                <div className='text-center font-semibold italic font-sm border-y-1 border-white'>Aucune commune enregistrée</div>
              </td>
            </tr>
          )}
          {communes.map((child, i) => (
            <tr key={i} className="">
              <td>{child.siren}</td>
              <td>{child.name}</td>
              <td>{child.email}</td>
              <td>{child.type}</td>
              <td >{child.population}</td>
              <td><Button onClick={deleteCommune(i)} icon={<DeleteOutlined className='text-alc-danger'></DeleteOutlined>}></Button></td>
            </tr>
          ))}
          <tr>
            <td  >
              <Select
                showSearch
                labelInValue
                placeholder="code"
                value={{ value: newItem.siren }}
                filterOption={false}
                suffixIcon={null}
                onSearch={debounceFetcher}
                onChange={selectCode}
                style={{ width: '100%', minWidth: '60px' }}
                notFoundContent={fetching ? <Spin size="small" /> : null}
              >
                {optionsEl}
              </Select>
            </td>
            <td>
              <Input readOnly value={newItem.name} placeholder='nom de la commune' onChange={e => setNewItem({ ...newItem, name: e.target.value })} />
            </td>
            <td>
              <Input value={newItem.email} type="email" placeholder='macommune@commune.fr' onChange={e => setNewItem({ ...newItem, email: e.target.value })} />
            </td>
            <td className="w-12">
              <Input readOnly value={newItem.population} type="text" placeholder='Population' />
            </td>
            <td>
              <Input readOnly value={newItem.type} type="text" placeholder='type' />
            </td>
            <td><Button onClick={addCommune} icon={<PlusCircleOutlined className='text-alc-success'></PlusCircleOutlined>}></Button></td>
          </tr>
        </tbody>
      </table>

      <div>
        <hr className="my-4" />
      </div>

      <NextStepComponent submit={save} disable={communes.length === 0} />

    </PageCanvas>
  </>
}

export default RegisterAllCommunes;
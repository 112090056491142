import { IProspect } from "@alcome-rep/alcome-types/dist/interfaces/crm";
import { BaseCommune } from "@alcome-rep/alcome-types/dist/models";
import { numericFormatter } from "react-number-format";

type PropsType = {
  structure: BaseCommune | IProspect
}

const CommunePopulation = ({ structure }: PropsType) => {
  return <span>{structure.population ? numericFormatter((structure.population).toFixed(2), { thousandSeparator: ' ', decimalScale: 0 }) : ' - '}</span>
}

export default CommunePopulation;
import { AnnualReviewStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces"
import { UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user"
import { AnnualReview } from "@alcome-rep/alcome-types/dist/models"
import { EditOutlined } from "@ant-design/icons"
import { Button, Tag } from "antd"
import { format } from "date-fns"
import { fr } from 'date-fns/locale'
import { t } from "i18next"
import moment from "moment"
import "moment/locale/fr"
import FeatureAvailable from "../../../../common/components/FeaturesAvailable/FeatureAvailable"
import Figure from "../../../../common/components/Panels/Figure"
import UpdateStatus from "../../../../common/components/admin/updateEntityStatus/UpdateEntityStatus"
import { useAppDispatch } from "../../../../common/hooks"
import { openDrawerAction } from "../../../../common/state/modal.slice"

moment().locale("fr");

type PropsType = {
    annualReview: AnnualReview
}

const AnnualReviewFigures = ({ annualReview }: PropsType) => {

    const dispatch = useAppDispatch();

    /** Scoped statuses */
    const annualReviewStatusLimited = annualReview.status <= AnnualReviewStatusEnum.completed ? annualReview.status : AnnualReviewStatusEnum.completed;
    const annualReviewStatusStatementLimited = Math.min(Math.max(annualReview.status, AnnualReviewStatusEnum.statement_pending), AnnualReviewStatusEnum.statement_accepted);
    const annualReviewStatusOrderLimited = Math.min(Math.max(annualReview.status, AnnualReviewStatusEnum.statement_accepted), AnnualReviewStatusEnum.statement_paid);

    const annualReviewStatusActions = <FeatureAvailable userType={UserTypeEnum.admin} featureName='admin.annualReview.changeStatus'>
        <Button className="btn-link text-alc-blue font-semibold" onClick={_ => dispatch(openDrawerAction({
            component: <UpdateStatus entityName='annualReview' entity={annualReview}></UpdateStatus>,
            options: {
                title: "Modifier le statut du bilan",
            }
        }))} icon={<EditOutlined />} />
    </FeatureAvailable>;

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
            <Figure title="Statut du bilan" actions={annualReviewStatusActions} value={
                <Tag className="space-x-2" color={annualReview.status >= AnnualReviewStatusEnum.validated ? "green" : t(`UI.TAG.AnnualReviewStatusEnum.${AnnualReviewStatusEnum[annualReviewStatusLimited]}`)}>
                    {annualReview.status < AnnualReviewStatusEnum.validated && t(`TYPES.ANNUAL_REVIEW.AnnualReviewStatusEnum.${AnnualReviewStatusEnum[annualReviewStatusLimited]}`)}
                    {annualReview.status >= AnnualReviewStatusEnum.validated && annualReview.validatedDate && <>{format(new Date(annualReview.validatedDate), "'Validé le 'd MMMM yyyy'", { locale: fr })}</>}
                </Tag>
            } />

            {annualReview.status >= AnnualReviewStatusEnum.validated && (
                <Figure title="Décompte liquidatif" value={
                    <Tag className="space-x-2" color={annualReview.status === AnnualReviewStatusEnum.validated ? "gray" : t(`UI.TAG.AnnualReviewStatusEnum.${AnnualReviewStatusEnum[annualReviewStatusStatementLimited]}`)}>
                        {annualReview.status === AnnualReviewStatusEnum.validated && "Décompte liquidatif en attente"}
                        {annualReview.status === AnnualReviewStatusEnum.statement_pending && <>{t(`TYPES.ANNUAL_REVIEW.AnnualReviewStatusEnum.${AnnualReviewStatusEnum[annualReviewStatusStatementLimited]}`)}</>}
                        {annualReview.status === AnnualReviewStatusEnum.statement_refused && annualReview.statementRefused && <>{format(new Date(annualReview.statementRefused), "'Refusé le 'd MMMM yyyy'", { locale: fr })}</>}
                        {annualReview.status >= AnnualReviewStatusEnum.statement_accepted && annualReview.statementAccepted && <>{format(new Date(annualReview.statementAccepted), "'Accepté le 'd MMMM yyyy'", { locale: fr })}</>}
                    </Tag>
                } />
            )}

            {annualReview.status >= AnnualReviewStatusEnum.statement_accepted && (
                <Figure title="Titre de recette" value={
                    <Tag className="space-x-2" color={t(annualReview.status === AnnualReviewStatusEnum.statement_accepted ? "gray" : `UI.TAG.AnnualReviewStatusEnum.${AnnualReviewStatusEnum[annualReviewStatusOrderLimited]}`)}>
                        {annualReview.status === AnnualReviewStatusEnum.statement_accepted && "Titre de recette en attente"}
                        {annualReview.status === AnnualReviewStatusEnum.order_sent && "Titre de recette envoyé"}
                        {annualReview.status === AnnualReviewStatusEnum.order_validate && annualReview.revenueOrderAccepted && <>{format(new Date(annualReview.revenueOrderAccepted), "'Validé le 'd MMMM yyyy'", { locale: fr })}</>}
                        {annualReview.status === AnnualReviewStatusEnum.order_refuse && annualReview.revenueOrderRefused && <>{format(new Date(annualReview.revenueOrderRefused), "'Refusé le 'd MMMM yyyy'", { locale: fr })}</>}
                        {annualReview.status === AnnualReviewStatusEnum.statement_accepted && annualReview.paymentExpectedDate && <>{format(new Date(annualReview.paymentExpectedDate), "'Paiement attendu le 'd MMMM yyyy'", { locale: fr })}</>}
                        {annualReview.status >= AnnualReviewStatusEnum.statement_paid && annualReview.paymentDate && <>{format(new Date(annualReview.paymentDate), "'Payé le 'd MMMM yyyy'", { locale: fr })}</>}
                    </Tag>
                } />
            )}
        </div>
    );
}

export default AnnualReviewFigures;
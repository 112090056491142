import { Models } from "@alcome-rep/alcome-types"
import { IUser, UserAdminRolesEnum, UserPortalRolesEnum, UserSupplierRolesEnum, UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user"
import { Button, Form, Input, Select } from "antd"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import adminSupplierCompanyApi from "../../../../SupplierCompany/services/admin-supplierCompany.api"
import useFormRules from "../../../../common/hooks/useFormRules"
import { enumToArrayNumber } from "../../../../common/tools/global-tools"
import { county } from "../../../../common/tools/county.constants"
import { User } from "@alcome-rep/alcome-types/dist/models"

type PropsType = {
  submit: (userData: IUser) => void,
  data: Models.User,
  deleteUser: () => void
}

const AdminUserDetailsForm = ({ submit, data, deleteUser }: PropsType) => {

  const { t } = useTranslation()
  const { required } = useFormRules();

  const isSupplier = data.userType === UserTypeEnum.supplier
  const isUserAdmin = User.IsUserAdmin(data)
  const [suppliers, setSuppliers] = useState<{ value: string, label: string }[]>([])
  const [selectedItem, setSelectedItem] = useState<string | null>(null)

  const fetchUser = useMemo(() => {
    return (search?: string) => {
      const q: any = {};
      if (search && search.length >= 2)
        q.name = '/' + search + '/'
      adminSupplierCompanyApi.findAllSuppliers(q, 1, -1)
        .then(r => setSuppliers(r.rows.map(row => ({
          value: row.id!,
          label: `${row.name}`
        }))))
    }
  }, [setSuppliers])

  useEffect(() => {
    fetchUser()
  }, [fetchUser]);

  const onSearch = async (value: string) => {
    fetchUser(value)
  }

  const clear = () => {
    setSuppliers([])
    setSelectedItem(null)
  }

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const getRoleOptions = () => {
    let values: any;
    switch (data.userType) {
      case UserTypeEnum.portal:
        const constPortalRoles = enumToArrayNumber(UserPortalRolesEnum).map(role => {
          return <Select.Option key={role} value={role}>{t(`TYPES.ROLES.PORTAL.${UserPortalRolesEnum[role as number]}`)}</Select.Option>
        })
        values = constPortalRoles
        break;
      case UserTypeEnum.admin:
        const constAdminRoles = enumToArrayNumber(UserAdminRolesEnum).map(role => {
          return <Select.Option key={role} value={role}>{t(`TYPES.ROLES.ADMIN.${UserAdminRolesEnum[role as number]}`)}</Select.Option>
        })
        values = constAdminRoles
        break;
      case UserTypeEnum.supplier:
        const constSupplierRoles = enumToArrayNumber(UserSupplierRolesEnum).map(role => {
          return <Select.Option key={role} value={role}>{t(`TYPES.ROLES.SUPPLIER.${UserSupplierRolesEnum[role as number]}`)}</Select.Option>
        })
        values = constSupplierRoles
        break;

      default:
        break;
    }
    return values
  }

  return (
    <Form
      name="basic"
      layout='vertical'
      onFinish={submit}
      initialValues={data}
    >
      <Form.Item
        label="Prénom de l'utilisateur"
        name="firstName"
        className='mt-3'
        rules={[required]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Nom de l'utilisateur"
        name="lastName"
        rules={[required]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Role de l'utilisateur"
        name="role"
        rules={[required]}
      >
        <Select>
          {getRoleOptions()}
          {/* {getRoleOptions()
                        .filter(k => !isNaN(+k))
                        // .filter(r => r >= UserRolesEnum.structureAdmin)
                        .map(role => (
                            <Select.Option key={role} value={role}>{t(`types.roles.${UserRolesEnum[role as number]}`)}</Select.Option>
                        ))} */}
        </Select>
      </Form.Item>
      {isSupplier && (
        <Form.Item
          label="Sélectionnez un fournisseur"
          name="supplierCompanyId"
          rules={[required]}
        >
          <Select
            showSearch
            placeholder="Selectionnez un fournisseur"
            optionFilterProp="children"
            onSearch={onSearch}
            filterOption={filterOption}
            onClear={clear}
            onSelect={selectedItem as any}
            value={selectedItem}
            options={suppliers}
          >
          </Select>
        </Form.Item>
      )}
      <Form.Item
        label="Email de l'utilisateur"
        name="email"
        rules={[required]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Télephone de l'utilisateur"
        name="phone"
        rules={[required]}
      >
        <Input></Input>
      </Form.Item>
      {isUserAdmin && (
        <Form.Item
          label="Département(s) à charges"
          name="departments"
        >
          <Select
            placeholder="Liste des départements"
            mode="multiple"
            options={county.map(v => ({ label: `${v.name} ${v.code}`, value: v.code }))}
            allowClear
            filterOption={(input, option) => {
              const rexp = new RegExp('^' + input, 'i');
              return rexp.test(option?.label ?? '') || rexp.test(option?.value ?? '')
            }}
          />

        </Form.Item>
      )}

      <Form.Item>
        <div>
          <Button htmlType="submit" type="primary" >{t('COMMON.save')}</Button>
          <Button
            onClick={deleteUser}
            danger
            type="link"
          >
            Supprimer l'utilisateur
          </Button>
        </div>
      </Form.Item>
    </Form>
  )
}

export default AdminUserDetailsForm
import { ApiListResult, Models } from "@alcome-rep/alcome-types";
import { IAmendment } from "@alcome-rep/alcome-types/dist/interfaces";
import ApiService from "../../common/services/apiService";
import apiClient from "../../common/services/apiClient";

const AMENDMENT_PORTAL_PATH_API = 'portal/amendment';

class AmendmentPortalApi extends ApiService<IAmendment> {
  constructor() {
    super(AMENDMENT_PORTAL_PATH_API)
  }

  getAmendmentToSign = () => this.custom<ApiListResult<IAmendment>>('get', 'amendmentToSign')
    .then(r => ({
      ...r,
      rows: r.rows.map(row => new Models.Amendment(row))
    }))

  getAmendmentDoc = () => apiClient({
    url: `${AMENDMENT_PORTAL_PATH_API}/amendmentFileGenerated/fileDoc`,
    method: 'get',
    responseType: 'blob'
  }).then(r => r.data)
  

  generateAmendmentFile = (amendmentId: string) => apiClient({
    url: `${AMENDMENT_PORTAL_PATH_API}/${amendmentId}/generate`,
    method: 'post',
    responseType: 'blob'
  }).then(r => r.data)

}

const amendmentPortalApi = new AmendmentPortalApi();
export default amendmentPortalApi;
import { ApiListResult, Models } from "@alcome-rep/alcome-types";
import { IUser, UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { defaultPagination } from "../../common/app-constants";
import ApiService, { ApiSortType } from "../../common/services/apiService";
import { AppUser } from "../types";
import { message } from "antd";

const USER_ADMIN_PATH_API = 'admin/user';

class UserAdminApi extends ApiService<AppUser> {
  constructor() {
    super(USER_ADMIN_PATH_API)
  }

  /**
   * @description Find all users as Admin
   * @query query + pagination 
   * @returns Return user list
   */
  findAllUsers(query: any = {}, page: number = defaultPagination.page, limit: number = defaultPagination.pageSize, sort?: ApiSortType)
    : Promise<ApiListResult<IUser>> {
    return this.find({ query, page, pageSize: limit, sort })
  }

  /**
   * @description Find all portal users
   * @param param0 
   * @returns List of portal users
   */
  findAllUserPortal(query: any = {}, page: number = defaultPagination.page, limit: number = defaultPagination.pageSize, sort?: ApiSortType)
    : Promise<{ count: number; rows: Models.UserPortal[]; }> {
    query.userType = UserTypeEnum.portal;
    return this.findAllUsers(query, page, limit, sort)
      .then(res => ({
        count: res.count,
        rows: res.rows.map(row => new Models.UserPortal(row as Models.UserPortal))
      }))
  }

  /**
   * @description Find all users admin
   * @param param0 
   * @returns List of admins
   */
  findAllUserAdmin(query: any = {}, page: number = defaultPagination.page, limit: number = defaultPagination.pageSize, sort?: ApiSortType)
    : Promise<{ count: number; rows: Models.UserAdmin[]; }> {
    query.userType = UserTypeEnum.admin;
    return this.findAllUsers(query, page, limit, sort)
      .then(res => ({
        count: res.count,
        rows: res.rows.map(row => new Models.UserAdmin(row as Models.UserAdmin))
      }))
  }

  /**
   * @description get user by id
   * @param userId 
   * @returns Return target user infos
   */
  getUserById(userId: string) {
    return this.get(userId)
  }

  /**
   * @description Update user by id
   * @param userId 
   * @param data 
   * @returns Return target user infos
   */
  updateUserById(userId: string, data: Partial<Models.User>) {
    return this.update(userId, data)
  }

  /**
   * @description Invalidate user token
   * @param userId 
   * @returns Return target user infos 
   */
  invalidateToken(userId: string) {
    return this.custom('put', `${userId}/invalidateToken`)
  }

  /**
   * @description Delete user
   * @param userId 
   * @returns Return target user infos
   */
  softDeleteUserBySuperAdmin(userId: string) {
    return this.delete(userId)
  }

  /**
   * @description Admin create User
   * @param data 
   * @returns Return created user
   */
  adminCreateAdminAccount(data: Partial<Models.User>) {
    return this.custom('post', 'createAdminAccount', undefined, data)
  }

  /**
   * @description Admin create User
   * @param data 
   * @returns Return created user
   */
  adminCreatePortalAccount(data: Partial<Models.User>) {
    return this.custom('post', 'createAccount', undefined, data)
  }

  /**
   * @description Admin create crm user
   * @param data 
   * @returns Return created crm user
   */
  adminCreateCrmAccount(data: Partial<Models.User>) {
    return this.custom('post', 'createCrmAccount', undefined, data)
  }

  /**
   * @description Admin create Supplier User
   * @param data 
   * @returns Return created supplier user
   */
  adminCreateSupplierAccount(data: Partial<Models.User>) {
    return this.custom('post', 'createSupplierAccount', undefined, data)
  }

  /**
   * @description Admin create Supplier User
   * @param data 
   * @returns Return created supplier user
   */
  adminCreateMemberAccount(data: Partial<Models.User>) {
    return this.custom('post', 'createMemberAccount', undefined, data)
  }

  /**
     * @description Admin user update his profil
     * @param userId 
     * @param data 
     * @returns Return user updated
     */
  updateMe(data: Partial<Models.UserAdmin>) {
    return this.custom<Models.UserAdmin>('put', 'me', undefined, data)
      .then(r => {
        if (r) {
          message.success('Profil mis à jour')
          return new Models.User(r)
        }
      })
      .catch(() =>
        message.error('Erreur lors de la modification du profil')
      )
  }

}

const userAdminApi = new UserAdminApi();
export default userAdminApi;
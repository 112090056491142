import { IUser } from '@alcome-rep/alcome-types/dist/interfaces/user';
import { Form, Input } from 'antd';
import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../common/hooks';
import useFormRules from '../../../common/hooks/useFormRules';
import { ROUTES } from '../../../common/router';
import { locallySaveUserAction, selectLocallySavedUser } from '../../state/register.slice';
import NextStepComponent from './NextStepComponent';


const RegisterUserProfile = () => {

    const navigate = useNavigate()
    const user = useAppSelector(selectLocallySavedUser)
    const dispatch: Dispatch<any> = useDispatch()

    const submit = (userData: IUser) => {
        dispatch(locallySaveUserAction(userData))
        return navigate(ROUTES.REGISTER_ROUTES.SUBMIT_STEP)
    }

    const { required, phoneNumber } = useFormRules();

    return (
        <>
            <div>
                <h2 className='mb-4'>Votre profil</h2>
                <p>Ces informations vous concerne. Les informations que vous allez renseigner ici vous permettrons de vous connecter au portail par la suite.
                    <br />
                    <br />
                    <b>Veuillez faire attention aux informations de contact renseignées ci-dessous.</b>
                </p>
                <Form
                    name="basic"
                    layout='vertical'
                    onFinish={submit}
                    initialValues={user!}
                >
                    <div className='grid grid-cols-2 gap-x-4'>

                        <Form.Item
                            label="Votre prénom"
                            name="firstName"
                            rules={[required]}
                        >
                            <Input></Input>
                        </Form.Item>
                        <Form.Item
                            label="Votre nom"
                            name="lastName"
                            rules={[required]}
                        >
                            <Input></Input>
                        </Form.Item>
                        <Form.Item
                            label="Votre fonction"
                            name="position"
                            rules={[required]}
                        >
                            <Input></Input>
                        </Form.Item>
                        <Form.Item
                            label="Votre téléphone"
                            name="phone"
                            rules={[required, phoneNumber]}
                        >
                            <Input></Input>
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <NextStepComponent />
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default RegisterUserProfile;
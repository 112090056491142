import { Models } from "@alcome-rep/alcome-types";
import { SupplierOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces/supplier";
import { SupplierOrder } from "@alcome-rep/alcome-types/dist/models";
import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import { TFunction } from "i18next";
import { Link, NavigateFunction } from "react-router-dom";
import AmountValue from "../../../../common/components/Fields/AmountValue";
import DateField from "../../../../common/components/Fields/DateField";
import { ROUTES } from "../../../../common/router";
import { getRoute } from "../../../../common/tools/router-tools";
import SupplierOrderStatus from "../../common/SupplierOrderStatus";

export const supplierOrderListColumns = (query: any, t: TFunction, navigate: NavigateFunction): ColumnsType<Models.SupplierOrder> => [
  {
    fixed: 'left',
    width: "40px",
    align: 'center',
    render: (value: any, _, i) => {
      return (
        <Button
          id={`action-button-${i}`}
          type="link"
          icon={<EditOutlined />}
          onClick={(_e) =>
            navigate(getRoute(ROUTES.ADMIN_SUPPLIER_ORDER_ROUTES.supplierOrderInfos, { supplierOrderId: value.id }))}
        />
      )
    }
  },
  {
    title: 'Date',
    width: '100px',
    dataIndex: 'orderDate',
    align: 'left',
    className: 'font-semibold',
    render: (v) => <DateField value={v} />
  },
  {
    title: 'N°',
    width: '150px',
    dataIndex: 'supplierOrderNumber',
    align: 'left',
    className: 'font-semibold',
  },
  {
    title: 'Fournisseur',
    dataIndex: ['supplierCompany', 'name'],
    align: 'left',
    render: (supplierName: string, order: SupplierOrder) =>
      <Link to={getRoute(ROUTES.ADMIN_SUPPLIER_COMPANY_ROUTES.supplierCompanyDetails, { supplierCompanyId: order.supplierCompanyId })}>{supplierName}</Link>,
  },
  {
    title: 'Structure',
    dataIndex: ['structure', 'name'],
    align: 'left',
    render: (structureName: string, order: SupplierOrder) =>
      <Link to={getRoute(ROUTES.ADMIN_STRUCTURE_ROUTES.structureDetails, { structureId: order.structureId })}>{structureName}</Link>,
  },
  {
    title: "Demande de dispositifs",
    dataIndex: ['acquisitionOrder', 'acquisitionOrderNumber'],
    align: 'left',
    render: (acquisitionOrderNumber: string, order: SupplierOrder) =>
      <Link to={getRoute(ROUTES.ADMIN_ACQUISITION_ORDER_ROUTES.acquisitionOrderDetails, { acquisitionOrderId: order.acquisitionOrderId })}>{acquisitionOrderNumber}</Link>,
  },
  {
    title: 'Livraison',
    width: '100px',
    dataIndex: 'shippingFees',
    render: (v) => <AmountValue amount={v} precision={2}/>,
  },
  {
    title: 'Total',
    width: '100px',
    dataIndex: 'total',
    render: (v) => <AmountValue amount={v} precision={2}/>,
  },
  {
    title: 'Status',
    fixed: 'right',
    dataIndex: 'status',
    render: (status: SupplierOrderStatusEnum) => <SupplierOrderStatus status={status} />
  },

];
import { Models } from "@alcome-rep/alcome-types";
import { IContract } from "@alcome-rep/alcome-types/dist/interfaces";
import { EditOutlined } from "@ant-design/icons";
import { Button, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { differenceInDays } from "date-fns";
import { TFunction } from "i18next";
import { Link, NavigateFunction } from "react-router-dom";
import ContractStatus from "../../../Contract/components/admin/ContractData/ContractStatus";
import StructureType from "../../../Structure/components/common/StructureType";
import DateField from "../../../common/components/Fields/DateField";
import { ROUTES } from "../../../common/router";
import { getRoute } from "../../../common/tools/router-tools";

export const prospectListColumns = (query: any, t: TFunction, navigate: NavigateFunction): ColumnsType<Models.Prospect> => [
  {
    title: '',
    fixed: 'left',
    // width: 50,
    render: (value: any) => {
      return (
        <div className='flex space-x-4'>
          <Button
            icon={<EditOutlined />}
            type="link"
            onClick={(_) => {
              navigate(getRoute(ROUTES.ADMIN_CRM.ADMIN_PROSPECT_ROUTES.prospectDetails, { prospectId: value.id }));
            }} />
        </div>
      )
    }
  },
  {
    fixed: 'left',
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    // align: 'center' as const,
    // sorter: {},
    // defaultSortOrder: 'descend',
    render: (value: any) => <DateField value={value} />,
  },
  {
    fixed: 'left',
    title: 'Nom',
    // width: '200px',
    dataIndex: 'name',
    key: 'name',
    align: 'center' as const,
    render: (value: any, item: any) =>
      <Link to={getRoute(ROUTES.ADMIN_CRM.ADMIN_PROSPECT_ROUTES.prospectDetails, { prospectId: item.id })}
        className='font-semibold text-center '>{value}
      </Link>
  },
  {
    title: "Siren",
    dataIndex: 'siren',
    key: 'siren',
    align: 'center' as const,
  },
  {
    title: "Dpt",
    dataIndex: 'department',
    key: 'department',
    align: 'center' as const,
  },
  {
    title: 'Type',
    // dataIndex: 'structureType',
    key: 'structureType',
    align: 'center' as const,
    render: (value: any) => <StructureType structure={value} mode="tag" />
  },
  {
    title: 'Nettoiement',
    dataIndex: 'inChargeOfCleaning',
    key: 'inChargeOfCleaning',
    align: 'center' as const,
    render: (value: any) => {
      if (value === true) return (<Tag color='green'>Oui</Tag>)
      else if (value === false) return (<Tag color='red'>Non </Tag>)
      else return <span>-</span>
    },
  },
  // {
  //   title: "Téléphone",
  //   dataIndex: "phone",
  //   key: "phone"
  // },
  // {
  //   title: "Email",
  //   dataIndex: "email",
  //   key: "email"
  // },
  {
    title: 'Prospect',
    dataIndex: 'prospectType',
    key: 'prospectType',
    align: 'center' as const,
    render: (value: any) => {
      if (value === 'prospection') return (<Tag color='blue'>Prospection</Tag>)
      else return (<Tag color='green'>Contact entrant</Tag>)
    },
  },
  {
    title: 'Intérêt',
    dataIndex: 'interestValue',
    key: 'interestValue',
    align: 'center' as const,
    render: (value: any) => {
      if (value === 'interested') return (<Tag color='green'>Intéressé</Tag>)
      else if (value === 'neutral') return (<Tag color='orange'>Neutre</Tag>)
        else return (<Tag color='red'>Non Intéressé</Tag>)
    },
  },
  {
    title: 'Date contrat',
    dataIndex: "contractDate",
    key: "contractDate",
    align: 'center' as const,
    render: (value: any) => <DateField value={value} />
  },
  {
    title: 'Inscrit depuis',
    dataIndex: "contractDate",
    key: "contractDate",
    align: 'center' as const,
    sorter: {},
    render: (value: any) => {
      if (value) {
        const daysDiff = differenceInDays(new Date(), new Date(value))
        return (
          <div className='font-semibold'>
            {daysDiff} jours
          </div>
        )
      }
    }
  },
  {
    title: 'Statut contrat',
    align: 'center' as const,
    key: 'contractStatus',
    dataIndex: 'contractStatus',
    render: (value: any) => {
      if(value){
        return <ContractStatus contract={{ status: value } as IContract} />
      }else{
        return <Tag color="orange">Non contractualisé</Tag>
      }
     }
  },

];
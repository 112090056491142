import { ILibraryDoc, LibraryDocTypesEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { Card } from "antd";
import Meta from "antd/lib/card/Meta";
import { TablePaginationConfig } from "antd/lib/table";
import { FilterValue } from "antd/lib/table/interface";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { selectCurrentStructure } from "../../../../Structure/state/structure-portal.slice";
import PageCanvas from "../../../../common/components/Panels/PageCanvas";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks";
import { closeModalAction, openModalAction } from "../../../../common/state/modal.slice";
import { fallBackImage } from "../../../../common/tools/global-tools";
import libraryDocApi from "../../../services/libraryDoc.api";
import portalLibraryDocApi from "../../../services/portal-libraryDoc.api";
import LibraryDocCgu from "../LibraryDocCgu/LibraryDocCgu";

const LibraryDocList = () => {

  const dispatch = useAppDispatch();

  const structure = useAppSelector(selectCurrentStructure);
  const [data, setData] = useState<ILibraryDoc[]>([]);
  const [, setCount] = useState<number>(1);
  const [, setCurrentPage] = useState<number>(1);
  const [, setPageSize] = useState<number>(30);
  const [, setCurrentQuery] = useState<any>({});
  const [, setloading] = useState<boolean>(true);
  const [downloading, setDownloading] = useState<ILibraryDoc>()

  const getData = useMemo(() => async (
    pagination: TablePaginationConfig = { pageSize: 30, current: 1 },
    filters?: Record<string, FilterValue | null>
  ) => {
    if (structure) {
      const data = await portalLibraryDocApi.userLibraryDocList({})
      setData(data.rows)
      setCount(data.count)
      setCurrentPage(pagination.current as number)
      setPageSize(pagination.pageSize as number)
      setCurrentQuery(filters)
      setloading(false)
    }
  }, [structure])

  useEffect(() => {
    getData();
  }, [getData]);


  const downloadDoc = (libDoc: ILibraryDoc) => {
    dispatch(openModalAction({
      component:<LibraryDocCgu libDoc={libDoc} finalizeDl={finalizeDl}/>,
      options:{
        title:"Condition générale d'utilisation"
      }
    }))
  }

  const finalizeDl = (libDoc: ILibraryDoc) => {
    dispatch(closeModalAction())
    setDownloading(libDoc)
    libraryDocApi.downloadLibraryDoc(libDoc.id as string, `${libDoc.name}.${libDoc.ext}`)
      .then(() => {
        setDownloading(undefined)
      })
  }

  const typeCard = (docTypeFilter: LibraryDocTypesEnum) => {
    const card = data && data.filter(d => d.docType === docTypeFilter).map((libDoc, i) => {
      const action = downloading === libDoc ? [
        <span > En cours de téléchargement <LoadingOutlined key="downloading" /></span>
      ] : [<span > Télécharger <DownloadOutlined key="setting" /></span>]
      return (<div key={i}>
        <Card
          onClick={_ => downloadDoc(libDoc)}
          className="shadow kit-card"
          hoverable
          cover={libDoc.thumbnail ?
            (<img className="h-48 object-cover" crossOrigin='anonymous' src={`${process.env.REACT_APP_API_URL}/libraryDoc/download/thumbnail/${libDoc.id}?rand=${Math.random()}`} alt={`${libDoc.description}`} />)
            : (<img alt='default thumbnail' className="h-48 object-cover" crossOrigin='anonymous' src={fallBackImage} />)
          }
          actions={action}
          extra={
            <div className="flex justify-center w-full">
              <div className='font-semibold text-alc-blue'>{t(`TYPES.LIBRARYDOC.LibraryDocTypesEnum.${libDoc.docType}`)}</div>
            </div>
          }
        >
          <Meta title={libDoc.name} description={libDoc.description} />
        </Card>
      </div>)
    })

    return card
  }

  return (
    <>
      <PageCanvas title="Les kits de communications">
        {Object.values(LibraryDocTypesEnum).map((o: LibraryDocTypesEnum) => {
          let title: string = "";
          let infoCount: number = 0;
          let kitCount: number = 0;
          let methodCount: number = 0;
          if (o === LibraryDocTypesEnum.info) {
            title = "Documents d'information"
            infoCount = data.filter(d => d.docType === o).map(d => d).length
          } else if (o === LibraryDocTypesEnum.kit) {
            title = "Kits de sensibilisation"
            kitCount = data.filter(d => d.docType === o).map(d => d).length
          } else if (o === LibraryDocTypesEnum.methodological_sheet) {
            title = "Fiches méthodologique"
            methodCount = data.filter(d => d.docType === o).map(d => d).length
          }
          return (
            <div>
              <div className="mb-6">
                {infoCount > 0 && (
                  <div className="text-alc-blue font-semibold text-lg mb-4">{title}</div>
                )}
                {kitCount > 0 && (
                  <div className="text-alc-blue font-semibold text-lg mb-4">{title}</div>
                )}
                {methodCount > 0 && (
                  <div className="text-alc-blue font-semibold text-lg mb-4">{title}</div>
                )}
                <div className="flex space-x-6">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4">
                    {typeCard(o)}
                  </div>
                </div>
              </div>
            </div >
          )
        })}
      </PageCanvas>

    </>

  );
};

export default LibraryDocList;
import { UserAdminRolesEnum, UserMemberRolesEnum, UserPortalRolesEnum, UserSupplierRolesEnum, UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { Button, Form, FormInstance, Input, Select } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import adminSupplierCompanyApi from "../../../../SupplierCompany/services/admin-supplierCompany.api";
import useFormRules from "../../../../common/hooks/useFormRules";

type ComponentProps = {
  userType: UserTypeEnum
  form: FormInstance,
  createUser: (formData: any) => void
}

const AdminCreateAccountForm = ({ userType, form, createUser }: ComponentProps) => {

  const { t } = useTranslation();
  const { required, email } = useFormRules();

  const isUserPortal = userType === UserTypeEnum.portal
  const isSupplier = userType === UserTypeEnum.supplier

  const [suppliers, setSuppliers] = useState<{ value: string, label: string }[]>([])
  const [selectedItem, setSelectedItem] = useState<string | null>(null)

  const fetchUser = useMemo(() => {
    return (search?: string) => {
      const q: any = {};
      if (search && search.length >= 2)
        q.name = '/' + search + '/'
      adminSupplierCompanyApi.findAllSuppliers(q, 1, -1)
        .then(r => setSuppliers(r.rows.map(row => ({
          value: row.id!,
          label: `${row.name}`
        }))))
    }
  }, [setSuppliers])

  useEffect(() => {
    fetchUser()
  }, [fetchUser]);

  const onSearch = async (value: string) => {
    fetchUser(value)
  }

  const clear = () => {
    setSuppliers([])
    setSelectedItem(null)
  }

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const selectElement = () => {
    const typeArr = [
      {
        type: UserTypeEnum.portal,
        enum: UserPortalRolesEnum
      },
      {
        type: UserTypeEnum.admin,
        enum: UserAdminRolesEnum
      },
      {
        type: UserTypeEnum.member,
        enum: UserMemberRolesEnum
      },
      {
        type: UserTypeEnum.supplier,
        enum: UserSupplierRolesEnum
      }
    ]
    const translationNameArr = [
      {
        type: UserTypeEnum.portal,
        name: "ROLES.PORTAL"
      },
      {
        type: UserTypeEnum.admin,
        name: "ROLES.ADMIN"
      },
      {
        type: UserTypeEnum.member,
        name: "ROLES.MEMBER"
      },
      {
        type: UserTypeEnum.supplier,
        name: "ROLES.SUPPLIER"
      }
    ]
    const currentEnum = typeArr.find(t => t.type === userType)?.enum
    const currentName = translationNameArr.find(t => t.type === userType)?.name
    return (
      <Form.Item
        label="Rôle"
        name="role"
        rules={[required]}
      >
        <Select>
          {Object.values(currentEnum!)
            .filter(k => !isNaN(+k))
            // .filter(r => r < (typeof UserPortalRolesEnum.structureAdmin))
            .map(role => (
              <Select.Option key={role} value={role}>{t(`TYPES.${currentName}.${currentEnum![role as number]}`)}</Select.Option>
            ))}
        </Select>
      </Form.Item>
    )
  }

  return (
    <>
      <Form
        form={form}
        className='grid grid-cols-2 gap-x-4 mt-5'
        name="basic"
        layout='vertical'
        onFinish={createUser}
      >
        <Form.Item hidden name="userType" className='hidden' initialValue={userType}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          label="Nom"
          name="lastName"
          rules={[required]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          label="Prénom"
          name="firstName"
          rules={[required]}
        >
          <Input></Input>
        </Form.Item>
        {isUserPortal && (
          <Form.Item
            label="Fonction"
            name="position"
            rules={[required]}
          >
            <Input></Input>
          </Form.Item>
        )}
        {selectElement()}
        {isSupplier && (
          <Form.Item
            label="Fournisseur"
            name="supplierCompanyId"
            rules={[required]}
          >
            <Select
              showSearch
              placeholder="Fournisseur"
              optionFilterProp="children"
              onSearch={onSearch}
              filterOption={filterOption}
              onClear={clear}
              onSelect={selectedItem as any}
              value={selectedItem}
              options={suppliers}
            >
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label="Email"
          name="email"
          rules={[required, email]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          label="Téléphone"
          name="phone"
          rules={[required]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" className="bg-alc-blue">
            Créer un compte
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default AdminCreateAccountForm
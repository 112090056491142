import { hasFeatureAccess } from '@alcome-rep/alcome-types';
import React from 'react';
import { useAppSelector } from '../../hooks';
import { selectUser } from '../../../Auth/state/auth.slice';
import { selectCurrentAdminStructure } from '../../../Structure/state/structure-admin.slice';
import { UserTypeEnum } from '@alcome-rep/alcome-types/dist/interfaces/user';

type PropsType = {
    children: React.ReactNode,
    featureName: string,
    userType: UserTypeEnum
}

const FeatureAvailable = ({ children, featureName, userType = UserTypeEnum.portal }: PropsType) => {

    const user = useAppSelector(selectUser)
    const structure = useAppSelector(selectCurrentAdminStructure)

    const hasAccess = hasFeatureAccess(featureName, { userRole: user?.role, structureType: structure?.structureType })
    return (
        <>
            {hasAccess === true ? (
                children
            ) :
                <></>
            }
        </>
    )
}

export default FeatureAvailable

import { RouteObject } from "react-router-dom";
import AdminAcquisitionOrderList from "../components/admin/AdminAcquisitionOrderList/AdminAcquisitionOrderList";
import AcquisitionOrderAshtrays from "../components/common/AcquisitionOrderAshtrays/AcquisitionOrderAshtrays";
import AcquisitionOrderExtinguishers from "../components/common/AcquisitionOrderExtinguishers/AcquisitionOrderExtinguishers";
import AcquisitionOrderResume from "../components/common/AcquisitionOrderResume/AcquisitionOrderResume";
import AcquisitionOrderLayout from "../layouts/AcquisitionOrderLayout";
import AcquisitionOrderMaterial from "../components/common/AcquisitionOrderMaterial/AcquisitionOrderMaterial";


const routePrefix = 'admin'
const routeDomain = 'acquisitionOrder'
const routeParam = 'acquisitionOrderId'

export const adminAcquisitionOrderRouteNames = {
  acquisitionOrderList: { path: `${routePrefix}/${routeDomain}`, title: `ROUTES.${routeDomain}.list` },
  acquisitionOrderDetails: { path: `${routePrefix}/${routeDomain}/:${routeParam}`, title: `ROUTES.${routeDomain}.details` },
  acquisitionOrderExtinguishers: { path: `${routePrefix}/${routeDomain}/:${routeParam}/extinguishers`, title: `ROUTES.${routeDomain}.extinguishers` },
  acquisitionOrderAshtrays: { path: `${routePrefix}/${routeDomain}/:${routeParam}/ashtrays`, title: `ROUTES.${routeDomain}.ashtrays` },
  acquisitionOrderDeclaration: { path: `${routePrefix}/${routeDomain}/:${routeParam}/declaration`, title: `ROUTES.${routeDomain}.declaration` },
}

export const adminAcquisitionOrderRoutes: RouteObject[] = [
  {
    path: "",
    children: [
      {
        path: adminAcquisitionOrderRouteNames.acquisitionOrderList.path.replace(`${routePrefix}/`, ''),
        element: <AdminAcquisitionOrderList />,
      },
      {
        path: adminAcquisitionOrderRouteNames.acquisitionOrderDetails.path.replace(`${routePrefix}/`, ''),
        element: <AcquisitionOrderLayout />,
        children: [
          {
            path: 'ashtrays',
            element: <AcquisitionOrderAshtrays />
          },
          {
            path: 'extinguishers',
            element: <AcquisitionOrderExtinguishers />
          },
          {
            path: '',
            element: <AcquisitionOrderResume />
          },
          {
            path: 'declaration',
            element: <AcquisitionOrderMaterial />
          }
        ]
      },
    ]
  }
]

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Dispatch } from '@reduxjs/toolkit';
import { Button, DatePicker, DatePickerProps, Input, message } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import AnnualReviewStatementDetailsComponent from './AnnualReviewStatementDetails';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { selectUser } from '../../../../Auth/state/auth.slice';
import { selectCurrentPortalAnnualReview } from '../../../state/annual-review-portal.slice';
import { AnnualReviewStatusEnum, IAnnualReview } from '@alcome-rep/alcome-types/dist/interfaces';
import LoadingScreen from '../../../../common/components/LoadingScreen';
import { UserTypeEnum } from '@alcome-rep/alcome-types/dist/interfaces/user';
import dayjs from 'dayjs';
import { adminSetStatementPaidActions, selectAdminCurrentAnnualReview } from '../../../state/annual-review-admin.slice';
import Info from '../../../../common/components/WarningUser/Info';

type PropsType = {
  isAdmin?: boolean
}

const StatementPaidStep = ({ isAdmin }: PropsType) => {

  const user = useAppSelector(selectUser);
  const annualReviewSelectorToUse = isAdmin ? selectAdminCurrentAnnualReview : selectCurrentPortalAnnualReview;
  const annualReview = useAppSelector(annualReviewSelectorToUse) as IAnnualReview;
  const dispatch: Dispatch<any> = useAppDispatch()

  let amount: number = annualReview.amountStatement;
  let datePayment: Date | undefined = annualReview.paymentExpectedDate;

  const onChange: DatePickerProps['onChange'] = (date) => {
    datePayment = date?.toDate()
  };

  const statementPaid = async () => {
    confirm({
      title: 'Paiement du décompte liquidatif',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <div>
            Payer le décompte liquidatif :
          </div>
          <div>
            <div className='text-alc-blue mt-2 mb-2 font-semibold '>
              Montant du décompte liquidatif :
            </div>
            <Input defaultValue={amount} className='block w-full p-2' onChange={e => amount = (+e.target.value)} type={'number'} />
          </div>
          <div>
            <div className='text-alc-blue mt-2 mb-2 font-semibold '>
              Date de paiement :
            </div>
            <DatePicker className='w-full ' onChange={onChange} defaultValue={dayjs(datePayment)} format={'DD/MM/YYYY'} />
          </div>
        </>),
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okButtonProps: { className: "btn" },
      onOk() {
        if (amount === null || datePayment === null) message.error('complété les champs ci dessus')
        dispatch(adminSetStatementPaidActions({ annualReviewId: annualReview.id, amountStatement: amount && +amount, paymentDate: datePayment }))
        amount = annualReview.amountStatement
        datePayment = annualReview.paymentDate
      },
      onCancel() {
      },
    });
  }

  const admin = (user && user.userType === UserTypeEnum.admin) || false

  if (!annualReview) {
    return <LoadingScreen />
  }

  return (
    <div className="grid grid-cols-1 gap-6">
      {annualReview.status >= AnnualReviewStatusEnum.order_validate && (
        <>
          {!admin && annualReview.status === AnnualReviewStatusEnum.order_validate && (
            <>
            {/* {annualReview.paymentExpectedDate && (
              <Info icon={<ExclamationCircleOutlined />} type="success" body={format(new Date(annualReview.paymentExpectedDate), "'Paiement prévu pour le 'd MMMM yyyy'", { locale: fr })} />
            )} */}
            {annualReview.paymentDate && (
              <Info icon={<ExclamationCircleOutlined />} type="warning" body="Si vous avez reçu le paiement du décompte liquidatif, marquez le comme payé en cliquant sur le bouton ci-après." />
            )}
          </>)}
            
          {annualReview.status === AnnualReviewStatusEnum.statement_paid && annualReview.paymentDate && (
            <Info icon={<ExclamationCircleOutlined />} type="success" body={`Le décompte liquidatif a été payé le ${format(new Date(annualReview.paymentDate), "d MMMM yyyy", { locale: fr })}.`} />
          )}

          <AnnualReviewStatementDetailsComponent annualReview={annualReview}></AnnualReviewStatementDetailsComponent>

          {admin && annualReview.status === AnnualReviewStatusEnum.order_validate && (
            <Button type="primary" htmlType="button" className="btn justify-self-start" onClick={statementPaid}>
              Marquer le décompte liquidatif comme payé
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default StatementPaidStep;
import { NavLink, useSearchParams } from "react-router-dom"

type PropsType = {
  queryParams: string
  label: string
}

const FilterNavLink = ({ queryParams, label }: PropsType) => {
  let [searchParams] = useSearchParams();


  const isCurrentFilter = () => {
    const currentParams = '?' + searchParams.toString()
    return decodeURIComponent(currentParams) === decodeURIComponent(queryParams)
  }

  return <NavLink
    end
    className={({ isActive }) => isActive && isCurrentFilter() ? "filter-link-active font-semibold text-alc-yellow" : ""}
    to={queryParams}
  >{label}</NavLink>
}

export default FilterNavLink
import { Models } from "@alcome-rep/alcome-types";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/store";
import { UserUpdateContractPayload } from "../services/types";
import { ContractServiceState, CONTRACT_PORTAL_SERVICE } from "./types";

const contractInitialState: ContractServiceState = {
  currentContract: null,
};

export const contractPortalSlice = createSlice({
  name: CONTRACT_PORTAL_SERVICE,
  initialState: contractInitialState,
  reducers: {

    updateContractAction: (state: ContractServiceState, _payload: PayloadAction<UserUpdateContractPayload>) => {
      // state.currentContract = null
    },

    getContractAction: (state: ContractServiceState, _payload: PayloadAction) => {
      // state.currentContract = null;
    },


    setCurrentContractAction: (state: ContractServiceState, { payload: contract }: PayloadAction<Models.Contract>) => {
      state.currentContract = contract;
    },

    unsetCurrentContractAction: (state: ContractServiceState, _payload: PayloadAction) => {
      state.currentContract = null;
    },

  }
});


export const {
  updateContractAction,
  getContractAction,
  setCurrentContractAction,
  unsetCurrentContractAction,

} = contractPortalSlice.actions;

export default contractPortalSlice.reducer;

export const selectContractService = (state: RootState) => state.contractPortalService
export const selectCurrentContract = createSelector(selectContractService, (contractSvc) => contractSvc.currentContract)
import { ReactNode } from "react"

type PropsType = {
  items: { title: string, description?: string | number | ReactNode }[]
}

const DList = ({ items }: PropsType) => {
  return <>
    <dl className="grid grid-cols-[200px_1fr]">
      {items && items.map(item => ([
        <dt key={'dt'}>{item.title}</dt>,
        <dd key={'dd'}>{item.description}</dd>
      ]
      ))}
    </dl>
  </>
}

export default DList
import { DispositifCategoryEnum, DispositifTypeEnum, isAshtrayType, isExtinguisherType } from "@alcome-rep/alcome-types/dist/interfaces";
import { IProduct, ProductStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces/product";
import { Button, Form, Input, InputNumber, Radio, Select } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import adminSupplierCompanyApi from "../../../../SupplierCompany/services/admin-supplierCompany.api";
import useFormRules from "../../../../common/hooks/useFormRules";
import msg from "../../../../common/services/toast";
import productAdminApi from "../../../services/product-admin-api";

type Props = {
  close: (product?: IProduct) => void
}

const CreateProductForm = ({ close }: Props) => {

  const [form] = useForm()
  const selectedCategory = useWatch('category', form)
  const { required } = useFormRules();
  const { t } = useTranslation();

  const [suppliers, setSuppliers] = useState<{ value: string, label: string }[]>([])
  const [selectedItem, setSelectedItem] = useState<string | null>(null)

  const fetchSuppliers = useMemo(() => {
    return (search?: string) => {
      const q: any = {};
      if (search && search.length >= 2)
        q.name = '/' + search + '/'
      adminSupplierCompanyApi.findAllSuppliers(q, 1, -1)
        .then(r => setSuppliers(r.rows.map(row => ({
          value: row.id!,
          label: `${row.name}`
        }))))
    }
  }, [setSuppliers])

  useEffect(() => {
    fetchSuppliers()
  }, [fetchSuppliers]);

  const onSearch = async (value: string) => {
    fetchSuppliers(value)
  }

  const clear = () => {
    setSuppliers([])
    setSelectedItem(null)
  }

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const createProduct = async (formData: IProduct) => {
    try {
      const product = await productAdminApi.adminCreateProduct(formData);
      msg.success('Produit créé avec succès')
      close(product)
    } catch {
      msg.error('Erreur lors de la création du produit')
    }
  }

  return (
    <Form
      form={form}
      name="basic"
      layout='vertical'
      onFinish={createProduct}
    >
      <Form.Item
        label="Fournisseur"
        name="supplierCompanyId"
      >
        <Select
          showSearch
          placeholder="Fournisseur"
          optionFilterProp="children"
          onSearch={onSearch}
          filterOption={filterOption}
          onClear={clear}
          onSelect={selectedItem as any}
          value={selectedItem}
          options={suppliers}
        >
        </Select>
      </Form.Item>

      <div className="flex space-x-4">
        <Form.Item
          label="Nom du produit"
          name="name"
          className="flex-1"
          rules={[required]}
        >
          <Input></Input>
        </Form.Item>

        <Form.Item
          label="Référence"
          name="reference"
          rules={[required]}
          className="w-44"
        >
          <Input></Input>
        </Form.Item>
      </div>

      <Form.Item
        label="Fiche technique"
        name="productDocumentUrl"
      >
        <Input></Input>
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        rules={[required]}
      >
        <TextArea></TextArea>
      </Form.Item>

      <div className="flex space-x-4">
        <Form.Item
          label="Catégorie"
          name="category"
          rules={[required]}
          className="w-48"
        >
          <Select
            options={Object.keys(DispositifCategoryEnum)
              .map(status => ({
                label: t(`TYPES.INVENTORY.DispositifCategoryEnum.${status as any}`),
                value: status
              }))
            }
          />

        </Form.Item>

        <Form.Item
          label="Type"
          name="type"
          rules={[required]}
          className="flex-1"
        >
          <Select
            options={Object.keys(DispositifTypeEnum)
              .filter((k: any) => selectedCategory === DispositifCategoryEnum.ashtray ? isAshtrayType(k) : isExtinguisherType(k))
              .map(status => ({ label: t(`TYPES.INVENTORY.DispositifTypeEnum.${status as any}`), value: status }))
            }
          />

        </Form.Item>


      </div>

      <div className="flex space-x-8">
        <Form.Item
          label="Poids"
          name="weight"
          className="w-20"
          rules={[required]}
        >
          <InputNumber suffix="kg" min={1}></InputNumber>
        </Form.Item>

        <Form.Item
          label="Tarif"
          name="price"
          className="w-20"
          rules={[required]}
        >
          <InputNumber suffix="€" min={1}></InputNumber>
        </Form.Item>
        {/* <Form.Item
          label="TVA"
          name="tax"
          rules={[required]}
        >
          <InputNumber min={0}></InputNumber>
        </Form.Item> */}
      </div>

      <Form.Item
        label="Disponibilité"
        name="status"
        rules={[required]}
        initialValue={ProductStatusEnum.product_available}
      >
        <Radio.Group >
          {Object.keys(ProductStatusEnum)
            .filter(k => !isNaN(k as any))
            .map(status =>
              <Radio key={status} value={+status}>{t(`TYPES.PRODUCT.ProductStatusEnum.${ProductStatusEnum[status as any]}`)}</Radio>
            )
          }
        </Radio.Group>

      </Form.Item>

      {/* <Form.Item
        label="Types de structure"
        name='structureValibility'
        rules={[required]}
      >
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Selectionnez le type de structure"
        >
          {Object.keys(StructureTypesEnum).map(type => (
            <Select.Option key={type} value={type}>{t(`TYPES.STRUCTURE.StructureTypesEnum.${type}`)}</Select.Option>
          ))}
        </Select>
      </Form.Item> */}



      <Button htmlType="submit" type="primary" >Créer un produit</Button>

    </Form >
  )
}

export default CreateProductForm
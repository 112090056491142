import { FileDocumentStatusEnum, IAnnualReview, IFileDocument } from "@alcome-rep/alcome-types/dist/interfaces"
import { selectAuth } from "../../../../Auth/state/auth.slice"
import { useAppSelector } from "../../../../common/hooks"
import { UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user"

type AnnualReviewRefusedMentionComponentType = {
    annualReview: IAnnualReview
}

const AnnualReviewRefusedMentionComponent = ({ annualReview }: AnnualReviewRefusedMentionComponentType) => {

    const shouldDisplayMention = (arev: IAnnualReview) => arev && arev.annualReviewFileId && (arev.annualReviewFileId as IFileDocument).status === FileDocumentStatusEnum.declined;
    const shouldDisplayFileComment = (arev: IAnnualReview) => arev && (arev.annualReviewFileId as IFileDocument).comment !== null

    const user = useAppSelector(selectAuth)?.user

    if (!shouldDisplayMention(annualReview))
        return null;

    if (user && user.userType === UserTypeEnum.admin) {
        return (
            <div>
                <span className="text-alc-danger font-semibold">
                    Le bilan a été refusé. L'utilisateur doit mettre à jour ses informations.
                </span>
                {shouldDisplayFileComment(annualReview) &&
                    (<div className='mt-2  col-span-3 refused'>
                        Commentaire : {(annualReview.annualReviewFileId as IFileDocument).comment}
                    </div>)
                }
            </div>
        )
    }

    return (
        <div>
            <span className="text-alc-danger font-semibold">
                Le bilan a été refusé par Alcome, merci de vérifier votre saisie et de valider à nouveau.
            </span>
            {shouldDisplayFileComment(annualReview) &&
                (<div className='mt-2 border-1 border-dashed border-alc-danger rounded-md p-2 col-span-3'>
                    Commentaire d'Alcome : {(annualReview.annualReviewFileId as IFileDocument).comment}
                </div>)
            }
        </div>
    )
}

export default AnnualReviewRefusedMentionComponent
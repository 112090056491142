const colors = require('tailwindcss/colors');

module.exports = {
  extend: {
    // fontFamily: {
    //   'acier-bat': 'acier-bat-solid',
    //   'acier-bat-outline': 'acier-bat-outline',
    // },
    borderWidth: {
      '1': '1px',
      '3': '3px',
      '6': '6px',
    },
    zIndex: {
      '100000': 100000
    },
    gridTemplateColumns: {
      // Complex site-specific column configuration
      "3panels": '25% 50% 25%',
      "2panels": '60% 40%'
    }
  },
  colors: {
    transparent: 'transparent',
    white: '#fff',
    black: '#000',
    gray: colors.gray,
    green: [colors.green, colors.emerald, colors.teal],
    alc: {
      'blue-grp-commune': '#005484',
      blue: '#003B71',
      yellow: '#f7a826',
      gray: '#777',
      "light-gray": '#aaa',
      success: "#62b12c",
      danger: "#cf0000"
    },
  },
  container: {
    center: true,
    padding: {
      DEFAULT: '4rem',
      sm: '2rem',
      lg: '4rem',
      xl: '8rem',
      '2xl': '12rem',
      '4xl': '12rem',
    },
  },
}
import { ChildCommuneInventory, IStructure, RevenueOrder } from "@alcome-rep/alcome-types/dist/interfaces";
import NavTabs from "../../../common/components/Panels/NavTabs";
import PageCanvas from "../../../common/components/Panels/PageCanvas";
import { ROUTES } from "../../../common/router";
import { Outlet } from "react-router-dom";

export type findNotExportedRevenueOrderType = {
  communes: ChildCommuneInventory[],
  revenueOrder: RevenueOrder
  structure: IStructure,
  _id: string
}

const AccountingExport = () => {
  return (
    <PageCanvas title="Extractions comptables" breadCrumbs={[ROUTES.ADMIN_USER_ROUTES.home, ROUTES.ADMIN_ACCOUNTING_EXPORT_ROUTES.accountingexportList]}>

      <NavTabs white={true} tabRoutes={[
        { ...ROUTES.ADMIN_ACCOUNTING_EXPORT_ROUTES.accountingexportRevenueOrderList },
        { ...ROUTES.ADMIN_ACCOUNTING_EXPORT_ROUTES.accountingexportInvoicesList },
      ]} />

      <Outlet />
    </PageCanvas>
  )
}

export default AccountingExport
import { Models } from '@alcome-rep/alcome-types'
import { AnnualReviewStatusEnum, IAnnualReview } from '@alcome-rep/alcome-types/dist/interfaces'
import { User } from '@alcome-rep/alcome-types/dist/models'
import { CloseOutlined, DeleteOutlined, DownloadOutlined, QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons'
import { Dispatch } from '@reduxjs/toolkit'
import { Button, DatePicker, Form, Input } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import confirm from 'antd/es/modal/confirm'
import TextArea from 'antd/lib/input/TextArea'
import Upload, { RcFile } from 'antd/lib/upload'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { selectUser } from '../../../../Auth/state/auth.slice'
import DateField from '../../../../common/components/Fields/DateField'
import Section from '../../../../common/components/Panels/Section'
import { useAppDispatch, useAppSelector } from '../../../../common/hooks'
import useFormRules from '../../../../common/hooks/useFormRules'
import { mimeTypeAccepted, randomNumberId } from '../../../../common/tools/global-tools'
import annualReviewAdminApi from '../../../services/annual-review-admin.api'
import annualReviewPortalApi from '../../../services/annual-review-portal.api'
import { setCurrentPortalAnnualReviewAction, uploadAnnualReviewArrayFileActions } from '../../../state/annual-review-portal.slice'
import AddButton from '../../../../common/components/Buttons/addButton'

type AwarnessTableComponentType = {
  annualReview: Models.AnnualReview,
}

const AwarnessTableComponent = ({ annualReview }: AwarnessTableComponentType) => {
  const user = useAppSelector(selectUser);
  const admin = User.IsUserAdmin(user);
  const canEdit = !admin && annualReview && annualReview.status <= AnnualReviewStatusEnum.refused ? true : false
  const propertyName = "awarenessAction";

  const [collapsed, setCollapsed] = useState<boolean>(true);

  const dispatch: Dispatch<any> = useAppDispatch();
  const { t } = useTranslation();

  const { required } = useFormRules();

  const form = useFormInstance<IAnnualReview>();
  const awarenessAction = useWatch(propertyName, form) || (annualReview.awarenessAction ?? []);

  const uploadDocument = async (file: any, index: any, subPropertyName: "actionProof" | "actionBills") => {
    const formData = new FormData();
    try {
      formData.append('files', file as RcFile);
      dispatch(uploadAnnualReviewArrayFileActions({
        annualReviewId: annualReview.id as string, data: {
          propertyName,
          subPropertyName,
          index,
          timestamp: Date.now()
        }, formData
      }))
    } catch { }
    return false;
  };

  const downloadFile = async (index: number, subPropertyName: string) => {
    const call = User.IsUserAdmin(user) ? annualReviewAdminApi.adminDownloadArrayFile : annualReviewPortalApi.downloadArrayFile;
    await call(
      annualReview.id!,
      propertyName,
      subPropertyName,
      (annualReview[propertyName] as any)[index][subPropertyName] as string
    )
  }

  const deleteFile = async (index: number, subPropertyName: 'actionProof' | 'actionBills') => {
    confirm({
      title: `Suppression du fichier`,
      icon: <QuestionCircleOutlined />,
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      // okButtonProps: { className: "btn" },
      async onOk() {
        const updatedAnnualReview = await annualReviewPortalApi.deleteArrayFile(annualReview.id!, propertyName, subPropertyName, index);
        dispatch(setCurrentPortalAnnualReviewAction(updatedAnnualReview))
      },
      onCancel() {
      },
    });
  }

  const removeArrest = async (index: number, removeFormMethod: any) => {
    confirm({
      title: `Supprimer la ligne ?`,
      icon: <QuestionCircleOutlined />,
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      // okButtonProps: { className: "btn" },
      async onOk() {
        if (awarenessAction[index].actionProof) {
          const updatedAnnualReview = await annualReviewPortalApi.deleteArrayFile(annualReview.id!, propertyName, "actionProof", index);
          dispatch(setCurrentPortalAnnualReviewAction(updatedAnnualReview))
        }
        if (awarenessAction[index].actionBills) {
          const updatedAnnualReview = await annualReviewPortalApi.deleteArrayFile(annualReview.id!, propertyName, "actionBills", index);
          dispatch(setCurrentPortalAnnualReviewAction(updatedAnnualReview))
        }
        removeFormMethod(index);
      },
      onCancel() {
      },
    });
  }

  const ListItemHasError = (fieldName: string) => {
    return form.getFieldsError()
      .filter(f => f.errors.length)
      .map(f => f.name.join('.'))
      .find(f => f.includes(fieldName))
      !== undefined
  }

  const getDownloadFormField = (name: number, key: number, subPropertyName: 'actionProof' | 'actionBills') => {
    return <Form.Item label={t(`TYPES.FILEDOCUMENT.${propertyName}.${subPropertyName}`)} className="mb-0">
      {annualReview.awarenessAction[key] && annualReview.awarenessAction[key][subPropertyName] ?
        <div className='flex space-x-4'>
          <Button
            icon={<DownloadOutlined />}
            onClick={_ => downloadFile(name, subPropertyName)}
            type='link'
            className='px-0'
          >Télécharger
          </Button>

          {canEdit && <Button
            icon={<DeleteOutlined />}
            onClick={_ => deleteFile(name, subPropertyName)}
            type='link'
            className='px-0'
          />}
        </div>
        : canEdit ? (
          <Upload
            disabled={annualReview.awarenessAction[name] === undefined}
            beforeUpload={(file) => uploadDocument(file, name, subPropertyName)}
            showUploadList={false}
            accept={mimeTypeAccepted()}
          >
            <Button disabled={!awarenessAction[name].actionType && !awarenessAction[name].actionDetail} htmlType='button' icon={<UploadOutlined />}>Chargez un fichier</Button>
          </Upload>
        )
          : <span>Aucun document</span>
      }
    </Form.Item>
  }

  return (
    <>
      <Form.List name={propertyName}>
        {(fields, { add, remove }, { errors }) => (
          <div className="grid grid-cols-1 gap-4">
            {fields.map(({ key, name, ...restField }) => (
              <Section
                key={key}
                mode="collapsible"
                collapsed={collapsed}
                titleClass={ListItemHasError('awarenessAction.' + name) ? '!text-alc-danger' : ''}
                title={<span><DateField value={(awarenessAction[name].actionDate)} /></span>}
                actions={canEdit && <Button
                  type='text'
                  size='small'
                  onClick={_ => removeArrest(name, remove)}
                  icon={<CloseOutlined className='text-alc-danger' readOnly={!canEdit}></CloseOutlined>}>
                </Button>}
              >
                <Form.Item {...restField} hidden name={[name, 'id']} className='hidden'>
                  <Input type="hidden" />
                </Form.Item>

                <div className="flex gap-4">
                  <Form.Item label='Date' name={[name, 'actionDate']} rules={[required]}><DatePicker className='w-full' readOnly={!canEdit} format={'DD/MM/YYYY'} /></Form.Item>
                  <Form.Item label="Type d'action" name={[name, 'actionType']} rules={[required]} className="grow"><Input readOnly={!canEdit} /></Form.Item>
                </div>

                <Form.Item label='Détails' name={[name, 'actionDetail']} rules={[required]}><TextArea readOnly={!canEdit} rows={3} /></Form.Item>

                <div className="flex gap-6">
                  {getDownloadFormField(name, key, "actionProof")}
                  {getDownloadFormField(name, key, "actionBills")}
                </div>

              </Section>
            ))}

            {canEdit && <AddButton onClick={() => { add({ id: randomNumberId(), actionDate: dayjs() }); setCollapsed(false) }}>Ajouter une action de sensibilisation</AddButton>}
          </div>
        )}
      </Form.List >
    </ >
  )
}

export default AwarnessTableComponent
import { FileDocumentStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { AnnualReview } from "@alcome-rep/alcome-types/dist/models";

/* ======== CONSTANTES ======== */



/* ======== TYPES ======== */
export type AnnualReviewServiceState = {
  currentAnnualReview: AnnualReview | null
};

export type AnnualReviewAdminServiceState = {
  currentAnnualReview: AnnualReview | null
};

export const ANNUALREVIEW_SERVICE = "annualReviewService";
// eslint-disable-next-line
export type ANNUALREVIEW_SERVICE = typeof ANNUALREVIEW_SERVICE; // Typescript line

export const ANNUALREVIEW_PORTAL_SERVICE = "annualReviewPortalService";
// eslint-disable-next-line
export type ANNUALREVIEW_PORTAL_SERVICE = typeof ANNUALREVIEW_PORTAL_SERVICE; // Typescript line

/* ======== PAYLOADS ======== */


export type GetAnnualReviewPayload = {
  id: string
}

export type UpdateAnnualReviewPayload = {
  id: string
  data: Pick<AnnualReview, 'communes'>
}

export type AdminGetAnnualReviewPayload = {
  id: string
}

export type AdminUpdateAnnualReviewPayload = {
  id: string
  data: Pick<AnnualReview, 'communes'>
}

export type AdminUpdateRevenueOrderFilePayload = {
  timestamp?: number
  status?: FileDocumentStatusEnum
  comment?: string
  extension?: string
}
import { CodeInsee } from '@alcome-rep/alcome-types';
import { StructureTypesEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import { Select, Spin, message } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import structureApi from '../../services/structure.api';

type PropsType = {
  selectCommune: (name: string, siren: string, isRegistered: boolean, insee?: CodeInsee) => void,
  structureType: StructureTypesEnum,
  defaultSirenValue?: string
}

export const CodeInseeField = ({ selectCommune, structureType, defaultSirenValue }: PropsType) => {

  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState<CodeInsee[]>([]);

  const { t } = useTranslation();

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      if (value) {
        setFetching(true);
        structureApi.getInseeCodes(value, structureType)
          .then(newOptions => {
            setOptions(newOptions);
            setFetching(false);
          });
      }
    };

    return loadOptions;
  }, [structureType]);

  const selectCode = (item: any) => {
    structureApi.isRegistered(item.label[0]).then(isRegistered => {
      if (isRegistered === true) {
        message.error('Un compte est déjà établi pour ce code')
      }
      const insee = options.find(o => o.code === item.label[0]);
      selectCommune(item.key, item.label[0], isRegistered, insee)
    })
  }

  const optionsEl = options.map((d) => <Select.Option key={d.nom}>{d.code} - {d.nom}</Select.Option>);

  return <>

    <div className="ant-row ant-form-item onboarding-profilAdministratif-fields-name-label">
      <label className='mb-2 block'><span className="text-alc-danger font-bold">*</span> {t('TYPES.STRUCTURE.Code.' + structureType)}</label>
      <Select
        showSearch
        labelInValue
        defaultValue={defaultSirenValue}
        filterOption={false}
        // defaultActiveFirstOption={false}
        onSearch={debounceFetcher}
        onChange={selectCode}
        // notFoundContent={null}
        style={{ width: '100%' }}
        notFoundContent={fetching ? <Spin size="small" /> : null}
      // options={options}
      >
        {optionsEl}
      </Select>
    </div>
  </>
}

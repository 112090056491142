import { AcquisitionItemOrderStatusEnum, AcquisitionOrderStatusEnum, IAcquisitionOrders } from "@alcome-rep/alcome-types/dist/interfaces"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Button } from "antd"
import { useAppDispatch } from "../../../../common/hooks"
import { updateAdminAcquisitionOrderItemStatusActions } from "../../../state/acquisitionOrder-admin.slice"

type PropsType = {
  acquisitionOrder: IAcquisitionOrders,
  itemIndex: number
}

const AdminAcquisitionOrderItemStatusEdit = ({ acquisitionOrder, itemIndex }: PropsType) => {
  const dispatch = useAppDispatch();

  if (acquisitionOrder.status !== AcquisitionOrderStatusEnum.acquisition_order_pending)
    return <></>

  const changeStatus = (status: AcquisitionItemOrderStatusEnum) => {
    dispatch(updateAdminAcquisitionOrderItemStatusActions({ acquisitionOrderId: acquisitionOrder.id, itemIndex, status }))
  }

  const displayAcquisitionOrderButtons = () => {
    if (acquisitionOrder.items[itemIndex].status === AcquisitionItemOrderStatusEnum.pending) {
      return (
        <div className="flex space-x-4">
          <Button size="small" onClick={_ => changeStatus(AcquisitionItemOrderStatusEnum.validated)} type="link" icon={<CheckOutlined />} title="Valider" />
          <Button size="small" onClick={_ => changeStatus(AcquisitionItemOrderStatusEnum.refused)} type="link" danger icon={<CloseOutlined />} title="Refuser" />
        </div>
      )
    } else if (acquisitionOrder.items[itemIndex].status === AcquisitionItemOrderStatusEnum.validated) {
      return <Button size="small" onClick={_ => changeStatus(AcquisitionItemOrderStatusEnum.refused)} type="link" danger icon={<CloseOutlined />} title="Refuser" />
    } else if (acquisitionOrder.items[itemIndex].status === AcquisitionItemOrderStatusEnum.refused) {
      return <Button size="small" onClick={_ => changeStatus(AcquisitionItemOrderStatusEnum.validated)} type="link" icon={<CheckOutlined />} title="Valider" />
    }else{
      return <></>
    }
  }

  return displayAcquisitionOrderButtons()
}

export default AdminAcquisitionOrderItemStatusEdit
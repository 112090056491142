import { Models } from "@alcome-rep/alcome-types";
import ApiService from "../../common/services/apiService";
import { AppUser } from "../types";

const USER_MEMBER_PATH_API = 'supplier/user';

class UserMemberApi extends ApiService<AppUser> {
    constructor() {
        super(USER_MEMBER_PATH_API)
    }

    /**
     * @description Structure admin update user in structure
     * @param userId 
     * @param data 
     * @returns Return the target user updated 
     */
    updateInfos(data: Partial<Models.UserMember>) {
        return this.custom<Models.UserMember>('put', 'me', undefined, data)
            .then(r => new Models.User(r))
    }

    /**
     * @description
     */
    deleteUser(userId: string) {
        return this.delete(userId)
    }
}

const userMemberApi = new UserMemberApi();
export default userMemberApi;
import { AnnualReview } from "@alcome-rep/alcome-types/dist/models";
import { Alert } from "antd";
import { format } from "date-fns";
import { useAppSelector } from '../../../../common/hooks';
import { selectAdminCurrentAnnualReview } from "../../../state/annual-review-admin.slice";

type ArHistoryProps = {
    isAdmin: boolean
}

const AnnualReviewRefuseHistory = ({ isAdmin }: ArHistoryProps) => {

    const annualReview = useAppSelector(selectAdminCurrentAnnualReview) as AnnualReview;
    const comments = annualReview.annualReviewFile && annualReview.annualReviewFile.history && annualReview.annualReviewFile.history.filter(a => !!a.comment)

    console.log(isAdmin)

    return (
        <>
            {isAdmin  && comments.length > 0 && (
                <div className="space-y-2">
                    <h6>Historique des refus</h6>
                    {comments.map(c => (
                        <div>
                            <Alert type='error' message={<div className="flex justify-between">
                                <div>{c.comment}</div>
                                <div>{format(new Date(c.statusDate), "dd/MM/yyyy")}</div>
                                </div>} />
                        </div>
                    ))}
                </div>
            )}
        </>
    )

}

export default AnnualReviewRefuseHistory
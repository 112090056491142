import { StructureQuotasType } from '@alcome-rep/alcome-types/dist/interfaces';
import { useEffect, useState } from 'react';
import supportOrderApi from '../../SupportOrder/services/supportOrder.api';


const useQuotas = (id?: string, prospect?: boolean) => {

  const [quotas, setQuotas] = useState<StructureQuotasType>();

  // fecthing support quotas 
  useEffect(() => {
    if (!id)
      return
    if (prospect) {
      supportOrderApi.getProspectQuotas(id)
        .then(r => setQuotas(r))
    } else {
      supportOrderApi.getQuotas(id)
        .then(r => setQuotas(r))
    }
  }, [id, prospect])

  return quotas
};

export default useQuotas;

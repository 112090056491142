import { IUser, UserPortalRolesEnum } from '@alcome-rep/alcome-types/dist/interfaces/user';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { getRoute } from '../../../../common/tools/router-tools';
import { ROUTES } from '../../../../common/router';
import { NavigateFunction } from 'react-router-dom';

export const UserStructureProfilColumns = (t: TFunction, navigate: NavigateFunction): ColumnsType<IUser> => [
  {
    // title: 'Actions',
    fixed: 'left',
    width: "40px",
    align: 'center',
    render: (value: any, _, i) => {
      return (
        <Button
          id={`action-button-${i}`}
          type="link"
          icon={<EditOutlined />}
          onClick={(_e) =>
            navigate(getRoute(ROUTES.PORTAL_USER_ROUTES.userDetails, { userId: value.id }))}
        />
      )
    }
  },
  {
    title: 'Nom',
    dataIndex: 'lastName',
    key: 'lastName',
    align: 'center' as const,
    render: (value: any) => {
      return value ? <div className='font-semibold text-center'>{value}</div> : '-'
    }
  },
  {
    title: 'Prénom',
    dataIndex: 'firstName',
    key: 'firstName',
    align: 'center' as const,
    render: (value: any) => {
      return value ? <div className='font-semibold text-center'>{value}</div> : '-'
    }
  },
  {
    title: 'Rôle',
    dataIndex: 'role',
    key: 'role',
    align: 'center' as const,
    render: (value: any) => {
      return value ? <div className='font-semibold text-center'>{t(`TYPES.ROLES.PORTAL.${UserPortalRolesEnum[value]}`)}</div> : '-'
    }
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    align: 'center' as const,
    render: (value: any) => {
      return value ? <div className=' text-center'>{value}</div> : '-'
    }
  },
  {
    title: 'Téléphone',
    dataIndex: 'phone',
    key: 'phone',
    align: 'center' as const,
    render: (value: any) => {
      return value ? <div className=' text-center'>{value}</div> : '-'
    },
  },
  {
    title: 'Fonction',
    dataIndex: 'position',
    key: 'position',
    align: 'center' as const,
    render: (value: any) => {
      return value ? <div className=' text-center'>{value}</div> : '-'
    }
  }
]
import { AcquisitionOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { Tag } from "antd"
import { useTranslation } from "react-i18next"


const AcquisitionOrderStatus = ({ status }: { status: AcquisitionOrderStatusEnum }) => {

  const { t } = useTranslation();

  return <Tag color={t(`UI.TAG.AcquisitionOrderStatusEnum.${AcquisitionOrderStatusEnum[status]}`)} className={`${AcquisitionOrderStatusEnum[status]} text-white`}>
    {t(`TYPES.ACQUISITION_ORDER.AcquisitionOrderStatusEnum.${AcquisitionOrderStatusEnum[status]}`)}
  </Tag>
}
export default AcquisitionOrderStatus
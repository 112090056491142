import { Models } from '@alcome-rep/alcome-types';
import { IStructure } from '@alcome-rep/alcome-types/dist/interfaces';
import { IUser } from '@alcome-rep/alcome-types/dist/interfaces/user';
import { QuestionCircleOutlined } from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import structureAdminApi from '../../../../Structure/services/structure-admin.api';
import LoadingScreen from '../../../../common/components/LoadingScreen';
import PageCanvas from '../../../../common/components/Panels/PageCanvas';
import Section from '../../../../common/components/Panels/Section';
import { ROUTES } from '../../../../common/router';
import userAdminApi from '../../../services/user-admin.api';
import AdminUserDetailsForm from './AdminUserDetailsForm';
import msg from '../../../../common/services/toast';

type PropsType = {
  isAdmin: boolean
}

const UserDetailsPage = ({ isAdmin }: PropsType) => {

  const [data, setData] = useState<IUser>();
  const [, setStructure] = useState<IStructure>();
  const { userId }: any = useParams();
  const navigate = useNavigate()

  const getStructure = useMemo(() => async (structureId: string) => {
    if (!isAdmin) {
      const res = await structureAdminApi.getStructure(structureId)
      setStructure(res)
    }
  }, [isAdmin])

  const getData = useMemo(() => async () => {
    const res = await userAdminApi.getUserById(userId)
    setData(res)
    getStructure((res as any).structureId)
  }, [userId, getStructure])

  useEffect(() => {
    getData();
  }, [userId, getData]);


  const submit = async (userData: IUser) => {
    try {
      await userAdminApi.updateUserById(userId, new Models.User(userData))
      msg.success('Enregistrement terminé')
      getData()
    } catch { }

  }

  const deleteUser = () => {
    confirm({
      title: 'Voulez-vous supprimer cet utilisateur ?',
      icon: <QuestionCircleOutlined />,
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okText: 'Supprimer l\'utilisateur',
      okButtonProps: { className: "btn" },
      onOk() {
        userAdminApi.softDeleteUserBySuperAdmin(userId)
          .then(() => navigate(-1))
      },
      onCancel() {

      },
    })
  }
  if (!data) {
    return <><LoadingScreen></LoadingScreen></>
  }
  return (
    <>
      <PageCanvas
        breadCrumbs={[
          ROUTES.ADMIN_USER_ROUTES.home,
          ROUTES.ADMIN_USER_ROUTES.userList,
          ROUTES.ADMIN_USER_ROUTES.userDetails
        ]}
        params={{ userType: data.userType }}
        title={<div>
          <div>Profil de l'utilisateur</div>
        </div>}
      >
        <Section>
          {data && (
            <AdminUserDetailsForm data={new Models.User(data)} deleteUser={deleteUser} submit={submit} />
          )}
        </Section>
      </PageCanvas>

    </>
  );
};

export default UserDetailsPage;
import React from 'react';

const ConfidentialPolicytStep: React.FC = () => {

    return (
        <>
            <div className="p-6">
                <h2>POLITIQUE DE CONFIDENTIALITÉ ET DE PROTECTION DES DONNÉES À CARACTÈRE PERSONNEL</h2>
                <p><strong>Date de création</strong>:	29 juin 2023</p>
                <p><strong>Date de mise à jour</strong>: NA</p>
                <p><em>En jaune est mis en évidence la mise à jour de la version précédente</em></p>
                <h4>INTRODUCTION</h4>
                <p>ALCOME est une SAS agréée par l’Etat qui a pour objet de pourvoir ou de contribuer par tous moyens, pour le compte de ses Adhérents, les producteurs au sens de l’article L.541-10 du code de l’environnement, à la prévention et à la gestion des déchets qui proviennent (i) des produits du tabac équipés de filtres composés en tout ou partie de plastique et (ii) des produits qui sont destinés à être utilisés avec des produits du tabac, et de contribuer à des projets d'aide au développement en matière de collecte et de traitement de ces déchets.</p>
                <p>ALCOME a pris les dispositions nécessaires afin d'assurer un niveau de sécurité de vos données personnelles, en lien et en conformité avec la loi n°78-17 du 6 janvier 1978, dite « Informatique et Libertés » et la nouvelle réglementation européenne, le RGPD (Règlement Général sur la Protection des Données n°2016/79 du 27 avril 2016), applicable à compter du 25 mai 2018.</p>
                <h4>Quelles sont les données personnelles collectées ?</h4>
                <p>Afin de remplir ses missions, la société ALCOME se doit de collecter un certain nombre de données personnelles. Selon le contact, ALCOME collecte et traite notamment les données personnelles suivantes&nbsp;:</p>
                <ul>
                    <li>Civilité,</li>
                    <li>Nom,</li>
                    <li>Prénom,</li>
                    <li>Adresse postale,</li>
                    <li>Adresse électronique,</li>
                    <li>Numéro de téléphone portable,</li>
                    <li>Numéro de téléphone fixe,</li>
                    <li>Service,</li>
                    <li>Société,</li>
                    <li>Fonction,</li>
                    <li>Informations de paiement,</li>
                    <li>Données de connexion (dates et heures de connexion, adresse IP) et données de navigation,</li>
                    <li>Réclamations,,</li>
                    <li>Gestions sociales.</li>
                </ul>
                <p>En aucun cas ALCOME ne collecte de données de santé à l’exception de celle rendue nécessaire par les lois et règlements.</p>
                <h4>A quel moment ALCOME collecte-t-elle vos données personnelles ?</h4>
                <p>ALCOME collecte les informations que vous nous fournissez volontairement notamment lorsque :</p>
                <ul>
                    <li>Vous écrivez à ALCOME&nbsp;;</li>
                    <li>Vous contactez ALCOME par téléphone ou courrier électronique&nbsp;;</li>
                    <li>Vous naviguez sur les sites d’ALCOME, consultez des produits et utilisez nos services ;</li>
                    <li>Vous participez à un événement, une conférence ou un webinar,  un salon ;</li>
                    <li>Vous souhaitez être contacté par un collaborateur d’ALCOME&nbsp;;</li>
                    <li>Vous êtes en contrat avec ALCOME&nbsp;;</li>
                    <li>Vous êtes salarié ou collaborateur occasionnel d’ALCOME&nbsp;;</li>
                    <li>Vous êtes prestataires, sous-traitants ou partenaires d’ALCOME&nbsp;;</li>
                    <li>Vous êtes un interlocuteur institutionnel d’ALCOME.</li>
                </ul>
                <p>Lorsque vous remplissez des champs, le caractère obligatoire de l’information qui vous est demandée est signalé par un astérisque car ces informations sont nécessaires afin de remplir nos obligations vis-à-vis de vous, à savoir vous fournir une prestation de service ou vous permettre l’achat d’un produit. Si ces informations obligatoires ne nous sont pas communiquées, nous ne pourrons pas fournir ce qui est attendu.</p>
                <p>Certaines informations, notamment techniques (adresse IP de votre ordinateur) ou concernant la consultation du site ainsi que ses fonctionnalités, sont collectées automatiquement du fait de vos actions sur notre site par des cookies. Pour plus d’information, vous pouvez consulter notre <strong><a href="cookies">politique en matière de cookies</a></strong>.</p>
                <h4>Dans quel but vos données personnelles sont-elles collectées&nbsp;?</h4>
                <p>ALCOME utilise vos données personnelles dans le cadre de l’exécution de ses activités pour un ou plusieurs des objectifs suivants :</p>
                <ul>
                    <li>Effectuer des opérations relatives à la gestion de nos relations : contrats, commandes, abonnements, livraisons, factures, comptabilité, gestion de votre compte client, suivi de notre relation client, gestion des réclamations, impayés et du contentieux ;</li>
                    <li>Gérer les collaborateurs d’ALCOME&nbsp;;</li>
                    <li>Gérer les accès aux sites&nbsp;d’ALCOME ;</li>
                    <li>Améliorer les actions d’ALCOME et de ses services en invitant à participer à des sondages, des études, des enquêtes de satisfaction, etc. ;</li>
                    <li>Elaborer, analyser et établir des statistiques&nbsp;;</li>
                    <li>Envoyer des informations et des lettres d’informations personnalisées sur les produits et services et leur évolution, sur l’actualité de la société&nbsp;;</li>
                    <li>Gérer les demandes de droit d'accès, de suppression, de rectification et d'opposition&nbsp;;</li>
                    <li>Organiser des opérations de marketing et de communication conformes aux obligations réglementaires d’ALCOME.</li>
                </ul>
                <p>Par ailleurs, des traitements pourront faire l’objet, autant que de besoin, du recueil de votre consentement, que vous pourrez retirer à tout moment.</p>
                <h4>Qui sont les destinataires de vos données personnelles ?</h4>
                <p>Vos données sont susceptibles d’être transmises à :</p>
                <ul>
                    <li>des services internes d’ALCOME en charge de l’exécution des missions légales et réglementaires de la société&nbsp;;</li>
                    <li>des prestataires externes à ALCOME : les prestataires techniques, y compris des sous-traitants (facturation, paiement, recouvrement, satisfaction, opérateur de collecte et de traitement, point de collecte et de distribution, etc) ;</li>
                    <li>l’autorité administrative, soit à leur demande, soit d’office dans le cadre des obligations légales et réglementaires.</li>
                </ul>
                <p>Ces destinataires pourront être amenés à vous contacter directement à partir des coordonnées que vous nous avez communiquées.</p>
                <p>Dans ce cadre, ces interlocuteurs ont l’obligation  de n’utiliser vos données personnelles que pour gérer les services que nous leur demandons de fournir. Nous exigeons qu’ils agissent toujours agir en conformité avec les lois applicables en matière de protection de données personnelles et d’accorder une attention particulière à la confidentialité et à la sécurité de ces données.</p>
                <p>Vos données personnelles pourront être communiquées en application d’une loi, d’un règlement, d’une décision d’une autorité réglementaire ou judiciaire et enfin, si nécessaire pour ALCOME afin de préserver ses droits et intérêts.</p>
                <h4>Où sont stockées vos données personnelles et sont-elles en sécurité ?</h4>
                <p>Vos données personnelles sont stockées soit dans nos bases de données soit dans celles de nos prestataires de services, lesquelles sont situées au sein de l’Union Européenne.</p>
                <p>Toutefois, si certaines données collectées sont susceptibles d’être traitées hors de l’Union Européenne, ALCOME prendra les dispositions nécessaires avec ses sous-traitants et partenaires pour garantir un niveau de protection de vos données adéquates et ce en toute conformité avec la règlementation applicable.</p>
                <p>Si les sous-traitants et partenaires concernés ne sont pas adhérents à l’accord Privacy Shield s’agissant de transferts vers les Etats-Unis d’Amérique, ou ne sont pas situés dans un pays disposant d’une législation considérée comme offrant une protection adéquate, ils auront alors préalablement signé les « clauses contractuelles types » de la Commission européenne ou seront soumis à des Règles internes contraignantes approuvées par les autorités.</p>
                <h4>Comment les données sont-elles sécurisées&nbsp;?</h4>
                <p>ALCOME met tout en œuvre en l‘état des connaissance techniques connues pour empêcher dans la mesure du possible toute altération ou perte de vos données ou tout accès non autorisé à celles-ci.</p>
                <h4>Vos données personnelles sont-elles transférées hors de l’Union Européenne ?</h4>
                <p>Non. Vos données personnelles ne sont pas transférées hors de l’Union Européenne.</p>
                <h4>Quels sont vos droits concernant vos données personnelles ?</h4>
                <p>Vous disposez d’un droit d’accès, de rectification et d’effacement des données personnelles vous concernant, ainsi que d’un droit à la limitation du traitement et à la portabilité de vos données.</p>
                <p>Concernant l’effacement, malgré votre demande de suppression de vos données, il se peut que nous devions conserver certaines des données stockées en raison des réglementations applicables. Dans ce cas, cependant, nous empêcherons tout traitement ultérieur de vos données.</p>
                <p>Vous disposez également du droit d’opposition pour motif légitime au traitement de vos données personnelles ainsi que du droit d’opposition au traitement de vos données à des fins de prospection.</p>
                <p>Vous pouvez exercer ces droits à tout moment en adressant au Data Policy Officer monsieur Jean-François REY :</p>
                <ul>
                    <li>un courrier postal : ALCOME SAS – 88, avenue des Ternes - 75017 PARIS - France&nbsp;;</li>
                    <li>un courriel : <a href="mailto:rgpd@alcome.eco">rgpd@alcome.eco</a>, en précisant l’objet votre demande.</li>
                </ul>
                <p>Toute demande devra être signée et accompagnée de la photocopie d’un titre d’identité portant votre signature et contenir l’adresse à laquelle devra vous parvenir la réponse.</p>
                <p>Une réponse vous sera alors adressée dans un délai d’un à trois mois suivant la réception de la demande.</p>
                <p>Vous disposez enfin du droit d’introduire une réclamation auprès de la CNIL&nbsp;:</p>
                <p>3 Place de Fontenoy - TSA 80715</p>
                <p>75334 PARIS CEDEX 07</p>
                <p>Tel: 01 53 73 22 22</p>
                <p>Ou en vous rendant sur la page: <a href="https://www.cnil.fr/fr/vous-souhaitez-contacter-la-cnil">https://www.cnil.fr/fr/vous-souhaitez-contacter-la-cnil</a></p>
                <h4>Quelle est la durée de conservation de vos données personnelles ?</h4>
                <p>Vos données personnelles sont conservées pendant une durée qui n’excède pas la durée nécessaire aux finalités pour lesquelles elles ont été collectées.</p>
                <p>Dès lors, les périodes de conservation des données personnelles sont amenées à varier en fonction des catégories de données personnelles collectées.</p>
                <p>En cas de contrat, vos données personnelles sont conservées pendant le temps nécessaire à l’exécution du contrat auquel vous avez souscrit. La durée de leur conservation commence à courir à la fin de la relation contractuelle.</p>
                <p>Lorsque vos données personnelles sont collectées sur la base du consentement, vous pouvez retirer votre consentement à tout moment.</p>
                <p>Lorsque vos données personnelles sont collectées sur la base de l’intérêt légitime, ALCOME a un intérêt à traiter vos données personnelles. Cette conservation est pertinente et non excessive. Le cas échéant, vous pouvez vous opposer à tout moment à un traitement de vos données personnelles basé sur l’intérêt légitime en contactant ALCOME dans les conditions définies au VII.</p>
                <p>Lorsque vos données personnelles sont collectées en raison d’une obligation légale ou lorsque vos données personnelles sont nécessaires pour que ALCOME puisse faire valoir ses droits ou se défendre en justice, ALCOME conservera vos données personnelles pendant le temps nécessaire ou bien jusqu'à ce que les litiges en cours soient résolus.
                    ALCOME - 88 avenue des Ternes 75017 PARIS - SAS au capital social de 100 000 €</p>
                <p>N° TVA Intracommunautaire FR86 892015355 - Code NAF 3821Z - Siret 89201535500019</p>
            </div>
        </>
    )
}

export default ConfidentialPolicytStep;
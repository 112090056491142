import { RouteObject } from "react-router-dom";
import AdminAmendmentList from "../components/admin/AdminAmendmentList/AdminAmendmentList";
import AdminAmendmentDetails from "../components/admin/AdminAmendmentDetails/AdminAmendmentDetails";


const routePrefix = 'admin'
const routeDomain = 'amendment'
const routeParam = 'amendmentId'

export const adminAmendmentRouteNames = {
  amendmentList: { path: `${routePrefix}/${routeDomain}`, title: `ROUTES.${routeDomain}.list` },
  amendmentDetails: { path: `${routePrefix}/${routeDomain}/:${routeParam}`, title: "ROUTES.infos" }
}

export const adminAmendmentRoutes: RouteObject[] = [
  {
    path: adminAmendmentRouteNames.amendmentList.path.replace(`${routePrefix}/`, ''),
    element: <AdminAmendmentList />,
  },
  {
    path: adminAmendmentRouteNames.amendmentDetails.path.replace(`${routePrefix}/`, ''),
    element: <AdminAmendmentDetails />
  },
]

import { RouteObject } from "react-router-dom";
import AdminProductList from "../components/admin/AdminProductList/AdminProductList";
import AdminProductDetails from "../components/admin/AdminProductDetails/AdminProductDetails";


const routePrefix = 'admin'
const routeDomain = 'product'
const routeParam = 'productId'

export const adminProductRouteNames = {
    productList: { path: `${routePrefix}/${routeDomain}/list`, title: `ROUTES.${routeDomain}.list` },
    productDetails: { path: `${routePrefix}/${routeDomain}/:${routeParam}`, title: `ROUTES.${routeDomain}.details` }
}

export const adminProductRoutes: RouteObject[] = [
    {
        path: adminProductRouteNames.productList.path.replace(`${routePrefix}/`, ''),
        element: <AdminProductList />,
    },
    {
        path: adminProductRouteNames.productDetails.path.replace(`${routePrefix}/`, ''),
        element: <AdminProductDetails />
    },
]

import { Models } from "@alcome-rep/alcome-types";
import { ISupplierOrder, SupplierOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces/supplier";
import { message } from "antd";
import { RcFile } from "antd/es/upload";
import apiClient from "../../common/services/apiClient";
import ApiService, { ApiSortType } from "../../common/services/apiService";

const SUPPLIER_SUPPLIER_COMPANY_PATH_API = 'supplier/supplierOrder';

class SupplierOrderApi extends ApiService<ISupplierOrder> {
  constructor() {
    super(SUPPLIER_SUPPLIER_COMPANY_PATH_API)
  }

  findAllSupplierOrders(query?: any, page?: number, limit?: number, sort?: ApiSortType) {
    return this.find({ query, page, pageSize: limit, sort })
      .then(r => ({ ...r, rows: r.rows.map(o => new Models.SupplierOrder(o)) }))
  }


  getSupplierOrderById(supplierOrderId: string) {
    return this.get(supplierOrderId)
      .then(o => new Models.SupplierOrder(o))
  }

  updateSupplierOrderStatus(supplierOrderId: string, data: { status: SupplierOrderStatusEnum }) {
    return this.update(supplierOrderId + "/status", data)
      .then(res => {
        message.success('Commande mise à jour avec succès')
        return new Models.SupplierOrder(res)
      })
      .catch(err => {
        message.error('Erreur lors de la mise à jour')
        return err
      })
  }

  getFileDocument(propertyName: keyof ISupplierOrder, supplierOrderId: string, supplierCompanyId: string, timestamp: string) {
    // return this.getFileDoc(propertyName, supplierOrderId)
    return apiClient({
      url: `${SUPPLIER_SUPPLIER_COMPANY_PATH_API}/${supplierOrderId}/supplierCompany/${supplierCompanyId}/${propertyName}${timestamp ? `?timestamp=${timestamp}` : ''}`,
      method: 'get',
      responseType: 'blob'
    }).then(r => r.data)
  }

  deleteFileDocument(supplierOrderId: string, supplierCompanyId: string, propertyName: string, timestamp?: number) {
    return this.custom<ISupplierOrder>('delete', `${supplierOrderId}/supplierCompany/${supplierCompanyId}/${propertyName}${timestamp ? `?timestamp=${timestamp}` : ''}`)
  }

  supplierOrderUploadDocument(supplierOrderId: string, supplierCompanyId: string, propertyName: string, file: RcFile) {
    const formData = new FormData();
    formData.append('file', file);
    return this.custom<ISupplierOrder>('post', `${supplierOrderId}/supplierCompany/${supplierCompanyId}/${propertyName}`, undefined, formData)
  }


}

const supplierOrderApi = new SupplierOrderApi();
export default supplierOrderApi;
import { Tag } from "antd";
import { useTranslation } from "react-i18next";

type PropsType = {
    communeType: string;
}

const ProspectCommuneType = ({ communeType }: PropsType) => {
    const { t } = useTranslation();
    return <Tag className="space-x-2" color={t('UI.TAG.CommuneTypeEnum.' + communeType)}>
        {t('TYPES.STRUCTURE.CommuneTypeEnum.' + communeType)}
    </Tag>
}

export default ProspectCommuneType;
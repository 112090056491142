import { AnnualReviewStatusEnum, IStructure } from '@alcome-rep/alcome-types/dist/interfaces';
import { UserTypeEnum } from '@alcome-rep/alcome-types/dist/interfaces/user';
import { QuestionCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/fr';
import { selectUser } from '../../../../Auth/state/auth.slice';
import LoadingScreen from '../../../../common/components/LoadingScreen';
import Info from '../../../../common/components/WarningUser/Info';
import { useAppSelector } from '../../../../common/hooks';
import Cartography from '../../../../Map/components/Cartography/cartography';
import { selectCurrentAdminStructure } from '../../../../Structure/state/structure-admin.slice';
import { selectCurrentStructure } from '../../../../Structure/state/structure-portal.slice';
import { selectAdminCurrentAnnualReview } from '../../../state/annual-review-admin.slice';
import { selectCurrentPortalAnnualReview } from '../../../state/annual-review-portal.slice';
moment().locale('fr')

type PropsType = {
  isAdmin?: boolean
}

const AnnualReviewMapStep = ({ isAdmin }: PropsType) => {

  const annualReviewSelectorToUse = isAdmin ? selectAdminCurrentAnnualReview : selectCurrentPortalAnnualReview;
  const annualReview = useAppSelector(annualReviewSelectorToUse);
  const user = useAppSelector(selectUser);
  const admin = (user && user.userType === UserTypeEnum.admin) || false
  const canEdit = !admin && annualReview!.status <= AnnualReviewStatusEnum.refused
  const structureAdmin = useAppSelector(selectCurrentAdminStructure)
  const structurePortal = useAppSelector(selectCurrentStructure)

  if (!annualReview || !user) {
    return <><LoadingScreen></LoadingScreen></>
  }

  return (
    <>
      {!admin && annualReview.status === AnnualReviewStatusEnum.pending && (
        <Info type="warning" icon={<QuestionCircleOutlined />} body={<>Remplissez la carte avec vos différents point de collecte et hotspots. Pour les groupements de commune vous devez le faire pour chaque commune. Une fois terminé, rendez vous sur l'onglet <span className="font-semibold">3. Communication</span>.</>} className="mb-6" /> 
      )}
      <Cartography user={user} structure={(admin ? structureAdmin : structurePortal) as IStructure} layer={annualReview} canEdit={canEdit} />
    </>
  );
};

export default AnnualReviewMapStep;
import { calculateAmountStatement } from '@alcome-rep/alcome-types';
import { IBaseCommune } from '@alcome-rep/alcome-types/dist/interfaces';
import { useEffect, useState } from 'react';


const useAmountStatement = (structure: IBaseCommune) => {

    const [amountStatement, setAmountStatement] = useState<number>();

    // fecthing amountStatement
    useEffect(() => {
        if (!structure) {
            return
        }
        setAmountStatement(calculateAmountStatement(structure.communes))
    }, [structure])

    return amountStatement
};

export default useAmountStatement;

import { Models } from "@alcome-rep/alcome-types";
import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import { TFunction } from "i18next";
import { NavigateFunction } from "react-router-dom";
import { ROUTES } from "../../../../common/router";
import { getRoute } from "../../../../common/tools/router-tools";

export const supplierListColumns = (query: any, t: TFunction, navigate: NavigateFunction): ColumnsType<Models.SupplierCompany> => [
    {
        fixed: 'left',
        width: "40px",
        align: 'center',
        render: (value: any, _, i) => {
            return (
                <Button
                    id={`action-button-${i}`}
                    type="link"
                    icon={<EditOutlined />}
                    onClick={(_e) =>
                        navigate(getRoute(ROUTES.ADMIN_SUPPLIER_COMPANY_ROUTES.supplierCompanyDetails, { supplierCompanyId: value.id }))}
                />
            )
        }
    },
    {
        title: 'Nom',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        className: 'font-semibold',
    },
    {
        title: 'Siret',
        dataIndex: 'siret',
        key: 'siret',
        align: 'center',
    },

];
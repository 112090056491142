import { RouteObject } from "react-router-dom";
import MemberDashboardPage from "../../Dashboard/components/member/MemberDashboardPage";

const routePrefix = 'member'

export const memberUserRouteNames = {
    userMemberHome: { path: `/${routePrefix}/home`, title: "ROUTES.home" },
}

export const memberUserRoutes: RouteObject[] = [
  {
    path: memberUserRouteNames.userMemberHome.path,
    element: <MemberDashboardPage/>,
  }
]
import { GeoPoint } from "@alcome-rep/alcome-types";
import { AcquisitionItemOrderStatusEnum, AcquisitionOrderItemType, AcquisitionOrderStatusEnum, DispositifCategoryEnum, DispositifTypeEnum, ICollectPoint } from "@alcome-rep/alcome-types/dist/interfaces";
import { User } from "@alcome-rep/alcome-types/dist/models";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, DatePicker, InputNumber, Modal, Table, TableColumnType, Upload, UploadProps, message } from "antd";
import { RcFile } from "antd/es/upload";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectUser } from "../../../../Auth/state/auth.slice";
import CollectPointModal from "../../../../Map/components/CollectPoint/collectPointModal";
import MapComponent from "../../../../Map/components/Gmap/mapComponent";
import mapApi from "../../../../Map/services/map.api";
import { selectCurrentStructure } from "../../../../Structure/state/structure-portal.slice";
import Section from "../../../../common/components/Panels/Section";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks";
import { debounce, randomNumberId } from "../../../../common/tools/global-tools";
import acquisitionOrderAdminApi from "../../../services/admin-acquisitionOrder.api";
import acquisitionOrderPortalApi from "../../../services/portal-acquisitionOrder.api";
import { getAdminAcquisitionOrderAction, selectCurrentAdminAcquisitionOrder } from "../../../state/acquisitionOrder-admin.slice";
import { selectCurrentPortalAcquisitionOrder, updatePortalAcquisitionOrderAction } from "../../../state/acquisitionOrder-portal.slice";
import AcquisitionOrderMaterialInvoices from "./AcquisitionOrderMaterialInvoices";

const AcquisitionOrderMaterial = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser)
  const isAdmin = User.IsUserAdmin(user)
  const acquisitionOrder = useAppSelector(User.IsUserAdmin(user) ? selectCurrentAdminAcquisitionOrder : selectCurrentPortalAcquisitionOrder);

  const [items, setItems] = useState<AcquisitionOrderItemType[]>([])
  const [tableRows, setTableRows] = useState<AcquisitionOrderItemType[]>([])
  const [supportTableRows, setSupportTableRows] = useState<AcquisitionOrderItemType[]>([])

  const [mode, setMode] = useState<'supportOrder' | 'acquisitionOrder'>('acquisitionOrder')

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<AcquisitionOrderItemType | null>(null);
  const [currentCollectPoint, setCurrentCollectPoint] = useState<ICollectPoint>();

  const structure = useAppSelector(selectCurrentStructure)

  useEffect(() => {
    if (acquisitionOrder)
      setItems(acquisitionOrder.items ?? [])
  }, [acquisitionOrder])

  useEffect(() => {
    const lines = items.filter(it => it.status === AcquisitionItemOrderStatusEnum.validated).reduce((lines, item) => {
      for (let i = 0; i < item.quantity; i++) {
        lines.push({ ...item, id: item.id + '-' + i })
      }
      return lines
    }, [] as AcquisitionOrderItemType[])
    setTableRows(lines.filter(item => !item.isSupport))
    setSupportTableRows(lines.filter(item => item.isSupport))
  }, [items])

  if (!acquisitionOrder) {
    return <></>
  }

  // const sendDeclaration = async () => {

  //   const arrItemsWithCp = acquisitionOrder && acquisitionOrder.items.map(item => {
  //     if (item.quantity === item.collectpoints?.length) {
  //       return true
  //     } else {
  //       return false
  //     }
  //   })

  //   const userCantSendDeclaration = arrItemsWithCp.filter(el => el === false).length > 0

  //   if (userCantSendDeclaration) {
  //     message.error("Merci de renseigner tous les dispositifs avant d'envoyer votre déclaration")
  //     return;
  //   } else {
  //     await acquisitionOrderPortalApi.sendDeclaration(acquisitionOrder.id as string)
  //   }

  // }

  const setAcquisitonOrderDeclarationStatus = async (status: AcquisitionOrderStatusEnum) => {
    const updatedAcquisitionOrder = await acquisitionOrderAdminApi.updateOrderDeclarationStatus(acquisitionOrder!.id as string, status)
    if (updatedAcquisitionOrder) {
      message.success('Mise à jour de la demande de soutien effectuée')
      dispatch(getAdminAcquisitionOrderAction({ id: acquisitionOrder.id }))
    }
  }

  const getProps = (collectpointId: string, itemId: string): UploadProps => ({
    beforeUpload: file => {
      addPictureToCp(collectpointId, itemId, file);
      return false;
    }
  })

  const addPictureToCp = (collectpointId: string, itemId: string, file: RcFile) => {
    mapApi.uploadPictureInstallationAcquisitionOrder(acquisitionOrder.id as string, collectpointId, file)
      .then(res => {
        if (res) {
          const findItem = items.find(i => i.id === itemId)
          if (findItem) {
            const findCp = findItem.collectpoints && findItem?.collectpoints.find(c => c.id === collectpointId);
            if (findCp) {
              findCp.pictureInstallation = res.pictureInstallation
              acquisitionOrderPortalApi.updateAcquisitionOrder(acquisitionOrder.id as string, { ...acquisitionOrder, items })
            }
          }
        }
      })
  }

  const downloadPicture = async (collectpoint: ICollectPoint) => {
    await mapApi.downloadPicture(collectpoint, collectpoint.structureId)
  }


  const updateCpProperty = debounce(async (collectpoint: ICollectPoint, selectedItem: AcquisitionOrderItemType, property: string, value: any) => {
    const matches = selectedItem.id.match(/(.+)-(\d+)$/);
    if (!matches)
      return
    const [, itemId, index] = matches
    const newItems = [...items.map(it => ({ ...it }))]
    const item = newItems.find(i => i.id === itemId)
    if (!item)
      return;

    const collectPoints = (item.collectpoints ?? []).map(cp => ({ ...cp }))
    const cp = (await mapApi.updateCollectPoint(collectpoint.id as string, {
      status: collectpoint.status,
      placeType: collectpoint.placeType,
      dispositifType: collectpoint.dispositifType,
      geoPoint: collectpoint.geoPoint,
      comments: collectpoint.comments,
      [property]: value
    }))
    collectPoints[+index] = cp
    item.collectpoints = collectPoints
    setItems(newItems)

    if (value !== null)
      dispatch(updatePortalAcquisitionOrderAction({ id: acquisitionOrder.id as string, data: { ...acquisitionOrder, items: newItems } }))
    else
      return;
  }, 400)

  const columns: TableColumnType<AcquisitionOrderItemType>[] = [
    {
      title: 'Produit',
      align: "left",
      dataIndex: ['product', 'name'],
    },
    {
      title: 'Catégorie',
      dataIndex: ['category'],
      render: (v: DispositifCategoryEnum) => v && <span>{t('TYPES.INVENTORY.DispositifCategoryEnum.' + v)}</span>,
    },
    {
      title: 'Type',
      dataIndex: ['type'],
      render: (v: DispositifTypeEnum) => v && <span>{t('TYPES.INVENTORY.DispositifTypeEnum.' + v)}</span>,
    },
    {
      title: 'Hotpost',
      dataIndex: ['hotspot', 'name'],
      align: 'center',
      render: (name: string) => <span>{name ?? 'N/A'}</span>,
    },
    {
      key: randomNumberId(),
      align: 'center',
      render: (value: AcquisitionOrderItemType) => {
        const match = value.id.match(/-(\d+)$/)
        if (!match)
          return <></>
        const index = match[1]
        const collectPoint = (value.collectpoints || [])[+index];

        if (!collectPoint && !isAdmin) {
          return <Button type="link" onClick={_ => openModal(value)}> Placer le dispositif</Button >
        } else if (!collectPoint) {
          return <div>En attente de dispositifs</div>
        }
        else if (collectPoint && collectPoint.pictureInstallation) {
          return (
            <Button
              icon={<DownloadOutlined />}
              className="btn-link"
              onClick={() => downloadPicture(value.collectpoints![+index])}
            >Télécharger la photo</Button>
          )
        }
        else if (collectPoint && !isAdmin) {
          return (
            <Upload {...getProps(collectPoint.id as string, value.id)}>
              <Button htmlType='button' icon={<UploadOutlined />}>Chargez votre photo</Button>
            </Upload>
          )
        }
      }
    }
  ]
  const supportColumns: TableColumnType<AcquisitionOrderItemType>[] = [
    // {
    //   title: 'Produit',
    //   align: "left",
    //   dataIndex: ['product', 'name'],
    // },
    {
      title: 'Catégorie',
      dataIndex: ['category'],
      render: (v: DispositifCategoryEnum) => v && <span>{t('TYPES.INVENTORY.DispositifCategoryEnum.' + v)}</span>,
    },
    // {
    //   title: 'Type',
    //   dataIndex: ['type'],
    //   render: (v: DispositifTypeEnum) => v && <span>{t('TYPES.INVENTORY.DispositifTypeEnum.' + v)}</span>,
    // },
    {
      title: 'Hotpost',
      dataIndex: ['hotspot', 'name'],
      align: 'center',
      render: (name: string) => <span>{name ?? 'N/A'}</span>,
    },
    {
      title: "Montant",
      align: 'center',
      key: randomNumberId(),
      render: (_v: number, value: AcquisitionOrderItemType) => {
        const match = value.id.match(/-(\d+)$/)
        if (!match)
          return <></>
        const index = match[1]
        const collectPoint = (value.collectpoints || [])[+index];

        if (!value.isSupport)
          return <></>

        if (collectPoint) {
          return (
            <div>
              {value.isSupport && (
                <InputNumber
                  suffix='€'
                  value={collectPoint.price}
                  onChange={(val) => updateCpProperty(collectPoint, value, 'price', val as number)}
                  disabled={acquisitionOrder.status > AcquisitionOrderStatusEnum.acquisition_order_declarations_pending}
                  min={0} />
              )}
            </div>
          )
        }
      }
    },
    {
      title: "Date d'installation",
      align: 'center',
      key: randomNumberId(),
      render: (_v: number, value: AcquisitionOrderItemType) => {
        const match = value.id.match(/-(\d+)$/)
        if (!match)
          return <></>
        const index = match[1]
        const collectPoint = (value.collectpoints || [])[+index];

        if (!value.isSupport)
          return <></>

        if (collectPoint) {
          return (
            <div>
              {value.isSupport && (
                <DatePicker
                  format={'DD/MM/YYYY'}
                  value={dayjs(collectPoint.dateInstallation)}
                  onChange={(val) => updateCpProperty(collectPoint, value, 'dateInstallation', val)}
                  disabled={acquisitionOrder.status > AcquisitionOrderStatusEnum.acquisition_order_declarations_pending}
                />
              )}
            </div>
          )
        }
      }
    },
    {
      key: randomNumberId(),
      width: '250px',
      render: (value: AcquisitionOrderItemType) => {
        const match = value.id.match(/-(\d+)$/)
        if (!match)
          return <></>
        const index = match[1]
        const collectPoint = (value.collectpoints || [])[+index];

        if (!collectPoint && !isAdmin) {
          return <Button type="link" onClick={_ => openModal(value)}> Placer le dispositif</Button >
        } else if (!collectPoint) {
          return <div>En attente de dispositifs</div>
        }
        else if (collectPoint && collectPoint.pictureInstallation) {
          return (
            <Button
              icon={<DownloadOutlined />}
              className="btn-link"
              onClick={() => downloadPicture(value.collectpoints![+index])}
            >Télécharger la photo</Button>
          )
        }
        else if (collectPoint && !isAdmin) {
          return (
            <Upload  {...getProps(collectPoint.id as string, value.id)}>
              <Button htmlType='button' icon={<UploadOutlined />}>Chargez votre photo</Button>
            </Upload>
          )
        }
      }
    }
  ]

  const openModal = (item: AcquisitionOrderItemType) => {
    if (!item.isSupport) {
      setMode('acquisitionOrder')
    } else {
      setMode('supportOrder')
    }
    setSelectedItem(item)
    setIsModalOpen(true)
  }

  const insertGeoPoint = (geoPoint: GeoPoint | GeoPoint[]) => {
    if (selectedItem?.hotspot) {
      const polygon = new google.maps.Polygon()
      polygon.setPath(selectedItem.hotspot.geoPoints)
      if (!google.maps.geometry.poly.containsLocation(geoPoint as GeoPoint, polygon)) {
        alert("Le point de collecte doit se situer dans le hotspot " + selectedItem.hotspot.name + " en surbrillance");
        return;
      }
    }
    setCurrentCollectPoint({
      geoPoint: Array.isArray(geoPoint) ? geoPoint[0] : geoPoint,
      dispositifType: selectedItem?.type
    } as ICollectPoint)

    setIsModalOpen(false)
  }

  const handleOk = async (data: ICollectPoint) => {
    if (!selectedItem || !acquisitionOrder)
      return;

    const matches = selectedItem.id.match(/(.+)-(\d+)$/);
    if (!matches)
      return
    const [, itemId, index] = matches
    const newItems = [...items.map(it => ({ ...it }))]
    const item = newItems.find(i => i.id === itemId)
    if (!item)
      return;

    const collectPoints = (item.collectpoints ?? []).map(cp => ({ ...cp }))
    const collectPointsId = [...(item.collectpointsId ?? [])]
    const cp = (await mapApi.createCollectPoint(data, acquisitionOrder.annualReviewId, true))
    collectPoints[+index] = cp
    collectPointsId[+index] = cp.id!
    item.collectpoints = collectPoints
    item.collectpointsId = collectPointsId
    setItems(newItems)

    dispatch(updatePortalAcquisitionOrderAction({ id: acquisitionOrder.id as string, data: { ...acquisitionOrder, items: newItems } }))
    setCurrentCollectPoint(undefined)
  }

  return (
    <>
      {tableRows && tableRows.length > 0 &&
        <Section title="Demandes de dispositifs">
          <Table<AcquisitionOrderItemType>
            dataSource={tableRows}
            rowKey={'id'}
            size="small"
            columns={columns}
            pagination={{
              hideOnSinglePage: true
            }}
          />
        </Section>
      }

      <Section title="Demandes de soutien">
        <Table<AcquisitionOrderItemType>
          dataSource={supportTableRows}
          rowKey={'id'}
          size="small"
          columns={supportColumns}
          pagination={{
            hideOnSinglePage: true
          }}
        />

        <AcquisitionOrderMaterialInvoices acquisitionOrder={acquisitionOrder} />

      </Section>


      {/* {!isAdmin && acquisitionOrder.status < AcquisitionOrderStatusEnum.acquisition_order_declarations_completed && (
        <Button className="mt-4" type="primary" onClick={sendDeclaration}>Envoyer la déclaration</Button>
      )} */}

      {
        isAdmin && acquisitionOrder.status === AcquisitionOrderStatusEnum.acquisition_order_declarations_completed && (
          <div className="flex space-x-4 mt-4">
            <Button type="primary" onClick={() => setAcquisitonOrderDeclarationStatus(AcquisitionOrderStatusEnum.acquisition_order_declarations_approved)}>Approuver la déclaration</Button>
            <Button className="text-alc-danger" type="link" onClick={() => setAcquisitonOrderDeclarationStatus(AcquisitionOrderStatusEnum.acquisition_order_declarations_refused)}>Refuser la déclaration</Button>
          </div>
        )
      }

      <Modal
        className="h-dvh !w-10/12 !lg:w-9/12"
        title="Sélectionnez les points de collecte"
        open={isModalOpen}
        onCancel={_ => setIsModalOpen(false)}
        destroyOnClose={true}
        footer={false}
      >
        <p>Cliquez sur le marqueur présent sur le menu de la carte pour insérer un point de collecte.</p>
        <div className="container">
          <MapComponent
            structure={structure!}
            editMode="clickLocation"
            onMapInsert={insertGeoPoint}
            initialSelection={selectedItem?.hotspot ? [selectedItem.hotspot] : undefined}
          />
        </div>
      </Modal>

      <CollectPointModal
        structure={structure!}
        mode={mode}
        canEdit={true}
        collectPoint={currentCollectPoint}
        onOk={handleOk}
        onCancel={() => setCurrentCollectPoint(undefined)}
      />
    </>
  )
}

export default AcquisitionOrderMaterial
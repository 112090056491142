import { IStructure } from "@alcome-rep/alcome-types/dist/interfaces";
import { Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import { Dispatch, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../common/hooks";
import useFormRules from "../../../common/hooks/useFormRules";
import { ROUTES } from "../../../common/router";
import { CodeInseeField } from "../../../Structure/components/InseeSiren/codeInseeField";
import { locallySaveStructureAction, selectLocallySavedStructure } from "../../state/register.slice";
import NextStepComponent from "../Register/NextStepComponent";

const GrpCommunesForm = () => {
  const navigate = useNavigate()
  const structure = useAppSelector(selectLocallySavedStructure)

  const [isRegistered, setIsRegistered] = useState<boolean>(false)
  const { required, zipCode, phoneNumber } = useFormRules()

  const [form] = useForm()
  const dispatch: Dispatch<any> = useAppDispatch()

  const submit = (data: IStructure) => {
    dispatch(locallySaveStructureAction({ ...structure, ...data }))
    return navigate(ROUTES.REGISTER_ROUTES.PROFILE_STEP)
  }

  const loadSiren = (name: string, siren: string, isRegistered: boolean) => {
    setIsRegistered(isRegistered)
    form.setFieldValue('name', name)
    form.setFieldValue('siren', siren)
  }

  if (!structure) {
    return <Navigate to={ROUTES.REGISTER} />
  }

  return (
    <>
      <h2>Informations sur la collectivité</h2>
      <p>Veuillez complèter les informations concernant votre commune.</p>

      <Form
        form={form}
        name="basic"
        layout='vertical'
        onFinish={submit}
        initialValues={structure}
        id="pa"
      >
        <div className='grid grid-cols-2 gap-x-4'>
          <Form.Item
            name={['siren']}
            className="hidden"
          >
            <Input type="hidden" />
          </Form.Item>
          {structure && <CodeInseeField selectCommune={loadSiren} structureType={structure.structureType} defaultSirenValue={structure.siren} />}

          <Form.Item
            label="Nom du groupement de communes"
            name={['name']}
            rules={[required]}
          >
            <Input readOnly></Input>
          </Form.Item>
          <h6>Coordonnées de la structure</h6>
          <Form.Item
            label="Rue"
            name={['address', 'street']}
            className="col-span-2"
            rules={[required]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="Code postal"
            name={['address', 'zip']}
            rules={[required, zipCode]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="Ville"
            name={['address', 'city']}
            rules={[required]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="Email"
            name={['email']}
            rules={[required]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="Téléphone"
            name={['phone']}
            rules={[required, phoneNumber]}
          >
            <Input></Input>
          </Form.Item>
        </div>

        <Form.Item>
          <NextStepComponent disable={isRegistered} />
        </Form.Item>
      </Form>
    </>
  )
}

export default GrpCommunesForm;


import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { defaultPagination } from '../app-constants';
import { getSearchParams } from '../tools/router-tools';

const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { page = 1, limit = defaultPagination.pageSize, ...query } = getSearchParams(searchParams);

  const [queryParams, setQueryParams] = useState<{ page: number, limit: number, sortField?: string, sortOrder?: 1 | -1, query: any }>({
    page, limit, query
  })

  useEffect(() => {
    const { page = 1, limit = defaultPagination.pageSize, sortField, sortOrder, ...query } = getSearchParams(searchParams);

    if (Number(limit) !== Number(queryParams.limit) || JSON.stringify(query) !== JSON.stringify(queryParams.query)) {
      searchParams.set('page', defaultPagination.page.toString())
      setSearchParams(searchParams)
    }

    if (queryParams.limit !== limit
      || queryParams.page !== page
      || queryParams.sortField !== sortField
      || queryParams.sortOrder !== sortOrder
      || JSON.stringify(queryParams.query) !== JSON.stringify(query))
      setQueryParams({ page, limit, query, sortField, sortOrder })
  },
    // eslint-disable-next-line
    [searchParams, setSearchParams, setQueryParams])


  return queryParams;

};

export default useQueryParams;

import { DispositifCategoryEnum } from "@alcome-rep/alcome-types/dist/interfaces"
import { AlertRules, ShippingRegionEnum } from "@alcome-rep/alcome-types/dist/interfaces/supplier"
import { SupplierCompany } from "@alcome-rep/alcome-types/dist/models"
import { CloseOutlined } from "@ant-design/icons"
import { Button, InputNumber, Select, Table, TableColumnsType, message } from "antd"
import { t } from "i18next"
import { useState } from "react"
import adminSupplierCompanyApi from "../../../services/admin-supplierCompany.api"

const SupplierAlertRulesComponent = ({ supplier }: { supplier: SupplierCompany }) => {

    const [alertRules, setAlertRules] = useState<AlertRules[]>(supplier.alertRules || [])

    const save = () => {
        adminSupplierCompanyApi.updateSupplierCompanyAlertRules(supplier.id!, { alertRules })
            .then(res => {
                message.success('Règles mis à jours')
                return res
            })
    }

    const addRule = () => {
        const newAlertrules = [...alertRules.map(a => ({ ...a }))]
        newAlertrules.push({
            category: [DispositifCategoryEnum.ashtray, DispositifCategoryEnum.extinguisher],
            quantity: 1,
            region: [ShippingRegionEnum.metropole]
        })

        setAlertRules(newAlertrules)
    }

    const removeRule = (index: number) => {
        const newAlertrules = [...alertRules.map(a => ({ ...a }))]
        newAlertrules.splice(index, 1)
        setAlertRules(newAlertrules)
    }

    const updateRuleValue = (index: number, prop: string, value: any) => {
        const newAlertrules = [...alertRules.map(a => ({ ...a }))];
        const rule: any = newAlertrules[index];
        rule[prop] = value
        setAlertRules(newAlertrules)
    }

    const columns: TableColumnsType<AlertRules> = [
        {
            title: "Quantité commandée",
            dataIndex: "quantity",
            render: (v: number, _r: AlertRules, index: number) => <InputNumber value={v} onChange={val => updateRuleValue(index, "quantity", val)} />
        },
        {
            title: "Catégorie concernée",
            dataIndex: "category",
            render: (v: DispositifCategoryEnum, _, index: number) => <Select
                mode="multiple"
                value={v}
                className="w-52"
                onChange={val => updateRuleValue(index, 'category', val)}
                options={Object.keys(DispositifCategoryEnum).map(k => ({ value: k, label: t('TYPES.INVENTORY.DispositifCategoryEnum.' + k) }))}
            />
        },
        {
            title: "Région concernée",
            dataIndex: "region",
            render: (v: ShippingRegionEnum, _, index: number) => <Select
                mode="multiple"
                value={v}
                className="w-52"
                onChange={val => updateRuleValue(index, 'region', val)}
                options={Object.keys(ShippingRegionEnum).map(k => ({ value: k, label: t('TYPES.PRODUCT.ShippingRegionEnum.' + k) }))}
            />
        },
        { key: 'action', render: (_v, _item, index) => <Button onClick={_ => removeRule(index)} type="link" danger icon={<CloseOutlined />} /> },
    ]

    return <div>
        <Table<AlertRules>
            rowKey={'ruleActivation'}
            dataSource={alertRules}
            locale={{
                emptyText: () => "Aucune règle définie"
            }}
            columns={columns}
            pagination={false}
            footer={() => {
                return <div className="flex space-x-2">
                    <Button type="link" onClick={_ => addRule()}>Ajouter une règle</Button>
                </div>
            }}
        />
        <div className="mt-4">
            <Button type='primary' onClick={_ => save()}>{t('COMMON.save')}</Button>
        </div>
    </div>
}

export default SupplierAlertRulesComponent
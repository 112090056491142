import { Table } from 'antd';
import { useState } from 'react';
import { useAppSelector } from '../../../../common/hooks';
import { ChildCommune, IBaseCommune, IStructure } from '@alcome-rep/alcome-types/dist/interfaces';
import { selectCurrentAdminStructure } from '../../../state/structure-admin.slice';

const CommuneListComponent = () => {
    const DEFAULT_PAGE_SIZE = 10;
    const structure = useAppSelector(selectCurrentAdminStructure) as IStructure

    const [loading] = useState<boolean>(false);

    const columns: any = [
        {
            title: "Insee",
            dataIndex: 'siren',
            key: 'siren',
        },
        {
            title: "Nom",
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: "Email",
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: "Population",
            render: (value: any) => {
                return <span>{value.population ?? '-'}</span>
            }
        },
        {
            title: "Type de commune",
            dataIndex: 'type',
            key: 'type',
        }
    ];

    return (
        <>
            <div className=''>
                <div className='flex mb-4 items-center justify-between'>
                    <h4 className='flex space-y-4 mt-4'>Liste des communes</h4>
                </div>
                <div className='grid grid-cols-1 grid-rows-2 gap-6'>
                    <Table<ChildCommune>
                        style={{ cursor: 'pointer' }}
                        columns={columns}
                        dataSource={(structure as IBaseCommune).communes}
                        loading={loading}
                        size='small'
                        rowKey="siren"
                        pagination={{
                            hideOnSinglePage: true,
                            pageSize: DEFAULT_PAGE_SIZE,
                            total: (structure as IBaseCommune).communes.length
                        }} />
                </div>
            </div>
        </>

    );
};

export default CommuneListComponent;
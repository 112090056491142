import { IStructure } from '@alcome-rep/alcome-types/dist/interfaces';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Pie, RingProgress } from '@ant-design/plots';
import { Button, Table, Tabs, TabsProps } from 'antd';
import { Excel } from "antd-table-saveas-excel";
import confirm from 'antd/lib/modal/confirm';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { numericFormatter } from 'react-number-format';
import structureAdminApi from '../../../Structure/services/structure-admin.api';
import PageCanvas from '../../../common/components/Panels/PageCanvas';
import Section from '../../../common/components/Panels/Section';
import { columnsStats, columsExcel } from './AdminDashboardComponent.constant';
import AnnualReviewToValidateList from './AnnualReviewToValidateComponent';
import ContractToValidateList from './ContractToValidateComponent';
import InventoryToValidateList from './InventoryToValidateComponent';

const AdminDashBoardPage = () => {

  const { t } = useTranslation()

  const [dataStructuresExcel, setDataStructuresExcel] = useState<IStructure[]>([]);
  const [adminStats, setAdminStats] = useState<any>([]);

  const getDataStructuresExcel = async () => {
    const { data } = await structureAdminApi.getExportStructures()
    setDataStructuresExcel(data.rows)
  }

  const getAdminStats = useCallback(async () => {
    const response = await structureAdminApi.getStructureAdminStats();
    const data = Object.keys(response).map((k) => ({ label: t(`UI.ADMIN.DASHBOARD.stats.${k}`), value: (response as any)[k] }))
    setAdminStats(data);
  }, [t])

  useEffect(() => {
    getAdminStats();
  }, [getAdminStats]);

  const exportExcel = async () => {
    await getDataStructuresExcel()
    confirm({
      title: 'Voulez-vous télécharger le reporting ?',
      icon: <QuestionCircleOutlined />,
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okText: 'Oui',
      okButtonProps: { className: "btn" },
      onOk() {
        let excel = new Excel();
        excel
          .addSheet("commune")
          .addColumns(columsExcel())
          .addDataSource(dataStructuresExcel, {
            str2Percent: true,
          })
          .saveAs("reporting.xlsx");
      },
      onCancel() {
      },
    });
  }
  const structureTypeStats = adminStats.slice(1, 4)
  const contractTypeStats = adminStats.slice(4, 7)
  const communeTypeStats = adminStats.slice(7, 11)
  const popTypeStats = adminStats.length > 0 && ((adminStats[11].value / adminStats[13].value)) as number

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Contrats',
      children: <ContractToValidateList />
    },
    {
      key: '2',
      label: 'Annexes',
      children: <InventoryToValidateList />
    },
    {
      key: '3',
      label: 'Bilans',
      children: <AnnualReviewToValidateList />
    },
  ];

  return (
    <PageCanvas title="Tableau de bord" actions={
      <Button htmlType="button" type="primary" className="bg-alc-blue " onClick={exportExcel}>Télécharger les données</Button>
    }>
      <div className='grid grid-cols-3 gap-4'>
        <div>
          <Section title='Population couverte'>
            <RingProgress
              style={{ height: 100, width: 100 }}
              color={['#5B8FF9', '#E8EDF3']}
              autoFit={false}
              percent={popTypeStats as number}
            />
          </Section>
        </div>
        <div className='col-span-2'>
          <Section title='Données principales'>
            <div className='text-alc-blue'>
              {adminStats.length > 0 && (
                <span className='text-base '> {adminStats[0].label} :
                  <span className='text-alc-blue font-semibold'>
                    {numericFormatter(` ${adminStats[0].value}`, { thousandSeparator: ' ', decimalScale: 0 })}
                  </span>
                </span>
              )}
            </div>
            <div className='text-alc-blue'>
              {adminStats.length > 0 && (
                <span className='text-base '> {adminStats[6].label} :
                  <span className='text-alc-blue font-semibold'>
                    {numericFormatter(` ${adminStats[6].value}`, { thousandSeparator: ' ', decimalScale: 0 })}
                  </span>
                </span>
              )}
            </div>
            <div className='text-alc-blue'>
              {adminStats.length > 0 && (
                <span className='text-base '> {adminStats[12].label} :
                  <span className='text-alc-blue font-semibold'>
                    {numericFormatter(` ${adminStats[12].value}`, { thousandSeparator: ' ', decimalScale: 0 })}
                  </span>
                </span>
              )}
            </div>
            <div className='text-alc-blue space-x-2'>
              {adminStats.length > 0 && (
                <span className='text-base'> {adminStats[11].label} :
                  <span className='text-alc-blue font-semibold'>
                    {numericFormatter(` ${adminStats[11].value}`, { thousandSeparator: ' ', decimalScale: 0 })}
                  </span>
                </span>
              )}
            </div>
          </Section>
        </div>
        {/* <div></div> */}

        <div>
          <Section title='Type de structure '>
            <Pie data={structureTypeStats}
              angleField="value"
              legend={{ layout: 'vertical', position: 'bottom' }}
              colorField="label"
              // radius={0.8}
              // label={{
              //   type: 'inner',
              //   offset: '-33%',
              //   content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
              //   style: {
              //     fontSize: 14,
              //     textAlign: 'center',
              //   },
              // }}
              interactions={[{ type: "element-active" }]}></Pie>
          </Section>
        </div>

        <div>
          <Section title='Type de commune'>
            <Pie data={communeTypeStats}
              angleField="value"
              colorField="label"
              radius={0.8}
              legend={{ layout: 'vertical', position: 'bottom' }}
              // label={{
              //   type: 'inner',
              //   offset: '-33%',
              //   content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
              //   style: {
              //     fontSize: 14,
              //     textAlign: 'center',
              //   },
              // }} 
              interactions={[{ type: "element-active" }]}></Pie>
          </Section>
        </div>
        <div>
          <Section title='Statut des contrats'>
            <Pie data={contractTypeStats}
              angleField="value"
              colorField="label"
              radius={0.8}
              legend={{ layout: 'vertical', position: 'bottom' }}
              // label={{
              //   type: 'inner',
              //   offset: '-33%',
              //   content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
              //   style: {
              //     fontSize: 14,
              //     textAlign: 'center',
              //   },
              // }} 
              interactions={[{ type: "element-active" }]}></Pie>
          </Section>
        </div>

        <div>
          <Section title='Données détaillées'>
            <Table dataSource={adminStats} columns={columnsStats()} pagination={false} rowKey="label" />
          </Section>
        </div>

        <div className='col-span-2'>
          <Section title='Documents à valider'>
            <Tabs defaultActiveKey="1" items={items} />
          </Section>
        </div>

      </div>
    </PageCanvas>
  );
};

export default AdminDashBoardPage;
import { Outlet } from 'react-router-dom';
import PortalNavBar from './PortalNavBar';
import PortalSideBar from './PortalSideBar';

const PortalLayout = ({ disableSideBar = false }) => (<>
  <>
    <div className="pageLayout overflow-auto">
      <div className='pageLayout-header'>
        <div>
          <PortalNavBar></PortalNavBar>
        </div>
      </div>
      <div className='pageLayout-menu'>
        <div>
          {!disableSideBar && <PortalSideBar></PortalSideBar>}
        </div>
      </div>

      <div className='pageLayout-page'>
        <div className='pageLayout-content'>
          <Outlet></Outlet>
        </div>
      </div>
    </div>
  </>



</>)

export default PortalLayout;
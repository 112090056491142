import { IAnnualReview } from "@alcome-rep/alcome-types/dist/interfaces";
import { Button, DatePicker, Form, Input, InputNumber } from "antd";
import { useForm } from "antd/lib/form/Form";
import { validateIBAN } from "ibantools";
import moment from "moment";
import { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../common/hooks";
import useFormRules from "../../../../common/hooks/useFormRules";
import { updateAnnualReviewLatestInfosAction } from "../../../state/annual-review-portal.slice";

const RevenueOrderForm = ({ annualReview, disabled = false }: { annualReview: IAnnualReview, disabled?: boolean }) => {

    const [form] = useForm()
    const { required } = useFormRules();
    const dispatch: Dispatch<any> = useAppDispatch()
    const { t } = useTranslation();

    const save = (formData: any) => {
        dispatch(updateAnnualReviewLatestInfosAction({ id: annualReview.id, data: formData }))
    }

    return (
        <Form
            form={form}
            initialValues={{
                ...annualReview,
                revenueOrder: {
                    ...annualReview.revenueOrder,
                    revenueOrderDate: moment(annualReview.revenueOrder?.revenueOrderDate)
                },
                structure: (annualReview.structureId as any)['id']
            }}
            layout='vertical'
            onFinish={save}
        >
            <div className='grid grid-cols-2 gap-x-6'>
                <Form.Item
                    name={['structure']}
                    className='hidden'
                >
                    <Input type='hidden'></Input>
                </Form.Item>
                <Form.Item
                    label='Désignation du comptable public'
                    name={['revenueOrder', 'accountantDesignation']}
                    rules={[required]}
                >
                    <Input disabled={disabled} />
                </Form.Item>
                <Form.Item
                    label='Coordonnées du comptable public'
                    name={['revenueOrder', 'accountantContact']}
                    rules={[required]}
                >
                    <Input disabled={disabled} />
                </Form.Item>
                <Form.Item
                    label='Ordonnateur'
                    name={['revenueOrder', 'authorisingOfficer']}
                    rules={[required]}
                >
                    <Input disabled={disabled} />
                </Form.Item>
                <Form.Item
                    label='Année'
                    name={['revenueOrder', 'year']}
                    rules={[required]}
                >
                    <InputNumber min={2021} max={new Date().getFullYear()} disabled={disabled} className="w-full"></InputNumber>
                </Form.Item>
                <Form.Item
                    label='Numéro de titre de recette'
                    name={['revenueOrder', 'revenueOrderNumber']}
                    rules={[required]}
                >
                    <Input disabled={disabled} />
                </Form.Item>
                <Form.Item
                    label="Date d'émission"
                    name={['revenueOrder', 'revenueOrderDate']}
                    rules={[required]}
                >
                    <DatePicker className='w-full' format={'DD/MM/YYYY'} disabled={disabled} />
                </Form.Item>
                <Form.Item
                    label='Objet complet'
                    name={['revenueOrder', 'subject']}
                    rules={[required]}
                >
                    <Input disabled={disabled} />
                </Form.Item>
                <Form.Item
                    label='Montant'
                    name={['revenueOrder', 'amount']}
                    rules={[required]}
                    initialValue={annualReview.amountStatement}
                >
                    <InputNumber
                        formatter={(value: string | undefined) => `${(value || '').replace(/,/g, '.')}`}
                        parser={(value) => value!.replace(/,/g, '.')}
                        disabled={disabled}
                        className="w-full"
                    >
                    </InputNumber>
                </Form.Item>
                <Form.Item
                    label='Référence pour le paiement'
                    name={['revenueOrder', 'paymentRef']}
                    rules={[required]}
                >
                    <Input disabled={disabled} />
                </Form.Item>
                <Form.Item
                    label='IBAN'
                    name={['revenueOrder', 'iban']}
                    normalize={(value) => value.replace(/\s/g, '')}
                    rules={[required, { validator: (_, v, c) => validateIBAN(v).valid ? c() : c("invalid iban"), message: "IBAN Invalide"}]}
                >
                    <Input disabled={disabled} />
                </Form.Item>
                <Form.Item className='col-span-2 mb-0'>
                    <Button htmlType="submit" type="primary" className="bg-alc-blue" disabled={disabled}>{t('COMMON.save')}</Button>
                </Form.Item>
            </div>
        </Form>
    )
}

export default RevenueOrderForm;
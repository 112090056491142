import { SupportOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { Tag } from "antd"
import { useTranslation } from "react-i18next"


const SupportOrderStatusComponent = ({ status }: { status: SupportOrderStatusEnum }) => {

  const { t } = useTranslation();

  return <Tag color={t(`UI.TAG.SupportOrderStatusEnum.${SupportOrderStatusEnum[status]}`)} className={`${SupportOrderStatusEnum[status]} text-white`}>
    {t(`TYPES.SUPPORT_ORDER.SupportOrderStatusEnum.${SupportOrderStatusEnum[status]}`)}
  </Tag>
}
export default SupportOrderStatusComponent
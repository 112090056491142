import { ISupplierOrder, SupplierOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces/supplier"
import { SupplierOrder } from "@alcome-rep/alcome-types/dist/models"
import { EditOutlined } from "@ant-design/icons"
import { Button, Table, TableColumnType } from "antd"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { selectUserSupplier } from "../../../../Auth/state/auth.slice"
import SupplierOrderStatus from "../../../../SupplierOrder/components/common/SupplierOrderStatus"
import supplierOrderApi from "../../../../SupplierOrder/services/supplierOrder.api"
import AmountValue from "../../../../common/components/Fields/AmountValue"
import DateField from "../../../../common/components/Fields/DateField"
import FilterEntity from "../../../../common/components/Filters/FilterEntity"
import FilterInput from "../../../../common/components/Filters/FilterInput"
import LoadingScreen from "../../../../common/components/LoadingScreen"
import FilterPanel from "../../../../common/components/Panels/FilterPanel"
import PageCanvas from "../../../../common/components/Panels/PageCanvas"
import { useAppSelector } from "../../../../common/hooks"
import useQueryParams from "../../../../common/hooks/useQueryParams"
import useTableChange from "../../../../common/hooks/useTableChange"
import { ROUTES } from "../../../../common/router"
import { getRoute } from "../../../../common/tools/router-tools"

const UserSupplierOrderList = () => {

  const [data, setData] = useState<SupplierOrder[]>()
  const [count, setCount] = useState<number>(0);
  const [loading, setloading] = useState<boolean>(false);

  const { t } = useTranslation();
  const user = useAppSelector(selectUserSupplier)

  const tableChange = useTableChange();
  const queryParams = useQueryParams()

  const navigate = useNavigate()

  useEffect(() => {
    setloading(true)
    supplierOrderApi.findAllSupplierOrders({ ...queryParams.query, supplierCompanyId: user.supplierCompanyId }, Number(queryParams.page), Number(queryParams.limit))
      .then(res => {
        setData(res.rows)
        setCount(res.count)
        setloading(false)
      })
      .catch(() => {
        setData([])
        setCount(0)
        setloading(false)
      })
  }, [setData, setCount, setloading, queryParams, user.supplierCompanyId])

  if (!data)
    return <LoadingScreen />

  const columns: TableColumnType<SupplierOrder>[] = [
    {
      fixed: 'left',
      width: "40px",
      align: 'left',
      render: (_, order: ISupplierOrder) => <Button
        type="link"
        icon={<EditOutlined />}
        onClick={(_e) =>
          navigate(getRoute(ROUTES.SUPPLIER_ORDER_ROUTES.supplierOrderInfos, { supplierOrderId: order.id }))}
      />
    },
    {
      title: 'N°',
      width: '150px',
      dataIndex: 'supplierOrderNumber',
      align: 'left',
      className: 'font-semibold',
    },
    {
      title: 'Structure',
      width: '150px',
      dataIndex: ['structure', 'name'],
      align: 'left',
      className: 'font-semibold',
    },
    {
      title: 'Date de commande',
      width: '150px',
      dataIndex: 'orderDate',
      render: (v) => <DateField value={v} />,
    },
    {
      title: 'Frais de livraison',
      width: '150px',
      dataIndex: 'shippingFees',
      render: (v) => <AmountValue precision={2} amount={v} />
    },
    {
      title: 'Total',
      width: '150px',
      dataIndex: 'total',
      render: (v) => <AmountValue precision={2} amount={v} />,
    },
    {
      title: 'Status',
      fixed: 'right',
      dataIndex: 'status',
      render: (status: SupplierOrderStatusEnum) => <SupplierOrderStatus status={status} />
    },
  ]

  return <>
    <PageCanvas title="Liste des commandes">

      <FilterPanel>
        <FilterInput
          searchParamName="supplierOrderNumber"
          label="Commande N°"
          isRegex={true}
        />
        <FilterEntity
          filterName='Statut'
          multiple={false}
          queryParamName='status'
          options={
            Object.keys(SupplierOrderStatusEnum)
              .slice(1)
              .filter((k): k is keyof typeof SupplierOrderStatusEnum => !isNaN(+k))
              .map(k => ({ label: t('TYPES.SUPPLIER_ORDER.SupplierOrderStatusEnum.' + SupplierOrderStatusEnum[k]), value: k }))
          }
        />
      </FilterPanel>

      <Table<SupplierOrder>
        dataSource={data}
        columns={columns}
        loading={loading}
        onChange={tableChange}
        pagination={
          {
            hideOnSinglePage: true,
            current: queryParams.page,
            pageSize: queryParams.limit,
            total: count,
          }
        }
        rowKey="id"
      />
    </PageCanvas>
  </>
}

export default UserSupplierOrderList
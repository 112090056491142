import { IOrder, OrderStatusEnum } from '@alcome-rep/alcome-types/dist/interfaces/product';
import { BaseCommune } from '@alcome-rep/alcome-types/dist/models';
import { EditOutlined } from '@ant-design/icons';
import { Button, Drawer, Table, Tag } from 'antd';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import orderAdminApi from '../../services/order-admin.api';


const StructureOrderList = ({ structure }: { structure: BaseCommune }) => {
  const [data, setData] = useState<IOrder[]>([]);
  const [loading, setloading] = useState<boolean>(false);
  const { t } = useTranslation()

  useEffect(() => {
    if (structure.id) {
      setloading(true)
      orderAdminApi.getStructureOrders(structure.id!)
        .then(data => {
          setData(data.rows)
          setloading(false)
        })
    }
  }, [setData, structure.id, setloading]);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true)
  };

  const onClose = () => {
    setOpen(false)
  };

  const columns: any = [
    {
      title: '',
      fixed: 'left',
      align: 'center',
      render: (value: any) => {
        return (
          <div className='flex space-x-4'>
            <Button className="btn-link text-alc-blue font-semibold text-lg px-0" onClick={_ => showDrawer()}>
              <EditOutlined />
            </Button>
            <Drawer
              title='Espace commande'
              width='50%'
              onClose={_ => onClose()}
              open={open}
              styles={{ body: { paddingBottom: 80 } }}
            >
              {/* {open && <OrderFiche onsubmit={onClose} order={value} />} */}
            </Drawer>
          </div>
        )
      }
    },
    {
      title: 'Date de commande',
      dataIndex: 'orderDate',
      key: 'orderDate',
      render: (value: any) => (<span>{format(new Date(value), "dd/MM/yyyy HH'h'mm", { locale: fr })}</span>)
    },
    {
      title: 'Structure',
      dataIndex: ['structure', 'name'],
      key: 'structure',
      align: 'center' as const,
      render: (value: any) => {
        return <div className=' text-center'>{value}</div>
      }
    },
    {
      title: 'Code Postal',
      dataIndex: ['shippingAddress', 'zip'],
      key: 'shippingAddress',
      align: 'center' as const,
      render: (value: any) => {
        return <div className=' text-center'>{value}</div>
      }
    },
    {
      title: 'Adresse',
      dataIndex: ['shippingAddress', 'street'],
      key: 'shippingAddress',
      align: 'center' as const,
      render: (value: any) => {
        return <div className=' text-center'>{value}</div>
      }
    },
    {
      title: 'Produit(s) commandé',
      dataIndex: ['items', '0', 'product', 'name'],
      key: 'quantity',
      render: (value: any) => (<span>{value}</span>)
    },
    {
      title: 'Quantité commandée',
      dataIndex: ['items', '0', 'quantity'],
      key: 'quantity',
    },
    {
      title: 'Statut de commande',
      dataIndex: 'status',
      key: 'status',
      render: (value: any) => {
        if (value === OrderStatusEnum.order_pending) return (<Tag color='orange'>{t('TYPES.ORDERS.OrderStatusEnum.' + OrderStatusEnum[value])}</Tag>)
        else if (value === OrderStatusEnum.order_approved) return (<Tag color='green'>{t('TYPES.ORDERS.OrderStatusEnum.' + OrderStatusEnum[value])}</Tag>)
        else if (value === OrderStatusEnum.order_processing) return (<Tag color='orange'>{t('TYPES.ORDERS.OrderStatusEnum.' + OrderStatusEnum[value])}</Tag>)
        else if (value === OrderStatusEnum.order_complete) return (<Tag color='green'>{t('TYPES.ORDERS.OrderStatusEnum.' + OrderStatusEnum[value])}</Tag>)
        else if (value === OrderStatusEnum.order_refused) return (<Tag color='red'>{t('TYPES.ORDERS.OrderStatusEnum.' + OrderStatusEnum[value])}</Tag>)
      }

    },

  ];

  return (
    <>
      <Table<IOrder>
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="id"
        pagination={false}
      />
    </>

  );

};

export default StructureOrderList;
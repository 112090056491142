import { DispositifCategoryEnum, DispositifTypeEnum, isAshtrayType, isExtinguisherType } from "@alcome-rep/alcome-types/dist/interfaces"
import { IProduct, ProductStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces/product"
import { EditOutlined, RightCircleOutlined } from "@ant-design/icons"
import { Button, Form, Modal, Select, Table } from "antd"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import productApi from "../../services/product-api"
import ProductImage from "./ProductImage"

type PropsType = {
  product?: IProduct,
  category?: DispositifCategoryEnum,
  type?: DispositifTypeEnum,
  productSelectedCb?: (product: IProduct) => void
}

const ProductSelector = ({ product, type, category, productSelectedCb }: PropsType) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [products, setProducts] = useState<Array<IProduct>>();
  const [selectedProduct, setSelectedProduct] = useState<IProduct | undefined>();

  const { t } = useTranslation()

  const searchProducts = useMemo(() => {
    return async (inputType?: DispositifTypeEnum) => {
      const q: any = { category, status: ProductStatusEnum.product_available };
      if (inputType)
        q.type = inputType
      const re = await productApi.find({ query: q, page: 1, pageSize: 10 })
      setProducts(re.rows)
    }
  }, [setProducts, category])

  const selectProduct = (product: IProduct) => {
    productSelectedCb && productSelectedCb(product)
    setVisible(false)
  }

  const select = async () => {
    await searchProducts(type);
    setVisible(true)
  }

  return <>
    {!product && <Button type="dashed" onClick={select} danger>Sélectionnez un produit</Button>}
    {product && <>
      <div className="flex space-x-2 items-center">

        <Button type="link" onClick={select} icon={<EditOutlined />}>{product.name}</Button>
      </div>
    </>}
    <Modal
      title="Sélectionnez un produit"
      centered
      open={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      width={1000}
      footer={null}
      destroyOnClose={true}
    >
      <div className="grid grid-cols-2">
        <div>
          <Form.Item
            label="Filtre"
            className="mb-4">
            <Select
              defaultValue={type}
              className="w-full"
              onChange={e => searchProducts(e)}
              options={Object.keys(DispositifTypeEnum)
                .filter((k: any) => category === DispositifCategoryEnum.ashtray ? isAshtrayType(k) : isExtinguisherType(k))
                .map(k => ({ label: t('TYPES.INVENTORY.DispositifTypeEnum.' + k), value: k }))}
            />
          </Form.Item>
          <Table<IProduct>
            rowKey={'id'}
            dataSource={products}
            size="small"
            scroll={{ y: 500 }}
            columns={[
              {
                title: "", key: 'action', render: (product: IProduct) => <Button type="link" onClick={_ => setSelectedProduct(product)} icon={<RightCircleOutlined />} />
              },
              // {
              //   title: "Visuel", dataIndex: 'images', render: (_images, product: IProduct) => <ProductImage product={product} />
              // },
              { title: "Nom", dataIndex: 'name' },
              { title: "Type", dataIndex: 'type', render: (v: DispositifTypeEnum) => <span>{t('TYPES.INVENTORY.DispositifTypeEnum.' + v)}</span> },
            ]}
            pagination={false}
          />
        </div>
        <div className="px-4 h-full border-l-1 border-gray-100">
          {!selectedProduct && <div className="bg-gray-100 flex items-center justify-center h-full rounded-md"><span>Aucun produit sélectionné</span></div>}
          {selectedProduct && <>
            <div className="flex flex-col justify-between h-full">
              <div className="rounded-md bg-gray-100 p-4 mb-2">
                <h5>{selectedProduct.name}</h5>
                <p className="whitespace-pre-line">{selectedProduct.description}</p>
                {selectedProduct.productDocumentUrl && (
                  <div className="flex space-x-2">
                    <a target="_blank" rel="noreferrer" href={`${selectedProduct.productDocumentUrl}`}>Voir la fiche technique</a>
                  </div>
                )}
                <div className="grid grid-cols-3 my-2">
                  {selectedProduct.images.map((image, i) => <ProductImage key={i} product={selectedProduct} index={i} />)}
                </div>
              </div>
              <div className="text-left">
                <Button type="primary" onClick={_ => selectProduct(selectedProduct)}>Sélectionner ce produit</Button>
              </div>
            </div>
          </>}
        </div>
      </div>
    </Modal >

  </>
}

export default ProductSelector
import { Models } from "@alcome-rep/alcome-types";
import { SupplierOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces/supplier";
import { Table } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import structureAdminApi from "../../../../Structure/services/structure-admin.api";
import adminSupplierCompanyApi from "../../../../SupplierCompany/services/admin-supplierCompany.api";
import FilterEntity from "../../../../common/components/Filters/FilterEntity";
import FilterInput from "../../../../common/components/Filters/FilterInput";
import FilterPanel from "../../../../common/components/Panels/FilterPanel";
import PageCanvas from "../../../../common/components/Panels/PageCanvas";
import QuickLinksPanel from "../../../../common/components/Panels/QuickLinksPanel";
import useQueryParams from "../../../../common/hooks/useQueryParams";
import useTableChange from "../../../../common/hooks/useTableChange";
import { ROUTES } from "../../../../common/router";
import adminSupplierOrderApi from "../../../services/admin-supplierOrder.api";
import { supplierOrderListColumns } from "./AdminSupplierListColumns";
import AdminSupplierOrderListStatusFilter from "./AdminSupplierOrderListStatusFilter";
import FilterNavLink from "../../../../common/components/Filters/FilterNavLink";

const AdminSupplierOrderList = () => {

  const [data, setData] = useState<Models.SupplierOrder[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setloading] = useState<boolean>(false);
  const [refresh] = useState<Date>()

  const tableChange = useTableChange();
  const queryParams = useQueryParams()

  const navigate = useNavigate()
  const { t } = useTranslation()

  const findStructures = (name: string) => structureAdminApi.findAll({ query: { name: '/' + name + '/' }, page: 1, pageSize: 5 })
    .then(r => r.rows.map(s => ({ label: s.name, value: s.id })))
  const retrieveStructure = (id: string) => structureAdminApi.get(id)

  const findSupplier = (name: string) => adminSupplierCompanyApi.findAllSuppliers({ name: '/' + name + '/' }, 1, 5)
    .then(r => r.rows.map(s => ({ label: s.name, value: s.id })))
  const retrieveSupplier = (id: string) => adminSupplierCompanyApi.findSupplierCompanyById(id)

  useEffect(() => {
    setloading(true)
    adminSupplierOrderApi.findAllSupplierOrders(queryParams.query, Number(queryParams.page), Number(queryParams.limit))
      .then(res => {
        setData(res.rows)
        setCount(res.count)
        setloading(false)
      })
      .catch(() => {
        setData([])
        setCount(0)
        setloading(false)
      })
  }, [setData, setCount, setloading, queryParams, refresh])

  return (
    <PageCanvas
      breadCrumbs={[
        ROUTES.ADMIN_USER_ROUTES.home,
        ROUTES.ADMIN_SUPPLIER_COMPANY_ROUTES.supplierCompanyList
      ]}
      title={<span>Liste des commandes fournisseurs <small>({count ? count : 0})</small ></span >}

    >
      <FilterPanel>
        <FilterInput
          searchParamName="supplierOrderNumber"
          label="Demande N°"
          isRegex={true}
        />
        <FilterEntity
          filterName='Structure'
          multiple={false}
          queryParamName='structureId'
          findFn={findStructures}
          retrieveFn={retrieveStructure}
        />
        <FilterEntity
          filterName='Fournisseur'
          multiple={false}
          queryParamName='supplierCompanyId'
          findFn={findSupplier}
          retrieveFn={retrieveSupplier}
        />
        <FilterEntity
          filterName='Statut'
          multiple={false}
          queryParamName='status'
          options={Object.keys(SupplierOrderStatusEnum)
            .filter(k => !isNaN(k as any))
            .map(k => ({ value: k, label: t('TYPES.SUPPLIER_ORDER.SupplierOrderStatusEnum.' + SupplierOrderStatusEnum[k as any]) }))
          }
        />
      </FilterPanel>

      <QuickLinksPanel icon={null} resetLink={null}>
        <AdminSupplierOrderListStatusFilter />
      </QuickLinksPanel>

      <QuickLinksPanel>
        <FilterNavLink queryParams={`?pendingInvoices=true`} label="Factures en attente de validation" />
      </QuickLinksPanel>

      <div className=''>
        <Table<Models.SupplierOrder>
          columns={supplierOrderListColumns(queryParams.query, t, navigate)}
          dataSource={data}
          loading={loading}
          onChange={tableChange}
          size="small"
          rowKey="id"
          scroll={{ scrollToFirstRowOnChange: true, x: 1000 }}
          pagination={
            {
              hideOnSinglePage: true,
              current: queryParams.page,
              pageSize: queryParams.limit,
              total: count,
            }
          } />
      </div>
    </PageCanvas >
  )
}

export default AdminSupplierOrderList
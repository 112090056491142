import { EditOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useFormRules from "../../../common/hooks/useFormRules";
import { IBaseCommune, IHotspot, IStructure, PlaceTypeEnum, SoilingEnum, StructureTypesEnum } from "@alcome-rep/alcome-types/dist/interfaces";

type HotSpotFormProps = {
  structure:IStructure,
  hotspot?: IHotspot,
  onCancel: () => void,
  onOk: (hotSpot: IHotspot) => void,
  onDelete?: (hotSpot: IHotspot) => void,
  canEdit: boolean,
};

const HotspotForm = ({ structure, hotspot, onOk, onCancel, onDelete, canEdit = true }: HotSpotFormProps) => {
  const [form] = Form.useForm<IHotspot>()
  const rules = useFormRules()
  const { t } = useTranslation();

  const [editOldValue, setEditOldValue] = useState(false);
  const [confirm, setConfirm] = useState(false);

  return (

    <>
      <Form form={form}
        disabled={canEdit === false}
        onFinish={(values: any) => onOk({ geoPoints: hotspot?.geoPoints, ...values })}
        layout="horizontal"
        initialValues={hotspot}>

        <Form.Item
          label="Libellé du hotspot"
          required
          name="name"
          rules={[rules.required]}
        >
          <Input />
        </Form.Item>
        {structure && structure.structureType === StructureTypesEnum.groupement_communes && (
          <Form.Item
            label="Commune"
            required
            name="communeName"
            rules={[rules.required]}
          >
            <Select>
              {structure && (structure as IBaseCommune).communes && (structure as IBaseCommune).communes.map(c => (<Select.Option key={c.name}>{c.name}</Select.Option>))}
            </Select>
          </Form.Item>)
        }
        <Form.Item
          label="Taille estimée (m2)"
          name="surface"
        >
          <Input readOnly />
        </Form.Item>
        {editOldValue || !hotspot?.placeType || Object.keys(PlaceTypeEnum).includes(hotspot?.placeType) ?
          <Form.Item
            label="Type de lieu"
            name="placeType"
            rules={[rules.required]}
          >
            <Select>
              {Object.keys(PlaceTypeEnum).filter(k => typeof k === 'string').map(type => (
                <Select.Option key={type} value={type}>{t(`TYPES.INVENTORY.PlaceTypeEnum.${type}`)}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          :
          <Form.Item
            label="Type de lieu"
            help={!Object.keys(PlaceTypeEnum).includes(hotspot?.placeType) ? `${t(`TYPES.INVENTORY.PlaceTypeEnum.${hotspot.placeType}`)} n'est plus une valeur disponible` : undefined}
          >
            <><span>{t(`TYPES.INVENTORY.PlaceTypeEnum.${hotspot.placeType}`)}</span> <Button type="link" htmlType="button" onClick={_ => setEditOldValue(true)} icon={<EditOutlined />}></Button></>
          </Form.Item>
        }
        <Form.Item
          label="Niveau de salissure"
          name="soilingDegree"
          rules={[rules.required]}
        >
          <Select>
            {Object.keys(SoilingEnum)
            .filter(k => typeof k === 'string')
            .slice(0, -1)
            .map(degree => (
              <Select.Option key={degree} value={degree}>{t(`TYPES.INVENTORY.SoilingEnum.${degree}`)}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        {/* <Collapse className="mb-2" defaultActiveKey={['1']} ghost>
                    <Collapse.Panel header="Plus de détails sur le niveau de salissure (facultatif)" key="1">
                        <Form.Item
                            label="Qualification AVPU"
                            name="abpoDegree"
                        >
                            <Select>
                                {Object.keys(ABPOEnum).filter((k: any) => isNaN(k)).map(degree => (
                                    <Select.Option key={degree} value={degree}>{t(`TYPES.INVENTORY.ABPOEnum.${degree}`)}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Collapse.Panel>
                </Collapse> */}

        <Form.Item
          label="Commentaires"
          name="comments"
        >
          <TextArea disabled={canEdit === false} />
        </Form.Item>
        {canEdit && (<div className="flex space-x-4 justify-between">
          <div>
            {onDelete && hotspot?.id && (
              <Button type="link" className="btn btn-link text-alc-danger" htmlType="button"
                onClick={() => {
                  setConfirm(true);
                  return confirm && hotspot && onDelete && onDelete(hotspot)
                }}>
                {confirm ? 'Cliquez pour confirmer' : 'Supprimer'}
              </Button>
            )}
          </div>
          <div>
            <Button type="link" className="btn btn-link" htmlType="button" onClick={() => onCancel()}>Annuler</Button>
            <Button type="primary" className="btn" htmlType="submit" >Ok</Button>
          </div>
        </div>
        )}
      </Form>
      {!canEdit &&
        (<div className="text-right">
          <Button type="primary" className="btn" htmlType="button" onClick={() => onCancel()}>Fermer</Button>
        </div>
        )}
    </>
  )
}

export default HotspotForm
import { AnnualReview, BaseCommune } from '@alcome-rep/alcome-types/dist/models';
import { EditOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Table, Tag, Tooltip } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { ColumnsType } from 'antd/lib/table/interface';
import { getYear } from 'date-fns';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { selectCurrentAdminContract } from '../../../../Contract/state/contract-admin.slice';
import { useAppSelector } from '../../../../common/hooks';
import { ROUTES } from '../../../../common/router';
import { getRoute } from '../../../../common/tools/router-tools';
import annualReviewAdminApi from '../../../services/annual-review-admin.api';

type TableType = { year: number, annualReview?: AnnualReview, key: number }

const StructureAnnualReviewList = ({ structure }: { structure: BaseCommune }) => {
  const contract = useAppSelector(selectCurrentAdminContract)

  const [refresh, setRefresh] = useState<Date>(new Date());
  const [loading, setloading] = useState<boolean>(false);
  const [tableList, setTableList] = useState<TableType[]>([]);

  useEffect(() => {
    if (structure && !!contract && contract.contractDate) {
      setloading(true)
      annualReviewAdminApi.getStructureAnnualReviews(structure.id!)
        .then(res => {
          let tableData: TableType[] = [];
          const contractYear = new Date(contract.contractDate as Date).getFullYear();

          for (let i = getYear(new Date()); i >= contractYear; i--) {
            const existingAR = res.rows.find(r => new Date(r.startDate).getFullYear() === i)
            tableData.push({ year: i, annualReview: existingAR, key: i });
          }

          setTableList(tableData)
          setloading(false)
        })
    }
  }, [structure, contract, setTableList, setloading, refresh]);


  const submitAnnualReview = async (year: number) => {
    if (structure) {
      await annualReviewAdminApi.createAnnualReview(structure.id!, year)
      setRefresh(new Date())
    }
  }

  const confirmCreation = (year: number) => {
    confirm({
      title: `Voulez vous créer le bilan annuel pour l'année ${year} ?`,
      icon: <QuestionCircleOutlined />,
      cancelText: 'Annuler',
      closable: true,
      okCancel: true,
      okButtonProps: { className: "btn" },
      async onOk() {
        await submitAnnualReview(year)
        setRefresh(new Date())
      },
      onCancel() {
      },
    });
  }

  const columns: ColumnsType<TableType> = [
    {
      key: 'action',
      width: '60px',
      fixed: 'left',
      align: 'center',
      render: (value: any) => {
        if (value.annualReview) {
          return <Tooltip title="Consulter">
            <Link
              className='px-2'
              to={getRoute(ROUTES.ADMIN_ANNUALREVIEW_ROUTES.annualReviewDetails, { annualReviewId: value.annualReview.id })}
            ><EditOutlined />
            </Link>
          </Tooltip>
        } else if (value.year < 2024) {
          return <Tooltip title="Créer le bilan annuel"><Button type="link" className='px-0' onClick={_ => confirmCreation(value.year)} icon={<PlusOutlined />}></Button></Tooltip>
        } else {
          return;
        }
      }
    },
    {
      title: 'Année',
      dataIndex: 'year',
      key: 'year',
      render: (value: number) => (<span>{value}</span>)
    },
    {
      title: 'Période',
      dataIndex: 'year',
      key: 'period',
      render: (value: any) => {
        if (value) {
          return <span>du 01/01/{value} au 31/12/{value}</span>
        } else {
          return <span>Créer le bilan pour déterminer la période</span>
        }
      }
    },
    {
      title: 'Info',
      dataIndex: 'annualReview',
      key: 'info',
      render: (value: AnnualReview | null) => {
        if (value) {
          const arDate = new Date(value.startDate).getFullYear();
          if (arDate === (new Date().getFullYear() - 1)) {
            return <Tag color='green'>Campagne en cours</Tag>
          } else if (arDate >= new Date().getFullYear()) {
            return <Tag color='orange'>Prochainement disponible</Tag>
          }else{
            return <Tag color='blue'>Campagne passée</Tag>
          }
        }else{
          return <Tag color='orange'>Prochainement disponible</Tag>
        }
      }
    },

  ];

  return <>
    <Table<TableType>
      columns={columns}
      dataSource={tableList}
      loading={loading}
      rowKey="key"
      pagination={false}
    />
  </>
}

export default StructureAnnualReviewList;
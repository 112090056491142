import { FileDocumentStatusEnum, IFileDocument } from '@alcome-rep/alcome-types/dist/interfaces';
import { UserTypeEnum } from '@alcome-rep/alcome-types/dist/interfaces/user';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, EyeOutlined, MoreOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Modal, Spin, Upload, message } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import confirm from 'antd/lib/modal/confirm';
import { RcFile } from 'antd/lib/upload';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../hooks';
import { getTrackProgressAreas } from '../../../services/apiService';
import { useTrackProgress } from '../../../services/trackProgress';
import { openDrawerAction } from '../../../state/modal.slice';
import FeatureAvailable from '../../FeaturesAvailable/FeatureAvailable';
import FileDocumentStatus from '../../FileDocument/FileDocumentStatus';

type AdminFileFieldProps = {
  fileDocument?: IFileDocument
  propertyName: string,
  upload?: (file: RcFile) => void,
  updateStatus?: (status: FileDocumentStatusEnum, comment?: string) => void,
  download?: () => Promise<any> | undefined
}

const AdminFileField = ({ fileDocument, propertyName, upload, download, updateStatus }: AdminFileFieldProps) => {
  const dispatch = useAppDispatch();
  const iFrame = useRef(null)
  const [blobContent, setBlobContent] = useState<any>();
  const [uploadOtherFile, setUploadOtherFile] = useState(false);

  const [currentDoc, setCurrentDoc] = useState<IFileDocument | undefined>(fileDocument)

  const isLoading = useTrackProgress(getTrackProgressAreas("").fileUpload(propertyName))

  useEffect(() => {
    setCurrentDoc(fileDocument)
  }, [fileDocument])

  useEffect(() => {
    if (blobContent && iFrame.current !== null)
      (iFrame.current as HTMLIFrameElement).src = URL.createObjectURL(blobContent);
  }, [blobContent])

  const { t } = useTranslation();
  let comment: undefined | string = undefined;

  const updateStatusModal = (status: FileDocumentStatusEnum) => () => {

    if (status === FileDocumentStatusEnum.declined) {
      confirm({
        title: 'Refus du document',
        icon: <ExclamationCircleOutlined />,
        content: (<>
          Ajouter un commentaire <textarea className='block w-full p-2' onChange={e => comment = (e.target.value)} ></textarea>
        </>),
        cancelText: 'Annuler',
        closable: true,
        okCancel: true,
        okButtonProps: { className: "btn" },
        onOk() {
          if (updateStatus) {
            updateStatus(status, comment);
          }
        },
        onCancel() {
        },
      });
    } else {
      confirm({
        title: 'Validation du document',
        icon: <ExclamationCircleOutlined />,
        content: 'Êtes-vous sûr de vouloir valider ce document ?',
        cancelText: 'Annuler',
        closable: true,
        okCancel: true,
        okButtonProps: { className: "btn" },
        onOk() {
          if (updateStatus) {
            updateStatus(status);
          }
        },
        onCancel() {
        },
      });
    }
  }

  const showDocumentStatus = (documentFile: IFileDocument) => {
    if (documentFile.status === FileDocumentStatusEnum.pending)
      return <div className='flex'>
        <Button
          type='link'
          onClick={updateStatusModal(FileDocumentStatusEnum.approved)}
          htmlType="button"
          className='text-alc-success'
          icon={<CheckCircleOutlined />}
        >
        </Button>
        <Button
          type="link"
          onClick={updateStatusModal(FileDocumentStatusEnum.declined)}
          htmlType="button"
          className='text-alc-danger'
          icon={<CloseCircleOutlined />}
        >
        </Button>
      </div>
    else
      return (<div className='contents'>
        <FileDocumentStatus fileDocument={documentFile} />
      </div>)
  }

  const showDocument = async (documentFile: IFileDocument) => {
    if (!download)
      return;
    if (documentFile !== undefined) {
      const res = await download()
      // contractApi.default.getDocument(currentFileDocument.propertyName, `contrat-alcome-${currentFileDocument.timestamp}.pdf`)
      const blobContent = new Blob([res], { type: "application/pdf" });
      setBlobContent(blobContent)
    }
  }

  const beforeUpload = (file: RcFile) => {
    const isPDF = file.type === 'application/pdf';
    if (!isPDF) {
      message.error(`${file.name} n'est pas un fichier pdf`);
    } else if (upload) {
      upload(file)
      setUploadOtherFile(false)
    }
    return false;
  }

  const showHistory = () => {
    dispatch(openDrawerAction({
      options: { title: 'Historique du fichier' },
      component: <>
        {currentDoc?.history
          .map(doc => <div className='flex justify-between items-center'>
            <Button
              icon={<EyeOutlined />}
              htmlType="button"
              className='px-0'
              onClick={() => showDocument(doc as IFileDocument)}
              type="link"
            >
              {t(`TYPES.FILEDOCUMENT.${propertyName}`)}
            </Button>
            <div>
              {doc.timestamp && format(new Date(doc.timestamp), "' 'dd/mm/yyyy' 'HH:mm:ss'", { locale: fr })}
            </div>
          </div>)}
      </>
    }))
  }

  const items: ItemType[] = [
    {
      key: 0,
      label: <FeatureAvailable userType={UserTypeEnum.admin} featureName='admin.contract.uploadFiles'>
        {upload && (
          <Button
            onClick={_ => setUploadOtherFile(true)}
            type='link'
            size='small'
            htmlType='button'>
            Charger un autre fichier
          </Button>

        )}
      </FeatureAvailable>
    },
    {
      key: 1,
      label: <Button
        size='small'
        onClick={showHistory}
        type="link" htmlType='button'
      >Voir l'historique</Button>
    }
  ].filter((item, i) => i === 0 || currentDoc?.history.length! > 0)

  return (
    <>
      <div className='bg-white p-2 rounded-md grid grid-cols-[1fr_1fr_120px] justify-between items-center my-2'>
        {!currentDoc ? (
          <div className='contents'>
            <div className='flex space-x-2'>
              <ClockCircleOutlined />
              <span>{t(`TYPES.FILEDOCUMENT.${propertyName}`)}</span>
            </div>
            <span>Document en attente</span>
            <div className='text-right'>
              <Dropdown menu={{ items }}>
                <Button type='link' icon={<MoreOutlined />} />
              </Dropdown>
            </div>
          </div>
        ) :
          <div className='contents'>
            <div>
              <Button
                icon={<EyeOutlined />}
                htmlType="button"
                className='px-0'
                onClick={() => showDocument(currentDoc)}
                type="link"
              >
                {t(`TYPES.FILEDOCUMENT.${propertyName}`)}
              </Button>
            </div>
            <div>
              {currentDoc.updatedAt && format(new Date(currentDoc.updatedAt), "' 'd MMMM yyyy' à 'HH:mm:ss'", { locale: fr })}
            </div>
            <div className='flex space-x-4 items-center justify-end'>
              <div className='contents'>
                <span>{showDocumentStatus(currentDoc)}</span>
                <Dropdown menu={{ items }}>
                  <Button type='link' icon={<MoreOutlined />} />
                </Dropdown>

              </div>
            </div>
            {currentDoc && currentDoc.status === FileDocumentStatusEnum.declined && currentDoc.comment && (
              <div className='flex col-span-3 items-center space-x-4'>
                <span>Motif du refus: </span>
                <div className='bg-alc-yellow bg-opacity-10 flex-1 px-2 py-1 italic border-1 border-dashed border-gray-400 rounded-md '>{currentDoc.comment}</div>
              </div>
            )}
          </div>
        }

      </div>

      <Modal
        title={t(`TYPES.FILEDOCUMENT.${propertyName}`)}
        styles={{ body: { height: 800 } }}
        centered
        open={!!blobContent}
        onOk={() => setBlobContent(undefined)}
        onCancel={() => setBlobContent(undefined)}
        width={1000}
        footer={null}
      >
        <iframe title="frame" ref={iFrame} className="w-full h-full" ></iframe>
      </Modal>
      <Modal
        title="Charger un autre fichier"
        centered
        open={uploadOtherFile}
        onCancel={() => setUploadOtherFile(false)}
        closable={true}
        footer={null}
      >
        <p>Vous avez la possibilité de charger un fichier manuellement. Si un fichier est déjà chargé, il se retrouvera dans l'historique et le nouveau viendra prendre sa place</p>
        <p className='text-center font-semibold'>{t(`TYPES.FILEDOCUMENT.${propertyName}`)}</p>
        <div className='flex justify-center'>
          <Upload
            beforeUpload={beforeUpload}
            accept="application/pdf"
            showUploadList={false}>
            <Button className='h-16 bg-gray-50'>
              <div className='flex space-x-2 justify-center items-center'>
                <div className=''>
                  {!isLoading && <UploadOutlined className='text-2xl text-alc-blue'></UploadOutlined>}
                  {isLoading && <Spin className='text-2xl text-alc-blue'></Spin>}
                </div>
                <div className='flex flex-col space-y-0 '>
                  {!isLoading && [
                    (<span key={1} className='text-alc-gray font-semibold text-lg'>{t("COMMON.FILEUPLOAD.dragndrop")}</span>),
                    (<span key={2} className='text-alc-gray'>{t("COMMON.FILEUPLOAD.dragndrop2")}</span>)
                  ]}
                  {isLoading && [
                    (<span key={1} className='text-alc-gray font-semibold text-lg'>{t("COMMON.FILEUPLOAD.loading")}</span>),
                    (<span key={2} className='text-alc-gray'>{t("COMMON.FILEUPLOAD.wait")}</span>)
                  ]}
                </div>
              </div>
            </Button>
          </Upload>
        </div>
      </Modal>
    </>
  );
};

export default AdminFileField;
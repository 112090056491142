import { IInventory } from '@alcome-rep/alcome-types/dist/interfaces'
import { User } from '@alcome-rep/alcome-types/dist/models'
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons'
import { Button, Form, Input, Switch } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import TextArea from 'antd/lib/input/TextArea'
import { Dispatch, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { selectUser } from '../../../Auth/state/auth.slice'
import LoadingScreen from '../../../common/components/LoadingScreen'
import Section from '../../../common/components/Panels/Section'
import { useAppDispatch, useAppSelector } from '../../../common/hooks'
import inventoryPortalApi from '../../services/inventory-portal.api'
import { selectCurrentAdminInventory } from '../../state/inventory-admin.slice'
import { selectCurrentInventory, setCurrentInventoryAction } from '../../state/inventory-portal.slice'
import msg from '../../../common/services/toast'

const PreventionForm = () => {
  const [isChecked, setChecked] = useState(false);

  const user = useAppSelector(selectUser);
  const inventory = useAppSelector(User.IsUserAdmin(user) ? selectCurrentAdminInventory : selectCurrentInventory);

  const dispatch: Dispatch<any> = useAppDispatch()

  const [form] = useForm()

  const { t } = useTranslation()

  const [openedPrevItems, setOpenedPrevItems] = useState<boolean[]>([true]);

  const toggleOpenedPrevItem = (index: number) => {
    const newState = [...openedPrevItems];
    newState[index] = !newState[index];
    setOpenedPrevItems(newState)
  }

  if (!inventory)
    return <LoadingScreen />

  const save = async (formData: IInventory) => {
    try {
      const updatedInventory = await inventoryPortalApi.updateInventoryInfos(inventory.id!, formData)
      msg.success('Enregistré')
      dispatch(setCurrentInventoryAction(updatedInventory));
    } catch (_err) {
      msg.error('Erreur')
    }

  }

  const canEdit = inventory?.userCanEdit(user)

  const checkHandler = () => {
    setChecked((check) => !check);
  };

  return (
    <Section mode="transparent" title="Etat des lieux de la prévention de l’abandon des déchets">
      <Form<IInventory>
        form={form}
        onFinish={save}
        initialValues={inventory}
        layout="vertical"
        className='w-full'
      >
        <Form.List name="communes">
          {(fields, _, { errors }) => (
            <div>
              {fields.map(field => (
                <div key={field.key} className={`mb-2 border-gray-200 border-1 rounded-md`}>
                  <h6 className={`${errors && errors.length ? 'text-alc-danger' : ''} bg-gray-100 flex mb-0 items-center cursor-pointer`} onClick={() => toggleOpenedPrevItem(field.key)}>
                    <Button
                      onClick={() => toggleOpenedPrevItem(field.key)}
                      type="link" htmlType='button'
                      icon={openedPrevItems[field.key] === true ? <CaretDownOutlined className='text-alc-blue'></CaretDownOutlined> : <CaretRightOutlined className='text-alc-blue'></CaretRightOutlined>} >
                    </Button>
                    <span>{inventory.communes?.[field.key].name}</span>
                  </h6>
                  <div className={`p-2 ${openedPrevItems[field.key] === true ? 'block' : 'hidden'}`}>
                    <Form.Item
                      name={[field.key, 'hasPolice']}
                      initialValue={isChecked}
                      label="Avez-vous une police municipale dans la commune ?"
                      valuePropName="checked">
                      {/* <Switch disabled={!canEdit} defaultChecked={false} ></Switch> */}
                      <Switch disabled={!canEdit} checked={isChecked} onChange={checkHandler}></Switch>
                    </Form.Item>
                    {form.getFieldValue(['communes', field.key, 'hasPolice']) === true && (
                      <>
                        <Form.Item
                          required
                          label={t('UI.PORTAL.INVENTORY.INSTRUCTION_POLICE.title')}
                          name={[field.key, 'policeDispositions']}>
                          <TextArea disabled={!canEdit} />
                        </Form.Item>
                        <Form.Item
                          required
                          label={t('UI.PORTAL.INVENTORY.AGENT_POLICE.fields.policeCount.label')}
                          name={[field.key, 'policeCount']}>
                          <Input type={'number'} disabled={!canEdit} />
                        </Form.Item>
                      </>
                    )}
                    <Form.Item
                      required
                      className="mb-0"
                      label={t(form.getFieldValue(['communes', field.key, 'hasPolice']) === true ?
                        'UI.PORTAL.INVENTORY.INSTRUCTION_POLICE.fields.policeSanctions.label'
                        : 'UI.PORTAL.INVENTORY.INSTRUCTION_POLICE.fields.policeSanctions2.label')}
                      name={[field.key, 'policeSanctions']}>
                      <TextArea disabled={!canEdit} />
                    </Form.Item>
                  </div>

                </div>
              ))}
              <Form.ErrorList errors={errors} />
            </div>
          )}
        </Form.List>
        {canEdit && (
          <Button type="primary" htmlType="submit" className="mt-3">{t('COMMON.save')}</Button>
        )}
      </Form>
    </Section>
  )
}

export default PreventionForm
import { Models } from "@alcome-rep/alcome-types";
import { ProductStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces/product";
import { EyeOutlined } from "@ant-design/icons";
import { Button, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { TFunction } from "i18next";
import { NavigateFunction } from "react-router-dom";
import { ROUTES } from "../../../../common/router";
import { getRoute } from "../../../../common/tools/router-tools";

export const supplierProductListColumns = (query: any, t: TFunction, navigate: NavigateFunction): ColumnsType<Models.Product> => [
  {
    // title: 'Actions',
    fixed: 'left',
    width: "40px",
    align: 'center',
    render: (value: any, _, i) => {
      return (
        <Button
          id={`action-button-${i}`}
          type="link"
          icon={<EyeOutlined />}
          onClick={(_e) =>
            navigate(getRoute(ROUTES.SUPPLIER_USER_ROUTES.productDetails, { productId: value.id }))}
        />
      )
    }
  },
  {
    fixed: 'left',
    title: 'Référence',
    dataIndex: 'reference',
    align: 'left',
  },
  {
    fixed: 'left',
    title: 'Nom',
    dataIndex: 'name',
    align: 'left',
  },
  {
    title: 'Catégorie',
    dataIndex: 'category',
    render: (v) => v && <span>{t('TYPES.INVENTORY.DispositifCategoryEnum.' + v)}</span>
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: (v) => v && <span>{t('TYPES.INVENTORY.DispositifTypeEnum.' + v)}</span>
  },
  {
    title: 'Prix',
    dataIndex: 'price',
  },
  // {
  //   fixed: 'left',
  //   title: 'Taxes',
  //   width: '250px',
  //   dataIndex: 'tax',
  //   key: 'tax',
  //   align: 'left',
  // },
  {
    fixed: 'left',
    title: 'Statut',
    width: '250px',
    dataIndex: 'status',
    key: 'status',
    align: 'left',
    render: (value) => {
      if (value === ProductStatusEnum.product_available) {
        return <Tag color="green">{t(`TYPES.PRODUCT.ProductStatusEnum.${ProductStatusEnum[value]}`)}</Tag>
      } else if (value === ProductStatusEnum.product_unavailable) {
        return <Tag color="red">{t(`TYPES.PRODUCT.ProductStatusEnum.${ProductStatusEnum[value]}`)}</Tag>
      } else {
        return <>-</>
      }
    }
  },
];
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch } from "../../../common/hooks";
import useFormRules from '../../../common/hooks/useFormRules';
import { ROUTES } from '../../../common/router';
import { useTrackProgress } from '../../../common/services/trackProgress';
import { progressKeys } from '../../services/auth.api';
import { loginAction } from "../../state/auth.slice";
import { LoginPayload } from '../../state/types';

const LoginForm = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { required, email } = useFormRules();

  const isInProgress = useTrackProgress(progressKeys.login);

  const onSubmit = (formData: LoginPayload) => {
    dispatch(loginAction(formData))
  }

  return <>
    <Form onFinish={onSubmit} layout="vertical">
      <Form.Item
        label={t('UI.AUTH.LOGIN_FORM.FIELDS.email')}
        name="email"
        rules={[required, email]}
      >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        className='mb-0'
        label={t('UI.AUTH.LOGIN_FORM.FIELDS.password')}
        name="password"
      // rules={[required]}
      >
        <Input.Password></Input.Password>
      </Form.Item>
      <div className='text-right text-xs mb-8'>
        <Link to={ROUTES.ASK_PASSWORD}>
          {t('UI.AUTH.LOGIN_FORM.ask_password')}
        </Link>
      </div>
      <Button className='btn w-full' type="primary" htmlType="submit" loading={isInProgress}>
        {t('UI.AUTH.LOGIN_FORM.button')}
      </Button>
    </Form>
  </>
}

export default LoginForm;
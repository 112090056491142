import { ReactNode, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import LoadingScreen from '../../../common/components/LoadingScreen';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { getAnnualReviewAction, selectCurrentPortalAnnualReview } from '../../state/annual-review-portal.slice';
import { setCurrentPortalAnnualReviewAction } from '../../state/annual-review-portal.slice';

type Props = { redirectPath?: string, children?: ReactNode };

const AnnualReviewPortalGuard = ({ children }: Props) => {

  const { annualReviewId } = useParams();
  const annualReview = useAppSelector(selectCurrentPortalAnnualReview);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAnnualReviewAction({ id: annualReviewId }))
    return () => {
      dispatch(setCurrentPortalAnnualReviewAction(null))
    }
  }, [annualReviewId, dispatch]);

  if (!annualReview)
    return <LoadingScreen />

  return children ? (<>{children}</>) : <Outlet></Outlet>
};

export default AnnualReviewPortalGuard
import { FileDocumentStatusEnum, IFileDocument } from '@alcome-rep/alcome-types/dist/interfaces';
import { ISupplierFileDocument } from '@alcome-rep/alcome-types/dist/interfaces/supplier/supplierFileDocument';
import { DeleteOutlined, DownloadOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Spin, Upload, message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTrackProgressAreas } from '../../../services/apiService';
import { useTrackProgress } from '../../../services/trackProgress';
import FileDocumentStatus from '../../FileDocument/FileDocumentStatus';

type UserFileFieldsProps = {
  fileDocument: IFileDocument[] | ISupplierFileDocument[] | undefined;
  propertyName: string;
  canEdit: boolean;
  ext?: string;
  upload?: (file: RcFile) => void;
  download: (file: IFileDocument | ISupplierFileDocument) => Promise<AxiosResponse>;
  deleteFile?: (file: IFileDocument | ISupplierFileDocument) => any;
  modifyFileMetadata?: (fileMetadata: ISupplierFileDocument) => JSX.Element;
  displayStatus?: boolean;
}

const UserFileFields = ({ fileDocument, propertyName, upload, download, deleteFile, canEdit = true, modifyFileMetadata, displayStatus }: UserFileFieldsProps) => {

  const trackContract = useTrackProgress(getTrackProgressAreas("").fileUpload(propertyName));
  const trackAnnualReview = useTrackProgress(getTrackProgressAreas("").fileUpload(propertyName));

  const isLoading = propertyName === 'contractFile' ? trackContract : trackAnnualReview
  const iFrame = useRef(null)
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const beforeUpload = (file: RcFile) => {
    if (!file) {
      message.error(`Veuillez inserer un fichier`);
    } else if (upload) {
      upload(file)
    }
    return false;
  }

  const displayButton = () => {
    return canEdit ? (<div className='mb-4'>
      <div className={`flex space-x-2`}>
        <div>
          <Form.Item validateTrigger={['onChange']}
            className={`mb-0`}
          >
            {upload && (
              <Upload
                beforeUpload={beforeUpload}
                showUploadList={false}>
                <Button className='h-16 bg-gray-50'>
                  <div className='flex space-x-2 justify-center items-center'>
                    <div className=''>
                      {!isLoading && <UploadOutlined className='text-2xl text-alc-blue'></UploadOutlined>}
                      {isLoading && <Spin className='text-2xl text-alc-blue'></Spin>}
                    </div>
                    <div className='flex flex-col space-y-0 '>
                      {!isLoading && [
                        (<span key={1} className='text-alc-gray font-semibold text-lg'>{t("COMMON.FILEUPLOAD.dragndrop")}</span>),
                        (<span key={2} className='text-alc-gray'>{t("COMMON.FILEUPLOAD.dragndrop2")}</span>)
                      ]}
                      {isLoading && [
                        (<span key={3} className='text-alc-gray font-semibold text-lg'>{t("COMMON.FILEUPLOAD.loading")}</span>),
                        (<span key={4} className='text-alc-gray'>{t("COMMON.FILEUPLOAD.wait")}</span>)
                      ]}
                    </div>
                  </div>
                </Button>
              </Upload>
            )}
          </Form.Item>
        </div>
      </div>
    </div>) : null
  }

  const showDocumentStatus = (file: IFileDocument | ISupplierFileDocument) => {
    return (<div>
      <FileDocumentStatus fileDocument={file} />
    </div>)
  }

  // const showDocumentStatusBadge = (file: IFileDocument | ISupplierFileDocument) => {
  // if (file && file.status === FileDocumentStatusEnum.pending)
  //   return (
  //     <div className='space-x-3'>
  //       <div><span className={`badge-${FileDocumentStatusEnum[file.status]}`}>{t('TYPES.FILEDOCUMENT.FileDocumentStatusEnum.' + FileDocumentStatusEnum[file.status])}</span></div>
  //     </div>)
  // else if (file !== undefined)
  //   return (
  //     <div className='contents'>
  //       <div>
  //         <span className={`badge-${FileDocumentStatusEnum[file.status]}`}>{t('TYPES.FILEDOCUMENT.FileDocumentStatusEnum.' + FileDocumentStatusEnum[file.status])}</span>
  //       </div>
  //     </div>)

  // }

  // const showDocumentStatusDeclined = (file: IFileDocument) => {
  //     if (file && file.status === FileDocumentStatusEnum.declined)
  //         return (
  //             <div>
  //                 <div className='mt-2'>
  //                     {file.comment && (
  //                         <div className='border-1 border-dashed border-gray-400 rounded-md p-2 col-span-3'>{file.comment}</div>
  //                     )}
  //                 </div>
  //                 <div className='mt-2'>
  //                     {(propertyName === 'contractFile' || propertyName === 'executiveDeliberationFile') && (
  //                         <div className='mt-5'>{displayButton()}</div>
  //                     )}
  //                     {(propertyName === 'inventoryFile' || propertyName === 'annualReviewFile') && (
  //                         <></>
  //                     )}

  //                 </div>

  //             </div>
  //         )
  // }

  const showDocumentNameByStatus = (file: IFileDocument | ISupplierFileDocument) => {
    if (
      (file && file.status === FileDocumentStatusEnum.pending) ||
      (file && file.status === FileDocumentStatusEnum.approved) ||
      (file && file.status === FileDocumentStatusEnum.declined)
    ) {
      if (file.originalName) {
        return (
          <div>
            <button type="button" onClick={_ => showDocument(file)} className='btn-link  flex w-8 px-2 py-1 items-start text-alc-blue m-1 mt-0'>
              <EyeOutlined className='text-alc-blue px-1' />{`${file.originalName}`}
            </button>
          </div>
        )
      } else {
        return (
          <div>
            {/* <EyeOutlined className='text-alc-blue' /> */}
            {file.extension === '.pdf' || file.extension === null ? (
              <button type="button" onClick={_ => showDocument(file)} className='btn-link  flex w-8 px-2 py-1 items-start text-alc-blue m-1 mt-0'>
                <EyeOutlined className='text-alc-blue px-1' />{`${file.timestamp}${file.extension}`}
              </button>
            ) : <button type="button" onClick={_ => showDocument(file)} className='btn-link  flex w-8 px-2 py-1 items-start text-alc-blue m-1 mt-0'>
              <DownloadOutlined className='text-alc-blue px-1' />{`${file.timestamp}${file.extension}`}
            </button>
            }
          </div>
        )
      }
    }
    return null
  }

  const showDocument = async (file: IFileDocument | ISupplierFileDocument) => {
    setVisible(true)
    const res = await download(file)
    if (!file.extension || file.extension === '.pdf') {
      const blobContent = new Blob([res as any], { type: "application/pdf" });
      if (iFrame.current !== null)
        (iFrame.current as HTMLIFrameElement).src = URL.createObjectURL(blobContent);
    }
  }


  return (
    <>
      <div className='mb-4'>
        <div className=''>
          <div className='admin-file-field grid grid-cols-4 gap-y-2'>
            <div className={fileDocument?.length === 0 ? 'hidden' : 'block' && 'text-sm font-semibold px-4'}>
              {t(`TYPES.FILEDOCUMENT.${propertyName}`)}
            </div>
            <div className={fileDocument?.length === 0 ? 'hidden' : 'block' && 'text-sm font-semibold'}>Téléversé le </div>
            {displayStatus ? <div className='text-sm font-semibold'>Status</div> : <div></div>}
            <div></div>
            {fileDocument?.map((file, i) => (
              <>
                <div key={i} className='contents text-sm font-semibold'>
                  {showDocumentNameByStatus(file)}
                  <div>
                    {file.updatedAt && format(new Date(file.updatedAt), "' 'd MMMM yyyy'", { locale: fr })}
                  </div>
                  {canEdit && <Button type="link" className="text-alc-danger" onClick={() => deleteFile && deleteFile(file as IFileDocument | ISupplierFileDocument)} icon={<DeleteOutlined />}></Button>}
                  {displayStatus ? showDocumentStatus(file) : <div></div>}
                </div>

                {modifyFileMetadata && (
                  <div>{modifyFileMetadata(file as ISupplierFileDocument)}</div>
                )}
              </>

            ))}
          </div>
          <div className='text-sm font-semibold'>
            <div className='mt-3'>{displayButton()}</div>
          </div>

        </div>
        <Modal
          title={t(`TYPES.FILEDOCUMENT.${propertyName}`)}
          styles={{ body: { height: 800 } }}
          centered
          open={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          width={1000}
          footer={null}
        >
          <iframe title="contract" ref={iFrame} className="w-full h-full" ></iframe>
        </Modal>
      </div>
    </>
  );
};

export default UserFileFields
import { Button, Modal, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import { selectCurrentContract } from "../../../Contract/state/contract-portal.slice";
import DateField from "../../../common/components/Fields/DateField";
import LoadingScreen from "../../../common/components/LoadingScreen";
import Section from "../../../common/components/Panels/Section";
import { useAppSelector } from "../../../common/hooks";
import Info from "../../../common/components/WarningUser/Info";
import amendmentPortalApi from "../../services/amendment-portal.api";
import { selectUser } from "../../../Auth/state/auth.slice";
import { User } from "@alcome-rep/alcome-types/dist/models";
import { selectCurrentAdminContract } from "../../../Contract/state/contract-admin.slice";
import amendmentAdminApi from "../../services/amendment-admin.api";
import { useParams } from "react-router-dom";

const AmendmentPage = () => {
  const iFrame = useRef(null);
  // const [visible, setVisible] = useState(false);
  const [blobContent, setBlobContent] = useState<any>();
  
  const user = useAppSelector(selectUser);
  const isAdmin = User.IsUserAdmin(user)
  const { structureId } = useParams();
  const contract = useAppSelector(User.IsUserAdmin(user) ? selectCurrentAdminContract : selectCurrentContract);
  const amendments = contract?.amendments || []

  useEffect(() => {
    if (blobContent && iFrame.current !== null)
      (iFrame.current as HTMLIFrameElement).src = URL.createObjectURL(blobContent);
  }, [blobContent])

  const showDocument = async () => {
    let res;
    if (isAdmin) {
      res = await amendmentAdminApi.getAmendmentDocByStructureId(structureId as string)
    } else {
      res = await amendmentPortalApi.getAmendmentDoc()
    }
    // setVisible(true)
    const blobCt = new Blob([res], { type: "application/pdf" });
    setBlobContent(blobCt)
    // if (iFrame.current !== null) {
    //   (iFrame.current as HTMLIFrameElement).src = URL.createObjectURL(blobContent);
    // }
  }



  if (!contract)
    return <LoadingScreen />

  return <>
    <Section title="Avenants au contrat" mode="transparent">
      {amendments.length === 0 ? (
        <Info body="Vous n'avez aucun avenant à votre contrat. Si un avenant est publié vous serez prevenu sur votre tableau de bord." />
      ) :
        <Table
          rowKey={'_id'}
          columns={[{
            key: 'amendmentNumber',
            dataIndex: 'amendmentNumber',
            title: 'Numéro'
          }, {
            key: 'name',
            dataIndex: 'name',
            title: 'Nom'
          }, {
            title: 'Consulté le',
            key: "agreementDate",
            render: (value) => <DateField value={value.agreementDate} time={true} />
          }, {
            key: '#',
            width: 50,
            render: (value) => <Button type="link" htmlType='button' onClick={() => showDocument()}>
              Voir l'avenant
            </Button>
          }]}
          dataSource={amendments}
        />
      }

      <Modal
        title="Avenant au contrat"
        styles={{ body: { height: 800 } }}
        centered
        open={!!blobContent}
        onOk={() => setBlobContent(undefined)}
        onCancel={() => setBlobContent(undefined)}
        width={1000}
        footer={null}
      >
        <iframe title="contract" ref={iFrame} className="w-full h-full" ></iframe>
      </Modal>
    </Section>
  </>
}

export default AmendmentPage;
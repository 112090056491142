import { Models } from "@alcome-rep/alcome-types"
import { DispositifCategoryEnum, DispositifTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces"
import { Table } from "antd"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import adminSupplierCompanyApi from "../../../../SupplierCompany/services/admin-supplierCompany.api"
import FilterEntity from "../../../../common/components/Filters/FilterEntity"
import FilterInput from "../../../../common/components/Filters/FilterInput"
import FilterNavLink from "../../../../common/components/Filters/FilterNavLink"
import FilterPanel from "../../../../common/components/Panels/FilterPanel"
import PageCanvas from "../../../../common/components/Panels/PageCanvas"
import QuickLinksPanel from "../../../../common/components/Panels/QuickLinksPanel"
import useQueryParams from "../../../../common/hooks/useQueryParams"
import useTableChange from "../../../../common/hooks/useTableChange"
import { ROUTES } from "../../../../common/router"
import productApi from "../../../services/product-api"
import AdminCreateProduct from "../AdminCreateProduct/AdminCreateProduct"
import { productListColumns } from "./AdminProductColumns.constant"

const AdminProductList = () => {

  const [data, setData] = useState<Models.Product[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setloading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<Date>()

  const tableChange = useTableChange();
  const queryParams = useQueryParams()

  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    setloading(true)
    productApi.findAllProducts(queryParams.query, Number(queryParams.page), Number(queryParams.limit), { [queryParams.sortField ?? 'name']: (queryParams.sortOrder ?? 1) })
      .then(res => {
        setData(res.rows)
        setCount(res.count)
        setloading(false)
      })
      .catch(() => {
        setData([])
        setCount(0)
        setloading(false)
      })
  }, [setData, setCount, setloading, queryParams, refresh])

  const findSupplier = (name: string) => adminSupplierCompanyApi.findAllSuppliers({ name: '/' + name + '/' }, 1, 5)
    .then(r => r.rows.map(s => ({ label: s.name, value: s.id })));
  const retrieveSupplier = (id: string) => adminSupplierCompanyApi.findSupplierCompanyById(id);

  return (
    <PageCanvas
      breadCrumbs={[
        ROUTES.ADMIN_USER_ROUTES.home,
        ROUTES.ADMIN_PRODUCT_ROUTES.productList
      ]}
      title={<span>Liste des Produits <small>({count ? count : 0})</small ></span >}
      actions={
        <AdminCreateProduct refresh={setRefresh} />
        // <ExportExcelButton fileName='structure-reporting' exportList={exportExcel} />
      }
    >
      <FilterPanel>
        <FilterInput
          label="Nom du produit"
          searchParamName="name"
          isRegex={true}
        />
        <FilterEntity
          filterName='Fournisseur'
          multiple={false}
          queryParamName='supplierCompanyId'
          findFn={findSupplier}
          retrieveFn={retrieveSupplier}
        />
        <FilterEntity
          filterName='Catégorie'
          multiple={false}
          queryParamName='category'
          options={Object.keys(DispositifCategoryEnum).map(k => ({ label: t('TYPES.INVENTORY.DispositifCategoryEnum.' + k), value: k }))}
        />
        <FilterEntity
          filterName='Type'
          multiple={false}
          queryParamName='type'
          options={Object.keys(DispositifTypeEnum).map(k => ({ label: t('TYPES.INVENTORY.DispositifTypeEnum.' + k), value: k }))}
        />
        {/* <FilterInput searchParamName='siret' label='Siret' isRegex={true} /> */}
      </FilterPanel>

      <QuickLinksPanel>
        <FilterNavLink label="Cendriers" queryParams={`?category=${DispositifCategoryEnum.ashtray}`} />
        <FilterNavLink label="Éteignoirs" queryParams={`?category=${DispositifCategoryEnum.extinguisher}`} />
      </QuickLinksPanel>

      <div>
        <Table<Models.Product>
          columns={productListColumns(queryParams.query, t, navigate)}
          dataSource={data}
          loading={loading}
          onChange={tableChange}
          size="small"
          rowKey="id"
          scroll={{ scrollToFirstRowOnChange: true, x: 1000 }}
          pagination={
            {
              hideOnSinglePage: true,
              current: queryParams.page,
              pageSize: queryParams.limit,
              total: count,
            }
          } />
      </div>
    </PageCanvas >
  )
}

export default AdminProductList
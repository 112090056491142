import { ApiListResult } from "@alcome-rep/alcome-types";
import { IOrder } from "@alcome-rep/alcome-types/dist/interfaces/product";
import ApiService, { ApiSortType } from "../../common/services/apiService";

const ORDER_ADMIN_PATH_API = 'admin/order';

class OrderAdminApi extends ApiService<IOrder> {
  constructor() {
    super(ORDER_ADMIN_PATH_API)
  }

  /**
   * @description find all orders
   * @param query
   * @returns orders list
   */
  findAll(query: any, page: number, pageSize: number, sort?: ApiSortType) {
    return this.find({ query, page, pageSize })
  }

  /**
  * @description find structure annual reviews
  * @param query
  * @returns structure annual reviews list
  */
  getStructureOrders(structureId: string) {
    return this.custom<ApiListResult<IOrder>>("get", "byStructure/:structureId", { structureId })
  }

  /**
   * @description get an order
   * @param orderId 
   * @returns Order
   */
  getOrder(orderId: string) {
    return this.get(orderId)
  }

  /**
   * @description update an order
   * @param orderId 
   * @param data 
   * @returns Updated order
   */
  updateOrder(orderId: string, data: Partial<IOrder>) {
    return this.update(orderId, data)
  }

  /**
   * @description create an order for a specific year
   * @param data 
   * @returns Created order
   */
  createOrder(structureId: string, data: Partial<IOrder>) {
    return this.custom<IOrder>("post", structureId, undefined, data)

  }
}

const orderAdminApi = new OrderAdminApi();
export default orderAdminApi;
import { appFeatures, flatMapFeatures } from '@alcome-rep/alcome-types';
import { Button, Checkbox, message } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import rolesSettingsApi from '../../../services/roleSettings/roles-settings.api';
import { UserAdminRolesEnum, UserPortalRolesEnum } from '@alcome-rep/alcome-types/dist/interfaces/user';


const RoleSettings = ({ onClose }: { onClose: () => any }) => {

  const { t } = useTranslation()

  const UserRolesEnum = { ...UserPortalRolesEnum, ...UserAdminRolesEnum };

  const columns = Object.keys(UserRolesEnum).filter(k => isNaN(+k))

  const featuresName = flatMapFeatures(appFeatures)
  const [featureRoles, setFeatureRoles] = useState<any>(featuresName)

  const saveSettings = () => {
    rolesSettingsApi.updateFeatureFile(featureRoles)
      .then(res => {
        setFeatureRoles(res)
        onClose()
        message.success('Roles mis à jour')
        return res
      })
  }

  const toggleFeature = (feature: string, role: string) => {
    const roles = [...featureRoles[feature]];
    const roleIndex = roles.indexOf(role);

    if (roleIndex < 0) {
      roles.push(role)
    } else {
      roles.splice(roleIndex, 1)
    }

    console.log({ ...featureRoles, [feature]: roles })
    setFeatureRoles({ ...featureRoles, [feature]: roles })

  }

  return (
    <>
      <div className=''>
        <table className='table-role-settings mb-8'>
          <thead>
            <tr className=''>
              <th></th>
              {columns.map(c => {
                return (<>
                  <th className='p-2'>{t('types.roles.' + c)}</th>
                </>)
              })}
            </tr>
          </thead>
          <tbody>
            {Object.keys(featuresName).map((feature, i) => {
              const mainFeaturesName = ["admin", "alcomeUser", "crm", "admin.inventory", "admin.annualReview"]
              const mainFeatures = mainFeaturesName.find(f => f === feature)
              return (<>
                <tr className={mainFeatures && 'bg-gray-100'}>
                  <td className='mb-2'>
                    {mainFeatures ?
                      <div className='text-alc-blue font-semibold'>{t(`features.${feature}.label`)}</div>
                      :
                      <div className='text-alc-blue pl-3'>{t(`features.${feature}`)}</div>
                    }
                  </td>
                  {columns.map(role => {
                    return (<>
                      <td className='text-center'>
                        <Checkbox checked={featureRoles[feature as any].indexOf(UserRolesEnum[role as any]) >= 0} onChange={_ => toggleFeature(feature as any, UserRolesEnum[role as any])}
                          className='items-center' />
                      </td>
                    </>)
                  })}
                </tr>
              </>)
            })}
          </tbody>
        </table>

        <div className='flex items-center'>
          <Button
            className='btn w-full'
            onClick={() => saveSettings()}
          >
            Enregistrer les paramètres
          </Button>
        </div>
      </div>
    </>

  );
};

export default RoleSettings;
import { ISupportOrders, SupportOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { Button, Form, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { selectUser } from "../../../../Auth/state/auth.slice";
import Section from "../../../../common/components/Panels/Section";
import { useAppSelector } from "../../../../common/hooks";
import supportOrderAdminApi from "../../../services/admin-supportOrder.api";
import supportOrderApi from "../../../services/supportOrder.api";
import SupportOrderCollectPointSelection from "../SupportOrderCollectPointSelection/SupportOrderCollectPointSelection";
import SupportOrderEteignoirsSelection from "../SupportOrderEteignoirSelection/SupportOrderEteignoirSelection";
import msg from "../../../../common/services/toast";

const SupportOrderMaterial = () => {

  const { supportOrderId }: any = useParams();
  const [form] = useForm();

  const [supportOrder, setSupportOrder] = useState<ISupportOrders | undefined>();

  const user = useAppSelector(selectUser)
  const isAdmin = user!.userType === UserTypeEnum.admin

  useEffect(() => {
    supportOrderApi.getSupportOrder(supportOrderId)
      .then(res => {
        setSupportOrder(res)
      })
  }, [supportOrderId])

  if (!supportOrder) {
    return <></>
  }


  const findHotspotCP = (hindex: number, cpindex: number) => {
    const hs = supportOrder.hotspots[hindex];
    if (hs) {
      const cp = hs.collectPoints && hs.collectPoints.length ? hs.collectPoints[cpindex] : undefined;
      if (cp) {

        return cp
      }
    }
    return {}
  }

  const sendDeclaration = async () => {
    let updatedSupportOrder = await supportOrderApi.getSupportOrder(supportOrderId)
    setSupportOrder(updatedSupportOrder)

    const userCanSendDeclaration = updatedSupportOrder
      && (updatedSupportOrder.extinguishers ?? []).find(ext => ext.id === undefined) === undefined
      && (updatedSupportOrder.hotspots ?? []).find(hs => hs.collectPoints?.length !== hs.qty) === undefined

    if (!userCanSendDeclaration) {
      msg.error("Merci de renseigner tous les dispositifs avant d'envoyer votre déclaration")
      return;
    }

    updatedSupportOrder = await supportOrderApi.sendDeclaration(supportOrderId)
    if (updatedSupportOrder) {
      setSupportOrder(updatedSupportOrder)
      message.success('Déclaration envoyée avec succès')
    }
  }

  const setSupportOrderStatus = async (status: SupportOrderStatusEnum) => {
    const updatedSupportOrder = await supportOrderAdminApi.updateSupportOrderSetCompleted(supportOrder!.id as string, status)
    if (updatedSupportOrder) {
      setSupportOrder(updatedSupportOrder)
      message.success('Mise à jour de la demande de soutien effectuée')
    }
  }


  return (
    <>
      <Form
        form={form}
        initialValues={{
          ...supportOrder,
          hotspots: supportOrder.hotspots
            .map(
              (h, hindex) => ({
                ...h,
                collectPoints: Array.from({ length: h.qty }, (v, cpindex) => findHotspotCP(hindex, cpindex))
              })
            ),
          extinguishers: Array.from({ length: supportOrder.extinguisherCount }, (v, cpindex) => (supportOrder.extinguishers && supportOrder.extinguishers[cpindex]) || ({}))
        }}
      >
        <Section title="Dispositif de rue">
          <SupportOrderCollectPointSelection supportOrder={supportOrder} />
        </Section>
        <Section title="Dispositifs de collecte associés aux corbeilles de rue">
          <SupportOrderEteignoirsSelection supportOrder={supportOrder} />
        </Section>
      </Form>
      {
        !isAdmin && supportOrder.status === SupportOrderStatusEnum.support_order_declarations_pending && (
          <>
            <Button type="primary" onClick={sendDeclaration}>Envoyer la déclaration</Button>
          </>
        )
      }
      {
        isAdmin && supportOrder.status === SupportOrderStatusEnum.support_order_declarations_completed && (
          <div>
            <Button type="primary" htmlType="button" className="btn mt-5" onClick={() => setSupportOrderStatus(SupportOrderStatusEnum.support_order_declarations_approved)}>
              Approuver la déclaration
            </Button>
            <Button
              type="link"
              htmlType="button"
              className="text-alc-danger"
              onClick={() => setSupportOrderStatus(SupportOrderStatusEnum.support_order_declarations_refused)}>
              Refuser la déclaration
            </Button>
          </div>
        )
      }
    </>
  )
}

export default SupportOrderMaterial
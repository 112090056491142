import { ISupplierCompany } from "@alcome-rep/alcome-types/dist/interfaces/supplier";
import { message } from "antd";
import ApiService, { ApiSortType } from "../../common/services/apiService";

const ADMIN_SUPPLIER_COMPANY_PATH_API = 'admin/supplierCompany';

class AdminSupplierCompanyApi extends ApiService<ISupplierCompany> {
  constructor() {
    super(ADMIN_SUPPLIER_COMPANY_PATH_API)
  }

  findAllSuppliers(query?: any, page?: number, limit?: number, sort?: ApiSortType) {
    return this.find({ query, page, pageSize: limit, sort })
  }
  createCompany(data: Partial<ISupplierCompany>) {
    return this.create(data)
      .then(res => {
        message.success('Fournisseur créé avec succès')
        return res
      })
      .catch(err => {
        message.error('Erreur lors de la création')
        return err
      })
  }

  findSupplierCompanyById(supplierCompanyId: string) {
    return this.get(supplierCompanyId)
  }

  updateSupplierCompany(supplierCompanyId: string, data: Partial<ISupplierCompany>) {
    return this.update(supplierCompanyId, data)
      .then(res => {
        message.success('Fournisseur mis à jour avec succès')
        return res
      })
      .catch(err => {
        message.error('Erreur lors de la mise à jour')
        return err
      })
  }

  updateSupplierCompanyShippingRules(supplierCompanyId: string, data: Pick<ISupplierCompany, 'shippingRules'>) {
    return this.update(supplierCompanyId + '/shippingRules', data)
  }

  updateSupplierCompanyAlertRules(supplierCompanyId: string, data: Pick<ISupplierCompany, 'alertRules'>) {
    return this.update(supplierCompanyId + '/alertRules', data)
  }

  updateSupplierCompanyShippingWeightSteps(supplierCompanyId: string, data: Partial<ISupplierCompany>) {
    return this.update(supplierCompanyId + '/shippingWeightSteps', data)
  }

}

const adminSupplierCompanyApi = new AdminSupplierCompanyApi();
export default adminSupplierCompanyApi;
import { EditOutlined } from "@ant-design/icons"
import { Button } from "antd"
import { useTranslation } from "react-i18next"
import { Link, Outlet } from "react-router-dom"
import NavTabs from "../../common/components/Panels/NavTabs"
import PageCanvas from "../../common/components/Panels/PageCanvas"
import { useAppDispatch, useAppSelector } from "../../common/hooks"
import { ROUTES } from "../../common/router"
import { closeDrawerAction, openDrawerAction } from "../../common/state/modal.slice"
import { getRoute } from "../../common/tools/router-tools"
import ProspectFigures from "../components/ProspectDetails/ProspectFigures"
import ProspectInfosForm from "../components/ProspectDetails/ProspectInfosForm"
import { selectCurrentAdminProspect } from "../state/prospect-admin.slice"

const ProspectLayout = () => {
  const prospect = useAppSelector(selectCurrentAdminProspect)
  const dispatch = useAppDispatch();
  const { t } = useTranslation()

  const editInfos = () => {
    dispatch(openDrawerAction({
      component: <ProspectInfosForm prospect={prospect!} formCb={_updatedProspect => dispatch(closeDrawerAction())} />,
      options: {
        title: "Modification"
      }
    }))
  }

  if (!prospect)
    throw new Error()

  return <>
    <PageCanvas breadCrumbs={[
      ROUTES.ADMIN_USER_ROUTES.home,
      ROUTES.ADMIN_CRM.ADMIN_PROSPECT_ROUTES.prospectList,
      ROUTES.ADMIN_CRM.ADMIN_PROSPECT_ROUTES.prospectDetails,
    ]}
      title={<div>
        <div>{prospect.name}</div>
        {prospect.structureId && <div className='text-sm'><Link to={getRoute(ROUTES.ADMIN_STRUCTURE_ROUTES.structureDetails, { structureId: prospect.structureId })}>Voir la structure</Link></div>}
      </div>}
      actions={<Button icon={<EditOutlined />} type="link" onClick={_ => editInfos()}>{t('COMMON.update')}</Button>}
    >

      <ProspectFigures prospect={prospect} />

      <NavTabs white={true} tabRoutes={[
        { ...ROUTES.ADMIN_CRM.ADMIN_PROSPECT_ROUTES.prospectDetails, params: { prospectId: prospect.id } },
        { ...ROUTES.ADMIN_CRM.ADMIN_PROSPECT_ROUTES.structure, params: { prospectId: prospect.id } },
        { ...ROUTES.ADMIN_CRM.ADMIN_PROSPECT_ROUTES.contacts, params: { prospectId: prospect.id } },
        { ...ROUTES.ADMIN_CRM.ADMIN_PROSPECT_ROUTES.actions, params: { prospectId: prospect.id } },
        { ...ROUTES.ADMIN_CRM.ADMIN_PROSPECT_ROUTES.files, params: { prospectId: prospect.id } },
      ]} />

      <Outlet />

    </PageCanvas>
  </>
}

export default ProspectLayout
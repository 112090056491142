import { Models } from "@alcome-rep/alcome-types";
import ApiService from "../../common/services/apiService";
import { AppUser } from "../types";

const USER_SUPPLIER_PATH_API = 'supplier/user';

class UserSupplierApi extends ApiService<AppUser> {
    constructor() {
        super(USER_SUPPLIER_PATH_API)
    }

    /**
     * @description Structure admin update user in structure
     * @param userId 
     * @param data 
     * @returns Return the target user updated 
     */
    updateInfos(data: Partial<Models.UserSupplier>) {
        return this.custom<Models.UserSupplier>('put', 'me', undefined, data)
            .then(r => new Models.User(r))
    }

}

const userSupplierApi = new UserSupplierApi();
export default userSupplierApi;
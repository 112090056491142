import { ISupplierOrder } from "@alcome-rep/alcome-types/dist/interfaces/supplier";
import { Button, DatePicker, Form } from "antd";
import dayjs from "dayjs";

type Props = {
    supplierOrder: ISupplierOrder;
    submit: (supplierOrderData: ISupplierOrder) => void;
}

const AdminUpdateSupplierOrderDates = ({ supplierOrder, submit }: Props) => {

    return (
        <div>
            {supplierOrder && (
                <Form
                    name="basic"
                    initialValues={
                        {
                            ...supplierOrder,
                            shipDate: supplierOrder?.shipDate ? dayjs(supplierOrder.shipDate) : undefined,
                            deliveryDate: supplierOrder?.deliveryDate ? dayjs(supplierOrder.deliveryDate) : undefined
                        }
                    }
                    layout='vertical'
                    onFinish={submit}
                    className="grid grid-cols-2 gap-x-4"
                    id="pa"
                >
                    <Form.Item
                        label="Date d'expédition"
                        name="shipDate"
                        className="col-span-2"
                    >
                        <DatePicker format={'DD/MM/YYYY'} ></DatePicker>
                    </Form.Item>
                    <Form.Item
                        label="Date de livraison"
                        name="deliveryDate"
                        className="col-span-2"
                    >
                        <DatePicker format={'DD/MM/YYYY'} ></DatePicker>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" type="primary"  >
                            Modifier
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </div>
    )
}

export default AdminUpdateSupplierOrderDates
import { AcquisitionItemOrderStatusEnum, IAcquisitionOrders } from "@alcome-rep/alcome-types/dist/interfaces";
import { Draft, PayloadAction, createSelector } from "@reduxjs/toolkit";
import factoryCreateSlice from "../../common/state/slice.factory";
import { RootState } from "../../common/store";
import { BaseServiceState } from "../../common/state/types";

export const ACQUISITION_ORDER_ADMIN_SERVICE = "acquisitionOrderAdminService";
// eslint-disable-next-line
export type ACQUISITION_ORDER_ADMIN_SERVICE = typeof ACQUISITION_ORDER_ADMIN_SERVICE; // Typescript line


const slices = factoryCreateSlice<"acquisitionAdminOrder", IAcquisitionOrders>('acquisitionAdminOrder', {

  updateAdminAcquisitionOrderItemStatusActions(
    state: Draft<BaseServiceState<IAcquisitionOrders, 'current'>>,
    { payload }: PayloadAction<Draft<{ acquisitionOrderId: string, itemIndex: number, status: AcquisitionItemOrderStatusEnum }>>) {
  },

  updateAdminAcquisitionOrderStatusActions(
    state: Draft<BaseServiceState<IAcquisitionOrders, 'current'>>,
    { payload }: PayloadAction<Draft<{ id: string, data: Pick<IAcquisitionOrders, 'status'> }>>) {
  },
})

export const {
  getAction: getAdminAcquisitionOrderAction,
  updateAction: updateAdminAcquisitionOrderStatusActions,
  setCurrentAction: setCurrentAdminAcquisitionOrderAction,
  updateAdminAcquisitionOrderItemStatusActions,
} = slices.actions;
export default slices.reducer;

export const selectAdminAcquisitionOrderService = (state: RootState) => state.acquisitionOrderAdminService
export const selectCurrentAdminAcquisitionOrder = createSelector(selectAdminAcquisitionOrderService, (acquisitionSvc) => acquisitionSvc.current)
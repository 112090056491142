import { Models } from '@alcome-rep/alcome-types';
import { UserTypeEnum } from '@alcome-rep/alcome-types/dist/interfaces/user';
import { Button, Form, Input } from 'antd';
import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { refreshTokenAction, selectUserMember } from '../../../../Auth/state/auth.slice';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import useFormRules from '../../../../common/hooks/useFormRules';
import msg from '../../../../common/services/toast';
import userMemberApi from '../../../services/user-member.api';
import userApi from '../../../services/user.api';


const UserMemberProfil = () => {

  const { t } = useTranslation();
  const user = useAppSelector(selectUserMember);

  const dispatch: Dispatch<any> = useAppDispatch()

  const submit = (userData: Models.UserMember) => {
    userMemberApi.updateInfos(userData)
    dispatch(refreshTokenAction(UserTypeEnum.portal))
  }

  const { required, phoneNumber, email, password: passwordMinLength } = useFormRules();

  const changePassword = async (passwordData: any) => {
    try {
      await userApi.changePassword(passwordData);
      msg.success("Mot de passe changé avec succès")
    }
    catch {
    }
  }

  return (
    <>
      <div className='grid grid-cols-4 gap-4 px-12'>
        <div className='p-4 col-span-4 lg:col-span-2'>
          <h4 id="infos">Mes données personnelles</h4>
          <Form
            name="basic"
            initialValues={user}
            layout='vertical'
            onFinish={submit}
          >
            <Form.Item
              label={t('UI.PROFIL.firstName')}
              name="firstName"
              rules={[required]}
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              label={t('UI.PROFIL.lastName')}
              name="lastName"
              rules={[required]}
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              label={t('UI.PROFIL.position')}
              name="position"
              rules={[required]}
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              label={t('UI.PROFIL.phone')}
              name="phone"
              rules={[required, phoneNumber]}
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              label={t('UI.PROFIL.email')}
              name="email"
              rules={[required, email]}
            >
              <Input></Input>
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary" className="bg-alc-blue">
                Modifier les paramètres
              </Button>
            </Form.Item>
          </Form>
        </div>

        <div className='p-4 col-span-4  lg:col-span-2'>
          <h4 id="account-settings" className='mb-5'>Accès au compte</h4>
          <Form
            name="basic"
            layout='vertical'
            onFinish={changePassword}
          >
            <Form.Item
              label={t('UI.PROFIL.password')}
              name="oldPassword"
              rules={[required]}
            >
              <Input type={'password'}></Input>
            </Form.Item>
            <Form.Item
              label={t('UI.PROFIL.newPassword')}
              tooltip={t('UI.PROFIL.tooltipPassword')}
              name="password"
              rules={[required, passwordMinLength]}
            >
              <Input type={'password'}></Input>
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary" className="bg-alc-blue">
                Modifier le mot de passe
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default UserMemberProfil;
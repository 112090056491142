import { RouteObject } from "react-router-dom";
import PortalDashBoardPage from "../../Dashboard/components/portal/PortalDashBoardPage";
import UserPortalDetailsPage from "../components/portal/UserPortalDetails/UserPortalDetailsPage";
import UserPortalProfil from "../components/portal/UserPortalProfil/UserPortalProfilPage";
import UserPortalStructureProfilPage from "../components/portal/UserStructureProfil/UserStructureProfilPage";

const routePrefix = 'portal'
const routeDomain = 'user'
const routeParam = 'userId'
// const routeUserTypeParam = 'userType'

export const userPortalRouteNames = {
  home: { path: `${routePrefix}/home`, title: 'ROUTES.dashboard' },
  administratifProfil: { path: `${routePrefix}/${routeDomain}/administratifProfil`, title: `ROUTES.${routeDomain}.administratifProfil` },
  userProfil: { path: `${routePrefix}/${routeDomain}/userProfil`, title: `ROUTES.${routeDomain}.profil` },
  userDetails: { path: `${routePrefix}/${routeDomain}/details/:${routeParam}`, title: `ROUTES.${routeDomain}.details` }
}

console.log(userPortalRouteNames.home.path.replace(`${routePrefix}`, ''))

export const userPortalRoutes: RouteObject[] = [
  {
    path: userPortalRouteNames.home.path.replace(`${routePrefix}/`, ''),
    element: <PortalDashBoardPage /> ,
  },
  {
    path: userPortalRouteNames.administratifProfil.path.replace(`${routePrefix}/`, ''),
    element: <UserPortalStructureProfilPage />
  },
  {
    path: userPortalRouteNames.userProfil.path.replace(`${routePrefix}/`, ''),
    element: <UserPortalProfil />,
  },
  {
    path: userPortalRouteNames.userDetails.path.replace(`${routePrefix}/`, ''),
    element: <UserPortalDetailsPage />
  },
]
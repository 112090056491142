import { ISupplierCompany } from "@alcome-rep/alcome-types/dist/interfaces/supplier"
import { Button, Form } from "antd"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import LoadingScreen from "../../../../common/components/LoadingScreen"
import PageCanvas from "../../../../common/components/Panels/PageCanvas"
import Section from "../../../../common/components/Panels/Section"
import { useAppDispatch } from "../../../../common/hooks"
import { ROUTES } from "../../../../common/router"
import { closeDrawerAction, openDrawerAction } from "../../../../common/state/modal.slice"
import adminSupplierCompanyApi from "../../../services/admin-supplierCompany.api"
import SupplierShippingRules from "../../common/SupplierEditForm/SupplierShippingRules"
import SupplierEditForm from "../../common/SupplierEditForm/supplierEditForm"
import AdminSupplierCompanyOrderList from "../AdminSupplierCompanyOrder/AdminSupplierCompanyOrderList"
import ShippingWeightSteps from "../../common/ShippingWeightSteps"
import { SupplierCompany } from "@alcome-rep/alcome-types/dist/models"
import { useTranslation } from "react-i18next"
import SupplierShippingSteps from "../../common/SupplierEditForm/SupplierShippingSteps"
import SupplierAlertRulesComponent from "../../common/SupplierEditForm/SupplierAlertRules"

const AdminSupplierCompanyDetails = () => {

  const { supplierCompanyId } = useParams();
  const [supplierCompany, setSupplierCompany] = useState<ISupplierCompany>()

  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    adminSupplierCompanyApi.get(supplierCompanyId as string)
      .then(res => setSupplierCompany(res))
  }, [supplierCompanyId])

  const editInfos = () => {
    dispatch(openDrawerAction({
      component: <SupplierEditForm supplierCompany={supplierCompany} onSubmit={(data) => {
        dispatch(closeDrawerAction())
        if (data && supplierCompany)
          setSupplierCompany({ ...data })
      }} />,
      options: {
        title: 'Fournisseur'
      }
    }))
  }

  if (!supplierCompany) {
    return <LoadingScreen />
  }

  const submitShippingWeightSteps = (values: SupplierCompany) => {
    adminSupplierCompanyApi.updateSupplierCompanyShippingWeightSteps(supplierCompany.id!, values)
      .then(res => setSupplierCompany(res))
  }

  return (
    <PageCanvas
      breadCrumbs={[
        ROUTES.ADMIN_USER_ROUTES.home,
        ROUTES.ADMIN_SUPPLIER_COMPANY_ROUTES.supplierCompanyList,
        ROUTES.ADMIN_SUPPLIER_COMPANY_ROUTES.supplierCompanyDetails
      ]}
      title={<div>Fiche fournisseur</div>}
      actions={<Button className="btn" onClick={editInfos}>Modifier les informations</Button>}
    >
      <Section title={'Informations'}>
        <div className="font-semibold">{supplierCompany.name}</div>
        <div className="font-semibold">N° SIRET : {supplierCompany.siret}</div>
        {supplierCompany.accountingTier && (
          <div className="font-semibold">Compte tier : {supplierCompany.accountingTier}</div>
        )}
        <h6>Adresse</h6>
        <div>{supplierCompany.address.street}</div>
        <div>{supplierCompany.address.street2}</div>
        <div>{supplierCompany.address.zip}, {supplierCompany.address.city}</div>
      </Section>

      <Section mode="transparent" title={'Commandes fournisseurs'}>
        <AdminSupplierCompanyOrderList supplierId={supplierCompany.id!} />
      </Section>

      <Section mode="transparent" title={'Grille tarifaire des frais de livraison cendrier selon la quantité'}>
        <Form<SupplierCompany>
          initialValues={supplierCompany}
          onFinish={submitShippingWeightSteps}
        >
          <Form.Item name={'shippingStepsAshtray'} hidden />
          <div className="mb-4">
            <SupplierShippingSteps property='shippingStepsAshtray' />
          </div>
          <Button htmlType="submit" type="primary">{t('COMMON.save')}</Button>
        </Form>
      </Section>

      <Section mode="transparent" title={'Grille tarifaire des frais de livraison éteignoir selon la quantité'}>
        <Form<SupplierCompany>
          initialValues={supplierCompany}
          onFinish={submitShippingWeightSteps}
        >
          <Form.Item name={'shippingStepsExtinguisher'} hidden />
          <div className="mb-4">
            <SupplierShippingSteps property='shippingStepsExtinguisher' />
          </div>
          <Button htmlType="submit" type="primary">{t('COMMON.save')}</Button>
        </Form>
      </Section>

      <Section mode="transparent" title={'Grille tarifaire des frais de livraison cendrier selon le poids'}>
        <Form<SupplierCompany>
          initialValues={supplierCompany}
          onFinish={submitShippingWeightSteps}
        >
          <Form.Item name={'shippingWeightStepsAshtray'} hidden />
          <div className="mb-4">
            <ShippingWeightSteps property='shippingWeightStepsAshtray' />
          </div>
          <Button htmlType="submit" type="primary">{t('COMMON.save')}</Button>
        </Form>
      </Section>

      <Section mode="transparent" title={'Grille tarifaire des frais de livraison éteignoir selon le poids'}>
        <Form<SupplierCompany>
          initialValues={supplierCompany}
          onFinish={submitShippingWeightSteps}
        >
          <Form.Item name={'shippingWeightStepsExtinguisher'} hidden />
          <div className="mb-4">
            <ShippingWeightSteps property='shippingWeightStepsExtinguisher' />
          </div>
          <Button htmlType="submit" type="primary">{t('COMMON.save')}</Button>
        </Form>
      </Section>

      <Section mode="transparent" title={'Remises spéciales sur livraison'}>
        <SupplierShippingRules supplier={supplierCompany} />
      </Section>

      <Section mode="transparent" title={'Régles alertant le Directeur financier'}>
        <SupplierAlertRulesComponent supplier={supplierCompany} />
      </Section>


    </PageCanvas>
  )
}

export default AdminSupplierCompanyDetails
import { BarcodeOutlined, BuildOutlined, ContainerOutlined, DashboardOutlined, FileTextFilled, FolderOpenOutlined, FundOutlined, GlobalOutlined, GroupOutlined, NotificationFilled, PlusSquareOutlined, ShoppingCartOutlined, ShoppingOutlined, TeamOutlined, ThunderboltOutlined, UnorderedListOutlined, UserOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/es/menu/interface';
import { Menu, MenuProps } from 'antd/lib';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../router';
import { getRoute } from '../../tools/router-tools';

const AdminSideBar = () => {
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>([])
  const [openedKeys, setOpenedKeys] = useState<string[]>()
  const navigate = useNavigate();
  const { pathname } = useLocation()

  const items: MenuProps['items'] = useMemo(() => ([
    { key: getRoute(ROUTES.ADMIN_USER_ROUTES.home), label: "Tableau de Bord", icon: <DashboardOutlined />, },
    {
      key: ROUTES.ADMIN_USER_ROUTES.userList.path, label: "Utilisateurs", icon: <TeamOutlined />, children: [
        { key: getRoute(ROUTES.ADMIN_USER_ROUTES.userList, { userType: 'portal' }), label: "Utilisateurs du Portail" },
        { key: getRoute(ROUTES.ADMIN_USER_ROUTES.userList, { userType: 'admin' }), label: "Administrateurs du Portail" },
        { key: getRoute(ROUTES.ADMIN_USER_ROUTES.userList, { userType: 'supplier' }), label: "Utilisateurs fournisseurs" },
        { key: getRoute(ROUTES.ADMIN_USER_ROUTES.userList, { userType: 'member' }), label: "Adhérants" },
      ]
    },

    { key: "crmDivider", type: 'divider' },
    {
      key: "crm", label: "CRM", icon: <ShoppingOutlined />, children: [
        { key: getRoute(ROUTES.ADMIN_CRM.ADMIN_PROSPECT_ROUTES.prospectList), label: "Prospects", icon: <FolderOpenOutlined /> },
        { key: getRoute(ROUTES.ADMIN_CRM.ADMIN_ACTION_ROUTES.actionList), label: "Actions", icon: <ThunderboltOutlined /> },
        { key: getRoute(ROUTES.ADMIN_CRM.ADMIN_CONTACT_ROUTES.contactList), label: "Contacts", icon: <UserOutlined /> },
      ]
    },

    {
      key: "structure", label: "Structures & contrats", icon: <GroupOutlined />, children: [
        { key: getRoute(ROUTES.ADMIN_STRUCTURE_ROUTES.structureList), label: "Liste des structures", icon: <UnorderedListOutlined /> },
        { key: getRoute(ROUTES.ADMIN_ANNUALREVIEW_ROUTES.annualReviewList), label: "Bilans", icon: <FundOutlined /> },
        // { key: getRoute(ROUTES.ADMIN_SUPPORT_ORDER_ROUTES.supportOrderList), label: "Demandes de soutiens", icon: <EuroOutlined /> },
        { key: getRoute(ROUTES.ADMIN_ACQUISITION_ORDER_ROUTES.acquisitionOrderList), label: "Demandes de dispositifs", icon: <PlusSquareOutlined /> },
        { key: getRoute(ROUTES.ADMIN_ORDER_ROUTES.orderList), label: "Commandes", icon: <ShoppingCartOutlined /> },

      ]
    },

    {
      key: "supportGroup", label: "Fournisseurs", icon: <BuildOutlined />, children: [
        {
          key: getRoute(ROUTES.ADMIN_SUPPLIER_COMPANY_ROUTES.supplierCompanyList
          ), label: "Liste des fournisseurs", icon: <UnorderedListOutlined />
        },
        { key: getRoute(ROUTES.ADMIN_PRODUCT_ROUTES.productList), label: "Produits", icon: <BarcodeOutlined /> },
        { key: getRoute(ROUTES.ADMIN_SUPPLIER_ORDER_ROUTES.supplierOrderList), label: "Commandes", icon: <ShoppingCartOutlined /> },
      ]
    },

    {
      key: "memberGroup", label: "Adhérents", icon: <GlobalOutlined />, children: [
        { key: getRoute(ROUTES.ADMIN_MEMBER_ROUTES.memberList), icon: <UnorderedListOutlined />, label: "Liste des adhérents" },
        { key: getRoute(ROUTES.ADMIN_DECLARATION_ROUTES.declarationList), icon: <ContainerOutlined />, label: "Déclarations annuelles" },
      ]
    },
    { key: "settingsDivider", type: 'divider' },
    { key: getRoute(ROUTES.ADMIN_LIBRARYDOC_ROUTES.libraryDocList), icon: <NotificationFilled />, label: "Docuthèque" },
    { key: getRoute(ROUTES.ADMIN_ADMENDMENT_ROUTES.amendmentList), icon: <FileTextFilled />, label: "Avenants" },
    { key: getRoute(ROUTES.ADMIN_ACCOUNTING_EXPORT_ROUTES.accountingexportRevenueOrderList), icon: <FileTextFilled />, label: "Extractions comptables" },
  ]), [])

  useEffect(() => {
    setDefaultSelectedKeys([pathname])

    function findItem(path: string, items: MenuProps['items'], selection: ItemType[] = []): ItemType | undefined {
      if (items === undefined)
        return undefined;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        // if (!item)
        //   continue;
        if (item?.key?.toString() === path) {
          return item;
        }
        else if ((item as any).children) {
          const found = findItem(path, (item as any).children)
          if (found) {
            selection.push(item)
            return item; // returns parent that shall be a group}
          }
        }
      }
    }
    const selection: ItemType[] = []
    findItem(pathname, items, selection)
    setOpenedKeys(["structure", ...selection.map(s => s!.key!.toString())])

    // if (/^[!/]+/.test(pathname)) {
    //   const pathPrefix = pathname.match(/^([!/]+)/)![1]
    //   setOpenedKeys(["structure", pathPrefix])
    // }
  }, [pathname, items, setOpenedKeys])

  const onClick = ({ key }: any) => {
    navigate(key);
  }

  return (
    <div className='flex flex-col h-full'>
      <div className='font-semibold text-xl py-6' style={{ backgroundColor: '#dfe3ee' }}>
        <div>
          <div className='text-alc-blue text-center'>Administration</div>
        </div>
      </div>
      <div
        style={{ backgroundColor: '#dfe3ee' }}
        className="flex-1 overflow-y-auto"
      >
        {openedKeys &&
          <Menu
            defaultOpenKeys={openedKeys}
            selectedKeys={defaultSelectedKeys}
            onSelect={onClick}
            style={{ width: 256 }}
            mode="inline"
            items={items}
          />
        }
        {/* <div className='sidemenu '>
          <NavLink className={({ isActive }) => 'mainNavlink ' + (isActive ? 'active' : '')} to={getRoute(ROUTES.ADMIN_USER_ROUTES.home)}><DashboardOutlined />Tableau de Bord</NavLink>

          <div className='group'>
            <NavLink style={{ pointerEvents: 'none' }} className={({ isActive }) => 'mainNavlink  ' + (isActive ? 'active' : '')} to={'/' + ROUTES.ADMIN_USER_ROUTES.userList.path.replace(/\/:.+$/, '')}><UserOutlined />Utilisateurs</NavLink>
            <div className={`sidemenu overflow-y-hidden max-h-0 group-hover:max-h-96 transition-all delay-500 duration-300 ml-5`}>
              <NavLink className={({ isActive }) => 'mainNavlink ' + (isActive ? 'active' : '')} to={getRoute(ROUTES.ADMIN_USER_ROUTES.userList, { userType: 'portal' })}>Utilisateurs du Portail</NavLink>
              <NavLink className={({ isActive }) => 'mainNavlink ' + (isActive ? 'active' : '')} to={getRoute(ROUTES.ADMIN_USER_ROUTES.userList, { userType: 'admin' })}>Administrateur du Portail</NavLink>
              <NavLink className={({ isActive }) => 'mainNavlink ' + (isActive ? 'active' : '')} to={getRoute(ROUTES.ADMIN_USER_ROUTES.userList, { userType: 'crm' })}>Utilisateurs CRM</NavLink>
              <NavLink className={({ isActive }) => 'mainNavlink ' + (isActive ? 'active' : '')} to={getRoute(ROUTES.ADMIN_USER_ROUTES.userList, { userType: 'supplier' })}>Fournisseurs</NavLink>
              <NavLink className={({ isActive }) => 'mainNavlink ' + (isActive ? 'active' : '')} to={getRoute(ROUTES.ADMIN_USER_ROUTES.userList, { userType: 'member' })}>Adhérants</NavLink>
            </div>
          </div>
          <hr className='my-2' />

          <NavLink className={({ isActive }) => 'mainNavlink ' + (isActive ? 'active' : '')} to={getRoute(ROUTES.ADMIN_STRUCTURE_ROUTES.structureList)}><CodeSandboxSquareFilled />Structures</NavLink>
          <NavLink className={({ isActive }) => 'mainNavlink ' + (isActive ? 'active' : '')} to={getRoute(ROUTES.ADMIN_ANNUALREVIEW_ROUTES.annualReviewList)}><FundFilled />Bilans</NavLink>
          <NavLink className={({ isActive }) => 'mainNavlink ' + (isActive ? 'active' : '')} to={getRoute(ROUTES.ADMIN_ORDER_ROUTES.orderList)}><ShoppingFilled />Commandes</NavLink>

          <hr className='my-2' />
          <NavLink className={({ isActive }) => 'mainNavlink ' + (isActive ? 'active' : '')} to={getRoute(ROUTES.ADMIN_MEMBER_ROUTES.memberList)}><TeamOutlined />Adhérents</NavLink>
          <NavLink className={({ isActive }) => 'mainNavlink ' + (isActive ? 'active' : '')} to={getRoute(ROUTES.ADMIN_DECLARATION_ROUTES.declarationList)}> <ContainerFilled />Déclarations annuelles</NavLink>

          <hr className='my-2' />
          <NavLink className={({ isActive }) => 'mainNavlink ' + (false && isActive ? 'active' : '')} to={getRoute(ROUTES.ADMIN_LIBRARYDOC_ROUTES.libraryDocList)}><NotificationFilled />Docuthèque</NavLink>
          <NavLink className={({ isActive }) => 'mainNavlink ' + (false && isActive ? 'active' : '')} to={getRoute(ROUTES.ADMIN_ADMENDMENT_ROUTES.amendmentList)}><FileTextFilled />Avenants</NavLink>

        </div> */}
      </div>
    </div>
  );
};

export default AdminSideBar;
import { BaseCommune } from "@alcome-rep/alcome-types/dist/models";
import { Button, Form, Input } from "antd";
import { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../common/hooks";
import structureAdminApi from "../../../services/structure-admin.api";
import { adminSetCurrentStructureAction } from "../../../state/structure-admin.slice";

type PropTypes = {
  structure: BaseCommune,
  formCb?: (data?: FormType) => void
}

type FormType = Partial<BaseCommune>

const StructureInfosForm = ({ structure, formCb }: PropTypes) => {

  const { t } = useTranslation();

  const dispatch: Dispatch<any> = useAppDispatch()

  const submit = async (structureData: FormType) => {
    const updatedStructure = await structureAdminApi.updateStructure(structure.id!, structureData)
    dispatch(adminSetCurrentStructureAction(updatedStructure))

    formCb && formCb(structureData)
  }

  return (
    <>
      <Form<FormType>
        name="basic"
        initialValues={structure}
        layout='vertical'
        onFinish={submit}
      >
        <div>
        
          <Form.Item
            label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.street.label')}
            name={['address', 'street']}
          >
            <Input></Input>
          </Form.Item>

          <Form.Item
            label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.city.label')}
            name={['address', 'city']}
          >
            <Input></Input>
          </Form.Item>

          <Form.Item
            label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.zip.label')}
            name={['address', 'zip']}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.email.label')}
            name="email"
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.phone.label')}
            name="phone"
          >
            <Input></Input>
          </Form.Item>
          
          <Form.Item
            label={t('UI.ADMIN.STRUCTURE_DETAILS.fields.accountingTier.label')}
            name="accountingTier"
          >
            <Input></Input>
          </Form.Item>

          <div className="text-right">
            <Button htmlType="button" type="link" onClick={_ => formCb && formCb()} >{t('COMMON.cancel')}</Button>
            <Button htmlType="submit" type="primary" >{t('COMMON.save')}</Button>
          </div>
        </div>
      </Form>
    </>
  )
}

export default StructureInfosForm
import { Models } from "@alcome-rep/alcome-types";
import { Prospect } from "@alcome-rep/alcome-types/dist/models";
import { createSelector } from "@reduxjs/toolkit";
import factoryCreateSlice from "../../common/state/slice.factory";
import { RootState } from "../../common/store";

export const PROSPECT_ADMIN_SERVICE = "prospectAdminService";
// eslint-disable-next-line
export type PROSPECT_ADMIN_SERVICE = typeof PROSPECT_ADMIN_SERVICE; // Typescript line


export type AdminProspectServiceState = {
  current: Prospect | null
};

const slices = factoryCreateSlice<"AdminProspect", Models.Prospect>('AdminProspect')


export const {
  getAction: adminGetProspectAction,
  updateAction: adminUpdateProspectAction,
  setCurrentAction: adminSetCurrentProspectAction,
  deleteAction: adminDeleteProspectAction
} = slices.actions;

export default slices.reducer;

export const selectProspectService = (state: RootState) => state.prospectAdminService
export const selectCurrentAdminProspect = createSelector(selectProspectService, (prospectSvc) => prospectSvc.current)
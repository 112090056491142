import { IUser, IUserAdmin } from "@alcome-rep/alcome-types/dist/interfaces/user";
import ApiService from "../../common/services/apiService";
import { AppUser } from "../types";
import { Models } from "@alcome-rep/alcome-types";
import { IStructure } from "@alcome-rep/alcome-types/dist/interfaces";

const USER_PATH_API = 'user';

class UserApi extends ApiService<AppUser> {
  constructor() {
    super(USER_PATH_API)
  }

  /**
   * @description Get my infos
   * @returns My infos
   */
  register(data: { user: IUser, structure: IStructure }): Promise<AppUser> {
    return this.custom<Models.User>('post', 'register', undefined, data)
      .then(r => new Models.User(r))
  }

  /**
   * @description Get current user
   * @returns User
   */
  getMe() {
    return this.get('me')
  }

  /**
     * @description Update me as Admin user
     * @param data 
     * @returns My infos updated
     */
  updateMe(data: Partial<IUser>) {
    return this.custom('put', '', undefined, data)
  }

  /**
   * @description Update my password
   * @param passwordData 
   * @returns My infos updated
   */
  updateMyPassword(passwordData: any) {
    return this.custom('put', 'me/password', undefined, passwordData)
  }

  /**
   * @description send a request for a new password via email link
   * @param email 
   * @returns 
   */
  askForPassword(email: string) {
    return this.custom('put', 'forgot-password', undefined, { email })
  }

  /**
   * @description change password with unique token
   * @param token 
   * @param password 
   * @returns {success : true}
   */
  recoverPassword(token: string, password: string): Promise<IUser> {
    return this.custom('put', 'change-password/:token', { token }, { password })
  }

  /**
   * @description change password with unique token
   * @param token 
   * @param password 
   * @returns {success : true}
   */
  changePassword(data: { oldPassword: string, password: string }): Promise<IUser> {
    return this.update('me/password', data)
  }

  findRegionalDelegate = (department: string) => {
    return this.custom<IUserAdmin>('get', `findRegionalDelegate?departments[]=${department}`)
  }


}

const userApi = new UserApi();
export default userApi;
import { AnnualReviewStatusEnum, IAnnualReview } from "@alcome-rep/alcome-types/dist/interfaces"
import { Tag } from "antd"
import { useTranslation } from "react-i18next"

type PropsType = {
  annualReview: IAnnualReview
}

const AnnualReviewStatus = ({ annualReview }: PropsType) => {
  const { t } = useTranslation();

  return <Tag className="space-x-2" color={t(`UI.TAG.AnnualReviewStatusEnum.${AnnualReviewStatusEnum[annualReview?.status]}`)}>
    {t(`TYPES.ANNUAL_REVIEW.AnnualReviewStatusEnum.${AnnualReviewStatusEnum[annualReview?.status]}`)}
  </Tag>
}

export default AnnualReviewStatus
import { Models } from "@alcome-rep/alcome-types";
import { UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { refreshTokenAction, selectHasRefreshUser, selectIsRefreshing, selectUser } from "../../Auth/state/auth.slice";
import LoadingScreen from "../../common/components/LoadingScreen";
import { useAppDispatch, useAppSelector } from "../../common/hooks";
import { ROUTES } from "../../common/router";

const UserSupplierGuard = ({ children }: any) => {
  // const [hasBeenRefreshed, setHasBeenRefreshed] = useState<boolean>(false)
  const user = useAppSelector(selectUser);
  const isRefreshing = useAppSelector(selectIsRefreshing);
  const hasRefreshUser = useAppSelector(selectHasRefreshUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!hasRefreshUser && !isRefreshing) {
      dispatch(refreshTokenAction(UserTypeEnum.supplier))
    }
  }, [dispatch, isRefreshing, hasRefreshUser])

  //   useEffect(() => {
  //     if (!user || !hasBeenRefreshed)
  //       return;
  //   }, [user, hasBeenRefreshed])

  if (!hasRefreshUser)
    return <LoadingScreen />
  else if (!Models.User.IsUserSupplier(user))
    return <Navigate to={ROUTES.LOGIN}></Navigate>
  // else if (!isRefreshing && !Models.User.IsUserSupplier(user))
  //   return <Navigate to={ROUTES.LOGIN}></Navigate>

  if (children)
    return <>{children}</>

  return <Outlet />
}

export default UserSupplierGuard;
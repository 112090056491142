import { StructureTypesEnum } from "@alcome-rep/alcome-types/dist/interfaces"
import { IProspect } from "@alcome-rep/alcome-types/dist/interfaces/crm"
import { BaseCommune } from "@alcome-rep/alcome-types/dist/models"
import ProspectCommuneType from "../../../Structure/components/common/ProspectCommuneType"
import StructureType from "../../../Structure/components/common/StructureType"
import Figure from "../../../common/components/Panels/Figure"
import ProspectInterest from "./ProspectInterest"
import ProspectType from "./ProspectType"

type PropsType = {
  prospect: IProspect,
}

const ProspectFigures = ({ prospect }: PropsType) => {
  return <>
    <div className="lg:grid lg:grid-cols-4 lg:gap-4 my-4">
      <Figure title="Type de prospect" value={<ProspectType prospect={prospect} />} />
      <Figure title="Niveau d'intérêt" value={<ProspectInterest prospect={prospect} />} />
      <Figure title="Type de structure" value={<StructureType mode="tag" structure={{ structureType: prospect.structureType } as BaseCommune} />} />
      {prospect.structureType === StructureTypesEnum.commune && (
      <Figure title="Type de commune" value={<ProspectCommuneType communeType={prospect.communeType} />} />
      )}
    </div>
  </>
}

export default ProspectFigures
import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import Section from "../../../../common/components/Panels/Section";
import useFormRules from "../../../../common/hooks/useFormRules";
import msg from "../../../../common/services/toast";
import userApi from "../../../services/user.api";

const AdminProfilPasswordForm = () => {

  const { t } = useTranslation()
  const { required, password: passwordMinLength } = useFormRules();

  const changePassword = async (passwordData: any) => {
    try {
      await userApi.changePassword(passwordData);
      msg.success("Mot de passe changé avec succès")
    }
    catch {
      msg.error("Le mot de passe doit contenir au minimum 8 caractères. 1 majuscule et un caractère spécial (@,$,!,%,*,?,&)")
    }
  }

  return (
    <Section title={<span>Accès au compte</span>}>
      <Form
        name="basic"
        layout='vertical'
        onFinish={changePassword}
      >
        <Form.Item
          label={t('UI.PROFIL.password')}
          name="oldPassword"
          rules={[required]}
        >
          <Input type={'password'}></Input>
        </Form.Item>
        <Form.Item
          label={t('UI.PROFIL.newPassword')}
          tooltip={t('UI.PROFIL.tooltipPassword')}
          name="password"
          rules={[required, passwordMinLength]}
        >
          <Input type={'password'}></Input>
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" className="bg-alc-blue">
            Modifier le mot de passe
          </Button>
        </Form.Item>
      </Form>
    </Section>
  )
}

export default AdminProfilPasswordForm
import { IHotspot, IStructure, StructureTypesEnum } from "@alcome-rep/alcome-types/dist/interfaces"
import { EditOutlined } from "@ant-design/icons"
import { Button, List } from "antd"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import AddButton from "../../../common/components/Buttons/addButton"
import SeeMoreButton from "../../../common/components/Buttons/seeMore"
import mapApi from "../../services/map.api"
import HotSpotModal from "./hotspotModal"

type PropsType = {
  structure: IStructure
  canEdit: boolean
  selectedCommune?: string,
  pageSize?: number,
  initialHotspots?: IHotspot[]
  toggleMapModeCb: () => void
}

const HotspotList = ({ structure, canEdit, selectedCommune, pageSize = 6, toggleMapModeCb, initialHotspots }: PropsType) => {

  const [hotspotsLimit, setHotspotLimit] = useState<number>(pageSize);
  const [hotspots, setHotspots] = useState<IHotspot[]>([]);
  const [selectedHotspot, setSelectedHotspot] = useState<IHotspot | undefined>();
  const [filteredHotspots, setFilteredHotspots] = useState<IHotspot[]>([]);

  const structureId = structure.id

  const { t } = useTranslation()

  if (canEdit && initialHotspots)
    canEdit = false

  const getHotspots = useCallback(() => {
    if (structureId)
      mapApi.getHotspots(structureId)
        .then(r => setHotspots(r.rows))
  }, [structureId])

  useEffect(() => {
    if (!initialHotspots) {
      getHotspots();
    } else {
      setHotspots(initialHotspots)
    }
  }, [getHotspots, initialHotspots, setHotspots])

  useEffect(() => {
    const isGrp = (structure.structureType === StructureTypesEnum.groupement_communes);
    const filteredHp = [...hotspots]
      .filter(cp => selectedCommune !== undefined ? cp.communeName?.toLowerCase() === selectedCommune.toLowerCase() : isGrp ? null : true);
    setFilteredHotspots(filteredHp)
  }, [hotspots, selectedCommune, structure])

  const updateData = async (data: IHotspot) => {
    if (!selectedHotspot)
      return;

    if (selectedHotspot.id) {
      await mapApi.updateHotspot(selectedHotspot.id, data)
    }
    setSelectedHotspot(undefined);
    getHotspots();
  }

  const deleteData = async () => {
    if (!selectedHotspot)
      return;
    await mapApi.deleteHotspot(selectedHotspot.id ?? selectedHotspot._id!);
    setSelectedHotspot(undefined);
    getHotspots();
  }

  return (
    <div>
      <List
        header={
          <div className="flex justify-between">
            <span className="text-lg">Hotspots</span>
            {canEdit && <AddButton onClick={toggleMapModeCb}>Ajouter un hotspot sur la carte</AddButton>}
          </div>
        }
        size="small"
        dataSource={filteredHotspots.slice(0, hotspotsLimit)}
        renderItem={(hotspot, index) => (
          <List.Item
            actions={[ 
              <Button
                icon={<EditOutlined className="text-alc-blue"></EditOutlined>}
                type="link" htmlType="button"
                onClick={() => setSelectedHotspot(filteredHotspots[index])}
              />
            ]}
          >
            <div className="flex flex-col">
              <span>{t(`TYPES.INVENTORY.PlaceTypeEnum.${hotspot.placeType}`)} - {hotspot.name}</span>
              <span className="text-xs italic">{hotspot.surface}m²</span>
              {hotspot.comments && (
                <span className="text-xs italic">Commentaire : {hotspot.comments}</span>
              )}
            </div>
          </List.Item>
        )}
      />

      {(filteredHotspots.length > hotspotsLimit) && <SeeMoreButton onClick={() => setHotspotLimit(hotspotsLimit + 6)}>Voir plus...</SeeMoreButton>}

      <HotSpotModal
        structure={structure}
        canEdit={canEdit}
        hotspot={selectedHotspot}
        onOk={updateData}
        onDelete={deleteData}
        onCancel={() => setSelectedHotspot(undefined)}
      />
    </div>
  );
}

export default HotspotList
import { AnnualReview } from "@alcome-rep/alcome-types/dist/models";
import { Draft, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { UploadDocumentPayload } from "../../Inventory/state/fileDoc.types";
import factoryCreateSlice from "../../common/state/slice.factory";
import { BaseServiceState } from "../../common/state/types";
import { RootState } from "../../common/store";

const slices = factoryCreateSlice<"AnnualReview", AnnualReview>('AnnualReview', {
  // getAnnualBilanAction(state: Draft<BaseServiceState<AnnualReview, 'current'>>, { payload }: PayloadAction<Draft<{ id: string }>>) { },
  updateAnnualReviewKitAction(state: Draft<BaseServiceState<AnnualReview, 'current'>>, { payload }: PayloadAction<Draft<{ id: string, data: AnnualReview }>>) {
  },
  updateAnnualReviewLatestInfosAction(state: Draft<BaseServiceState<AnnualReview, 'current'>>, { payload }: PayloadAction<Draft<{ id: string, data: AnnualReview }>>) {
  },
  uploadCurrentAnnualReviewDocumentActions(state: Draft<BaseServiceState<AnnualReview, 'current'>>, { payload }: PayloadAction<Draft<UploadDocumentPayload<AnnualReview>>>) {
  },
  uploadAnnualReviewArrayFileActions(state: Draft<BaseServiceState<AnnualReview, 'current'>>, { payload }: PayloadAction<Draft<UploadDocumentPayload<AnnualReview>>>) {
  },
  deleteAnnualReviewDocumentActions(state: Draft<BaseServiceState<AnnualReview, 'current'>>, { payload }: PayloadAction<Draft<{ id: string, propertyName: string }>>) {
  },
  sendOrderActions(state: Draft<BaseServiceState<AnnualReview, 'current'>>, { payload }: PayloadAction<Draft<{ annualReviewId: string }>>) {
  },
  userPortalValidStatusActions(state: Draft<BaseServiceState<AnnualReview, 'current'>>, { payload }: PayloadAction<Draft<{ id: string }>>) {
  },
  acceptStatementActions(state: Draft<BaseServiceState<AnnualReview, 'current'>>, { payload }: PayloadAction<Draft<{ annualReviewId: string }>>) {
  },
  refuseStatementActions(state: Draft<BaseServiceState<AnnualReview, 'current'>>, { payload }: PayloadAction<Draft<{ annualReviewId: string, statementComment?: string }>>) {
  },
})

export const {
  getAction: getAnnualReviewAction,
  updateAction: updateAnnualReviewAction,
  setCurrentAction: setCurrentPortalAnnualReviewAction,
  unsetCurrentAction: unsetCurrentPortalAnnualReviewAction,
  // getAnnualBilanAction,
  updateAnnualReviewKitAction,
  updateAnnualReviewLatestInfosAction,
  uploadCurrentAnnualReviewDocumentActions,
  uploadAnnualReviewArrayFileActions,
  deleteAnnualReviewDocumentActions,
  sendOrderActions,
  userPortalValidStatusActions,
  acceptStatementActions,
  refuseStatementActions,
} = slices.actions;
export default slices.reducer;

export const selectPortalAnnualReviewService = (state: RootState) => state.annualReviewPortalService
export const selectCurrentPortalAnnualReview = createSelector(selectPortalAnnualReviewService, (annualreviewSvc) => {
  return annualreviewSvc.current
})
// export const selectCurrentAnnualReview = createSelector(selectAnnualReviewService, (annualreviewSvc) => annualreviewSvc.currentAnnualReview)
import UserSupplierInfos from "../../../SupplierCompany/components/userSuppliers/UserSupplierInfos/UserSupplierInfos"

const SupplierDashboardPage = () => {

    return (
        <>
            <UserSupplierInfos />
        </>
    )

}

export default SupplierDashboardPage
import { useForm } from "antd/lib/form/Form";
import userAdminApi from "../../../services/user-admin.api";
import AdminCreateAccountForm from "./AdminCreateAccountForm";
import { UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user";

type ComponentProps = {
  userType: UserTypeEnum,
  onClose: () => any
}

const AdminCreateAccount = ({ userType, onClose }: ComponentProps) => {

  const [form] = useForm()

  const createUser = (formData: any) => {

    switch (userType) {

      case UserTypeEnum.admin:
        userAdminApi.adminCreatePortalAccount(formData)
          .then(_ => form.resetFields())
          .then(_ => onClose());
        break;

      case UserTypeEnum.portal:
        userAdminApi.adminCreatePortalAccount(formData)
          .then(_ => form.resetFields())
          .then(_ => onClose());
        break;
      case UserTypeEnum.supplier:
        userAdminApi.adminCreateSupplierAccount(formData)
          .then(_ => form.resetFields())
          .then(_ => onClose())
        break;
      case UserTypeEnum.member:
        userAdminApi.adminCreateMemberAccount(formData)
          .then(_ => form.resetFields())
          .then(_ => onClose())
        break;
      default:
        break;
    }

  }
  return (
    <AdminCreateAccountForm userType={userType} createUser={createUser} form={form} />
  );
};

export default AdminCreateAccount;
import { Models } from "@alcome-rep/alcome-types";
import { UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { refreshTokenAction, selectIsRefreshing, selectUser } from "../../Auth/state/auth.slice";
import LoadingScreen from "../../common/components/LoadingScreen";
import { useAppDispatch, useAppSelector } from "../../common/hooks";
import { ROUTES } from "../../common/router";

const UserMemberGuard = ({ children }: any) => {
  const [hasBeenRefreshed, setHasBeenRefreshed] = useState<boolean>(false)
  const user = useAppSelector(selectUser);
  const isRefreshing = useAppSelector(selectIsRefreshing);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!hasBeenRefreshed && !isRefreshing) {
      dispatch(refreshTokenAction(UserTypeEnum.member))
      setHasBeenRefreshed(true)
    }
  }, [dispatch, isRefreshing, hasBeenRefreshed])

//   useEffect(() => {
//     if (!user || !hasBeenRefreshed)
//       return;
//   }, [user, hasBeenRefreshed])

  if (isRefreshing)
    return <LoadingScreen />
  else if (!isRefreshing && !Models.User.IsUserMember(user))
    return <Navigate to={ROUTES.LOGIN}></Navigate>

  if (children)
    return <>{children}</>

  return <Outlet />
}

export default UserMemberGuard;
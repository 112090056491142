import { AnnualReview } from '@alcome-rep/alcome-types/dist/models';
import { call, put, takeLatest } from 'redux-saga/effects';
import createEntityDucksFactory from '../../common/state/ducks.factory';
import annualReviewPortalApi from '../services/annual-review-portal.api';
import { acceptStatementActions, getAnnualReviewAction, refuseStatementActions, sendOrderActions, setCurrentPortalAnnualReviewAction, unsetCurrentPortalAnnualReviewAction, updateAnnualReviewAction, updateAnnualReviewKitAction, updateAnnualReviewLatestInfosAction, uploadAnnualReviewArrayFileActions, uploadCurrentAnnualReviewDocumentActions, userPortalValidStatusActions } from './annual-review-portal.slice';
import annualReviewApi from '../services/annual-review.api';

/**
 * SAGAS
 */

function* updateAnnualReviewActionKit({ payload }: ReturnType<typeof updateAnnualReviewKitAction>) {
  try {
    const resp: AnnualReview = yield call(annualReviewPortalApi.updateCommunicationKitUsed, { annualReviewId: payload.annualReviewId, data: payload.data });

    yield put(setCurrentPortalAnnualReviewAction(resp));
  } catch {
    yield put(unsetCurrentPortalAnnualReviewAction(null));
  }
}
function* updateAnnualReviewLatestInfos({ payload }: ReturnType<typeof updateAnnualReviewLatestInfosAction>) {
  try {
    const resp: AnnualReview = yield call(annualReviewApi.updateAnnualReviewLatestInfos, { annualReviewId: payload.id, data: payload.data });

    yield put(setCurrentPortalAnnualReviewAction(resp));
  } catch {
    yield put(unsetCurrentPortalAnnualReviewAction(null));
  }
}

function* uploadCurrentAnnualReviewDocument({ payload }: ReturnType<typeof uploadCurrentAnnualReviewDocumentActions>) {
  try {
    const resp: AnnualReview = yield call(annualReviewPortalApi.uploadAnnualReviewFile, payload);
    if (
      payload.propertyName === 'annualReviewFileId' ||
      payload.propertyName === 'revenueOrderFileId'
    ) yield put(setCurrentPortalAnnualReviewAction(resp));
  } catch {
    yield put(unsetCurrentPortalAnnualReviewAction(null));
  }
}

function* uploadAnnualReviewArrayFile({ payload }: ReturnType<typeof uploadAnnualReviewArrayFileActions>) {
  try {
    const resp: AnnualReview = yield call(annualReviewPortalApi.uploadArrayFile, { annualReviewId: payload.annualReviewId, data: payload.data, formData: payload.formData });
    yield put(setCurrentPortalAnnualReviewAction(resp));
  } catch {
    yield put(unsetCurrentPortalAnnualReviewAction(null));
  }
}

function* userValidStatus({ payload }: ReturnType<typeof userPortalValidStatusActions>) {
  try {
    const resp: AnnualReview = yield call(annualReviewPortalApi.userValidateStatus, { annualReviewId: payload.annualReviewId });
    yield put(setCurrentPortalAnnualReviewAction(resp));
  } catch {
    yield put(unsetCurrentPortalAnnualReviewAction(null));
  }
}

function* acceptStatement({ payload }: ReturnType<typeof acceptStatementActions>) {
  try {
    const resp: AnnualReview = yield call(annualReviewPortalApi.userAcceptStatement, { annualReviewId: payload.annualReviewId });
    yield put(setCurrentPortalAnnualReviewAction(resp));
  } catch {
    yield put(unsetCurrentPortalAnnualReviewAction(null));
  }
}

function* refuseStatement({ payload }: ReturnType<typeof refuseStatementActions>) {
  try {
    const resp: AnnualReview = yield call(annualReviewPortalApi.userRefuseStatement, { annualReviewId: payload.annualReviewId, statementComment: payload.statementComment });
    yield put(setCurrentPortalAnnualReviewAction(resp));
  } catch {
    yield put(unsetCurrentPortalAnnualReviewAction(null));
  }

}

function* sendOrder({ payload }: ReturnType<typeof sendOrderActions>) {
  try {
    const resp: AnnualReview = yield call(annualReviewPortalApi.sendOrder, { annualReviewId: payload.annualReviewId });
    yield put(setCurrentPortalAnnualReviewAction(resp));
  } catch {
    yield put(unsetCurrentPortalAnnualReviewAction(payload));
  }
}


// function* deleteAnnualReviewDocument({ payload }: ReturnType<typeof deleteAnnualReviewDocumentActions>) {
//   try {
//     const resp: AnnualReview = yield call(annualReviewPortalApi.deleteAnnualReviewFile, payload);
//     yield put(setCurrentAnnualReviewAction(resp));
//   } catch {
//     yield put(setCurrentAnnualReviewAction(null));
//   }
// }

const actions = createEntityDucksFactory<AnnualReview>({
  ...annualReviewPortalApi,
  get: annualReviewPortalApi.getAnnualReview
}, {
  get: getAnnualReviewAction,
  set: setCurrentPortalAnnualReviewAction,
  update: updateAnnualReviewAction,
})


export function* annualreviewPortalSaga() {
  yield takeLatest(getAnnualReviewAction, actions.get);
  yield takeLatest(updateAnnualReviewAction, actions.update);
  yield takeLatest(updateAnnualReviewKitAction, updateAnnualReviewActionKit);
  yield takeLatest(updateAnnualReviewLatestInfosAction, updateAnnualReviewLatestInfos);
  yield takeLatest(uploadCurrentAnnualReviewDocumentActions, uploadCurrentAnnualReviewDocument);
  yield takeLatest(uploadAnnualReviewArrayFileActions, uploadAnnualReviewArrayFile);
  yield takeLatest(userPortalValidStatusActions, userValidStatus);
  yield takeLatest(acceptStatementActions, acceptStatement);
  yield takeLatest(refuseStatementActions, refuseStatement);
  yield takeLatest(sendOrderActions, sendOrder);
  // yield takeLatest(deleteAnnualReviewDocumentActions, deleteAnnualReviewDocument);
}
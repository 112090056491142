import { Models } from "@alcome-rep/alcome-types";
import { CommuneTypeEnum, InventoryStatusEnum, statementVars } from "@alcome-rep/alcome-types/dist/interfaces";
import { EditOutlined } from "@ant-design/icons";
import { Button, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { TFunction } from "i18next";
import { NavigateFunction } from "react-router-dom";
import ContractStatus from "../../../../Contract/components/admin/ContractData/ContractStatus";
import DateField from "../../../../common/components/Fields/DateField";
import { ROUTES } from "../../../../common/router";
import { getRoute } from "../../../../common/tools/router-tools";
import StructureType from "../../common/StructureType";
import InventoryStatus from "../../../../Inventory/components/admin/InventoryData/InventoryStatus";

export const structureListColumns = (query: any, t: TFunction, navigate: NavigateFunction): ColumnsType<Models.BaseCommune> => [
  {
    // title: 'Actions',
    fixed: 'left',
    width: "40px",
    align: 'center',
    render: (value: any, _, i) => {
      return (
        <Button
          id={`action-button-${i}`}
          type="link"
          icon={<EditOutlined />}
          onClick={(_e) =>
            navigate(getRoute(ROUTES.ADMIN_STRUCTURE_ROUTES.structureDetails, { structureId: value._id }))}
        />
      )
    }
  },
  {
    fixed: 'left',
    title: 'Nom',
    // width: '200px',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    className: 'font-semibold',
  },
  {
    title: 'Insee / Siren',
    // width: '100px',
    fixed: 'left',
    dataIndex: 'siren',
    key: 'siren',
    align: 'center',
  },
  {
    title: 'Inscription',
    // width: '100px',
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    render: (value: any) => <DateField value={value} />
  },
  {
    title: 'Signature',
    // width: '100px',
    dataIndex: ['contract', 'contractDate'],
    key: 'contract.contractDate',
    align: 'center',
    render: (value: any) => <DateField value={value} />
  },
  {
    title: 'Annexes',
    // width: '100px',
    dataIndex: [],
    key: 'daysLeft',
    align: 'center',
    render: (value: any) => {
      if (!value.daysLeft && value.inventory.status >= InventoryStatusEnum.completed) {
        return <Tag color='green'>OK</Tag>
      } else if (value.daysLeft < 0) {
        return <Tag color='red'> délais dépassé</Tag>
      }
      else {
        return value.daysLeft ? <Tag color='orange'>reste {value.daysLeft} jours</Tag> : <Tag color='red'>Contrat en attente</Tag>;
      }
    }
  },
  {
    title: 'Année',
    // width: '80px',
    dataIndex: 'contractYear',
    key: 'contractYear',
    align: 'center',
    render: (value: any) => {
      return value ? value : '-';
    },
  },
  {
    title: 'Population',
    // width: '100px',
    dataIndex: 'population',
    key: 'population',
    align: 'center',
    render: (value: any) => {
      if (value) return (<span>{value}</span>)
      else return (<span>-</span>)
    },
  },
  {
    title: `Barème`,
    // width: '80px',
    key: 'bareme',
    align: 'center',
    render: (value: any) => {
      if (value.communes.length > 1) {
        return <></>
      } else if(value.communes[0] && value.communes[0].type ){
        return <div className=' text-center'>{statementVars.typeZone[value.communes[0].type as CommuneTypeEnum]}</div>
      }else{
        return <></>
      }
    }
  },
  {
    title: 'Type commune',
    // width: '100px',
    align: 'center',
    render: (value: any) => {
      if (value.communes.length > 1) {
        return <></>
      } else if(value.communes[0] && value.communes[0].type ){
        return <Tag color={t('UI.TAG.CommuneTypeEnum.' + value.communes[0].type)} className="uppercase">{value.communes[0].type}</Tag>
      }else{
        return <></>
      }
    }
  },
  {
    title: 'Type structure',
    // width: '100px',
    // dataIndex: 'structureType',
    key: 'structureType',
    align: 'center',
    render: (value: any) => <StructureType structure={value} mode="tag" />
  },
  {
    title: 'Statut annexes',
    // width: '100px',
    fixed: 'right',
    dataIndex: ['inventory', 'status'],
    key: 'inventory.status',
    align: 'center',
    render: (value: any) => <InventoryStatus inventory={{status:value} as any}  />
  },
  {
    title: 'Statut contrat',
    // width: '100px',
    fixed: 'right',
    dataIndex: ['contract', 'status'],
    key: 'contract.status',
    align: 'center',
    render: (value: any) => <ContractStatus contract={{ status: value } as any} translationString="ContractStatusEnumAdmin" />
  },

];
import { PriceStep } from "@alcome-rep/alcome-types/dist/interfaces/supplier";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, InputNumber, Table, TableColumnType } from "antd";
import { useWatch } from "antd/es/form/Form";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { randomNumberId } from "../../../../common/tools/global-tools";

type Props = {
  readOnly?: boolean,
}

const AdminProductPriceSteps = ({ readOnly }: Props) => {
  const addStep = () => {
    let priceSteps = form.getFieldValue('priceSteps') as PriceStep[]
    form.setFieldValue('priceSteps', [...priceSteps, {
      floor: 1,
      ceil: 10,
      price: 100
    }])
  }

  const form = useFormInstance<any>()
  const priceStepsFieldValue = useWatch('priceSteps', form) as PriceStep[];

  const updateSteps = (ceilValue: number, index: number, field: 'ceil' | 'floor') => {
    const steps = [...(form.getFieldValue('priceSteps') as PriceStep[])
      .map(s => ({ ...s }))]
    steps[index][field] = ceilValue;


    form.setFieldValue('priceSteps', steps)
  }

  return <>
    <Table<PriceStep>
      rowKey={() => randomNumberId()}
      dataSource={priceStepsFieldValue}
      locale={{
        emptyText: () => "Aucun tarif"
      }}
      columns={[{
        title: "Palier",
        dataIndex: "ceil",
        render: (q: number, item: PriceStep, index: number) => {
          return < div className="flex space-x-4 items-center" >
            <span>Palier de : </span>
            <InputNumber
              disabled={readOnly}
              value={form.getFieldValue(['priceSteps', index, "floor"])}
              onChange={v => v !== null && updateSteps(v, index, 'floor')}
            />
            <span> à </span>
            <InputNumber
              disabled={readOnly}
              min={form.getFieldValue(['priceSteps', index, "floor"])}
              value={form.getFieldValue(['priceSteps', index, "ceil"])}
              onChange={v => v !== null && updateSteps(v, index, 'ceil')}
            />
            <span> produits</span>
          </div >
        }
      }, {
        dataIndex: 'price',
        title: 'Tarif',
        render: (q: number, item: PriceStep, index: number) => <InputNumber
          disabled={readOnly}
          min={1}
          step={10}
          value={form.getFieldValue(['priceSteps', index, "price"])}
          onChange={v => form.setFieldValue(['priceSteps', index, "price"], v)}
        />
      },
      {
        title: '',
        key: 'action',
        align: 'right',
        width: '50px',
        render: (_v: any, _item: any, index: number) =>
          !readOnly && (
            <Button
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={_ => {
                const steps = form.getFieldValue('priceSteps');
                steps.splice(index, 1);
                form.setFieldValue('priceSteps', [...steps])
              }}
            />
          )
      }] as TableColumnType<PriceStep>[]}
      pagination={false}
      footer={() => !readOnly && <Button type="link" onClick={addStep}>Ajouter un palier</Button>}
    />
  </>
}

export default AdminProductPriceSteps;
import { ReactNode, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { adminGetStructureAction, adminSetCurrentStructureAction, selectCurrentAdminStructure } from '../../../Structure/state/structure-admin.slice';
import LoadingScreen from '../../../common/components/LoadingScreen';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { adminGetAnnualReviewAction, adminSetCurrentAnnualReviewAction, selectAdminCurrentAnnualReview } from '../../state/annual-review-admin.slice';

type Props = { redirectPath?: string, children?: ReactNode };

// const StructureAdminGuard = ({ redirectPath = ROUTE_ADMIN_HOME, children }: Props) => {
const AnnualReviewAdminGuard = ({ redirectPath = '', children }: Props) => {
  const dispatch = useAppDispatch();
  const { annualReviewId } = useParams();

  const structure = useAppSelector(selectCurrentAdminStructure);
  const annualReview = useAppSelector(selectAdminCurrentAnnualReview)

  // Load Annual Review
  useEffect(() => {
    if (!annualReviewId)
      return;

    dispatch(adminSetCurrentAnnualReviewAction(null))
    dispatch(adminGetAnnualReviewAction({ id: annualReviewId }))

    return () => {
      dispatch(adminSetCurrentAnnualReviewAction(null))
    };
  }, [annualReviewId, dispatch])

  // Load Structure
  useEffect(() => {
    if (!annualReview)
      return;

    dispatch(adminSetCurrentStructureAction(null))
    dispatch(adminGetStructureAction({ id: annualReview.structureId }))

    return () => {
      dispatch(adminSetCurrentStructureAction(null))
    };
  }, [annualReview, dispatch])

  if (!structure || !annualReview)
    return <LoadingScreen />

  return children ? (<>{children}</>) : <Outlet></Outlet>
};

export default AnnualReviewAdminGuard
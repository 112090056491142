import { StructureTypesEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NextStepComponent from '../../../../Auth/components/Register/NextStepComponent';
import { selectCurrentStructure } from '../../../../Structure/state/structure-portal.slice';
import LoadingScreen from '../../../../common/components/LoadingScreen';
import { useAppSelector } from '../../../../common/hooks';
import { ROUTES } from '../../../../common/router';
import { getRoute } from '../../../../common/tools/router-tools';
import PortalExecutiveDeliberationFileUpload from '../FileUploads/PortalExecutiveDeliberationUpload';

const ExecutiveDeliberation = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const structure = useAppSelector(selectCurrentStructure)

  const [form] = useForm()

  const nextStepFnc = () => {

    let nextStepLink = "";
    switch (structure?.structureType) {
      case StructureTypesEnum.commune:
        nextStepLink = getRoute(ROUTES.PORTAL_REGISTER_CONTRACT_ROUTES.STEPS_COMMUNE.conflict)
        break;
      case StructureTypesEnum.groupement_communes:
        nextStepLink = getRoute(ROUTES.PORTAL_REGISTER_CONTRACT_ROUTES.STEPS_GRP_COMMUNES.conflict)
        break;
      default:
        break;
    }
    return navigate(nextStepLink)
  }

  if (!structure)
    return <LoadingScreen />

  return (
    <>

      <h2 className='mb-4'>{t('UI.REGISTER_CONTRACT.EXECUTIVE_DELIBERATION.title')}</h2>

      <div className='flex flex-col justify-center space-y-4 items-center'>
        <p className='text-center m-0'>{t('UI.REGISTER_CONTRACT.EXECUTIVE_DELIBERATION.explanations.' + structure?.structureType)}</p>
        <a href="/communes-groupements-deliberation-type.pdf" target="_blank">
          <img src="/download-icon.png" className='w-20' alt='icone téléchargement' />
        </a>
      </div>
      <Form
        name="basic"
        layout='vertical'
        onFinish={nextStepFnc}
        form={form}
        id="pa"
      >
        <PortalExecutiveDeliberationFileUpload isInForm={true} />

        <Form.Item>
          <NextStepComponent />
        </Form.Item>
      </Form>
    </>
  )
}

export default ExecutiveDeliberation;
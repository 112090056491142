import { RouteObject } from "react-router-dom";
import AdminAnnualReviewPage from "../components/admin/AnnualReviewDetails/AdminAnnualReviewPage";
import AnnualReviewListPage from "../components/admin/AnnualReviewList/AdminAnnualReviewListPage";
import AnnualReviewCommunicationStep from "../components/common/AnnualReviewCommunication/annualReviewCommunicationStep";
import AnnualReviewFormStep from "../components/common/AnnualReviewForm/annualReviewFormStep";
import AnnualReviewMapStep from "../components/common/AnnualReviewMap/annualReviewMapStep";
import RevenueOrderStep from "../components/common/AnnualReviewOrder/annualReviewRevenueOrderStep";
import AnnualReviewRefuseHistory from "../components/common/AnnualReviewRefuseHistory/AnnualReviewRefuseHistory";
import AnnualReviewRevisionStep from "../components/common/AnnualReviewRevision/annualReviewRevisionStep";
import StatementPaidStep from "../components/common/AnnualReviewStatement/annualReviewStatementPaid";
import StatementStep from "../components/common/AnnualReviewStatement/annualReviewStatementStep";
import AnnualReviewAdminGuard from "../components/guards/annualReviewAdminGuard";


const routePrefix = 'admin'
const routeDomain = 'annualReview'
const routeParam = 'annualReviewId'

// const steps = {
//   review: 'review',
//   statement: 'statement',
//   communication: 'communication',
//   map: 'map',
//   order: 'order',
//   payment: 'payment',
// }

export const adminAnnualReviewRouteNames = {
  annualReviewList: { path: `${routePrefix}/${routeDomain}`, title: `ROUTES.${routeDomain}.list` },
  annualReviewDetails: { path: `${routePrefix}/${routeDomain}/:${routeParam}`, title: `ROUTES.${routeDomain}.actions` },
  StepMap: { path: `${routePrefix}/${routeDomain}/:${routeParam}/map`, title: `ROUTES.${routeDomain}.map` },
  StepCommunication: { path: `${routePrefix}/${routeDomain}/:${routeParam}/communication`, title: `ROUTES.${routeDomain}.communication` },
  StepReview: { path: `${routePrefix}/${routeDomain}/:${routeParam}/review`, title: `ROUTES.${routeDomain}.review` },
  StepStatement: { path: `${routePrefix}/${routeDomain}/:${routeParam}/statement`, title: `ROUTES.${routeDomain}.statement` },
  StepOrder: { path: `${routePrefix}/${routeDomain}/:${routeParam}/order`, title: `ROUTES.${routeDomain}.order` },
  StepPayment: { path: `${routePrefix}/${routeDomain}/:${routeParam}/payment`, title: `ROUTES.${routeDomain}.payment` },
  RefuseHistory: { path: `${routePrefix}/${routeDomain}/:${routeParam}/refuseHistory`, title: `ROUTES.${routeDomain}.refuseHistory` },
}

export const adminAnnualReviewRoutes: RouteObject[] = [
  {
    path: adminAnnualReviewRouteNames.annualReviewList.path.replace(`${routePrefix}/`, ''),
    element: <AnnualReviewListPage />,
  },
  {
    path: adminAnnualReviewRouteNames.annualReviewDetails.path.replace(`${routePrefix}/`, ''),
    element: <AnnualReviewAdminGuard><AdminAnnualReviewPage /></AnnualReviewAdminGuard>,
    children: [
      {
        path: '',
        element: <AnnualReviewFormStep />
      },
      {
        path: 'review',
        element: <AnnualReviewRevisionStep isAdmin={true} />
      },
      {
        path: 'statement',
        element: <StatementStep isAdmin={true} />
      },
      {
        path: 'communication',
        element: <AnnualReviewCommunicationStep isAdmin={true} />
      },
      {
        path: 'map',
        element: <AnnualReviewMapStep isAdmin={true} />
      },
      {
        path: 'order',
        element: <RevenueOrderStep isAdmin={true} />
      },
      {
        path: 'payment',
        element: <StatementPaidStep isAdmin={true} />
      },
      {
        path: 'refuseHistory',
        element: <AnnualReviewRefuseHistory isAdmin={true} />
      }
    ]
  }
]

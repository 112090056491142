import { ILibraryDoc, LibraryDocTypesEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { Tag } from "antd";
import { ColumnsType } from "antd/es/table";

export const libraryDocListColumns = (): ColumnsType<ILibraryDoc> => [
    {
        title: 'Nom',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        className: 'font-semibold',
    },
    {
        title: 'Type de document',
        dataIndex: 'docType',
        key: 'docType',
        align: 'center',
        render:(value:LibraryDocTypesEnum) => {
            switch (value) {
                case LibraryDocTypesEnum.kit:
                    return <Tag color="purple">Kit de communication</Tag>
                case LibraryDocTypesEnum.info:
                    return <Tag color="blue">Informatif</Tag>
                case LibraryDocTypesEnum.methodological_sheet:
                    return <Tag color="orange">Fiche méthodologique</Tag>
                // case value.docType === LibraryDocTypesEnum.technical_documentation:
                //     return <Tag></Tag>
                default:
                    break;
            }
        }
    },
    {
        title: 'Timestamp',
        dataIndex: 'timestamp',
        key: 'timestamp',
        align: 'center',
    },

];
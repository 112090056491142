import { AccountingTypeEnum, IAccountingExport } from "@alcome-rep/alcome-types/dist/interfaces";
import { ISupplierFileDocument } from "@alcome-rep/alcome-types/dist/interfaces/supplier/supplierFileDocument";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Drawer, Modal } from "antd";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { useEffect, useState } from "react";
import { numericFormatter } from "react-number-format";
import adminSupplierOrderApi from "../../../SupplierOrder/services/admin-supplierOrder.api";
import LoadingScreen from "../../../common/components/LoadingScreen";
import Section from "../../../common/components/Panels/Section";
import HeaderSectionExport from "../../HeaderSectionExport/HeaderSectionExport";
import accountingExportAdminApi from "../../services/admin-accountingExportApi";
import InfosComponent from "../InfosComponent/InfosComponent";

export type findNotExportedInvoicesType = {

}

const InvoicesExport = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [selectedExport, setSelectedExport] = useState<IAccountingExport | undefined>();

    const [refresh, setRefresh] = useState<Date>(new Date())
    const [period, setPeriod] = useState<string>()
    const [listAcExports, setListAcExports] = useState<IAccountingExport[]>([]);
    const [invoices, setInvoices] = useState<ISupplierFileDocument[]>([]);
    const [invoicesSelected, setInvoicesSelected] = useState<any>([]);

    useEffect(() => {
        setLoading(true);
        accountingExportAdminApi.accountingList({ accountingType: AccountingTypeEnum.invoices }).then(res => {
            setLoading(false);
            setListAcExports(res.rows);
        });
    }, [setListAcExports, setLoading, refresh]);

    const fetchInvoicesToExport = async () => {
        if (!period) {
            await Modal.warning({
                title: "Données manquantes",
                content: `Vous devez sélectionner une période comptable à extraire.`
            });

            return;
        }

        const [year, month] = period?.split('-').map(e => +e as number)
        setLoading(true);
        const res = await adminSupplierOrderApi.findInvoicesToExport(year, month)
        setInvoices(res);
        setInvoicesSelected(res.map(s => s._id));
        setLoading(false);
    }

    const generateExport = async () => {
        if (invoicesSelected?.length === 0) {
            await Modal.warning({
                title: "Données manquantes",
                content: `Vous devez sélectioner au moins un titre de recette pour réaliser l'export.`
            });

            return;
        }
        if (!period)
            return;

        await Modal.confirm({
            cancelText: 'Annuler',
            closable: true,
            okCancel: true,
            title: "Confirmation",
            content: `Etes-vous sûr de vouloir continuer l'extraction ? Cette opération est irréversible.`,
            async onOk() {
                let dataExportedResult = await accountingExportAdminApi.createAccountingExport({ period: period.replace('-', ""), listId: invoicesSelected, accountingType: AccountingTypeEnum.invoices })

                setInvoices([]);
                setInvoicesSelected([]);

                if (dataExportedResult.accountingResume.errors.length) {
                    await Modal.warning({
                        title: "Fichier incomplet",
                        content: `Nous n'avons pas pu exporter toutes les factures. Veuillez vérifier le fichier.`
                    });
                }
                else {
                    await Modal.success({
                        title: "Exporté",
                        content: `L'export est maintenant terminé. Vous pouvez télécharger le fichier.`
                    });
                }
                setRefresh(new Date());
            }
        });
    }

    const downloadAccountingFile = (filePath: string) => {
        return accountingExportAdminApi.downloadAccountingExportFile(filePath)
    }

    const toggleSelection = (invoiceId: string) => {
        const newSelection = [...invoicesSelected];
        const foundIndex = newSelection.indexOf(invoiceId);
        if (foundIndex >= 0)
            newSelection.splice(foundIndex, 1)
        else
            newSelection.push(invoiceId)
        setInvoicesSelected(newSelection)
    }

    if (loading) {
        return <LoadingScreen />
    }

    return (
        <>
            <HeaderSectionExport entity="invoices" fetchStatementsToExport={fetchInvoicesToExport} setPeriod={setPeriod} />

            {invoices.length > 0 &&
                <Section title={`Liste des factures fournisseur trouvés pour la période de ${(format(new Date(`${period}-01`), 'MMMM yyyy', { locale: fr })).toLocaleUpperCase()}`}>
                    <table style={{ marginTop: "20px" }} className="arTable">
                        <thead>
                            <tr>
                                <th>Exporter</th>
                                <th>Fournisseur</th>
                                <th>Année</th>
                                <th>Date de la facture</th>
                                <th>Date de comptabilisation</th>
                                <th>Montant</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                period && invoices.map((item: ISupplierFileDocument) => {
                                    const invoiceYear = new Date(item.fileMetadata?.invoiceDate as Date).getFullYear();
                                    const invoicePeriod = new Date(item.fileMetadata?.invoiceDate!).toISOString().match(/\d{4}-\d{2}/);
                                    const comptabilisationDate = invoicePeriod![0] === period ? item.fileMetadata?.invoiceDate! : new Date(+period.slice(0, 4), +period.slice(5, 7) - 1, 1)
                                    
                                    return (
                                        <tr key={item._id}>
                                            <td><input type="checkbox" name="invoicesSelected" defaultChecked={true} onChange={_ => toggleSelection(item._id as string)} /></td>
                                            {/* <td>{item.communes[0].name}</td> */}
                                            <td>{(item as any).supplierCompany.name}</td>
                                            <td>{invoiceYear}</td>
                                            <td>{format(new Date(item.fileMetadata?.invoiceDate!), 'dd/MM/yyyy', { locale: fr })}</td>
                                            <td>{format(new Date(comptabilisationDate), 'dd/MM/yyyy', { locale: fr })}</td>
                                            <td>{numericFormatter((item.fileMetadata?.totalPrice!).toFixed(2), { thousandSeparator: ' ', decimalScale: 2 })} &euro;</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                    <div style={{ marginTop: "20px" }}><Button className="btn rounded-lg items-center" onClick={() => generateExport()}>Générer le fichier</Button></div>
                </Section>
            }

            <Section title="Historique des extractions">
                {/* <h4 className="section-title mb-4">Historique des extractions</h4> */}
                <table style={{ marginTop: "20px" }} className="arTable">
                    <thead>
                        <tr>
                            <th>Date d'extraction</th>
                            <th>Période</th>
                            <th>Nb commandes</th>
                            <th>Nb erreurs</th>
                            <th>Montant</th>
                            <th>#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            listAcExports.map((item: IAccountingExport) => {
                                return (
                                    <tr key={item.id}>
                                        <td>{format(new Date((item as any).createdAt), 'dd/MM/yyyy', { locale: fr })}</td>
                                        <td>{item.period}</td>
                                        <td>{item.accountingResume?.supplierOrders?.length}</td>
                                        <td>{item.accountingResume?.errors?.length} </td>
                                        <td>{numericFormatter((item.accountingResume.totalAmount).toFixed(2), { thousandSeparator: ' ', decimalScale: 2 })} &euro;</td>
                                        <td>
                                            <Button icon={<EyeOutlined />} className="btn-link" onClick={() => setSelectedExport(item)}></Button>
                                            <Button icon={<DownloadOutlined />} className="btn-link" onClick={() => downloadAccountingFile(item.exportedFilePath as string)}></Button>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </Section>

            <Drawer
                width={'40%'}
                title="Résumé"
                onClose={_ => setSelectedExport(undefined)}
                open={selectedExport !== undefined}
            >
                {selectedExport && <>
                    <h5 className="my-2">Informations</h5>
                    <dl className="grid grid-cols-2">
                        <dt>Créé le</dt>
                        <dd>{format(new Date((selectedExport as any).createdAt), 'dd/MM/yyyy', { locale: fr })}</dd>
                        <dt>Période</dt>
                        <dd>{selectedExport.period}</dd>
                        <dt>Montant</dt>
                        <dd>{numericFormatter((selectedExport.accountingResume.totalAmount).toFixed(2), { thousandSeparator: ' ', decimalScale: 2 })} &euro;</dd>
                    </dl>

                    <h5 className="my-2">Factures exportés</h5>
                    {selectedExport.accountingResume.supplierOrders && selectedExport.accountingResume.supplierOrders.length ?
                        <InfosComponent infos={selectedExport.accountingResume.supplierOrders} accountingType={AccountingTypeEnum.invoices} />
                        : <p>Aucune facture exporté</p>}

                    <h5 className="my-2">Factures en erreur (non exportés)</h5>
                    {selectedExport.accountingResume.errors.length ?
                        <InfosComponent infos={selectedExport.accountingResume.errors} accountingType={AccountingTypeEnum.invoices} />
                        : <p>Aucune facture en erreur</p>}

                </>}
            </Drawer>
        </>
    )
}

export default InvoicesExport
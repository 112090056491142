import { numericFormatter } from "react-number-format";

type PropsType = {
  amount?: number,
  precision?: number,
  hideSuffix?: boolean
}

const AmountValue = ({ amount, hideSuffix, precision = 0 }: PropsType) => {
  return <span>{amount !== undefined ? numericFormatter((amount).toFixed(2), { thousandSeparator: ' ', decimalScale: precision }) + (hideSuffix ? '' : ' €') : ' - '}</span>
}

export default AmountValue;
import { RouteObject } from "react-router-dom";
import WelcomeLayout from "../../common/layouts/WelcomeLayout/WelcomeLayout";
import CommuneConflictRecap from "../components/portal/ContractCreation/CommuneConflictRecap";
import CommuneConflictStarter from "../components/portal/ContractCreation/CommuneConflictStarter";
import CommuneSignatory from "../components/portal/ContractCreation/CommuneSignatory";
import ContractConfirmStep from "../components/portal/ContractCreation/ContractConfirm";
import ContractStartStep from "../components/portal/ContractCreation/ContractStarterStep";
import ExecutiveDeliberation from "../components/portal/ContractCreation/ExecutiveDeliberation";
import ContractPage from "../components/common/ContractPage/ContractPage";
import ContractLayout from "../layouts/ContractLayout";
import AmendmentPage from "../../Amendment/components/common/AmendmentPage";
import InventoryPage from "../../Inventory/components/common/InventoryPage";
import PreventionForm from "../../Inventory/components/common/PreventionForm";
import SalubriteForm from "../../Inventory/components/common/SalubriteForm";
import InventoryAnnexeStep from "../../Inventory/components/common/InventoryAnnexeStep";
import InventoryMapStep from "../../Inventory/components/common/inventoryMapStep";
import RegisterAllCommunes from "../components/portal/ContractCreation/RegisterAllCommunes";
import GrpCommunesConflictStarter from "../components/portal/ContractCreation/GrpCommunesConflictStarter";

const routePrefix = 'portal'
const starterDomain = 'start-contract'
const communeSubDomain = 'commune'
const grpCommuneSubDomain = 'grp-communes'
const routeDomain = 'contract'

export const portalRegisterContractRouteNames = {
  start: { path: `${routePrefix}/${starterDomain}/starter` },
  confirm_infos: { path: `${routePrefix}/${starterDomain}/confirm` },
  STEPS_COMMUNE: {
    signatory: { path: `${routePrefix}/${starterDomain}/${communeSubDomain}/signatory` },
    deliberation: { path: `${routePrefix}/${starterDomain}/${communeSubDomain}/executiveDeliberation` },
    conflict: { path: `${routePrefix}/${starterDomain}/${communeSubDomain}/conflict/starter` },
    conflict_recap: { path: `${routePrefix}/${starterDomain}/${communeSubDomain}/conflict/recap` },
  },
  STEPS_GRP_COMMUNES: {
    communes: { path: `${routePrefix}/${starterDomain}/${grpCommuneSubDomain}/communes` },
    signatory: { path: `${routePrefix}/${starterDomain}/${grpCommuneSubDomain}/signatory` },
    deliberation: { path: `${routePrefix}/${starterDomain}/${grpCommuneSubDomain}/executiveDeliberation` },
    conflict: { path: `${routePrefix}/${starterDomain}/${grpCommuneSubDomain}/conflict/starter` },
    conflict_recap: { path: `${routePrefix}/${starterDomain}/${communeSubDomain}/conflict/recap` },
  },
}


export const PortalRegisterContractRoute: RouteObject[] = [
  {
    path: "",
    element: <WelcomeLayout></WelcomeLayout>,
    children: [
      { path: 'starter', element: <ContractStartStep /> },
      { path: 'confirm', element: <ContractConfirmStep /> },
      {
        path: "commune",
        children: [
          { path: 'signatory', element: <CommuneSignatory /> },
          { path: 'executiveDeliberation', element: <ExecutiveDeliberation /> },
          { path: 'conflict/starter', element: <CommuneConflictStarter /> },
          { path: 'conflict/recap', element: <CommuneConflictRecap /> },
        ]
      },
      {
        path: "grp-communes",
        children: [
          { path: 'communes', element: <RegisterAllCommunes /> },
          { path: 'signatory', element: <CommuneSignatory /> },
          { path: 'executiveDeliberation', element: <ExecutiveDeliberation /> },
          { path: 'conflict/starter', element: <GrpCommunesConflictStarter /> },
          { path: 'conflict/recap', element: <CommuneConflictRecap /> },

        ]
      },
      // { path: 'member/administration-details', element: <></> }, //<AdministrationDetailsStep /> },
      // { path: 'member/signatory', element: <></> }, // <MemberSignatory /> },
      // { path: 'member/situation', element: <></> }, // <MemberSituationStep /> },
      // { path: 'member/closing-annual-accounts', element: <></> }, // <MemberClosingAnnualAccounts /> },
      // { path: 'member/conflict/recap', element: <></> }, // <MemberRecap /> },
    ]
  }
]

export const portalContractRouteNames = {
  dashboard: { path: `${routePrefix}/home`, title: "ROUTES.dashboard" },
  contractDetails: { path: `${routePrefix}/${routeDomain}`, title: "ROUTES.contract.details" },
  inventoryDetails: { path: `${routePrefix}/${routeDomain}/inventory`, title: "ROUTES.inventory.details" },
  amendmentsDetails: { path: `${routePrefix}/${routeDomain}/amendments`, title: "ROUTES.amendments.list" },
  INVENTORY: {
    PREVENTION: { path: `${routePrefix}/${routeDomain}/inventory`, title: "ROUTES.inventory.prevention" },
    SALUBRITE: { path: `${routePrefix}/${routeDomain}/inventory/salubrite`, title: "ROUTES.inventory.salubrite" },
    MAP: { path: `${routePrefix}/${routeDomain}/inventory/map`, title: "ROUTES.inventory.map" },
    FILES: { path: `${routePrefix}/${routeDomain}/inventory/annexes`, title: "ROUTES.inventory.files" },
    // VOLUMES: 'inventory/volumes',
  }
}

export const PortalContractRoutes: RouteObject[] = [
  {
    path: "",
    element: <ContractLayout />,
    children: [
      {
        path: portalContractRouteNames.contractDetails.path.replace(`${routePrefix}/`, ''),
        element: <ContractPage />
      },
      {
        path: portalContractRouteNames.inventoryDetails.path.replace(`${routePrefix}/`, ''),
        element: <InventoryPage />,
        children: [
          {
            path: '',
            element: <PreventionForm />
          },
          {
            path: 'salubrite',
            element: <SalubriteForm />
          },
          {
            path: 'map',
            element: <InventoryMapStep />
          },
          {
            path: 'annexes',
            element: <InventoryAnnexeStep />
          }
        ]
      },
      {
        path: portalContractRouteNames.amendmentsDetails.path.replace(`${routePrefix}/`, ''),
        element: <AmendmentPage />
      }
    ]
  },
]


import { ISupportOrders, SupportOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { Loader } from "@googlemaps/js-api-loader";
import { Button, Form, InputNumber, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../../../common/components/LoadingScreen";
import Section from "../../../../common/components/Panels/Section";
import Info from "../../../../common/components/WarningUser/Info";
import supportOrderAdminApi from "../../../services/admin-supportOrder.api";
import supportOrderApi from "../../../services/supportOrder.api";
import SupportOrderHotspotSelection from "../../common/SupportOrderHotSpotSelection/SupportOrderHotspotSelection";

const AdminSupportOrderEdit = () => {

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLEMAP_KEY || '',
      libraries: ["drawing", "places"]
    });
    loader.libraries.forEach(l => loader.importLibrary(l))
    // loader.load()
  }, [])


  const [form] = useForm();

  const { supportOrderId }: any = useParams();

  const [supportOrder, setSupportOrder] = useState<ISupportOrders | undefined>();

  useEffect(() => {
    if (!supportOrderId)
      setSupportOrder({} as ISupportOrders)
    else
      supportOrderApi.getSupportOrder(supportOrderId)
        .then(res => {
          setSupportOrder(res)
        })
  }, [supportOrderId])



  const setSupportOrderStatus = async (status: SupportOrderStatusEnum) => {
    const updatedSupportOrder = await supportOrderAdminApi.updateSupportOrderSetCompleted(supportOrder!.id as string, status)
    if (updatedSupportOrder) {
      setSupportOrder(updatedSupportOrder)
      message.success('Mise à jour de la demande de soutien effectuée')
    }
  }

  if (!supportOrder)
    return <LoadingScreen />

  return (
    <div>

      {supportOrder.status < SupportOrderStatusEnum.support_order_completed && (
        <Info type="warning" body={
          <>
            <p>
              La demande de soutien a été refusée pour le motif ci-dessous :<br />
            </p>
            <p className="italic mb-0 font-semibold">{supportOrder.supportOrderComment}</p>
          </>
        } />
      )}



      <Form
        form={form}
        component={false}
        initialValues={supportOrder}
      >
        <Section title="Dispositifs de collecte - Cendrier de rue">
          <SupportOrderHotspotSelection supportOrder={supportOrder} />
        </Section>

        <Section title="Nombre de dispositifs mégot associés aux corbeilles">
          <Form.Item name="extinguisherCount">
            <InputNumber readOnly ></InputNumber>
          </Form.Item>
        </Section>
      </Form>
      {supportOrder.status <= SupportOrderStatusEnum.support_order_completed && (
        <div>
          <Button type="primary" htmlType="button" className="btn mt-5" onClick={() => setSupportOrderStatus(SupportOrderStatusEnum.support_order_declarations_pending)}>
            Accepter la demande de soutien
          </Button> 
          <Button
            type="link"
            htmlType="button"
            className="text-alc-danger"
            onClick={() => setSupportOrderStatus(SupportOrderStatusEnum.support_order_refused)}>
            Refuser la demande de soutien
          </Button>
        </div>
      )}

    </div >


  )
}

export default AdminSupportOrderEdit
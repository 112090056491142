import { UserTypeEnum } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { IdType, TokenType } from "../../common/store/types";

/* ======== CONSTANTES ======== */

/* ======== TYPES ======== */

export type AuthStateType<K> = {
  token: TokenType,
  user: K
}

export const AUTH_SERVICE = "authService";
// eslint-disable-next-line
export type AUTH_SERVICE = typeof AUTH_SERVICE; // Typescript line

export const REGISTER_SERVICE = "registerService";
// eslint-disable-next-line
export type REGISTER_SERVICE = typeof REGISTER_SERVICE; // Typescript line

/* ======== PAYLOADS ======== */

export type SignUpPayload = {
  email: string;
  password: string;
};

export type LoginPayload = {
  email: string;
  password: string;
  userType: UserTypeEnum;
};

export type LoginAsPayload = {
  userId: string;
  userType: UserTypeEnum;
};

export type RefreshTokenPayload = {
  token: string;
};

export type ActivateAccountPayload = {
  userId: IdType;
  token: TokenType;
};

export type ForgottenPasswordPayload = {
  email: string;
};

export type ResetPasswordPayload = {
  email: string;
  password: string;
  token: TokenType;
};



import { ICrmFileDocument } from "@alcome-rep/alcome-types/dist/interfaces/crm";
import ApiService from "../../common/services/apiService";
import apiClient from "../../common/services/apiClient";

const CRM_FILEDOCUMENT_PATH_API = 'crmfileDocument';

class CrmFileDocumentApi extends ApiService<ICrmFileDocument, 'prospectFile'> {
  constructor() {
    super(CRM_FILEDOCUMENT_PATH_API)
  }

  findCrmDocs = ({ query = {}, page = 1, pageSize = 10 }: { query?: any, page?: number, pageSize?: number }) => {
    return this.find({ query, page, pageSize });
  }

  getCrmDoc = (id: string) => {
    return this.get(id)
  }

  uploadCrmDoc = async ({ propertyName, file, entityId, actionId }: { propertyName: 'prospectFile', file: File, entityId: string, actionId?: string }) => {
    const query = actionId ? 'action=' + actionId : ''
    return this.uploadFileDoc(propertyName, file, entityId, query)
  }

  downloadCrmDoc = (id: string) => apiClient({
    url: `${CRM_FILEDOCUMENT_PATH_API}/download/${id}`,
    method: 'get',
    responseType: 'blob'
  }).then(r => r.data)

  // downloadCrmDoc = (id: string, documentName?: string) => {
  //   return this.downloadFileDoc('prospectFile', id, documentName)
  // }


  deleteCrmDoc = (fileId: string) => this.delete(fileId)
}

const crmFileDocApi = new CrmFileDocumentApi();

export default crmFileDocApi;
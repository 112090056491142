import { AcquisitionOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces"
import { User } from "@alcome-rep/alcome-types/dist/models"
import { Loader } from "@googlemaps/js-api-loader"
import { Alert } from "antd"
import { useEffect } from "react"
import { Link, Outlet, useParams } from "react-router-dom"
import { selectUser } from "../../Auth/state/auth.slice"
import SupportCounter from "../../SupportOrder/components/common/SupportCounter/SupportCounter"
import LoadingScreen from "../../common/components/LoadingScreen"
import NavTabs from "../../common/components/Panels/NavTabs"
import PageCanvas from "../../common/components/Panels/PageCanvas"
import { useAppDispatch, useAppSelector } from "../../common/hooks"
import { ROUTES } from "../../common/router"
import { getRoute } from "../../common/tools/router-tools"
import { getAdminAcquisitionOrderAction, selectCurrentAdminAcquisitionOrder, setCurrentAdminAcquisitionOrderAction } from "../state/acquisitionOrder-admin.slice"
import { getPortalAcquisitionOrderAction, selectCurrentPortalAcquisitionOrder, setCurrentPortalAcquisitionOrderAction } from "../state/acquisitionOrder-portal.slice"
import Info from "../../common/components/WarningUser/Info"
import { QuestionCircleOutlined } from "@ant-design/icons"

const AcquisitionOrderLayout = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const isAdmin = User.IsUserAdmin(user)
  const acquisitionOrder = useAppSelector(User.IsUserAdmin(user) ? selectCurrentAdminAcquisitionOrder : selectCurrentPortalAcquisitionOrder)
  const { acquisitionOrderId } = useParams()

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLEMAP_KEY || '',
      libraries: ["drawing", "places"]
    });
    loader.libraries.forEach(l => loader.importLibrary(l))
    // loader.load()
  }, [])

  useEffect(() => {
    if (acquisitionOrderId && acquisitionOrderId !== ':acquisitionOrderId')
      if (User.IsUserAdmin(user)) {
        dispatch(getAdminAcquisitionOrderAction({ id: acquisitionOrderId }))
      }
      else {
        dispatch(getPortalAcquisitionOrderAction({ id: acquisitionOrderId }))
      }
    else {
      dispatch(setCurrentPortalAcquisitionOrderAction({}))
    }
    return function () {
      if (User.IsUserAdmin(user)) {
        dispatch(setCurrentAdminAcquisitionOrderAction({}))
      } else
        dispatch(setCurrentPortalAcquisitionOrderAction({}))
    }
  }, [acquisitionOrderId, dispatch, user])

  if (!acquisitionOrder)
    return <LoadingScreen />
  return (
    <>
      <PageCanvas
        title={<div>
          <div>{acquisitionOrder?.acquisitionOrderNumber ? `Demande ${acquisitionOrder.acquisitionOrderNumber}` : "Nouvelle demande de dispositifs"}</div>
          {User.IsUserAdmin(user) && <Link className="text-sm" to={getRoute(ROUTES.ADMIN_STRUCTURE_ROUTES.structureDetails, { structureId: acquisitionOrder.structureId })}>{acquisitionOrder?.structure?.name}</Link>}
        </div>
        }
        breadCrumbs={
          isAdmin ?
            [
              ROUTES.ADMIN_USER_ROUTES.home,
              ROUTES.ADMIN_ACQUISITION_ORDER_ROUTES.acquisitionOrderList,
              ROUTES.ADMIN_ACQUISITION_ORDER_ROUTES.acquisitionOrderDetails,
            ] :
            [
              ROUTES.PORTAL_USER_ROUTES.home,
              ROUTES.PORTAL_ACQUISITION_ORDER_ROUTES.acquisitionOrderList,
              ROUTES.PORTAL_ACQUISITION_ORDER_ROUTES.acquisitionOrderDetails,
            ]}
      >
        {User.IsUserPortal(user) && <>
          {acquisitionOrder.id && acquisitionOrder?.status === AcquisitionOrderStatusEnum.acquisition_order_pending &&
            <Alert banner={true} type="warning" message={<span>Votre commande est en cours de traitement par alcome. Le suivi est consultable sur l'onglet <b>Résumé</b></span>} />
          }
          {acquisitionOrder?.status === AcquisitionOrderStatusEnum.acquisition_order_refused &&
            <Alert banner={true} type="error" message={<span>Votre commande a été refusée. Nous vous invitons à en effectuer une nouvelle.</span>} />
          }
        </>}
        {User.IsUserAdmin(user) && <>
          {acquisitionOrder?.status === AcquisitionOrderStatusEnum.acquisition_order_pending &&
            <Alert banner={true} type="warning" message={<span>Les éléments de la commande sont en attente de validation de la part de Alcome.</span>} />
          }
          {acquisitionOrder?.status === AcquisitionOrderStatusEnum.acquisition_order_refused &&
            <Alert banner={true} type="error" message={<span>La commande a été refusée.</span>} />
          }
          {acquisitionOrder?.status === AcquisitionOrderStatusEnum.acquisition_order_completed && acquisitionOrder.items.find(item => !item.isSupport) &&
            < Alert banner={true} type="warning" message={<span>La commande est en attente des fournisseurs. <Link to={getRoute(ROUTES.ADMIN_SUPPLIER_ORDER_ROUTES.supplierOrderList, {}, { supplierOrderNumber: '/' + acquisitionOrder.acquisitionOrderNumber.replace(/^C-/, 'CSUP-') + '/' })}>Voir les commandes fournisseurs</Link></span>} />
          }
        </>}

        <SupportCounter structureId={acquisitionOrder.structureId} />

          {acquisitionOrder?.acquisitionOrderNumber === undefined && (
            <Info icon={<QuestionCircleOutlined />} title="Information sur les demandes d'aquisition" body={(
              <>
                <p className="mb-2">Pour plus d’informations veuillez consulter le guide technique et notre catalogue de dispositifs de rue:</p>
                <ul className="my-0 pl-5 list-disc">
                  <li><a href="/acquisition-orders/guide-technique.pdf" download="Guide technique.pdf">Télécharger le guide technique</a></li>
                  <li><a href="/acquisition-orders/catalogue-dispositif-rue-alcome.pdf" download="Catalogue dispositif de rue Alcome.pdf">Télécharger le catalogue de dispositifs de rue</a></li>
                </ul>
              </>
            )} type="warning" />
          )}

        <NavTabs tabRoutes={User.IsUserAdmin(user)
          ? [
            { ...ROUTES.ADMIN_ACQUISITION_ORDER_ROUTES.acquisitionOrderAshtrays, params: { acquisitionOrderId: acquisitionOrderId } },
            { ...ROUTES.ADMIN_ACQUISITION_ORDER_ROUTES.acquisitionOrderExtinguishers, params: { acquisitionOrderId: acquisitionOrderId } },
            { ...ROUTES.ADMIN_ACQUISITION_ORDER_ROUTES.acquisitionOrderDetails, params: { acquisitionOrderId: acquisitionOrderId } },
            { ...ROUTES.ADMIN_ACQUISITION_ORDER_ROUTES.acquisitionOrderDeclaration, params: { acquisitionOrderId: acquisitionOrderId } }
          ] : [
            { ...ROUTES.PORTAL_ACQUISITION_ORDER_ROUTES.acquisitionOrderAshtrays, params: { acquisitionOrderId: acquisitionOrderId } },
            { ...ROUTES.PORTAL_ACQUISITION_ORDER_ROUTES.acquisitionOrderExtinguishers, params: { acquisitionOrderId: acquisitionOrderId } },
            { ...ROUTES.PORTAL_ACQUISITION_ORDER_ROUTES.acquisitionOrderDetails, params: { acquisitionOrderId: acquisitionOrderId } },
            ...acquisitionOrder.status >= AcquisitionOrderStatusEnum.acquisition_order_completed ? [{ ...ROUTES.PORTAL_ACQUISITION_ORDER_ROUTES.acquisitionOrderDeclaration, params: { acquisitionOrderId: acquisitionOrderId } }] : []
          ]} />

        <Outlet />
      </PageCanvas >
    </>
  )
}

export default AcquisitionOrderLayout
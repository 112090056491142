import { AnnualReviewStatusEnum, IAnnualReview } from '@alcome-rep/alcome-types/dist/interfaces';
import { EditOutlined } from '@ant-design/icons';
import { Button, Table, TablePaginationConfig, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { FilterValue } from 'antd/lib/table/interface';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import annualReviewAdminApi from '../../../AnnualReview/services/annual-review-admin.api';
import { ROUTES } from '../../../common/router';
import { getRoute } from '../../../common/tools/router-tools';
import { useAppSelector } from '../../../common/hooks';
import { selectUser } from '../../../Auth/state/auth.slice';


const AnnualReviewToValidateList: React.FC = () => {

    const DEFAULT_PAGESIZE = 10;

    const user = useAppSelector(selectUser);

    const navigate = useNavigate();
    const [data, setData] = useState<IAnnualReview[]>([]);
    const [status, setStatus] = useState<number>(10);
    const [, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [, setCurrentQuery] = useState<any>({})
    const [loading, setloading] = useState<boolean>(true);

    const getData = useMemo(() => async (
        pagination: TablePaginationConfig = { pageSize: DEFAULT_PAGESIZE, current: 1 },
        filters?: Record<string, FilterValue | null>,
    ) => {
        if (user) {
            setloading(true)
            const data = await annualReviewAdminApi.findAll(
                { status, ownerId: user.id }, pagination.current as number, pagination.pageSize as number
            )
            setData(data.rows)
            setCurrentPage(pagination.current as number)
            setPageSize(pagination.pageSize as number)
            setCurrentQuery(filters)
            setloading(false)
        }
    }, [status, user])

    useEffect(() => {
        getData();
    }, [getData, pageSize]);


    const columns: ColumnsType<IAnnualReview> = [
        {
            fixed: 'left',
            width: '50px',
            render: (value: any) => {
                return (
                    <div className='flex space-x-2'>
                        <Button
                            className="btn-link text-alc-blue font-semibold text-lg px-0"
                            onClick={() => navigate(getRoute(ROUTES.ADMIN_ANNUALREVIEW_ROUTES.annualReviewDetails, { annualReviewId: value.id }))}
                            icon={<EditOutlined />}
                        />
                    </div>
                )
            }
        },
        {
            title: 'Structure',
            fixed: 'left',
            dataIndex: ['structure', 'name'],
            key: 'structure.name',
            align: 'center' as const,
            render: (value: any) => {
                return <div className=' text-center'>{value}</div>
            }
        },
        {
            title: 'Insee / Siren',
            fixed: 'left',
            dataIndex: ['structure', 'siren'],
            key: 'structure.siren',
            align: 'center' as const,
            render: (value: any) => {
                return <div className=' text-center'>{value}</div>
            }
        },
        {
            title: 'Statut du bilan',
            fixed: 'right',
            dataIndex: 'status',
            key: 'status',
            align: 'center' as const,
            render: (value: any) => {
                if (value === AnnualReviewStatusEnum.completed) return (<Tag color='orange'>Bilan à valider</Tag>)
                else if (value === AnnualReviewStatusEnum.statement_pending) return (<Tag color='orange'>Décompte liquidatif à valider</Tag>)
                else if (value === AnnualReviewStatusEnum.order_sent) return (<Tag color='orange'>Titre de recette à valider</Tag>)
                else return (<div>Statut manquant</div>)
            }
        }
    ];

    const showMore = () => {
        navigate(getRoute(ROUTES.ADMIN_ANNUALREVIEW_ROUTES.annualReviewList, {}, { 'status': status }))
    }

    return (
        <>
            <div className='quick-links'>
                <button className={status === AnnualReviewStatusEnum.completed ? 'text-alc-blue font-bold underline' : 'text-alc-blue font-semibold'}
                    onClick={_ => setStatus(AnnualReviewStatusEnum.completed)}>Bilans à valider</button>
                <button className={status === AnnualReviewStatusEnum.statement_pending ? 'text-alc-blue font-bold underline' : 'text-alc-blue font-semibold'}
                    onClick={_ => setStatus(AnnualReviewStatusEnum.statement_pending)}>Décompte liquidatif à valider</button>
                <button className={status === AnnualReviewStatusEnum.order_sent ? 'text-alc-blue font-bold underline' : 'text-alc-blue font-semibold'}
                    onClick={_ => setStatus(AnnualReviewStatusEnum.order_sent)}>Titre de recette à valider</button>
            </div>
            <div className='mt-4'>
                <div className='grid grid-cols-1 grid-rows-2 gap-6'>
                    <Table<IAnnualReview>
                        style={{ cursor: 'pointer' }}
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        onChange={getData}
                        rowKey="id"
                        pagination={false}
                        footer={() => <Button type='link' onClick={showMore}> Voir plus ... </Button>}
                    />

                </div>
            </div>
        </>

    );
};

export default AnnualReviewToValidateList;
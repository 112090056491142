import { RcFile } from "antd/es/upload"
import { selectCurrentContract, setCurrentContractAction } from "../../../state/contract-portal.slice"
import UserFileField from "../../../../common/components/portal/UserFileField/UserFileField"
import { useAppDispatch, useAppSelector } from "../../../../common/hooks"
import contractPortalApi from "../../../services/contract-portal.api"
import { useTranslation } from "react-i18next"
import FileField from "../../../../common/components/portal/FileField/FileField"

const PortalExecutiveDeliberationFileUpload = ({ isInForm = false }) => {
  const contract = useAppSelector(selectCurrentContract)
  const dispatch = useAppDispatch()
  const { t } = useTranslation();

  const contractExecutiveDeliberationUpload = async (file: RcFile) => {
    const contractUpdated = await contractPortalApi.uploadExecutiveDeliberationFile(file)
    dispatch(setCurrentContractAction(contractUpdated))
  }

  const contractExecutiveDeliberationDownload = (iframe = false) => {
    return contractPortalApi.downloadExecutiveDeliberationFile()
  }

  const contractExecutiveDeliberationView = (iframe = false) => {
    return contractPortalApi.getExecutiveDeliberationFile()
  }

  const contractExecutiveDeliberationDelete = () => {
    return contractPortalApi.deleteExecutiveDeliberationFile()
      .then(contractUpdated => dispatch(setCurrentContractAction(contractUpdated)))
  }

  if (!contract)
    return <></>;

  if (isInForm)
    return <FileField
      contract={contract}
      propertyName="executiveDeliberationFileId"
      upload={contractExecutiveDeliberationUpload}
      download={contractExecutiveDeliberationDownload}
      remove={contractExecutiveDeliberationDelete}
      label={t("TYPES.FILEDOCUMENT.executiveDeliberationFileId")}
      isRequired={false}
    />

  return <UserFileField
    fileDocument={contract.executiveDeliberationFile}
    upload={contractExecutiveDeliberationUpload}
    download={contractExecutiveDeliberationView}
    propertyName='executiveDeliberationFileId'
  ></UserFileField>
}

export default PortalExecutiveDeliberationFileUpload
import { RcFile } from "antd/es/upload"
import AdminFileField from "../../../../common/components/admin/AdminFileField/AdminFileField"
import { useAppDispatch, useAppSelector } from "../../../../common/hooks"

import { FileDocumentStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces"
import contractAdminApi from "../../../services/contract-admin.api"
import { adminSetCurrentContractAction, selectCurrentAdminContract } from "../../../state/contract-admin.slice"


const AdminDelegationFileUpload = () => {
  const contract = useAppSelector(selectCurrentAdminContract)
  const dispatch = useAppDispatch()

  const contractDelegationUpload = async (file: RcFile) => {
    if (!contract)
      throw new Error()
    const contractUpdated = await contractAdminApi.uploadDelegationFile(contract.id!, file)
    dispatch(adminSetCurrentContractAction(contractUpdated))
  }

  const contractDelegationDownload = () => {
    if (!contract)
      throw new Error()
    return contractAdminApi.getDelegationFile(contract.id!)
  }

  const updateStatus = async (status: FileDocumentStatusEnum, comment?: string) => {
    const contractUpdated = await contractAdminApi.updateDelegationFileDoc(contract?.id ?? '', { status, comment })
    dispatch(adminSetCurrentContractAction(contractUpdated))
  }

  if (!contract)
    return <></>;

  return <AdminFileField
    fileDocument={contract.delegationFile}
    updateStatus={updateStatus}
    upload={contractDelegationUpload}
    download={contractDelegationDownload}
    propertyName='delegationFileId'
  ></AdminFileField>
}

export default AdminDelegationFileUpload
import { Models } from "@alcome-rep/alcome-types";
import { IFileDocument } from "@alcome-rep/alcome-types/dist/interfaces";
import { ISupplierFileDocument } from "@alcome-rep/alcome-types/dist/interfaces/supplier/supplierFileDocument";
import { Alert } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { selectUser } from "../../../../Auth/state/auth.slice";
import adminSupplierOrderApi from "../../../../SupplierOrder/services/admin-supplierOrder.api";
import LoadingScreen from "../../../../common/components/LoadingScreen";
import Section from "../../../../common/components/Panels/Section";
import UserFileFields from "../../../../common/components/portal/UserFileFields/UserFileFields";
import { useAppSelector } from "../../../../common/hooks";
import supplierOrderApi from "../../../services/supplierOrder.api";

const AdminSupplierOrderDeliveryNotes = () => {

  const { supplierOrderId }: any = useParams();

  const user = useAppSelector(selectUser)

  const [supplierOrder, setSupplierOrder] = useState<Models.SupplierOrder>()

  useEffect(() => {
    if (!supplierOrderId)
      return

    adminSupplierOrderApi.getSupplierOrderById(supplierOrderId)
      .then(res => {
        setSupplierOrder(res)
      })

  }, [supplierOrderId])

  const getdeliveryNote = async (file: ISupplierFileDocument | IFileDocument): Promise<any> => {
    if (supplierOrder)
      return await supplierOrderApi.getFileDocument(file.propertyName as any, supplierOrder.id as string, supplierOrder.supplierCompanyId, file.timestamp as any)
  }

  if (!supplierOrder)
    return <LoadingScreen />

  return (
    <>
      <div className="print:hidden">
        {
          supplierOrder.deliveryNotes && supplierOrder.deliveryNotes.length > 0 ?
            <div>
              <Section title="Bons de livraison">
                <UserFileFields
                  canEdit={!user?.isSuperAdmin()}
                  download={getdeliveryNote}
                  fileDocument={(supplierOrder.deliveryNotes ?? []) as ISupplierFileDocument[]}
                  propertyName="deliveryNotes"
                />
              </Section>
            </div>
            :
            <Alert message={<div>Aucun bon de livraison disponible pour le moment.</div>} />
        }
      </div>
    </>
  )
}

export default AdminSupplierOrderDeliveryNotes
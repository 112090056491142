import { Form, Select } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { selectUserAdmin } from "../../../Auth/state/auth.slice";
import userAdminApi from "../../../User/services/user-admin.api";
import { useAppSelector } from "../../hooks";

type PropsType = {
  hideLabel?: boolean,
  initialValue?: string,
  className?: string,
  filterName?: string
}

const FilterDelegate = ({ hideLabel = false, initialValue, className = `m-0`, filterName = 'Délégué' }: PropsType) => {
  const queryParamName = 'ownerId'

  const [selectedItem, setSelectedItem] = useState<string | null>(initialValue ?? null)
  const user = useAppSelector(selectUserAdmin)
  const [options, setOptions] = useState<{ value: string, label: string }[]>();
  let [searchParams, setSearchParams] = useSearchParams();

  const fetchUser = useMemo(() => {
    return (search?: string) => {
      const q: any = {};
      if (search)
        q.lastName = '/' + search + '/'
      userAdminApi.findAllUserAdmin(q)
        .then(r => setOptions(r.rows.map(row => ({
          value: row.id!,
          label: `${row.firstName} ${row.lastName}`
        }))))
    }
  }, [setOptions])

  useEffect(() => {
    const queryParam = searchParams.get(queryParamName);
    if (queryParam !== null) {
      setSelectedItem(queryParam)
    } else {
      fetchUser()
      setSelectedItem(null)
    }
  }, [setSelectedItem, searchParams, queryParamName, fetchUser])

  useEffect(() => {
    const val = searchParams.get(queryParamName) || initialValue
    if (val) {
      fetchUser(val)

      searchParams.set(queryParamName, val);
      setSearchParams(searchParams);
    }
  },
    // eslint-disable-next-line
    [initialValue, setSearchParams, fetchUser]
  )

  // /* eslint-disable */
  // useEffect(() => {
  //   if (!!initialValue) {
  //     setSelectedItem(initialValue)
  //     selectItem(initialValue)
  //   }

  // }, [initialValue])
  /* eslint-enable */



  useEffect(() => {
    fetchUser()
  }, [fetchUser]);

  const onSearch = async (value: string) => {
    fetchUser(value)
  }

  const selectItem = (item: any) => {
    searchParams.set(queryParamName, item);
    setSearchParams(searchParams);
  }

  const clear = () => {
    searchParams.delete(queryParamName);
    setSearchParams(searchParams);
    setOptions([])
    setSelectedItem(null)
  }

  if (!user)
    return <></>

  return (
    <>
      <Form.Item
        className={className}
        label={!hideLabel && filterName}
      >
        <Select
          filterOption={false}
          allowClear
          showSearch
          placeholder={filterName}
          // optionFilterProp="children"
          onSearch={onSearch}
          onClear={clear}
          onSelect={selectItem}
          value={selectedItem}
          options={options}
        />

      </Form.Item >
    </>
  );
};

export default FilterDelegate;
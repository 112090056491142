import { SignatoryTypesEnum } from "@alcome-rep/alcome-types/dist/interfaces"
import { Contract } from "@alcome-rep/alcome-types/dist/models"
import { Button, Form, Input, Select } from "antd"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "../../../../common/hooks"
import contractAdminApi from "../../../services/contract-admin.api"
import { adminSetCurrentContractAction } from "../../../state/contract-admin.slice"

type PropsType = {
  contract: Contract
  formSubmit?: (data?: FormType) => void
}

type FormType = Partial<Contract['signatory']>

const SignatoryForm = ({ contract, formSubmit }: PropsType) => {
  const dispatch = useAppDispatch();

  const updateSignatory = async (formData: any) => {
    const contractupdated = await contractAdminApi.updateContractSignatory(contract.id!, formData);
    dispatch(adminSetCurrentContractAction(contractupdated));

    formSubmit && formSubmit(formData)
  }

  const { t } = useTranslation()

  return <Form
    name="basic"
    initialValues={contract.signatory}
    layout='vertical'
    onFinish={updateSignatory}
  >
    <div >
      <Form.Item<FormType>
        label="Type de signataire"
        name={['type']}
        required
      >
        <Select>
          {Object.keys(SignatoryTypesEnum).map(type => (
            <Select.Option key={type} value={type}>{t(`TYPES.CONTRACT.SignatoryTypesEnum.${type}`)}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Nom"
        name={['lastName']}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Prénom"
        name={['firstName']}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="Fonction"
        name={['function']}
      >
        <Input defaultValue={""}></Input>
      </Form.Item>
    </div>
    <div className='flex space-x-2 justify-end'>
      <Button onClick={_ => formSubmit && formSubmit()} htmlType="button" type="link" >{t('COMMON.cancel')}</Button>
      <Button htmlType="submit" type="primary"  >{t('COMMON.save')}</Button>
    </div>
  </Form >
}


export default SignatoryForm
import { SignatoryTypesEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { Contract } from "@alcome-rep/alcome-types/dist/models";
import PortalContractFileUpload from "../FileUploads/PortalContractFileUpload";
import PortalDelegationFileUpload from "../FileUploads/PortalDelegationUpload";
import PortalExecutiveDeliberationFileUpload from "../FileUploads/PortalExecutiveDeliberationUpload";

type PropsType = {
  contract: Contract
}

const PortalContractFiles = ({ contract }: PropsType) => {

  if (!contract) {
    throw new Error()
  }
  return (
    <>
      <PortalContractFileUpload />
      <PortalExecutiveDeliberationFileUpload />
      {contract.signatory && contract.signatory.type === SignatoryTypesEnum.delegate && (
        <PortalDelegationFileUpload />
      )}
    </>

  );
};

export default PortalContractFiles;
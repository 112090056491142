import { DownOutlined } from "@ant-design/icons";
import { Button } from "antd";
import type { ReactNode } from "react";

type PropTypes = {
    onClick: () => void,
    children: ReactNode,
    icon?: ReactNode,
}

export default function SeeMoreButton({ onClick, children, icon = <DownOutlined /> } : PropTypes) {

    return (
        <Button 
          icon={icon}
          className="text-black font-semibold " type="link" htmlType="button" onClick={onClick}
        >
          {children}
        </Button>
    );
}
import { RouteObject } from "react-router-dom";


const routePrefix = 'admin'
const routeDomain = 'order'
const routeParam = 'orderId'

export const adminOrderRouteNames = {
  orderList: { path: `${routePrefix}/${routeDomain}`, title: `ROUTES.${routeDomain}.list` },
  orderDetails: { path: `${routePrefix}/${routeDomain}/:${routeParam}`, title: "ROUTES.infos" }
}

export const adminOrderRoutes: RouteObject[] = [
  {
    path: adminOrderRouteNames.orderList.path.replace(`${routePrefix}/`, ''),
    element: <></>,
  },
  {
    path: adminOrderRouteNames.orderDetails.path.replace(`${routePrefix}/`, ''),
    element: <></>
  },
]

import { Models } from "@alcome-rep/alcome-types"
import { SupplierOrderStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces/supplier"
import { User } from "@alcome-rep/alcome-types/dist/models"
import { PrinterOutlined } from "@ant-design/icons"
import { Alert, Button } from "antd"
import { useEffect, useState } from "react"
import { Link, Outlet, useParams } from "react-router-dom"
import { selectUser } from "../../../Auth/state/auth.slice"
import DateField from "../../../common/components/Fields/DateField"
import LoadingScreen from "../../../common/components/LoadingScreen"
import ActionPanel from "../../../common/components/Panels/ActionPanel"
import Figure from "../../../common/components/Panels/Figure"
import NavTabs from "../../../common/components/Panels/NavTabs"
import PageCanvas from "../../../common/components/Panels/PageCanvas"
import { useAppSelector } from "../../../common/hooks"
import { ROUTES } from "../../../common/router"
import { getRoute } from "../../../common/tools/router-tools"
import adminSupplierOrderApi from "../../services/admin-supplierOrder.api"
import supplierOrderApi from "../../services/supplierOrder.api"
import SupplierOrderStatus from "../common/SupplierOrderStatus"

const SupplierOrderLayout = () => {

  const { supplierOrderId }: any = useParams();

  const user = useAppSelector(selectUser)
  const isAdmin = User.IsUserAdmin(user);

  const [supplierOrder, setSupplierOrder] = useState<Models.SupplierOrder>()

  useEffect(() => {
    if (!supplierOrderId)
      return

    if (isAdmin) {
      adminSupplierOrderApi.getSupplierOrderById(supplierOrderId)
        .then(res => {
          setSupplierOrder(res)
        })
    } else {
      supplierOrderApi.getSupplierOrderById(supplierOrderId)
        .then(res => setSupplierOrder(res))
    }

  }, [supplierOrderId, isAdmin])

  if (!supplierOrder)
    return <LoadingScreen />

  return (
    <>
      <div className="hidden print:flex print:flex-wrap">
        <div className="w-full">
          <div>ALCOME</div>
          <div>164 rue du Faubourg Saint-Honoré</div>
          <div>75008 Paris</div>
          <div>SIRET : 892 015 355 00019</div>
        </div>
        <div className="ml-auto flex-wrap">
          <div>{supplierOrder.supplierCompany?.name}</div>
          <div>{supplierOrder.supplierCompany?.address.street}</div>
          <div>{supplierOrder.supplierCompany?.address.zip} {supplierOrder.supplierCompany?.address.city}</div>
          <div>SIRET: {supplierOrder.supplierCompany?.siret}</div>
        </div>
      </div>
      <PageCanvas
        title={`Bon de commande`}
        actions={<Button type="link" onClick={_ => window.print()} icon={<PrinterOutlined />}>Imprimer le bon de commande</Button>}
        breadCrumbs={[
          ROUTES.ADMIN_USER_ROUTES.home,
          ROUTES.ADMIN_SUPPLIER_ORDER_ROUTES.supplierOrderList,
          ROUTES.ADMIN_SUPPLIER_ORDER_ROUTES.supplierOrderDetails
        ]}
      >
        <>
          {User.IsUserAdmin(user) && <>
            {supplierOrder.status === SupplierOrderStatusEnum.pending && <Alert type="warning" banner message="Cette commande est en attente d'acceptation de la part du directeur financier" />}
            {supplierOrder.status === SupplierOrderStatusEnum.published && <Alert type="warning" banner message="Cette commande est en attente d'acceptation de la part du fournisseur" />}
            {supplierOrder.status === SupplierOrderStatusEnum.supplier_has_validate && <Alert type="warning" banner message="Cette commande a été validée par le fournisseur" />}
          </>}
        </>

        <>
          {User.IsUserSupplier(user) && <>
            {supplierOrder.status === SupplierOrderStatusEnum.pending && <Alert type="warning" banner message="La commande est en attente d'acceptation de votre part" />}
            {supplierOrder.status >= SupplierOrderStatusEnum.supplier_has_validate && <Alert type="warning" banner message="Vous avez validé cette commande" />}
          </>}
        </>


        <div className="grid grid-cols-3 mt-2 gap-4">
          {/* <Figure title="Fournisseur" value={supplierOrder.supplierCompany?.name} /> */}
          <Figure title="Fournisseur" value={<Link to={getRoute(ROUTES.ADMIN_SUPPLIER_COMPANY_ROUTES.supplierCompanyDetails, { supplierCompanyId: supplierOrder.supplierCompanyId })}>{supplierOrder.supplierCompany?.name}</Link>} />
          <Figure title="Date de commande" value={<DateField value={supplierOrder.orderDate} />} />
          <Figure title="Numéro de commande" value={supplierOrder.supplierOrderNumber} />

          <div className="contents print:hidden">

            <Figure title="Date d'expédition" value={<DateField value={supplierOrder.shipDate} />} />
            <Figure title="Date de livraison" value={<DateField value={supplierOrder.deliveryDate} />} />
            <Figure title="Status" value={<SupplierOrderStatus status={supplierOrder.status} />} />

            {/* <ActionPanel
              title="Fournisseur"
              content={<Link to={getRoute(ROUTES.ADMIN_SUPPLIER_COMPANY_ROUTES.supplierCompanyDetails, { supplierCompanyId: supplierOrder.supplierCompanyId })}>{supplierOrder.supplierCompany?.name}</Link>}
            /> */}
            <ActionPanel
              title="Structure"
              content={<Link className="font-semibold text-xl" to={getRoute(ROUTES.ADMIN_STRUCTURE_ROUTES.structureDetails, { structureId: supplierOrder.structureId })}>{supplierOrder.structure?.name}</Link>}
            />
            <ActionPanel
              title="Demande de dispositifs"
              content={<Link to={getRoute(ROUTES.ADMIN_ACQUISITION_ORDER_ROUTES.acquisitionOrderDetails, { acquisitionOrderId: supplierOrder.acquisitionOrderId })}>{supplierOrder.acquisitionOrder?.acquisitionOrderNumber}</Link>}
            />
          </div>

        </div >
        <div className="mt-4">
          <div className="print:hidden">
            <NavTabs tabRoutes={User.IsUserAdmin(user)
              ? [
                { ...ROUTES.ADMIN_SUPPLIER_ORDER_ROUTES.supplierOrderInfos, params: { supplierOrderId: supplierOrderId } },
                // ...supplierOrder.status >= SupplierOrderStatusEnum.supplier_has_validate ? [{ ...ROUTES.ADMIN_SUPPLIER_ORDER_ROUTES.supplierOrderInvoices, params: { supplierOrderId: supplierOrderId } }] : [],
                { ...ROUTES.ADMIN_SUPPLIER_ORDER_ROUTES.supplierOrderInvoices, params: { supplierOrderId: supplierOrderId } },
                { ...ROUTES.ADMIN_SUPPLIER_ORDER_ROUTES.supplierOrderDeliveryNotes, params: { supplierOrderId: supplierOrderId } },
              ] : [
                { ...ROUTES.SUPPLIER_ORDER_ROUTES.supplierOrderInfos, params: { supplierOrderId: supplierOrderId } },
                { ...ROUTES.SUPPLIER_ORDER_ROUTES.supplierOrderInvoices, params: { supplierOrderId: supplierOrderId } },
                { ...ROUTES.SUPPLIER_ORDER_ROUTES.supplierOrderDeliveryNotes, params: { supplierOrderId: supplierOrderId } },
              ]} />
          </div>
          <Outlet />
        </div>
      </PageCanvas>
    </>
  )
}

export default SupplierOrderLayout
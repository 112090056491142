import { Tooltip } from "antd";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { TFunction } from "i18next";

export const logActionsColumns = (t: TFunction<"translation", undefined>) => [
  {
    title: 'Type d\'action',
    dataIndex: 'method',
    key: 'method',
    render: (value: any) => (<span>{t(`TYPES.LOGACTIONS.method.${value}`)}</span>)
  },
  {
    title: 'Evènement',
    dataIndex: 'eventTitle',
    key: 'eventTitle',
    render: (value: any) => {
      return (<Tooltip title={value}><span>{value.match(/[^/]+$/)[0]}</span></Tooltip>)
    }
  },
  // {
  //   title: 'Role de l\'utilisateur',
  //   dataIndex: 'userRole',
  //   key: 'userRole',
  //   render: (value: UserTypeEnum) => (<span >{t(`TYPES.ROLES.${value.userType.toUpperCase()}.${Models.User.GetUserRoleKey({ role: value } as IUser)}`)}</span>)
  // },
  {
    title: 'Date de l\'action',
    dataIndex: 'date',
    key: 'date',
    render: (value: any) => (<span className=''>{format(new Date(value), "' 'd MMMM yyyy à HH:mm'", { locale: fr })}</span>)
  },
];
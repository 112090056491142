import { InventoryStatusEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import { User } from '@alcome-rep/alcome-types/dist/models';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Dispatch } from '@reduxjs/toolkit';
import { Button, message } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { useEffect, useState } from 'react';
import { selectUser } from '../../../Auth/state/auth.slice';
import { selectCurrentAdminContract } from '../../../Contract/state/contract-admin.slice';
import { selectCurrentContract } from '../../../Contract/state/contract-portal.slice';
import GenerateFileButton from '../../../common/components/GenerateFileButton/GenerateFileButton';
import LoadingScreen from '../../../common/components/LoadingScreen';
import Section from '../../../common/components/Panels/Section';
import Info from '../../../common/components/WarningUser/Info';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import toast from '../../../common/services/toast';
import { useTrackProgress } from '../../../common/services/trackProgress';
import inventoryAdminApi from '../../services/inventory-admin.api';
import inventoryPortalApi, { progressKeys } from '../../services/inventory-portal.api';
import { adminSetCurrentInventoryAction, selectCurrentAdminInventory } from '../../state/inventory-admin.slice';
import { selectCurrentInventory, setCurrentInventoryAction } from '../../state/inventory-portal.slice';

const InventoryAnnexeStep = () => {
  const user = useAppSelector(selectUser);
  const inventory = useAppSelector(User.IsUserAdmin(user) ? selectCurrentAdminInventory : selectCurrentInventory);
  const contract = useAppSelector(User.IsUserAdmin(user) ? selectCurrentAdminContract : selectCurrentContract);

  const dispatch: Dispatch<any> = useAppDispatch()

  const admin = User.IsUserAdmin(user);
  const isUploading = useTrackProgress(progressKeys.generateInventory);

  const [hotspotCount, setHotspotCount] = useState<number>(0)

  useEffect(() => {
    if (!admin && inventory!.status < InventoryStatusEnum.completed)
      inventoryPortalApi.checkHotspotCount().then(res => setHotspotCount(res))
  }, [inventory, admin])

  // check if switch fields are defined to know if forms has been correctly set
  // const hasFillForms = (inventory?.communes || []).find(c => c.hasPolice === undefined) === undefined
  //   && inventory?.inChargeOfTrashes !== undefined;
  // const showHotspotError = !admin && (!inventory?.hotspotCount || !(inventory)?.hotspotCount)
  const userCanValidate = inventory?.userCanValidate(user) && hotspotCount > 0;

  let comment: null | string = null;

  const download = () => {
    if (admin && inventory && inventory.id) {
      return inventoryAdminApi.getInventoryFile(inventory.id)
    } else {
      return inventoryPortalApi.getInventoryFile()
    }
  }

  const completeInventory = async () => {
    if (!inventory)
      return;

    const checkValues = inventory.communes && inventory.communes.map(c => {
      const policeDispositions = c.policeDispositions && c.policeDispositions !== ''
      const policeCount = c.policeCount && c.policeCount as any !== ''
      const policeSanctions = c.policeSanctions && c.policeSanctions !== ''

      if (c.hasPolice === false && policeSanctions) {
        return true
      } else if (c.hasPolice === true && policeDispositions && policeCount && policeSanctions) {
        return true
      } else {
        return false
      }
    })
    const check = checkValues?.includes(false)
    const checkGrp = checkValues?.includes(true)

    if (check === true) {
      const msg = checkGrp === true ? 'Une ou plusieurs communes sont incomplètes dans l\'onglet "Prévention" ' : 'L\'onglet "Prévention" est incomplet'
      return message.error(msg)
    } else {
      try {
        const inventoryUpdated = await inventoryPortalApi.completeInventoryStatus(inventory.id!)
        dispatch(setCurrentInventoryAction(inventoryUpdated))
        return toast.success('Votre état des lieux a été envoyé. Alcome procédera à sa vérification très rapidement.');
      } catch (_err) {

      }
    }

  }

  const generateAnnexe = async () => {
    if (!inventory)
      return;
    const inventoryUpdated = await inventoryAdminApi.generateInventoryFile(inventory.id!)
    dispatch(adminSetCurrentInventoryAction(inventoryUpdated))
  }

  const validateInventory = async () => {
    if (!inventory)
      return;

    confirm({
      title: "Validation de l'état des lieux",
      icon: <ExclamationCircleOutlined />,
      content: 'Êtes-vous sûr de vouloir valider ?',
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okButtonProps: { className: "btn" },
      async onOk() {
        const inventoryUpdated = await inventoryAdminApi.validateStatus(inventory.id!)
        dispatch(adminSetCurrentInventoryAction(inventoryUpdated));
      },
      onCancel() {
      },
    });

  }

  const refuseInventory = async () => {
    if (!inventory)
      return;

    confirm({
      title: "Refuser l'état des lieux",
      icon: <ExclamationCircleOutlined />,
      content: (<>Ajouter un commentaire <textarea className='block w-full p-2' onChange={e => comment = (e.target.value)} ></textarea></>),
      cancelText: 'Annuler',
      closable: true,
      okCancel: true,
      okButtonProps: { className: "btn" },
      async onOk() {
        const inventoryUpdated = await inventoryAdminApi.refuseStatus(inventory.id!, { comment })
        dispatch(setCurrentInventoryAction(inventoryUpdated));
        comment = null
      },
      onCancel() {

      },
    })

  }

  if (!inventory)
    return <LoadingScreen />;

  return (
    <Section mode="transparent" >
      {inventory.status <= InventoryStatusEnum.refused && (
        <>
          {!admin ? <div>
            {inventory.status === InventoryStatusEnum.pending &&
              <p>Pour obtenir vos annexes veuillez valider votre états des lieux une fois toutes les informations fournies. Nos équipes vous communiquerons alors les documents PDF correspondants.</p>
            }
            {inventory.status === InventoryStatusEnum.refused &&
              <>
                <p className="mb-4">Vos annexes ont été refusées pour le motif précisé ci-dessous. Pour obtenir vos annexes veuillez corriger votre états des lieux et les transmettre à nouveau à Alcome.</p>
                <div className='refused mb-4'>
                  <span className="font-semibold block">Motif de refus:</span>
                  {inventory.inventoryFile?.comment}
                </div>
              </>
            }
            <Button type="primary" disabled={!userCanValidate} htmlType="button" className="btn w-full" onClick={completeInventory}>
              Envoyer l'état des lieux
            </Button>
            {hotspotCount === 0 &&
              (<p className='text-alc-danger text-xs italic text-center mt-1'>Vous devez renseigner au moins un hotspot avant d'envoyer vos informations</p>)
            }
            {inventory.inventoryHasMissingPoliceInfos() &&
              (<p className='text-alc-danger text-xs italic text-center mt-1'>Vous devez renseigner les informations présentes dans les différents onglets</p>)
            }
          </div>
            : (
              <>
                {inventory.status === InventoryStatusEnum.pending && <p>L'état des lieux est en cours de saisie par l'utilisateur</p>}
                {inventory.status === InventoryStatusEnum.refused &&
                  <>
                    <p>L'état des lieux a été refusé. Il est en cours de saisie par l'utilisateur</p>
                    <span>Motif de refus: </span>
                    <div className='refused mt-2'>
                      {inventory.inventoryFile?.comment}
                    </div>
                  </>
                }
              </>
            )}
        </>
      )}

      {inventory.status === InventoryStatusEnum.completed && !admin && (
        <Info body="Vos annexes sont en cours de révision chez Alcome. Vous recevrez prochainement un email de notification lorsque les informations seront validées." />
      )}
      {inventory.status === InventoryStatusEnum.validated && (
        <Info body="Les annexes ont été validées" />
      )}

      {(inventory.userAdminCanGenerateInventoryFile(user) || inventory.userCanViewInventoryFile(user)) && (
        <GenerateFileButton
          isLoading={isUploading}
          downloadFile={download}
          generateFile={generateAnnexe}
          readOnly={!inventory.userAdminCanGenerateInventoryFile(user)}
          file={inventory.inventoryFile} />
      )}

      {admin && inventory.status === InventoryStatusEnum.completed && (
        <div className='mt-10 flex space-x-4'>
          <Button
            disabled={!inventory.adminCanValidateInventory(user, contract)}
            type="primary"
            htmlType="button"
            className="btn"
            onClick={validateInventory}
          >
            Valider l'état des lieux
          </Button>
          {inventory.adminCanValidateInventory(user, contract) &&
            <Button
              type="link"
              htmlType="button"
              className="text-alc-danger"
              onClick={refuseInventory}>
              Refuser l'état des lieux
            </Button>
          }
        </div>
      )}

    </Section >
  );
};

export default InventoryAnnexeStep;
import { Prospect } from '@alcome-rep/alcome-types/dist/models/crm';
import { takeLatest } from 'redux-saga/effects';
import createEntityDucksFactory from '../../common/state/ducks.factory';
import prospectAdminApi from '../services/prospect-admin.api';
import { adminDeleteProspectAction, adminGetProspectAction, adminSetCurrentProspectAction, adminUpdateProspectAction } from './prospect-admin.slice';
import ApiService from '../../common/services/apiService';

const actions = createEntityDucksFactory<Prospect>({
  get: prospectAdminApi.getProspect,
  update: prospectAdminApi.updateProspect,
  delete: prospectAdminApi.deleteProspect
} as ApiService<Prospect>,
  {
    get: adminGetProspectAction,
    set: adminSetCurrentProspectAction,
    update: adminUpdateProspectAction,
    delete: adminDeleteProspectAction,
  })

export function* prospectAdminSaga() {
  yield takeLatest(adminGetProspectAction, actions.get);
  yield takeLatest(adminUpdateProspectAction, actions.update);
  yield takeLatest(adminDeleteProspectAction, actions.delete);

}


// }
import { AnnualReviewStatusEnum, FileDocumentStatusEnum, IAnnualReview, IFileDocument } from '@alcome-rep/alcome-types/dist/interfaces';
import { UserTypeEnum } from '@alcome-rep/alcome-types/dist/interfaces/user';
import { ExclamationCircleOutlined, SendOutlined, UploadOutlined } from '@ant-design/icons';
import { Dispatch } from '@reduxjs/toolkit';
import { Button } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { RcFile } from 'antd/lib/upload';
import moment from 'moment';
import 'moment/locale/fr';
import { selectUser } from '../../../../Auth/state/auth.slice';
import LoadingScreen from '../../../../common/components/LoadingScreen';
import Info from '../../../../common/components/WarningUser/Info';
import AdminFileField from '../../../../common/components/admin/AdminFileField/AdminFileField';
import UserFileField from '../../../../common/components/portal/UserFileField/UserFileField';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import annualReviewAdminApi from '../../../services/annual-review-admin.api';
import annualReviewPortalApi from '../../../services/annual-review-portal.api';
import { adminAcceptOrderActions, adminRefuseOrderActions, adminUpdateRevenueOrderFileActions, adminUploadDocumentActions, selectAdminCurrentAnnualReview } from '../../../state/annual-review-admin.slice';
import { deleteAnnualReviewDocumentActions, selectCurrentPortalAnnualReview, sendOrderActions, uploadCurrentAnnualReviewDocumentActions } from '../../../state/annual-review-portal.slice';
import RevenueOrderForm from './annualReviewRevenueOrderForm';
moment().locale('fr')

type PropsType = {
  isAdmin?: boolean
}

const RevenueOrderStep = ({ isAdmin }: PropsType) => {

  const annualReviewSelectorToUse = isAdmin ? selectAdminCurrentAnnualReview : selectCurrentPortalAnnualReview;
  const annualReview = useAppSelector(annualReviewSelectorToUse);

  let comment: null | string = null;

  const user = useAppSelector(selectUser);
  const dispatch: Dispatch<any> = useAppDispatch()

  if (!annualReview) {
    return <><LoadingScreen></LoadingScreen></>
  }

  const admin = (user && user.userType === UserTypeEnum.admin) || false
  const canSendRevenueOrder = annualReview.revenueOrder?.revenueOrderDate && annualReview.revenueOrderFileId ? true : false

  const upload = (propertyName: string) => (file: RcFile) => {
    dispatch(uploadCurrentAnnualReviewDocumentActions({ id: annualReview.id, propertyName, file }))
  }

  const adminUpload = (propertyName: string) => (file: RcFile) => {
    dispatch(adminUploadDocumentActions({ id: annualReview.id, propertyName, file }))
  }

  const download = (propertyName: keyof IAnnualReview) => () => {
    if (!admin) {
      return annualReviewPortalApi.getAnnualReviewFile(propertyName, annualReview.id as string)
    } else {
      return annualReviewAdminApi.getAnnualReviewFile(propertyName, annualReview.id as string)
    }
  }

  const deleteFile = (propertyName: string) => () => {
    dispatch(deleteAnnualReviewDocumentActions({ id: annualReview.id, propertyName }))
  }

  const sendRevenueOrder = async () => {
    confirm({
      title: 'Acceptation du titre de recette',
      icon: <ExclamationCircleOutlined />,
      content: 'Êtes-vous sûr de vouloir envoyer ce titre de recette?',
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okButtonProps: { className: "btn" },
      onOk() {
        dispatch(sendOrderActions({ annualReviewId: annualReview.id }))
      },
      onCancel() {
      },
    });
  }
  const acceptRevenueOrder = async () => {
    confirm({
      title: 'Acceptation du titre de recette',
      icon: <ExclamationCircleOutlined />,
      content: 'Êtes-vous sûr de vouloir accepter le titre de recette ci-dessus ?',
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okButtonProps: { className: "btn" },
      onOk() {
        dispatch(adminAcceptOrderActions({ annualReviewId: annualReview.id }))
      },
      onCancel() {
      },
    });
  }

  const refuseRevenueOrder = async () => {
    confirm({
      title: 'Refus du titre de recette',
      icon: <ExclamationCircleOutlined />,
      content: (<>Ajouter un commentaire <textarea className='block w-full p-2' onChange={e => comment = (e.target.value)} ></textarea></>),
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okButtonProps: { className: "btn" },
      onOk() {
        dispatch(adminRefuseOrderActions({ annualReviewId: annualReview.id, revenueOrderComment: comment || '' }))
        comment = null
      },
      onCancel() {
      },
    });
  }

  const updateStatus = (propertyName: string) => (status: FileDocumentStatusEnum, comment?: string) => {
    dispatch(adminUpdateRevenueOrderFileActions({ annualReviewId: annualReview.id, propertyName, data: { status, comment } }))
  }

  return (
    <>
      {annualReview.status >= AnnualReviewStatusEnum.statement_accepted && (
        <div className="grid grid-cols-1 gap-6">

          {!admin && annualReview.status < AnnualReviewStatusEnum.order_sent && (
            <Info type="warning" icon={<ExclamationCircleOutlined />} body={<>Veuillez remplir le formulaire (pensez à l'enregistrer) et insérer le titre de recette avant l'envoi en cliquant sur <UploadOutlined /> ci-après.</>} />
          )}

          {/*{annualReview.paymentExpectedDate && (
            <span className='text-alc-success'>{format(new Date(annualReview.paymentExpectedDate), "'Paiement prévu pour le 'd MMMM yyyy'", { locale: fr })}</span>
          )} 
          <hr />
          {annualReview.status === AnnualReviewStatusEnum.statement_accepted && (
            <RevenueOrderForm annualReview={annualReview} />
          )}
          */}

          {!admin && annualReview.status === AnnualReviewStatusEnum.order_sent && (
            <Info icon={<SendOutlined />} type="warning" body="Votre titre de recette à été envoyé, il doit être accepté par l'équipe Alcome." />
          )}

          {!admin && annualReview.status === AnnualReviewStatusEnum.order_refuse && (
            <Info icon={<ExclamationCircleOutlined />} type="error" body={`Votre titre de recette à été refusé pour le motif : ${annualReview.revenueOrderComment ?? "Motif non communiqué"}.`} />
          )}

          {admin && annualReview.status === AnnualReviewStatusEnum.order_sent && (
            <Info icon={<ExclamationCircleOutlined />} type="warning" body="Vous devez accepter ou refuser le titre de recette en validant le fichier envoyé par la structure puis en validant le titre de recette." />
          )}

          <RevenueOrderForm annualReview={annualReview} disabled={!admin && annualReview.status !== AnnualReviewStatusEnum.statement_accepted} />

          {!admin && annualReview.status === AnnualReviewStatusEnum.statement_accepted && (
            <UserFileField
              fileDocument={annualReview.revenueOrderFile}
              upload={upload('revenueOrderFileId')}
              download={download('revenueOrderFileId')}
              propertyName='revenueOrderFileId'
              deleteFile={deleteFile('revenueOrderFileId')}
            />
          )}
          {!admin && annualReview.status >= AnnualReviewStatusEnum.order_sent && (
            <UserFileField
              fileDocument={annualReview.revenueOrderFile}
              upload={upload('revenueOrderFileId')}
              download={download('revenueOrderFileId')}
              propertyName='revenueOrderFileId'
            />
          )}
          {admin && annualReview.status >= AnnualReviewStatusEnum.statement_accepted && (
            <AdminFileField
              fileDocument={annualReview.revenueOrderFile}
              upload={adminUpload('revenueOrderFileId')}
              download={download('revenueOrderFileId')}
              propertyName='revenueOrderFileId'
              updateStatus={updateStatus('revenueOrderFileId')}
            />
          )}
          
          {!admin && annualReview.status === AnnualReviewStatusEnum.statement_accepted && (
            <Button disabled={!canSendRevenueOrder} type="primary" htmlType="button" className="btn justify-self-start" onClick={sendRevenueOrder}>
              Envoyer votre titre de recette
            </Button>
          )}
          {admin && annualReview.status === AnnualReviewStatusEnum.order_sent && (annualReview.revenueOrderFile as IFileDocument).status === FileDocumentStatusEnum.approved && (
            <div className="flex gap-4">
              <Button type="primary" htmlType="button" className="btn" onClick={acceptRevenueOrder}>
                Accepter le titre de recette
              </Button>
              <Button
                type="link"
                htmlType="button"
                className="text-alc-danger"
                onClick={refuseRevenueOrder}>
                Refuser le titre de recette
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RevenueOrderStep;
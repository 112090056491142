import { IAnnualReview, ZoneTypeEnum, statementVars } from "@alcome-rep/alcome-types/dist/interfaces";
import { Table } from "antd";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

const AnnualReviewStatementDetailsComponent = ({ annualReview }: { annualReview: IAnnualReview }) => {

    let year = new Date(annualReview.startDate).getFullYear();

    return (

        <Table 
            pagination={false}
            dataSource={annualReview.communes} 
            columns={[
                {
                    title: 'Commune',
                    dataIndex: 'name',
                },
                {
                    title: "Population",
                    dataIndex: 'population',
                },
                {
                    title: 'Type de commune',
                    dataIndex: 'zoneType',
                    render: (zoneType: ZoneTypeEnum) => statementVars.typeZone[zoneType]
                },
                {
                    title: 'Coefficient de Zone',
                    dataIndex: 'zoneType',
                    render: (zoneType: ZoneTypeEnum) => statementVars.typeZone[zoneType]
                },
                {
                    title: 'Coefficient Annuel',
                    dataIndex: 'zoneType',
                    render: (zoneType: ZoneTypeEnum) => (statementVars.multiplier as any)[year]
                },
                {
                    title: 'Total',
                    dataIndex: 'amountStatement',
                    render: (amountStatement: number | undefined) => `${amountStatement ? amountStatement.toFixed(2) : 0} €`
                }
            ]}
            footer={() => (
                <div className='flex justify-between font-semibold'>
                    <span>
                        Décompte pour la période du {format(new Date(annualReview.startDate), "' 'd MMMM'", { locale: fr })} au {format(new Date(annualReview.endDate), "' 'd MMMM yyyy'", { locale: fr })}
                    </span>
                    <span>
                        {annualReview.amountStatement ? annualReview.amountStatement.toFixed(2) : 0} €
                    </span>
                </div>
            )}
        />
    );
}


export default AnnualReviewStatementDetailsComponent
import { IUser } from "@alcome-rep/alcome-types/dist/interfaces/user";
import { BaseCommune } from "@alcome-rep/alcome-types/dist/models";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Section from "../../../../common/components/Panels/Section";
import { UserStructureProfilColumns } from "./UserStructureProfilComponent.constant";

type Props = {
    structure: BaseCommune
    loading: boolean
    userInStructure: IUser[] | []
    getUserInStructure: () => void
    count: number
}
const UserListOfUserInStructure = ({ structure, loading, count, userInStructure, getUserInStructure }: Props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Section mode="transparent" title={<span>Utilisateurs de votre structure</span>}>
            {structure && (
                <div id="gr">
                    <Table<IUser>
                        style={{ cursor: 'pointer' }}
                        columns={UserStructureProfilColumns(t,navigate)}
                        dataSource={userInStructure}
                        loading={loading}
                        onChange={getUserInStructure}
                        rowKey="id"
                        scroll={{ scrollToFirstRowOnChange: true }}
                        pagination={{
                            hideOnSinglePage: true,
                            defaultPageSize: 10,
                            total: count
                        }} />
                </div>
            )}
        </Section>
    )
}

export default UserListOfUserInStructure
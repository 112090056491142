import { selectCurrentStructure } from "../../../../Structure/state/structure-portal.slice";
import { useAppSelector } from "../../../../common/hooks";
import { selectCurrentPortalAnnualReview } from "../../../state/annual-review-portal.slice";
import AnnualReviewComponent from "../../common/AnnualReviewDetails/AnnualReviewComponent";

const PortalAnnualReviewPage = () => {
  const annualReview = useAppSelector(selectCurrentPortalAnnualReview);
  const structure = useAppSelector(selectCurrentStructure);

  if (!annualReview || !structure)
    throw new Error()

  return (
    <AnnualReviewComponent annualReview={annualReview} structure={structure} />
  )
}

export default PortalAnnualReviewPage
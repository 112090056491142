import { AnnualReviewStatusEnum } from '@alcome-rep/alcome-types/dist/interfaces';
import { UserTypeEnum } from '@alcome-rep/alcome-types/dist/interfaces/user';
import { AnnualReview } from '@alcome-rep/alcome-types/dist/models';
import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Dispatch } from '@reduxjs/toolkit';
import { Button } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { RcFile } from 'antd/lib/upload';
import moment from 'moment';
import 'moment/locale/fr';
import { selectUser } from '../../../../Auth/state/auth.slice';
import GenerateFileButton from '../../../../common/components/GenerateFileButton/GenerateFileButton';
import LoadingScreen from '../../../../common/components/LoadingScreen';
import UserFileField from '../../../../common/components/portal/UserFileField/UserFileField';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { useTrackProgress } from '../../../../common/services/trackProgress';
import annualReviewAdminApi from '../../../services/annual-review-admin.api';
import annualReviewPortalApi from '../../../services/annual-review-portal.api';
import { adminGenerateStatementDocumentActions, adminSetStatementActions, adminValidateStatementActions, selectAdminCurrentAnnualReview } from '../../../state/annual-review-admin.slice';
import { acceptStatementActions, refuseStatementActions } from '../../../state/annual-review-portal.slice';
import { selectCurrentPortalAnnualReview } from '../../../state/annual-review-portal.slice';
import AnnualReviewStatementDetailsComponent from './AnnualReviewStatementDetails';
import Info from '../../../../common/components/WarningUser/Info';
moment().locale('fr')

type PropsType = {
  isAdmin?: boolean
}

const StatementStep = ({ isAdmin }: PropsType) => {

  const annualReviewSelectorToUse = isAdmin ? selectAdminCurrentAnnualReview : selectCurrentPortalAnnualReview;
  const annualReview = useAppSelector(annualReviewSelectorToUse);
  let comment: null | string = null;

  // const isLoading = useTrackProgress(apiCallIds.GENERATE_ANNUAL_REVIEW);
  const isLoading = useTrackProgress('');
  const user = useAppSelector(selectUser);
  const dispatch: Dispatch<any> = useAppDispatch()

  const upload = (propertyName: string) => (file: RcFile) => {
    // dispatch(uploadCurrentAnnualReviewDocumentActions.request({ id: annualReview.id, propertyName, file }))
  }

  const download = (propertyName: keyof AnnualReview) => () => {
    if (!admin) {
      return annualReviewPortalApi.getAnnualReviewFile(propertyName, annualReview!.id as string)
    } else {
      return annualReviewAdminApi.getAnnualReviewFile(propertyName, annualReview!.id as string)
    }
  }

  const acceptStatement = async () => {
    confirm({
      title: 'Validation du décompte',
      icon: <ExclamationCircleOutlined />,
      content: "Vous êtes sur le point d'accepter le décompte. Souhaitez-vous poursuivre ?",
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okButtonProps: { className: "btn" },
      onOk() {
        dispatch(acceptStatementActions({ annualReviewId: annualReview!.id }))
      },
      onCancel() {
      },
    });

  }

  const refuseModal = () => () => {
    confirm({
      title: 'Refus du décompte liquidatif',
      icon: <ExclamationCircleOutlined />,
      content: (<>Ajouter un commentaire <textarea className='block w-full p-2' onChange={e => comment = (e.target.value)} ></textarea></>),
      cancelText: 'Annuler',
      closable:true,
      okCancel:true,
      okButtonProps: { className: "btn" },
      onOk() {
        dispatch(refuseStatementActions({ annualReviewId: annualReview?.id, statementComment: comment }))
        comment = null
      },
      onCancel() {

      },
    })
  }

  const calculateStatement = async () => {
    dispatch(adminSetStatementActions({ annualReviewId: annualReview!.id }))
  }

  const generateStatement = async () => {
    dispatch(adminGenerateStatementDocumentActions({ annualReviewId: annualReview?.id }))
  }

  const validateStatement = async () => {
    dispatch(adminValidateStatementActions({ annualReviewId: annualReview!.id }))
  }


  const admin = (user && user.userType === UserTypeEnum.admin) || false
  const canCalculate = admin && annualReview?.status === AnnualReviewStatusEnum.validated && !annualReview?.amountStatement !== undefined ? true : false
  const shouldDisplayAmountStatement = annualReview?.amountStatement !== undefined &&
    ((admin && annualReview && annualReview.status >= AnnualReviewStatusEnum.validated) || (!admin && annualReview && annualReview.status >= AnnualReviewStatusEnum.statement_pending)) ? true : false
  const userShouldValidateStatement = !admin && annualReview?.status === AnnualReviewStatusEnum.statement_pending ? true : false;
  const adminShouldValidateStatement = admin && annualReview?.status === AnnualReviewStatusEnum.validated && annualReview.statementFileId && annualReview?.amountStatement ? true : false;
  const adminShouldGenerateStatement = admin && annualReview?.status === AnnualReviewStatusEnum.validated && annualReview?.amountStatement ? true : false;

  if (!annualReview) {
    return <><LoadingScreen></LoadingScreen></>
  }

  return (
    <>
      {annualReview.status >= AnnualReviewStatusEnum.validated && (
        <div className='grid grid-cols-1 gap-6'>

          {shouldDisplayAmountStatement && adminShouldGenerateStatement && !annualReview.statementFile && (
            <Info icon={<QuestionCircleOutlined />} type="warning" body="Merci de générer le fichier du décompte liquidatif." />
          )}

          {shouldDisplayAmountStatement && adminShouldValidateStatement && annualReview.statementFile && (
            <Info icon={<QuestionCircleOutlined />} type="warning" body="Merci de valider le décompte liquidatif." />
          )}

          {admin && annualReview.status === AnnualReviewStatusEnum.statement_pending && (
            <Info body="Le décompte liquidatif est en attente de validation par l'utilisateur " />
          )}

          {!admin && annualReview.status < AnnualReviewStatusEnum.statement_pending && (
            <Info body="Votre décompte liquidatif est en cours de calcul par Alcome." />
          )}

          {!admin && annualReview.status === AnnualReviewStatusEnum.statement_pending && (
            <Info type="warning" icon={<QuestionCircleOutlined />} body="Merci d'accepter ou refuser le décompte liquidatif." />
          )}

          {annualReview.statementComment && (
            <Info type="error" icon={<ExclamationCircleOutlined />} body={`Décompte refusé : ${annualReview.statementComment}`} />
          )}

          {shouldDisplayAmountStatement && (
            <AnnualReviewStatementDetailsComponent annualReview={annualReview}></AnnualReviewStatementDetailsComponent>
          )}

          {canCalculate && annualReview.amountStatement === undefined && (
            
            <>
              <Info icon={<QuestionCircleOutlined />} type="warning" body="Vous pouvez calculer le décompte liquidatif." />
              <Button
                disabled={annualReview.status >= AnnualReviewStatusEnum.statement_pending}
                type="primary"
                htmlType="button"
                className="btn"
                onClick={calculateStatement}
              >
                Lancer le calcul
              </Button>
            </>
          )}

          {annualReview.status >= AnnualReviewStatusEnum.statement_pending && annualReview.statementFileId && (
            <UserFileField
              fileDocument={annualReview.statementFile}
              upload={upload('statementFileId')}
              download={download('statementFileId')}
              propertyName='statementFileId'
            />
          )}

          {adminShouldGenerateStatement && (
            <GenerateFileButton
              isLoading={isLoading}
              downloadFile={download('statementFileId')}
              generateFile={generateStatement}
              file={annualReview.statementFile} />
          )}

          {adminShouldValidateStatement && (<>
            <Button
              disabled={annualReview.status >= AnnualReviewStatusEnum.statement_pending}
              type="primary"
              htmlType="button"
              className="btn justify-self-start"
              onClick={validateStatement}
            >
              Valider le décompte liquidatif
            </Button>
          </>
          )}

          {userShouldValidateStatement && (
            <div className='flex gap-4'>
              <Button type="primary" htmlType="button" className="btn" onClick={acceptStatement}>
                Accepter le décompte
              </Button>
              <Button type="link" htmlType="button" className="text-alc-danger" onClick={refuseModal()}>
                Refuser le décompte
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default StatementStep;
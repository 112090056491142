import { IStructure, StructureTypesEnum } from "@alcome-rep/alcome-types/dist/interfaces"
import { BaseCommune } from "@alcome-rep/alcome-types/dist/models"
import { EditOutlined } from "@ant-design/icons"
import { Dispatch } from "@reduxjs/toolkit"
import { Button, Form, Input, message } from "antd"
import { useTranslation } from "react-i18next"
import structurePortalApi from "../../../../Structure/services/structure-portal.api"
import { getMyStructureAction, selectCurrentStructure, setCurrentStructureAction } from "../../../../Structure/state/structure-portal.slice"
import Section from "../../../../common/components/Panels/Section"
import { useAppDispatch, useAppSelector } from "../../../../common/hooks"
import { closeDrawerAction, openDrawerAction } from "../../../../common/state/modal.slice"
import AddressForm from "./AddressForm"

const UserStructureProfilDataForm = () => {

  const { t } = useTranslation()
  const structure = useAppSelector(selectCurrentStructure)
  const dispatch: Dispatch<any> = useAppDispatch()

  const submit = async (structureData: IStructure) => {
    try {
      const data: Partial<IStructure> = { ...structureData }
      delete data.siren;
      delete data.name
      const updatedStructure = await structurePortalApi.updateStructureInfos(data)
      dispatch(setCurrentStructureAction(updatedStructure));
      dispatch(closeDrawerAction())
      dispatch(getMyStructureAction())
      message.success('Profil administratif mis à jour')
    } catch { }
  }

  return (
    <div className="w-1/2">
      <Section title={
        <div className="flex justify-between">
          <span>Coordonnées de votre structure</span>
          <Button
            type="link"
            onClick={(e) => {
              dispatch(openDrawerAction({
                component: <AddressForm structure={structure as BaseCommune} submit={submit} />,
                options: { title: "Modifier mon adresse", size: 'large' }
              }))
            }}
            icon={<EditOutlined />} />
        </div>
      }>

        {structure && <Form
          name="basic"
          initialValues={structure}
          layout='vertical'
          onFinish={submit}
          className="grid grid-cols-2 gap-x-4"
          id="pa"
        >
          <Form.Item
            label={structure.structureType === StructureTypesEnum.commune ? t('UI.STRUCTURE_DETAILS.fields.insee.label') : t('UI.STRUCTURE_DETAILS.fields.siren.label')}
            name={['siren']}
          >
            <Input disabled></Input>
          </Form.Item>
          <Form.Item
            label={t('UI.STRUCTURE_DETAILS.fields.name.label')}
            name={['name']}
          >
            <Input disabled></Input>
          </Form.Item>
          <Form.Item
            label={t('UI.STRUCTURE_DETAILS.fields.street.label')}
            name={['address', 'street']}
            className="col-span-2"
          >
            <Input disabled></Input>
          </Form.Item>
          <Form.Item
            label={t('UI.STRUCTURE_DETAILS.fields.zip.label')}
            name={['address', 'zip']}
          >
            <Input disabled></Input>
          </Form.Item>
          <Form.Item
            label={t('UI.STRUCTURE_DETAILS.fields.city.label')}
            name={['address', 'city']}
          >
            <Input disabled></Input>
          </Form.Item>
          <Form.Item
            label={t('UI.STRUCTURE_DETAILS.fields.email.label')}
            name={['email']}
          >
            <Input disabled></Input>
          </Form.Item>
          <Form.Item
            label={t('UI.STRUCTURE_DETAILS.fields.phone.label')}
            name={['phone']}
          >
            <Input disabled></Input>
          </Form.Item>
          {/* <Form.Item>
            <Button disabled htmlType="submit" type="primary"  >
              Modifier les informations
            </Button>
          </Form.Item> */}
        </Form>}
      </Section>
    </div>
  )
}

export default UserStructureProfilDataForm
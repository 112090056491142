import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/store";
import { REGISTER_SERVICE } from "./types";
import { IStructure } from "@alcome-rep/alcome-types/dist/interfaces";
import { IUser } from "@alcome-rep/alcome-types/dist/interfaces/user";

// to implement

type RegisterServiceStateType = {
  locallySavedStructure: IStructure | null,
  locallySavedUser: IUser | null,
};

const registerInitialState: RegisterServiceStateType = {
  locallySavedStructure: null,
  locallySavedUser: null,
}

export const registerSlice = createSlice({
  name: REGISTER_SERVICE,
  initialState: registerInitialState,
  reducers: {
    locallySaveStructureAction: (state: RegisterServiceStateType, { payload: structure }: PayloadAction<IStructure | null>) => {
      state.locallySavedStructure = structure;
    },

    locallySaveUserAction: (state: RegisterServiceStateType, { payload: user }: PayloadAction<IUser | null>) => {
      state.locallySavedUser = user;
    },
  }
});


export const {
  locallySaveStructureAction,
  locallySaveUserAction
} = registerSlice.actions;

export default registerSlice.reducer;

export const selectRegisterService = (state: RootState) => state.registerService
export const selectLocallySavedStructure = createSelector(selectRegisterService, (registerSvc) => registerSvc.locallySavedStructure)
export const selectLocallySavedUser = createSelector(selectRegisterService, (registerSvc) => registerSvc.locallySavedUser)
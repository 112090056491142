import { IAcquisitionOrders } from "@alcome-rep/alcome-types/dist/interfaces";
import ApiService from "../../common/services/apiService";
import apiClient from "../../common/services/apiClient";

const ACQUISITION_ORDER_PATH_API = '/portal/acquisitionOrder';

class AcquisitionOrderApi extends ApiService<IAcquisitionOrders> {
  constructor() {
    super(ACQUISITION_ORDER_PATH_API)
  }

  getFileDocument(propertyName: keyof IAcquisitionOrders, acquisitionOrderId: string, timestamp: any) {
    return apiClient({
      url: `${ACQUISITION_ORDER_PATH_API}/${acquisitionOrderId}/fileDoc/${propertyName}/${timestamp}`,
      method: 'get',
      responseType: 'blob'
    }).then(r => r.data)
  }

  uploadFiles(propertyName: keyof IAcquisitionOrders, acquisitionOrderId: string, file: any) {
    return this.uploadFileDoc<IAcquisitionOrders>(propertyName, file, acquisitionOrderId, undefined)
  }

  deleteFile(propertyName: keyof IAcquisitionOrders, acquisitionOrderId: string, timestamp: any) {
    return this.custom('delete', `${acquisitionOrderId}/fileDoc/${propertyName}/?timestamp=${timestamp}`).then(res => res)
  }

}

const acquisitionOrderPortalApi = new AcquisitionOrderApi();
export default acquisitionOrderPortalApi;
import { Models } from "@alcome-rep/alcome-types";
import { ContractStatusEnum, InventoryStatusEnum } from "@alcome-rep/alcome-types/dist/interfaces";
import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { selectUser } from "../../../../Auth/state/auth.slice";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks";
import { closeDrawerAction, openDrawerAction } from "../../../../common/state/modal.slice";
import SignatoryForm from "../SignatoryForm/SignatoryForm";

type PropsType = {
  contract: Models.Contract,
  inventory: Models.Inventory
}

const ContractSignatory = ({ contract, inventory }: PropsType) => {
  const { t } = useTranslation()
  const user = useAppSelector(selectUser)

  if (!contract || !inventory)
    throw new Error()

  const dispatch = useAppDispatch()
  const contractAndInventoryValidated = contract && inventory && contract.status < ContractStatusEnum.validated && inventory.status < InventoryStatusEnum.validated

  const showSignatoryForm = () => {
    dispatch(openDrawerAction({
      component: <SignatoryForm contract={contract} formSubmit={() => dispatch(closeDrawerAction())} />,
      options: { title: "Signataire" }
    }))
  }

  return (
    <>
      {/* EN attente d'instructions */}
      {contract.signatory && (
        <div>
          <dl className="grid grid-cols-[200px_1fr]">
            <dt>Type de signataire</dt>
            <dd>{t('TYPES.CONTRACT.SignatoryTypesEnum.' + contract.signatory.type)}</dd>
            <dt>Nom, prénom</dt>
            <dd>{contract.signatory.firstName}, {contract.signatory.lastName}</dd>
            <dt>Fonction</dt>
            <dd>{contract.signatory.function}</dd>
          </dl>

        </div>
      )}
      {Models.User.IsUserAdmin(user) && contractAndInventoryValidated &&
        <Button onClick={showSignatoryForm} type="link" htmlType="button" className="px-0" icon={<EditOutlined />}>
          Modifier le Signataire
        </Button>}
    </>
  )
}
export default ContractSignatory
import { IInventory } from '@alcome-rep/alcome-types/dist/interfaces';
import { Inventory } from '@alcome-rep/alcome-types/dist/models';
import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { adminSetCurrentStructureAction } from '../../Structure/state/structure-admin.slice';
import inventoryAdminApi from '../services/inventory-admin.api';
import { adminGetInventoryAction, adminGetInventoryByStructureAction, adminSetCurrentInventoryAction } from './inventory-admin.slice';

/**
 * SAGAS
 */

function* getInventory({ payload: id }: ReturnType<typeof adminGetInventoryAction>) {
  try {
    const resp: AxiosResponse<IInventory> = yield call(inventoryAdminApi.getInventoryById, id);

    yield put(adminSetCurrentInventoryAction(resp.data));
  } catch {
    yield put(adminSetCurrentInventoryAction(null));
  }
}

function* getStructureInventory({ payload }: ReturnType<typeof adminSetCurrentStructureAction>) {
  try {
    const resp: Inventory = yield call(inventoryAdminApi.getInventoryByStructureId, payload.id!);

    yield put(adminSetCurrentInventoryAction(resp));
  } catch {
    yield put(adminSetCurrentInventoryAction(null));
  }
}

function* unsetInventory({ payload }: ReturnType<typeof adminSetCurrentStructureAction>) {
  if (payload === null) {
    yield put(adminSetCurrentInventoryAction(null));
  }
}

export function* InventoryAdminSaga() {
  yield takeLatest(adminGetInventoryAction, getInventory);
  yield takeLatest(adminSetCurrentStructureAction, unsetInventory);
  yield takeLatest(adminGetInventoryByStructureAction, getStructureInventory);
}
import { IProduct } from "@alcome-rep/alcome-types/dist/interfaces/product"
import { useEffect, useState } from "react"
import productApi from "../../services/product-api"
import { getBase64 } from "../../../common/tools/global-tools"
import { Image } from "antd"

const ProductImage = ({ product, index = 0 }: { product: IProduct, index?: number }) => {
  const [image, setImage] = useState<string>()

  useEffect(() => {
    if (product.images && product.images.length)
      productApi.getProductImage(product?.id!, product.images && product.images[index])
        .then(blob => getBase64(blob))
        .then(img => setImage(img))
  }, [product, index])

  if (!image)
    return <></>
  return <div style={{ maxHeight: '120px', maxWidth: '120px' }}>
    <Image width={120} height={120} src={image}  className="object-cover" />
  </div>
}

export default ProductImage